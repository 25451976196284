import { Component } from './component';


/**
 * 
 */
export class Comment
{
    /**
     * Construction d'un objet Comment à partir d'un objet json donné
     * @param jsonObj 
     */
    public static fromJsonObject(jsonObj: any): Comment
    {
        let newComment = new Comment();

        newComment.name		= jsonObj.name      || '';
        newComment.message	= jsonObj.message   || '';
        newComment.status	= jsonObj.status    || '';
        newComment.id		= jsonObj.id        || jsonObj.uuid     || '';
        newComment.idUser	= jsonObj.idUser    || jsonObj.user     || '';
        newComment.resolved = jsonObj.resolved  || jsonObj.resolu   || '';

        if (jsonObj.creationdate)
        {
            newComment.creationDate.setTime(jsonObj.creationdate);
        }

        return newComment;
    }


	/**
	 * 
	 */
    constructor(	
        private _id:            string	= '',
        private _name:          string	= '',
        private _idUser:        string	= '',
        private _message:       string	= '',
        private _status:        string	= '',
        private _resolved:      string	= '',
        private _creationDate:  Date	= new Date()
    ){ }


    get id():			string		{ return this._id; }
    get name():			string		{ return this._name; }
    get idUser():		string		{ return this._idUser; }
    get message():		string		{ return this._message; }
    get status():		string		{ return this._status; }
    get resolved():		string		{ return this._resolved; }
    get creationDate(): Date		{ return this._creationDate; }


    set id(id:						string)	{ this._id				= id; }
    set name(name:					string)	{ this._name			= name; }
    set idUser(idUser:				string)	{ this._idUser			= idUser; }
    set message(message:			string)	{ this._message			= message; }
    set status(status:				string)	{ this._status			= status; }
    set resolved(resolved:			string)	{ this._resolved		= resolved; }
    set creationDate(creationDate:	Date)	{ this._creationDate	= creationDate; }


	/**
	 * 
	 */
    public toJson()
    {
        let result = 
        {
            id:				this.id,
            name:			this.name,
            idUser:			this.idUser,
            message:		this.message,
            status:			this.status,
            resolved:		this.resolved,
            creationdate:	this.creationDate.getTime()
        };

        return result;
    }
}

