import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }							from '@angular/material';
import { UserData }								from '../../providers/user-data';
import { WorkspaceData }								from '../../providers/workspace-data';

const fileSaver   = require('file-saver');

@Component({
	selector:		'download-rsa-modal',
	templateUrl:	'downloadRsaModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./downloadRsaModal.component.scss']
})


/**
 *
 */
export class DownloadRsaModalComponent
{

	/**
	 *
	 * @param dialogRef
	 */
	constructor(public dialogRef: MatDialogRef<DownloadRsaModalComponent, any>,
				public userData: UserData,
				public workspaceData: WorkspaceData)
	{
	}

	public downloadRsa()
	{
		window.URL = this.workspaceData.urlCreator;
		const key = this.userData.getRSAPrivate();

		if (key !== undefined)
		{
			const blob: Blob = new Blob([key], { type: 'text/plain' });
			fileSaver.saveAs(blob, 'rsaKey.exakey');
		}
	}

	public close()
	{
		this.dialogRef.close();
	}
}
