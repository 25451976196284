<div class="workspace_content_commentary_inner">
    <div class="workspace_content_commentary_title">
        {{libelle}}
    </div>
    <div #scrollMessages class="workspace_content_commentary_content" *ngIf="element">
        <div class="workspace_content_commentary_item" *ngFor="let comment of element.comments">
			 <div *ngIf="typeComment === 'comment'" class="workspace_content_commentary_comment ">
                <div class="workspace_content_commentary_item_title">
                    <span class="comment_item_title"	*ngIf="comment.idUser"><app-user-name [userMail]="comment.idUser"></app-user-name></span>
                    <span class="comment_item_subtitle" *ngIf="comment.creationDate"><app-date-user-friendly [noFuture]="true" [date]="comment.creationDate" ></app-date-user-friendly></span>
					<div class="clear"></div>
                </div>
				<div class="comment_content_container">

					<div class="workspace_content_commentary_item_content" *ngIf="comment.message">
						{{comment.message}}
					</div>
				</div>
            </div>
            <div *ngIf="typeComment === 'historic'" class="workspace_content_commentary_historic">
                <div class="workspace_content_commentary_item_title">
                    <span class="comment_item_title" *ngIf="comment.idUser"><app-user-name [userMail]="comment.idUser"></app-user-name></span>
                    <span class="comment_item_subtitle" *ngIf="comment.creationDate"><app-date-user-friendly  [noFuture]="true" [date]="comment.creationDate" ></app-date-user-friendly></span>
					<div class="clear"></div>
					<div class="comment_content_container">
						<span class="comment_item_subtitle" *ngIf="comment.soustitre">{{comment.soustitre}}</span>

						<span class="comment_item_subtitle" *ngIf="comment.path">
							{{comment.soustitrePath}}
							<span *ngIf="canDownloadFile(comment.idFile) === true">
								<a (click)="downloadFile(comment.path, comment.idFile, comment.nameFile, comment.typeFile)">{{comment.nameFile}}</a>
							</span>
							<span *ngIf="canDownloadFile(comment.idFile) === false">{{comment.nameFile}}</span>
							<span class="comment_item_subtitle2" *ngIf="comment.version">version {{comment.version}}</span>
						</span>
					</div>
					<div class="workspace_content_commentary_item_content" *ngIf="comment.content" [innerHTML]="decodeHTML(comment.content)">
					</div>
				</div>
			</div>
			<div *ngIf="typeComment === 'historic-comment'" class="workspace_content_commentary_hc">
				<div class="workspace_content_commentary_item_title">
					<span class="comment_item_title"	*ngIf="comment.idUser"><app-user-name [userMail]="comment.idUser"></app-user-name></span>
					<span class="comment_item_subtitle" *ngIf="comment.creationDate"><app-date-user-friendly  [noFuture]="true" [date]="comment.creationDate" ></app-date-user-friendly></span>
					<div class="clear"></div>

				</div>
				<div class="comment_content_container">
					<span class="comment_item_subtitle" *ngIf="comment.soustitre">
						{{comment.soustitre}}
					</span>
					<span class="comment_item_subtitle" *ngIf="comment.path">
						{{comment.soustitrePath}}
						<span *ngIf="canDownloadFile(comment.idFile) === true">
							<a (click)="downloadFile(comment.path, comment.idFile, comment.nameFile, comment.typeFile)">{{comment.nameFile}}</a>
						</span>
						<span *ngIf="canDownloadFile(comment.idFile) === false">
							{{comment.nameFile}}
					</span>
				</span>
				<div class="workspace_content_commentary_item_content" *ngIf="comment.content" [innerHTML]="decodeHTML(comment.content)">					
				</div>
				<div class="workspace_content_commentary_item_content" *ngIf="comment.path">
					<span *ngIf="canDownloadFile(comment.idFile) === true">
						<a (click)="downloadFile(comment.path, comment.idFile)">{{comment.textPath}}</a>
					</span>
					<span *ngIf="canDownloadFile(comment.idFile) === false">
						{{comment.textPath}}
				</span>
			</div>
		</div>
	</div>
</div>
</div>
<div class="add_comment_block" *ngIf="canAddNewMessage">
	<label>{{ 'comments.addComment' | translate }}</label>
	<mat-form-field>
		<textarea [(ngModel)]="comment" (keyup.enter)="addNewComment()" matInput matTextareaAutosize matAutosizeMinRows="3"
			matAutosizeMaxRows="3"></textarea>
	</mat-form-field>
	<button mat-raised-button [disabled]="!comment || comment === ''" (click)="addNewComment()">{{ 'common.send' | translate }}</button>
</div>

</div>

