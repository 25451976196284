import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';
import { ChatData }				from '../../../providers/chat-data';
import { Router }				from '@angular/router';


@Component({
	selector:		'app-module-chat',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./module-chat.component.html',
	styleUrls:		['./module-chat.component.scss']
})


/**
 * 
 */
export class ModuleChatComponent implements OnInit
{
	@Input() maHomeRooms:	any;
	@Input() contexte:		any;
	

	/**
	 * 
	 * @param chatData 
	 * @param router 
	 */
	constructor(public chatData:	ChatData,
				public router:		Router) 
	{ }


	/**
	 * 
	 */
	public ngOnInit()
	{
	}


	/**
	 * 
	 * @param id 
	 */
	public onClick(id: any)
	{
		this.chatData.setSelectedRoomBy(id);
		this.router.navigate(['/community']);
	}

}