// Angular
import { Injectable }	from '@angular/core';
import { Http }			from '@angular/http';
import {Observable} from 'rxjs/Rx';
// Other
import { UserData }		from './user-data';
import { EtablissementService } from '../services/etablissement.service';
import { SujetService } from '../services/sujet.service';

@Injectable()


/**
 * Manage the datas for the workspace component
 */
export class WorkspaceDataService
{
	_data:          any;
    _dataReturn:    any;


	/**
	 *
	 */
	constructor(public http: Http, public user: UserData,
				public etablissementService: EtablissementService,
				public sujetService: SujetService)
	{

	}


	public get data()
	{
		return this._dataReturn;
	}


	/**
	 *
	 */
    processSujet(data: any = null, sujet: any = null)
    {
    }


	/**
	 *
	 */
    filterSujet(sujet: any, queryWords: any, excludeTracks: any, segment: any)
    {

    }

}