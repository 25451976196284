<div [ngSwitch]="displayType" class="list_container">
	<div *ngSwitchCase="'list'" class="list">
		<div class="user_block">
			<div *ngIf='name' class="profile-picture" [attr.data-icone]="firstLetterIcone"  [style.color]="userData.getTextColor(color)"  [style.background]="color" [class.seen]="state">
				<div *ngIf="icone">
					<img [src]="icone">
				</div>
				<div *ngIf="!icone">
				</div>
			</div>
			<div *ngIf='!name' class="profile-picture" attr.data-icone="N" [style.color]="userData.getTextColor(color)" [style.background]="color" [class.seen]="state">
				<div *ngIf="icone">
					<img [src]="icone">
				</div>
				<div *ngIf="!icone">
				</div>
			</div>
			<div class="user_inner">
				<div class="name">
					{{ name }}
				</div>
				<div class="clear"></div>
				<div class="status" [class.seen]="state">
					{{ status }}
				</div>
			</div>
			<div class="clear"></div>
		</div>
		<div class="clear"></div>
	</div>
	<div *ngSwitchCase="'header'" class="header">
		<div class="name">
			{{ name }}
		</div>
		<div class="status">
			{{status}}
		</div>
	</div>
	<div class="clear"></div>
</div>
