<div *ngIf="task" class="ticket ticketType{{task.type}}" (mousedown)="focusTicket()" [class.minor]="task.priority == 2" [class.major]="task.priority == 1" [class.critical]="task.priority == 0">
	<div class="ticket-content" *ngIf="typeTicket==='ticket'">
		<mat-card class=" step_item"  >
			<div class="step_item_header">
				<div class="step_item_header_title_block">
					<div class="step_item_header_title">
						{{task.nameEpreuve}} - {{task.nameSujet}}
					</div>
					<div class="clear"></div>
					<!--	-->
					<div class="step_item_content_type_name">{{task.typeLib}}</div>

				</div>
				<div class="step_item_header_icon_block" matTooltip="{{task.name}}"  (click)="editTicket()" matTooltip="Editer la tâche">
					<mat-icon >mode_edit</mat-icon>
				</div>
			</div>
			<div class="step_item_content">
				<div class="step_item_content_info">

					<div class="step_item_content_info_user  title-h4" *ngIf="userData.isAdmin() || userData.isSuperviseur()">{{task.nameEtablissement}} - {{task.nameSession}}</div>

					<div class="step_item_content_info_user" *ngIf="userData.isAdmin() || userData.isSuperviseur()">
						 Assigné(e) à <app-user-name [userMail]="task.assigneduser"></app-user-name>
					</div>
					<div class="step_item_content_info_user" *ngIf="!userData.isAdmin() && !userData.isSuperviseur()">
						Assigné(e) par <app-user-name [userMail]="task.creator"></app-user-name>
					</div>
					<div class="step_item_content_info_user" *ngIf="task.name">
						Description : {{task.name}}
					</div>
					<div class="clear"></div>
					<div class="step_item_content_info_echeance">
						<span class="step_item_content_info_echeance_title">Echéance : </span><span class="step_item_content_info_echeance_date"><app-date-user-friendly [date]="task.endDate"></app-date-user-friendly></span>
					</div>
				</div>
				<!--
	
				<div class="step_item_content_type">
					<div class="step_item_content_type_name">{{task.typeLib}}</div>
					<div class="step_item_content_type_state">{{task.stateLib}}</div>
				</div>
				<button class="btn btn-glass btn-edit icon-pencil" title="Edit" (click)="editTicket()">
					<i class="material-icons">mode_edit</i>
				</button>
				-->
			</div>
		</mat-card>
	</div>
	<div class="ticket-content" *ngIf="typeTicket==='epreuve'">
		<mat-card class=" step_item"  >
			<div class="step_item_header">
				<div class="step_item_header_title_block">
					<div class="step_item_header_title" >
						{{task.name}}
					</div>
					<div class="clear"></div>
				</div>
				<div class="step_item_header_icon_block">
					<div class="neoptec_icone_24 neoptec_icone_24_warning"></div>
				</div>
			</div>
			<div class="step_item_content">
				<div class="step_item_content_info">
					<div class="step_item_content_info_user">
						Créateur <app-user-name [userMail]="task.author"></app-user-name>
					</div>
					<div class="clear"></div>
					<div class="step_item_content_info_echeance">
						<span class="step_item_content_info_echeance_title">Date de création : </span><span class="step_item_content_info_echeance_date">{{task.dateCreation.toLocaleDateString()}}</span>
					</div>

					<div class="step_item_content_type_name">{{task.nameEpreuve}}</div>
					<div class="step_item_content_type_state">{{task.state}}</div>
				</div>

			</div>
		</mat-card>
	</div>
</div>
