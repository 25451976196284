<div class="modal_user_valid_rsa">
	<div class="modal_user_valid_rsa_title">
		<div class="">{{ data.title }}</div>
	</div>
	<div class="modal_user_valid_rsa_content">
		{{ data.text }}
    </div>
	<div class="modal_user_valid_rsa_footer">
		<button id="" class="" mat-raised-button color="accent" (click)="onNoClick()">Valider</button>
	</div>
</div>
