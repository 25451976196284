// Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';

import { File } from '../classes/file.class';
import { Comment } from '../classes/comment.class';
import { Note } from '../classes/note.class';

import { FileHttpService }			from './http/fileHttp.service';
import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';
import { VaultData }				from '../providers/vault-data';
import { WorkspaceData } 			from '../providers/workspace-data';

import { EncryptService } from '../services/encrypt.service';
declare const require: any;


let fileSaver   = require('file-saver');
let JSZip		= require('jszip');

import {Buffer}		from 'buffer/';
import * as _		from 'underscore';
import * as crypto	from "crypto-browserify";

//let fileSaver: any;
@Injectable()


/**
 *
 */
export class FileService
{
    private socket: any = undefined;

    private listExtensionsFileSelector = '.jpeg, .jpg, .gif, .png, .bmp, .tga, .txt, .doc, .docx, .xls, .xlsx, .odt, .pdf, .html, .csv, .json, .zip, .tar, .rar, .7z, .xml, .tex, .ctx, .ltx, .tif, .tiff';

	/**
	 *
	 */
    constructor(private fileHttpService:	FileHttpService,
                private socketService:		SocketService,
                private workspaceData:		WorkspaceData,
                private encryptService:		EncryptService,
				private userData:			UserData,
				private vaultData:			VaultData)
	{
		this.socket = socketService.getSocketConnection();
	}


    /**
     * Add file in database indexed by given id
     */
    public addFile(file: File)
    {
        return this.fileHttpService.addFile(file).share();
    }


    /**
     * Get all files wich belong to a subject given by his id
     *
     * @param subjectId
     */
	public getFiles(subjectId: string)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd':	'files',
						'args': { idSubject: subjectId }
					};
				socket.emit('read', data);
			}
		});
	}


    /**
     * Get detailed file from infos in the file object given
     *
     * @param file
     */
	public getDetailsFile(file: File)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'files',
						'args':
						{
							idSubject:	file.idSubject,
							id:			file.id,
						}
					};
				socket.emit('read', data);

				let dataHistoric: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'history',
						'args':
						{
							bucket:		'files',
							key:		file.id,
						}
					};
				socket.emit('read', dataHistoric);
			}
		});
	}


    /**
     * Get detailed file from infos in the file object given
     *
     * @param file
     */
	public getHistoricFile(file: File)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{

				let dataHistoric: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'history',
						'args':
						{
							bucket:	'files',
							key:	file.id,
						}
					};
				socket.emit('read', dataHistoric);
			}
		});
	}


    /**
     * Remove file in database indexed by given id
     */
    public removeFile(file: File)
    {
       return this.fileHttpService.removeFile(file).share();
    }


	/**
	 *
	 */
	public updateFile(fileJson: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'file',
						'args':
						{
							file: fileJson
						}
					};
				socket.emit('update', data);
			}
		});
	}


	public deleteFile(file: any, definitive: boolean = false)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'file',
					'args':
					{
						fileId:			file.id,
						epreuveId:		file.idEpreuve,
						subjectId:		file.idSubject,
						definitive:		definitive
					}
				};

				socket.emit('delete', data);
			}
		});
	}


	public restoreFile(file: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'restoreFile',
						'args':
						{
							fileId:		file.id,
							epreuveId:	file.idEpreuve,
							subjectId:	file.idSubject
						}
					};

				socket.emit('update', data);
			}
		});
	}


	public addFileNote(file: File, note: Note)
	{
		return this.fileHttpService.addFileNote(file, note);
	}


	public addFileVersion(file: File)
	{
		return this.fileHttpService.addFileVersion(file);
	}


	public downloadContentFile(file: any, typeFile: string = 'file'): Observable<any>
	{
		// ---------------------------------------
		// Récupère le contenu d'un fichier
		// ---------------------------------------
		const observable = Observable.create((observer: any) =>
		{
			this.fileHttpService.downloadFile(file, typeFile).subscribe((res) =>
			{
				observer.next(res);
			});
		});

		return observable;
	}


	/**
	 *
	 */
	public saveForEdit(file: File, mustDecrypt: boolean = true): Observable<any>
	{
		// ---------------------------------------
		// Récupère le contenu d'un fichier et le télécharge
		// sur le navigateur du client
		// ---------------------------------------

		const observable = Observable.create((observer: any) =>
		{
			this.downloadContentFile(file).subscribe((res) =>
			{
				const body = (<any> res)._body;

				if (body)
				{
					const mimeType 		= body.type || this._getMimeType(file.extension);
					const blob: Blob 	= new Blob([body], { type: mimeType });
					// Cas spécifique: l'extension n'est pas ajoutée automatiquement
					// dans le cas d'un fichier tex
					switch (mimeType)
					{

						case 'application/x-tex':
							const nameExtension: any = file.name.split('.').pop();
							if (nameExtension !== 'ltx' && nameExtension !== 'tex')
							{
								file.name += '.ltx';
							}
							break;
						default:
					}

					if (mustDecrypt === true)
					{
						this.decryptFileWithAES(file, blob, mimeType).then((blobResult) =>
						{
							this.workspaceData.fileForEdit = blobResult;
							observer.next(true);
						});
					}
					else
					{
						this.workspaceData.fileForEdit = blob;
						observer.next(true);
					}
				}
			});
		});
		return observable;
	}


	/**
	 *
	 */
	public downloadFile(file: File, mustDecrypt: boolean = true): Observable<any>
	{
		// ---------------------------------------
		// Récupère le contenu d'un fichier et le télécharge
		// sur le navigateur du client
		// ---------------------------------------

		// On réinitialise l'objet global URL au cas ou si ce dernier aurait été écrasé (MacGiver)
		window.URL = this.workspaceData.urlCreator;

		let observable = Observable.create((observer: any) =>
		{
			file.isDownloading = true;

			this.downloadContentFile(file).subscribe((res) =>
			{

				let body = (<any> res)._body;

				if (body)
				{
					let mimeType 	= body.type || this._getMimeType(file.extension);
					let blob: Blob 	= new Blob([body], { type: mimeType });

					if (mustDecrypt === true)
					{
						this.decryptFileWithAES(file, blob, mimeType).then((blobResult) =>
						{
							fileSaver.saveAs(blobResult, file.name);
						});
					}
					else
					{
						fileSaver.saveAs(blob, file.name);
					}
				}
				file.isDownloading = false;
				observer.next(true);

			});
		});

		return observable;
	}


	public downloadContentFileFromId(id: any): Observable<any>
	{
		// ---------------------------------------
		// Récupère le contenu d'un fichier
		// ---------------------------------------
		let observable = Observable.create((observer: any) =>
		{
			this.fileHttpService.downloadFromId(id).subscribe((res) =>
			{
				observer.next(res);
			});
		});

		return observable;
	}

    /**
     * Suppression d'un fichier à partir de son identifiant dans le système de stockage
     * @param id
     */
	public deleteFromId(id: any): Observable<any>
	{
		let observable = Observable.create((observer: any) =>
		{
			this.fileHttpService.deleteFromId(id).subscribe((res) =>
			{
				observer.next(res);
			});
		});

		return observable;
	}


	public downloadFromId(path: any, idFile: any, name: string, type: string, mustDecrypt: boolean = true)
	{
		// ---------------------------------------
		// Récupère le contenu d'un fichier et le télécharge
		// sur le navigateur du client
		// ---------------------------------------
		window.URL = this.workspaceData.urlCreator;

		if (idFile)
		{
			let file: any = this.workspaceData.getTreeElement(idFile);

			if (file && file['encryptedAesKeys'] && file['encryptedAesKeys'].length > 0)
			{
				this.fileHttpService.downloadFromId(path).subscribe((res) =>
				{
					let body = (<any> res)._body;

					if (body)
					{
						let blob: Blob = new Blob([body], { type: type });

						if (mustDecrypt === true)
						{
							this.decryptFileWithAES(file, blob, type).then((blobResult) =>
							{
								fileSaver.saveAs(blobResult, name);
							});
						}
						else
						{
							fileSaver.saveAs(blob, name);
						}
					}
				});
			}
		}
	}


    /**
     * Download in blob objects all thumbnails for a given file
     */
	public downloadThumbnail(file: File, mustDecrypt: boolean = true): Observable<any>
	{
		const observable = Observable.create((observer: any) =>
		{
			this.fileHttpService.downloadFile(file, 'thumbnail').subscribe((res) =>
			{
				const mimeType 			= 'image/jpeg';
				const blobThumb: Blob 	= new Blob([(<any> res)._body], { type: mimeType });

				let thumbDatas: any;
				const urlCreator = this.workspaceData.urlCreator;

				if (mustDecrypt === true)
				{
					this.decryptFileWithAES(file, blobThumb, mimeType).then((blobResult) =>
					{
						thumbDatas =
						{
							blob: 	blobResult,
							url: 	urlCreator.createObjectURL(blobResult)
						};
						observer.next(thumbDatas);
					}).catch((error) =>
					{
						observer.next();
					});
				}
				else
				{
					thumbDatas =
						{
							blob: blobThumb,
							url: urlCreator.createObjectURL(blobThumb)
						};
					observer.next(thumbDatas);
				}
			}, (err) =>
				{
					observer.next();
				});
		});

		return observable;
	}


	/**
	 *
	 */
	public downloadFiles(files: File[], nameArchive: string, password: string = null): Observable<any>
	{
		window.URL = this.workspaceData.urlCreator;
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		const observable = Observable.create((observer: any) =>
		{
			this.fileHttpService.downloadFiles(files, password).subscribe((res) =>
			{
				const mimeType: any	= this._getMimeType('zip');
				const blob: Blob	= new Blob([(<any> res)._body], { type: mimeType });

				fileSaver.saveAs(blob, nameArchive);
				observer.next(true);
			});
		});
		return observable;
	}


	public decryptFileWithAES(file: any, blobInput: Blob, type: string): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.getDecryptedAESKey(file).then((aesFileKey =>
			{
				if (aesFileKey)
				{
					const objAesKey = JSON.parse(aesFileKey);

					this.encryptService.decryptAesFile(blobInput, type, objAesKey.sharedkey, objAesKey.vectorkey).then((blobResult) =>
					{
						resolve(blobResult);

					}).catch((error) =>
					{
						reject(error);
					});
				}
			})).catch((error) =>
			{
				reject(error);
			});
		});
	}


	public createArchivePassation(passationStruct: any, subjectsToAdd: any[]): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			const subjectsInfos: any[] = [];

			for (const subject of subjectsToAdd)
			{
				const newSubject: any 	= {};
				newSubject.id 			= subject.id;

				if (subject.datas)
				{
					newSubject.type = subject.datas.etat;
				}

				newSubject.name = subject.name;
				newSubject.pj	= [];

				let nameMainFile;

				if (subject.pj)
				{

					for (let pj of subject.pj)
					{

						const newPj: any = {};

						newPj.id		= pj.id;
						newPj.idSubject = pj.idSubject;
						newPj.name		= pj.name;

						const compoName: any = pj.name.split('.');
						if (compoName.length > 1 && compoName[1] === 'json')
						{
							nameMainFile = pj.name;
						}

						newSubject.pj.push(newPj);
					}
				}

				newSubject.nameMainFile = nameMainFile;

				subjectsInfos.push(newSubject);
			}

			const structIndex: any =
				{
					idpassation:		passationStruct.id,
					nomPassation:		passationStruct.libelle,
					datePassation:		passationStruct.datas.date,
					locationPassation:	passationStruct.datas.location,
					subjects:			subjectsInfos
				};

			let candidats = [];

			if (passationStruct.datas.candidats)
			{
				candidats = passationStruct.datas.candidats;
			}

			let zip = new JSZip();

			zip.file('index.json', JSON.stringify(structIndex));
			zip.file('candidats.json', JSON.stringify(candidats));

			const sujetsFolder = zip.folder('sujets');
			let cptFileToDownload = 0;

			for (let index = 0; index < subjectsToAdd.length; index++)
			{

				const sujet: 	any	= subjectsToAdd[index];
				const name: 	any	= index === 0 ? 'principal' : 'secondaire';

				const sujetFolder = sujetsFolder.folder(name);

				sujetFolder.file('correction.json', '');
				sujetFolder.file('bareme.json', '');

				for (const file of sujet.pj)
				{
					cptFileToDownload++;

					const pathJson = JSON.parse(file.path);

					this.downloadContentFileFromId(pathJson.main).subscribe((res) =>
					{

						sujetFolder.file(file.name, res._body);
						cptFileToDownload--;

						if (cptFileToDownload === 0)
						{
							zip.generateAsync({ type: 'blob' }).then((blob: any) =>
							{
								resolve(blob);

							}).catch((error: any) =>
							{
								reject(error);
							});
						}
					});
				}
			}
		});
	}


    /**
	 * Retourne si le format du fichier est accépté, retourne faux sinon
	 * @param file
	 */
	public isAcceptedFile(file: any, isNumericEpreuve: boolean = false)
	{
		let result = false;
		// ---------------------------------------
		// Todo: Remplacer ce test par un call via BDD
		// ---------------------------------------
		let supportedFileTypes: string[] =
		[
			'text/plain',
			'text/html',
			'text/csv',
			'application/x-abiword',
			'application/pdf',
			'application/msword',
			'application/json',
			'application/x-bzip',
			'application/x-bzip2',
			'application/x-rar-compressed',
			'application/x-tar',
			'application/x-7z-compressed',
			'application/x-zip-compressed',
			'application/x-gzip',
			'application/zip',
			'application/xml',
			'application/x-tex',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.ms-excel',
			'image/png',
			'image/bmp',
			'image/jpeg',
			'image/tiff'];


		if (isNumericEpreuve === true)
		{
			supportedFileTypes.push('audio/mp3');
			supportedFileTypes.push('video/mp4');
			supportedFileTypes.push('video/avi');
			supportedFileTypes.push('video/x-matroska');
		}

		if (file && file.type)
		{
			result = _.contains(supportedFileTypes, file.type);
		}
		else
		{
			// S'il n'y a pas de mimetype on regarde l'extension

			// On récupère les extentions accéptées
			let arrayExtentions = this.listExtensionsFileSelector.split(',');
			arrayExtentions 	= _.map(arrayExtentions, (ext: any) => { return ext.trim(); });

			// On récupère l'extention du fichier
			const fileNameParts = file.name.split('.');
			if (fileNameParts.length > 1)
			{
				const extention = '.' + fileNameParts[1];
				result 			= _.contains(arrayExtentions, extention);
			}
		}

		return result;
	}


	public getDecryptedAESKey(file: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			if (file.encryptedAesKeys && file.encryptedAesKeys.length > 0)
			{
				let encryptedKey	= file.encryptedAesKeys[0];
				encryptedKey		= encryptedKey.val;
				return this.encryptService.decryptData(encryptedKey).then(decoded =>
				{
					resolve(decoded);
				}).catch(error =>
				{
					reject(null);
				});
			}
			else
			{
				reject(null);
			}
		});
	}


	/**
	 *
	 */
	private _getMimeType(extension: string)
	{
		let oReturn: any;
		switch (extension)
		{
			case 'jpg':
				oReturn = 'image/jpeg';
				break;

			case 'zip':
				oReturn = 'application/zip';
				break;

			default:
				oReturn = 'application/octet-stream';
				break;
		}
		return oReturn;
	}
}
