<div class=" adminUser tree-view" >
	<mat-list>
		<mat-list-item   [class.selected]="isSelected(user)"  class="group sc2rv-item" (click)="displayDetailUser(user)" *ngFor="let user of userData.users | otherUsersFilter:userData.mail">
			<app-user [_name]="user.completeName" [_lastName]="user.infos.prenom" [_color]="user.infos.color" [_status]="user.mail" [link]="'none'" [_icone]="" [displayType]="'list'"></app-user>
		</mat-list-item>
	</mat-list>
</div>

<div *ngIf="!selectedUser" class="user_empty">
	<div class="user_empty_container">
		<div class="user_empty_admin_icon"></div>
		<h2>Merci d'ajouter ou de sélectionner un utilisateur</h2>
		<button mat-raised-button  class="user_empty_admin_button" (click)="addNewUser()">Ajouter un utilisateur</button>
	</div>
</div>    

<div class="content_view" *ngIf="selectedUser">
	<div class="inser-info-container-users">
		<div class="form_section_block">
			<div class="admin_user_form_row">
				<label>Type de compte :</label>
				<span *ngIf="selectedUser.publics.isAdmin==='true' || selectedUser.publics.isAdmin===true">Administrateur</span>
				<span *ngIf="(selectedUser.publics.isAdmin==='false' || selectedUser.publics.isAdmin===false) && (!selectedUser.publics.isSuperUser || selectedUser.publics.isSuperUser === 'false') && (selectedUser.publics.isPrinter === 'false' || selectedUser.publics.isPrinter === false) ">Utilisateur</span>
				<span *ngIf="(selectedUser.publics.isAdmin==='false' || selectedUser.publics.isAdmin===false) && (!selectedUser.publics.isSuperUser || selectedUser.publics.isSuperUser === 'false') && (selectedUser.publics.isPrinter === 'true'|| selectedUser.publics.isPrinter === true) ">Imprimeur</span>
				<span *ngIf="(selectedUser.publics.isAdmin==='false' || selectedUser.publics.isAdmin===false) && selectedUser.publics.isSuperUser==='true'">Super Administrateur</span>
			</div>
			
			<div class="clear"></div>
			<div class="admin_user_form_row">
				<label>{{ 'adminUsers.mail' | translate }}</label>
				<span>{{selectedUser.mail}}</span>
			</div>
			<div class="clear"></div>
			<div class="admin_user_form_row">
				<label>{{ 'adminUsers.name' | translate }}</label>
				<span>{{ selectedUser.infos.nom }}</span>
			</div>
			<div class="clear"></div>
			<div class="admin_user_form_row">
				<label>{{ 'adminUsers.surname' | translate }}</label>
				<span>{{ selectedUser.infos.prenom }}</span>
			</div>
			<div class="clear"></div>
		</div>

		<div class="form_section_block">
			<div class="admin_user_form_row">
				<div class="">
					<label>{{ 'adminUsers.phone' | translate }}</label>
				</div>
				<div class="clear"></div>
				<div class="admin_user_form_row">
					<label>{{ 'adminUsers.phone1' | translate }}</label>
					<span>{{selectedUser.publics.fixe1}}</span>
				</div>
				<div class="clear"></div>
				<div class="admin_user_form_row">
					<label>{{ 'adminUsers.phone2' | translate }}</label>
					<span>{{selectedUser.publics.mobile1}}</span>
				</div>
			</div>
			<div class="clear"></div>
		</div>

		<div class="form_section_block">
			<div class="admin_user_form_row">
				<div class="">
					<label>{{ 'adminUsers.jobs' | translate }}</label>
				</div>
				<div class="clear"></div>
				<div *ngFor="let profession of arrayProfessions; let index = index">
					<div class="indent">
						<label>{{ 'adminUsers.job' | translate }} {{index + 1}} :</label>
					</div>
					<span>{{profession}}</span>
					<div class="clear"></div>
				</div>
			</div>
			<div class="clear"></div>
			<!--	<div class="admin_user_form_row">
					<div class="">
						<label>{{ 'adminUsers.matieres' | translate }}</label>
					</div>
					<div class="clear"></div>
					<div *ngFor="let matiere of arrayMatieres; let index = index">
						<div class="indent">
							<label>{{ 'adminUsers.matiere' | translate }} {{index + 1}} :</label>
						</div>
						<span>{{matiere}}</span>
						<div class="clear"></div>
					</div>
				</div>-->
			<div class="clear"></div>
		</div>

		<div class="form_section_block" *ngIf="canMakeActionOnCurrentUser()">
			<div class="admin_user_form_row">
				<label>{{ 'adminUsers.codeMachine' | translate }}</label>
				<span>{{ getCodeMachine(selectedUser) }}</span>
			</div>
			<div class="clear"></div>
		</div>
		<div class="button_validation_user">
			<button mat-raised-button color="#fff" class="edit-user-button icon-pencil" (click)="displayModalAccount()" [disabled]="canMakeActionOnCurrentUser() === false">{{ 'adminUsers.edit' | translate }}</button>
			<button mat-raised-button color="#fff" class="edit-user-button icon-pencil" (click)="resetRsaKey(selectedUser)" [disabled]="canMakeActionOnCurrentUser() === false">{{ 'adminUsers.resetRsa' | translate }}</button>
			<button mat-raised-button color="#fff" class="edit-user-button icon-pencil" *ngIf="isCurrentUserAdminOrSuperUser()" [disabled]="canMakeActionOnCurrentUser() === false || hasCurrentUserRsaKey() === false || userData.hasRSAprivate() === false" (click)="giveFilesKeys(selectedUser)">{{ 'adminUsers.giveFilesKeys' | translate }}</button>
			<button mat-raised-button color="#fff" class="edit-user-button alert icon-pencil" (click)="toggleEnableAccount()" [disabled]="canMakeActionOnCurrentUser() === false">
				<span *ngIf="selectedUser.enabled === true">{{ 'adminUsers.disable' | translate }}</span>
				<span *ngIf="selectedUser.enabled === false">{{ 'adminUsers.enable' | translate }}</span>
			</button>
			<button mat-raised-button color="#fff" class="edit-user-button alert icon-pencil" [disabled]="isCurrentUserAdminOrSuperUser()" (click)="deleteAccount()">{{ 'adminUsers.delete' | translate }}</button>
		</div>
	</div>
</div>

<div class="commons_add_block" *ngIf="userData.isAdmin() || userData.isSuperviseur()" matTooltip="Ajouter un utilisateur">
	 <button mat-raised-button mat-fab ngsReveal (click)="addNewUser()"><i class="material-icons">add</i></button>
</div>
