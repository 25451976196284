<div *ngIf="currentScenario">

    <mat-form-field>
		<input type="text" matInput required placeholder="{{ 'processEditor.title' | translate }}"  maxLength="80" [disabled]="isReadOnly" [(ngModel)]="currentScenario.title"/>
	</mat-form-field>
	<div class="clear"></div>

	<mat-form-field  class="full_textarea">
		<textarea matInput placeholder="{{ 'processEditor.description' | translate }}" [disabled]="isReadOnly" [(ngModel)]="currentScenario.description"></textarea>
	</mat-form-field>

	<div class="clear"></div>
	<div class="testTest">
		<mat-table #table [dataSource]="etapesProcess" *ngIf="displayedColumns">

			<ng-container matColumnDef="name" class="testTest">
				<mat-header-cell *matHeaderCellDef> {{ 'processEditor.stepTitle' | translate }} </mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<mat-form-field>
						<input type="text" placeholder="Nom de l'étape" maxLength="80" matInput [(ngModel)]="etape.name" [disabled]="isReadOnly" [ngModelOptions]="{standalone: true}" />
					</mat-form-field>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="typevalid">
				<mat-header-cell *matHeaderCellDef> {{ 'processEditor.validType' | translate }} </mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<mat-select [(ngModel)]="etape.typevalid" [disabled]="isReadOnly" [ngModelOptions]="{standalone: true}" >
						<mat-option *ngFor="let typevalid of typesValidation" [value]="typevalid.value">{{ typevalid.label }}</mat-option>
					</mat-select>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="read">
				<mat-header-cell *matHeaderCellDef> {{ 'processEditor.read' | translate }} </mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<mat-checkbox [(ngModel)]="etape.read" [disabled]="isReadOnly" [ngModelOptions]="{standalone: true}"></mat-checkbox>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="write">
				<mat-header-cell *matHeaderCellDef> {{ 'processEditor.write' | translate }} </mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<mat-checkbox [(ngModel)]="etape.write" [disabled]="isReadOnly" [ngModelOptions]="{standalone: true}"></mat-checkbox>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="news">
				<mat-header-cell *matHeaderCellDef> {{ 'processEditor.new' | translate }} </mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<mat-checkbox [(ngModel)]="etape.news" [disabled]="isReadOnly" [ngModelOptions]="{standalone: true}"></mat-checkbox>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="actions" *ngIf="!isReadOnly">
				<mat-header-cell *matHeaderCellDef>Supprimer</mat-header-cell>
				<mat-cell *matCellDef="let etape">
					<button class='delete-button' type="button" (click)='removeStep(etape.id)'><i class="material-icons">add</i></button>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</div>
	<div class="clear"></div>

	<div class="button-add-step" *ngIf="!isReadOnly">
		<button mat-button type="button" *ngIf="cptStep < 7" (click)='createNewStep()'><i class="add-button material-icons">add</i> {{ 'processEditor.addStep' | translate }}</button>
	</div>
	<div class="clear"></div>

</div>
