<div class="loading_container">
	<div class="login_wait" *ngIf="isLoading">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
		</svg>
	</div>

	<mat-tab-group>
		<mat-tab label="Planification"> 
			<div class="kanban_header">

				<!--
				<mat-form-field class="kanban_header_selector">
					<mat-select class="" [(ngModel)]="kanbanTypeSelected" placeholder="Trier les tâches par: "><mat-option *ngFor="let typ2e of kanbanTypes" [value]="typ2e.value">{{ typ2e.viewValue }}</mat-option></mat-select>
				</mat-form-field>
				<div *ngIf="kanbanTypeSelected === 'status'" class="kanban_header_more workflow_selector">
					<mat-form-field>
						<mat-select [ngModel]="titleScenarioSelected" (ngModelChange)="changeScenarioSelected($event)"  placeholder="Type de workflow: ">
							<mat-option *ngFor="let scenario of adminData.scenariosAvailable" [value]="scenario.title">{{ scenario.title }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>-->

				<div class="kanban_header_more">
					<div class="kanban_header_more_block">
						<mat-form-field class="kanban_header_more_block_selector" >
							<mat-select placeholder="Classeur" class="item_selector" [ngModel]="workspaceData.selectedEtablissement" (ngModelChange)="changeSelectedEtablissement($event)" >
								<mat-option [value]="null" >Tous</mat-option>
								<mat-option [value]="etablissement" *ngFor="let etablissement of workspaceData.etablissements">{{ etablissement.name }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="kanban_header_more_block">
						<mat-form-field class="kanban_header_more_block_selector" *ngIf="workspaceData.selectedEtablissement">
							<mat-select placeholder="Session" class="item_selector" [ngModel]="workspaceData.selectedSession" (ngModelChange)="changeSelectedSession($event)" >
								<mat-option [value]="null" >Toutes</mat-option>
								<mat-option [value]="session" *ngFor="let session of workspaceData.selectedEtablissement.sessions">{{session.name}}</mat-option>
							</mat-select>
							<!--<mat-select placeholder="Session" class="item_selector" *ngIf="!workspaceData.selectedSession" (change)="changeSelectedSession($event)" >
								<mat-option [value]="null" >Aucun</mat-option>
								<mat-option [value]="session.id" *ngFor="let session of workspaceData.selectedEtablissement.sessions">
									{{session.name}}
							</mat-option>
						</mat-select>-->
						</mat-form-field>
					</div>

					<div class="kanban_header_more_block" >
						<mat-form-field class="kanban_header_more_block_selector" *ngIf="workspaceData.selectedSession">
							<mat-select placeholder="Examen" class="item_selector" [ngModel]="workspaceData.selectedExamen" (ngModelChange)="changeSelectedExamen($event)" >
								<mat-option [value]="null" >Tous</mat-option>
								<mat-option [value]="examen" *ngFor="let examen of workspaceData.selectedSession.examens">{{examen.name}}</mat-option>
							</mat-select>
							<!--<mat-select placeholder="Examen" class="item_selector"  *ngIf="!workspaceData.selectedExamen" (change)="changeSelectedExamen($event)" >
								<mat-option [value]="null" >Aucun</mat-option>
								<mat-option [value]="examen.id" *ngFor="let examen of workspaceData.selectedSession.examens">
									{{examen.name}}
							</mat-option>
						</mat-select>-->
						</mat-form-field>
					</div>


					<div class="kanban_header_more_block">
						<mat-form-field class="kanban_header_more_block_selector" *ngIf="workspaceData.selectedExamen">
							<mat-select placeholder="Epreuve" class="item_selector" [ngModel]="workspaceData.selectedMatiere" (ngModelChange)="changeSelectedMatiere($event)" >
								<mat-option [value]="null" >Toutes</mat-option>
								<mat-option [value]="matiere" *ngFor="let matiere of workspaceData.selectedExamen.matieres">{{matiere.name}}</mat-option>
							</mat-select>
							<!--<mat-select placeholder="Epreuve" class="item_selector" *ngIf="!workspaceData.selectedMatiere" (change)="changeSelectedMatiere($event)" >
								<mat-option [value]="null" >Aucun</mat-option>
								<mat-option [value]="matiere.id" *ngFor="let matiere of workspaceData.selectedExamen.matieres">
									{{matiere.name}}
							</mat-option>
						</mat-select>-->
						</mat-form-field>
					</div>



					<div class="kanban_header_more_block" >
						<mat-form-field class="kanban_header_more_block_selector" *ngIf="workspaceData.selectedMatiere">
							<mat-select placeholder="Sujet" class="item_selector" [ngModel]="workspaceData.selectedSujet" (ngModelChange)="changeSelectedSujet($event)" >
								<mat-option [value]="null" >Tous</mat-option>
								<mat-option [value]="sujet" *ngFor="let sujet of workspaceData.selectedMatiere.sujets">{{sujet.name}}</mat-option>
							</mat-select>
							<!--<mat-select placeholder="Sujet" class="item_selector" *ngIf="!workspaceData.selectedSujet" (change)="changeSelectedSujet($event)" >
								<mat-option [value]="null" >Aucun</mat-option>
								<mat-option [value]="sujet.id" *ngFor="let sujet of workspaceData.selectedMatiere.sujets">
									{{sujet.name}}
							</mat-option>
						</mat-select>-->
						</mat-form-field>
					</div>
					<div class="clear"></div>
				</div>
			</div>

			<div class="kanban_block">

				<div class="tree-view" *ngIf="kanbanTypeSelected !== 'status'">
					<div class="commons_block">
						<div class="new" *ngIf="taskData.taskDatas">
							<div class="lane_title">{{ 'task.unassigned' | translate }}</div>
							<lane type="new" id="new" laneTitle="Tâches disponible" [dragula]='kanbanTypeSelected'
								[dragulaOptions]="options"
								[dragulaModel]='task'
								[typeTicket]="'ticket'"
								[tasks]="taskData.taskDatas | tasksFilter:'state':'new'"
								(onTicketEdit)="editTicket($event)"
								(onAddSubTicket)="addSubTicket($event)"
								(onTicketRemove)="removeTicket($event)"
								(onTicketFocus)="focusTicket($event)"
								(onTicketSelect)="selectTicket($event)">
							</lane>
							<div class="clear"></div>
						</div>

					</div>
				</div>

				<div class="content_view" [ngClass]="{'content-by-user': kanbanTypeSelected === 'user'}">
					<div class="kanbanBoard">
						<div class="kanbanBoard-content">
							<div class="lanes" *ngIf="kanbanTypeSelected === 'state' && taskData.taskDatas">

								 <div *ngFor="let state of statesAvailable" class="lane_block" [ngClass]="state.value"  ngsRevealSet [ngsSelector]="'.lane_block'" [ngsRevealSet]="{origin:'bottom ', distance:'180px'}" [ngsInterval]="25">
									<div class=" lane_title">{{state.libelle}}</div>
									<lane type="{{state.value}}" id="{{state.value}}" laneTitle="{{state.libelle}}" [dragula]='kanbanTypeSelected'
										  [dragulaOptions]="options"
										  [dragulaModel]='task'
										  [typeTicket]="'ticket'"
										  [tasks]="taskData.taskDatas | tasksFilter:'state':state.value"
										  (onTicketEdit)="editTicket($event)"
										  (onAddSubTicket)="addSubTicket($event)"
										  (onTicketRemove)="removeTicket($event)"
										  (onTicketFocus)="focusTicket($event)"
										  (onTicketSelect)="selectTicket($event)"></lane>
									<div class="clear"></div>
								</div>
								<div class="clear"></div>
							</div>

							<div class="lanes" *ngIf="kanbanTypeSelected === 'status'">
								<div *ngFor="let state of adminData.scenarioSelected.steps" class="lane_block"   ngsRevealSet [ngsSelector]="'.lane_block'" [ngsRevealSet]="{origin:'bottom ', distance:'180px'}" [ngsInterval]="25">
									<div class=" lane_title">{{state.name}}</div>

									<lane type="{{state.id}}" id="{{state.id}}" laneTitle="{{state.id}}"
										  [typeTicket]="'epreuve'"
										  [tasks]="taskData.epreuveByWorkflowSelected | tasksFilter:'maxStep':state.id"
										  (onTicketEdit)="editTicket($event)"
										  (onAddSubTicket)="addSubTicket($event)"
										  (onTicketRemove)="removeTicket($event)"
										  (onTicketFocus)="focusTicket($event)"
										  (onTicketSelect)="selectTicket($event)"></lane>

									<div class="clear"></div>
								</div>
							</div>

							<div class="lanes lanes-user" *ngIf="kanbanTypeSelected === 'user'">
								<div *ngFor="let user of usersTask" class="lane_block">
									<div class=" lane_title user_lane_title">{{user}}</div>
									<lane type="{{user}}" id="{{user}}" laneTitle="{{user}}" [dragula]='kanbanTypeSelected'
										  [dragulaOptions]="options"
										  [dragulaModel]='task'
										  [typeTicket]="'ticket'"
										  [tasks]="taskData.taskDatas | tasksFilter:'assigneduser':user"
										  (onTicketEdit)="editTicket($event)"
										  (onAddSubTicket)="addSubTicket($event)"
										  (onTicketRemove)="removeTicket($event)"
										  (onTicketFocus)="focusTicket($event)"
										  (onTicketSelect)="selectTicket($event)"></lane>
									<div class="clear"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<!--<div class="commons_add_task" [class.is_visible]="showFab">
			<div class="commons_add_task" *ngIf="userData.isAdmin()">
				<button mat-fab ngsReveal (click)="addTicket();"><i class="material-icons">add</i></button>
			</div>-->

		</mat-tab>
	</mat-tab-group>

</div>
