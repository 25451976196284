import { Component, ViewEncapsulation }	from '@angular/core';
import { MatDialogRef }					from '@angular/material';
import { UserService }					from '../../services/user.service';


@Component({
	selector:		'connect-otp-modal',
	templateUrl:	'connectOTPModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./connectOTPModal.component.scss']
})


/**
 *
 */
export class ConnectOTPModalComponent
{

	public otpCode: any;
	public displayErrorOtp: boolean = false;

	 constructor(public dialogRef: MatDialogRef<ConnectOTPModalComponent, any>,
                 public userService: UserService)
    {
	}


	/**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandler(event: KeyboardEvent): void
	{
		if (event.key === "Enter")
		{
			this.checkOTP();
		}
	}


	/**
	 * 
	 */
	public checkOTP()
	{
		this.displayErrorOtp = false;

		this.userService.checkUserOTPCode(this.otpCode, (err, data) => 
		{
			if (data && data.logged === true)
			{
				this.dialogRef.close(true);
			}
			else
			{
				this.displayErrorOtp = true;
			}
		});
	}
	

	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 */
	public canValidCheckOTP()
	{
		return this.otpCode && this.otpCode.length === 6;
	}

}



