import { Component, OnInit, Input, OnChanges, DoCheck, EventEmitter }	from '@angular/core';
import { UserData }														from '../../providers/user-data';
import { WorkspaceData }														from '../../providers/workspace-data';
import { TaskData }														from '../../providers/task-data';


@Component({
	selector:	'app-state-stats',
	outputs:	['_onClick: onClick'],

	templateUrl:	'./state-stats.component.html',
	styleUrls:		['./state-stats.component.scss']
})


export class StateStatsComponent implements OnChanges, DoCheck, OnInit
{
	public _onClick: EventEmitter<any> = new EventEmitter<any>();

	@Input() task: any;

	public content:		any;
	public dateClass:	any;
	public fullDate:	any;
	public taskId:		any;
	public oSujet:		any;
	
	public endDate: Date;


	public moUserDataSubscription: any;

	constructor(public userData: UserData, public workspaceData: WorkspaceData, public taskData: TaskData)
	{
		this.content = '';


	}


	public ngOnChanges()
	{

	}


	public ngOnInit()
	{
		this.taskId 	= this.task.id;
		this.endDate 	= this.task.endDate;
		//this.task 	= this.taskData.getTaskById(this.taskId);
		const er 	= this.workspaceData.globalEmitter.subscribe((value: string) =>
		{
			//Evènement récupéré lorsqu'un nouveau classeur à été ajouté
			//On ajoute donc le dernier elément de la structure dans user data
			//dans les classeurs du composant
			if (value === 'majStatsEpr')
			{
				let	statsNode2 = this.workspaceData.statsNode2;
				if(statsNode2)
				{
					for (let statsNode of statsNode2)
					{
						if (this.taskId === statsNode.idTask)
						{
							this.endDate = new Date(statsNode.echeance);
						}
					}
				}
			}
		});

		this.taskData.taskDataEmitter.emit('task::update');

	}
	

	public ngDoCheck()
	{

	}


	public onClick(test: any)
	{
		this._onClick.emit(this.task);
	}
}
