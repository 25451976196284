import { Component, OnInit, Input } from '@angular/core';
import { UserData }				from '../../providers/user-data';
import { ChatData }				from '../../providers/chat-data';
import { User }				from '../../classes/user.class';
import { UserInfoComponent } from '../user-info/user-info.component';
import { ViewEncapsulation }			from '@angular/core';
import { Router }				from '@angular/router';


@Component({
	selector: 'app-user-name',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './user-name.component.html',
	styleUrls: ['./user-name.component.scss']
})

export class UserNameComponent implements OnInit
{

	@Input() userMail: string;
	@Input() showBubble: boolean = true;
	public outputContent: string;
	public oUser: any;
	public showMail = false;

	constructor(
		public router: Router,
		public userData: UserData,
		public chatData: ChatData
			) { }



	public ngOnInit()
	{
		this.outputContent = this.userMail;

		if (this.userMail)
		{
			this.oUser = this.userData.getUser(this.userMail);
		}


		if (this.oUser && this.oUser.infos && (this.oUser.infos.nom || this.oUser.infos.prenom))
		{
			this.outputContent = '';

			if (this.oUser.infos.prenom) this.outputContent += this.oUser.infos.prenom + ' ';
			if (this.oUser.infos.nom) this.outputContent += this.oUser.infos.nom;
		}
		else if(this.oUser && this.oUser.infos )
		{
			this.showMail = true;
		}


		else if (this.userMail === this.userData.getUserId())
		{
			this.outputContent = this.userData.getUsername();
		}

	}
	
	public onClick(event: any)
	{
		this.chatData.setSelectedRoomByMail(this.userMail);
		this.router.navigate(['/community']);

	}
}
