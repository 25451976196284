﻿import { CreationHttpService }				from './creationHttp.service';
import { Injectable }						from '@angular/core';
import { Http, Headers, URLSearchParams }	from '@angular/http';
import {Observable} from 'rxjs/Rx';

import { Matiere }	from '../../classes/matiere.class';
import { UserData } from '../../providers/user-data';


@Injectable()


/**
 *
 */
export class DataHttpService
{
	protected sUrl:				string;
	protected sUrlExtention:	string;
	protected sToken:			string;
	protected sParentToken:		string;
	protected jOptionToken:     any;


	/**
	 *
	 */
	constructor(protected http: Http, protected userData: UserData, urlExtention: string= '')
	{
		this.sUrlExtention	= urlExtention;
        this.sUrl 			= CreationHttpService.absolute(CreationHttpService.getAPIUrl(), this.sUrlExtention);
	}


	/**
	 *
	 */
	protected add(pxData: any, path: any[] = [], data: any = undefined): Observable<any>
	{
		let jData;

		if (data)
		{
			jData = data;
		}
		else
		{
			jData =
				{
					name: pxData.name,
					path: path
				};
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let credentials =
			{
				data:	jData,
				token:	this.userData.getTokenKey()
			};

		return this.http.post(this.getUrl() + 'create', JSON.stringify(credentials), { headers: headers });
	}


	protected edit(pxData: any, path: any[] = [], data: any = undefined): Observable<any>
	{
		let jData;

		if (data)
		{
			jData = data;
		}
		else
		{
			jData = 
				{
					name: pxData.name,
					path: path
				};
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		const credentials =
			{
				data:	jData,
				token:	this.userData.getTokenKey()
			};

		return this.http.post(this.getUrl() + 'create', JSON.stringify(credentials), { headers: headers });
	}


	/**
	 *
	 */
	protected get(id: string = '', idParent: string = ''): Observable<any>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		const params: URLSearchParams = new URLSearchParams();

		if (id && this.sToken)
		{
			params.set(this.sToken, id);
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (idParent && this.sParentToken)
		{
			params.set(this.sParentToken, idParent);
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (this.jOptionToken)
		{
			for (let key in this.jOptionToken)
			{
				var value: any = this.jOptionToken[key];
				params.set(key, this.jOptionToken[key]);
			}
		}


		return this.http.get(this.getUrl() + 'get', { headers: headers, search: params });
	}


	/**
	 *
	 */
	protected getById(data: any)
	{

	}


	/**
	 *
	 */
	protected getByForeignKey(data: any)
	{

	}

	protected getUrl()
	{
		const result = CreationHttpService.absolute(CreationHttpService.getAPIUrl(), this.sUrlExtention);
		return result;
	}


	/**
	 *
	 */
	protected delet(data: any)
	{

	}
}
