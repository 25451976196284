import { Injectable }	from '@angular/core';
import { Http }			from '@angular/http';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';
import { Session }				from '../../classes/session.class';

@Injectable()
export class SessionHttpService extends DataHttpService
{
	protected sToken:		string = 'idsession';
	protected sParentToken:	string = 'idetab';

	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('session'));
	}


    /**
     * Add new session in database from Session object given in parameter
	 * Parents ids are given by the path parameter
     */
	public addSession(session: any, parentPath: any[] = [])
	{
		session.path = parentPath;
        return this.add(null, [], session);
	}


    /**
     * Get all sessions in database relative to user account and parameters given:
     * 
     *  -cascade:   if false, return only Sessions objects, if true return Sessions object with childs 
     *              (examens and epreuves) in each objects
     *  -idSession: if this parameter is defined, return the Session object corresponding to the value id
     *  -idEtab:    if this parameter is defined (and if the parameter idSession is not), return the Sessions
     *              objects wich the etablissement parent matches the value id.
     * 
     * Return a list of Session objects
     */
	public getSessions(cascade: boolean, idSession: string, idEtab: string)
	{
		let cascadeStr		= cascade ? (cascade === true ? 'true' : 'false') : 'false';
		this.jOptionToken	= { "cascade": cascadeStr };

		return this.get(idSession, idEtab);
	}


}

