import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType, URLSearchParams }   from '@angular/http';
import {Observable} from 'rxjs/Rx';

import { Comment } 	from '../../classes/comment.class';
import { Note } 	from '../../classes/note.class';
import { File } 	from '../../classes/file.class';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';

import * as _ from 'underscore';

@Injectable()


export class FileHttpService extends DataHttpService
{

    /**
	 *
	 */
	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('file'));
	}

    /**
     *
     */
    public addFile(file: File): Observable<any>
    {
		let data = 
			{
				file: file.toJson()
			};

		// ---------------------------------------
		// Ajouter la vérification de l'extention
		// ---------------------------------------
		// Ajoute la vérif du poids
		// ---------------------------------------
		// Thumbnail
		// ---------------------------------------

        return this.add(undefined, undefined, data);
    }


    /**
     *
     */
    public getFiles(subjectId: string = undefined, fileId: string = undefined): Observable<any>
    {
		this.jOptionToken = 
			{
				idsubject:	subjectId,
				idfile:		fileId
			};

        return this.get();
    }

    /**
     *
     * @param fileId
     */
    public removeFile(file: File): Observable<any>
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

        let params: URLSearchParams = new URLSearchParams();
        params.set('files', JSON.stringify(file.toJson()));

        return this.http.get(this.getUrl() + 'delete', { headers: headers, search: params });
    }


    public updateFile(file: File, datas: any)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let data = 
		{
            file:	file.toJson(),
            datas:	datas
        };

		let credentials = 
		{
			data:	data
		};

        return this.http.post(this.getUrl() + 'update', JSON.stringify(credentials), { headers: headers });
    }


    public addFileNote(file: File, note: Note)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let datas = 
		{
            file: file.toJson(),
            note: note.toJson()
        };

		let credentials = 
		{
			data:	datas
		};

        return this.http.post(this.getUrl() + 'addNote', JSON.stringify(credentials), { headers: headers });
    }


    public addFileVersion(file: any)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let datas =
			{
				file: file.toJson()
			};

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let credentials =
			{
				data: datas
			};

        return this.http.post(this.getUrl() + 'addVersion', JSON.stringify(credentials), { headers: headers });
    }


    public downloadFile(file: File, typeFile: string = 'file')
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        const headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		const fileJson: any =
		{
			idsubject:	file.idSubject,
			id:			file.id
		};

        const params: URLSearchParams = new URLSearchParams();
        params.set('file', JSON.stringify(fileJson));
        params.set('type', typeFile);

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        const options = new RequestOptions(
		{
			search:				params,
			headers:			headers,
			withCredentials:	true,
			responseType:		ResponseContentType.Blob
		});

        return this.http.get(this.getUrl() + 'download', options);
    }

    /**
     * Téléchargement d'un fichier à partir de son identifiant dans le système de stockage
     * du serveur
     * @param path
     */
	public downloadFromId(path: any)
	{
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let params: URLSearchParams = new URLSearchParams();
		params.set('path', path);

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let options = new RequestOptions({
			search:				params,
			headers:			headers,
			withCredentials:	true,
			responseType:		ResponseContentType.Blob
		});

		return this.http.get(this.getUrl() + 'downloadFromId', options);
	}

    /**
     * Suppression d'un fichier à partir de son identifiant dans le système de stockage
     * du serveur
     * @param path
     */
	public deleteFromId(path: any)
	{
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let params: URLSearchParams = new URLSearchParams();
		params.set('path', path);

		let options = new RequestOptions(
			{
				search:				params,
				headers:			headers,
				withCredentials:	true,
				responseType:		ResponseContentType.Blob
			});

		return this.http.get(this.getUrl() + 'deleteFromId', options);
	}


    public downloadFiles(files: File[], password: string = null)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let filesJson = _.map(files, function(file: any)
			{
				return file.toJson();
			});

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let params: URLSearchParams = new URLSearchParams();
        params.set('files', JSON.stringify(filesJson));

        if (password)
        {
            params.set('password', password);
        }

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let options = new RequestOptions({search: params, headers: headers, responseType: ResponseContentType.Blob });

        return this.http.get(this.getUrl() + 'downloadFiles', options);
    }
}
