/**
 * 
 * @export
 * @enum {number}
 */
export enum OrderType 
{
    ASC		= 0,
    DESC	= 1
}


/**
 * 
 */
export class Filter
{
    constructor(
        private _id:            string,
        private _name:          string,
        private _default:       boolean,
        private _groupsFilter:  any[]
    ){ }


    get id()            { return this._id; }
    get name()          { return this._name; }
    get default()       { return this._default; }
    get groupsFilter()	{ return this._groupsFilter; }

    set id(id)                      { this._id              = id; }
    set name(name)                  { this._name            = name; }
    set default(defaultVal)         { this._default         = defaultVal; }
    set groupsFilter(groupsFilter)  { this._groupsFilter    = groupsFilter; }
}
