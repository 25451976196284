import { Message }	from '../classes/message.class';
import { User }		from '../classes/user.class';
import { IRoom }    from '../models/room.model';
import * as _		from 'underscore';


/**
 * 
 * @export
 * @class Piece
* */
export class Piece implements IRoom
{


    /**
     * 
     * 
     * @static
     * @param {any} jsonObj
     * @returns {Piece}
     * 
     * @memberOf Piece
     */
    public static fromJsonObject(jsonObj: any): Piece 
    {

        let result = new Piece();

        result.id					= jsonObj.uuid					? jsonObj.uuid					: '';
        result.type					= jsonObj.isPublic				? 'public'						: 'group';
        result.name					= jsonObj.libelle				? jsonObj.libelle				: '';
        result.creationDate			= jsonObj.creationDate			? jsonObj.creationDate			: '';
        result.isPublic				= jsonObj.isPublic				? jsonObj.isPublic				: '';
        result.isReadOnly			= jsonObj.isReadOnly			? jsonObj.isReadOnly			: '';
        result.lastId				= jsonObj.lastId				? jsonObj.lastId				: '';
        result.lastDate				= jsonObj.lastDate				? jsonObj.lastDate				: '';
        result.firstDate			= jsonObj.FirstDate				? jsonObj.FirstDate				: '';
        result.lastIdReadedByMe		= jsonObj.lastIdReadedByMe		? jsonObj.lastIdReadedByMe		: '';
        result.lastIdReadedByHim	= jsonObj.lastIdReadedByHim		? jsonObj.lastIdReadedByHim		: '';
        result.lastIdReceivedByHim	= jsonObj.lastIdReceivedByHim	? jsonObj.lastIdReceivedByHim	: '';
        result.lastIdReceivedByMe	= jsonObj.lastIdReceivedByMe	? jsonObj.lastIdReceivedByMe	: '';
        result.firstId				= jsonObj.firstId				? jsonObj.firstId				: '';
        result.counter				= jsonObj.counter				? jsonObj.counter				: '';

        return result;
    }


    /**
     * Creates an instance of Piece.
     * 
     * @param {number} [_id=0]
     * @param {User[]} [_users=[]]
     * @param {Message[]} [_messages=[]]
     * @param {string} [_type='']
     * @param {string} [_name='']
     * @param {string} [_description='']
     * @param {boolean} [_state=false]
     * 
     * @memberOf Piece
     */
    constructor(
        public _id:						string      = '',
        public _users:					any[]		= [],
        public _messages:				Message[]   = [],
        public _type:					string      = '',
        public _name:					string      = '',
        public _displayName:			string      = '',
        public _description:			string      = '',
        public _isPublic:				boolean     = false,
        public _isReadOnly:				boolean     = false,
        public _owner:					string      = '',
        public _state:					boolean     = false,
        public _lastId:					string      = '',
		public _firstId:				string      = '',
		public _firstDate:				string      = '',
		public _lastDate:				string      = '',
        public _lastIdReadedByMe:		string      = '',
        public _lastIdReadedByHim:		string      = '',
        public _lastIdReceivedByHim:	string      = '',
        public _lastIdReceivedByMe:		string      = '',
		public _counter:				number		= 0,
        public _creationDate:			string      = '',
    ){ }


//        result.lastIdReadedByMe		= jsonObj.lastIdReadedByMe		? jsonObj.lastIdReadedByMe		: '';
//        result.lastIdReadedByHim	= jsonObj.lastIdReadedByHim		? jsonObj.lastIdReadedByHim		: '';
//        result.lastIdReceivedByHim	= jsonObj.lastIdReceivedByHim	? jsonObj.lastIdReceivedByHim	: '';
//        result.lastIdReceivedByMe	= jsonObj.lastIdReceivedByMe	? jsonObj.lastIdReceivedByMe	: '';

    get id()					{ return this._id; }
    get users()					{ return this._users; }
    get type()					{ return this._type; }
    get name()					{ return this._name; }
    get creationDate()			{ return this._creationDate; }
    get isPublic()				{ return this._isPublic; }
    get isReadOnly()			{ return this._isReadOnly; }
    get owner(): any			{ return this.owner; }
    get lastId()				{ return this._lastId; }
    get firstId()				{ return this._firstId; }
    get lastDate()				{ return this._lastDate; }
    get firstDate()				{ return this._firstDate; }
    get lastIdReadedByMe()		{ return this._lastIdReadedByMe; }
    get lastIdReadedByHim()		{ return this._lastIdReadedByHim; }
    get lastIdReceivedByHim()	{ return this._lastIdReceivedByHim; }
    get lastIdReceivedByMe()	{ return this._lastIdReceivedByMe; }
    get displayName()			{ return this._displayName; }
    get description()			{ return this._description; }
    get state()					{ return this._state; }
    get counter()				{ return this._counter; }
    get messages()				{ return this._messages; }


    /**
     * 
     */
	getMessages()      
	{

		let arrayReturn = [];
		let arrayKey	= [];

		// récupération des ids
		for (let key in this._messages)
		{
			arrayKey.push(key);
        }
        
		let key: any;
		// on boucle sur le tableau des id pr sortir un tableau de message templatefriendly
		for (key of arrayKey)
		{
			arrayReturn.push(this._messages[key]);
        }
        
		arrayReturn = _.sortBy(arrayReturn, 'date');

		return arrayReturn;
	}


    set id(id)										{ this._id					= id; }
    set users(users)								{ this._users				= users; }
    set messages(messages)							{ this._messages			= messages; }
    set type(type)									{ this._type				= type; }
    set creationDate(creationDate)					{ this._creationDate		= creationDate; }
    set name(name)									{ this._name				= name; }
    set isPublic(isPublic)							{ this._isPublic			= isPublic; }
    set isReadOnly(isReadOnly)						{ this._isReadOnly			= isReadOnly; }
    set owner(owner)								{ this._owner				= owner; }
    set firstId(firstId)							{ this._firstId				= firstId; }
    set firstDate(firstDate)						{ this._firstDate			= firstDate; }
    set lastId(lastId)								{ this._lastId				= lastId; }
    set lastDate(lastDate)							{ this._lastDate			= lastDate; }
    set lastIdReadedByMe(lastIdReadedByMe)			{ this._lastIdReadedByMe	= lastIdReadedByMe; }
    set lastIdReadedByHim(lastIdReadedByHim)		{ this._lastIdReadedByHim	= lastIdReadedByHim; }
    set lastIdReceivedByHim(lastIdReceivedByHim)	{ this._lastIdReceivedByHim	= lastIdReceivedByHim; }
    set lastIdReceivedByMe(lastIdReceivedByMe)		{ this._lastIdReceivedByMe	= lastIdReceivedByMe; }
    set displayName(displayName)					{ this._displayName			= displayName; }
    set description(description)					{ this._description			= description; }
    set state(state)								{ this._state				= state; }
    set counter(counter)							{ this._counter				= counter; }


    /**
     * 
     * @param message 
     * @param id 
     */
	setMessageById(message: Message, id: any)			{ this._messages[id]	= message; }


    /**
     * 
     * @param {string} [order='ASC']
     * @returns
     *
     * @memberOf Piece
     */
	public getMessagesSorted(order = 'ASC')
	{
		let oReturn = null;		
		
		// ---------------------------------------
		// ToComment
		// ---------------------------------------	
		if (order === 'ASC' || order === 'DESC')
		{
			let messagesSorted	= _.sortBy(this._messages, function (message: any) { return message.date.valueOf(); });
			oReturn				= order === 'ASC' ? messagesSorted : messagesSorted.reverse();
		}

		return oReturn;
	}


    /**
     * 
     * @returns
     * 
     * @memberOf Piece
     */
    public getLastMessage()
    {
        return _.max(this._messages, function(message: any){ return message.date.valueOf(); });
    }
	
	
	/**
	 * Ajout d'un message à la piece
	 */
	public addMessage(message: Message, id: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this._messages[id] = message;
	}
    
    
	/**
	 * Start des messages
	 */
	public initMessage(message: [Message])
	{
	}


    /**
     * 
     * @param userJson Add new user to current piece
     */
    public addUserFromJson(userJson: any)
    {
        let newUser = new User();
        newUser.id	= userJson.mail;

        this.users.push(newUser);
    }


    /**
     * 
     */
    public toJson()
    {
        let usersJson = [];
        for (let user of this.users)
        {
            usersJson.push(user.toJson());
        }

        let result = {
            uuid:					this.id,
            users:					usersJson,
            messages:				this.messages,
            isPublic:				this.isPublic,
			creationDate:			this.creationDate,
			isReadOnly:				this.isReadOnly,
			owner:					this.owner,
			lastId:					this.lastId,
			lastDate:				this.lastDate,
			firstDate:				this.firstDate,
			firstId:				this.firstId,
			lastIdReadedByMe:		this.lastIdReadedByMe,
			lastIdReadedByHim:		this.lastIdReadedByHim,
			lastIdReceivedByHim:	this.lastIdReceivedByHim,
			lastIdReceivedByMe:		this.lastIdReceivedByMe,
            libelle:				this.name,
            description:			this.description,
            state:					this.state,
            counter:				this.counter
        };
		
		
        return result;
    }

}

