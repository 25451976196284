import { Component, ViewEncapsulation, OnInit, OnDestroy }	from '@angular/core';

import { MatDialogRef } from '@angular/material';
import { UserService }	from '../../services/user.service';


@Component({
	selector:		'forget-password-modal',
	templateUrl:	'forgetPasswordModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./forgetPasswordModal.component.scss']
})


export class ForgetPasswordModalComponent
{
	public usermail:		any;
	public displayError:	boolean = false;

	constructor(public dialogRef: MatDialogRef<ForgetPasswordModalComponent, any>,
				public userService: UserService)
	{

	}


	/**
	 * 
	 */
	public canValidReset()
	{
		let result = true;

		if (!this.usermail || this.usermail === '')
		{
			result = false;
		}

		return result;
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(undefined);
	}
	

	/**
	 * 
	 */
	public sendPasswordReset()
	{
		this.displayError = false;
		
		this.userService.sendPasswordReset(this.usermail).then(res =>
		{
			this.dialogRef.close(this.usermail);
		}).catch(err =>
		{
			this.displayError = true;
		});
	}
}
