import { Component, SecurityContext, ViewEncapsulation, AfterViewChecked, ViewChild, ElementRef, Input, OnInit, OnDestroy }	from '@angular/core';
import { MatDialog }            from '@angular/material';
import {TranslateService} 		from '@ngx-translate/core';
import { DomSanitizer }		    from '@angular/platform-browser';

import { Session }              from '../../classes/session.class';
import { Examen }               from '../../classes/examen.class';
import { Matiere }              from '../../classes/matiere.class';
import { Sujet }                from '../../classes/sujet.class';
import { Task }                 from '../../classes/task.class';
import { File as ExatiqueFile }	from '../../classes/file.class';

import { FileService }      from '../../services/file.service';
import { MessagesService }  from '../../services/messages.service';

import { UserData }			from '../../providers/user-data';
import { WorkspaceData }    from '../../providers/workspace-data';
import { TaskData }         from '../../providers/task-data';
import { SavedData }		from '../../providers/saved-data';

import * as _ from 'underscore';
import * as htmlEncode from 'js-htmlencode';


/**
 * Composant générique permet de gérer des commentaires appartenant à plusieurs
 * entités différentes (session, examen, pereuves, sujet, fichier,...)
 * Permet la récupération et l'affichage des commnetiare ainsi que l'ajout d'un nouveau commentaire
 */
@Component({
	selector:		'comments-component',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./comments.component.scss'],
	templateUrl:	'comments.component.html'
})


/**
 *
 */
export class CommentsComponent implements OnInit, OnDestroy, AfterViewChecked
{

    /**
     * Type de l'élément concerné par les commentaire:
     *  -session
     *  -examen
     *  -epreuve
     *  -sujet
     *  -fichier
     */
    public element:		any		= null;
    public elementId:   any     = null;
    public typeComment:	string	= 'comment';
	public libelle:		string	= '';

	public idUser:			string	= null;
	public creationDate:	Date	= new Date();

    public moHistorySubscription:	any;
    public comment:					string = '';

    public mustScrollBottom: boolean = false;
    public canAddNewMessage: boolean = true;

    public parentElement: any;

    @Input() public showButton = true;
    @ViewChild('scrollMessages') private myScrollContainer: ElementRef<any>;


    /**
     * Identifiant de l'élément dont on souhaite génrer les commentaires
     */
	@Input()
	set type(type: string)
	{
		this.typeComment = type;
		if (this.typeComment === 'historic')
		{
			this.translate.get('comments.historic').subscribe((historic: string) =>
			{
				this.libelle = historic;
			});
		}
		else
		{
			if (this.typeComment === 'historic-comment')
			{
				this.translate.get('comments.historicComments').subscribe((historicComments: string) =>
				{
					this.libelle = historicComments;
				});
			}
			else
			{
				this.translate.get('comments.comments').subscribe((comments: string) =>
				{
					this.libelle = comments;
				});
			}
		}
	}


	/**
	 *
	 */
	@Input()
	set idElement(idElement: string)
	{
		if (idElement)
		{
			this.elementId		= idElement;
			let allElements		= this.workspaceData.getHistoric(idElement);

			if (allElements && allElements.comments)
			{
				allElements.comments	= _.filter(allElements.comments, (comment: any) => { return this.hasContent(comment); });
				this.element			= allElements;
			}
		}
	}


    @Input()
    set parent(parent: string)
	{
        this.parentElement = parent;
    }


    @Input()
    set canAddMessage(canAddMessage: boolean)
    {
        this.canAddNewMessage = canAddMessage;
    }


    constructor(
        public fileService:		FileService,
        public messagesService: MessagesService,
        public translate:       TranslateService,
        public sanitizer:       DomSanitizer,
        public userData:		UserData,
        public savedData:		SavedData,
        public workspaceData:   WorkspaceData,
        public taskData:        TaskData,
        public dialog:			MatDialog)
    {
        this.comment = '';
    }


	/**
	 *
	 */
    public ngOnInit()
	{
		this.moHistorySubscription = this.workspaceData.globalEmitter.subscribe((values: any) =>
		{
			if (values[0] === 'messages::get' && values[1] === this.elementId)
			{
                let allElements = this.workspaceData.getHistoric(values[1]);
                if (allElements && allElements.comments)
                {
                    allElements.comments	= _.filter(allElements.comments, (comment: any) => { return this.hasContent(comment); });
                    this.element			= allElements;
                }

                this.mustScrollBottom = true;
			}
        });
        this.mustScrollBottom = true;
    }


	/**
	 *
	 */
    public ngOnDestroy()
	{
        if (this.moHistorySubscription)
        {
            this.moHistorySubscription.unsubscribe();
        }
	}


    /**
     *
     */
	ngAfterViewChecked()
	{
		if (this.mustScrollBottom === true)
		{
			this.mustScrollBottom = false;
			this.scrollToBottom();
		}
	}


    /**
     *
     */
    scrollToBottom()
	{
        try
		{
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        }
		catch(err)
		{ }
    }


	/**
	 *
	 * @param comment
	 */
    public hasContent(comment: any)
    {
        let result = false;

		if (comment.message || comment.soustitre || comment.soustitrePath || comment.content)
		{
			result = true;
		}

        return result;
    }


	/**
	 *
	 * @param idFile
	 */
	public canDownloadFile(idFile: any)
	{
		let result		= false;
		let file: any	= this.workspaceData.getTreeElement(idFile);

		if (file && file.deleted === false)
		{
			result = true;
		}

		return result;
	}


	/**
	 *
	 */
    public downloadFile(path: any, idFile: any, name: string, type: string)
    {
        let file: any = this.workspaceData.getTreeElement(idFile);

        if (file && file.deleted === false)
        {
            this.fileService.downloadFromId(path, file.id, name, type);
        }
    }


    /**
     *
     */
    public canValidateComment()
    {
        let result				= true;
        let commentWithoutSpace = this.comment.replace(/\s+/g, '');

        if (!commentWithoutSpace || commentWithoutSpace === '')
        {
            result = false;
        }

        return result;
    }


    /**
     * Affichage de la modal d'ajout de commentaire
     */
	public addNewComment()
	{
        if (this.canValidateComment() === true)
        {
            let type;

            let value = this.workspaceData.getTreeElement(this.elementId);
            //Si value est undefined, il s'agit peut être de l'identifiant d'une tache
            if (value === undefined)
            {
                value = this.taskData.getTaskById(this.elementId);
            }

            let baseChannelNode		= '*soft::' + this.userData.account + '::creation::tree::';
            let baseChannelSubject	= '*soft::' + this.userData.account + '::creation::suj::';
            let baseChannelTask		= '*soft::' + this.userData.account + '::creation::task::';

            let notifiers = [];

            if (value)
            {
                if (value instanceof Session)
                {
                    type = 'session';
                    if (this.workspaceData.selectedEtablissement)
                    {
                        notifiers.push(baseChannelNode + this.workspaceData.selectedEtablissement.id);
                    }
                }

				if (value instanceof Examen)
				{
					type = 'examen';
					if (this.workspaceData.selectedEtablissement)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedEtablissement.id);
					}
					if (this.workspaceData.selectedSession)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedSession.id);
					}
				}

				if (value instanceof Matiere)
				{
					type = 'matiere';
					if (this.workspaceData.selectedEtablissement)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedEtablissement.id);
					}
					if (this.workspaceData.selectedSession)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedSession.id);
					}
					if (this.workspaceData.selectedExamen)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedExamen.id);
					}
				}

				if (value instanceof Sujet)
				{
					if (this.workspaceData.selectedEtablissement)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedEtablissement.id);
					}
					if (this.workspaceData.selectedSession)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedSession.id);
					}
					if (this.workspaceData.selectedExamen)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedExamen.id);
					}
					if (this.workspaceData.selectedMatiere)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedMatiere.id);
					}
					if (this.workspaceData.selectedSujet)
					{
						notifiers.push(baseChannelSubject + this.workspaceData.selectedSujet.id);
					}

					//On récupère les taches affiliée au sujet concerné pour les ajouter dans les taches à notifier
					let tasksFiltered = _.filter(this.workspaceData.globalTask, (task: any) =>
					{
						return task.idSujet === value.id;
					});

					for (let task of tasksFiltered)
                    {
                        notifiers.push(baseChannelTask + task.id);
                    }
                    type = 'subjects';
                }

				if (value instanceof ExatiqueFile)
				{
					if (this.workspaceData.selectedEtablissement)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedEtablissement.id);
					}
					if (this.workspaceData.selectedSession)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedSession.id);
					}
					if (this.workspaceData.selectedExamen)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedExamen.id);
					}
					if (this.workspaceData.selectedMatiere)
					{
						notifiers.push(baseChannelNode + this.workspaceData.selectedMatiere.id);
					}
					if (this.workspaceData.selectedSujet)
					{
						notifiers.push(baseChannelSubject + this.workspaceData.selectedSujet.id);

						// On récupère les taches affiliée au sujet concerné pour les ajouter dans les taches à notifier
						let tasksFiltered = _.filter(this.workspaceData.globalTask, (task: any) =>
						{
							return task.idSujet === this.workspaceData.selectedSujet.id;
						});

						for (let task of tasksFiltered)
						{
							notifiers.push(baseChannelTask + task.id);
						}
					}
					type = 'files';
				}

				if (value instanceof Task)
				{
					if (this.parentElement && this.parentElement.id)
					{
						notifiers.push(baseChannelSubject + this.parentElement.id);
					}
					else
					{
						if (this.workspaceData.selectedSujet)
						{
							notifiers.push(baseChannelSubject + this.workspaceData.selectedSujet.id);
						}
					}

					notifiers.push(baseChannelTask + value.id);

					type = 'tasks';
				}

				this.comment = htmlEncode.htmlEncode(this.comment);

				if (type)
				{
					let datasComment: any =
					{
						action:		'create',
						comment:	this.comment,
						user:		this.userData.mail
					}
					this.messagesService.sendMessage(type, this.elementId, 'C', datasComment, notifiers);

					this.comment = '';
				}
			}
		}
	}


	public decodeHTML(html:any)
	{
		var txt 		= document.createElement('textarea');
		txt.innerHTML 	= html;

		return txt.value;
	}

}
