import { Component, OnInit, ViewEncapsulation }	from '@angular/core';
import { UserData }				from '../../../providers/user-data';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';


@Component({
	selector: 'app-notification-modal',
	templateUrl: './notification-modal.component.html',
	styleUrls: ['./notification-modal.component.scss'],
	encapsulation: ViewEncapsulation.None,

})


/**
 * 
 */
export class NotificationModalComponent implements OnInit
{
	poHorizontalPosition: MatSnackBarHorizontalPosition = 'end';
	poVerticalPosition: MatSnackBarVerticalPosition		= 'top';


	actionButtonLabel:	string	= '';
	action:				boolean = true;
	setAutoHide:		boolean = true;
	autoHide:			number	= 5000;
	addExtraClass:		boolean = true;


	/**
	 * 
	 * @param userData 
	 * @param snackBar 
	 */
	constructor(public userData: UserData, public snackBar: MatSnackBar) { }


	/**
	 * 
	 */
	public ngOnInit()
	{
		this.userData.notifEmitter.subscribe((value: any[]) => this.control(value[0], value[1]),
			(error: Error) => console.log(error),
			() => console.log('done'));
	}


	/**
	 * 
	 * @param psMessage 
	 * @param psAction 
	 */
	public control(psMessage: any, psAction: any)
	{
		this.openSnackBar(psMessage, psAction);
	}


	/**
	 * 
	 * @param message 
	 * @param action 
	 */
	private openSnackBar(message: string, action: string)
	{
		let config = new MatSnackBarConfig();

		config.verticalPosition		= this.poVerticalPosition;
		config.horizontalPosition	= this.poHorizontalPosition;
		config.duration				= this.setAutoHide ? this.autoHide : 0;
//		config.extraClasses			= this.addExtraClass ? ['exa_snack'] : undefined;

		this.snackBar.open(message, action, config);
	}
}
