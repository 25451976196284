<form (ngSubmit)="validate()" [formGroup]="moFormGroup" class="dialog_form">
    <div class="dialog_grid_container nomenu">
        <div class="dialog_header">
			<div class="dialog_header_title">
				{{textHeader}}
			</div>
			<div class="dialog_header_close">
				<div class="dialog_header_close_icon" (click)='cancel()'><mat-icon>close</mat-icon></div>
			</div>
		</div>

        <div class="dialog_content">

			<div *ngIf="currentState === 'review'">

				<div>{{ 'validateTask.optionTitle' | translate }}</div>

				<mat-radio-group (ngModelChange)="onChangeAction($event)" [ngModel]="nextAction" class="validate-task-radio-group" [ngModelOptions]="{standalone: true}">
					<mat-radio-button *ngIf="nameNextStep" value="1">{{ 'validateTask.optionAction1' | translate }} ({{nameNextStep}})</mat-radio-button>
					<mat-radio-button value="2">{{ 'validateTask.optionAction2' | translate }}</mat-radio-button>
					<mat-radio-button *ngIf="namePreviousStep" value="3">{{ 'validateTask.optionAction3' | translate }} ({{namePreviousStep}})</mat-radio-button>
					<mat-radio-button value="4">
						<span *ngIf="nameNextStep">{{ 'validateTask.optionAction4' | translate }}</span>
						<span *ngIf="!nameNextStep">{{ 'validateTask.optionAction5' | translate }}</span>
					</mat-radio-button>
				</mat-radio-group>
			</div>

            <mat-form-field>
				<textarea rows="3"  maxLength="250" matInput placeholder="{{ 'validateTask.commentTitle' | translate }}" [(ngModel)]="currentComment.message"
						  formControlName="message"></textarea>
			</mat-form-field>
			<div class="clear"></div>

			<div *ngIf="canSendMail()">
				<mat-checkbox [(ngModel)]="sendEmail" formControlName="sendEmail">{{ 'validateTask.sendMail' | translate }}</mat-checkbox>
			</div>
        </div>

        <div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button mat-raised-button type="button" (click)='cancel()'>Annuler</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button mat-raised-button *ngIf='textValidationButton' color="primary" type="submit">{{textValidationButton}}</button>
			</div>
		</div>
    </div>
</form>
