import { Component }			from './component';
import { Member }			from './member.class';

import * as _ from 'underscore';


/**
 *
 * @export
 * @class Etablissement
 */
export class Page extends Component
{

	public _visible: boolean = true;

	series:		Member;
	date:		Member;
	start:		Member;
	consigne:	Member;
	bareme:		Member;
	coef:		Member;
	duration:	Member;
	intro:		Member;
	questions:	Member;

	session:	any;
	examen:		any;
	epreuve:	any;


    /**
     * Creates an instance of Session.
     *
     * @param {string} [_name='']
     * @param {boolean} [_show=false]
     * @param {boolean} [_selected=false]
     * @param {Examen[]} [_examens=[]]
     *
     * @memberOf Session
     */
	constructor()
	{
		super();
		
		// ---------------------------------------
		// ID
		// ---------------------------------------
		this._id.msName			= 'uuid';
		this._id.msType			= 'input';
		this._id.msNgModel		= 'idPage';
		this._id.mbRequired		= true;
		this._id.mbAutoComplete = false;
		this._id.mbHidden		= true;
		
		
		// ---------------------------------------
		// Nom
		// ---------------------------------------
		this._name.msName			= 'Nom';
		this._name.msType			= 'input';
		this._name.msId				= 'etab_form_input_name';
		this._name.msClass			= 'etab_form_input_name';
		this._name.msNgModel		= 'nomPage';
		this._name.mbRequired		= true;
		this._name.msPlaceholder	= 'Nom page';
		this._name.mbAutoComplete	= false;
		this._name.mbHidden			= false;

						
		// ---------------------------------------
		// Description
		// ---------------------------------------
		this._description.msName			= 'Description';
		this._description.msType			= 'textarea';
		this._description.mbRequired		= true;
		this._description.mbAutoComplete	= false;
		this._description.mbHidden			= false;
		this._description.msId				= 'etab_form_input_desc';
		this._description.msNgModel			= 'descriptionModel';

	}


	/**
     * Création d'un objet Etablissement en fonction d'un objet json donné
     * @static
     * @param {any} jsonObj
     * @returns {Etablissement}
     *
     * @memberOf Etablissement
     */
	public static fromJsonObject(jsonObj: any): Page
	{
		// Initialisation de l'identifiant et du nom
		let oEtablissement = new Page();

		oEtablissement.id	= jsonObj.id 	|| jsonObj.uuid 	|| 0;
		oEtablissement.name = jsonObj.name 	|| jsonObj.libelle 	|| '';

		return oEtablissement;
	}


	/**
	 * convertion d'un objet Etablissement au format Json
	 */
	public toJson()
	{
		let result: any = 
		{
			id:	this.id,
			datas: {}
		};

		return result;
	}
}
