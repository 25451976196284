<mat-dialog id="connection_application_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Sélection du compte
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

		<label>Votre compte utilisateur est lié à plusieurs comptes existants. Veuillez sélectionner le compte sur lequel vous souhaitez vous connecter:</label>
		<div class="clear"></div>

		<mat-form-field>
			<mat-label>Compte</mat-label>
			<mat-select [(ngModel)]="selectedAccount">
				<mat-option *ngFor="let account of accounts" [value]="account.name">
					{{account.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

    </div>
	<div class="dialog_footer">
		<button class="cancel_button" type="button" mat-raised-button color="" (click)="cancel()">Annuler</button>
		<button class="validate_button" mat-raised-button color="accent" (click)="validate()">Valider</button>
	</div>
</mat-dialog>
