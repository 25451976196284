import { Injectable }	from '@angular/core';
import { Http }			from '@angular/http';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }		from './dataHttp.service';
import { Matiere }				from '../../classes/matiere.class';
import { UserData }				from '../../providers/user-data';

@Injectable()


/**
 * 
 */
export class EpreuveHttpService extends DataHttpService
{
	protected sToken:			string = 'idepreuve';
	protected sParentToken:		string = 'idexamen';
	
	
	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('epreuve'));
	}


    /**
     * add new epreuve in database from Matiere object given in parameter
     */
	public addEpreuve(epreuve: any, parentPath: any[] = [])
	{
		epreuve.dateDebut	= epreuve.redactionStart;
		epreuve.dateFin		= epreuve.redactionEnd;
		epreuve.path		= parentPath;

		return this.add(null, parentPath, epreuve);
	}


    /**
     * Get all epreuves in database relative to user account and parameters given:
     * 
     *  -idEpreuve: if this parameter is defined, return the Matiere object corresponding to the value id
     *  -idExamen:  if this parameter is defined (and if the parameter idEpreuve is not), return the Matiere
     *              objects wich the examen parent matches the value id.
     * 
     * Return a list of Matiere objects
     */
	public getEpreuves(idEpreuve: string, idExamen: string)
	{
		return this.get(idEpreuve, idExamen);
	}
}

