// Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { EtablissementHttpService } from './http/etablissementHttp.service';
import { Etablissement }			from '../classes/etablissement.class';
import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData }					from '../providers/user-data';

import * as _ from 'underscore';

@Injectable()


/**
 *
 */
export class EtablissementService
{


	private socket: any = undefined;
	/**
	 *
	 */


	constructor(private etablissementHttpService:	EtablissementHttpService,
				public	socketService:				SocketService,
				private userData:					UserData)
	{
		this.socket = socketService.getSocketConnection();
	}


    /**
     * Add new etablissement in database from Etablissement object given in parameter
     */
	public addEtablissement(dataEtab: Etablissement)
	{
		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args':
						{
							type:	'etablissement',
							node:	dataEtab.toJson()
						}
					};
				socket.emit('create', data);
			}
		});
	}


    /**
     * Update etablissement in database from Etablissement object given in parameter
     */
	public editEtablissement(dataEtab: Etablissement)
	{
		return this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args': 
							{
								type: 'etablissement',
								node: dataEtab.toJson()
							}
					};
				socket.emit('update', data);
			}
		});
	}


    /**
     * Get all etablissements in database from the user account
     * Return a list of Etablissement object
     */
	public getEtablissementsAvailable()
	{
		if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
		{
			this.socketService.on('read', this._onRessourcesReturned.bind(this));

			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					let data: ISocketData =
					{
						'iam':	'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd':	'ressources',
						'args': {}
					};
					socket.emit('read', data);
				}
			});
		}
		else
		{
			let tasksAvailable	= this.userData.getTasksHomeForMe();
			let sUserId			= this.userData.getUserId();
			let eSocketWhat: SocketWhat.request;
			let arrayEpreuves = _.map(tasksAvailable, (task: any) => { return task.idepreuve; });

			//On effectue un appel socket pour récupérer l'épreuve associé à la tache
			//ainsi que tous ses parents
			if (sUserId && arrayEpreuves.length > 0)
			{
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						this.socket = socket;

						let data: ISocketData = 
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'node.info',
								'args':
								{
									nodeOption:	'wp',
									nodes:		arrayEpreuves
								}
							};

						socket.emit('read', data);
					}
				});
			}
		}
	}


	/**
	 * Suppression d'un noeud du système et de le'ensemble des noeuds enfants, des sujets, des taches et des fichiers
	 * @param element
	 */
	public removeElement(element: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				let data: ISocketData = 
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args':
						{
							nodeId: element.id
						}
					};

				socket.emit('delete', data);
			}
		});
	}

	/**
	 * Fonction appelée automatiquement à la récupération de ressources
	 */
	private _onRessourcesReturned(datas: any)
	{

		if (datas.cmd && datas.cmd === 'ressources')
		{
			if (datas.args && datas.args.data && datas.args.data.ressources)
			{
				let nodes = _.keys(datas.args.data.ressources);
				this.userData.setRessources(nodes);

				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'node.info',
							'args':
							{
								nodes:		nodes,
								nodeOption: 'wc',
								depth:		-1
							}
							};
						socket.emit('read', data);
					}
				});
			}
		}
	}
}

