import { Component, OnInit, Input, OnChanges, DoCheck } from '@angular/core';

@Component({
	selector: 		'app-date-user-friendly',
	templateUrl: 	'./date-user-friendly.component.html',
	styleUrls: 		['./date-user-friendly.component.scss']
})


/**
 * 
 */
export class DateUserFriendlyComponent implements OnChanges, DoCheck
{

	@Input() date:		any;
	@Input() log:		boolean = false;
	@Input() noFuture:	boolean = false;
	
	public content:		any;
	public dateClass:	any;
	public fullDate:	any;
	
	public noToolTip = false;

	constructor() { this.content = ''; }


	/**
	 * 
	 */
	public ngOnChanges()
	{
	}


	/**
	 * 
	 */
	public ngDoCheck()
	{
		let oDate;

		if (!this.date)
		{
			this.content	= 'Aucune';
			this.dateClass	= 'futur';
			this.noToolTip	= true;
		}
		else
		{
			let delta = Math.round((+Date.now() - this.date) / 1000);

			if (isNaN(delta ))
			{
				delta = Math.round((+Date.now() - new Date(this.date).getTime()) / 1000);
			}

			let daysName = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

			let minute	= 60,
				hour	= minute	* 60,
				day		= hour		* 24,
				week	= day		* 7,
				month	= day		* 30;


			this.content = '';

			if (this.date instanceof Date)
			{
				oDate = this.date;
			}
			else
			{
				oDate = new Date(this.date);
			}

			this.fullDate = oDate.toLocaleString();

			this.dateClass = 'passe';

			
			// Futur
			if (delta < 0 && !this.noFuture)
			{
				this.dateClass = 'futur';

				if (delta > -59)
				{
					this.content = 'A l\'instant.';
				}
				else if (delta > -minute)
				{
					this.content = "Dans " + delta + ' secondes.';
				}
				else if (delta > -hour)
				{
					this.content = "Dans " + (Math.round(delta / minute)) * -1 + ' minutes.';
				}
				else if (Math.floor(delta / -hour) == 1)
				{
					this.content = 'Dans une heure'
				}
				else if (delta > -day)
				{
					this.content = 'Dans ' + (Math.round(delta / hour)) * -1 + ' heures.';
				}
//				else if (delta > -(day * 2))
//				{
//					this.content = 'Demain';
//				}
				else if (delta > -week)
				{
					// Hier
					let dateNumber = oDate.getDay()  - 1;
					if(dateNumber === -1)
					{
						dateNumber = 6;
					}
					this.content = daysName[dateNumber] + ' prochain';
				}
				else if (delta > -month)
				{
					// Moins d'une semaine => Todo mardi dernier
					// this.content = 'il y à moins d\'une semaine';
					this.content = 'Dans ' + (Math.round(delta / day))*-1 + ' jours';
				}
				else if (delta > (-month * 12))
				{
					// Moins de deux mois
					this.content = 'Dans ' + Math.round(delta / month)*-1 + ' mois';
				}
				else
				{
					// au delà
					this.content = 'Dans ' + Math.round(delta / month / 12)*-1 + ' an(s)';
				}

			} //Passé
			else if (delta < 59)
			{
				// Moins de 30 secondes
				this.content = 'A l\'instant.';
			}
			else if (delta < minute)
			{
				// Moins d1 minute
				this.content = "il y a " + delta + ' secondes.';
			}
			else if (delta < 2 * minute)
			{
				// Moins de deux
				this.content = 'il y a une minute.'
			}
			else if (delta < hour)
			{
				// Moins d 1 heure
				this.content = "il y a " + Math.round(delta / minute) + ' minutes.';
			}
			else if (Math.round(delta / hour) == 1)
			{
				// une heure
				this.content = 'il y a une heure'
			}
			else if (delta < day)
			{
				// x heures
				this.content = 'il y a ' + Math.round(delta / hour) + ' heures.';
			}
			else if (delta < day * 2)
			{
				// Hier
				this.content = 'Hier';
			}
			else if (delta < week)
			{
				// Hier
				let dateNumber = oDate.getDay() - 1;
				if (dateNumber === -1)
				{
					dateNumber = 6;
				}
				this.content = daysName[dateNumber] + ' dernier';
			}
			else if (delta < month)
			{
				// Moins d'une semaine => Todo mardi dernier
				// this.content = 'il y à moins d\'une semaine';
				this.content = 'Il y a ' + Math.round(delta / day) + ' jours1';
			}
			else if (delta > month && delta < month * 12)
			{
				// Moins de deux mois
				this.content = 'Il y a ' + Math.round(delta / month) + ' mois';
			}
			else if (delta > month * 12 && delta < month * 2000 )
			{
				// au delà
				this.content = 'Il y a ' + Math.round(delta / (month / 12)) + ' an(s)';
			}
			else
			{
				this.content	= 'Aucune';
				this.dateClass	= 'futur';
				this.noToolTip	= true;
			}
		}
	}
}
