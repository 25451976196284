import { User }			    from '../classes/user.class';
import { IMessage }         from '../models/message.model';


/**
 * 
 * @export
 * @class Message
 */
export class Message implements IMessage
{

    /**
     * 
     * @static
     * @param {any} jsonObj
     * @returns {Message}
     * 
     * @memberOf Message
     */
    public static fromJsonObject(jsonObj: any)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let result = new Message();
        
        result.userId	= jsonObj._userId	? jsonObj._userId	: '';
        result.content	= jsonObj._content	? jsonObj._content	: '';
        result.ticks	= jsonObj._ticks	? jsonObj._ticks	: '';
        result.date     = jsonObj.id;

        return result;
    }


    /**
     * Creates an instance of Message.
     * 
     * @param {User} [_user=new User()]
     * @param {string} [_content='']
     * @param {Date} [_date=new Date()]
     * 
     * @memberOf Message
     */
    constructor(
        public _userId:		string	= '',
        public _content:	string	= '',
        public _date:		any		= null,
		public _ticks:		string	= ''
    ){ }


    get userId()	{ return this._userId; }
    get content()	{ return this._content; }
    get ticks()		{ return this._ticks; }
    get date()      { return this._date; }


    set userId(userId)		{ this._userId	= userId; }
    set ticks(ticks)		{ this._ticks	= ticks; }
    set content(content)    { this._content = content; }
    set date(date)          { this._date    = date; }


	/**
	 * 
	 */
	public toJson()
	{
		let result =
			{
				userId:		this.userId,
				content:	this.content,
				ticks:		this.ticks,
				date:		this.date
			};

		return result;
	}
}
