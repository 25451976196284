<mat-dialog id="connection_new_machine_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Validation nouvelle machine
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

        <mat-card-content>
            <label>Veuillez saisir le code à quatre chiffres qui vous a été envoyé sur l'adresse email de votre compte:</label>
            <div class="clear"></div>
        </mat-card-content>

		<mat-card-content>
			<mat-form-field>
				<input type="text"  maxLength="4" matInput placeholder="Veuillez saisir le code à 4 chiffres" (keypress)="eventHandler($event)" id="connection_application_modal_password" [(ngModel)]="code">
			</mat-form-field>
			<div class="link_regen_machine_code">
				<a [routerLink]="" (click)="generateNewMachineCode()">Demander un nouveau code</a>
			</div>
			<span class="error-message" *ngIf="displayInvalidCodeError"> Erreur: le code saisie est incorrect </span>
			<span class="new-code-message" *ngIf="displayNewCodeGenerared"> Nouveau code envoyé par mail </span>
			<div class="clear"></div>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="cancel-connect" class="cancel_button" mat-raised-button (click)="cancel()">Annuler</button>
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validateCode()" [disabled]="!canValidCheckMachine()">Valider</button>
	</div>
</mat-dialog>
