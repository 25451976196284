<mat-dialog id="connection_otp_modal "  class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Double authentification
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

		<mat-card-content>
			<mat-form-field>
				<input type="text"  maxLength="6"  matInput placeholder="Veuillez saisir le code à 6 chiffres" (keypress)="eventHandler($event)" id="connection_application_modal_password" [(ngModel)]="otpCode">
			</mat-form-field>
			<div class="clear"></div>
		</mat-card-content>

    </div>
	<div class="dialog_footer">
		<button id="cancel-connect" class="cancel_button" mat-raised-button color="accent" (click)="cancel()">Annuler</button>
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="checkOTP()" [disabled]="!canValidCheckOTP()">Valider</button>
	</div>
</mat-dialog>
