<div class="row text-center calendar_action">

	<div class="btn btn-primary calendar_action_prev"
		 mwlCalendarPreviousView
		 [view]="view"
		 [(viewDate)]="viewDate"
		 (viewDateChange)="viewDateChange.next(viewDate)">
		 <mat-icon>keyboard_arrow_left</mat-icon>

	</div>
	<div class="btn btn-secondary calendar_action_now" >
		{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}
	</div>
	<div
		class="btn btn-primary calendar_action_next"
		mwlCalendarNextView [view]="view"
		[(viewDate)]="viewDate"
		(viewDateChange)="viewDateChange.next(viewDate)">
		<mat-icon>keyboard_arrow_right</mat-icon>
	</div>
	<div class="clear"></div>
</div>

<div [ngSwitch]="view">
	<mwl-calendar-month-view
		*ngSwitchCase="'month'"
		[viewDate]="viewDate"
		[locale]="'fr'"
		[events]="events"
		[refresh]="refresh"
		[activeDayIsOpen]="activeDayIsOpen"
		[weekStartsOn]="1"
		(dayClicked)="dayClicked($event.day)"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-month-view>
	<mwl-calendar-week-view
		*ngSwitchCase="'week'"
		[viewDate]="viewDate"
		[locale]="'fr'"
		[events]="events"
		[refresh]="refresh"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-week-view>
	<mwl-calendar-day-view
		*ngSwitchCase="'day'"
		[locale]="'fr'"
		[viewDate]="viewDate"
		[events]="events"
		[refresh]="refresh"
		(eventClicked)="handleEvent('Clicked', $event.event)"
		(eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-day-view>
</div>

