<div class="dialog_grid_container">
	<div class="dialog_header">
		<div class="dialog_header_title">
			{{titleModal}}
		</div>
		<div class="dialog_header_close">
			<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_menu">
		<div  class="dialog_menu_title">
			{{mailUser}}
		</div>
		<div (click)="switch_tab('infos')" [class.dialog_menu_selected]="itemSelected === 'infos'" class="dialog_menu_block">
			{{ 'userModal.accounts' | translate }}
		</div>
		<div (click)="switch_tab('password')" *ngIf="mbIsNew === false" [class.dialog_menu_selected]="itemSelected === 'password'" class="dialog_menu_block">
			{{ 'common.password' | translate }}
		</div>
	<!--
		<div (click)="switch_tab('doubleauth')" *ngIf="editAccount" [class.dialog_menu_selected]="itemSelected === 'doubleauth'" class="dialog_menu_block">
			{{ 'userModal.doubleAuth' | translate }}
		</div>
	-->
		<div (click)="switch_tab('autres')" *ngIf="editAccount && isCurrentAccount" [class.dialog_menu_selected]="itemSelected === 'autres'" class="dialog_menu_block">
			{{ 'userModal.keyAccount' | translate }}
	</div>
</div>
<div class="dialog_content">

	<div class="form_section_block" *ngIf="!mbIsAdminEditing">
		<div class="qcm_part_info_other_item">
			<mat-form-field>
				<mat-label>Type d'utilisateur</mat-label>
				<mat-select (ngModelChange)="onChangeUserTypeSelect($event)" [ngModel]="currentUserType">
					<mat-option *ngFor="let type of userTypes" [value]="type.value">{{type.libelle}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<form (ngSubmit)="validate()" [formGroup]="moFormUser" class="dialog_form">

		<!--
			// =====================================================
			// infos du comptes
			// =====================================================
		-->

		<div class="form_section " [hidden]="itemSelected !== 'infos'">
			<h3>Informations principales : </h3>
			<div class="form_section_block ">
				<div class="bddaccount" *ngIf="this.userData.bddAccountConnected"> Compte : {{ this.userData.bddAccountConnected }} </div>
				<mat-form-field>
					<input type="email" matInput required maxLength="80" placeholder="{{ 'userModal.mainMail' | translate }}" (keyup)="onKeyUpMail($event)" [(ngModel)]="mailUser" formControlName="mail" />
					<!--		<mat-hint>Errors appear instantly!</mat-hint>-->
					<mat-error *ngIf="moFormCtrlMailUser.hasError('email') && !moFormCtrlMailUser.hasError('required')">
						Merci d'entrer une adresse email valide
					</mat-error>
					<mat-error *ngIf="moFormCtrlMailUser.hasError('required')">
						L'Email est <strong>obligatoire</strong>
					</mat-error>
					<mat-error *ngIf="!moFormCtrlMailUser.hasError('email') && !moFormCtrlMailUser.hasError('required') && errorMailAvailable">
						Un compte avec ce mail est déjà existant
					</mat-error>

				</mat-form-field>

				<div class="clear"></div>

				<mat-form-field class="">
					<input type="text" matInput required maxLength="80" placeholder="{{ 'common.name' | translate }}" [(ngModel)]="nomUser" formControlName="nom"/>
				</mat-form-field>

				<div class="clear"></div>

				<mat-form-field class="">
					<input type="text" matInput required maxLength="80" placeholder="{{ 'common.surname' | translate }}" [(ngModel)]="prenomUser" formControlName="prenom"/>
				</mat-form-field>

				<div class="clear"></div>

				<mat-form-field>
					<input type="email" matInput maxLength="80" placeholder="{{ 'userModal.secondMail' | translate }}" (keyup)="onKeyUpMail2($event)" [(ngModel)]="secondMailUser" formControlName="secondMail" />
					<mat-error *ngIf="moFormCtrlSecondMailUser.hasError('email') && !moFormCtrlSecondMailUser.hasError('required')">
						Merci d'entrer une adresse email valide
					</mat-error>
				</mat-form-field>

				<div class="clear"></div>

				<mat-checkbox [(ngModel)]="mbUseSecondMailUser"
							[ngModelOptions]="{standalone: true}">
					{{ 'userModal.useSecondMail' | translate }}
				</mat-checkbox>

				<div class="clear"></div>

				<mat-form-field class="">
					<input type="text" matInput placeholder="{{ 'userModal.phone1' | translate }}" maxLength="13"  [(ngModel)]="numTelFixe" formControlName="telFixe" />
				</mat-form-field>
				<div class="clear"></div>
				<mat-form-field class="">
					<input type="text" matInput placeholder="{{ 'userModal.phone2' | translate }}"  maxLength="13" [(ngModel)]="numTelMobile" formControlName="telMobile" />
				</mat-form-field>
				<div class="clear"></div>
			</div>


			<div class="form_section_printer" *ngIf="mbIsPrinter">
				<h3>Imprimeur </h3>

				<div class="form_section_block">
					Option Imprimeur
				</div>
			</div>

			<div class="form_section_not_printer" *ngIf="!mbIsPrinter">
				<h3>{{ 'common.jobs' | translate }} :</h3>

				<div class="form_section_block">

					<div class="clear"></div>

					<div *ngFor="let profession of professions; let index = index; trackBy:trackByProfession"  class="block_profession_item">
						<mat-form-field>
							<input matInput  maxLength="80"  placeholder="{{ 'common.job' | translate }} {{index + 1}}" [(ngModel)]="professions[index]" [ngModelOptions]="{standalone: true}">
						</mat-form-field>
						<button type="button" mat-icon-button (click)="removeProfession(index)">
								<mat-icon>clear</mat-icon>
						</button>
						<div class="clear"></div>
					</div>
					<div class="block_profession_item">
						<mat-form-field class="">
							<input matInput maxLength="80" placeholder="{{ 'common.job' | translate }} {{professions.length + 1}}" [(ngModel)]="newProfession" formControlName="newProfession">
						</mat-form-field>
					</div>

					<mat-form-field class="toHide">
						<input matInput maxLength="80">
					</mat-form-field>

					<div class="block_profession_input_add_block" *ngIf="professions && newProfession && newProfession[professions.length]" (click)="addProfession()">
						<button mat-mini-fab type="button" >
							<mat-icon>add</mat-icon>
						</button>
						<span class="block_profession_input_add_block_label">
							{{ 'userModal.addProf' | translate }}
						</span>
					</div>
					<div class="clear"></div>
				</div>

			</div>
		</div>


		<!--// =====================================================
		// Mot de passe
		// =====================================================-->
		<div class="form_section" *ngIf="mbIsNew === false" [hidden]="itemSelected !== 'password'">
			<h3>{{ 'userModal.updatePassword' | translate }} : </h3>
			<div class="form_section_block">
				<div *ngIf="!editAccount">
					<div class="clear"></div>
					<password-editor (onValidPassword)="handleOnValidPassword($event)" [password]="passwordUser"></password-editor>
				</div>
				<div *ngIf="editAccount">
					<div *ngIf="isCurrentAccount">
						<mat-form-field>
							<input type="text"  maxLength="80"  type="password" matInput placeholder="{{ 'userModal.enterPassword' | translate }}" [(ngModel)]="currentPassword" formControlName="currentPassword"/>
						</mat-form-field>
						<div class="clear"></div>
					</div>
					<password-editor (onValidPassword)="handleOnValidPassword($event)" [currentPassword]="currentPassword" [password]="passwordUser"></password-editor>
					<div class="clear"></div>
					<button mat-raised-button type="button" *ngIf="!isCurrentAccount" (click)='generateRandomPassword()'>{{ 'userModal.refreshPassword' | translate }}</button>
				</div>
			</div>
		</div>
		<!--// =====================================================
		// Double auth
		// =====================================================-->
		<div class="form_section" [hidden]="itemSelected !== 'doubleauth'">
			<h3>Double authentification : </h3>
			<div class="form_section_block">

				<mat-form-field>
					<mat-select placeholder="{{ 'userModal.authMethod' | translate }}"
								[ngModel]="doubleAuthTypeSelected"
								formControlName="doubleAuthType"
								(ngModelChange)="onChangeAuthType($event)">
						<mat-option *ngFor="let type of doubleAuthTypes" [value]="type.value">{{type.libelle}}</mat-option>
					</mat-select>
				</mat-form-field>

				<div class='content_otp_conf' *ngIf="displayQRcode">

					<div class="otp_conf_step1_label">
						<label>{{ 'userModal.qrCodeInstructions1' | translate }}</label>
					</div>
					<div class="clear"></div>

					<div class="otp_conf_qrcode" *ngIf="otpGeneratedUrl">
						<ngx-qrcode
							[qrc-value] = "otpGeneratedUrl"
							qrc-errorCorrectionLevel = "L">
						</ngx-qrcode>
					</div>
					<div class="clear"></div>

					<div class="otp_conf_step2_label">
						<label>{{ 'userModal.qrCodeInstructions2' | translate }}</label>
					</div>
					<div class="clear"></div>

					<div class="otp_conf_valid">
						<mat-form-field>
							<input type="text" maxLength="80"  matInput [(ngModel)]="otpValidationCode" formControlName="otpValidationCode"/>
						</mat-form-field>
						<div class="clear"></div>
						<button [disabled]="!otpValidationCode" (click)="validateOTP()" [color]="accent" mat-raised-button type="button">{{ 'common.validate' | translate }}</button>
						<div class="clear"></div>
						<label *ngIf="displayMessageOTPValidated">{{ 'userModal.otpActivated' | translate }}</label>
					</div>

				</div>

				<div *ngIf="!displayQRcode && doubleAuthTypeSelected === 3">
					<button (click)="generateNewOTPCode()" mat-raised-button type="button">{{ 'userModal.generateNewOtp' | translate }}</button>
				</div>

			</div>
		</div>


		<!--// =====================================================
		// Clé RSA
		// =====================================================-->
		<div class="form_section" [hidden]="itemSelected !== 'autres'">
			<h3>Clé RSA du compte : </h3>

			<div class="form_section_block">

				<div *ngIf="hasRsaPrivate() === true">
					<span class="label_rsa">{{ 'userModal.downloadRsaKey' | translate }}</span>
					<button (click)="downloadRsaKey()" color="primary" mat-raised-button type="button">{{ 'userModal.downloadRsaKeyButton' | translate }}</button>
				</div>
				<div *ngIf="hasRsaPrivate() === false">
					<span class="label_rsa">{{ 'userModal.importRsaKey' | translate }}</span>
					<input type="file" accept=".txt, .exakey" hidden #file (change)="importRsaKey($event)">
						   <div class="clear"></div>
					<button mat-raised-button color="primary" type="button" (click)="file.click()">{{ 'userModal.importRsaKeyButton' | translate }}</button>
				</div>
				<div *ngIf="displayMessageErrorRsa">
					<span class="return_rsa">{{ 'userModal.errorImportRsa' | translate }}</span>
				</div>
				<div *ngIf="displayMessageRsaValidated">
					<span class="return_rsa">{{ 'userModal.successImportRsa' | translate }}</span>
				</div>
			</div>
		</div>
	</form>


</div>
<div class="dialog_footer">
	<div class="dialog_footer_cancel_button">
		<button mat-raised-button type="button" (click)='close()'>{{ 'common.cancel' | translate }}</button>
	</div>
	<div class="dialog_footer_valid_button">
		<button mat-raised-button [disabled]="!canValidateUserModal()" (click)="validate()" type="submit">{{titleValidateButton}}</button>
	</div>
</div>
</div>
