import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Task } 					from '../../classes/task.class';

import { EtablissementService } 	from '../../services/etablissement.service';
import { SocketService }			from '../../services/socket.service';
import { ISocketData, SocketWhat }	from '../../models/message.model';
import { TaskService }				from '../../services/task.service';
import { MessagesService }			from '../../services/messages.service';
import { AppComponent }				from '../../app.component';
import { UserData }					from '../../providers/user-data';
import { AdminData }				from '../../providers/admin-data';
import { WorkspaceData }			from '../../providers/workspace-data';
import { TaskData } 				from '../../providers/task-data';
import { MatDialog }				from '@angular/material';

import { EditorPanelTaskComponent }		from './components/editor-panel-task/editor-panel-task.component';
import { EditorPanelComponent }			from './components/editor-panel/editor-panel.component';
import { SelectAssignedComponent }		from './modal/select-assigned.component';
import { SendMailModalComponent }		from './modal/sendMailModal.component';
import { ValidateTaskModalComponent }	from '../../pages/workspace/subject/subject-list/modal/validateTaskModal/validateTaskModal.component';

import { TranslateService } from '@ngx-translate/core';
import { DragulaService }	from 'ng2-dragula/ng2-dragula';
import * as _				from 'underscore';
import {Observable}			from 'rxjs/Rx';


@Component({
	selector:		'app-task',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./task.component.html',
	styleUrls:		['./task.component.scss']
})


export class TaskComponent implements OnInit, OnDestroy
{
	public tasksByUser:			any			= {};
	public tasksByGroup:		any			= {};
	public lastTasksByGroup:	any			= {};
	public usersTask:			string[]	= [];
	public structureSubjects:	any[]		= [];
	public usersAvailable:		any[]		= [];
	public scenariosAvailable:	any[]		= [];
	public ticketByWorkflow:	any[]		= [];
	public showFab:				boolean		= false;
	public tasksLoaded:			boolean		= false;
	public updating:			boolean		= false;
	public kanbanTypeSelected:	string		= 'state';
	public isLoading:			boolean		= false;

	public selectedTask:			Task;
	public editingTask:				Task;
	public isEditorPanelActive:		boolean;
	public focusedTask:				Task;
	public dropSubscription:		any;
    public titleScenarioSelected:	any;
    public scenarioSelected:		any;
	public moTaskDataSubscription:	any;
	selectedEtablissementId:		any;

	/**
	 * Types de diagrammes kanban qui seront selectionnables
	 * dans le select en haut à gauche de l'interface des tâches
	 */
	public kanbanTypes: any = [];


//	/**
//	 * Ensemble des états disponibles.
//	 * Correspond aux colonnes du diagramme kanban lorsque le kanban de type
//	 * Etat est selectionné
//	 */
	public statesAvailable: any = [];


	/**
	 * Ensemble des status disponibles.
	 * Correspond aux colonnes du diagramme kanban lorsque le kanban de type
	 * Status est selectionné
	 */
	public typeAvailable = [
		{
			/* Correspond à des tâches de rédaction de sujets assignées à un ou plusieurs utilisateurs */
			value:		'redaction',
			libelle:	'Rédaction'
		},
		{
			/** Correspond à des tâches de tests de sujets en conditions réélles par un ou plusieurs utilisateurs
			 * assignés
			 */
			value:		'test',
			libelle:	'Test'
		},
		{
			/** Correspond à des tâches de validation de sujets assignées à un ou plusieurs membres de jury */
			value:		'miseEnPage',
			libelle:	'Mise en page'
		},
		{
			/** Correspond à des tâches de validation de sujets assignées à un ou plusieurs membres de jury */
			value:		'verification',
			libelle:	'Vérification finale'
		}
	];


	public options: any =
	{
		accepts: (el: any, target: any, source: any, sibling: any) =>
		{
			let result = true;
			// Un simple utilisateur ne peux pas déplacer une tache vers l'état 'non assignées' ou 'Terminées'
			// ou qui est déja en état 'non assignées' ou 'Terminées'
			if (this.userData.isAdmin() === false && this.userData.isSuperviseur() === false)
			{
				if (target && target.id && source && source.id)
				{
					if (target.id === 'new' || target.id === 'done' || target.id === 'todo' || source.id === 'new' || source.id === 'done')
					{
						result = false;
					}
				}
			}

			return result;
		}
	}


	constructor(
		public _router:					Router,
		public _taskService:			TaskService,
		public etablissementService:	EtablissementService,
		public socketService:			SocketService,
		public messagesService:			MessagesService,
		public appComponent:			AppComponent,
		public dragulaService:			DragulaService,
		public userData:				UserData,
		public taskData:				TaskData,
		public adminData:				AdminData,
		public workspaceData:			WorkspaceData,
		public dialog:					MatDialog,
		public translate: 				TranslateService)
	{
		this.translate.get('task.pageTitle').subscribe((value: string) => {
			appComponent.setPageTitle(value);
		});

		this._initKanbanTypes();
		this._initStatesAvailable();

		this._applyTasksByUser();
		this._applyTasksByGroup();

		//usersAvailable
		/**
		 * On effectue un appel socket vers le serveur pour récupérer les utilisateurs disponibles
		 */
		this.usersAvailable = this.userData.getUsers();
	}


	public ngOnInit()
	{
		this._initTasks();
		this._initParameters();
		this.dropSubscription = this.dragulaService.drop.subscribe((el: any) =>
		{

			//Si lors du drop la colonne d'arrivée est la même que la colonne de départ on ne fait rien
			//sinon on applique les changements
			
			if (el[2].id !== el[3].id)
			{
				if(el[3].id === "done")
				{
					/*************************************** 
					 * Avertissement demandant confirmation de la supression des taches suivante
					***************************************/
					

					/*************************************** 
					 * Si oui on supprime les taches suivante
					***************************************/


					/*************************************** 
					 * Si non on remet la tache dans sa colonne
					***************************************/

				}
				else
				{

					let mustChooseAssigned			= false;
					let mustDisplayValidationForm	= false;
					let mustShowSendMailModal		= false;

					// Todo => Rajouter l'update 
					// ---------------------------------------
					// Récupération de la lane pour mettre à jour la task en cours
					// el[0] le type de lane
					// el[2] la nouvelle lane
					// el[3] l'ancienne lane 
					// ---------------------------------------
					if (this.focusedTask && !this.updating)
					{
						this.focusedTask.newTask = false;

						// ---------------------------------------
						// On créer un objet resSubmit contenant dans un premier temps la tâche qui a été déplacée
						// par drag and drop
						// ---------------------------------------
						let resSubmit: any =
							{
								task:	this.focusedTask,
								datas:	undefined
							};

						// ---------------------------------------
						// 	Si on est dans un kanban de type Etat
						// on met à jour le le champ state de la tache courante qui à été
						// déplacée dans le tableau
						// On met à jour l'objet resSubmit avec le nouvel état de la tâche déplacée
						// ---------------------------------------
						if (el[0] === 'state')
						{
							if (el[3].id === 'new')
							{
								mustChooseAssigned = true;
							}
							if (el[3].id !== 'done' && (el[2].id === 'done' || el[2].id === 'review'))
							{
								mustDisplayValidationForm = true;
							}
							if (el[2].id === 'new')
							{
								mustShowSendMailModal = true;
							} 
							resSubmit.datas =
								{
									state:		el[2].id,
									oldState:	el[3].id
								};
							this.focusedTask.state = el[2].id;
						}
						else
						{
							// ---------------------------------------
							// Si on est dans un kanban de type Status
							// on met à jour le le champ status de la tache courante qui à été
							// déplacée dans le tableau
							// On met à jour l'objet resSubmit avec le nouveau status de la tâche déplacée
							// ---------------------------------------
							if (el[0] === 'status')
							{
								resSubmit.datas =
									{
										type: el[2].id
									};
								this.focusedTask.type = el[2].id;
							}
							else
							{

								// ---------------------------------------
								// Si on est dans un kanban de type Utilisateur
								// on met à jour le le champ assigneduser de la
								// tache courante qui à été
								// déplacée dans le tableau
								// On met à jour l'objet resSubmit avec le nouvel
								// utilisateur assigné de la tâche déplacée
								// ---------------------------------------
								if (el[0] === 'user')
								{
									resSubmit.datas =
									{
										assigneduser: el[2].id
									}
									this.focusedTask.assigneduser = el[2].id;
								}
							}
						}

						// ---------------------------------------
						// On appelle la function permettant la mise à jour en bdd de la tâche déplacée.
						// Si initialiement la tache était dans l'état 'non assigné' on affiche une modale pour
						// sélectionner l'utilisateur assigné
						// ---------------------------------------
						if (mustChooseAssigned === true)
						{
							this.translate.get('task.textTaskAssigned').subscribe((value1: string) =>
							{
								this.translate.get('task.echeance').subscribe((value2: string) =>
								{
									const dialogRef = this.dialog.open(SelectAssignedComponent,
									{
										panelClass:		'responsive_modal',
										height:			'40%',
										width:			'50%',
										disableClose:	true,
										data:
										{
											sendMailDefaultValue: true
										}
										});

									dialogRef.afterClosed().subscribe((result: any) =>
									{
										if (result && result.user)
										{
											resSubmit.datas.assigneduser = result.user;

											if (result.typeEmail)
											{
												const currentExamen		= this.workspaceData.getExamen(this.focusedTask.idExamen);
												const currentEpreuve	= this.workspaceData.getEpreuve(this.focusedTask.idEpreuve);
												const currentSujet		= this.workspaceData.getSujet(this.focusedTask.idSujet);

												resSubmit.datas.datasEmail =
													{
														nomEtape:	resSubmit.task.typeLib,
														nomExamen:	currentExamen			? currentExamen.name	: '',
														nomEpreuve: currentEpreuve			? currentEpreuve.name	: '',
														nomSujet:	currentSujet			? currentSujet.name		: ''
													};
												resSubmit.datas.typeMail = result.typeEmail;
											}

											this.submitTaskChange(resSubmit);
										}
										else
										{
											//Si aucun utilisateur n'est selectionné
											//on replace la tache a son emplacement initial
											this.focusedTask.state = el[3].id;

										}
									});
								});
							});
						}
						else
						{
							if (mustDisplayValidationForm === true)
							{
								let heightModal;
								if (el[2].id === 'review')
								{
									heightModal = '65vh';
								}
								else
								{
									heightModal = '75vh';
								}

								//On modifie l'état de la tache pour la modale de validation
								let oldState;
								if (el[2].id === 'done')
								{
									oldState = 'review';
								}
								else
								{
									oldState = 'inprogress';
								}

								let dialogRefValidateTask = this.dialog.open(ValidateTaskModalComponent,
									{
										height:			heightModal,
										panelClass:		'responsive_modal',
										width:			'650px',

										disableClose: true,
										data:
										{
											'task':		resSubmit.task,
											'state':	oldState
										}
									});

									dialogRefValidateTask.afterClosed().subscribe((newTask: any) =>
									{
										/**
										 * Si une nouvelle tache doit être créée après la validation
										 * on ouvre la modale de création de tache
										 */
										let selectedItems: any[] =
											[
												resSubmit.task.idEtablissement,
												resSubmit.task.idSession,
												resSubmit.task.idExamen,
												resSubmit.task.idEpreuve
											];

										if (newTask !== undefined)
										{
											let dialogRefCreateTask = this.dialog.open(EditorPanelTaskComponent,
												{
													height:			'80%',
													panelClass:		'responsive_modal',
													width:			'80%',
													disableClose:	true,
													data:
													{
														'selectedItems':	selectedItems,
														'oldTask':			resSubmit.task,
														'task':				newTask,
														'isEdition':		false
													}
												});

											dialogRefCreateTask.afterClosed().subscribe((result: any) =>
											{

											});
										}
										else
										{
											//Si on annule on remet la tache à l'état précédent
											this.focusedTask.state = el[3].id;
										}
									});
							}
							else
							{
								if (mustShowSendMailModal === true)
								{
									this.translate.get('task.textTaskUnassigned1').subscribe((value1: string) => {
										this.translate.get('task.textTaskUnassigned2').subscribe((value2: string) => {

											const dialogRefSendMail = this.dialog.open(SendMailModalComponent,
												{
													height:			'30%',
													panelClass:		'responsive_modal',
													width:			'30%',
													disableClose:	true,
													data:
													{}
												});

											dialogRefSendMail.afterClosed().subscribe((result: any) =>
											{
												if (result)
												{
													if (result.sendMail === true)
													{
														const currentSujet = this.workspaceData.getSujet(this.focusedTask.idSujet);

														resSubmit.datas.typeMail	= 'unassignedTask';
														resSubmit.datas.datasEmail	=
															{
																nomSujet: currentSujet ? currentSujet.name : '',
																nomEtape: resSubmit.task.typeLib
															}
													}

													this.submitTaskChange(resSubmit);
												}
												else
												{
													//Si aucun utilisateur n'est selectionné
													//on replace la tache a son emplacement initial
													this.focusedTask.state = el[3].id;
												}
											});
										});
									});
								}
								else
								{
									this.submitTaskChange(resSubmit);
								}
							}

						}
					}
				}

			}
		});


		this.moTaskDataSubscription = this.taskData.taskDataEmitter.subscribe((values: any) =>
		{
			if (values === 'task::update')
			{
				this._initParameters();
			}
		});
	}


	/**
	 *
	 */
	public changeSelectedEtablissement(event: any)
	{
		this.workspaceData.selectedEtablissement = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;

			this.workspaceData.resetSelectedSession();
			this.workspaceData.resetSelectedExamen();
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}
		else
		{
			this.workspaceData.resetSelectedEtablissement();
			this.workspaceData.resetSelectedSession();
			this.workspaceData.resetSelectedExamen();
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}

		this.taskData.filterTasks('etab', id);
	}
	/**
	 *
	 */
	public changeSelectedSession(event: any)
	{
		this.workspaceData.selectedSession = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;

			this.workspaceData.resetSelectedExamen();
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}
		else
		{
			this.workspaceData.resetSelectedSession();
			this.workspaceData.resetSelectedExamen();
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}

		this.taskData.filterTasks('sess', id);

	}
	/**
	 *
	 */
	public changeSelectedExamen(event: any)
	{
		this.workspaceData.selectedExamen = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}
		else
		{

			this.workspaceData.resetSelectedExamen();
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}

		this.taskData.filterTasks('exam', id);

	}
	/**
	 *
	 */
	public changeSelectedMatiere(event: any)
	{
		this.workspaceData.selectedMatiere = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
			this.workspaceData.resetSelectedSujet();
		}
		else
		{
			this.workspaceData.resetSelectedMatiere();
			this.workspaceData.resetSelectedSujet();
		}

		this.taskData.filterTasks('matiere', id);

	}
	/**
	 *
	 */
	public changeSelectedSujet(event: any)
	{
		this.workspaceData.selectedSujet = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
		}
		else
		{
			this.workspaceData.selectedSujet = null;
		}

		this.taskData.filterTasks('suj', id);

	}


	/**
	 *
	 */
	public ngOnDestroy()
	{
		//On désactive la souscription aux evènement de drop lorsque l'on quitte la page
		//Permet de ne pas multiplier les mêmes appels
		if (this.dropSubscription)
		{
			this.dropSubscription.unsubscribe();
		}

		if (this.moTaskDataSubscription)
		{
			this.moTaskDataSubscription.unsubscribe();
		}
	}

	public processRoute()
	{
		let routeParams = this.getRouteParams();
	}

	public getRouteParams(): any
	{
		return null;
	}


	/**
	 *
	 */
	public editTicket(task: Task)
	{
		this.messagesService.getMessages(task.id, 'tasks');
		this.taskData.listenTask(task);
		let dialogRef = this.dialog.open(EditorPanelTaskComponent,
			{
				height:			'80%',
				width:			'80%',
				disableClose:	true,
				data:
				{
					'task':			task
				}
			});
	}

	/**
	 *
	 * @param task
	 */
	public addSubTicket(task: Task)
	{
		this.addTicket(task);
//		this.toggle();
	}


	/**
	 *
	 */
	public selectTicket(task: Task)
	{
		this.selectedTask = task;
	}


	/**
	 *
	 */
	public focusTicket(task: Task)
	{
		this.focusedTask = task;
	}


	/**
	 *
	 */
	public removeTicket(task: Task)
	{
		this._taskService.deleteTask(task).subscribe((res: any) =>
		{
			let arrayTasks: Task[] = this.workspaceData.globalTask.filter((item: any) => { return item.id !== task.id; });

			if (task.taskLocked)
			{
				for (let t of task.taskLocked)
				{
					this._updateTaskDependencies(t.id, task.id, 'lockRules', 'delete');
				}
			}


			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			if (task.lockRules)
			{
				for (let rule of task.lockRules)
				{
					for (let rule of task.lockRules)
					{
						this._updateTaskDependencies(rule.id, task.id, 'taskLocked', 'delete');
					}
				}

				this.workspaceData.globalTask = arrayTasks;
				this._applyTasksByUser();
				this._applyTasksByGroup();
			}
		});
	}


	/**
	 *
	 */
	public addTicket(parentTicket: Task = null)
	{
		if (this.editingTask)
		{
			return;
		}

		let newTask: Task = new Task();

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		newTask.newTask = true;
		if (parentTicket)
		{
			newTask.parents = [parentTicket.id];
		}
		this.editingTask			= newTask;

		this.isEditorPanelActive	= true;
		let dialogRef				= this.dialog.open(EditorPanelComponent,
			{
				height:			'80%',
				panelClass:		'responsive_modal',
				width:			'65%',
				disableClose:	true,
				data:
				{
					'task': newTask
				}
			});

		this.toggle();
	}


	/**
	* Post de la mise à jours d'une task
	*/
	public submitTaskChange(datasTask: any)
	{
		this.updating = true;
		let arrayTasks: Task[];
		if (datasTask.task)
		{

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			let task = datasTask.task;

			let newTasks: Task[] = [];
			newTasks = newTasks.concat(this.workspaceData.globalTask);


			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			if (task.newTask === false)
			{
				let newDatas = datasTask.datas;
				//Si la tache passe en état non assgigné, on supprime l'utilisateur assigné
				//Si il existe
				if (newDatas.state && newDatas.state === 'new')
				{
					if (task.assigneduser)
					{
						newDatas.assigneduser = '';
					}
				}

				//Si un message doit être envoyé par mail on active la notification par mail sur la tache courante
				if (newDatas.typeMail && newDatas.typeMail !== '')
				{
					task.notifyByMail = true;
				}
				else
				{
					task.notifyByMail = false;
				}

				this._taskService.updateTask(task, newDatas);
				this.updating = false;
			}
			else
			{
				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				task.flags = ['inprogress'];
				this._taskService.addTask(task);
				this.updating = false;
			}
		}
	}


	public toggle()
	{
		this.showFab = !this.showFab;
	}


	public _initKanbanTypes()
	{
		this.translate.get('task.state').subscribe((value1: string) =>
		{
			this.translate.get('task.workflow').subscribe((value2: string) =>
			{
				this.translate.get('task.user').subscribe((value3: string) =>
				{

					this.kanbanTypes = [
						{
							/*Diagramme permettant de trier les tâches par état*/
							value:		'state',
							viewValue:	value1
						},
//						{
//							/*Diagramme permettant de trier les tâches par statut*/
//							value:		'status',
//							viewValue:	value2
//						},
						{
							/*Diagramme permettant de trier les tâches par utilisateur*/
							value:		'user',
							viewValue:	value3
						}
					];
				});
			});
		});
	}


	public _initStatesAvailable()
	{
		this.translate.get('task.assigned').subscribe((value1: string) =>
		{
			this.translate.get('task.inprogress').subscribe((value2: string) =>
			{
				this.translate.get('task.toValidate').subscribe((value3: string) =>
				{
					this.translate.get('task.validated').subscribe((value4: string) =>
					{
						this.statesAvailable =
							[
								{
									/* La tache est assignée à un utilisateur mais n'est pas encore débutée */
									value:		'todo',
									libelle:	value1
								},
								{
									/* La tache est assignée et elle est en cours */
									value:		'inprogress',
									libelle:	value2
								},
								{
									/* La tache est terminée mais elle doit être validée par un responsable */
									value:		'review',
									libelle:	value3
								},
								{
									/* La tache est terminée et elle a été validée par un responsable */
									value:		'done',
									libelle:	value4
								}
							];
					});
				});
			});
		});
	}

	public _initTasks()
	{
		let sUserId = this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{

				if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': sUserId,
							'what': eSocketWhat,
							'cmd': 'tasks.stats',
							'args': {}
						};

					socket.emit('read', data);
				}
				else
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': sUserId,
							'what': eSocketWhat,
							'cmd': 'tasks.assigned',
							'args': {}
						};
					socket.emit('read', data);
				}
			}
		});
	}



	public _applyTasksByUser()
	{
		this.tasksByUser	= _.groupBy(this.workspaceData.globalTask, function(task: any){ return task.assigneduser; });
		this.usersTask		= _.filter(Object.keys(this.tasksByUser), function(user: any){ return user !== ''; });

	}

	public _applyTasksByGroup()
	{
		this.lastTasksByGroup	= [];
		this.tasksByGroup		= _.groupBy(this.workspaceData.globalTask, function (task: any)
		{
			return task.idSession + ':' + task.idExamen + ':' + task.idEpreuve + ':' + task.idSujet;
		});

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let tasksDone;
		let lastTask;
		let lastIndexState = 0;

		for (let key in this.tasksByGroup)
		{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
			if (this.tasksByGroup[key] && this.tasksByGroup[key].length > 0)
			{
				tasksDone = _.sortBy(_.where(this.tasksByGroup[key], {state: 'done'})
							, function(task: any){ return task.dateModification.getTime(); });

				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				if (tasksDone && tasksDone.length)
				{
					lastTask = tasksDone[tasksDone.length - 1];
				}
				else
				{
					lastTask = this.tasksByGroup[key][0];
					lastIndexState = _.findLastIndex(this.typeAvailable, {value: lastTask.state});
				}

				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				for (let task of this.tasksByGroup[key])
				{
					if (task.state !== 'done'
						&& _.findLastIndex(this.typeAvailable, {value: task.state}) > lastIndexState)
					{
						lastTask = task;
						lastIndexState = _.findLastIndex(this.typeAvailable, {value: task.state});
					}
				}
				this.lastTasksByGroup.push(lastTask);
			}
		}
	}


	/**
	 *
	 */
	public _initStructuresModel(datas: any)
	{
		let structureModel = [];

		if (datas && datas.cmd === 'node.info')
		{
			// ---------------------------------------
			// On récupère la structure renvoyée par socket et on affecte les structures qui pourront
			// être sélectionnées dans le formulaire de tâches
			// ---------------------------------------
			if (datas.args && datas.args.data && datas.args.data.nodes && datas.args.data.nodes.structure)
			{
				for (let etablissement of datas.args.data.nodes.structure)
				{
					for (let session of etablissement.sessions)
					{
						structureModel.push(
							{
								libelle:	etablissement.name + ' - ' + session.name,
								 value:		session.uuid
							});

						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						for (let examen of session.examens)
						{
							structureModel.push(
								{
									libelle:	etablissement.name + ' - ' + session.name + ' - ' + examen.name,
									value:		session.uuid + ',' + examen.uuid
								});


							// ---------------------------------------
							// ToComment
							// ---------------------------------------
							for (let epreuve of examen.epreuves)
							{
								structureModel.push(
									{
										libelle:	etablissement.name + ' - ' + session.name + ' - ' + examen.name + ' - ' + epreuve.name,
										value:		session.uuid + ',' + examen.uuid + ',' + epreuve.uuid
									});
							}
						}
					}
				}

				this.structureSubjects = structureModel;
			}
		}
	}


	public _updateTaskDependencies(idTask: string, idField: string, nameField: string, mode: string)
	{
		let index;
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		switch (mode)
		{
			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			case 'add':
				index = _.findIndex(this.workspaceData.globalTask, (val: any) =>
				{
					return val.id === idTask;
				});

				let indexTask = _.findIndex(this.workspaceData.globalTask, (val: any) =>
				{
					return val.id === idField;
				});

				if (index !== -1 && indexTask !== -1)
				{
					let value: any = this.workspaceData.globalTask[index][nameField];

					let index2 = _.findIndex(value, (val: any) =>
					{
						return val.id === idField;
					});

					if (index2 !== -1)
					{
						this.workspaceData.globalTask[index][nameField][index2] = this.workspaceData.globalTask[indexTask];
					}
					else
					{
						this.workspaceData.globalTask[index][nameField].push(this.workspaceData.globalTask[indexTask]);
					}
				}
				break;


			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			case 'delete':
				index = _.findIndex(this.workspaceData.globalTask, (val: any) =>
				{ 
					return val.id === idTask;
				});

				if (index !== -1)
				{
					let index2 = _.findIndex(this.workspaceData.globalTask[index][nameField], (val: any) =>
					{
						return val.id === idField;
					});

					if (index2 !== -1)
					{
						this.workspaceData.globalTask[index][nameField].splice(index2, 1);

						//We update lock state of task
						this.workspaceData.globalTask[index].locked = this.workspaceData.globalTask[index].lockRules.length > 0;
					}
				}
				break;
			default:
		}
	}


	/**
	 * Cette fonction est appelée lorsque des informations relativres aux
	 * utilisateurs sont transmises par socket
	 * @param datas
	 */
	public _onUsersReturned(datas: any)
	{
		if (datas.cmd && datas.cmd === 'users')
		{
			if (datas && datas.args && datas.args.data && datas.args.data.users)
			{
				this.usersAvailable = datas.args.data.users;
			}
		}
	}


	/**
	 * Cette fonction est appellée lorsque des informations relatives aux tâches
	 * sont transmises par socket
	 * @param datas
	 */
	public _onTasksReturned(datas: any)
	{
		if (datas.cmd && (datas.cmd === 'tasks.bynode' || datas.cmd === 'tasks.assigned'))
		{
			if (datas.args && datas.args.data && datas.args.data.tasks)
			{
				this.workspaceData.globalTask = _.map(datas.args.data.tasks, (taskJson: any) =>
                    {
                        return Task.fromJsonObject(taskJson);
                    });

				this._applyTasksByUser();
				this._applyTasksByGroup();

				this.isLoading		= false;
				this.tasksLoaded	= true;
			}
		}
	}


	public changeScenarioSelected(event: any)
	{
		this.titleScenarioSelected = event;
		this.adminData.scenarioSelected = _.find(this.adminData.scenariosAvailable, (scenario: any) => { return scenario.title === this.titleScenarioSelected; });

		this.taskData.getEpreuveByWorkflow(this.adminData.scenarioSelected.title);
	}



    private _initParameters()
    {
		//Initialisation des paramètres généraux
		//Initialisation des scénarios
		if (this.adminData.scenariosAvailable && this.adminData.scenariosAvailable.length > 0)
		{
			this.adminData.scenarioSelected = this.adminData.scenariosAvailable[0];
			this.titleScenarioSelected = this.adminData.scenarioSelected.title;

			this.taskData.getEpreuveByWorkflow(this.adminData.scenarioSelected.title);
		}


		let filterName	= '';
		let filterId	= '';
		if (this.workspaceData.selectedSujet)
		{
			filterName	= 'suj';
			filterId	= this.workspaceData.selectedSujet.id;
		}
		else if (this.workspaceData.selectedMatiere)
		{
			filterName	= 'matiere';
			filterId	= this.workspaceData.selectedMatiere.id;
		}
		else if (this.workspaceData.selectedExamen)
		{
			filterName	= 'exam';
			filterId	= this.workspaceData.selectedExamen.id;
		}
		else if (this.workspaceData.selectedSession)
		{
			filterName	= 'sess';
			filterId	= this.workspaceData.selectedSession.id;
		}
		else if (this.workspaceData.selectedEtablissement)
		{
			filterName	= 'etab';
			filterId	= this.workspaceData.selectedEtablissement.id;
		}
		this.taskData.filterTasks(filterName, filterId);
	}


	public onDrag(args: any)
	{
		let [e, el] = args;
	}

	public onDrop(args: any)
	{
		let [e, el] = args;
	}

	public onOver(args: any)
	{
		let [e, el, container] = args;
	}

	public onOut(args: any)
	{
		let [e, el, container] = args;
	}
}
