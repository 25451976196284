import { Comment }		from './comment.class';
import { Component }	from './component';
import { Note }			from './note.class';

import { FileUploader, FileItem }			from 'ng2-file-upload';


/**
 * Todo => changer le nom car réserver
 */
export class File extends Component
{


    /**
     * 
     * @param jsonObj 
     */
    public static fromJsonObject(jsonObj: any): File
    {
        const newFile = new File();

        newFile.id			= jsonObj.id			|| jsonObj.uuid;
        newFile.idSubject	= jsonObj.idSubject		|| jsonObj.idsubject;
        newFile.idEpreuve	= jsonObj.idEpreuve		|| jsonObj.idepreuve	|| '';
        newFile.name		= jsonObj.name			|| jsonObj.libelle;
        newFile.path		= jsonObj.path			|| '';
        newFile.pathPdf		= jsonObj.pathPdf		|| jsonObj.pathpdf		|| '';
        newFile.pathThumbs  = jsonObj.pathThumbs	|| jsonObj.paththumbs	|| [];

        const comments = [];

        // Initialisation des commentaires récupérés
        if (jsonObj.comments && Object.keys(jsonObj.comments).length > 0)
        {
            for (const key in jsonObj.comments)
            {
                if (jsonObj.comments[key])
                {
                    const objComment = Comment.fromJsonObject(jsonObj.comments[key]);
                    comments.push(objComment);
                }
            }
        }

        const historic = [];
        if (jsonObj.historic)
        {
            for (const version of jsonObj.historic)
            {
                historic.push(File.fromJsonObject(version));
            }
        }

        const notes = [];

        if (jsonObj.notes)
        {
            for (const note of jsonObj.notes)
            {
                notes.push(Note.fromJsonObject(note));
            }
        }


        newFile.comments		= comments;
        newFile.notes           = notes;
        newFile.historic        = historic;

        const dateCreationJson = jsonObj.date || jsonObj.dateCreation || Date.parse(jsonObj.date_creation);
        if (dateCreationJson)
        {
            newFile.dateCreation = new Date(dateCreationJson);
        }

        newFile.author				= jsonObj.author			|| jsonObj.createur				|| '';
        newFile.description			= jsonObj.description		|| jsonObj.descriptif			|| '';
        newFile.types			    = jsonObj.typeFile			|| jsonObj.types				|| '';
		newFile.tags			    = jsonObj.tags				|| jsonObj.tags					|| '';

        const dateLastUpdateJson = jsonObj.dateLastUpdate || Date.parse(jsonObj.date_last_update);
        if (dateLastUpdateJson)
        {
            newFile.dateModification	= new Date(dateLastUpdateJson);
        }

        newFile.extension			= jsonObj.extension			|| '';
        newFile.encoded				= jsonObj.chiffrage			|| false;
        newFile.updater				= jsonObj.updater			|| '';
        newFile.editable			= jsonObj.editable			|| false;
		newFile.typeDocument        = '';

		// ---------------------------------------
		// Gestion des tags, relation doc / tache
		// ---------------------------------------
		const flagtype: any = {};

		for (const value of newFile.tags)
		{
			// si on a des :: on splite
			if (value.startsWith('*'))
			{
				const arrayValue	= value.split('::');
				const key			= arrayValue[0];
				const valueTag	    = arrayValue[1];

				switch (key)
				{
					case '*type':
						newFile.typeDocument = valueTag;
						break;

					case '*task':
                        newFile.taskId = valueTag;
						break;

					case '*check':
						newFile.check = valueTag;
                        break;

                    case 'deleted':
                        newFile.deleted = true;
						break;
					default:
						break;
				}
			}
		}

        return newFile;
    }


    /**
     * Creates an instance of File
     *
     * @param _id
     * @param _name
     * @param _comments
     * @param _author
     * @param _description
     * @param _extension
     * @param _types
     * @param _updater
     *
     * @memberOf File
     */
    constructor(
        private _idSubject:			string			= '',
        private _idEpreuve:			string			= '',
        private _path:				string			= '',
        private _pathPdf:			string			= '',
        private _errorPdf:			boolean         = false,
        private _pathThumbs:		string[]		= [],
        private _errorThumb:		boolean         = false,
        private _comments:			Comment[]		= [],
        private _historic:			File[]			= [],
        private _notes:				Note[]			= [],
        private _author:			string			= '',
        private _extension:			string			= '',
        private _taskId:			string			= '',
        private _currentStep:		string          = '',
        private _check:				string			= '',
        private _types:				string[]		= [],
        private _tags:				string[]		= [],
        private _typeDocument:		string			= '',
        private _encoded:			boolean			= false,
        private _updater:			string			= '',
        private _thumbs:			any[]			= [],
        private _completed:			boolean			= true,
        private _modified:			boolean         = false,
        private _fileItem:			FileItem		= null,
        private _uploader:			FileUploader	= null,
		private _encryptedAesKeys:	any[]			= [],
		private _decryptedAesKeys: 	any[]			= [],
        private _editable:			boolean         = false,
        private _isDownloading:		boolean         = false,
        private _deleted:			boolean         = false
    )
	{
        super();
    }

    get idSubject()			{ return this._idSubject; }
    get idEpreuve()			{ return this._idEpreuve; }
    get path()				{ return this._path; }
    get pathPdf()			{ return this._pathPdf; }
    get pathThumbs()		{ return this._pathThumbs; }
    get errorThumb()		{ return this._errorThumb; }
    get errorPdf()			{ return this._errorPdf; }
    get thumbs()			{ return this._thumbs; }
    get comments()			{ return this._comments; }
    get historic()			{ return this._historic; }
    get notes()				{ return this._notes; }
    get author()			{ return this._author; }
    get extension()			{ return this._extension; }
    get taskId()			{ return this._taskId; }
    get currentStep()		{ return this._currentStep; }
    get check()				{ return this._check; }
    get tags()				{ return this._tags; }
    get types()				{ return this._types; }
    get typeDocument()		{ return this._typeDocument; }
    get encoded()			{ return this._encoded; }
    get updater()			{ return this._updater; }
    get completed()			{ return this._completed; }
    get modified()			{ return this._modified; }
    get uploader()			{ return this._uploader; }
    get fileItem()			{ return this._fileItem; }
	get encryptedAesKeys()	{ return this._encryptedAesKeys; }
	get decryptedAesKeys()	{ return this._decryptedAesKeys; }
    get editable()			{ return this._editable; }
    get isDownloading()		{ return this._isDownloading; }
    get deleted()			{ return this._deleted; }


    set idSubject(idSubject)                { this._idSubject			= idSubject; }
    set idEpreuve(idEpreuve)                { this._idEpreuve			= idEpreuve; }
    set path(path)                          { this._path				= path; }
    set pathPdf(pathPdf)                    { this._pathPdf				= pathPdf; }
    set errorPdf(errorPdf)                  { this._errorPdf			= errorPdf; }
    set pathThumbs(pathThumbs)              { this._pathThumbs			= pathThumbs; }
    set errorThumb(errorThumb)              { this._errorThumb			= errorThumb; }
    set thumbs(thumbs)                      { this._thumbs				= thumbs; }
    set comments(comments)                  { this._comments			= comments; }
    set historic(historic)                  { this._historic			= historic; }
    set notes(notes)                        { this._notes				= notes; }
    set author(author)                      { this._author				= author; }
    set extension(extension)                { this._extension			= extension; }
    set taskId(taskId)				        { this._taskId				= taskId; }
    set currentStep(step)                   { this._currentStep			= step; }
    set tags(tags)					        { this._tags				= tags; }
    set check(check)				        { this.check				= check; }
    set types(types)                        { this._types				= types; }
    set typeDocument(typeDocument)          { this._typeDocument		= typeDocument; }
    set encoded(encoded)                    { this._encoded				= encoded; }
    set updater(updater)                    { this._updater				= updater; }
	set completed(completed)				{ this._completed			= completed; }
    set modified(modified)                  { this._modified			= modified; }
    set uploader(uploader)                  { this._uploader			= uploader; }
    set fileItem(fileItem)                  { this._fileItem			= fileItem; }
	set encryptedAesKeys(encryptedAesKeys)  { this._encryptedAesKeys	= encryptedAesKeys; }
	set decryptedAesKeys(decryptedAesKeys)  { this._decryptedAesKeys	= decryptedAesKeys; }
    set editable(editable)                  { this._editable			= editable; }
    set isDownloading(isDownloading)        { this._isDownloading		= isDownloading; }
    set deleted(deleted)                    { this._deleted				= deleted; }


    /**
     * 
     */
	public calcMoyenneNotes()
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (this.notes.length === 0)
		{
			return 0;
		}
		else
		{
			let sum: number = 0;
			for (const note of this.notes)
			{
				sum += note.note;
			}
			return sum / this.notes.length;
		}
	}


    /**
     * 
     */
    public getVersions()
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        const versions = [{
            libelle:	this.name,
            date:		this.dateCreation,
            path:		this.path
        }];


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        for (const version of this.historic)
        {
            versions.push({
                libelle:	version.name,
                date:		version.dateCreation,
                path:		version.path
            });
        }
        return versions;
    }


	/**
	 *
	 */
    public toJson(): any
    {

        const commentsJson = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        for (const comment of this.comments)
        {
            commentsJson.push(comment.toJson());
        }

        const notesJson = [];

        for (const note of this.notes)
        {
            notesJson.push(note.toJson());
        }
        const historicJson = [];

        for (const version of this.historic)
		{
			historicJson.push(version.toJson());
		}

		const tagsArray = [];

		// ---------------------------------------
		// On récupère les valeurs de type et de tache ... pour remettre dans tags
		// ---------------------------------------
		if (this.typeDocument)
		{
			tagsArray.push('*type::' + this.typeDocument);
		}
		if (this.taskId)
		{
			tagsArray.push('*task::' + this.taskId);
		}
		if (this.check)
		{
			tagsArray.push('*check::' + this.check);
        }
        if (this.deleted === true)
        {
            tagsArray.push('deleted');
        }


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        const result = {
            id:					this.id,
            idsubject:			this.idSubject,
            idepreuve:			this.idEpreuve,
            libelle:			this.name,
            path:				this.path,
            pathPdf:            this.pathPdf,
            paththumbs:			this.pathThumbs,
            comments:			commentsJson,
            historic:			historicJson,
            notes:				notesJson,
            createur:			this.author,
            descriptif:			this.description,
            extension:			this.extension,
            types:				this.types,
			tags:				tagsArray,
            updater:			this.updater,
            chiffrage:			this.encoded,
            date_creation:		this.dateCreation,
            editable:		    this.editable,
            date_last_update:	this.dateModification
        };
        return result;
    }
}


