<div class="ion-header">
	<!-- Partie Tabs-->
	<div class="ion-navbar tabs-page community-tab">
		<mat-tab-group #matTabs [(selectedIndex)]="panelSelected" (selectedIndexChange)="refreshTab($event)" >
			<mat-tab *ngIf="canAccessChat" label="{{ 'chat.title' | translate }}" >
				<div class="ion-content">
					<app-chat-list></app-chat-list>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

