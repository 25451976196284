import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';


@Component({
	selector:		'password-editor',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./passwordEditor.component.scss'],
	templateUrl:	'passwordEditor.component.html'
})

export class PasswordEditorComponent
{
	@Input()	class:			string	= '';
	@Input()	id:				string	= '';
	@Output()	onValidPassword			= new EventEmitter<any>();

	public passwordCurrent: any = undefined;
	public password1: any;
	public password2: any;


	@Input()
	set password(password: string)
	{
		if (password)
		{
			this.password1	= password;
			this.password2	= password;
			this.value		= password;

			this.evaluatePassword();
		}
	}


	@Input()
	set currentPassword(currentPassword: string)
	{
		if (currentPassword)
		{
			this.passwordCurrent = currentPassword;
			this.checkValidPassword();
		}
	}

	public	value:		string;
	public	message:	string;
	public	symbool:	any		= { 'isit': false };
	public	nucbool:	any		= { 'isit': false };
	public	numbool:	any		= { 'isit': false };

	constructor(public translate: TranslateService)
	{
		translate.get('passwordEditor.instructions').subscribe((txt: string) => {
            this.message	= txt;
        });
	}


	/**
	 *
	 */
	onKeyPassword(event: any)
	{
		this.value = event.target.value;

		this.evaluatePassword();
		this.checkValidPassword();
	}


	/**
	 *
	 */
	evaluatePassword()
	{
		let numberOfUpperCase	= this.value.replace(/[^A-Z]/g, "").length; // Uppercase Letters
//		let numberOfLowerCase	= this.value.replace(/[^a-z]/g, "").length; // Lowercase Letters
		let numberOfNumber		= this.value.replace(/[^0-9]/g, "").length; // Numbers

		let numberOfSymbol: number;
		let sym = this.value.match(/[ !@#$£%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g); // Symbols
		if (!sym) { numberOfSymbol = 0 } else { numberOfSymbol = sym.length };

		if (sym == null)
		{
			this.symbool['isit'] = false;
		}
		else
		{
			this.symbool['isit'] = true;
		}

		if (numberOfUpperCase === 0)
		{

			this.nucbool['isit'] = false;
		}
		else
		{
			this.nucbool['isit'] = true;
		}

		if (numberOfNumber === 0)
		{
			this.numbool['isit'] = false;
		}
		else
		{
			this.numbool['isit'] = true;
		}
	}


	/**
	 *
	 * @param event
	 */
	onKeyPasswordConfirm(event: any)
	{
		this.checkValidPassword();
	}


	/**
	 *
	 */
	checkValidPassword()
	{
		if (this.passwordCurrent === undefined || this.passwordCurrent !== this.password1)
		{
			if (this.symbool['isit'] === true && this.nucbool['isit'] === true && this.numbool['isit'] === true && this.password1 === this.password2)
			{
				this.onValidPassword.emit(this.password1);
			}
			else
			{
				this.onValidPassword.emit(false);
			}
		}
		else
		{
			this.onValidPassword.emit(false);
		}
	}
}
