import { Component, ViewEncapsulation, Inject }				from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators }	from '@angular/forms';
import { MatDialogRef }										from '@angular/material';
import { MAT_DIALOG_DATA }									from '@angular/material';
import { TranslateService } 						        from '@ngx-translate/core';

import { Comment }			from '../../../../../../classes/comment.class';
import { Task }				from '../../../../../../classes/task.class';
import { Matiere }				from '../../../../../../classes/matiere.class';
import { UserData }			from '../../../../../../providers/user-data';
import { WorkspaceData }	from '../../../../../../providers/workspace-data';
import { TaskService }		from '../../../../../../services/task.service';
import { MessagesService }	from '../../../../../../services/messages.service';

@Component({
    selector:		'validate-task-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'validateTaskModal.component.html',
    styleUrls:		['./validateTaskModal.component.scss']
})


/**
 *
 */
export class ValidateTaskModalComponent
{
    public currentComment:	Comment;
    public moFormGroup:		FormGroup;
    public currentTask:		Task;

    public nextAction: string = '1';

    public numberCurrentStep:   any;
    public nameCurrentStep:     any;
    public nameNextStep:        any;
    public namePreviousStep:    any;

    public textHeader:				string;
    public textValidationButton:	string;

    public sendEmail: boolean = false;

    public currentState:    string;
	public cancelComment:   any;

    moFormCtrlMessage:		FormControl;
    moFormCtrlSendEmail:	FormControl;


    /**
     *
     * @param dialogRef
     * @param fb
     * @param userData
     * @param workspaceData
     * @param taskService
     * @param messagesService
     * @param translate
     * @param data
     */
    constructor(public dialogRef:			MatDialogRef<ValidateTaskModalComponent, any>,
                public fb:					FormBuilder,
                public userData:			UserData,
                public workspaceData:		WorkspaceData,
                public taskService:			TaskService,
                public messagesService:		MessagesService,
                public translate:           TranslateService,
				@Inject(MAT_DIALOG_DATA)	public data: any)
    {
        this.currentComment = new Comment();

        if (this.data && this.data.task)
		{
            this.currentTask = this.data.task;

            if (this.data.state)
            {
                this.currentState = this.data.state;
            }
            else
            {
                this.currentState = this.currentTask.state;
            }


            this.moFormCtrlMessage		= this.fb.control(this.currentComment.message, Validators.required);
            this.moFormCtrlSendEmail	= this.fb.control(this.sendEmail);

            this.moFormGroup =	this.fb.group({
                message:		this.moFormCtrlMessage,
                sendEmail:		this.moFormCtrlSendEmail
            });

			if (this.currentState === 'review')
			{
				this.sendEmail = true;
				this.translate.get('validateTask.validationButton1').subscribe((value: string) =>
				{
					this.textValidationButton = value;
				});

                this.nameCurrentStep = '';

				let oEpreuve = this.workspaceData.getEpreuve(this.currentTask.idEpreuve);

                if (this.currentTask.type && oEpreuve.scenario.steps)
                {
                    this.numberCurrentStep = Number(this.currentTask.type);

                    if (oEpreuve.scenario.steps[this.numberCurrentStep])
                    {
                        this.nameCurrentStep = oEpreuve.scenario.steps[this.numberCurrentStep].name;
                    }

                    //On récupère le nom de la prochaine étape (si l'étape courante n'est pas déja la dernière)
                    if (this.numberCurrentStep < oEpreuve.scenario.steps.length - 1 && oEpreuve.scenario.steps[this.numberCurrentStep + 1])
                    {
                        this.nameNextStep = oEpreuve.scenario.steps[this.numberCurrentStep + 1].name;
                    }

                    //On récupère le nom de l'étape précédente (si l'étape courante n'est pas déja la première)
                    if (this.numberCurrentStep > 0 && oEpreuve.scenario.steps[this.numberCurrentStep - 1])
                    {
                        this.namePreviousStep = oEpreuve.scenario.steps[this.numberCurrentStep - 1].name;
                    }
                }
                this.translate.get('validateTask.headerText1').subscribe((value: string) =>
                {
                    this.textHeader = value + this.nameCurrentStep;
                });
            }
            else
            {
                this.translate.get('validateTask.validationButton2').subscribe((value1: string) =>
				{
					this.translate.get('validateTask.headerText2').subscribe((value2: string) =>
					{
                        this.textValidationButton	= value1;
                        this.textHeader				= value2;
                    });
                });
            }
        }
    }


    /**
     * On ajoute le commentaire à la tache concernée
     * et on la passe au status "à valider"
     */
    public validate()
    {
        /**
         * Si le commentaire est saisie on l'enregistre en base de données pour la tache concernée
         */
        if (this.currentComment && this.currentComment.message && this.currentComment.message !== '')
        {
			const baseChannelSubject = '*soft::' + this.userData.account + '::creation::suj::';
			const datasComment: any =
			{
				action:		'create',
				comment:	this.currentComment.message,
				user:		this.userData.mail
			};


			// ce if a été mis là pour éviter une erreur, mais je pense que c'est un pb
			if (this.workspaceData.selectedSujet)
			{
				const notifiers: any[] = [baseChannelSubject + this.workspaceData.selectedSujet.id];
				this.messagesService.sendMessage('tasks', this.currentTask.id, 'C', datasComment, notifiers);
				this.messagesService.sendMessage('subjects', this.workspaceData.selectedSujet.id, 'C', datasComment, notifiers);
			}
			else if (this.currentTask.idSujet)
			{
				const notifiers: any[] = [baseChannelSubject + this.currentTask.idSujet];
				this.messagesService.sendMessage('tasks', this.currentTask.id, 'C', datasComment, notifiers);
				this.messagesService.sendMessage('subjects', this.currentTask.idSujet, 'C', datasComment, notifiers);
			}
        }

        const   newDatas: any = {};
		let     newTask;

		const currentSujet = this.workspaceData.getSujet(this.currentTask.idSujet);

        const datasEmail: any =
        {
			nomSujet: currentSujet ? currentSujet.name : '',
			nomEtape: this.currentTask.typeLib
		};

        if (this.currentState === 'review')
        {
			if ((this.nextAction === '1' || this.nextAction === '3' || this.nextAction === '4') && this.sendEmail === true)
        	{
				this.currentTask.notifyByMail   = true;
				newDatas.typeMail               = 'validatedTask';
				newDatas.datasEmail             = datasEmail;
			}

            switch(this.nextAction)
            {
                case '1':
                    // On met la tache courante en 'done' et on créé une nouvelle tache avec
                    // l'étape suivante
                    newDatas.state = 'done';
                    this.taskService.updateTask(this.currentTask, newDatas);
                    newTask = this._initialiseNewTask('next');
                    break;


                case '2':
                    // On met la tache courante en 'inprogress'
                    newDatas.state = 'inprogress';
                    this.taskService.updateTask(this.currentTask, newDatas);
                    break;


                case '3':
                    //On met la tache courante en 'done'  et on créé une nouvelle tache avec
                    //l'étape précédente
                    newDatas.state = 'done';
                    this.taskService.updateTask(this.currentTask, newDatas);
                    newTask = this._initialiseNewTask('previous');
                    break;


                case '4':
                    //On met uniquement la tache courante en 'done'
                    newDatas.state = 'done';
                    this.taskService.updateTask(this.currentTask, newDatas);
                    break;


                default:
                    break;
			}
        }
        else
        {
			if (this.sendEmail === true)
        	{
				this.currentTask.notifyByMail = true;
				newDatas.typeMail = 'terminatedTask';
				newDatas.datasEmail = datasEmail;
			}

			newDatas.state = 'review';

            this.taskService.updateTask(this.currentTask, newDatas);
        }
        this.dialogRef.close(newTask);
    }


    /**
     *
     */
    public cancel()
    {
        this.dialogRef.close();
    }


    /**
     *
     * @param event
     */
    public onChangeAction(event: any)
    {
        this.nextAction = event.toString();

        if (this.nextAction === '1' || this.nextAction === '3')
		{
			this.translate.get('validateTask.validationButton1').subscribe((value3: string) =>
			{
				this.textValidationButton = value3;

			});
        }
        else
        {
			this.translate.get('validateTask.validationButton3').subscribe((value3: string) =>
			{
				this.textValidationButton = value3;
			});
		}
    }


    /**
     *
     */
    public canSendMail()
    {
        let result = false;

        if (this.currentState === 'review')
        {
            result = true;
        }
        else
        {
            if (this.userData.mail !== this.currentTask.assigneduser)
            {
                result = true;
            }
        }

        return result;
    }


    /**
     *
     * @param nature
     */
    private _initialiseNewTask(nature: string)
    {
        let newTask:		Task;
        let libelleNewStep: string = '';

		let oEpreuve = this.workspaceData.getEpreuve(this.currentTask.idEpreuve);

        if (oEpreuve.scenario.steps)
        {
            if (nature === 'next' && oEpreuve.scenario.steps[this.numberCurrentStep + 1])
            {
                newTask = new Task();

                let newStep		= this.numberCurrentStep + 1;
                let strNewStep	= newStep.toString();
                newTask.type	= strNewStep;
                libelleNewStep	= oEpreuve.scenario.steps[this.numberCurrentStep + 1].name;
            }
            else
            {
                if (nature === 'previous' && oEpreuve.scenario.steps[this.numberCurrentStep - 1])
                {
                    newTask = new Task();

                    let newStep		= this.numberCurrentStep - 1;
                    let strNewStep	= newStep.toString();
                    newTask.type	= strNewStep;
                    libelleNewStep	= oEpreuve.scenario.steps[this.numberCurrentStep - 1].name;
                }
            }

            if (newTask)
            {
                newTask.idEpreuve		= this.currentTask.idEpreuve;
                newTask.canCreateFile	= true;

                this.translate.get('validateTask.taskName1').subscribe((value1: string) => {
                    this.translate.get('validateTask.taskName2').subscribe((value2: string) => {

                        let newTaskName = '';
                        newTaskName		+= value1;
                        newTaskName		+= libelleNewStep;

						const currentSujet = this.workspaceData.getSujet(this.currentTask.idSujet);

                        if (this.workspaceData.selectedSujet)
                        {
                            newTaskName += value2;
                            newTaskName += currentSujet ? currentSujet.name : '';
                        }

                        newTask.name	= newTaskName;
                    });
                });
                newTask.idSujet = this.currentTask.idSujet;
            }
        }
        return newTask;
    }
}


