import { Injectable, EventEmitter } from '@angular/core';
import { GenericData }		from './generic-data';

import { ChatData }	from './chat-data';
import { UserData }	from './user-data';

import { Router }	from '@angular/router';

import { Etablissement }	from '../classes/etablissement.class';
import { Session }			from '../classes/session.class';
import { Examen }			from '../classes/examen.class';
import { Matiere }			from '../classes/matiere.class';
import { Sujet }			from '../classes/sujet.class';
import { Task }				from '../classes/task.class';
import { File as EFile }	from '../classes/file.class';
import * as _				from 'underscore';

// import { Storage } from '@ionic/storage';

@Injectable()


/**
 * Class UserData
 */
export class WorkspaceData extends GenericData
{

	// Selection user
	private _selectedEtablissement:	Etablissement	= null;
	private _selectedSession:		Session			= null;
	private _selectedExamen:		Examen			= null;
	private _selectedMatiere:		Matiere			= null;
	private _selectedSujet:			Sujet			= null;


	private _selectedHomeEtablissement:	Etablissement	= null;
	private _selectedHomeSession:		Session			= null;
	private _selectedHomeExamen:		Examen			= null;
	private _selectedHomeMatiere:		Matiere			= null;
	private _selectedHomeSujet:			Sujet			= null;


	private _selectedTask:			Task			= null;
	private _selectedElement:		any				= null;

	// Les datas
	private _etablissements:			Etablissement[] = [];
	private _selectedSegment:			string			= null;
	private _epreuveByWorkflowSelected:	any[]			= [];


	/**
	 * Stockage des taches récupérées dans l'appli
	 */
	private _taskDatas:					any		= {};
	private _taskHomeAdmin:				Task[]	= [];
	private _taskHomeAdminSave:			Task[]	= [];
	private _taskHomeAdminToValid:		Task[]	= [];
	private _taskHomeAdminToValidSave:	Task[]	= [];
	private _taskHomeAdminExpired:		Task[]	= [];
	private _taskHomeAdminExpiredSave:	Task[]	= [];
	private _epreuveHomeAdmin:			any[]	= [];
	private _epreuveHomeAdminSave:		any[]	= [];
	private _taskHomeMine:				Task[]	= [];
	private _taskHomeMineSave:			Task[]	= [];
	private _globalTask:				Task[]	= [];

	private _historicFromElement: any = {};

	private _isInfoTaskMineOk:	boolean	= false;
	private _isInfoTaskAdminOk:	boolean	= false;
	private _isInfoNodeOk:		boolean	= false;
	private _isInfoSubjectOk:	boolean	= false;
	private _isHomeEpreuveOk:	boolean	= false;
	public selectedItems:		any[]	= [];

	public goToSubject:			boolean	= false;

	private _statsNode:		any;
	private _statsNode2:	any;
	private _statsNodeEpr:	any;

	private _openedItemsBySearch: any[] = [];

	private _sujetKey:	any;

	public workspaceEmitter: EventEmitter<any> = new EventEmitter<any>();

	private _fileForEdit: any;

	public urlCreator = window.URL;
	public taskData: any;

	public mbisWorkspaceLoading: boolean = false;

	/**
	 *
	 */
	constructor(
				public chatData:	ChatData,
				public router:		Router,
				public userData:	UserData
				)
	{
		super();
	}


	/**
	 *
	 * @param taskData
	 */
	setTaskData(taskData: any)
	{
		this.taskData = taskData;
	}


	/**
	 *
	 */
	get fileForEdit() { return this._fileForEdit; }


	/**
	 *
	 */
	get selectedEtablissement(): Etablissement
	{
		let oReturn = this._selectedEtablissement;
		if (this._selectedEtablissement === null)
		{
			let etablissementId			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedEtablissementId');
			oReturn						= this.getEtablissement(etablissementId);
			this._selectedEtablissement = oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedSession():			Session
	{
		let oReturn = this._selectedSession;
		if (this._selectedSession === null)
		{
			let sessionId 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedSessionId');
			oReturn 				= this.getSession(sessionId);
			this._selectedSession 	= oReturn;
		}
		return oReturn;
	}


	/**
	 *
	 */
	get selectedExamen():			Examen
	{
		let oReturn = this._selectedExamen;
		if (this._selectedExamen === null)
		{
			let examenId 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedExamenId');
			oReturn 				= this.getExamen(examenId);
			this._selectedExamen 	= oReturn;
		}
		return oReturn;
	}


	/**
	 *
	 */
	get selectedMatiere():			Matiere
	{
		let oReturn = this._selectedMatiere;
		if (this._selectedMatiere === null)
		{
			let matiereId 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedMatiereId');
			oReturn 				= this.getEpreuve(matiereId);
			this._selectedMatiere 	= oReturn;
		}
		return oReturn;
	}


	/**
	 *
	 */
	get selectedSujet():			Sujet
	{
		let oReturn = this._selectedSujet;
		if (this._selectedSujet === null)
		{
			let subjectId 		= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedSubjectId');
			oReturn 			= this.getSujet(subjectId);
			this._selectedSujet = oReturn;
		}
		return oReturn;
	}


	/**
	 *
	 */
	get selectedTask():			    Task
	{
		let oReturn = this._selectedTask;
		if (this._selectedTask === null)
		{
			let taskId 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedTaskId');
			oReturn 			= this.taskData.getTaskById(taskId);
			this._selectedTask 	= oReturn;
		}
		return oReturn;
	}


	/**
	 *
	 */
	get selectedElement():			any
	{
		let oReturn = this._selectedElement;
		if (this._selectedElement === null)
		{
			let elementId 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedElementId');
			oReturn 				= this.getTreeElement(elementId);
			this._selectedElement 	= oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedSegment():			string			{ return this._selectedSegment; }
	get taskDatas()									{ return this._taskDatas; }

	get sujetKey()
	{
		let oReturn = this._sujetKey;
		if (this._sujetKey === null)
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::sujetKey');
			this._sujetKey = oReturn;
		}

		return oReturn;
	}


	get epreuveByWorkflowSelected()			{ return this._epreuveByWorkflowSelected; }
	get isHomeEpreuveOk()					{ return this._isHomeEpreuveOk; }
	set isHomeEpreuveOk(isHomeEpreuveOk)	{ this._isHomeEpreuveOk = isHomeEpreuveOk; }


	get statsNode()
	{
		return this._statsNode;
	}

	get statsNodeEpr()
	{
		return this._statsNodeEpr;
	}

	get statsNode2()
	{
		return this._statsNode2;
	}

	setEpr(statsNode: any)
	{
		this._statsNode = [];
		for (let key in statsNode)
		{
			this._statsNode.push(statsNode[key]);
		}
	}


	/**
	 *
	 */
	set statsNodeEpr(statsNode)
	{
		this._statsNodeEpr = [];

		for (let key in statsNode)
		{
			let formatedStatNode: any = {};

			//On récupère les propriétés existantes
			for (let keyStat in statsNode[key])
			{
				formatedStatNode[keyStat] = statsNode[key][keyStat];
			}

			formatedStatNode.nomeEpreuve	= '';
			formatedStatNode.nomExamen		= '';
			formatedStatNode.nomUser		= '';
			formatedStatNode.sCount			= '';
			formatedStatNode.echeanceLib	= '';
			formatedStatNode.isPrincipal	= false;
			formatedStatNode.isSecondary	= false;
			formatedStatNode.isAccepted		= false;


			formatedStatNode.sujetLib	= '';

			let oEpreuve;
			// Récupération du nom de l'épreuve
			if (statsNode[key].idEpreuve !== '')
			{
				oEpreuve = this.getEpreuve(statsNode[key].idEpreuve);

				if (oEpreuve)
				{
					formatedStatNode.nomEpreuve = oEpreuve.name;
				}
			}

			if(statsNode[key].status)
			{
				switch (statsNode[key].status)
				{
					case 'new':
						formatedStatNode.status = 'Non assigné';
						break;
					case 'todo':
						formatedStatNode.status = 'Assigné';
						break;
					case 'inprogress':
						formatedStatNode.status = 'En cours';
						break;
					case 'review':
						formatedStatNode.status = 'A valider';
						break;
					case 'done':
						formatedStatNode.status = 'Terminé';
						break;
					default:
						break;
				}
			}


			if (statsNode[key].nomUser === "undefined")
			{
				formatedStatNode.nomUser = ' - ';
			}

			// Nom de l'examen
			if (statsNode[key].idExamen !== '')
			{
				let oExamen = this.getExamen(statsNode[key].idExamen);
				if (oExamen !== null)
				{
					formatedStatNode.nomExamen = oExamen.name;
				}
			}
			// Nom de l'examen
			if (statsNode[key].echeance !== '')
			{
				let date = new Date(statsNode[key].echeance);
				formatedStatNode.echeanceLib = date.toLocaleDateString();
			}

			// Mise en forme des infos utilisateur
			if (statsNode[key].assigned_to !== '')
			{
				let oUser = this.chatData.getUser(statsNode[key].assigned_to);
				if (oUser)
				{
					if (oUser.nom !== '' && oUser.nom !== undefined)
					{
						formatedStatNode.nomUser += oUser.nom + ' ';
					}
					if (oUser.prenom !== '' && oUser.prenom !== undefined)
					{
						formatedStatNode.nomUser += oUser.prenom + ' ';
					}
					// Si on a rien on met le mail

				}
				else
				{
					formatedStatNode.nomUser = 'utilisateur inconnu';
				}

				if (oUser)
				{
					if (statsNode[key].nomUser === '')
					{
						formatedStatNode.nomUser += oUser.id;
					}
				}
				else
				{
					formatedStatNode.nomUser = 'utilisateur inconnu';
				}
			}

			let oTask;
			// Mise en forme info des taches


			if (statsNode[key].idTask !== '')
			{
				oTask = this._globalTask[statsNode[key].idTask];

			}

			if (statsNode[key].count !== '')
			{
				let sCount = [];
				let isFind = false;

				for (let key2 in statsNode[key].count)
				{
					if (key2 === statsNode[key].typeT)
					{
						isFind 			= true;
						let txtEtape 	= key2;

						if (oEpreuve && oEpreuve.scenario && oEpreuve.scenario.steps)
						{
							let step =  _.find(oEpreuve.scenario.steps, (step: any) => { return step.id.toString() === key2; });

							if (step)
							{
								txtEtape = step.name;
							}
						}

						if (Number(statsNode[key].count[key2]) < 2)
						{
							sCount.push(txtEtape);
						}
						else
						{
							sCount.push( txtEtape + '(' + statsNode[key].count[key2] + ')');
						}
					}
					else
					{

					}
				}
				if(!isFind)
				{
					let txtEtape 	= 'Création terminée';
					sCount 			= [];

					sCount.push(txtEtape);
				}
				formatedStatNode.sCount = sCount;
			}

			if(statsNode[key].idSujet !== '')
			{

				let oSujet = this.getSujet(statsNode[key].idSujet);
				if (oSujet)
				{
					formatedStatNode.sujetLib = oSujet.name;
					if (oSujet.datas && oSujet.datas.etat && !formatedStatNode.ps)
					{
						formatedStatNode.ps = oSujet.datas.etat;
					}
				}
			}

			this._statsNodeEpr.push(formatedStatNode);
		}
	}




	/**
	 * LEs node sauf epreuve
	 */
	set statsNode(statsNode)
	{
		this._statsNode = [];
		for (let key in statsNode)
		{
			let formatedStatNode: any = {};

			//On récupère les propriétés existantes
			for (let keyStat in statsNode[key])
			{
				formatedStatNode[keyStat] = statsNode[key][keyStat];
			}

			formatedStatNode.nomeEpreuve	= '';
			formatedStatNode.nomExamen		= '';
			formatedStatNode.nomEtab		= '';
			formatedStatNode.nomSession		= '';
			formatedStatNode.nomUser		= '';
			formatedStatNode.sCount			= '';
			formatedStatNode.echeanceLib	= '';
			formatedStatNode.isPrincipal	= false;
			formatedStatNode.isSecondary	= false;
			formatedStatNode.isAccepted		= false;
			formatedStatNode.sujetLib		= '';

			let oEpreuve: Matiere;

			// Mise en forme du status
			if(statsNode[key].status)
			{
				switch (statsNode[key].status)
				{
					case 'new':
						formatedStatNode.status = 'Non assigné';
						break;
					case 'todo':
						formatedStatNode.status = 'Assigné';
						break;
					case 'inprogress':
						formatedStatNode.status = 'En cours';
						break;
					case 'review':
						formatedStatNode.status = 'A valider';
						break;
					case 'done':
						formatedStatNode.status = 'Terminé';
						break;
					default:
						break;
				}
			}
			// nom de l'user
			if (statsNode[key].nomUser === "undefined")
			{
				formatedStatNode.nomUser = ' - ';
			}

			let oEpr = this.getEpreuve(key);
			if (oEpr)
			{
				formatedStatNode.nomEpreuve = oEpr.name;
			}

			for (let key2 of statsNode[key].parents)
			{
				let keyArray = key2.split('::');

				switch (keyArray[0])
				{
					case '*etab':
						let oEtab = this.getEtablissement(keyArray[1]);
						if (oEtab)
						{
							formatedStatNode.nomEtab = oEtab.name;
						}
						break;
					case '*exam':
						let oExam = this.getExamen(keyArray[1]);
						if (oExam)
						{
							formatedStatNode.nomExamen = oExam.name;
						}
						break;
					case '*epr':
						let oEpr = this.getEpreuve(key);
						if (oEpr)
						{
							formatedStatNode.nomEpreuve = oEpr.name;
						}
						break;

					case '*sess':
						let oSess = this.getSession(keyArray[1]);
						if (oSess)
						{
							formatedStatNode.nomSession = oSess.name;
						}
						break;

					default:

						break;
				}
			}

			// Mise en forme des infos utilisateur
			if (statsNode[key].assigned_to !== '')
			{
				let oUser = this.chatData.getUser(statsNode[key].assigned_to);
				if (oUser)
				{
					if (oUser.nom !== '' && oUser.nom !== undefined)
					{
						formatedStatNode.nomUser += oUser.nom + ' ';
					}
					if (oUser.prenom !== '' && oUser.prenom !== undefined)
					{
						formatedStatNode.nomUser += oUser.prenom + ' ';
					}
					// Si on a rien on met le mail

				}
				else
				{
					formatedStatNode.nomUser = 'utilisateur inconnu';
				}

				if (oUser)
				{

					if (statsNode[key].nomUser === '')
					{
						formatedStatNode.nomUser += oUser.id;
					}
				}
				else
				{
					formatedStatNode.nomUser = 'utilisateur inconnu';
				}

			}

			let oTask;
			// Mise en forme info des taches
			if (statsNode[key].idTask !== '')
			{
				oTask = this._globalTask[statsNode[key].idTask];
			}

			if (statsNode[key].count !== '')
			{
				let sCount = [];
				for (let key2 in statsNode[key].count)
				{
					if (key2 === statsNode[key].typeT)
					{
						let txtEtape = key2;

						if (oEpreuve && oEpreuve.scenario && oEpreuve.scenario.steps)
						{
							let step = _.find(oEpreuve.scenario.steps, (step: any) => { return step.id.toString() === key2; });

							if (step)
							{
								txtEtape = step.name;
							}
						}

						if (Number(statsNode[key].count[key2]) < 2)
						{
							sCount.push(txtEtape);
						}
						else
						{
							sCount.push( txtEtape + '(' + statsNode[key].count[key2] + ')');
						}
					}
				}
				formatedStatNode.sCount = sCount;
			}

			if(statsNode[key].idSujet !== '')
			{

				let oSujet = this.getSujet(statsNode[key].idSujet);
				if (oSujet)
				{
					formatedStatNode.sujetLib = oSujet.name;
					if (oSujet.datas && oSujet.datas.etat && !formatedStatNode.ps)
					{
						formatedStatNode.ps = oSujet.datas.etat;
					}
				}
			}

			this._statsNode.push(formatedStatNode);
		}

	}


	/**
	 *
	 */
	set statsNode2(statsNode)
	{

		this._statsNode2 = [];
		for (let key in statsNode)
		{
			let formatedStatNode: any = {};

			//On récupère les propriétés existantes
			for (let keyStat in statsNode[key])
			{
				formatedStatNode[keyStat] = statsNode[key][keyStat];
			}

			formatedStatNode.nomeEpreuve	= '';
			formatedStatNode.nomExamen		= '';
			formatedStatNode.nomSession		= '';
			formatedStatNode.nomEtab		= '';
			formatedStatNode.nomUser		= '';
			formatedStatNode.sCount			= '';
			formatedStatNode.echeanceLib	= '';
			formatedStatNode.isPrincipal	= false;
			formatedStatNode.isSecondary	= false;
			formatedStatNode.isAccepted		= false;


			formatedStatNode.sujetLib	= '';

			let oEpreuve;
			// Récupération du nom de l'épreuve
			if (statsNode[key].idEpreuve !== '')
			{
				oEpreuve = this.getEpreuve(statsNode[key].idEpreuve);

				if (oEpreuve)
				{
					formatedStatNode.nomEpreuve = oEpreuve.name;
				}
			}
			// Nom de l'examen
			if (statsNode[key].idExamen !== '')
			{
				let oExamen = this.getExamen(statsNode[key].idExamen);

				if (oExamen)
				{
					formatedStatNode.nomExamen = oExamen.name;
				}
			}
			// Nom de l'examen
			if (statsNode[key].idSession !== '')
			{
				let oSession = this.getSession(statsNode[key].idSession);

				if (oSession)
				{
					formatedStatNode.nomSession = oSession.name;
				}
			}
			// Nom de l'examen
			if (statsNode[key].idEtablissement !== '')
			{
				let oEtab = this.getExamen(statsNode[key].idEtablissement);

				if (oEtab)
				{
					formatedStatNode.nomEtab = oEtab.name;
				}
			}
			// Nom de l'examen
			if (statsNode[key].echeance !== '')
			{
				let date = new Date(statsNode[key].echeance);
				formatedStatNode.echeanceLib = date.toLocaleDateString();
			}
			if (statsNode[key].status)
			{
				switch (statsNode[key].status)
				{
					case 'new':
						formatedStatNode.status = 'Non assignée';
						break;

					case 'todo':
						formatedStatNode.status = 'Assignée';
						break;

					case 'inprogress':
						formatedStatNode.status = 'En cours';
						break;

					case 'review':
						formatedStatNode.status = 'A valider';
						break;

					case 'done':
						formatedStatNode.status = 'Terminé';
						break;

					default:
						break;
				}
			}

			if (statsNode[key].nomUser === "undefined")
			{
				formatedStatNode.nomUser = ' - ';
			}

			// Mise en forme des infos utilisateur
			if (statsNode[key].assigned_to !== '')
			{
				let oUser = this.chatData.getUser(statsNode[key].assigned_to);

				if (oUser)
				{
					if (oUser.nom !== '' && oUser.nom !== undefined)
					{
						formatedStatNode.nomUser += oUser.nom + ' ';
					}
					if (oUser.prenom !== '' && oUser.prenom !== undefined)
					{
						formatedStatNode.nomUser += oUser.prenom + ' ';
					}

					// Si on a rien on met le mail
					if (statsNode[key].nomUser === '')
					{
						formatedStatNode.nomUser += oUser.id;
					}
				}

			}
			// Mise en forme info des taches
			if (statsNode[key].idTask !== '')
			{
				let oTask = this._globalTask[statsNode[key].idTask];
			}

			if (statsNode[key].count !== '')
			{
				let sCount = [];
				for (let key2 in statsNode[key].count)
				{
					if (key2 === statsNode[key].typeT)
					{
						let name 		= '';
						let txtEtape 	= key2;

						if (oEpreuve && oEpreuve.scenario && oEpreuve.scenario.steps)
						{
							let step =  _.find(oEpreuve.scenario.steps, (step: any) => { return step.id.toString() === key2; });

							if (step)
							{
								txtEtape = step.name;
							}
						}

						if(statsNode[key].count[key2] > 1)
						{
							sCount.push( txtEtape + '(' + statsNode[key].count[key2] + ')');
						}
						else
						{
							sCount.push(txtEtape);
						}
					}
				}
				formatedStatNode.sCount = sCount;
			}

			if(statsNode[key].idSujet !== '')
			{
				let oSujet = this.getSujet(statsNode[key].idSujet);
				if (oSujet)
				{
					formatedStatNode.sujetLib = oSujet.name;
					if (oSujet.datas && oSujet.datas.etat)
					{

						formatedStatNode.ps = oSujet.datas.etat;
					}
				}
			}

			this._statsNode2.push(formatedStatNode);
		}
	}


	set fileForEdit(fileForEdit) { this._fileForEdit = fileForEdit;}


	set selectedEtablissement(selectedEtablissement)
	{
		if (selectedEtablissement)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedEtablissementId', selectedEtablissement.id);
		}
		this._selectedEtablissement	= selectedEtablissement;
	}


	set selectedSession(selectedSession)
	{
		if (selectedSession)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedSessionId', selectedSession.id);
		}
		this._selectedSession = selectedSession;
	}


	set selectedExamen(selectedExamen)
	{
		if (selectedExamen)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedExamenId', selectedExamen.id);
		}
		this._selectedExamen = selectedExamen;
	}


	set selectedMatiere(selectedMatiere)
	{
		if (selectedMatiere)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedMatiereId', selectedMatiere.id);
		}
		this._selectedMatiere = selectedMatiere;
	}


	set selectedSujet(selectedSujet)
	{
		if (selectedSujet)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedSubjectId', selectedSujet.id);
		}
		else
		{
			localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedSubjectId');
		}
		this._selectedSujet	= selectedSujet;
	}


	set selectedTask(selectedTask)
	{
		if (selectedTask)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedTaskId', selectedTask.id);
		}
		this._selectedTask = selectedTask;
	}


	set sujetKey(sujetKey)
	{
		if (sujetKey)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::sujetKey', sujetKey);
		}
		this._sujetKey = sujetKey;
	}


	set selectedElement(selectedElement)
	{
		if (selectedElement)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedElementId', selectedElement.id);
		}
		this._selectedElement = selectedElement;
	}


	set etablissements(etablissements)
	{
		this._etablissements = etablissements;
	}


	resetSelectedEtablissement()
	{
		this._selectedEtablissement = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedEtablissementId');
	}


	resetSelectedSession()
	{
		this._selectedSession = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedSessionId');
	}


	resetSelectedExamen()
	{
		this._selectedExamen = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedExamenId');
	}


	resetSelectedMatiere()
	{
		this._selectedMatiere = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedMatiereId');
	}


	resetSelectedSujet()
	{
		this._selectedSujet = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedSubjectId');
	}


	set selectedSegment(selectedSegment)				{ this._selectedSegment			= selectedSegment;}
	set taskDatas(taskDatas)							{ this._taskDatas				= taskDatas; }
	set taskHomeAdmin(taskHomeAdmin)					{ this._taskHomeAdmin			= taskHomeAdmin; }
	set taskHomeAdminToValid(taskHomeAdminToValid: any)	{ this._taskHomeAdminToValid	= taskHomeAdminToValid; }
	set taskHomeAdminExpired(taskHomeAdminExpired)		{ this._taskHomeAdminExpired	= taskHomeAdminExpired; }
	set taskHomeMine(taskHomeMine)						{ this._taskHomeMine			= taskHomeMine; }
	set epreuveHomeAdmin(epreuveHomeAdmin)				{ this._epreuveHomeAdmin		= epreuveHomeAdmin; }
	set globalTask(globalTask)							{ this._globalTask				= globalTask; }


	/**
	 * Quand on a les data, si les 2 sont ok on precess, a la fin du process haveDatachat = true;
	 */
	set isInfoTaskMineOk(isOk: any)
	{
		this._isInfoTaskMineOk = isOk;
		if ((this._isInfoTaskMineOk === true || this._isInfoTaskAdminOk === true) && this._isInfoNodeOk === true && this._isInfoSubjectOk === true)
		{
			this.processData();
		}
	}


	/**
	 * Quand on a les data, si les 2 sont ok on precess, a la fin du process haveDatachat = true;
	 */
	set isInfoTaskAdminOk(isOk: any)
	{
		this._isInfoTaskAdminOk = isOk;
		if ((this._isInfoTaskMineOk === true || this._isInfoTaskAdminOk === true) && this._isInfoNodeOk === true && this._isInfoSubjectOk === true)
		{
			this.processData();
		}
	}


	/**
	 * Quand on a les data, si les 2 sont ok on precess, a la fin du process haveDatachat = true;
	 */
	set isInfoNodeOk(isOk: any)
	{
		this._isInfoNodeOk = isOk;
		if ((this._isInfoTaskMineOk === true || this._isInfoTaskAdminOk === true) && this._isInfoNodeOk === true && this._isInfoSubjectOk === true)
		{
			this.processData();
		}
	}

	/**
	 * Quand les sujets sont chargés on peux mettre à jour les informations des taches
	 * avec les infos des sujets
	 */
	set isInfoSubjectOk(isOk: any)
	{
		this._isInfoSubjectOk = isOk;
		if ((this._isInfoTaskMineOk === true || this._isInfoTaskAdminOk === true) && this._isInfoNodeOk === true && this._isInfoSubjectOk === true)
		{
			this.processData();
		}
	}


	set historic(historic)
	{
		this._historicFromElement = historic;
	}


	get globalTask(): any
	{
		let oReturn = [];
		for (let key in this._globalTask)
		{
			// On retourne sous cette forme pour que ça soit plus simple de parser coté html
			oReturn.push(this._globalTask[key]);
		}
		return oReturn;
	}


	get historic()
	{
		return this._historicFromElement;
	}


	// ---------------------------------------------------------------------------------------------------------------------
	// Spécifique
	// ---------------------------------------------------------------------------------------------------------------------
	public setTaskHomeAdmin(id: any, data: any)
	{
		this._taskHomeAdmin[id] = data;
	}

	public setTaskHomeExpired(id: any, data: any)
	{
		this._taskHomeAdminExpired[id] = data;
	}


	public setTaskHomeAdminToValid(id: any, data: any)
	{
		this._taskHomeAdminToValid[id] = data;
	}


	public setTaskHomeAdminExpired(id: any, data: any)
	{
		this._taskHomeAdminExpired[id] = data;
	}


	/**
	 *
	 * @param id
	 * @param data
	 */
	public setEpreuveHomeAdmin(id: any, data: any)
	{
		let epName			= '';
		let ariane			= '';
		let etabName		= '';
		let sessName		= '';
		let examName		= '';

		let oEpreuve	= this.getEpreuve( data.id);
		if (oEpreuve)
		{
			data.name = oEpreuve.name;
		}

		for (let key of data.parents)
		{
			let keyArray = key.split('::');

			switch (keyArray[0])
			{
				case '*etab':
					let oEtab = this.getEtablissement(keyArray[1]);
					if (oEtab)
					{
						etabName = oEtab.name;
					}
					break;

				case '*exam':
					let oExam = this.getExamen(keyArray[1]);
					if (oExam)
					{
						examName = oExam.name;
					}
					break;

				case '*sess':
					let oSess = this.getSession(keyArray[1]);
					if (oSess)
					{
						sessName = oSess.name;
					}
					break;

				default:
					break;
			}
		}
		ariane		= etabName + '/' + sessName + '/' + examName;
		data.ariane = ariane;

		this._epreuveHomeAdmin[id]		= data;
		this._epreuveHomeAdminSave[id]	= data;
	}


	/**
	 *
	 * @param pnIndice
	 */
	removeTaskToValid(pnIndice: any)
	{
		let aArray:any[]			= [];
		let nIndice					= 0;
//		this._taskHomeAdminExpired	= [];

		for (let key in this._taskHomeAdminToValid)
		{
			if (nIndice !== pnIndice)
			{
				aArray[key] = this._taskHomeAdminToValid[key];

			}
		}
		this._taskHomeAdminToValid = aArray;
	}


	/**
	 *
	 * @param pnIndice
	 */
	removeTaskExpired(pnIndice: any)
	{

		let aArray:any[]			= [];
		let nIndice					= 0;
//		this._taskHomeAdminExpired	= [];

		for (let key in this._taskHomeAdminExpired)
		{
			if (nIndice !== pnIndice)
			{
				aArray[key] = this._taskHomeAdminExpired[key];
			}
		}
		this._taskHomeAdminExpired = aArray;
	}


	/**
	 *
	 * @param pnIndice
	 */
	removeTaskToMine(pnIndice: any)
	{

		let aArray: any[] = [];
		//		this._taskHomeMine	= [];
		let nIndice = 0;

		for (let key in this._taskHomeMine)
		{
			if (nIndice !== pnIndice)
			{
				aArray[key] = this._taskHomeMine[key];
			}
			nIndice++;
		}

		this._taskHomeMine = aArray;
	}


	/**
	 *
	 * @param id
	 * @param data
	 */
	public setTaskHomeMine(id: any, data: any)
	{
		this._taskHomeMine[id] = data;
//		this.processData();
	}
	/**
	 *
	 * @param id
	 * @param data
	 */
	public cleanTaskHomeMine()
	{
		this._taskHomeMine = [];
//		this.processData();
	}


	/**
	 *
	 * @param idTask
	 * @param task
	 */
	public setGlobalTask(idTask: any, task: any)
	{
		let indexTask = _.findIndex(this._globalTask, (t: any) =>
		{
			return t.id === idTask;
		});

		if (indexTask === - 1)
		{
			this._globalTask.push(task);
		}
		else
		{
			this._globalTask[indexTask] = task;
		}
	}


	/**
	 *
	 * @param idTask
	 */
	public getGlobalTaskById(idTask: any)
	{
		let result = undefined;

		let indexTask = _.findIndex(this._globalTask, (t: any) =>
		{
			return t.id === idTask;
		});

		if (indexTask !== -1)
		{
			result = this._globalTask[indexTask];
		}
		return result;
	}


	public getEtablissement(id: any): Etablissement { return this._etablissements[id]; }
	public addEtablissement(id: any, data: Etablissement)
	{
		this._etablissements[id] 	= data;
		//this._selectedEtablissement = data;
	}


	/**
	 * Retourne la liste des établissements
	 */
	get etablissements(): Etablissement[]
	{
		let etabReturn = [];
		for (let key in this._etablissements)
		{
			// On retourne sous cette forme pour que ça soit plus simple de parser coté html
			etabReturn.push(this._etablissements[key]);
		}
		etabReturn.sort(function (a, b)
		{
			var nameA = a.name.toUpperCase(); // ignore upper and lowercase
			var nameB = b.name.toUpperCase(); // ignore upper and lowercase

			if (nameA < nameB)
			{
				return -1;
			}
			if (nameA > nameB)
			{
				return 1;
			}

			// names must be equal
			return 0;
		});
		return etabReturn;
	}


	/**
	 * Maj d'un établissement
	 */
	public majEtablissement(id: any, data: any)
	{
		// Msie à jours des éléments en mode GROS bourrin !
		// La maj c'est spécial
		if (data && this._etablissements)
		{
			if (this._etablissements[data.id])
			{
				this._etablissements[data.id].name				= data.name;
				this._etablissements[data.id].description		= data.description;
				this._etablissements[data.id].dateModification	= data.dateModification;
				this._etablissements[data.id].dateCreation		= data.dateCreation;
				this._etablissements[data.id].author			= data.author;


				if (this._selectedEtablissement && data.id === this._selectedEtablissement.id)
				{
					// Mise à jours des éléments selectionné
					this._selectedEtablissement.name				= data.name;
					this._selectedEtablissement.description			= data.description;
					this._selectedEtablissement.dateModification	= data.dateModification;
					this._selectedEtablissement.dateCreation		= data.dateCreation;
					this._selectedEtablissement.author				= data.author;


					if (this._selectedElement && data.id === this._selectedElement.id)
					{
						this._selectedElement.name					= data.name;
						this._selectedElement.description			= data.description;
						this._selectedElement.dateModification		= data.dateModification;
						this._selectedElement.dateCreation			= data.dateCreation;
						this._selectedElement.author				= data.author;
					}
				}
			}
			else
			{
				this._etablissements[data.id]	= data;
	//			this._selectedEtablissement		= data;
	//			this._selectedElement			= data;
			}
		}
	}


	/**
	 * Ajoute ou MAJ une session
	 */
	public setSession(id: string, data: Session, idParent: any)
	{
		let isFind = false;
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				if (session.id === id)
				{
					isFind					= true;
					session.name			= data.name;
					session.description		= data.description;
					session.dateCreation	= data.dateCreation;

					this._etablissements[key].sessions.sort(function (a, b)
					{
						var nameA = a.name.toUpperCase(); // ignore upper and lowercase
						var nameB = b.name.toUpperCase(); // ignore upper and lowercase
						if (nameA < nameB)
						{
							return -1;
						}
						if (nameA > nameB)
						{
							return 1;
						}

						// names must be equal
						return 0;
					});
				}
			}
		}
		// Cas ou la session est à ajouter
		if (!isFind)
		{
			for (let key in this._etablissements)
			{
				if (this._etablissements[key].id === idParent)
				{
					this._etablissements[key].sessions.push(data);
					this._etablissements[key].sessions.sort(function (a, b)
					{
						var nameA = a.name.toUpperCase(); // ignore upper and lowercase
						var nameB = b.name.toUpperCase(); // ignore upper and lowercase

						if (nameA < nameB)
						{
							return -1;
						}
						if (nameA > nameB)
						{
							return 1;
						}

						// names must be equal
						return 0;
					});
				}
			}
		}
	}


	/**
	 *
	 */
	public resetSelect()
	{
		this._selectedEtablissement = null;
		this._selectedSession		= null;
		this._selectedExamen		= null;
		this._selectedMatiere		= null;
		this._selectedSujet			= null;
		this._selectedElement		= null;
		this._sujetKey				= null;
		this.selectedItems			= [];
	}


	/**
	 *
	 */
	public resetHomeSelect()
	{
		this._selectedHomeEtablissement = null;
		this._selectedHomeSession		= null;
		this._selectedHomeExamen		= null;
		this._selectedHomeMatiere		= null;
		this._selectedHomeSujet			= null;
	}


	/**
	 * ajoute ou MAJ un examen
	 */
	public setExamen(id: string, data: Examen, idParent: any)
	{
		let isFind = false;
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					if (examen.id === id)
					{
						isFind 				= true;
						examen.name 		= data.name;
						examen.description 	= data.description;

						session.examens.sort(function (a, b)
						{
							var nameA = a.name.toUpperCase(); // ignore upper and lowercase
							var nameB = b.name.toUpperCase(); // ignore upper and lowercase
							if (nameA < nameB)
							{
								return -1;
							}
							if (nameA > nameB)
							{
								return 1;
							}
							// names must be equal
							return 0;
						});
					}
				}
			}
		}
		// Cas ou la session est à ajouter
		if (!isFind)
		{
			for (let key in this._etablissements)
			{
				for (let session of this._etablissements[key].sessions)
				{
					if (session.id === idParent)
					{
						session.examens.push(data);
						session.examens.sort(function (a, b)
						{
							var nameA = a.name.toUpperCase(); // ignore upper and lowercase
							var nameB = b.name.toUpperCase(); // ignore upper and lowercase
							if (nameA < nameB)
							{
								return -1;
							}
							if (nameA > nameB)
							{
								return 1;
							}
							// names must be equal
							return 0;
						});
					}
				}
			}
		}
	}


	/**
	 * ajoute ou MAJ une matière
	 */
	public setMatiere(id: string, data: Matiere, idParent: any)
	{
		let isFind = false;
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						if (matiere.id === id)
						{
							isFind					= true;
							matiere.name			= data.name;
							matiere.description		= data.description;
							matiere.dateCreation	= data.dateCreation;
							matiere.typeEpreuve		= data.typeEpreuve;

							examen.matieres.sort(function (a, b)
							{
								var nameA = a.name.toUpperCase(); // ignore upper and lowercase
								var nameB = b.name.toUpperCase(); // ignore upper and lowercase
								if (nameA < nameB)
								{
									return -1;
								}
								if (nameA > nameB)
								{
									return 1;
								}

								// names must be equal
								return 0;
							});
						}
					}
				}
			}
		}

		// Cas ou la session est à ajouter
		if (!isFind)
		{
			for (let key in this._etablissements)
			{
				for (let session of this._etablissements[key].sessions)
				{
					for (let examen of session.examens)
					{
						if (examen.id === idParent)
						{
							examen.matieres.push(data);
							examen.matieres.sort(function (a, b)
							{
								var nameA = a.name.toUpperCase(); // ignore upper and lowercase
								var nameB = b.name.toUpperCase(); // ignore upper and lowercase
								if (nameA < nameB)
								{
									return -1;
								}
								if (nameA > nameB)
								{
									return 1;
								}

								// names must be equal
								return 0;
							});
						}
					}
				}
			}
		}
	}


	/**
	 * Retourne vrai si un element possède des élément enfants, retourne faux sinon
	 */
	public hasElementChilds(element: any)
	{
		let result: boolean = false;

		if (element instanceof Etablissement)
		{
			if (element.sessions.length > 0)
			{
				result = true;
			}
		}

		if (element instanceof Session)
		{
			if (element.examens.length > 0)
			{
				result = true;
			}
		}

		if (element instanceof Examen)
		{
			if (element.matieres.length > 0)
			{
				result = true;
			}
		}

		if (element instanceof Matiere)
		{
			if (element.sujets.length > 0)
			{
				result = true;
			}
		}

		return result;
	}


	/**
	 * Mise à jours d'une matière après insert d'un sujet
	 */
	public addFile(idSujet: string, data: EFile, ras = false)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						for (let sujet of matiere.sujets)
						{
							if (sujet.id === idSujet)
							{
								let indexPj = _.findIndex(sujet.pj, {id:data.id});

								if (indexPj === -1)
								{
									sujet.pj.push(data);
								}
								else
								{
									//Sinon on la met à jour
									//On concernve les thumbnails et les clé aes du fichier courant
									//si ces données sont présentes
									//Si le thumbnail et les clé AES sont également présent dans la
									//nouvelle version du fichier, on prend ces donénes en priorité
									if (sujet.pj[indexPj].thumbs.length > 0)
									{
										if (data.thumbs.length === 0)
										{
											data.thumbs = sujet.pj[indexPj].thumbs;
										}
									}

									if (sujet.pj[indexPj].encryptedAesKeys.length > 0)
									{
										if (data.encryptedAesKeys.length === 0)
										{
											data.encryptedAesKeys = sujet.pj[indexPj].encryptedAesKeys;
										}
									}

									sujet.pj[indexPj] = data;
								}
							}
						}
					}
				}
			}
		}
	}


	/**
	 * Mise à jours d'une matière après insert d'un sujet
	 */
	public razFile(idSujet: string)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						for (let sujet of matiere.sujets)
						{
							if (sujet.id === idSujet)
							{
								sujet.pj = [];
							}
							// Mise à jour des élements selected
							if (sujet.id === this.selectedSujet.id)
							{
								this.selectedSujet.pj = [];
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param idSujet
	 */
	public selectSujetById(idSujet: any)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						for (let sujet of matiere.sujets)
						{
							if (sujet.id === idSujet)
							{
								this.selectedSujet = sujet;
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param jsonSujets
	 */
	public _updateSubjectFromJSON(jsonSujets: any[])
	{
	}


	/**
	 * Gestion des sujets transmis par socket et enregistrement de ces sujets dans la structure user-data
	 * @param jsonSujets
	 */
	public _addSubjectFromJSON(jsonSujets: any[])
	{
		const subjectsAdded: any[] = [];
		//pour chaque sujet récupéré
		for (let subject of jsonSujets)
		{
			let subjectToAdd : Sujet = Sujet.fromJsonObject(subject);

			if (subjectToAdd.datas.scenario)
			{
				subjectToAdd.datas.scenario2 = JSON.parse(subjectToAdd.datas.scenario);

				for (let key of subjectToAdd.datas.scenario2.steps)
				{
					//Si une tache existe on l'affecte sinon il s'agit d'une tache "fantome"
					let existingTask = _.find(this.taskData.globalTaskDatas, (task: any) =>
					{
						return task.idSujet === subjectToAdd.id && Number(task.type) === Number(key.id);
					});

					if (existingTask)
					{
						existingTask.nameSujet = subjectToAdd.name;
						subjectToAdd.fake[key.id] = existingTask;
					}
					else
					{
						subjectToAdd.fake[key.id] = key;
						subjectToAdd.fake[key.id].realTask = false;
					}
	//				subjectToAdd.fake[key.id] = subjectToAdd.datas.scenario2.steps;
				}
			}

			//On récupère l'épreuve correspondante
			let epreuve = this.getEpreuve(subjectToAdd.idMatiere);

			if (epreuve)
			{
				//On regarde si le sujet n'éxiste pas déja dans l'épreuve
				let indexSujet = _.findIndex(epreuve.sujets, (sujet: any) => { return sujet.id === subjectToAdd.id; });
				if (indexSujet !== -1)
				{
					//Si c'est le cas on remplace le sujet
					epreuve.sujets[indexSujet] = subjectToAdd;
				}
				else
				{
					//Sinon on l'ajoute
					epreuve.sujets.push(subjectToAdd);
				}

				subjectsAdded.push(subjectToAdd);

				//S'il s'agit du sujet courant on le met à jour
				if (this.selectedSujet && this.selectedSujet.id === subjectToAdd.id)
				{
					this.selectedSujet = subjectToAdd;
				}

				//On met à jour le nom du sujet dans les tache concernée de l'épreuve
				let taskEpr = _.find(epreuve.tasks, (task: any) =>
				{
					return task.idSujet === subjectToAdd.id;
				});

				if (taskEpr)
				{
					taskEpr.nameSujet = subjectToAdd.name;
				}
			}
		}
		return subjectsAdded;
	}


	/**
	 *
	 * @param id
	 */
	public getTreeElementForComment(id: any)
	{
		for (let key in this._etablissements)
		{
//			if (key === id) { return this._etablissements[key]; }

			for (let session of this._etablissements[key].sessions)
			{
				if (session.id === id) { return session; }

				for (let examen of session.examens)
				{
					if (examen.id === id) { return examen; }

					for (let matiere of examen.matieres)
					{
						if (matiere.id === id) { return matiere; }

						for (let sujet of matiere.sujets)
						{
							if (sujet.id === id) { return sujet; }
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param id
	 */
	public getBranchFromId(id: any)
	{
		let result: any = {};
		for (let key in this._etablissements)
		{
			if (key === id)
			{
				result.etablissement = this._etablissements[key];

				return result;
			}
			else
			{
				for (let session of this._etablissements[key].sessions)
				{
					if (session.id === id)
					{
						result.etablissement 	= this._etablissements[key];
						result.session 			= session;

						return result;
					}
					else
					{
						for (let examen of session.examens)
						{
							if (examen.id === id)
							{
								result.etablissement 	= this._etablissements[key];
								result.session 			= session;
								result.examen 			= examen;

								return result;
							}
							else
							{
								for (let matiere of examen.matieres)
								{
									if (matiere.id === id)
									{
										result.etablissement 	= this._etablissements[key];
										result.session 			= session;
										result.examen 			= examen;
										result.matiere 			= matiere;

										return result;
									}
									else
									{
										for (let sujet of matiere.sujets)
										{
											if (sujet.id === id)
											{
												result.etablissement 	= this._etablissements[key];
												result.session 			= session;
												result.examen 			= examen;
												result.matiere 			= matiere;
												result.sujet 			= sujet;

												return result;
											}
											else
											{
												for (let pj of sujet.pj)
												{
													if (pj.id === id)
													{
														result.etablissement 	= this._etablissements[key];
														result.session 			= session;
														result.examen 			= examen;
														result.matiere 			= matiere;
														result.sujet 			= sujet;
														result.pj 				= pj;

														return result;
													}
													// désolé
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		return result;
	}


	/**
	 *
	 * @param data
	 * @param replace
	 */
	public setTasksBySubject(data: any, replace = false)
	{
		for (let key in this._etablissements)
		{
			//			if (key === id) { return this._etablissements[key]; }

			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						for (let sujet of matiere.sujets)
						{
							for (let task of data)
							{
								if (sujet.id === task.idsujet)
								{

									let oTask 	= Task.fromJsonObject(task);
									oTask 		= this.taskData.buildTask(oTask);

									let idSaouled 			= parseInt(oTask.type);
									sujet.fake[idSaouled] 	= oTask;

									let indexInSubjectTasks = _.findIndex(sujet.tasks, (taskObj: any) => { return taskObj.id === oTask.id; });
									if (indexInSubjectTasks === -1)
									{
										sujet.tasks.push(oTask);
									}
									else
									{
										if (replace === true)
										{
											sujet.tasks[indexInSubjectTasks] = oTask;
										}
									}
									// ---------------------------------------
									// Gestion des scénario ...
									// ---------------------------------------
								}
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param id
	 */
	public getTreeElement(id: any)
	{
		for (let key in this._etablissements)
		{
			if (key === id) { return this._etablissements[key]; }

			for (let session of this._etablissements[key].sessions)
			{
				if (session.id === id) { return session; }

				for (let examen of session.examens)
				{
					if (examen.id === id) { return examen; }

					for (let matiere of examen.matieres)
					{
						if (matiere.id === id) { return matiere; }

						for (let sujet of matiere.sujets)
						{
							if (sujet.id === id) { return sujet; }
							for (let pj of sujet.pj)
							{
								if (pj.id === id) { return pj; }
								// désolé
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param id
	 */
	public getSession(id: any)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				if (session.id === id) { return session; }
			}
		}
	}


	/**
	 *
	 * @param id
	 */
	public getExamen(id: any)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					if (examen.id === id) { return examen; }
				}
			}
		}
	}


	/**
	 *
	 * @param id
	 */
	public getEpreuve(id: any)
	{
		for (let key in this._etablissements)
		{
			for (let session of this._etablissements[key].sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						if (matiere.id === id) { return matiere; }
					}
				}
			}
		}
	}


	/**
	 *
	 * @param idNode
	 * @param type
	 * @param parents
	 */
	public removeNode(idNode: any, type: string, parents: any[])
	{
		let structParents: any = {};

		for (let parent of parents)
		{
			if (parent.indexOf('*etab::') >= 0)
			{
				structParents.etab = parent.split('::').pop();
			}

			if (parent.indexOf('*sess::') >= 0)
			{
				structParents.session = parent.split('::').pop();
			}

			if (parent.indexOf('*exam::') >= 0)
			{
				structParents.examen = parent.split('::').pop();
			}
		}

		switch (type)
		{
			default:
				break;
			case 'etablissement':

				if (this._etablissements[idNode])
				{
					delete this._etablissements[idNode];
				}
				break;

			case 'session':
				if (parents.length === 2)
				{
					if (structParents.etab
						&& this._etablissements[structParents.etab])
					{
						let etab = this._etablissements[structParents.etab];

						let index = _.findIndex(etab.sessions, (session: any) => {
							return session.id === idNode;
						});

						if (index !== -1)
						{
							etab.sessions.splice(index, 1);
						}
					}
				}
				break;

			case 'examen':
				if (parents.length === 3)
				{
					if (structParents.etab && structParents.session && this._etablissements[structParents.etab])
					{
						let etab = this._etablissements[structParents.etab];
						let indexSession = _.findIndex(etab.sessions, (session: any) =>
						{
							return session.id === structParents.session;
						});

						if (indexSession !== -1)
						{
							let session = etab.sessions[indexSession];
							let index = _.findIndex(session.examens, (examen: any) =>
							{
								return examen.id === idNode;
							});

							if (index !== -1)
							{
								session.examens.splice(index, 1);
							}
						}
					}
				}
				break;

			case 'epreuve':
				if (parents.length === 4)
				{
					if (structParents.etab && structParents.session && structParents.examen && this._etablissements[structParents.etab])
					{
						let etab = this._etablissements[structParents.etab];

						let indexSession = _.findIndex(etab.sessions, (session: any) =>
						{
							return session.id === structParents.session;
						});

						if (indexSession !== -1)
						{
							let session = etab.sessions[indexSession];
							let indexExamen = _.findIndex(session.examens, (examen: any) =>
							{
								return examen.id === structParents.examen;
							});

							if (indexExamen !== -1)
							{
								let examen = session.examens[indexExamen];

								let index = _.findIndex(examen.matieres, (matiere: any) =>
								{
									return matiere.id === idNode;
								});
								examen.matieres.splice(index, 1);
							}
						}
					}
				}
				break;
		}
		return structParents;
	}


	/**
	 *
	 * @param id
	 */
	public getSujet(id: any)
	{
		for (const key in this._etablissements)
		{
			for (const session of this._etablissements[key].sessions)
			{
				for (const examen of session.examens)
				{
					for (const matiere of examen.matieres)
					{
						for (const sujet of matiere.sujets)
						{
							if (sujet.id === id) { return sujet; }
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param resultSearch
	 * @param visible
	 * @param textSearch
	 */
	changeTreeVisibility(resultSearch: any, visible: boolean, textSearch: string)
	{
		let found;

		for (let item of this._openedItemsBySearch)
		{
			item.show = false;
		}

		this._openedItemsBySearch = [];

		for (let key in this._etablissements)
		{
			if (this._etablissements[key])
			{
				if (resultSearch)
				{
					found = _.findWhere(resultSearch, {idEtab: this._etablissements[key].id});
					this._etablissements[key].visible = (found !== undefined);

					if (found && textSearch)
					{
						if(this._etablissements[key].name.toLowerCase().indexOf(textSearch) === -1)
						{
							this._etablissements[key].show = true;
							this._openedItemsBySearch.push(this._etablissements[key]);
						}
					}
				}
				else
				{
					this._etablissements[key].visible = visible;
				}

				for (let session of this._etablissements[key].sessions)
				{
					if (resultSearch)
					{
						found 			= _.findWhere(resultSearch, {idSession: session.id});
						session.visible = (found !== undefined);

						if (found && textSearch)
						{
							if(session.name.toLowerCase().indexOf(textSearch) === -1)
							{
								session.show = true;
								this._openedItemsBySearch.push(session);
							}
						}
					}
					else
					{
						session.visible = visible;
					}

					for (let examen of session.examens)
					{
						if (resultSearch)
						{
							found 			= _.findWhere(resultSearch, {idExamen: examen.id});
							examen.visible 	= (found !== undefined);

							if (found && textSearch)
							{
								if(examen.name.toLowerCase().indexOf(textSearch) === -1)
								{
									examen.show = true;
									this._openedItemsBySearch.push(examen);
								}
							}
						}
						else
						{
							examen.visible = visible;
						}

						for (let matiere of examen.matieres)
						{
							if (resultSearch)
							{
								found = _.findWhere(resultSearch, {idMatiere: matiere.id});
								matiere.visible = (found !== undefined);

								if (found && textSearch)
								{
									if(matiere.name.toLowerCase().indexOf(textSearch) === -1)
									{
										matiere.show = true;
										this._openedItemsBySearch.push(matiere);
									}
								}
							}
							else
							{
								matiere.visible = visible;
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param idElement
	 * @param values
	 */
	public setHistoric(idElement: any, values: any[])
	{
		this._historicFromElement[idElement] = { comments: values };
	}


	/**
	 *
	 * @param idElement
	 */
	public getHistoric(idElement: any)
	{
		let result;

		if (this._historicFromElement && this._historicFromElement[idElement])
		{
			result = this._historicFromElement[idElement];
		}

		return result;
	}


	/**
	 * Ajout d'un historic ou un commentaire à un élement donné
	 * @param idElement
	 * @param values
	 */
	public addHistoricToElement(idElement: any, values: any[])
	{
		if (this._historicFromElement && this._historicFromElement[idElement])
		{
			for (let value of values)
			{
				//On vérifie si le message n'est pas déja présent: vérification sur l'auteur et le dateticks
				let existingMessage = _.find(this._historicFromElement[idElement].comments, (message: any) => {

					return (	//filtrage sur les commentaires déja existants
								value.idUser && value.dateticks && message.idUser === value.idUser && message.dateticks === value.dateticks
							)
							||
							(	//filtrage sur les historiques déja existants
								value.path && value.type && message.path === value.path && message.type === value.type
							)
							||
							(
								value.soustitre && value.dateticks && message.soustitre === value.soustitre && message.dateticks === value.dateticks
							);
				});

				if (!existingMessage)
				{
					this._historicFromElement[idElement].comments.push(value);
				}
			}
		}
		else
		{
			this._historicFromElement[idElement] = { comments: values };
		}
	}


	/**
	 * Parse un ensemble d'element de type historique ou de type message
	 * @param data
	 */
	public parseHistoric(data: any)
	{
		let result: any[] = [];
		if (data.bucket)
		{
			switch (data.bucket)
			{
				default:
					break;

				case 'session':
				case 'examen':
				case 'matiere':
				case 'subjects':
				case 'files':
				case 'tasks':
					let fileObj: any = this.getTreeElement(data.key);

					let versionNum = 1;
					let arrayHisto = _.toArray(data.messages);

					let indice = 0;

					for (let key in data.messages)
					{
						if (data.messages[key] && data.messages[key])
						{
							arrayHisto[indice].key			= new Date(data.messages[key].dateticks);
							arrayHisto[indice].dateticks	= data.messages[key].dateticks;
							indice++;
						}
					}

					for (let keyDate of arrayHisto)
					{

						let globalDate = keyDate.key;

						if (keyDate)
						{
							if (keyDate.val && keyDate.who !== null)
							{
								let dataHistoric: any	= {};
								let values				= JSON.parse(keyDate.val);
								let sub					= keyDate.sub;

								dataHistoric.idUser			= keyDate.who;
								dataHistoric.creationDate	= keyDate.key;
								dataHistoric.dateticks		= keyDate.dateticks;

								if (sub === 'H')
								{
									dataHistoric = this.parseHistoricItem(values, dataHistoric);
									if (dataHistoric)
									{
										dataHistoric.version = versionNum;
										versionNum++;
									}
								}
								else
								{
									if (sub === 'C')
									{
										dataHistoric.typeName = 'Message';
										if (values.comment)
										{
											dataHistoric.content = values.comment;
										}
									}
								}
								if (dataHistoric)
								{
									result.push(dataHistoric);
								}
							}
						}
					}
					result = result.reverse();
					break;
			}
		}
		return result;
	}


	/**
	 * Parse un élément de type historique
	 */
	public parseHistoricItem(value: any, objectResult: any)
	{
		switch (value.action)
		{
			default:
				break;

			case 'update':
				objectResult.type		= 'update';
				objectResult.typeName	= 'Mise à jour';

				if (value.data && value.data.libelle)
				{
					objectResult.soustitre	= 'à modifié : ';
					objectResult.content	= 'Libelle : ' + value.data.libelle;
				}
				else
				{
					//Si il s'agit d'une nouvelle version d'un fichier
					if (value.path)
					{
						objectResult.typeName		= 'Edition';
						objectResult.soustitrePath	= 'A édité : ';
						objectResult.path			= value.path;
						objectResult.nameFile		= value.name;
						objectResult.typeFile		= value.type;
						objectResult.idFile			= value.idFile;
					}
					else
					{
						if (value.data && value.data.state)
						{
							//objectResult.soustitre = 'à modifié la tache: ';
							objectResult.soustitre = '';
							if (value.data.name)
							{
								objectResult.soustitre += value.data.name;
							}
							switch (value.data.state)
							{
								case 'new':
									objectResult.content = "Etat : 'Non assignée'";
									break;
								case 'todo':
									objectResult.content = "Etat : 'Assignée'";
									break;
								case 'inprogress':
									objectResult.content = "Etat : 'En cours'";
									break;
								case 'review':
									objectResult.content = "Etat : 'A valider'";
									break;
								case 'done':
									objectResult.content = "Etat : 'Terminé'";
									break;
								default:
									break;
							}
						}
						else
						{
							// objectResult.content = "à mis une nouvelle version du fichier";
						}
					}
				}
				break;

			case 'create':

				objectResult.type 		= 'create';
				objectResult.typeName 	= 'Création';
				objectResult.version 	= 1;

				if (value.path)
				{
					objectResult.soustitrePath	= 'A créé : ';
					objectResult.path			= value.path;
					objectResult.nameFile		= value.name;
					objectResult.typeFile		= value.type;
					objectResult.idFile			= value.idFile;
				}
				else
				{
					if (value.name)
					{
						objectResult.soustitre = 'A créé : ' + value.name;
					}
					else
					{
						if (value.what && value.id)
						{
							let name;
							switch(value.what)
							{
								case 'etablissement':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let etab 	= this.getEtablissement(value.id);
										name		= etab ? etab.name : '';
									}

									objectResult.soustitre = 'A créé le classeur : ' + name;
									break;

								case 'session':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let session = this.getSession(value.id);
										name 		= session ? session.name : '';
									}
									objectResult.soustitre = 'A créé la session : ' + name;
									break;

								case 'examen':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let examen 	= this.getExamen(value.id);
										name 		= examen ? examen.name : '';
									}

									objectResult.soustitre = "A créé l'examen : " + name;
									break;

								case 'matiere':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let matiere = this.getEpreuve(value.id);
										name 		= matiere ? matiere.name : '';
									}

									objectResult.soustitre = "A créé l'épreuve : " + name;
									break;

								case 'subjects':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let sujet 	= this.getSujet(value.id);
										name 		= sujet ? sujet.name : '';
									}

									objectResult.soustitre = 'A créé le sujet : ' + name;
									break;

								case 'task':
									if (value.libelle)
									{
										name = value.libelle;
									}
									else
									{
										let task 	= this.taskData.getTaskById(value.id);
										name 		= task ? task.name : '';
									}

									objectResult.soustitre = "A créé l'étape : " + name;
									break;

								default:
									break;
							}
						}
						else
						{
							objectResult = undefined;
						}
					}
				}
				break;
		}
		return objectResult;
	}


	/**
	 * Est call une fois que tt les datas sont là pour les merger
	 */
	public processData()
	{

		// ---------------------------------------
		// Là tu vas aller chercher tes infos du node pour les insérer dans l'objet Task
		// ---------------------------------------
		for(let key in this._taskHomeMine)
		{
			if (this._taskHomeMine[key])
			{
				let etablissement 	= this.getEtablissement(this._taskHomeMine[key].idEtablissement);
				let session 		= this.getSession(this._taskHomeMine[key].idSession);
				let examen 			= this.getExamen(this._taskHomeMine[key].idExamen);
				let epreuve			= this.getEpreuve(this._taskHomeMine[key].idEpreuve);
				let sujet			= this.getSujet(this._taskHomeMine[key].idSujet);

				this._taskHomeMine[key].nameEtablissement 	= etablissement ? etablissement.name 	: '';
				this._taskHomeMine[key].nameSession 		= session		? session.name 			: '';
				this._taskHomeMine[key].nameExamen 			= examen		? examen.name 			: '';
				this._taskHomeMine[key].nameEpreuve 		= epreuve		? epreuve.name 			: '';
				this._taskHomeMine[key].nameSujet 			= sujet			? sujet.name 			: '';

				this._taskHomeMineSave[key]					= this._taskHomeMine[key];
			}
		}

		for(let key in this._taskHomeAdmin)
		{
			if (this._taskHomeAdmin[key])
			{
				let etablissement 	= this.getEtablissement(this._taskHomeAdmin[key].idEtablissement);
				let session 		= this.getSession(this._taskHomeAdmin[key].idSession);
				let examen 			= this.getExamen(this._taskHomeAdmin[key].idExamen);
				let epreuve 		=  this.getEpreuve(this._taskHomeAdmin[key].idEpreuve);
				let sujet 			= this.getSujet(this._taskHomeAdmin[key].idSujet);

				this._taskHomeAdmin[key].nameEtablissement 	= etablissement ? etablissement.name 	: '';
				this._taskHomeAdmin[key].nameSession 		= session		? session.name 			: '';
				this._taskHomeAdmin[key].nameExamen 		= examen		? examen.name 			: '';
				this._taskHomeAdmin[key].nameEpreuve		= epreuve		? epreuve.name 			: '';
				this._taskHomeAdmin[key].nameSujet			= sujet			? sujet.name 			: '';

				this._taskHomeAdminSave[key] = this._taskHomeAdmin[key];
			}
		}

		for (let key in this._taskHomeAdminToValid)
		{
			if (this._taskHomeAdminToValid[key])
			{
				let etablissement 	= this.getEtablissement(this._taskHomeAdminToValid[key].idEtablissement);
				let session 		= this.getSession(this._taskHomeAdminToValid[key].idSession);
				let examen 			= this.getExamen(this._taskHomeAdminToValid[key].idExamen);
				let epreuve 		= this.getEpreuve(this._taskHomeAdminToValid[key].idEpreuve);
				let sujet 			= this.getSujet(this._taskHomeAdminToValid[key].idSujet);

				this._taskHomeAdminToValid[key].nameEtablissement 	= etablissement ? etablissement.name 	: '';
				this._taskHomeAdminToValid[key].nameSession 		= session		? session.name 			: '';
				this._taskHomeAdminToValid[key].nameExamen			= examen		? examen.name 			: '';
				this._taskHomeAdminToValid[key].nameEpreuve			= epreuve		? epreuve.name 			: '';
				this._taskHomeAdminToValid[key].nameSujet			= sujet			? sujet.name 			: '';

				this._taskHomeAdminToValidSave[key] = this._taskHomeAdminToValid[key];
			}
		}

		for (let key in this._taskHomeAdminExpired)
		{
			if (this._taskHomeAdminExpired[key])
			{
				let etablissement 	= this.getEtablissement(this._taskHomeAdminExpired[key].idEtablissement);
				let session 		= this.getSession(this._taskHomeAdminExpired[key].idSession);
				let examen 			= this.getExamen(this._taskHomeAdminExpired[key].idExamen);
				let epreuve 		= this.getEpreuve(this._taskHomeAdminExpired[key].idEpreuve);
				let sujet 			= this.getSujet(this._taskHomeAdminExpired[key].idSujet);

				this._taskHomeAdminExpired[key].nameEtablissement 	= etablissement ? etablissement.name 	: '';
				this._taskHomeAdminExpired[key].nameSession 		= session		? session.name 			: '';
				this._taskHomeAdminExpired[key].nameExamen 			= examen		? examen.name 			: '';
				this._taskHomeAdminExpired[key].nameEpreuve			= epreuve		? epreuve.name 			: '';
				this._taskHomeAdminExpired[key].nameSujet			= sujet			? sujet.name 			: '';

				this._taskHomeAdminExpiredSave[key]					= this._taskHomeAdminExpired[key];
			}
		}

		this._globalTask = [];
		this._globalTask = this._globalTask.concat(this.taskHomeMine);
		this._globalTask = this._globalTask.concat(this.taskHomeAdmin);
		this._globalTask = this._globalTask.concat(this.taskHomeAdminExpired);
	}




	/**
	 *
	 */
	public logout()
	{
		this._taskHomeAdmin			= [];
		this._taskHomeMine			= [];
		this._taskHomeAdminExpired	= [];
		this._taskHomeAdminToValid	= [];
		this.epreuveHomeAdmin		= [];
		this._globalTask			= [];
		this._etablissements		= [];
		this.selectedItems			= [];
		this._historicFromElement	= {};

		this.resetSelect();
	}


	/**
	 *
	 * @param id
	 */
	public getTaskName(id: any)
	{
		let name = '';

		for (let task of this._globalTask)
		{
			if (task.id === id)
			{
				switch (task.type)
				{
					case 'redaction':
						name = 'Rédaction';
						break;

					case 'test':
						name = 'Test';
						break;

					case 'miseEnPage':
						name = 'Mise en page';
						break;

					case 'verification':
						name = 'Vérification finale';
						break;

					default:

						break;
				}
			}
		}
		return name;
	}


	/**
	 *
	 * @param sujetId
	 */
	public constructTreeBySubjectId(sujetId: any)
	{
		// ---------------------------------------
		//
		// ---------------------------------------
		let tempEtab:		Etablissement;
		let tempSession:	Session;
		let tempExam:		Examen;
		let tempMatiere:	Matiere;
		let tree = [];

		for (let key in this._etablissements)
		{
			tempEtab = this._etablissements[key];

			for (let session of this._etablissements[key].sessions)
			{
				tempSession = session;

				for (let exam of session.examens)
				{
					tempExam = exam;

					for (let matiere of exam.matieres)
					{
						tempMatiere = matiere;

						for (let sujet of matiere.sujets)
						{
							if (sujet.id === sujetId)
							{
								tree[0] = tempMatiere.id;
								tree[1] = tempExam.id;
								tree[2] = tempSession.id;
								tree[3] = tempEtab.id;
								tree[4] = sujet.id;

								return tree;
							}
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param sujetId
	 */
	public constructAutoTreeBySubjectId(sujetId: any)
	{
		// ---------------------------------------
		//
		// ---------------------------------------
		let tempEtab:		Etablissement;
		let tempSession:	Session;
		let tempExam:		Examen;
		let tempMatiere:	Matiere;
		let tree = [];

		for (let key in this._etablissements)
		{
			tempEtab = this._etablissements[key];

			for (let session of this._etablissements[key].sessions)
			{
				tempSession = session;

				for (let exam of session.examens)
				{
					tempExam = exam;

					for (let matiere of exam.matieres)
					{
						tempMatiere = matiere;

						for (let sujet of matiere.sujets)
						{
							if (sujet.id === sujetId)
							{
								this.goToSubject			= true;
								this._selectedMatiere		= tempMatiere;
								this._selectedElement		= tempMatiere;
								this._selectedExamen		= tempExam;
								this._selectedSession		= tempSession;
								this._selectedEtablissement = tempEtab;
								this._selectedSujet			= sujet;

								this.router.navigate(['/workspace']);
							}
						}
					}
				}
			}
		}
	}


	/**
	 * Vérifie si le nom est dispo avec un nom à check et un type
	 */
	public isNameFree(psName: string, psType: any)
	{
		let xReturn: any = null;
		let indexFinal = false;
		if (psName)
		{
			psName = psName.toLowerCase();


			switch (psType)
			{
				case 'etablissement':

					for (let indice in this._etablissements)
					{
						if (this._etablissements[indice])
						{
							let nameToTest: string = this._etablissements[indice].name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;


				case 'session':

					if (this.selectedEtablissement)
					{
						for (let session of this.selectedEtablissement.sessions)
						{
							let nameToTest: string = session.name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;

				case 'examen':
					if (this.selectedSession)
					{
						for (let examen of this.selectedSession.examens)
						{
							let nameToTest: string = examen.name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;

				case 'matiere':
					if (this.selectedExamen)
					{
						for (let matiere of this.selectedExamen.matieres)
						{
							let nameToTest: string = matiere.name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;

				case 'sujet':
					if (this.selectedMatiere)
					{
						for (let sujet of this.selectedMatiere.sujets)
						{
							let nameToTest: string = sujet.name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;

				case 'pj':
					if (this.selectedSujet)
					{
						for (let pj of this.selectedSujet.pj)
						{
							let nameToTest: string = pj.name;
							if (nameToTest)
							{
								nameToTest = nameToTest.toLowerCase();
								if (nameToTest === psName)
								{
									indexFinal = true;
								}
							}
						}
					}
					break;

				default:
					break;
			}

			if (indexFinal)
			{
				xReturn = { invalid: true };
			}
		}

		return xReturn;
	}


	// ---------------------------------------------------------------------------------------------------------------------------------------------------------
	// Home
	// ---------------------------------------------------------------------------------------------------------------------------------------------------------


	/**
	 *
	 * @param paArray
	 * @param psId
	 * @param psType
	 */
	public findItem(paArray: any[], psId: string, psType ='etab')
	{
		// Partie à valider
		let tempObj1 = [];
		let paReturn = [];
		for (let key in paArray)
		{
			switch (psType)
			{
				case 'etab':
					if (paArray[key].idEtablissement === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'sess':
					if (paArray[key].idSession === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'exam':
					if (paArray[key].idExamen === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'matiere':
					if (paArray[key].idEpreuve === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'suj':
					if (paArray[key].idSujet === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				default:
					break;
			}

		}
		paReturn = tempObj1;

		return paReturn;
	}


	/**
	 *
	 * @param paArray
	 * @param psId
	 * @param psType
	 */
	public findItemEpreuve(paArray: any[], psId: string, psType ='etab')
	{
		// Partie à valider
		let tempObj1 = [];
		let paReturn = [];


		for (let key in paArray)
		{

			switch (psType)
			{
				case 'etab':
					if (paArray[key].parents[0] === "*etab::"+psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'sess':
					if (paArray[key].parents[3]  === "*sess::"+psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'exam':
					if (paArray[key].parents[1]  === "*exam::"+psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				case 'matiere':
					if (paArray[key].id  === psId)
					{
						tempObj1.push(paArray[key]);
					}
					break;

				default:
					break;
			}

		}
		paReturn = tempObj1;

		return paReturn;
	}


	/**
	 * Trie des éléments sur la home
	 */
	public filterHomeTasks(typeOfFilter: any, id: any = null)
	{
		let resultDatasAdminToValid:	any[] = [];
		let resultDatasAdmin:			any[] = [];
		let resultDatasAdminExpired:	any[] = [];
		let resultDatasMine:			any[] = [];
		let resultDatasEpreuve:			any[] = [];

		if (id)
		{
			// Raz des data, si on veut trier sur étab2 et que seulement les etab1 sont dispo ça va pas le faire
			resultDatasAdminToValid		= this._taskHomeAdminToValidSave;
			resultDatasAdmin			= this._taskHomeAdminSave;
			resultDatasAdminExpired		= this._taskHomeAdminExpiredSave;
			resultDatasMine				= this._taskHomeMineSave;
			resultDatasEpreuve			= this._epreuveHomeAdminSave;

			switch (typeOfFilter)
			{
				case 'etab':
					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			id, typeOfFilter);
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	id, typeOfFilter);
					resultDatasMine			= this.findItem(resultDatasMine,			id, typeOfFilter);

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, id, typeOfFilter);
				break;

				case 'sess':
					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			id, typeOfFilter);
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	id, typeOfFilter);
					resultDatasMine			= this.findItem(resultDatasMine,			id, typeOfFilter);

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, id, typeOfFilter);
					break;

				case 'exam':
//					resultDatasAdminToValid = _.filter(resultDatasAdminToValid, (step: any) => { return step.idExamen === id; });
					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			id, typeOfFilter);
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	id, typeOfFilter);
					resultDatasMine			= this.findItem(resultDatasMine,			id, typeOfFilter);

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, id, typeOfFilter);
				break;

				case 'matiere':
					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdmin		= this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	id, typeOfFilter);
					resultDatasMine			= this.findItem(resultDatasMine,			id, typeOfFilter);

					resultDatasEpreuve		= this.findItemEpreuve(resultDatasEpreuve,			id, typeOfFilter);
				break;

				case 'suj':
					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	id, typeOfFilter);
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			id, typeOfFilter);
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	id, typeOfFilter);
					resultDatasMine			= this.findItem(resultDatasMine,			id, typeOfFilter);


					let sujet = this.getSujet(id);
					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, sujet.idMatiere, 'matiere');
				break;

				default:
					resultDatasAdminToValid = this._taskHomeAdminToValidSave;
					resultDatasAdmin		= this._taskHomeAdminSave;
					resultDatasAdminExpired = this._taskHomeAdminExpiredSave;
					resultDatasMine			= this._taskHomeMineSave;
					resultDatasEpreuve		= this._epreuveHomeAdminSave;

				break;
			}
		}
		else
		{
			// ---------------------------------------
			// Cas aucun => on filtre l'étape d'avant si elle est selectionnée
			// ---------------------------------------
			resultDatasAdminToValid		= this._taskHomeAdminToValidSave;
			resultDatasAdmin			= this._taskHomeAdminSave;
			resultDatasAdminExpired		= this._taskHomeAdminExpiredSave;
			resultDatasMine				= this._taskHomeMineSave;
			resultDatasEpreuve			= this._epreuveHomeAdminSave;

			let filterId: any;

			switch (typeOfFilter)
			{
				case 'etab':
					break;
				case 'sess':
					filterId = this.selectedHomeEtablissement.id;

					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	filterId, 'etab');
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			filterId, 'etab');
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	filterId, 'etab');
					resultDatasMine			= this.findItem(resultDatasMine,			filterId, 'etab');

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, filterId, 'etab');
				break;


				case 'exam':
					filterId = this.selectedHomeSession.id;

					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	filterId, 'sess');
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			filterId, 'sess');
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	filterId, 'sess');
					resultDatasMine			= this.findItem(resultDatasMine,			filterId, 'sess');

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, filterId, 'sess');
				break;


				case 'matiere':
					filterId = this.selectedHomeExamen.id;

					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	filterId, 'exam');
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			filterId, 'exam');
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	filterId, 'exam');
					resultDatasMine			= this.findItem(resultDatasMine,			filterId, 'exam');

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, filterId, 'exam');
				break;


				case 'suj':
					filterId = this.selectedHomeMatiere.id;

					resultDatasAdminToValid = this.findItem(resultDatasAdminToValid,	filterId, 'matiere');
					resultDatasAdmin		= this.findItem(resultDatasAdmin,			filterId, 'matiere');
					resultDatasAdminExpired = this.findItem(resultDatasAdminExpired,	filterId, 'matiere');
					resultDatasMine			= this.findItem(resultDatasMine,			filterId, 'matiere');

					resultDatasEpreuve = this.findItemEpreuve(resultDatasEpreuve, filterId, 'matiere');
				break;
			}
		}
		this._taskHomeAdminToValid	= resultDatasAdminToValid;
		this._taskHomeAdmin			= resultDatasAdmin;
		this._taskHomeAdminExpired	= resultDatasAdminExpired;
		this._taskHomeMine			= resultDatasMine;
		this._epreuveHomeAdmin		= resultDatasEpreuve;
	}


	/**
	 *
	 */
	get selectedHomeEtablissement(): Etablissement
	{
		let oReturn = this._selectedHomeEtablissement;
		if (this._selectedHomeEtablissement === null)
		{
			let etablissementId				= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedHomeEtablissementId');
			oReturn							= this.getEtablissement(etablissementId);
			this._selectedHomeEtablissement = oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedHomeSession():			Session
	{
		let oReturn = this._selectedHomeSession;

		if (this._selectedHomeSession === null)
		{
			let sessionId				= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedHomeSessionId');
			oReturn						= this.getSession(sessionId);
			this._selectedHomeSession	= oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedHomeExamen():			Examen
	{
		let oReturn = this._selectedHomeExamen;

		if (this._selectedHomeExamen === null)
		{
			let examenId				= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedHomeExamenId');
			oReturn						= this.getExamen(examenId);
			this._selectedHomeExamen	= oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedHomeMatiere():			Matiere
	{
		let oReturn = this._selectedHomeMatiere;

		if (this._selectedHomeMatiere === null)
		{
			let matiereId				= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedHomeMatiereId');
			oReturn						= this.getEpreuve(matiereId);
			this._selectedHomeMatiere	= oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	get selectedHomeSujet():			Sujet
	{
		let oReturn = this._selectedHomeSujet;
		if (this._selectedHomeSujet === null)
		{
			let subjectId			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::selectedHomeSubjectId');
			oReturn					= this.getSujet(subjectId);
			this._selectedHomeSujet = oReturn;
		}

		return oReturn;
	}


	/**
	 *
	 */
	set selectedHomeEtablissement(selectedEtablissement)
	{
		if (selectedEtablissement)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeEtablissementId', selectedEtablissement.id);
		}
		this._selectedHomeEtablissement	= selectedEtablissement;
	}


	/**
	 *
	 */
	set selectedHomeSession(selectedSession)
	{
		if (selectedSession)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeSessionId', selectedSession.id);
		}
		this._selectedHomeSession = selectedSession;
	}


	/**
	 *
	 */
	set selectedHomeExamen(selectedExamen)
	{
		if (selectedExamen)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeExamenId', selectedExamen.id);
		}
		this._selectedHomeExamen = selectedExamen;
	}


	/**
	 *
	 */
	set selectedHomeMatiere(selectedMatiere)
	{
		if (selectedMatiere)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeMatiereId', selectedMatiere.id);
		}
		this._selectedHomeMatiere = selectedMatiere;
	}


	/**
	 *
	 */
	set selectedHomeSujet(selectedSujet)
	{
		if (selectedSujet)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeSubjectId', selectedSujet.id);
		}
		else
		{
			localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeSubjectId');
		}
		this._selectedHomeSujet	= selectedSujet;
	}


	resetSelectedHomeEtablissement()
	{
		this._selectedHomeEtablissement = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeEtablissementId');
	}


	resetSelectedHomeSession()
	{
		this._selectedHomeSession = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeSessionId');
	}


	resetSelectedHomeExamen()
	{
		this._selectedHomeExamen = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeExamenId');
	}


	resetSelectedHomeMatiere()
	{
		this._selectedHomeMatiere = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeMatiereId');
	}


	resetSelectedHomeSujet()
	{
		this._selectedHomeSujet = null;
		localStorage.removeItem(this.userData.mail + '::' + this.userData.account + '::selectedHomeSubjectId');
	}


	get epreuveHomeAdmin()
	{
		let oReturn = [];
		for (let key in this._epreuveHomeAdmin)
		{
			// On retourne sous cette forme pour que ça soit plus simple de parser coté html
			oReturn.push(this._epreuveHomeAdmin[key]);
		}
		return oReturn;
	}


	get taskHomeAdmin()
	{
		let oReturn = [];
		for (let key in this._taskHomeAdmin)
		{
			oReturn.push(this._taskHomeAdmin[key]);
		}
		return oReturn;
	}


	get taskHomeAdminToValid()
	{
		return this._taskHomeAdminToValid;
	}


	geTaskHomeAdminToValid()
	{
		let oReturn = [];
		for (let key in this._taskHomeAdminToValid)
		{
			oReturn.push(this._taskHomeAdminToValid[key]);
		}

		return oReturn;
	}


	getTaskHomeMine()
	{
		let oReturn = [];
		for (let key in this._taskHomeMine)
		{
			if (this._taskHomeMine[key].name)
			{
				oReturn.push(this._taskHomeMine[key]);
			}
		}

		return oReturn;
	}


	getTaskHomeAdminExpired()
	{
		let oReturn = [];
		for (let key in this._taskHomeAdminExpired)
		{
			// On retourne sous cette forme pour que ça soit plus simple de parser coté html
			oReturn.push(this._taskHomeAdminExpired[key]);
		}


		return oReturn;
	}


	getTaskHomeMineLength()
	{
		let oReturn = [];
		for (let key in this._taskHomeMine)
		{
			if (this._taskHomeMine[key].name)
			{
				oReturn.push(this._taskHomeMine[key]);
			}
		}

		return oReturn.length;
	}

	getTaskHomeExpiredLength()
	{
		let oReturn = [];
		for (let key in this._taskHomeAdminExpired)
		{
			oReturn.push(this._taskHomeAdminExpired[key]);
		}

		return oReturn.length;
	}


	geTaskHomeAdminToValidLenght()
	{
		let oReturn = [];
		for (let key in this._taskHomeAdminToValid)
		{
			oReturn.push(this._taskHomeAdminToValid[key]);
		}

		return oReturn.length;
	}


	get taskHomeAdminExpired()
	{
		return this._taskHomeAdminExpired;
	}


	get taskHomeMine()
	{
		return this._taskHomeMine;
	}


	public deleteTaskNodeById(psId: string)
	{

		for (let etablissement of this.etablissements)
		{
			for (let session of etablissement.sessions)
			{
				for (let examen of session.examens)
				{
					for (let matiere of examen.matieres)
					{
						if (matiere.tasks)
						{
							let nIndice 	= 0;
							let arrayTask 	= [];

							for (let task of matiere.tasks)
							{
								// On a la meme id de tache mais pas pour la meme personne, on l'enlève donc
								if (psId !== task.id)
								{
									arrayTask.push(task);
								}
								nIndice++;
							}
							matiere.tasks = arrayTask;
						}
					}
				}
			}
		}
	}


	public deleteStatsNodeByIndice(pnIndice: number)
	{

		this.statsNode2 = undefined;

	}
}
