import { Component, OnInit, DoCheck }	from '@angular/core';
import { ViewEncapsulation }	from '@angular/core';
import { UserData }				from '../../providers/user-data';
import { WorkspaceData }				from '../../providers/workspace-data';
import { Router }				from '@angular/router';


@Component({
	selector:		'footer-component',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./footer.component.html',
	styleUrls:		['./footer.component.scss']
})


/**
 *
 */
export class FooterComponent implements OnInit, DoCheck
{
	public showMenu:		boolean	= false;
	public isHomeSelected:	boolean = false;
	public isWorkSelected:	boolean = false;
	public isTaskSelected:	boolean = false;
	public isComuSelected:	boolean = false;


	/**
	 *
	 */
	constructor(public userData: UserData,
				public router: Router,
				public workspaceData: WorkspaceData,
				)
	{
	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		this.isHomeSelected = false;
		this.isWorkSelected = false;
		this.isTaskSelected = false;
		this.isComuSelected = false;

		// ---------------------------------------
		//
		// ---------------------------------------
		switch (this.router.url)
		{
			case '/home':
				this.isHomeSelected = true;
				break;
				
			case '/workspace':
				this.workspaceData.mbisWorkspaceLoading = false;
				this.isWorkSelected = true;
				break;
				
			case '/task':
				this.isTaskSelected = true;
				break;
				
			case '/community':
				this.isComuSelected = true;
				break;

			default:

				break;
		}
	}


	/**
	 * 
	 */
	ngDoCheck()
	{
		// ---------------------------------------
		//
		// ---------------------------------------
		switch (this.router.url)
		{
			case '/home':
				this.isHomeSelected = true;
				this.isWorkSelected = false;
				this.isTaskSelected = false;
				this.isComuSelected = false;


				break;
			case '/workspace':
				this.isWorkSelected = true;
				this.isHomeSelected = false;
				this.isTaskSelected = false;
				this.isComuSelected = false;

				break;
			case '/task':
				this.isTaskSelected = true;
				this.isHomeSelected = false;
				this.isWorkSelected = false;
				this.isComuSelected = false;

				break;
			case '/community':
				this.isComuSelected = true;
				this.isHomeSelected = false;
				this.isWorkSelected = false;
				this.isTaskSelected = false;

				break;

			default:
				this.isHomeSelected = false;
				this.isWorkSelected = false;
				this.isTaskSelected = false;
				this.isComuSelected = false;
				break;
		}
	}


	/**
	 * 
	 * @param psColor 
	 */
	public changeBackgroundColor(psColor: any)
	{
	}


	/**
	 * 
	 * @param psName 
	 */
	public changeSelected(psName: any)
	{

		// ---------------------------------------
		// En fonction on va ouvrir le bon formulaire
		// ---------------------------------------
		this.isHomeSelected = false;
		this.isWorkSelected = false;
		this.isTaskSelected = false;
		this.isComuSelected = false;

		switch (psName)
		{
			case 'home':
				this.isHomeSelected = true;
				break;
			case 'work':
				this.isWorkSelected = true;
				break;
			case 'task':
				this.isTaskSelected = true;
				break;
			case 'comu':
				this.isComuSelected = true;
				break;

			default:
				break;
		}
	}
}
