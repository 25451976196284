import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }							from '@angular/material';


@Component({
	selector:		'warning-add-blocker-enabled',
	templateUrl:	'warningAddBlockerEnabledModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./warningAddBlockerEnabledModal.component.scss']
})


/**
 * 
 */
export class WarningAddBlockerEnabledComponent
{
	
	
	/**
	 * 
	 * @param dialogRef 
	 */
	constructor(public dialogRef: MatDialogRef<WarningAddBlockerEnabledComponent, any>)
    {
	}


	/**
	 * 
	 */
    public validate()
    {
        this.dialogRef.close();
    }
}
