<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">Confirmation réinitialisation clé RSA</div>
	</div>
	<div class="dialog_content">
		<mat-card-content>
			<label>La clé RSA a été réinitialisée pour l'utilisateur suivant:</label>
			<div class="clear">- {{ user.completeName }}</div>
			<label></label>
			<div class="clear">la clé sera automatiquement recréée lors de sa prochaine autenthification.</div>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">Ok</button>
	</div>
</mat-dialog>
