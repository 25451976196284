import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/observable';
import {Observable} from 'rxjs/Rx';

import { of } from 'rxjs';

@Injectable()
export class DataResolver implements Resolve<any>
{
	constructor()
	{
	}
	
	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
	{
		return Observable.of({ res: 'I am data' });
	}
}


// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [
	DataResolver
];
