import { Injectable } from '@angular/core';

import { VaultHttpService } from './http/vaultHttp.service';
import { ISocketData, SocketWhat } from '../models/message.model';
import { SCClientSocket } from 'socketcluster-client';
import { SCChannel } from 'sc-channel';
import { EventEmitter } from 'events';
import {Observable} from 'rxjs/Rx';
declare const require: any;

let socketCluster = require('socketcluster-client');

interface ISocketEvents
{
	// Canaux par defaut hors room
	on(event: 'genericUpdates',	listener: (data: any) => void): this; // Pour action globale
	on(event: 'accountUpdates',	listener: (data: any) => void): this; // Action sur le compte
	on(event: 'user',			listener: (data: any) => void): this; // Action mail user (si 10machines, send sur les 10)
	on(event: 'machine',		listener: (data: any) => void): this; // Cible une seule machine en cours (multi onglet par contre)
	on(event: 'account',		listener: (data: any) => void): this; // Le compte (Etablissement)

	// Ecouteur générique
	on(event: 'create', listener: (data: any) => void): this;
	on(event: 'read',	listener: (data: any) => void): this;
	on(event: 'update', listener: (data: any) => void): this;
	on(event: 'delete', listener: (data: any) => void): this;

	// Ecouteur spécifique
	on(event: 'error',				listener: (data: any)							=> void): this;
	on(event: 'debug',				listener: (data: any)							=> void): this;
	on(event: 'authenticate',		listener: (data: any)							=> void): this;
	on(event: 'authStateChange',	listener: (old: any, news: any, signed: any)	=> void): this;
	on(event: 'deauthenticate',		listener: ()									=> void): this;
	on(event: 'authTokenChange',	listener: (newtoken: any)						=> void): this;
	on(event: 'connect',			listener: ()									=> void): this;
	on(event: 'disconnect',			listener: ()									=> void): this;

	on(event: string, listener: (data: any) => void): this;
}


@Injectable()
export class SocketVaultService extends EventEmitter implements ISocketEvents
{
    public	roomsAGerer: any = undefined;

	private socket:			any;
	private processing:		boolean	= false;
	private timeOutSocket:	any		= undefined;
    private roomsGet:		boolean	= false;

    constructor()
	{
		super();
		this.init();
    }

    /**
	 * Initialisation du service socket
	 */
	public init()
	{
		this.roomsAGerer =
        [
            {
                name: '*soft::all::creation', state: 'nosub', watch: true,
                fct: this.watchGenericUpdates.bind(this), channel: undefined
            }
        ];
    }

    /**
	 * Cree le socket authentifie ou pas , pas d'http
	 */
	public getSocketConnection(): Observable<any>
	{
		let observable = Observable.create((observer: any) =>
		{
			if (this.socket)
			{
				if (this.socket.state)
				{
					//retourne le socket s'il est ouvert (peut etre non auth)
					if (this.socket.state === SCClientSocket.OPEN)
					{
						observer.next(this.socket);
						return;
					}
					// en cours de connexion
					if (this.socket.state === SCClientSocket.CONNECTING)
					{
						if (this.timeOutSocket)
						{
							return;
						}
						else
						{
							observer.next(undefined);
							return;
						}
					}
					// ou deco
					if (this.socket.state === SCClientSocket.CLOSED)
					{
						this.socket.connect();
					}
				}
			}
			else
			{
				//Pas de socket, on cree un
				this.socket = socketCluster.connect({
					hostname:		VaultHttpService.getChatUrl(),
					secure:			VaultHttpService.getChatSecure(),
					path:			VaultHttpService.getChatPath(),
					autoreconnect:	true,
					port:			VaultHttpService.getChatPort()
				});
			}

			this.socket.off('authenticate');
			this.socket.off('authStateChange');
			this.socket.off('deauthenticate');
			this.socket.off('authTokenChange');
			this.socket.off('error');
			this.socket.off('debug');
			this.socket.off('connect');
			this.socket.off('create');
			this.socket.off('read');
			this.socket.off('update');
			this.socket.off('delete');
			this.socket.off();

			if (this.timeOutSocket)
			{
				clearTimeout(this.timeOutSocket);
			}

			this.socket.on('authenticate', (old: any, news: any, signed: any) =>
			{
				this.emit('authenticate', old, news, signed);
			});


			this.socket.on('authStateChange', (old: any, news: any, signed: any) =>
			{
				if (old && old.newState && old.newState === 'unauthenticated')
					this.emit('deauthenticate');

				this.emit('authStateChange', old, news, signed);
			});


			this.socket.on('deauthenticate', () =>
			{
				this.emit('deauthenticate');
			});

			this.socket.on('authTokenChange', (newToken: any) =>
			{
				if (!newToken)
					this.emit('deauthenticate');

				this.emit('authTokenChange', newToken);
			});


			this.socket.on('error', (data: any) =>
			{
				if (this.timeOutSocket)
				{
					clearTimeout(this.timeOutSocket);
					observer.next(undefined);
				}

				if (this.emit('error', data) === false)
				{
					console.log('Hey no listener for', 'error', data);
				}
			});

			this.socket.on('debug', (data: any) =>
			{
				if (this.emit('debug', data) === false)
				{
					console.log('Hey no listener for', 'debug', data);
				}

			});

			this.socket.on('create', (data: any) =>
			{
				if (this.emit('create', data) === false)
				{
					console.log('Hey no listener for', 'create', data);
				}
			});
			this.socket.on('read', (data: any) =>
			{
				if (this.emit('read', data) === false)
				{
					console.log('Hey no listener for', 'read', data);
				}
			});
			this.socket.on('update', (data: any) =>
			{
				if (this.emit('update', data) === false)
				{
					console.log('Hey no listener for', 'update', data);
				}
			});
			this.socket.on('delete', (data: any) =>
			{
				if (this.emit('delete', data) === false)
				{
					console.log('Hey no listener for', 'delete', data);
				}
			});
			this.socket.on('connect', () =>
			{
				if (this.timeOutSocket)
					clearTimeout(this.timeOutSocket);
				if (this.emit('connect') === false)
				{
					console.log('Hey no listener for', 'connect');
				}
				observer.next(this.socket);
			});

			this.socket.on('disconnect', () =>
			{
				if (this.timeOutSocket)
					clearTimeout(this.timeOutSocket);
				if (this.emit('disconnect') === false)
				{
					console.log('Hey no listener for', 'disconnect');
				}
				observer.next(undefined);
			});


			this.timeOutSocket = setTimeout(() =>
			{
				console.log('************Trop long on deco volontairement!');
				observer.next(undefined);
				this.logout();
			}, 10000);

		});
		return observable;
    }


    /**
	 *
	 */
	public logout()
	{
		if (this.socket && this.socket.state && this.socket.state === SCClientSocket.OPEN)
		{
			if (this.roomsAGerer)
			{
				for (let room of this.roomsAGerer)
				{
					if (room.channel)
					{
						room.channel.unwatch();
						room.channel.off();
					}
				}
			}
			// ---------------------------------------
			// On enlève les écouteurs
			// ---------------------------------------
			this.socket.off('authenticate');
			this.socket.off('authStateChange');
			this.socket.off('deauthenticate');
			this.socket.off('authTokenChange');
			this.socket.off('error');
			this.socket.off('connect');

			this.socket.off('create');
			this.socket.off('read');
			this.socket.off('update');
			this.socket.off('delete');


			// ---------------------------------------
			// Déconnexion et désauthentification
			// ---------------------------------------
			this.socket.deauthenticate();
			this.socket.disconnect();
			this.socket.off(); 

		}
    }


    public watchGenericUpdates(data: any)
	{ 
		if (super.emit('genericUpdates', data) === false)
		{
			console.log('Hey no listener for', 'genericUpdates', data);
		}
	}  


	public watchAccountUpdates(data: any)
	{
		if (super.emit('accountUpdates', data) === false)
		{
			console.log('Hey no listener for', 'accountUpdates', data);
		}
	} 


	public watchUser(data: any)
	{
		if (super.emit('user', data) === false)
		{
			console.log('Hey no listener for', 'user', data);
		}
	}

	public watchMachine(data: any)
	{
		if (super.emit('machine', data) === false)
		{
			console.log('Hey no listener for', 'machine', data);
		}
	}
	public watchAccount(data: any)
	{
		if (super.emit('account', data) === false)
		{
			console.log('Hey no listener for', 'account', data);
		}
	}

	public findRoomAGerer(name: string):any
	{
		if (this.roomsAGerer)
		{
			for (let i = 0; i < this.roomsAGerer.length; i++)
			{
				if (this.roomsAGerer[i].name == name)
					return i;
			}
		}
		return -1;
	}



	public RoomLost()
	{
		let time = Date.now() / 1000;
		
		if (this.roomsAGerer)
		{
			// on parcours toutes les rooms 
			let nb = 3;
			for (let i = 0; i < this.roomsAGerer.length; i++)
			{
				// on passe a eta cnx perdue
				this.roomsAGerer[i].state = 'lost';
			}
		}
    } 


    public GererRoom()
	{
		let time = Date.now() / 1000;

		if (this.roomsAGerer)
		{
			// on parcours toutes les rooms
			let nb = 5;
			for (let i = 0; i < this.roomsAGerer.length; i++)
			{
				// si on en trouve une non subscrite on lance le sub, et on sort
				if (this.roomsAGerer[i].state === 'nosub')
				{
					this.subscribe(this.socket, i);
					// nb a la fois
					if (nb < 0)
					{
						break;
					}
					nb--;
				}
				//pour les socket a l'etat lost (deco && a surveiller)
				if (this.roomsAGerer[i].state === 'lost')
				{
					let channel = this.roomsAGerer[i].channel;
					this.roomsAGerer[i].state = 'pending';

					if (channel.state === SCChannel.UNSUBSCRIBED)
					{
						channel.subscribe();
						// nb a la fois
						if (nb < 0)
						{
							break;
						}
						nb--;
					}
				}
			}


			// si tout ca est ok
			// toutes les 10s (modulo sur date)
			if (time % 10)
			{
				if (this.roomsAGerer)
				{
					// on parcours toutes les rooms
					for (let i = 0; i < this.roomsAGerer.length; i++)
					{
						let value = this.roomsAGerer[i];
						//si le canal est connecté
						if (value.channel && value.channel.state === SCChannel.SUBSCRIBED)
						{
							let watchers = value.channel.watchers();
							// si pas de watcher on l'ajoute
							if (!watchers || watchers.length == 0)
							{
								value.channel.watch((data: any) => { value.fct(data); });
								value.state = 'watching';
							}
						}
					}
				}
			}
		}
	}


	private subscribe(socket: any, idx: number)
	{
		let value = this.roomsAGerer[idx];
		if (!value)
		{
			console.log(idx, 'must be in table before subsribe');
			return;
		}
		value.state = 'pending';

		value.channel = socket.subscribe(value.name);
		value.channel.on('subscribe', () =>
		{
			console.log('Subscribre to', value.name);
			if (value.fct)
			{
				value.channel.watch((data: any) => { value.fct(data); });
				value.state = 'watching';
			}
			else
			{
				value.state = 'noWatcher';
			}
			// TODO: recuperee etat messages lus
		});


		value.channel.on('unsubscribe', () =>
		{
			console.log('unsubscribe to', value.name);
			if (value.watch)
			{
				value.state = 'lost';
			}
			else
			{
				delete this.roomsAGerer[idx];
			}
		});


		value.channel.on('subscribeFail', () =>
		{
			console.log('subscribeFail', value.name);
			if (value.watch)
			{
				value.state = 'lost';
			}
			else
			{
				value.state = 'fail';
			}
		});


	}
}
