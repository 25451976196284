import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

import { UserData }		from '../../providers/user-data';
import { UserService }	from '../../services/user.service';


@Component({
	selector:		'select-account-modal',
	templateUrl:	'selectAccountModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./selectAccountModal.component.scss']
})


/**
 *
 */
export class SelectAccountModalComponent
{

	public accounts: any[] = [];
	public selectedAccount: any;


	/**
	 * 
	 * @param dialogRef 
	 * @param userService 
	 * @param userData 
	 * @param data 
	 */
	constructor(public dialogRef:		MatDialogRef<SelectAccountModalComponent, any>,
		public userService: UserService,
		public userData: UserData,
		@Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.accounts)
		{
			this.accounts 			= data.accounts;
			this.selectedAccount 	= this.accounts[0].name;
		}
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 */
	public validate()
	{
		const currentToken 					= this.userData.getTokenKey();
		this.userData.account 				= this.selectedAccount;
		this.userData.bddAccountConnected 	= this.selectedAccount;

		this.userService.initKeyspaceAndUser(this.userData.account, this.userData.mail, currentToken).then(res => 
		{
			this.dialogRef.close(this.selectedAccount);
		}).catch(err => 
		{
			this.dialogRef.close(false);
		});
	}
}
