
import { Component, EventEmitter, ViewEncapsulation, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog }							    from '@angular/material';
import { MAT_DIALOG_DATA }									    from '@angular/material';
import { DataSource, SelectionModel }	                        from '@angular/cdk/collections';
import {Observable} from 'rxjs/Rx';
import {startWith, map}                                              from 'rxjs/operators';
import { TranslateService } 						            from '@ngx-translate/core';

import { Etablissement }		from '../../../../classes/etablissement.class';
import { Session }				from '../../../../classes/session.class';
import { Examen }				from '../../../../classes/examen.class';
import { Matiere }				from '../../../../classes/matiere.class';
import { Task }					from '../../../../classes/task.class';
import { Sujet }				from '../../../../classes/sujet.class';
import { File as FileObject }	from '../../../../classes/file.class';

import { TaskService }		from '../../../../services/task.service';
import { FileService }		from '../../../../services/file.service';
import { EncryptService }   from '../../../../services/encrypt.service';
import { UserData }			from '../../../../providers/user-data';
import { WorkspaceData }	from '../../../../providers/workspace-data';

import { WarningUnloggedUserModalComponent }				from '../../modal/warningUnloggedUserModal.component';
import { FormBuilder, FormGroup, FormControl, Validators }  from '@angular/forms';

import * as _ from 'underscore';

/*
export interface FileItemList {
    name: string;
}
*/

export class FileItemDataSource extends DataSource<FileObject>
{
    public objSubject: any;


	constructor(objSubject: any)
	{
		super();
        this.objSubject = objSubject;
	}


	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<FileObject[]>
	{
		return Observable.of(this.objSubject.pj);
	}

	disconnect() { }
}



@Component({
	selector:		'editor-panel-task',
	templateUrl:	'./editor-panel-task.component.html',
	inputs:			['task'],
	encapsulation:	ViewEncapsulation.None,
	outputs:		['_onSubmit: onSubmit', '_onCancel: onCancel'],
	styleUrls:		['./editor-panel-task.component.scss']
})


export class EditorPanelTaskComponent implements OnInit, OnDestroy
{
    public startDate = new Date();

	public oldTask:				Task;
    public initialTask:			Task;
    public currentTask:			Task;
    public structureModel:		any[];
    public isTaskEdition:		boolean = false;
    public titleModal:          string;
    public itemSelected:		string;
    public subjectsAvailable:	any[];
    public usersAvailable:		any[];
    public moFormGroup:			FormGroup;
    public scenarioList:		any;

    public currentName:			string;
    public currentType:			number;
    public currentIdSujet:		string;
    public currentDescription:	string;
    public currentAssigneduser: any;

    public sendEmail:			boolean = false;

    public noAccessFile:			boolean = false;
    public readOnlyFile:			boolean = false;
    public canCreateFile:			boolean = true;
    public majSelected:				any		= 'false';
    public canDeleteFile:			boolean = false;
    public typeFileAccess:          string  = 'all';

    public selectedClasseur:	Etablissement;
    public selectedSession:		Session;
    public selectedExamen:		Examen;
    public selectedMatiere:		Matiere;
    public selectedSubject:     Sujet;
    public dateEcheance:		Date;

    public moFormCtrType:			FormControl;
    public moFormCtrSubject:		FormControl;
    public moFormCtrDescription:	FormControl;
    public moFormCtrEcheance:		FormControl;
    public moFormCtrNoAccessFile:	FormControl;
    public moFormCtrCanCreateFile:	FormControl;
    public moFormCtrAssignedUser:	FormControl;
    public moFormCtrSendEmail:		FormControl;

    public filesSubject:					FileItemDataSource;
    public moUserDataEmmiterSubscription:	any;
    public selection:						SelectionModel<FileObject>	= new SelectionModel<FileObject>(true, []);
    public displayedColumns												= ['select', 'name'];

    public unloggedUserSelected: boolean = false;

    public _onSubmit: EventEmitter<any>		= new EventEmitter<any>();
	public _onCancel: EventEmitter<boolean>	= new EventEmitter<boolean>();

    public treeDatas: any = undefined;
    public txtValidateButon: any;

    filteredOptions: Observable<string[]>;

    constructor(public dialogRef:		MatDialogRef<EditorPanelTaskComponent, any>,
                public taskService:		TaskService,
                public fileService:		FileService,
                public encryptService:	EncryptService,
                public fb:				FormBuilder,
                public userData:		UserData,
                public workspaceData:	WorkspaceData,
                public dialog:			MatDialog,
                public translate:       TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any)
	{

		this.translate.get('editorPanel.titleCreation').subscribe((title: string) =>
		{
			this.titleModal = title;
		});

		this.itemSelected = 'informations';

		this.translate.get('editorPanel.new').subscribe((value: string) =>
		{

			this.subjectsAvailable = [
				{
					value:		'new',
					libelle:	value
				}
			];
		});

		this.translate.get('editorPanel.noUser').subscribe((value: string) =>
		{
			this.usersAvailable = [
				{
					value:		'none',
					loggedOnce: true,
					isPrinter:	false,
					libelle:	value
				}];
		});

        let allUsers = this.userData.getUsers();

		if (allUsers)
		{
			for (let key in allUsers)
			{
				if (allUsers[key])
				{
					let userInfo;
					let outputContent = key;

					if (key)
					{
						userInfo = this.userData.getUser(key);
					}

					if (userInfo && userInfo.infos && (userInfo.infos.nom || userInfo.infos.prenom))
					{
						outputContent = '';

						if (userInfo.infos.prenom)	outputContent += userInfo.infos.prenom + ' ';
						if (userInfo.infos.nom)		outputContent += userInfo.infos.nom;
					}

					let loggedOnce: boolean = true;
					if (userInfo && userInfo.state && userInfo.state.active)
					{
						let dateActive = new Date(userInfo.state.active);
						if (dateActive && dateActive.getTime() === 0)
						{
							loggedOnce = false;
						}
					}

					let isPrinter: boolean = false;
					if (userInfo && userInfo.publics && userInfo.publics.isPrinter)
					{
						isPrinter = userInfo.publics.isPrinter === 'true';
					}

					this.usersAvailable.push(
						{
							value:		key,
							loggedOnce: loggedOnce,
							isPrinter:	isPrinter,
							libelle:	outputContent
						});
				}
			}
		}


		if (this.data)
        {
            /**
             * Récupération des éléments de l'arborescence selectionnés
             */
            if (this.data.selectedItems)
            {
                if (this.data.selectedItems[0])
					this.selectedClasseur = this.workspaceData.selectedEtablissement;

                if (this.data.selectedItems[1])
					this.selectedSession = this.workspaceData.selectedSession;

                if (this.data.selectedItems[2])
					this.selectedExamen = this.workspaceData.selectedExamen;

                if (this.data.selectedItems[3])
					this.selectedMatiere = this.workspaceData.selectedMatiere;
			}


			if (this.data.oldTask)
            {
				this.oldTask = this.data.oldTask;
			}
			if (this.data.majSelected)
            {
				this.majSelected = this.data.majSelected;
			}

            /**
             * lorsqu'une tache est transmise au composant d'édition
             * on remplie le formulaire avec les informations existantes
             * pour gérer l'édition
             *
             * On considère lorsqu'une tache est transmise que nous sommes par défaut en mode édition (sauf si le paramètre isEdition est affecté de faux)
             *
             * Setter
             */
            if (this.data.task)
            {
				this.currentTask = this.data.task;

                // ---------------------------------------
				// récupération du process courant
				// ---------------------------------------
				let idEpreuve	= this.currentTask.idEpreuve;
                let oEpreuve	= this.workspaceData.getEpreuve(idEpreuve);

                if (oEpreuve)
                {
                    this.scenarioList = oEpreuve.scenario;
                }

                this.currentName			= this.currentTask.name;
                this.currentType			= Number(this.currentTask.type);
                this.currentIdSujet			= this.currentTask.idSujet;
                this.currentDescription		= this.currentTask.description;
                this.currentAssigneduser	= this.currentTask.assigneduser;
                this.selectedSubject		= this.workspaceData.getSujet(this.currentIdSujet);
				const currentTypeScenario	= this.getType();

				// On détermine si l'imprimeur aura accés ou pas au document de fin de tache
				this.currentTask.hasReadRightForPrinter = currentTypeScenario ? currentTypeScenario.isReadablePrinter === true : false;

                this.translate.get('editorPanel.newTaskText').subscribe((value: string) => {
					const currentTypeName = currentTypeScenario ? currentTypeScenario.name : '';
                });

                if (this.selectedSubject)
                {
                    this.fileService.getFiles(this.selectedSubject.id);
                }

                if (!this.currentAssigneduser)
                {
                    this.currentAssigneduser	= this.usersAvailable[0];
                    this.unloggedUserSelected	= false;
                }
                else
                {
                    this.currentAssigneduser = _.find(this.usersAvailable, (user: any) => { return user.value === this.currentAssigneduser; });

                    if (this.currentAssigneduser)
                    {
                        if (this.currentAssigneduser.loggedOnce === false)
                        {
                            this.unloggedUserSelected = true;
                        }else
                        {
                            this.unloggedUserSelected = false;
                        }
                    }
                }

                this.dateEcheance = this.currentTask.endDate;
                this._initTaskParents();
                this.isTaskEdition = this.data.isEdition === false ? false : true;

                if (this.data.task.idSujet)
                {
                    let treeStructure = this.workspaceData.getBranchFromId(this.data.task.idSujet);

                    this.treeDatas							= {};
                    this.treeDatas.selectedEtablissement	= treeStructure.etablissement;
                    this.treeDatas.selectedSession			= treeStructure.session;
                    this.treeDatas.selectedExamen			= treeStructure.examen;
                    this.treeDatas.selectedMatiere			= treeStructure.matiere;

                    if (this.treeDatas.selectedMatiere)
                    {
                        for (let sujet of this.treeDatas.selectedMatiere.sujets)
                        {
							this.subjectsAvailable.push(
								{
									value:		sujet.id,
									libelle:	sujet.name
								});
						}
                    }
                }

                // ---------------------------------------
                // S'il ne s'agit pas d'une nouvelle tache que l'on créé
                // on initialise l'ensemble des champs
                // ---------------------------------------
				let jsonValue		= this.currentTask.toJson();
				this.initialTask	= Task.fromJsonObject(jsonValue);

                //initialisation des champs relatifs aux droits
                this.noAccessFile	= this.currentTask.noAccessFile;
                this.canCreateFile	= this.currentTask.canCreateFile;

                if (this.currentTask.noAccessFile === false)
                {
                    this.readOnlyFile	= this.currentTask.readOnlyFile;
                    this.canDeleteFile	= this.currentTask.canDeleteFile;
                    this.typeFileAccess = this.currentTask.typeFileAccess;
                }
                else
                {
                    this.readOnlyFile	= false;
                    this.canDeleteFile	= false;
                    this.typeFileAccess = 'all';
                    this.selection		= new SelectionModel<FileObject>(true, []);
                }

            }
            else
            {

                // ---------------------------------------
                // Si auncune tache n'est renseigné on considère qu'il s'agit d'un
                // ajout de tache
				// ---------------------------------------
				const currentTypeScenario	= this.getType();
                this.currentTask			= new Task();
                this.currentTask.flags		= ['inprogress'];

				if (this.workspaceData.selectedSujet)
				{
					this.currentIdSujet = this.workspaceData.selectedSujet.id;
				}

                this.selectedSubject	= this.workspaceData.getSujet(this.currentIdSujet);
				this.scenarioList		= this.workspaceData.selectedMatiere.scenario;

                if (this.workspaceData.selectedMatiere)
                {
                    for (let sujet of this.workspaceData.selectedMatiere.sujets)
                    {
						this.subjectsAvailable.push(
							{
								value:		sujet.id,
								libelle:	sujet.name
							});
                    }
                }

				this.translate.get('editorPanel.formatedText1').subscribe((value1: string) =>
				{
					this.translate.get('editorPanel.formatedText2').subscribe((value2: string) =>
					{
						this.currentName = '';
						this.currentName += value1;
						this.currentName += currentTypeScenario ? currentTypeScenario.name : '';

						if (this.workspaceData.selectedSujet)
						{

							this.currentName += value2;
							this.currentName += this.workspaceData.selectedSujet.name;
						}
					});
				});

                this.currentAssigneduser = this.usersAvailable[0];
                this._initTaskParents();
                this.isTaskEdition = false;

                this.selection = new SelectionModel<FileObject>(true, []);
            }

			const hasAdminRight = this.userData.isAdmin() === true || this.userData.isSuperviseur() === true;

            this.moFormCtrType			= this.fb.control({value: this.currentType,			disabled: hasAdminRight === false},			Validators.required);
            this.moFormCtrSubject		= this.fb.control({value: this.currentIdSujet,		disabled: true},							Validators.required);
            this.moFormCtrDescription	= this.fb.control({value: this.currentName,			disabled: hasAdminRight === false},			Validators.required);
            this.moFormCtrEcheance		= this.fb.control({value: this.dateEcheance,		disabled: hasAdminRight === false},			Validators.required);
            this.moFormCtrAssignedUser	= this.fb.control({value: this.currentAssigneduser, disabled: hasAdminRight === false},			[Validators.required, this.isAssignedUserValid.bind(this)]);
            this.moFormCtrNoAccessFile	= this.fb.control({value: this.noAccessFile,		disabled: hasAdminRight === false});
            this.moFormCtrCanCreateFile	= this.fb.control({value: this.canCreateFile,		disabled: hasAdminRight === false});
            this.moFormCtrSendEmail		= this.fb.control({value: this.sendEmail,			disabled: hasAdminRight === false});

            this.moFormGroup = this.fb.group(
				{
					type:			this.moFormCtrType,
					subject:		this.moFormCtrSubject,
					description:	this.moFormCtrDescription,
					dateEcheance:	this.moFormCtrEcheance,
					assignedUser:	this.moFormCtrAssignedUser,
					noAccessFile:	this.moFormCtrNoAccessFile,
                    canCreateFile:	this.moFormCtrCanCreateFile,
                    sendEmail:      this.moFormCtrSendEmail
                });

			if (this.isTaskEdition === true)
			{
				this.translate.get('editorPanel.title').subscribe((value1: string) =>
				{
					this.translate.get('common.validate').subscribe((value2: string) =>
					{

						this.titleModal			= value1;
						this.txtValidateButon	= value2;
					});
				});
			}
			else
			{
				this.translate.get('editorPanel.titleCreation').subscribe((value1: string) =>
				{
					this.translate.get('common.add').subscribe((value2: string) =>
					{

						this.titleModal			= value1;
						this.txtValidateButon	= value2;
					});
				});
			}

		}
	}


    public ngOnInit()
	{
        this.moUserDataEmmiterSubscription = this.userData.globalEmitter.subscribe((value: any) =>
		{
			if (value === 'files::get')
			{
                this.initExistingFiles();
            }

        });
		this.initExistingFiles();

		this.filteredOptions = this.moFormCtrAssignedUser.valueChanges.pipe(
			startWith<string | any>(null),
			map(value => typeof value === 'string' ? value : value ? value.libelle : ''),
			map(name => name ? this.filterUser(name) : this.usersAvailable.slice())
		);
	}


    public ngOnDestroy()
	{
        this.moUserDataEmmiterSubscription.unsubscribe();
    }


	public filterUser(val: any): string[]
	{

		let libelle = val.libelle ? val.libelle : val;

		return this.usersAvailable.filter(user => user.libelle.toLowerCase().indexOf(libelle.toLowerCase()) === 0);

	}


	public displayUser(user?: any): string | undefined
	{
		return user ? user.libelle : undefined;
	}


    public initExistingFiles()
    {
        this.filesSubject = new FileItemDataSource(this.selectedSubject);

        //Si des fichiers sont sélectionnés dans la tache courante, on les selectionne dans la liste
        if (this.selectedSubject && this.selectedSubject.pj && this.currentTask.fileToAccess)
        {
            let filesSelected = _.filter(this.selectedSubject.pj, (pj: any) => {
                return _.find(this.currentTask.fileToAccess, (fileId: any) => { return fileId == pj.id; }) !== undefined;
            });

            this.selection = new SelectionModel<FileObject>(true, filesSelected);
        }
    }


    /**
     *
     */
	public changeSelectedUser()
	{
		if (this.currentAssigneduser)
		{
			if (this.currentAssigneduser.loggedOnce === false)
			{
				this.unloggedUserSelected = true;

				this.dialog.open(WarningUnloggedUserModalComponent,
					{
						height:			'auto',
						width:			'auto',
						data:			{},
						disableClose:	true
					});

			}
			else
			{
				this.unloggedUserSelected = false;
			}
		}
	}


	public isAssignedUserValid(control: FormControl)
	{
		let result: any = { invalid: true };
		if (control && control.value && control.value.value && control.value.libelle)
		{
			result = null;
		}

		return result;
	}


    public buildInfosTooltipUser(userId: any)
    {
        let currentObjectUser = this.userData.getUser(userId);
        let tooltipCurrentUser: any;

        if (currentObjectUser && currentObjectUser.infos)
        {
            tooltipCurrentUser = currentObjectUser.infos.nom + ' ' + currentObjectUser.infos.prenom;
            tooltipCurrentUser += '\n' + currentObjectUser.infos.mail;

            let professions: any;
            if (typeof currentObjectUser.publics.professions === 'string')
            {
                professions = JSON.parse(currentObjectUser.publics.professions);
            }
            else
            {
                professions = currentObjectUser.publics.professions;
            }

            let matieres: any;
            if (typeof currentObjectUser.publics.matieres === 'string')
            {
                matieres = JSON.parse(currentObjectUser.publics.matieres);
            }
            else
            {
                matieres = currentObjectUser.publics.matieres;
            }

            if(professions && professions.length > 0)
            {
                this.translate.get('common.jobs').subscribe((value: string) => {

                    tooltipCurrentUser += '\n\n' + value + ':';
                    for(let profession of professions)
                    {
                        tooltipCurrentUser += '\n - ' + profession;
                    }
                });
            }

            if(matieres && matieres.length > 0)
            {
                this.translate.get('common.matieres').subscribe((value: string) => {

                    tooltipCurrentUser += '\n\n' + value + ':';
                    for(let matiere of matieres)
                    {
                        tooltipCurrentUser += '\n - ' + matiere;
                    }
                });
            }
        }

        return tooltipCurrentUser;
    }

    /**
     * Dans la partie droits, retourne vrai si tout les fichiers sont selectionnées dans
     * la table de selection des sujets
     */
	isAllSelected()
	{
		const numSelected	= this.selection.selected.length;
		const numRows		= this.filesSubject.objSubject.pj.length;


		return numSelected === numRows;
	}


    /**
     * Dans l'interface des droits si on souhaite choisir les fichiers accessibles
     * Selectionne tous les fichiers dans le tableau s'ils ne sont pas déja tous selectionné.
     * Dans le cas contraire ils sont tous déselectionnés
     */
	masterToggle()
	{
		this.isAllSelected() ? this.selection.clear() : this.filesSubject.objSubject.pj.forEach((pj: any) => this.selection.select(pj));
	}


	/**
	 *
	 */
    public close()
    {
        this.dialogRef.close(false);
    }


    public allowFilesTaskForAssignedUser()
	{
        if (this.currentTask.assigneduser && this.currentTask.assigneduser !== '')
        {
            // On récupère les clé aes des fichiers dont on donne les droits et on les ré-encode avec
            // la clé publique de l'utilisateur selectionné
            let listFile: any[];

            // Liste des fichiers dont on souhaite supprimer les droits d'accès à l'utilisateur concerné
            // On affecte cette liste de l'ensemble des fichiers au départ
            let listFileToLock = _.map(this.filesSubject.objSubject.pj, (pj: any) => { return pj.id });

            if (this.currentTask.noAccessFile === true)
            {
                // Si on désactive l'accès aux fichier on supprime les droits aux fichiers dont l'utilisateur à accès jusqu'à présent
                for (let fileTolock of listFileToLock)
                {
                    this.encryptService.deleteAesKey(this.currentTask.assigneduser, fileTolock);
                }
            }
            else
            {
                // Sinon on détermine les fichiers accèssibles et les fichiers qui ne sont plus accèssibles et on met à jour les droits
                if (this.filesSubject && this.filesSubject.objSubject && this.currentTask.typeFileAccess === 'all')
                {
                    listFile = _.map(this.filesSubject.objSubject.pj, (pj: any) => { return pj.id });
                }
                else
                {
                    if (this.currentTask.typeFileAccess === 'list')
                    {
                        listFile = _.map(this.selection.selected, (pj: any) => { return pj.id; });
                    }
                }


                if (listFile)
                {
                    listFileToLock = _.difference(listFileToLock, listFile);

                    for (let fileTolock of listFileToLock)
                    {
                        this.encryptService.deleteAesKey(this.currentTask.assigneduser, fileTolock);
                    }

                    let currentSubject = this.workspaceData.getSujet(this.currentTask.idSujet);

                    this.encryptService.encryptFilesForUSer(listFile, this.currentTask.assigneduser, currentSubject);
                }
            }
        }
	}


	/**
	 *
	 */
    public validate()
    {
		const currentType:		any = this.getType();
		const currentNameType:	any = currentType ? currentType.name : '';

        this.currentTask.name			= this.currentName;
        this.currentTask.type			= this.currentType.toString();
        this.currentTask.libelleType	= currentNameType;
        this.currentTask.idSujet		= this.currentIdSujet;
        this.currentTask.description	= this.currentDescription;

        if (this.currentAssigneduser && this.currentAssigneduser.value)
        {
            this.currentTask.assigneduser = this.currentAssigneduser.value === 'none' ? '' : this.currentAssigneduser.value;
        }
        else
        {
            this.currentTask.assigneduser = null;
        }

        this.currentTask.noAccessFile		= this.noAccessFile;
        this.currentTask.canCreateFile		= this.canCreateFile;

        if (this.currentTask.noAccessFile === false)
        {
            this.currentTask.readOnlyFile	= this.readOnlyFile;
            this.currentTask.canDeleteFile	= this.canDeleteFile;
            this.currentTask.typeFileAccess = this.typeFileAccess;
            this.currentTask.fileToAccess	= _.map(this.selection.selected, (pj: any) => { return pj.id; });

        }
        else
        {
            this.currentTask.readOnlyFile	= false;
            this.currentTask.canDeleteFile	= false;
            this.currentTask.typeFileAccess = 'none';
            this.currentTask.fileToAccess	= [];
        }

        if (this.dateEcheance)
        {
            this.currentTask.endDate = new Date(this.dateEcheance);
		}

		const datasEmail: any =
        {
			nomSujet: this.selectedSubject ? this.selectedSubject.name : '',
			nomEtape: this.currentTask.typeLib,
			nomExamen: this.workspaceData.selectedExamen.name,
			nomEpreuve: this.workspaceData.selectedMatiere.name
		};

		//Si c'est une édition on met la tache à jour
		if (this.isTaskEdition === true)
		{
			let datasToUpdate: any = this._getDatasToUpdate(this.initialTask, this.currentTask);

			if (datasToUpdate['assigneduser'] && datasToUpdate['assigneduser'] !== '' && this.sendEmail === true)
			{
				this.initialTask.notifyByMail = true;
				datasToUpdate.typeMail = 'newTask';
				datasToUpdate.datasEmail = datasEmail;
			}

			this.taskService.updateTask(this.initialTask, datasToUpdate);
			this.allowFilesTaskForAssignedUser();
		}
        else
        {
            if (this.currentTask.assigneduser === 'none')
            {
                this.currentTask.assigneduser	= '';
                this.currentTask.state			= 'new';
            }
            else
            {
                this.currentTask.state = 'todo';
            }

			let datasMail: any = {};
            if (this.sendEmail === true)
            {
				this.currentTask.notifyByMail = true;

				const currentExamen		= this.workspaceData.getExamen(this.currentTask.idExamen);
				const currentEpreuve	= this.workspaceData.getEpreuve(this.currentTask.idEpreuve);
				const currentSujet		= this.workspaceData.getSujet(this.currentTask.idSujet);

				datasMail =
				{
					nomEtape:	this.getType().name,
					nomExamen:	currentExamen	? currentExamen.name:	'',
					nomEpreuve: currentEpreuve	? currentEpreuve.name:	'',
					nomSujet:	currentSujet	? currentSujet.name:	''
				};
            }

            // Sinon on l'ajoute en BDD
			this.taskService.addTask(this.currentTask, this.sendEmail, datasMail, this.majSelected);
            this.allowFilesTaskForAssignedUser();
        }

        this.dialogRef.close(true);
    }


	/**
	 *
	 */
    public switch_tab(tabName: any)
	{
        this.itemSelected = tabName;
    }


	/**
	 *
	 */
    private _initTaskParents()
    {
        if (this.selectedClasseur)
		{
            this.currentTask.idEtablissement = this.selectedClasseur.id;
		}

        if (this.selectedSession)
		{
            this.currentTask.idSession = this.selectedSession.id;
		}

        if (this.selectedExamen)
		{
            this.currentTask.idExamen = this.selectedExamen.id;
		}

        if (this.selectedMatiere)
		{
            this.currentTask.idEpreuve = this.selectedMatiere.id;
		}
    }


	getType()
	{
        let sReturn = null;
        if (this.scenarioList)
        {
			for (let typeName of this.scenarioList.steps)
            {
                if (typeName.id === this.currentType)
                {
                    sReturn = typeName;
                }
            }
        }
		return sReturn;
	}


	majTitle()
	{
		let sujetId = this.currentIdSujet;
		let sujetName = '';

		this.translate.get('editorPanel.new').subscribe((value1: string) =>
		{
			this.translate.get('editorPanel.formatedText1').subscribe((value2: string) =>
			{
				this.translate.get('editorPanel.formatedText2').subscribe((value3: string) =>
				{

					if (sujetId === 'new')
					{
						sujetName = value1;
						this.selectedSubject = undefined;
					}
					else
					{
						this.selectedSubject = this.workspaceData.getSujet(sujetId);
						if (this.selectedSubject)
						{
							sujetName = this.selectedSubject.name;
						}
					}

					const currentType = this.getType();

					this.currentName = value2;
					this.currentName += currentType ? currentType.name : '';
					this.currentName += value3;
					this.currentName += sujetName;
				});
			});
		});
	}


    canValidateTask()
    {
        let result = true;

		if ((this.userData.isAdmin() === false && this.userData.isSuperviseur() === false) || !this.currentName || this.currentName === undefined || this.currentName === '' || this.currentType === undefined)
        {
            result = false;
        }

        return result;
    }


	onChangeAssignedUser(event: any)
	{
		this.currentAssigneduser = event;

		// Si l'utilisateur selectionné est un imprimeur, on modifie les droits pour qu'il ne puisse pas
		// créer de fichier et pour qu'il ne puisse lire que les fichiers flaggés à la tache précédente
		// (à condition que cette dernière soit accéssible aux imprimeur dans le scénario)
		if (this.currentAssigneduser.isPrinter === true)
		{
			this.moFormCtrNoAccessFile.setValue(true);
			this.moFormCtrCanCreateFile.setValue(false);

			// on vérifie que la tache précédente est accéssible pour l'imprimeur
			if (this.oldTask && this.oldTask.hasReadRightForPrinter === true)
			{
				// On récupère les fichiers flaggés pour cette dernière tache
				const pjsFiltered = _.filter(this.filesSubject.objSubject.pj, (pj: any) =>
				{

					let result = false;

					if (pj.deleted === false)
					{
						for (const tag of pj.tags)
						{
							const partTags = tag.split('::');
							if (partTags[0] === '*task' && partTags[1] === this.oldTask.id)
							{
								result = true;
							}
						}
					}

					return result;
				});

				if (pjsFiltered && pjsFiltered.length > 0)
				{
					this.moFormCtrNoAccessFile.setValue(false);
					this.moFormCtrCanCreateFile.setValue(true);

					this.readOnlyFile		= true;
					this.canDeleteFile		= false;
					this.typeFileAccess		= 'list';
					this.selection			= new SelectionModel<FileObject>(true, pjsFiltered);
				}
			}
		}
		else
		{
			// sinon par défaut l'utilsiateur peux créer des fichiers et lire tout les fichiers du sujet
			this.moFormCtrNoAccessFile.setValue(false);
			this.moFormCtrCanCreateFile.setValue(true);

			this.readOnlyFile		= false;
			this.canDeleteFile		= false;
			this.typeFileAccess		= 'all';
		}
	}


	/**
	 *
	 */
    private _getDatasToUpdate(oldTask: Task, newTask: Task)
    {
        let datas: any = {};

        // ---------------------------------------
        // ToComment
        // ---------------------------------------
        if (oldTask.description !== newTask.description)
        {
            datas['description'] = newTask.description;
        }

        if (oldTask.name !== newTask.name)
        {
            datas['name'] = newTask.name;
        }

        if (oldTask.type !== newTask.type)
        {
            datas['type'] = newTask.type;
        }

        // ---------------------------------------
        // ToComment
        // ---------------------------------------
        if (oldTask.state !== newTask.state)
        {
            datas['state'] = newTask.state;
        }

        // ---------------------------------------
        // ToComment
        // ---------------------------------------

        if (oldTask.assigneduser !== newTask.assigneduser)
        {
            datas['assigneduser'] = newTask.assigneduser;
            //si l'utilisateur assigné est supprimé, on met automatiquement
            // la tache en non assigné
            if (datas['assigneduser'] === '' ||  datas['assigneduser'] === 'none')
            {
                datas['assigneduser'] = '';
                datas['state'] = 'new';
            }
            else
            {
                //Si le nouvel utilisateur assigné est non vide et que le l'état initial
                //de la tache est non assigné alors on la met automatiquement à l'état assigné
                if (oldTask.state === 'new')
                {
                    datas['state'] = 'todo';
                }
            }
        }

		let oldEndDate = oldTask.endDate ? oldTask.endDate.getTime() : null;
		let newEndDate = newTask.endDate ? newTask.endDate.getTime() : null;

		if (oldEndDate !== newEndDate)
		{
			datas['enddate'] = newTask.endDate;
		}

		if (oldTask.noAccessFile !== newTask.noAccessFile)
		{
			datas['noaccessfile'] = newTask.noAccessFile ? '1' : '0';
		}

		if (oldTask.canCreateFile !== newTask.canCreateFile)
		{
			datas['cancreatefile'] = newTask.canCreateFile ? '1' : '0';
		}

		if (oldTask.readOnlyFile !== newTask.readOnlyFile)
		{
			datas['readonlyfile'] = newTask.readOnlyFile ? '1' : '0';
		}

		if (oldTask.canDeleteFile !== newTask.canDeleteFile)
		{
			datas['candeletefile'] = newTask.canDeleteFile ? '1' : '0';
		}

		if (oldTask.typeFileAccess !== newTask.typeFileAccess)
		{
			datas['typefileaccess'] = newTask.typeFileAccess;
		}

		let intersectFiles = _.intersection(oldTask.fileToAccess, newTask.fileToAccess);

		if (oldTask.fileToAccess.length !== newTask.fileToAccess.length || intersectFiles.length !== oldTask.fileToAccess.length)
		{
			datas['filetoaccess'] = newTask.fileToAccess;
		}

        return datas;
    }
}
