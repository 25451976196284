import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy }	from '@angular/core';
import { MatDialogRef }					        from '@angular/material';
import { MAT_DIALOG_DATA }                      from '@angular/material';

import { UserData }			from '../../providers/user-data';
import { UserService }      from '../../services/user.service';
import { EncryptService }	from '../../services/encrypt.service';

import * as _ from 'underscore';

@Component({
	selector:		'confirm-give-files-keys-modal',
	templateUrl:	'confirmGiveFilesKeysModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./confirmGiveFilesKeysModal.component.scss']
})


/**
 * 
 */
export class ConfirmGiveFilesKeysModalComponent implements OnInit, OnDestroy
{

	public nameUser:				any;
	public mailUser:				any;
	public rsaKeyUser:				any;
	public moGlobalSubscription:	any;

	public processing:			boolean = false;
	public processPercent:		number = 0;
	public processFinished:		boolean = false;

	public aesKeysQueue:		any[] = [];
	public cptEasKeysToSend:	number = 0;

	constructor(public dialogRef:		MatDialogRef<ConfirmGiveFilesKeysModalComponent, any>,
				public userData:		UserData,
				public userService:		UserService,
				public encryptService:	EncryptService,
				@Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.user && data.user.completeName && data.user.publics && data.user.publics.rsaPublicKey) 
        {
			this.nameUser	= data.user.completeName;
			this.mailUser	= data.user.mail;
			this.rsaKeyUser = data.user.publics.rsaPublicKey;
		}
	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		this.moGlobalSubscription = this.userData.globalEmitter.subscribe((value: any) =>
		{
            if (value.length && value.length > 1 && value[0] === 'getAesKeysFromUser')
			{
				const aesKeys = value[1];

				for (const aesKey of aesKeys)
				{
					this.aesKeysQueue.push(
						{
							key:		aesKey.key,
							val:		aesKey.val,
							valDecoded: undefined,
							valEncoded: undefined,
							who:		this.mailUser
						});
				}

				this.cptEasKeysToSend = this.aesKeysQueue.length;

				const keysError: any[] = [];

				for (const aesKeyQueue of this.aesKeysQueue)
				{
					// Pour chaque clé AES on décode la clé avec la clé RSA de l'utilisateur courant
					const rsaKey = this.userData.getRSAPrivate();

					this.encryptService.decryptData(aesKeyQueue.val, rsaKey).then((decoded: any) =>
					{
						aesKeyQueue.valDecoded = decoded;

						// On réencrypte la  clé avec la clé RSA publique de l'utilisateur concerné
						this.encryptService.encryptData(decoded, this.rsaKeyUser).then((encryptedAes: any) =>
						{
							aesKeyQueue.valEncoded = encryptedAes;
							this.encryptService.sendAesKey(this.mailUser, aesKeyQueue.key, aesKeyQueue.valEncoded, new Date());

						}).catch((err: any) => 
						{
							keysError.push(aesKeyQueue.key);
						});

					}).catch((err: any) => 
					{
						keysError.push(aesKeyQueue.key);
					});
				}
				
				this.aesKeysQueue = _.filter(this.aesKeysQueue, (aesKey: any) => keysError.includes(aesKey.key) === false);
			}

			if (value.length && value.length > 1 && value[0] === 'userFileKey')
			{
				this.aesKeysQueue = _.filter(this.aesKeysQueue, (aesKey: any) => aesKey.key !== value[1]);

				// On met à jour le pourcentage d'avancement
				this.processPercent = Math.floor(((this.cptEasKeysToSend - this.aesKeysQueue.length) / this.cptEasKeysToSend) * 100);
				if (this.aesKeysQueue.length === 0)
				{
					this.processFinished = true;
				}
			}
		});
	}


	/**
	 * 
	 */
	public ngOnDestroy()
	{
		if (this.moGlobalSubscription) 
		{
			this.moGlobalSubscription.unsubscribe();
		}
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 */
	public close()
	{
		this.dialogRef.close(true);
	}


	/**
	 * 
	 */
	public validate()
	{
		this.processing = true;
		this.userService.getAesKeysFromUser(this.userData.mail);
	}
}
