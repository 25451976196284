<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">Avertissement: aucune clé RSA détectée</div>
	</div>
	<div class="dialog_content">
		<mat-card-content>
			<label *ngIf="typeWarning === 1">Attention, aucune clé RSA privée n'est detectée sur votre machine ou sur votre navigateur.</label>
			<label *ngIf="typeWarning === 2">Attention, votre clé RSA actuelle n'est pas valide.</label>
			<div class="clear"></div>
			<label>Sans clé RSA valide, vous n'aurez pas d'accès aux fichiers.</label>
			<div class="clear"></div>
			<label>Veuillez importer votre clé RSA dans la rubrique 'Mon compte' -> 'Clé du compte'.</label>
			<div class="clear"></div>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">Continuer</button>
	</div>
</mat-dialog>
