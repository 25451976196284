import { Component }	from './component';


export class Note extends Component
{

    private _idUser:        string = '';
    private _note:          number = 0;


	/**
	 * 
	 */
    public static fromJsonObject(jsonObj: any): Note
    {

        let note = new Note();

        note.dateCreation	= jsonObj.creationdate;
        note.idUser			= jsonObj.idUser;
        note.note			= jsonObj.note;

        return note;
    }


	/**
	 * 
	 */
    constructor(creationDate: Date = new Date(), idUser: string = '', note: number = 0)
    {
        super();
				
		// ---------------------------------------
		// ToComment
		// ---------------------------------------	
        this.dateCreation	= creationDate;
        this._idUser		= idUser;
        this._note			= note;
    }


    get idUser()	{ return this._idUser; }
	get note()		{ return this._note; }

	set idUser(idUser)	{ this._idUser = idUser; }
	set note(note)		{ this._note = note; }


	/**
	 * 
	 */
    public toJson()
    {
        let result = 
        {
            idUser:			this.idUser,
            note:			this.note,
            creationdate:	this.dateCreation
        };

        return result;
    }
}

