import { Component, ViewEncapsulation, ViewChild, OnInit }		from '@angular/core';

import { AppComponent }	from '../../app.component';
import { Router, ActivatedRoute, NavigationEnd }		from '@angular/router';

import { UserData }			from '../../providers/user-data';


@Component({
    selector:		'admin',
    templateUrl:	'./admin.component.html',
    encapsulation:	ViewEncapsulation.None,
    styleUrls:		['./admin.component.scss']
})


/**
 *
 */
export class AdminComponent implements OnInit
{
	public panelSelected:					number	= 0;
    public isChatPanelSelected:				boolean	= true;
    public isForumtPanelSelected:			boolean	= false;
    public isNotificationstPanelSelected:	boolean	= false;
    public mbLoadState:						boolean	= false;
	public matTabs:							any;

    /**
     * Creates an instance of CommunityPage.
     *
     * @param {PopoverController} popoverCtrl
     * @param {NavController} nav
     *
     * @memberOf CommunityPage
     */
	constructor( public userData:	UserData,
				 public router:		Router)
	{
		this.panelSelected = 0;

		//	Toodo => récup l'index en cours, et en fonction on affiche
		//	appComponent.setPageTitle('Communauté - Messagerie');
		//this.matTabs.position = 1;

		if (!this.userData.isAdmin() && !this.userData.isSuperviseur())
		{
			this.router.navigate(['/home']);
			return;
		}

	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		this.panelSelected = 0;
		this.refreshTab(0);
	}


	/**
	 * 
	 */
	public ngOnChange()
	{

	}


	/**
	 *
	 *
	 * @memberOf CommunityPage
	 */
	public updateSegment(): void
	{
		// this.isChatPanelSelected 			= this.panelSelected === 0 ? true : false		;
		// this.isForumtPanelSelected 			= this.panelSelected === 1 ? true : false		;
		// this.isNotificationstPanelSelected 	= this.panelSelected === 2 ? true : false;
	}


    /**
     *
     */
	public addCommunityItem(nameButton: any)
	{
		switch (nameButton)
		{
			case 'newTopic':
			break;
			case 'publicConversation':
			case 'privateGroup':
			//			case 'privateConversation':
			//				let popover = this.popoverCtrl.create(PopoverCreateChat, {type: nameButton}, {cssClass: 'popover-create-chat'});
			//				popover.present();
			//				break;
		}
	}


	/**
	 * Retourne true une fois la page chargée
	 *
	 * @returns
	 *
	 * @memberOf WorkspacePage
	 */
	public isLoaded(): boolean
	{
		return this.mbLoadState;
	}


    /**
     * Display message for chat, topic and notifications creation. Display equally error messages
     *
     * @returns
     *
     * @memberOf WorkspacePage
     */
	public presentToast(message: any)
	{
		//		let toast = this.toastCtrl.create({
		//			message: message,
		//			duration: 3000
		//		});
		//		toast.present();
 	}


	/**
	 * Call on every modification of the tab
	 */
	public refreshTab(e: any)
	{

		// En fonction de l'index on va choisir quelle section est à afficher
	}
}
