import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DataSource }	                            from '@angular/cdk/collections';
import {Observable} from 'rxjs/Rx';

import * as _ from 'underscore';


/**
 *
 */
export interface Etape
{
	id:					number;
	name:				string;
	position:			number;
	typevalid:			string;
	read:				boolean;
	write:				boolean;
	news:				boolean;
}



/**
 *
 */
export class EtapeDataSource extends DataSource<Etape>
{
    public objProcess: any;

	constructor(objProcess: any)
	{
		super();
        this.objProcess = objProcess;
	}


	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<Etape[]>
	{
		return Observable.of(this.objProcess.steps);
	}

	disconnect() { }
}


@Component({
	selector:		'process-editor',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./processEditor.component.scss'],
	templateUrl:	'processEditor.component.html'
})


export class ProcessEditorComponent implements OnInit
{
    @Input() readOnly: any;

    public isReadOnly:		boolean = true;
    public currentScenario: any;

    public cptStep:				any;
    public etapesDatas:			Etape[]			= [];
    public etapesProcess:		EtapeDataSource;
    public displayedColumns:	any;


	public typesValidation =
	[
		{
			label: 'Administrateur',
			value: 'superviseur'
		},
		{
			label: 'Normale',
			value: 'normale'
		}
	];


	/**
	 *
	 */
	constructor()
    {
		this.cptStep = 0;
        //this.currentScenario.steps = this.etapesDatas;
		//this.etapesProcess = new EtapeDataSource(this.currentScenario);
	}


	@Input()
	set scenario(scenario: any)
	{
		if (scenario)
		{
			this.currentScenario = scenario;
			this.initExistingSteps();
		}
	}


	/**
	 *
	 */
	ngOnInit()
	{
		if (this.readOnly === 'false' || this.readOnly === false)
		{
			this.isReadOnly			= false;
			this.displayedColumns	= ['name', 'typevalid', 'read', 'write', 'news', 'actions'];
		}
		else
		{
			this.isReadOnly			= true;
			this.displayedColumns	= ['name', 'typevalid', 'read', 'write', 'news'];
		}

		if (this.currentScenario)
		{
			if (this.currentScenario.steps && this.currentScenario.steps.length > 0)
			{
				this.initExistingSteps();
			}
		}
	}


	/**
	 *
	 */
	public initExistingSteps()
	{
		this.cptStep				= 0;
		let formatedSteps: Etape[]	= [];

		for (let step of this.currentScenario.steps)
		{
			let newEtape: Etape =
				{
					id:						this.cptStep,
					name:					step.name,
					position:				this.cptStep + 1,
					typevalid:				step.typevalid,
					read:					step.read				? step.read : false,
					write:					step.write				? step.write : false,
					news:					step.news				? step.news : false
				};

			this.cptStep++;

			formatedSteps.push(newEtape);
        }

        this.currentScenario.steps	= formatedSteps;
        this.etapesProcess			= new EtapeDataSource(this.currentScenario);
    }


	/**
	 *
	 */
    public createNewStep()
	{
		if (this.cptStep < 7)
		{
			let newEtape: Etape =
				{
					id:					this.cptStep,
					name:				'',
					position:			this.currentScenario.steps.length + 1,
					typevalid:			'superviseur',
					read:				false,
					write:				false,
					news:				false
				};
			this.cptStep++;

			this.currentScenario.steps.push(newEtape);

			this.etapesProcess = new EtapeDataSource(this.currentScenario);
		}
    }


	/**
	 *
	 * @param idStep
	 */
    public removeStep(idStep: number)
    {
        this.currentScenario.steps	= _.filter(this.currentScenario.steps, (step: any) => { return step.id !== idStep; });
        this.etapesProcess			= new EtapeDataSource(this.currentScenario);
    }
}
