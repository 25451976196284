/***********************************************
 * Angular
 **********************************************/
import { Component, OnInit, OnChanges, OnDestroy, HostListener }	from '@angular/core';
import { ViewEncapsulation, ViewChild, EventEmitter }				from '@angular/core';
import {TranslateService} 											from '@ngx-translate/core';

import { AppState }													from './app.service';
import { MatSidenav }												from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart }	from '@angular/router';
import { MatDialog }												from '@angular/material';
import { Http }														from '@angular/http';

import { Location, PlatformLocation }	from '@angular/common';
import { SCClientSocket }				from 'socketcluster-client';

/***********************************************
 * Classes
 **********************************************/
import { Tools }				from './classes/tools';
import { Etablissement }		from './classes/etablissement.class';
import { Session }				from './classes/session.class';
import { Examen }				from './classes/examen.class';
import { Matiere }				from './classes/matiere.class';
import { Sujet }				from './classes/sujet.class';
import { Task }					from './classes/task.class';
import { Piece }				from './classes/piece.class';
import { File as FileSubject }	from './classes/file.class';
import { SpecialTime }			from './classes/specialTime.class';


/***********************************************
 * Providers
 **********************************************/
import { FooterComponent }	from './pages/footer/footer.component';
import { UserData }			from './providers/user-data';
import { WorkspaceData }	from './providers/workspace-data';
import { ChatData }			from './providers/chat-data';
import { TaskData }			from './providers/task-data';
import { AdminData }		from './providers/admin-data';
import { SavedData }		from './providers/saved-data';
import { VaultData }		from './providers/vault-data';
import { BaremeData }		from './providers/bareme-data';
import { FileData }			from './providers/file-data';


/***********************************************
 * Service
 **********************************************/
import { SocketService }		from './services/socket.service';
import { SocketVaultService }	from './services/socketVault.service';
import { EtablissementService } from './services/etablissement.service';
import { SujetService }			from './services/sujet.service';
import { FileService }			from './services/file.service';
import { PassationService }		from './services/passation.service';
import { CandidatService }		from './services/candidat.service';
import { MessagesService }		from './services/messages.service';
import { StatisticsService }	from './services/statistics.service';
import { AuthService }			from './services/auth.service';
import { UserService }			from './services/user.service';
import { TaskService }			from './services/task.service';
import { EncryptService }		from './services/encrypt.service';
import { CreationHttpService }	from './services/http/creationHttp.service';

// ---------------------------------------
// Modals
// ---------------------------------------
import { ConnectApplicationModalComponent } 	from './modal/connect/connectApplication.component';
import { ConnectOTPModalComponent }				from './modal/connectOTP/connectOTPModal.component';
import { ConfirmDeleteElementModalComponent } 	from './modal/confirmDeleteElement/confirmDeleteElementModal.component';
import { InfoResetRsaModalComponent } 			from './modal/infoResetRsa/infoResetRsaModal.component';
import { DownloadRsaModalComponent } 			from './modal/downloadRsa/downloadRsaModal.component';
import { ConnectNewMachineModalComponent }		from './modal/connectNewMachine/connectNewMachineModal.component';
import { ResetPasswordModalComponent }			from './modal/resetPassword/resetPasswordModal.component';
import { WarningAddBlockerEnabledComponent }	from './modal/warningAddBlockerEnabled/warningAddBlockerEnabledModal.component';
import { WarningRsaModalComponent }				from './modal/warningRsa/warningRsaModal.component';
import { SelectAccountModalComponent }			from './modal/selectAccount/selectAccountModal.component';
import { ISocketData, SocketWhat }				from './models/message.model';
import { UserModalComponent }					from './modal/user/userModal.component';
import { InfoInitAccountModalComponent }		from './modal/infosInitAccount/infosInitAccountModal.component';

import {MatSnackBar} from '@angular/material';


import
{
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
 } from '@angular/material-moment-adapter';
  import * as moment from "moment";

  import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { MyDateAdapter } from './classes/dateAdapter';

import * as _ from 'underscore';
import * as $ from 'jquery';
import { timingSafeEqual } from 'crypto';
declare var loading_screen: any;

import {Injectable} from '@angular/core';
import {NativeDateAdapter} from '@angular/material';


@Component({
	selector:		'app',
	styleUrls:		['./app.component.scss'],
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./app.component.html',
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MyDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})


/**
 *
 */
export class AppComponent implements OnChanges, OnInit, OnDestroy
{
	@ViewChild('sidenav') sidenav: MatSidenav;
	pages: Array<{ title: string, url: string }>;

	public canAccessChat:		boolean = false;
	public haveChatData:		boolean = false;
	public showMenu:			boolean = false;
	public isConnected:			boolean = false;
	public inLoginPanel:		boolean = false;
	public inCreationPart:		boolean = false;
	public timedOut:			boolean = false;
	public showBoxForum:		boolean = false;
	public showBoxChat:			boolean = false;
	public workspaceLoading:	boolean = false;
	public showBoxNotification: boolean = false;

	public socket:					any = null;
	public dialogRef:				any = null;
	public currentStateApplication: any = {};

	public currentLogin:		string = '';
	public pageTitle:			string = 'Accueil';
	public angularclassLogo:	string = 'assets/img/angularclass-avatar.png';
	public idleState:			string = 'Not started.';
	public firstLetterIcone:	string = '';


	public lastPing?:	Date	= null;
	public maHomeRooms:	any		= [];
	public currentName:	any		= '';

	public homeInfosEmitter: EventEmitter<string> = new EventEmitter<string>();

	public isCreationSelected:	boolean	= true;
	public isPassationSelected: boolean = false;
	public isResultatSelected:		boolean	= false;
	public isCheckingRSAKey:		boolean = false;
	public isConfigInitiated: 		boolean = false;
	public dialogRefSelectAccount: 	any 	= undefined;
//	private notifier: NotifierService;

	/**
	 *
	 */
	constructor(
		public translate: 				TranslateService,
		public appState:				AppState,
		public userData:				UserData,
		public baremeData:				BaremeData,
		public workspaceData:			WorkspaceData,
		public fileData:			FileData,
		public chatData:				ChatData,
		public taskData:				TaskData,
		public adminData:				AdminData,
		public vaultData:				VaultData,
		public router:					Router,
		public route:					ActivatedRoute,
		public dialog:					MatDialog,
		public footerComponent:			FooterComponent,
		public authService:				AuthService,
		public userService:				UserService,
		public socketService:			SocketService,
		public socketVaultService:		SocketVaultService,
		public etablissementService:	EtablissementService,
		public sujetService:			SujetService,
		public messagesService:			MessagesService,
		public taskService:				TaskService,
		public statisticsService:		StatisticsService,
		public encryptService:			EncryptService,
		public savedData:				SavedData,
		public fileService:				FileService,
		public passationService:		PassationService,
		public candidatService:			CandidatService,
		private _location:				Location,
		private location:				PlatformLocation,
		public snackBar:				MatSnackBar,
		public http:					Http,
		private _adapter: 				DateAdapter<any>)
	{
			this.translate.setDefaultLang('fr');
			this.translate.use('fr');
			this._adapter.setLocale('fr');

			// ---------------------------------------
			// Code pour déterminer si l'user est afk
			// ---------------------------------------
			// sets an idle timeout of 30 seconds, for testing purposes.
			// idle.setIdle(30);
			// sets a timeout period of 30 seconds. after x seconds of inactivity, the user will be considered timed out.
			// dle.setTimeout(30);
			// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
			// idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
			//
			// On désactive par défault le drop sur toute l'application
			// $(document).bind('drop dragover', function (e) {      e.preventDefault(); });
			//
			// idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
			// idle.onTimeout.subscribe(() =>
			// {
			// 		this.idleState = 'Timed out!';
			//		this.timedOut = true;
			// });
			//
			// idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
			// idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
			//
			// sets the ping interval to 15 seconds
			// keepalive.interval(15);
			// keepalive.onPing.subscribe(() => this.lastPing = new Date());
			//
			// this.reset();


			// Vérification du compte de l'user => si non loggué => loginmatMenuTriggerFor
			// Si logué => home page, ou le controller de l'appli
			this.pages = [
				{ title: 'Accueil',		url: '/' },
				{ title: 'Workspace',	url: 'workspace' },
				{ title: 'Communauté',	url: 'community' },
				{ title: 'Tâches',		url: 'task' },
				{ title: 'Account',		url: 'login' }
			];

			this.currentStateApplication = this.userData.getStateApplication();
		// this._checkAddBlocker();

			// ---------------------------------------
			// Gestion des chargements des routes !
			// ---------------------------------------
			router.events.subscribe((val) =>
			{
				this.initConfigs().then(res =>
				{
					// Tant que AddBlock est activé on demande à l'utilisateur de la désactiver
					this.userData.setStateApplication('application', 'pending');

					if (val instanceof NavigationStart)
					{
						// Affichage du loader quand on charge le workspace
						if (val.url === '/workspace')
						{
							this.workspaceLoading = true;
						}
					}

					// Masque le loader quand le workspace est loaded
					if (val instanceof NavigationEnd)
					{
						if (val.url === '/workspace')
						{
							this.workspaceLoading = false;
						}
						// this._checkAddBlocker();

						this.inLoginPanel = val && (val.url === '/login' || val.url === '/');
						// see also

						// Si on n'est pas dans la fenêtre de login est que l'on n'est pas connecté entièrement (validation mail, machine, otp)
						// on est redirigé vers la fenètre de login

						// If we are in the login panel, we don't show the header and the footer menus
						this.inCreationPart = val.url !== '/passation' && val.url !== '/result';
						this.showMenu = !this.inLoginPanel && !this.inCreationPart;

						if (!this.inLoginPanel)
						{
							if (this.userData.mustReset === true || this.userData.otpAuth === true || this.userData.mustValidateBrowser === true)
							{
								// Si on est pas dans le login et que des validations ne sont pas faite on est redirigé vers le login
								this.userData.logout();
								this.socketService.logout();
								this.socketVaultService.logout();
								this.taskData.initTasks();
								this.router.navigate(['/login']);
								this.isConnected = false;
							}
							else
							{
								let isConnected = this._checkConnection();
								if (isConnected === true)
								{
									this.isConnected = true;

									if (this.inLoginPanel)
									{
										this.router.navigate(['/home']);
									}
								}
								else
								{
									this.isConnected = false;

									if (this.userData.getUserId())
									{
										this.openModalConnectApplication((result) =>
										{
											this.userData.dialogRefModalConnection = null;

											if (result === false)
											{
												this.router.navigate(['/login']);
											}
										});
									}
									else
									{
										this.router.navigate(['/login']);
									}
								}
							}
						}
						else
						{
							if (this.userData.mustReset === false && this.userData.otpAuth === false && this.userData.mustValidateBrowser === false)
							{
								let isConnected = this._checkConnection();
								if (isConnected === true)
								{
									this.isConnected = true;
									this.router.navigate(['/home']);
								}
								else
								{
									this.isConnected = false;
								}
							}
						}
					}

				}).catch(err =>
				{
				});
			});

			this.location.onPopState(() =>
			{
			});
			// this.disableBrowserNavigation();
	}

/*	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event: any)
	{
		event.returnValue = "?";
	}
*/
	backClicked()
	{
		this._location.back();
	}

	ngOnDestroy()
	{
		this.userData.globalEmitter.unsubscribe();
		/*	this.userData.logout();
		this.socketService.logout();
		this.socketVaultService.logout();
		*/
	}


	public showNotification(psMessage: string, psType: string= 'success'): void
	{
		this.userData.notifEmitter.emit([psType, psMessage]);

	}


	/****************************************
	 * Interface Page
	****************************************/
	/**
	 * Setter pour le titre de page
	 */
	public setPageTitle(title: string): void
	{
		this.pageTitle = title;
	}


	public changeSelected(psName: any)
	{
		// ---------------------------------------
		// En fonction on va ouvrir le bon formulaire
		// ---------------------------------------
		this.isCreationSelected		= false;
		this.isPassationSelected	= false;
		this.isResultatSelected		= false;
		this.showMenu				= true;

		switch (psName)
		{
			case 'creation':
				this.isCreationSelected = true;
				break;
			case 'passation':
				this.isPassationSelected	= true;
				this.showMenu				= false;
				break;
			case 'result':
				this.isResultatSelected = true;
				this.showMenu			= false;
				break;
			default:
				break;
		}
	}


	public ngOnChanges()
	{
	}


	public closeBox(psTitle = '', poEvent: any = null)
	{
		if (poEvent.target.id === 'commons_header_right_action_forum' || poEvent.target.id === 'commons_header_right_action_chat' || poEvent.target.id === 'commons_header_right_action_notification')
		{
			return;
		}

		// Switch pour afficher la bonne
		switch (psTitle)
		{
			case 'forum':
				this.showBoxForum = false;
				break;

			case 'chat':
				this.showBoxChat = false;
				break;

			case 'notification':
				this.showBoxNotification = false;
				break;

			default:
				this.showBoxChat			= false;
				this.showBoxForum			= false;
				this.showBoxNotification	= false;
				break;
		}
	}


	/**
	 *
	 */
	public ngOnInit()
	{
		this.userData.globalEmitter.subscribe((value: any) =>
		{
			if (value.length && value.length === 3 && value[0] === 'RSA:validate')
			{
				let currentRsaKey = this.userData.getRSAPrivate();

				if (this.isCheckingRSAKey === true && currentRsaKey)
				{
					this.isCheckingRSAKey 	= false;
					let dataEncoded			= value[1];
					let dataToValidate 		= value[2];

					this.encryptService.decryptData(dataEncoded, currentRsaKey).then((decoded: any) =>
					{
						if (dataToValidate === decoded)
						{
							this._otpAuthentification();
						}
						else
						{
							// On affiche un message d'érreur
							this.userData.deleteRSAPrivate();
							let dialogRefWarningRsa = this.dialog.open(WarningRsaModalComponent,
								{
									height:			'210px',
									panelClass:		'responsive_mini_modal',
									width:			'600px',
									data:
									{
										typeWarning: 2
									},
									disableClose: true
								});

							dialogRefWarningRsa.afterClosed().subscribe((resultWarning: any) =>
							{
								this._otpAuthentification();
							});
						}

					}).catch((err: any) =>
					{
						// On affiche un message d'érreur
						this.userData.deleteRSAPrivate();
						let dialogRefWarningRsa = this.dialog.open(WarningRsaModalComponent,
							{
									height:			'210px',
									panelClass:		'responsive_mini_modal',
									width:			'600px',
								data:
								{
									typeWarning: 2
								},
								disableClose:	true
							});

						dialogRefWarningRsa.afterClosed().subscribe((resultWarning: any) =>
						{
							this._otpAuthentification();
						});
					});
				}
			}
		});

		this.setListenerAuthenticate();

		loading_screen.finish();
	}


	/**
	 * Applique tt les listener globaux de l'application
	 */
	public setListenerAuthenticate()
	{
		// Ecouteur pour l'authentification, Une fois que lui est bon on set tt le reste
		this.socketService.on('authenticate',		this.onAuth.bind(this));
		this.socketService.on('authTokenChange',	this.updateToken.bind(this));

		this.socketService.on('authStateChange',	this.authStateChange.bind(this));
		this.socketService.on('error',				this.onErrorServer.bind(this));
		this.socketService.on('deauthenticate',		this.onDisconnect.bind(this));

		// Ecouteurs
		this.socketService.on('read',				this.dataReceivedRead.bind(this));
		this.socketService.on('error',				this.dataReceivedError.bind(this));
		this.socketService.on('debug',				this.dataReceivedDebug.bind(this));
		this.socketService.on('create',				this.dataReceivedCreate.bind(this));
		this.socketService.on('update',				this.dataReceivedUpdate.bind(this));
		this.socketService.on('delete',				this.dataReceivedDelete.bind(this));
		this.socketService.on('accountUpdates', 	this.dataReceivedAccountUpdates.bind(this));
		this.socketService.on('user',				this.dataReceivedUser.bind(this));
		this.socketService.on('message_creation',	this.dataReceivedMessage.bind(this));
		this.socketService.on('machine',			this.fileReceived.bind(this));
	}

	/**
	 * Après l'ajout d'un fichier, ajoute ce dernier après retour serveur
	 * @param datas 
	 */
	public fileReceived(datas: any)
	{
		if (datas.cmd && datas.cmd === 'file')
		{
			if (datas.args && datas.args.resultFinal && datas.args.resultFinal.id && datas.args.resultFinal.file)
			{
				const fileAdded = FileSubject.fromJsonObject(datas.args.resultFinal.file);

				if (datas.args.resultFinal.encodedRsaKey)
				{
					fileAdded.encryptedAesKeys.push(datas.args.resultFinal.encodedRsaKey);
				}

				// Si le fichier appartient au sujet courant on met ce dernier à jour sinon on recherche le sujet concerné
				let subjectToUpdate: any;

				if (this.workspaceData.selectedSujet && this.workspaceData.selectedSujet.id === fileAdded.idSubject)
				{
					subjectToUpdate = this.workspaceData.selectedSujet;
				}
				else
				{
					subjectToUpdate = this.workspaceData.getSujet(fileAdded.idSubject);
				}

				if (subjectToUpdate)
				{
					let fileIndex = -1;

					if (subjectToUpdate.pj)
					{
						fileIndex = _.findIndex(subjectToUpdate.pj, (pj: any) =>
						{
							return pj.id === datas.args.resultFinal.id;
						});
					}

					if (fileIndex !== -1)
					{
						if (subjectToUpdate.pj[fileIndex].thumbs.length === 0)
						{
							this.fileService.downloadThumbnail(fileAdded).subscribe((res) =>
							{
								if (res && res.url)
								{
									fileAdded.errorThumb = false;
									fileAdded.thumbs.push(res);
								}
							});
							subjectToUpdate.pj[fileIndex] = fileAdded;
							this.workspaceData.globalEmitter2.emit(subjectToUpdate.id);
						}
						else
						{
							subjectToUpdate.pj[fileIndex] = fileAdded;
							this.workspaceData.globalEmitter2.emit(subjectToUpdate.id);
						}
					}
					else
					{
						// Si le fichier n'existap as déja on l'ajoute
						subjectToUpdate.pj.push(fileAdded);
						this.workspaceData.globalEmitter2.emit(subjectToUpdate.id);
					}

					// Si le fichier contient des fichiers attachés, on les mets à jour
					if (datas.args.resultFinal.attachedFiles)
					{
						for (const attachedFile of datas.args.resultFinal.attachedFiles)
						{
							const fileIndexAttached = _.findIndex(subjectToUpdate.pj, (pj: any) =>
							{
								return pj.id === attachedFile.uuid;
							});

							if (fileIndexAttached !== -1 && subjectToUpdate.pj[fileIndexAttached])
							{
								const reference = '*referencedBy::' + fileAdded.id;
								if (subjectToUpdate.pj[fileIndexAttached].tags)
								{
									const indexReference = subjectToUpdate.pj[fileIndexAttached].tags.indexOf(reference);

									if (indexReference === -1)
									{
										subjectToUpdate.pj[fileIndexAttached].tags.push(reference);
									}
								}
							}
						}
					}
					// Si des fichiers attachés ont été supprimés alors on supprime les références
					if (datas.args.resultFinal.removedAttachedFiles)
					{
						for (const attachedFileToRemove of datas.args.resultFinal.removedAttachedFiles)
						{
							const fileIndexToRemove = _.findIndex(subjectToUpdate.pj, (pj: any) =>
							{
								return pj.id === attachedFileToRemove.uuid;
							});

							if (fileIndexToRemove !== -1 && subjectToUpdate.pj[fileIndexToRemove])
							{
								const reference = '*referencedBy::' + fileAdded.id;

								subjectToUpdate.pj[fileIndexToRemove].tags = subjectToUpdate.pj[fileIndexToRemove].tags.filter((tag: any) => tag !== reference);
							}


						}
					}
					this.fileData.synchronize();
				}
			}
		}

		if (datas.cmd && datas.cmd === 'filePreview')
		{
			if (datas.args
				&& datas.args.id)
			{
				this.fileService.downloadContentFileFromId(datas.args.id).subscribe((res) =>
				{
					this.workspaceData.globalEmitter2.emit(['previewGenerated', res]);
					this.fileService.deleteFromId(datas.args.id).subscribe((resDelete) =>
					{
					});
				});
			}
			else
			{
				this.workspaceData.globalEmitter2.emit(['previewGenerated', null]);
			}
		}

		if (datas.cmd && datas.cmd === 'fileConvert')
		{
			if (datas.args && datas.args.resultFinal && datas.args.resultFinal.id && datas.args.resultFinal.oldName && datas.args.resultFinal.file)
			{
				const fileAdded = FileSubject.fromJsonObject(datas.args.resultFinal.file);

				if (datas.args.resultFinal.encodedRsaKey)
				{
					fileAdded.encryptedAesKeys.push(datas.args.resultFinal.encodedRsaKey);
				}

				this.workspaceData.selectedSujet.pj.push(fileAdded);
				this.workspaceData.globalEmitter2.emit(this.workspaceData.selectedSujet.id);

				this.userData.notifEmitter.emit(['success', 'Conversion du fichier ' + datas.args.resultFinal.oldName + ' vers ' + fileAdded.name + ' réussie.']);
			}
		}

	}

	/**
	 *
	 */
	public updateToken(token: any)
	{
		if (token)
		{

			this.userData.connected		= true;
			let splitToken: string[]	= token.split('.');

			let base64js = JSON.parse(window.atob(splitToken[1]));
			this.userData.login(base64js.idSN, token, base64js.idUser);

			this.userData.mail		= base64js.idUser;
			this.userData.accounts	= base64js.accounts;
			this.userData.account	= base64js.acc;

			let mustReset = false;
			if (base64js.reset !== undefined)
			{
				mustReset = base64js.reset === true || base64js.reset === 'true';
			}
			this.userData.mustReset = mustReset;
			if (base64js.lm && base64js.lmd !== undefined)
			{
				this.userData.methodAuth	= Number(base64js.lm);
				this.userData.otpAuth		= !base64js.lmd;
			}
			else
			{
				this.userData.otpAuth = false;
			}

			this.userData.setFingerPrint(base64js.fp);
			this.userData.setTokenKey(token);
		}
	}


	/**
	 * Une fois authentifié on va récupérer les infos pour construire l'appli
	 */
	public onAuth(datas: any)
	{
		if (datas)
		{
			let splitToken: string[]	= datas.split('.');
			this.userData.connected		= true;
			this.canAccessChat			= true;

			if (splitToken.length > 2)
			{
				this.updateToken(datas);

				if (this.isConnected === false)
				{
					this.isConnected = true;

					this._checkSelectAccount().then(res =>
					{
						if (res === true)
						{
							this.dialogRefSelectAccount = undefined;

							// Si l'utilisateur doit réinitialiser son mot de passe une modale s'affiche
							// afin qu'il en selectionne un nouveau, sinon il se connecte normalement
							this._checkResetPassword().then(res =>
							{
								this.userService.checkFingerPrintUser();

							}).catch(err =>
							{
								this.userData.logout();
								this.socketService.logout();
								this.socketVaultService.logout();
								this.taskData.initTasks();
								this.router.navigate(['/login']);
								this.isConnected = false; 
							});
						}

					}).catch(err =>
					{

						this.dialogRefSelectAccount = undefined;

						this.userData.logout();
						this.socketService.logout();
						this.socketVaultService.logout();
						this.taskData.initTasks();
						this.router.navigate(['/login']);
						this.isConnected = false;
					});
				}
			}
		}
	}


	/**
	 * Vérification et initialisation coté serveur du compte selectionné
	 */
	private _checkSelectAccount(): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			if (this.userData.isAccountSelected === false && this.userData.accounts && this.userData.accounts.length > 1)
			{
				if (this.dialogRefSelectAccount === undefined)
				{
					this.dialogRefSelectAccount = this.dialog.open(SelectAccountModalComponent,
					{
						height: 	'30%',
						width: 		'40%',
						panelClass: 'responsive_modal',
						data:
						{
							accounts: this.userData.accounts
						},
						disableClose: true
					});

					// A la fermeture de la box
					this.dialogRefSelectAccount.afterClosed().subscribe((resultAccount: any) =>
					{
						this.dialogRefSelectAccount = undefined;

						if (resultAccount === false)
						{
							reject();
						}
						else
						{
							this.userData.isAccountSelected = true;
							resolve(true);
						}
					});
				}
				else
				{
					resolve(false);
				}
			}
			else
			{
				resolve(true);
			}
		});
	}


	/**
	 *
	 */
	private _checkResetPassword(): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			if (this.userData.mustReset === true)
			{
				let dialogRefPasswordReset = this.dialog.open(ResetPasswordModalComponent,
				{
					height: '50%',
					width: '60%',
					panelClass: 'responsive_modal',
					data:
					{
						type: 'resetForTemporary'
					},
					disableClose: true
				});

				// A la fermeture de la box
				dialogRefPasswordReset.afterClosed().subscribe((resultReset: any) =>
				{
					if (resultReset && resultReset === true)
					{
						this.userData.mustReset = false;
						resolve();
					}
					else
					{
						reject();
					}
				});
			}
			else
			{
				resolve();
			}
		});
	}


	/**
	 *
	 */
	private _otpAuthentification()
	{
		if (this.userData.otpAuth === true)
		{
			let dialogRefOtp = this.dialog.open(ConnectOTPModalComponent,
				{
					height:			'50%',
					panelClass:		'responsive_modal',
					width:			'60%',
					data:			{},
					disableClose:	true
				});

			dialogRefOtp.afterClosed().subscribe((result: any) =>
			{
				if (result && result === true)
				{
					this.userData.otpAuth = false;
					this._initializeClientDatas();
				}
				else
				{
					this.userData.logout();
					this.socketService.logout();
					this.socketVaultService.logout();
					this.taskData.initTasks();
					this.router.navigate(['/login']);
					this.isConnected = false;
				}
			});
		}
		else
		{
			this._initializeClientDatas();
		}
	}


	/**
	 *
	 */
	private _initializeClientDatas()
	{
		let sUserId				= this.userData.getUserId();
		this.currentName		= this.userData.firstName + ' ' + this.userData.lastName;

		// Récupération des lettres pour l'icone perso
		this.firstLetterIcone	= '';
		this.firstLetterIcone	+= this.userData.firstName.charAt(0);
		this.firstLetterIcone	+= this.userData.lastName.charAt(0);

		this.currentLogin		= this.userData.mail;

		// ---------------------------------------
		// Récupération de la liste des rooms dispo
		// ---------------------------------------
		let eSocketWhat: SocketWhat.request;


		// Demande de la liste des utilisateurs, un écouteur est placé,
		// lorsque la réponse arrive la modal s'ouvre automatiquement
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': sUserId,
						'what': eSocketWhat,
						'cmd': 'user',
						'args':
							{
								bdata:	true,
								bstate: true,
								token:	this.userData.getTokenKey()
							}
					};
				socket.emit('read', data);
			}
		});


		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				let data: ISocketData =
					{
						'iam':	'c-c',
						'name': sUserId,
						'what': eSocketWhat,
						'cmd':	'rooms',
						'args': { mail: '' }
					};
				socket.emit('read', data);
			}
		});


		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': sUserId,
						'what': SocketWhat.info,
						'cmd':	'ressources',
						'args': {}
					};
				socket.emit('read', data);
			}
		});

		this.taskService.getTasksAvailable();

		// ---------------------------------------
		// Récupération des notification
		// ---------------------------------------
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': sUserId,
						'what': eSocketWhat,
						'cmd':	'notification',
						'args': { 'days': '3' }
					};

				socket.emit('read', data);
			}
		});


		// ---------------------------------------
		// Récupération des calendar
		// ---------------------------------------
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': sUserId,
						'what': eSocketWhat,
						'cmd':	'calendar',
						'args': {}
					};
				socket.emit('read', data);
			}
		});


		// ---------------------------------------
		// Param (principalement ici c'est pr le workflow
		// ---------------------------------------
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd':	'adminParameters',
						'args': {}
					};
				socket.emit('read', data);
			}
		});

		let isConnected = this._checkConnection();
		if (isConnected === true && this.inLoginPanel)
		{
			this.isConnected = true;
			this.router.navigate(['/home']);
		}


		// ---------------------------------------
		// récupération des barèmes
		// ---------------------------------------
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd':	'allBaremes',
						'args': {}
					};
				socket.emit('read', data);
			}
		});
	}


	public authStateChange(test: any)
	{
	}


	/**
	 * L'application est déconnectée, on affiche la pop-up de reconnection
	 * Si la reconnection fonctionne, alors on fait un refresh sur la page courante
	 */
	public onErrorServer()
	{
		if (this.userData.connected)
		{
			this.userData.connected = false;
			this.isConnected		= false;

			setTimeout(() =>
			{
				if (this.userData.connected === false)
				{
					if (this.router.url !== '/' && this.router.url !== '/login')
					{
						this.openModalConnectApplication((result) =>
						{
							this.userData.dialogRefModalConnection = null;

							if (result === true)
							{
								this.router.navigate([this.router.url]);
							}
							else
							{
								this.router.navigate(['/login']);
							}
						});
					}
				}

			}, 40000);
		}
	}


	/**
	 * L'application est déconnectée, on affiche la pop-up de reconnection
	 * Si la reconnection fonctionne, alors on fait un refresh sur la page courante
	 */
	public onDisconnect()
	{
		if (this.userData.connected)
		{
			this.socketService.getSocketConnection().subscribe((socket: any) =>
			{
				if (!socket || !socket.state || socket.state !== SCClientSocket.OPEN)
				{
					this.userData.connected = false;
					this.userData.logout();
					this.workspaceData.logout();
					this.vaultData.logout();
					this.taskData.initTasks();
					this.isConnected = false;
					AuthService.stopGestionRooms();
					this.socketService.offMachine();
					this.socketService.init();


					if (this.router.url !== '/' && this.router.url !== '/login')
					{
						this.openModalConnectApplication((result) =>
						{
							this.userData.dialogRefModalConnection = null;
							if (result === true)
							{
								this.router.navigate([this.router.url]);
							}
							else
							{
								this.router.navigate(['/login']);
							}
						});
					}
				}
			});
		}
	}


	public setUsersByRoomId()
	{
	}


	/**
	 * Appelé a la reception d'un new message alors que l'user est connecté
	 */
	public newMessageReceived(id: any, data: any)
	{
		if (data.cmd === 'msg.ack')
		{
			let keyRoom		= data.args.key;
			let userIdRead	= data.args.userName;
			let ticks		= data.args.ackTo;


			if (userIdRead === this.userData.getUserId())
			{
				this.chatData.setLastMessageReceivedByMeByRoom(keyRoom, ticks)
			}
			else
			{
				this.chatData.setLastMessageReceivedByHimByRoom(keyRoom, ticks)
			}
		}


		// ---------------------------------------
		// Mettre en place le readed
		// ---------------------------------------
		if (data.cmd === 'msg')
		{
			let keyRoom = id.split('::');
			this.userData.globalEmitter.emit(['chat::newMessage', data]);

			if (data.name && data.name !== this.userData.getUserId())
			{
				let arrayKey		= [];
				let arrayMessage	= _.toArray(data.args.messages);

				for (const key in data.args.messages)
				{
					if (data.args.messages[key])
					{
						arrayKey.push(key);
					}
				}


				// Boucle seulement sur les messages reçu
				for (let item of arrayMessage)
				{
					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						if (socket)
						{
							let data: ISocketData =
								{
									'iam': 'c-c',
									'name': this.userData.getUserId(),
									'what': SocketWhat.info,
									'cmd': 'msg.ack',
									'args':
										{
											bucket:		'rooms',
											notifier:	[id],
											key:		keyRoom[1],
											sub:		'M',
											when:		item.id
										}
								};

							socket.emit('update', data);
						}
					});
				}

				const user = this.userData.getUser(data.name);

				let nomPrenom = '';

				if (user && user.infos && user.infos.nom && user.infos.prenom)
				{
					nomPrenom = ' de ' + user.infos.prenom + ' ' + user.infos.nom;
				}

				this.showNotification('Nouveau Message', nomPrenom);
				this.chatData.incrementCounterByRoom(keyRoom[1]);

			}
		}

		if (data.cmd === 'msg.read')
		{

			let keyRoom		= data.args.key;
			let userIdRead	= data.args.userName;
			let ticks		= data.args.rTo;


			if (userIdRead === this.userData.getUserId())
			{
				this.chatData.setCounterByRoom(keyRoom, 0);
				this.chatData.setLastMessageReadedByMeByRoom(keyRoom, ticks)
			}
			else
			{
				this.chatData.setLastMessageReadedByHimByRoom(keyRoom, ticks)
			}
		}

		if (data.cmd === 'room.delete')
		{
			const elementsRoom = id.split('::');
			if (elementsRoom.length === 3 && elementsRoom[2] === 'priv')
			{
				this.chatData.deleteRoom(elementsRoom[1]);
			}
		}
	}



	/**
	 * Récupération des datas
	 */
	public dataReceivedRead(data: any)
	{
		// les noeuds (info de base)
		if (data.cmd === 'node.info' && !data.err)
		{
			this._parseNodeInfo(data);
		}


		// Date du calendrier pour la home
		if (data.cmd === 'calendar' && data.args && data.args.data && data.args.data.dates)
		{
			for (let date of data.args.data.dates)
			{
				this.userData.addCalendarElement(date.id, date);
				this.userData.globalEmitter.emit('calendar:newDate');
			}
		}

		// stats de la home  => refacto revoir car 2x..
		if (data.cmd === 'epreuve.stats' && data.args && data.args.data)
		{
			this.workspaceData.statsNode = data.args.data.statEpr;
			this.workspaceData.globalEmitter.emit('majStatsEpr');
		}

		if (data.cmd === 'epreuve.stats')
		{
			this._parseEpreuveStats(data);
		}

		// stats workspace
		if (data.cmd === 'statEpr' && data.args && data.args.data)
		{
			this.workspaceData.statsNodeEpr = data.args.data.stats;
			this.workspaceData.globalEmitter.emit('majStatsEpr');
		}

		if (data.cmd === 'statSuj' && data.args && data.args.data)
		{
			if (this.workspaceData.selectedTask && this.workspaceData.selectedTask.idSujet === data.args.data.idSuj)
				this.workspaceData.statsNode2 = data.args.data.stats;

			this.workspaceData.globalEmitter.emit('majStatsEpr');
		}


		// Listing sujet
		if (data.cmd === 'subjects' && data.args && data.args.data && data.args.data.result && data.args.data.result.subjects)
		{
			this._parseSubject(data);
		}

		// File des sujets
		if (data.cmd === 'files' && data.args && data.args.data && data.args.data.result && data.args.data.result.files)
		{
			this._parseFile(data);
		}

		if (data.cmd === 'mail2name')
		{
			this.dataReceivedUsers(data);
		}

		// ---------------------------------------
		// Gestion des tache de la home
		// ---------------------------------------
		if (data.cmd === 'tasks.stats')
		{
			this._parseTasksStats(data);
		}

		if (data.cmd === 'tasks.assigned')
		{
			this._parseTasksAssigned(data);
		}

		if (data.cmd === 'tasks.bynode')
		{
			this.setTasksBySubject(data);
		}

		if (data.cmd === 'history.readed' && !data.args.err)
		{
			this._parseHistoryReaded(data);
		}

		if (data.cmd === 'history.count' && !data.args.err)
		{
			this._parseHistoryCount(data);
		}

		if (data.cmd === 'roles')
		{
			if (data.args.data.roles.length > 0)
			{
				this.handleRolesReceived(data.args.data.roles);

			} else { this.userData.status = UserData.STATUS_USER; }
		}


		// ---------------------------------------
		// Lorsqu'on récupère les ressources on initialise les rooms
		// pour écouter les évènements relatifs aux ressources dont
		// on a accès
		// ---------------------------------------
		if (data.cmd === 'ressources')
		{
			this._parseRessources(data);
		}

		if (data.cmd === 'rooms' && !Tools.isEmpty(data) && !data.err)
		{
			this.setRooms(data);
			this.userData.globalEmitter.emit('subject::get');
		}

		if (data.cmd === 'room' && !Tools.isEmpty(data) && !data.err)
		{
			this.setRoom(data);
		}

		if (data.cmd === 'msg.count')
		{
			if (!data.args.err)
			{
				this.chatData.setCounterByRoom(data.args.data.key, parseInt(data.args.data.nbmessages));
			}
		}

		// ---------------------------------------
		// Récupération des infos sur les rooms
		// ---------------------------------------
		if (data.cmd === 'msg.readed')
		{
			this._parseMessagesReaded(data);
		}

		if (data.cmd === 'user')
		{
			this.setUsers(data);
		}

		if (data.idc === 'getUserChatInit')
		{
			this.userData.globalEmitter.emit(['chat::getUsers', '']);
		}

		if (data.cmd === 'msg')
		{
			this._parseMessages(data);
		}

		if (data.cmd === 'messages')
		{
			if (data.args.data && data.args.data.messages && Object.keys(data.args.data.messages).length > 0)
			{
				let messagesRead = this.workspaceData.parseHistoric(data.args.data);
				this.workspaceData.setHistoric(data.args.data.key, messagesRead);
				this.workspaceData.globalEmitter.emit(['messages::get', data.args.data.key]);
			}
		}

		if (data.cmd === 'messages.readed')
		{
		}

		if (data.cmd === 'encryptDatasForValidation')
		{
			if (data.args && data.args.data && data.args.data.datasEncoded && data.args.data.datasToValid)
			{

				this.userData.globalEmitter.emit(['RSA:validate', data.args.data.datasEncoded, data.args.data.datasToValid]);
			}
		}

		if (data.cmd === 'adminParameters')
		{
			this._parseAdminParameters(data);
		}

		if (data.cmd === 'epreuve.stats')
		{
		}

		if (data.cmd === 'passations')
		{
			if (data.args && data.args.data && data.args.data.passations && data.args.data.passations.length > 0)
			{
				let passations = data.args.data.passations;

				for (let passation of passations)
				{
					this.vaultData.addConcours(passation);
				}
				this.vaultData.vaultEmitter.emit('passations::get');
			}
		}

		if (data.cmd === 'candidatsPassations')
		{
			if (data.args && data.args.data && data.args.data.idPassation && data.args.data.candidats)
			{
				const currentPassation: any = this.vaultData.getConcourByid(data.args.data.idPassation);

				try{
					const candidats = JSON.parse(data.args.data.candidats);
					currentPassation.datas.candidats = candidats;
					this.vaultData.addConcours(currentPassation);
					this.vaultData.vaultEmitter.emit(['candidats::get', currentPassation.id]);
				}catch(e){}
			}
		}


		if (data.cmd === 'candidatByParent')
		{
			if (data.args && data.args.data && data.args.data.candidats)
			{
				for(let candidat of data.args.data.candidats)
				{
					if (candidat.idparents && candidat.idparents.length > 0)
					{
						for(let idparent of candidat.idparents)
						{
//							this.vaultData.addCandidatByElement(idparent, candidat);
						}
					}
				}

				this.vaultData.vaultEmitter.emit('candidats::read');
			}
		}

		if (data.cmd === 'importResultsPassations2')
		{
			if (data.args && data.args.data && data.args.data.resultatsCandidats && data.args.data.idPassation)
			{
				this.vaultData.vaultEmitter.emit(['results::read', data.args.data]);
			}
			else
			{
				if (data.args && data.args.err === true && data.args.mId)
				{
					const messageParts = data.args.mId.split('::');
					if (messageParts[0] === 'error_import_result_passation')
					{
						this.vaultData.vaultEmitter.emit(['results::error', messageParts[1]]);
					}
				}
			}
		}

		if (data.cmd === 'importResultsPassations')
		{
			if (data.args && data.args.data && data.args.data.result)
			{
//				this.showNotification('Nouveau jeu de résultat', '');
				this.vaultData.vaultEmitter.emit(['results::read', data.args.data]);
			}else
			{
				if (data.args && data.args.err === true && data.args.mId)
				{
					const messageParts = data.args.mId.split('::');
					if (messageParts[0] === 'error_import_result_passation')
					{
						this.vaultData.vaultEmitter.emit(['results::error', messageParts[1]]);
					}
				}
			}
		}


		if (data.cmd === 'allBaremes')
		{
			if (data.args && data.args.data && data.args.data.baremes)
			{
//				this.showNotification('Nouveau jeu de résultat', '');
//				this.vaultData.vaultEmitter.emit(['results::read', data.args.data.result]);
				this.baremeData.addBaremes(data.args.data.baremes);
			}
		}

		if (data.cmd === 'getAesKeysFromUser')
		{
			if (data.args && data.args.data && data.args.data.result)
			{
				this.userData.globalEmitter.emit(['getAesKeysFromUser', data.args.data.result]);
			}
		}

		if (data.cmd === 'candidatsEmargement')
		{
			if (data.args
				&& data.args.err === false
				&& data.args.data
				&& data.args.data.candidats
				&& data.args.data.passationId
				&& data.args.data.candidats.data
				&& data.args.data.candidats.data.candidats)
			{
				const epreuveId = data.args.data.passationId;
				const candidats = data.args.data.candidats.data.candidats;

				for (const candidat of candidats)
				{
					if (candidat && candidat.signature && candidat.signature !== '')
					{
						try{
							candidat.signature = JSON.parse(candidat.signature);
						}catch(e)
						{}
					}
				}

				this.workspaceData.globalEmitter.emit(['emargements::' + epreuveId, candidats]);
			}


		}
	}


	/**
	 * a la récupération des taches, on va les affecter dans les noeuds correspondants
	 */
	private _addTasksToEpreuves(tasks: any)
	{
		for (let task of tasks)
		{
			if (task.idepreuve)
			{
				let epreuve: any = this.workspaceData.getEpreuve(task.idepreuve);

				if (epreuve)
				{
					//On vérifie si la tache est présente dans l'épreuve
					//Si ce n'est pas le cas on l'insère dans l'épreuve et on demande le sujet concerné
					let objTask = Task.fromJsonObject(task);
					objTask		= this.taskData.buildTask(objTask);


					let taskFinded = _.find(epreuve.tasks, (task: any) => { return task.id === objTask.id; });

					if (taskFinded === undefined)
					{
						epreuve.tasks.push(objTask);
						this.sujetService.getSujets(epreuve.id, objTask.idSujet);
					}

					//On récupère les messages lus par l'utilisateur afin de savoir si
					//la tache contient des messages non lus
					this.messagesService.getReadedMessages(objTask.id, 'task');
				}
			}
		}
	}



	/**
	 * Enregistrement des infos de base
	 */
	private _parseNodeInfo(data: any)
	{
		let sUserId = this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;

		if (data.args && data.args.data && !data.err && data.args.data.nodes[0])
		{
			// RAZ des etabs, pour éviter de retrouver les anciennes
			this.workspaceData.etablissements	= [];
			let datasStruct						= data.args.data.nodes[0];


			// ---------------------------------------
			// Parcours des data reçu par le socket pour les affecter dans workspaceData
			// Appels socket pour récupérer les commentaires et historique pour chaque palier
			// ---------------------------------------
			if (datasStruct.structure)
			{
				for (let etabTemporaire of datasStruct.structure)
				{
					if (etabTemporaire && etabTemporaire.type === 'etablissement' && etabTemporaire.type)
					{
						let etabObject 	= Etablissement.fromJsonObject(etabTemporaire);
						etabObject.show = true;

						this.workspaceData.addEtablissement(etabObject.id, etabObject);
						// this.sujetService.getSujets(this.workspaceData.selectedMatiere.id);

						//On récupère les sujets d'une épreuve si on est administrateur ou un superutilisateur
						if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
						{
							for (let session of etabObject.sessions)
							{
								for (let examen of session.examens)
								{
									for (let epreuve of examen.matieres)
									{
										// pour chaque epreuve on va apeller les sujets..
										this.sujetService.getSujets(epreuve.id);
									}
								}
							}
						}
					}
				}
			}

			if (this.workspaceData.etablissements.length === 0)
			{
				this.savedData.lastGroup = '';
			}

			// ---------------------------------------
			// Récupération des tasks
			// ---------------------------------------
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					this.socket = socket;

					if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'tasks.stats',
								'args': {}
							};

						socket.emit('read', data);
					}
					else
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'tasks.assigned',
								'args': {}
							};
						socket.emit('read', data);
					}
				}
			});

			// ---------------------------------------
			// call des stats des épreuves
			// ---------------------------------------
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'epreuve.stats',
							'args':
							{
								parentNodes: null,
								limit: 160
							}
						};

					socket.emit('read', data);
				}
			});
			this.workspaceData.isInfoNodeOk = true;
		}
	}



	/**
	 * Récupère la liste des rooms pour le module de chat global
	 */
	public getGlobalRooms()
	{

	}


	/**
	 * Ajout les rooms dans le trableau des rooms à écouter pour recevoir les messages
	 */
	public addRoomToChanel(room: any)
	{
		// Construction de la cdc pour avoir les retours de messages pour chaques rooms
		let roomState = '::priv';
		let roomReadOnly = '';

		if (room.isPublic)
		{
			roomState = '::public';
		}
		if (room.isReadOnly)
		{
			roomReadOnly = '::' + room.owner;
		}

		let roomId		= this.socketService.findRoomAGerer(name);
		let roomName	= '*room::' + room.uuid + roomState + roomReadOnly;

		// room non trouvé, donc juste à push
		if (roomId === -1)
		{
			this.socketService.roomsAGerer.push({ name: roomName, state: 'nosub', watch: true, fct: this.newMessageReceived.bind(this, roomName), channel: undefined });
		}
	}


	/**
	 * Sauvegarde locale des rooms pour la construction du chat
	 */
	public setRooms(data: any)
	{
		this.userData.rooms = [];
		this.chatData.rooms = [];

		if (data && data.args && data.args.data && data.args.data.rooms && !data.err)
		{

			// ---------------------------------------
			// Pour chaque room on va demander les infos sur les messages et les compteurs
			// ---------------------------------------
			for (let room of data.args.data.rooms)
			{
				// Message.readead, pour savoir si on a des nouveaus messages et les flaguer comme lu
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': this.userData.getUserId(),
								'what': SocketWhat.info,
								'cmd': 'msg.readed',
								'args':
									{
										bucket:		'rooms',
										key:		room.uuid
									}
							};
						socket.emit('read', data);
					}
				});


				// ---------------------------------------
				// Sauvegarde des infos dans le privoder
				// ---------------------------------------
				this.addRoomToChanel(room);
				this.chatData.setRoom(room.uuid, Piece.fromJsonObject(room));
			}

			this.chatData.isRoomOk = true;
			this.homeInfosEmitter.emit('homeRoomsOk');
		}
	}


	/**
	 * Sauvegarde locale des rooms pour la construction du chat
	 */
	public setRoom(data: any)
	{
		if (data && data.args && data.args.data && data.args.data.rooms && !data.err)
		{
			for (let room of data.args.data.rooms)
			{
				// Place l'écouteur sur la room pour l'arriver de new message
				this.addRoomToChanel(room);

				// On enregistre la room
				this.chatData.setRoom(room.uuid, Piece.fromJsonObject(room));
				if (room.owner != this.userData.getUserId())
				{
					this.chatData.setUserByRoom2(room.uuid, room.owner);
				}

				// Message.readead, pour savoir si on a des nouveaus messages et les flaguer comme lu
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': this.userData.getUserId(),
								'what': SocketWhat.info,
								'cmd': 'msg.readed',
								'args':
									{
										bucket: 'rooms',
										key: room.uuid
									}
							};
						socket.emit('read', data);
					}
				});

			}
			this.chatData.isRoomOk = true;
		}
	}


	/**
	 * Sauvegarde locale des rooms pour la construction du chat, spécifique à lla création de chat..
	 */
	public setRoomForCreate(room: any)
	{
		// Place l'écouteur sur la room pour l'arriver de new message
		this.addRoomToChanel(room);

		// On enregistre la room
		let oRoom = Piece.fromJsonObject(room);
		this.chatData.setRoom(room.uuid, oRoom);


		this.chatData.setSelectedRoomBy(room.uuid);


		// Message.readead, pour savoir si on a des nouveaus messages et les flaguer comme lu
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'msg.readed',
						'args':
						{
							bucket: 'rooms',
							key: room.uuid
						}
					};
				socket.emit('read', data);
			}
		});

		this.chatData.isRoomOk = true;
	}


	/**
	 * affectation des taches directement dans les sujets
	 */
	public setTasksBySubject(data: any)
	{
		if (data && !data.err && data.args && data.args.data && data.args.data.tasks)
		{
			this.workspaceData.setTasksBySubject(data.args.data.tasks);
			this.taskData.addTasks(data.args.data.tasks);
		}
	}

	/**
	 * Enregistre les infos des user et init les classes
	 */
	public setUsers(data: any)
	{
		let arrayUser = data.args.data.users;

		if (arrayUser)
		{
			for (let user of arrayUser)
			{

				// super ... 2 fonctions pas le meme nom, pas le meme ordre des params, gg à moi ... =>virer celui qui ne sert à rien refacto
				this.userData.addUser(user, user.mail);
				this.chatData.setUser(user.mail, user);

				this.userData.userForChatInvite = user;
				let me = this.userData.getUserId();


				if (me === user.mail)
				{
					if (user.infos.nom		!== '')	{ this.userData.firstName	= user.infos.nom; }
					if (user.infos.prenom	!== '')	{ this.userData.lastName	= user.infos.prenom; }
					if (user.infos.color	!== '')	{ this.userData.color		= user.infos.color; }

					// gestion de l'icone user avec la 1er lettre du prénom ou du mail
					if (user.infos.prenom !== '')
					{
						this.userData.firstLetterIcone = user.infos.nom.charAt(0);
						this.userData.firstLetterIcone += user.infos.prenom.charAt(0);
					}
					else
					{
						let userId						= this.userData.getUserId();

						this.userData.firstLetterIcone	= '';
						this.userData.firstLetterIcone	+= userId.charAt(0);
						this.userData.firstLetterIcone	+= userId.charAt(1);
					}
				}

				// ---------------------------------------
				// Ajout de l'user dans la room
				// ---------------------------------------
				if (data.idc)
				{
					// Pour ouvrir le chat, on affecte les users, la room selectionné et on bascule
					this.chatData.setUserByRoom2(data.idc, user.mail);
					this.chatData.setSelectedRoomBy(data.idc);
					this.userData.globalEmitter.emit(['chat::openRoomAfterData', '']);

				}
			}
		}

		let idRoom = this.userData.idNewRoom;
		this.userData.globalEmitter.emit(['majRooms', idRoom]);
	}


	/**
	 *
	 */
	private _parseFile(data: any)
	{
		// ---------------------------------------
		// RAJ des fichiers
		// ---------------------------------------
		let subjectId;
		let promises: Promise<any>[] = [];

		for (let fileReturned of data.args.data.result.files)
		{
			promises.push(this._addFileToWorkspace(fileReturned, data.args.data.result.encodedRsaKeys));
		}

		Promise.all(promises).then(results =>
		{
			if (results && results.length > 0)
			{
				this.userData.globalEmitter.emit('files::get');
			}
			this.workspaceData.globalEmitter2.emit(data.args.data.result.idSubject);
		});
	}



	/**
	 * Ajoute un fichier récupéré en base de données, téléchargement et décodage éventuel d'un thumbnail
	 */
	private _addFileToWorkspace(fileJson: any, encodedRsaKeys: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
        {
			if (fileJson && fileJson.id)
			{
				let fileObj = FileSubject.fromJsonObject(fileJson);

				// Si le fichier possède le tag 'corbeille', on le définit comme étant supprimé
				if (_.indexOf(fileObj.tags, 'corbeille') !== -1)
				{
					fileObj.deleted = true;
				}

				if (encodedRsaKeys && encodedRsaKeys[fileObj.id])
				{
					fileObj.encryptedAesKeys = encodedRsaKeys[fileObj.id];
				}

				//On effectue l'appel socket pour récupérer les historique et les commentaires du fichier
				this.messagesService.getMessages(fileObj.id, 'files');

				if (fileObj.pathThumbs.length > 0 && fileObj.pathThumbs.length !== fileObj.thumbs.length)
				{
					this.fileService.downloadThumbnail(fileObj).subscribe((res) =>
					{
						if (res && res.url)
						{
							fileObj.thumbs.push(res);
						}
						this.workspaceData.addFile(fileObj.idSubject, fileObj);
						resolve(fileObj.idSubject);
					});
				}
				else
				{
					this.workspaceData.addFile(fileObj.idSubject, fileObj);
					resolve(fileObj.idSubject);
				}
			}
		});
	}


	/**
	 * Les stats sur les taches
	 */
	private _parseTasksStats(data: any)
	{
		if (data.args.mess === 'ok')
		{
			let tasks:			any[] = [];
			let tasksExpired:	any[] = [];
			let tasksToClose:	any[] = [];

			if (data.args.data)
			{
				// affectation des taches arrivant à expiration
				if (data.args.data.expired)
				{
					tasks			= tasks.concat(data.args.data.expired);
					tasksExpired	= tasks.concat(data.args.data.expired);
				}

				// les taches à valider (taff ok mais non validé)
				if (data.args.data.toclose)
				{
					tasks			= tasks.concat(data.args.data.toclose);
					tasksToClose	= tasks.concat(data.args.data.toclose);
				}
			}

			// Les taches pour la home / expiration
			for (let task of tasksExpired)
			{
				let oTask	= Task.fromJsonObject(task);
				oTask		= this.taskData.buildTask(oTask);

				this.workspaceData.setTaskHomeAdminExpired(oTask.id, oTask);
			}

			// Les taches pour la home / à fermer
			for (let task of tasksToClose)
			{
				let oTask	= Task.fromJsonObject(task);
				oTask		= this.taskData.buildTask(oTask);


				if (oTask.state === 'review')
				{
					this.workspaceData.setTaskHomeAdminToValid(oTask.id, oTask);
				}

				this.workspaceData.setTaskHomeAdmin(oTask.id, oTask);
			}


			this.workspaceData.isInfoTaskAdminOk = true;
			// Au cas ou on les assigne, pas obligatoire ..
			this._addTasksToEpreuves(tasks);
			this.homeInfosEmitter.emit('homeTasksOk');
		}
	}



	public _updateTasksFromDatas(taskData: any)
	{
		this.workspaceData.setTasksBySubject([taskData], true);

		let oTask	= Task.fromJsonObject(taskData);
		oTask		= this.taskData.buildTask(oTask);


		if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
		{
			this.workspaceData.setTaskHomeAdmin(oTask.id, oTask);
		}

		for (let etablissement of this.workspaceData.etablissements)
		{
			for (let session of etablissement.sessions)
			{
				for (let examen of session.examens)
				{
					for (let epreuve of examen.matieres)
					{
						for (let task of epreuve.tasks)
						{
							if (task.id === oTask.id)
							{
								task = oTask;
							}
						}
					}
				}
			}
		}


		if (oTask.endDate && oTask.endDate > new Date())
		{
			// Pas expiré
			let aArrayKey2 = [];
			let key3: any;
			for (key3 in this.workspaceData.taskHomeAdminExpired)
			{
				aArrayKey2.push(key3);
			}

			let nIndice2 =	0;
			let key4:		any;

			for (key4 of aArrayKey2)
			{
				if (this.workspaceData.taskHomeAdminExpired[key4] && this.workspaceData.taskHomeAdminExpired[key4].id === oTask.id)
				{
					this.workspaceData.removeTaskExpired(nIndice2);
				}

				nIndice2++;
			}
		}
		else
		{
			// expiré
			this.workspaceData.setTaskHomeExpired(oTask.id, oTask);
		}

		if (oTask.assigneduser === this.userData.getUserId())
		{
			this.workspaceData.setTaskHomeMine(oTask.id, oTask);
		}
		else
		{
			let aArrayKey2 = [];
			let key3: any;

			for (key3 in this.workspaceData.taskHomeMine)
			{
				aArrayKey2.push(key3);
			}
			let nIndice2 = 0;
			let key4:		any;

			for (key4 of aArrayKey2)
			{
				if (this.workspaceData.taskHomeMine[key4] && this.workspaceData.taskHomeMine[key4].id === oTask.id)
				{
					this.workspaceData.removeTaskToMine(nIndice2);
				}

				nIndice2++;
			}
		}

		// Regarder si la tache n'est pas déjà dans la variable, à valider
		let aArrayKey = [];

		for (let key in this.workspaceData.taskHomeAdminToValid)
		{
			aArrayKey.push(key);
		}
		let nIndice = 0;

		for (let key2 of aArrayKey)
		{
			if (this.workspaceData.taskHomeAdminToValid[key2] && this.workspaceData.taskHomeAdminToValid[key2].id === oTask.id)
			{

				this.workspaceData.removeTaskToValid(nIndice);
			}

			nIndice++;
		}

		this.taskData.setGlobalTask(oTask.id, oTask);
		// Ajout après gros bug taks
		let sUserId = this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;
	/*
		if (!this.workspaceData.statsNode2)
		{*/
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					this.socket = socket;

					if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'tasks.stats',
								'args': {}
							};

						socket.emit('read', data);
					}
					else
					{
						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'tasks.assigned',
								'args': {}
							};
						socket.emit('read', data);
					}
				}
			});
			/*
		}
*/
		if (this.workspaceData.selectedTask && this.workspaceData.selectedTask.id === oTask.id)
		{

			if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true || (oTask.assigneduser === this.userData.mail && oTask.state !== 'done'))
			{
				this.workspaceData.selectedTask = oTask;
			}
			else
			{
				this.workspaceData.selectedTask = undefined;
				this.taskData.taskDataEmitter.emit(['task::done', oTask.id]);
			}
		}
		this.taskData.taskDataEmitter.emit('task::update');
	}


	public socketToArray(data: any)
	{
		let keyArray	= [];
		let array		= [];
		let arrayReturn = [];

		for (let key in data)
		{
			keyArray.push(key);
		}
		array = _.toArray(data);

		let indice = 0;
		for (let item of array)
		{
			item.id = keyArray[indice];
			arrayReturn.push(item)
			indice++;
		}

		return arrayReturn;
	}


	/**
	 *
	 */
	private _parseEpreuveStats(data: any)
	{
		if (data.args && data.args.mess === 'ok' && !data.args.err && data.args.data.statEpr)
		{
			let epreuves = this.socketToArray(data.args.data.statEpr)

			for (let epreuve of epreuves)
			{
				this.workspaceData.setEpreuveHomeAdmin(epreuve.id, epreuve);
			}

			this.homeInfosEmitter.emit('homeEpreuveOk');
			this.workspaceData.isHomeEpreuveOk = true;
		}

	}


	/**
	 * Construit les taches pr celles qui me sont affectés
	 */
	private _parseTasksAssigned(data: any)
	{
		if (data.args.mess === 'ok')
		{
			// On est dans le cas des tache qui me sont affecté
			for (let task of data.args.data.tasks)
			{
				let oTask = Task.fromJsonObject(task);
				// Récupération du nom d'épeuvre et du sujet pour affichage ds la vignette

				oTask = this.taskData.buildTask(oTask);
				this.workspaceData.setTaskHomeMine(oTask.id, oTask);
			}
			this.workspaceData.isInfoTaskMineOk = true;
			this._addTasksToEpreuves(data.args.data.tasks);
			this.workspaceData.setTasksBySubject(data.args.data.tasks);
			this.taskData.addTasks(data.args.data.tasks);

			// voir si il sert tjr
			this.homeInfosEmitter.emit('homeTasksOk');
			this.taskData.taskDataEmitter.emit('task::update');
		}
	}


	/**
	 *
	 */
	private _parseHistoryReaded(data: any)
	{
		if (data.args.data && data.args.data.history_readed && data.args.data.bucket && data.args.data.key)
		{
			let datesKeys = Object.keys(data.args.data.history_readed);

			if (datesKeys && datesKeys.length > 0)
			{
				let date = datesKeys[0];
				// pas de limit sur le count, fonctionne pas  - si on veut limiter demander directement les n derniers messages
				this.messagesService.getMessagesCount(data.args.data.key, data.args.data.bucket, date);

			}
		}
	}


	/**
	 *
	 */
	private _parseSubject(data: any)
	{
		this._addSubjectToUserDatas(data.args.data.result.subjects);

		//On effectue les appels pour récupérer les historiques et les commentaires des sujets
		for (let subjectJson of data.args.data.result.subjects)
		{
			if (subjectJson.idsujet)
			{
				this.messagesService.getMessages(subjectJson.idsujet, 'subjects');
			}

			// ---------------------------------------
			// Mise à jour des nom des taches
			// ---------------------------------------
			this.taskData.majSubjectName(subjectJson.idsujet);
		}

		this.userData.globalEmitter.emit('subject::get');
		this.workspaceData.isInfoSubjectOk = true;
	}


	/**
	 *
	 */
	private _parseHistoryCount(data: any)
	{
		if (data.args.data.bucket && data.args.data.key && data.args.data.nbmessages)
		{
			switch (data.args.data.bucket)
			{
				case 'task':
					if (data.args.data.nbmessages > 0)
					{
						let currentTask: Task = this.workspaceData.getGlobalTaskById(data.args.data.key);
						if (currentTask)
						{
							currentTask.containsNewDatas = true;
						}
					}
					break;
				default:
					break;
			}
		}
	}


	/**
	 *
	 */
	private _parseRessources(data: any)
	{
		let sUserId = this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;

		if (Object.keys(data.args.data.ressources).length > 0)
		{
			for (let keyRessource in data.args.data.ressources)
			{
				// keyRessource est une chaine contenant le type de ressource
				// et son identifiant séparées par ::
				if (data.args.data.ressources[keyRessource])
				{
					let ressource = data.args.data.ressources[keyRessource];

					// On récupère l'identifient de la ressource
					let nodid = keyRessource.split('::');
					if (nodid.length > 1)
					{
						// On vérifie si l'identifiant est bien défini
						if (nodid[1] && nodid[1] !== 'undefined')
						{
							// Si la ressource est une tache on appelle la fonction d'écoute
							// de tâches
							if (_.indexOf(ressource, 'task') !== -1)
							{
								this._listenTaskBySocket(nodid[1]);
							}
							else
							{
								// Sinon il s'agit d'un node et on vérifie si on la droit en lecture
								if (_.indexOf(ressource, 'read') !== -1)
								{
									this._listenNodeBySocket(nodid[1]);
								}
							}
						}
					}
				}
			}


			if (data.args && data.args.data && data.args.data.ressources)
			{
				let nodes = _.keys(data.args.data.ressources);

				this.userData.setRessources(nodes);

				// Cas user est admin
				if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
				{
					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						if (socket)
						{
							let data: ISocketData =
								{
									'iam':	'c-c',
									'name': this.userData.getUserId(),
									'what': SocketWhat.info,
									'cmd':	'node.info',
									'args': {
										nodes:		nodes,
										nodeOption: 'wc',
										depth:		-1
									}
								};
							socket.emit('read', data);
						}
					});
				}
				else
				{
					let idNodes = _.map(nodes, (node: any) =>
					{
						let idNodes = node.split('::');
						// AaarG !
						return idNodes.length > 1 ? idNodes[1] : idNodes[0];
					});

					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						if (socket)
						{
							this.socket = socket;

							let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd':	'node.info',
								'args':
									{
										nodes: idNodes,
										nodeOption: 'wp'
									}
							};

							socket.emit('read', data);
						}
					});
				}
			}
		}
	}


	private _parseMessagesReaded(data: any)
	{
		let indice		= 0;
		let indiceFinal = 0;

		let indiceInfoCurrent	= 0;
		let sUserId				= this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;

		// ---------------------------------------
		// ici on va réaliser l'appel pour récup les infos de l'user.
		// ---------------------------------------
		if (!data.args.err)
		{
			let key = data.args.data.key;

			// Récupération du mail du mail du mec d'en face
			// (principalement pour la récup des infos et la construction du titre de la room)
			for (let test in data.args.data.messages_readed)
			{
				let mail: any;
				if (test.startsWith('*'))
				{
					mail = test.substr(7);
				}
				else
				{
					mail = test;
				}

				if (this.userData.getUserId() !== mail)
				{
					// si on trouve on met à jour l'indice final pour la suite
					this.chatData.setUserByRoom2(key, mail);

					// On demande les infos des users des rooms
					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						if (socket)
						{
							this.socket = socket;
							let data: ISocketData =
								{
									'iam': 'c-c',
									'name': sUserId,
									'what': eSocketWhat,
									'cmd':	'user',
									idc:	key,
									'args':
									{
										'mail': mail,
										bdata:	true,
										bstate: true,
										token:	this.userData.getTokenKey()
									}
								};
							socket.emit('read', data);
						}
					});
				}
				else
				{
					// dans ce cas là c'est l'user en cours
					indiceFinal = indice;
				}
				indice++;
			}


			// ---------------------------------------
			// Récup de l'id du dernier message que j'ai lu
			// on transforme le json en array qu'on parse, quand on a l'user = a nous on ajoute ds la room.
			// Servira à renseigner les messages non lu et lu ds les compteurs
			// ---------------------------------------
			let indiceForTest = 0;

			let arrayMessageReaded = _.toArray(data.args.data.messages_readed);

			for (let test of arrayMessageReaded)
			{
				// Cas user en cours
				if (indiceForTest === indiceFinal && test.sub.M)
				{
					if (test && test.sub && test.sub.M)
					{
						// Message.count, récupération des compteurs de messages non lu
						this.socketService.getSocketConnection().subscribe((socket) =>
						{
							if (socket)
							{
								let data: ISocketData =
									{
										'iam': 'c-c',
										'name': this.userData.getUserId(),
										'what': SocketWhat.info,
										'cmd':	'msg.count',
										'args':
										{
											bucket:		'rooms',
											key:		key,
											fromDate:	test.sub.M.readed
										}
									};
								socket.emit('read', data);
							}
						});

						this.chatData.setLastMessageReadedByMeByRoom(key, test.sub.M.readed);
					}
				}
				else
				{
					if (test && test.sub && test.sub.M)
					{
						this.chatData.setLastMessageReadedByHimByRoom(key, test.sub.M.readed);
					}
				}
				indiceForTest++;
			}
		}
	}


	/**
	 *
	 */
	private _parseMessages(data: any)
	{
		if (data.args && !data.args.err && data.args.data.key)
		{
			let idRoom = data.args.data.key;
			this.chatData.setMessagesByRoom(idRoom, data.args.data.messages);

			// dans ce cas c'est une demande de message ancien, donc ne pas lancer (
			if (data.idc !== 'true')
			{
				this.userData.globalEmitter.emit(['chat::openRoomAfterData', '']);
			}
		}
	}


	private _parseAdminParameters(data: any)
	{
		if (data.args && data.args.data && data.args.data.result)
		{
			let result: any = data.args.data.result;
			let generalParameters: any =
			{
				mailAdmin:			result['@admin'],
				mailSystem:			result['@sys'],
				domain:				result['domaine'],
				hostMailServer: 	result['hostmailserver'],
				loginAccount:		result['loginaccount'],
				passwdAccount:		result['passwdaccount'],
				portMailServer: 	result['portmailserver'],
				secure:				result['secure'],
				typeMailServer: 	result['typemailserver'],
				useAccount:			result['useaccount'],
				numericEnabled:		result['numericEnabled'] 		=== '1' ? true : false,
				specialTimeEnabled: result['specialTimeEnabled']	=== '1' ? true : false,
			};

			generalParameters.specialTimeArray = [];

			if (result['specialTimeEnabled'])
			{

				const jsonSpecialTimeArray = JSON.parse(result['specialTimeArray']);
				for (const jsonSpecialTime of jsonSpecialTimeArray)
				{
					generalParameters.specialTimeArray.push(SpecialTime.fromJsonObject(jsonSpecialTime));
				}
			}


			this.adminData.generalParameters = generalParameters;

			if (result['scenarios'])
			{
				let scenariosObj: any = result['scenarios'];

				if (typeof scenariosObj === 'string')
				{
					scenariosObj = JSON.parse(scenariosObj);
				}

				this.adminData.scenariosAvailable = scenariosObj;
			}

			if (result['mailsTemplates'])
			{
				let mailsTemplates: any = result['mailsTemplates'];

				if (typeof mailsTemplates === 'string')
				{
					mailsTemplates = JSON.parse(mailsTemplates);
				}

				for (const template of mailsTemplates)
				{
					switch(template.type)
					{
						default:
							template.title = 'Inconnu';
							break;
						case 'accountCreation':
							template.title = 'Création de compte';
							break;
						case 'updatePassword':
							template.title = 'Mise à jour du mot de passe';
							break;
						case 'newTempPassword':
							template.title = 'Nouveau mot de passe';
							break;
						case 'newCodeMachine':
							template.title = 'Nouveau code machine';
							break;
						case 'newTask':
							template.title = 'Affectation de tâche';
							break;
						case 'terminatedTask':
							template.title = 'Tâche terminée';
							break;
						case 'validatedTask':
							template.title = 'Tâche validée';
							break;
						case 'unassignedTask':
							template.title = 'Tâche désassignée';
							break;
						case 'resetPassword':
							template.title = 'Réinitialisation du mot de passe';
							break;
					}

					if (template.datas && template.datas.jetons)
					{
						template.datas.jetons = template.datas.jetons.split(';');
					}
				}

				this.adminData.mailsTemplates = mailsTemplates;
			}

			this.adminData.adminEmitter.emit('admin::parameters::read');
		}
	}


	/**
	 *
	 */
	public _checkConnection()
	{
		let result = false;
		this.authService.checkTokenWsHttp().subscribe(resAuth =>
		{
			// auth, on peut aller sur le home, ou rester sur la page en cours
			if (resAuth === true)
			{

				// ---------------------------------------
				// création de l'object currentUser
				// ---------------------------------------
				this.userData.setStateApplication('application', 'online');

				if (!this.isConnected)
				{
					this.userService.getCurrentUser().subscribe(res =>
					{
						let body = JSON.parse(res.text());

						let etatc = 'online';

						if (body && body.data && body.data.data && body.data.data.stateChat)
						{
							etatc = body.data.data.stateChat;
						}

						this.socketService.getSocketConnection().subscribe((socket) =>
						{
							if (socket)
							{
								let data: ISocketData =
									{
										// "authToken":	this.userToken,
										'iam':	'c-c',
										'name':	this.userData.getUserId(),
										'what':		SocketWhat.info,
										'cmd':		'state',
										'args':		{ 'state': etatc }
									};

								socket.emit('update', data);
							}
						});

					});
				}
				this.activateMenu();
				result = true;
			}
			else
			{
				this.userData.setStateApplication('application', 'offline');
			}
		});

		return result;
	}


	/**
	 * Fonction permettant d'écouter une tache dont l'identifient est donné
	 * @param idTask
	 */
	public _listenTaskBySocket(idTask: string)
	{
	}


	/**
	 * Fonction permettant d'écouter un noeud dont l'identifient est donné en paramètre
	 * afin de le mettre à jour quand la bdd est modif (mise à J coté provider)
	 * @param idNode
	 */
	public _listenNodeBySocket(idNode: string)
	{
		// Construction du channel que l'on va écouter
		let channel = '*soft::' + this.userData.account + '::creation::tree::' + idNode;
		/**
		 * On vérifie si on écoute pas déja sur le channe!
		 * Si ce n'est pas le cas on ajoute une room pour écouter sur ce channel
		 * en transmettant une fonction pour gérer les evènements
		 */
		let value = this.socketService.findRoomAGerer(channel);

		if (value < 0)
		{
			this.socketService.roomsAGerer.push(
				{
					name:		channel,
					state:		'nosub',
					watch:		true,
					fct:		this._handleNodeEvent.bind(this),
					channel:	undefined
				});
		}
	}


	/**
	 * Fonction permettant de gérer les évènement relatifs à un node provenant
	 * du serveur
	 * @param datas
	 */
	public _handleNodeEvent(datas: any)
	{
		if (datas.cmd === 'node' && datas.args.node)
		{

			//S'il s'agit d'informations relatives au noeud
			//On vérifie un noeud et son type sont bien transmis
			if (datas.args.node && datas.args.node.type)
			{
				let node = datas.args.node;

				let objectToUpdate: any;
				let idParent:		string;

				let cptParents = node.idparents.length;

				// ---------------------------------------
				// Récupération de l'id parents....
				// ---------------------------------------
				// On déduit le type du parent en fonction du nombre de parents
				// contenus dans le noeud
				switch (cptParents)
				{
					case 2:
						// Le parent est un classeur
						idParent = _.find(node.idparents, (parent: any) =>
						{
							let elemnts = parent.split('::');

							return elemnts[0] === '*etab';
						});
						break;

					case 3:
						//Le parent est une session
						idParent = _.find(node.idparents, (parent: any) =>
						{
							let elemnts = parent.split('::');

							return elemnts[0] === '*sess';
						});
						break;

					case 4:
						// Le parent est un examen
						idParent = _.find(node.idparents, (parent: any) =>
						{
							let elemnts = parent.split('::');

							return elemnts[0] === '*exam';
						});
						break;

					case 5:
						idParent = _.find(node.idparents, (parent: any) =>
						{
							let elemnts = parent.split('::');

							return elemnts[0] === '*epr';
						});
						break;

					default:
						break;
				}

				if (idParent)
				{
					if (idParent.split('::').length > 1)
					{
						idParent = idParent.split('::')[1];
					}
				}


				let eSocketWhat: SocketWhat.request;
				let sUserId = this.userData.getUserId();


				const sCreatorMail = datas.args.node.createur;

				if (node.datas && datas.args.node.createur)
				{
					node.datas.author = datas.args.node.createur;
				}

				// On créé l'objet du noeud correspondant en fonction de son type
				switch (node.type)
				{
					case 'info':
					break;
					case 'etablissement':

						objectToUpdate = Etablissement.fromJsonObject(node);

						if (sCreatorMail === this.userData.mail)
						{
							this.workspaceData.resetSelect();
							objectToUpdate.show = true;
							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket && this.workspaceData.selectedEtablissement)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': eSocketWhat,
											'cmd': 'statNode',
											'args': { idNodePrefix: '*etab::' + objectToUpdate.id }
										};
									socket.emit('read', data);
								}
							});

							this.workspaceData.selectedEtablissement = objectToUpdate;
							this.workspaceData.resetSelectedSession();
							this.workspaceData.resetSelectedExamen();
							this.workspaceData.resetSelectedMatiere();

							this.savedData.lastGroup = 'etablissement';
						}

						this.workspaceData.majEtablissement(objectToUpdate.id, objectToUpdate);

						break;


					case 'session':

						objectToUpdate = Session.fromJsonObject(node);
						if (sCreatorMail === this.userData.mail)
						{
							objectToUpdate.show = true;

							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket && this.workspaceData.selectedSession)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': eSocketWhat,
											'cmd': 'statNode',
											'args': { idNodePrefix: '*sess::' + objectToUpdate.id }
										};
									socket.emit('read', data);
								}
							});

							this.workspaceData.selectedSession = objectToUpdate;
							this.workspaceData.resetSelectedExamen();
							this.workspaceData.resetSelectedMatiere();
							this.savedData.lastGroup = 'session';

						}
						this.workspaceData.setSession(objectToUpdate.id, objectToUpdate, idParent);
						break;


					case 'examen':

						objectToUpdate = Examen.fromJsonObject(node);

						if (sCreatorMail === this.userData.mail)
						{
							objectToUpdate.show = true;

							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket && this.workspaceData.selectedExamen)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': eSocketWhat,
											'cmd': 'statNode',
											'args': { idNodePrefix: '*exam::' + objectToUpdate.id }
										};
									socket.emit('read', data);
								}
							});
							this.workspaceData.selectedExamen = objectToUpdate;
							this.workspaceData.resetSelectedMatiere();
							this.savedData.lastGroup = 'examen';
						}
						this.workspaceData.setExamen(objectToUpdate.id, objectToUpdate, idParent);

						break;


					case 'epreuve':

						objectToUpdate = Matiere.fromJsonObject(node);
						if (sCreatorMail === this.userData.mail)
						{
							objectToUpdate.show = true;

							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket && this.workspaceData.selectedMatiere)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': eSocketWhat,
											'cmd': 'statEpr',
											'args': { idEpr: objectToUpdate.id }

										};
									socket.emit('read', data);
								}
							});
							this.workspaceData.selectedMatiere = objectToUpdate;
							this.savedData.lastGroup = 'epreuve';

						}
						this.workspaceData.setMatiere(objectToUpdate.id, objectToUpdate, idParent);
						break;


					default:
						break;

				}
				if (sCreatorMail === this.userData.mail)
				{
					this.workspaceData.selectedItems = [];

					if (this.workspaceData.selectedEtablissement)
					{
						this.workspaceData.selectedItems[0] = this.workspaceData.selectedEtablissement.id;
					}
					if (this.workspaceData.selectedSession)
					{
						this.workspaceData.selectedItems[1] = this.workspaceData.selectedSession.id;
					}
					if (this.workspaceData.selectedExamen)
					{
						this.workspaceData.selectedItems[2] = this.workspaceData.selectedExamen.id;
					}
					if (this.workspaceData.selectedMatiere)
					{
						this.workspaceData.selectedItems[3] = this.workspaceData.selectedMatiere.id;
					}

					this.userData.globalEmitter.emit(['node::updateTree', objectToUpdate]);
				}

				if (datas.args.message && datas.args.message.key)
				{
					let messageAdded = this.workspaceData.parseHistoric(datas.args.message);
					this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
					this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
				}
			}

			// On met à jours la liste de dates pour le calendrier
			if (datas.args.dates)
			{
				for (let data of datas.args.dates)
				{
					if (data.id)
					{
						this.userData.addCalendarElement(data.id, data);
					}
				}
			}
		}

		if (datas.cmd === 'node' && datas.args.message)
		{
			//S'il s'agit d'informations relatives aux messages du noeud
			if (datas.args.message && datas.args.message.key)
			{
				let messageAdded = this.workspaceData.parseHistoric(datas.args.message);
				this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
				this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
			}
		}

		if (datas.cmd === 'subject' || datas.cmd === 'subjects')
		{
			if (datas.args && datas.args.subject)
			{
				if (datas.args.action !== 'update')
				{
					this._addSubjectToUserDatas([datas.args.subject]);

					// On met a jour les stat epreuves de la matière du sujet recu
					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						let sUserId = this.userData.getUserId();
						let eSocketWhat: SocketWhat.request;

						if (socket)
						{
							let data: ISocketData =
								{
									'iam': 'c-c',
									'name': sUserId,
									'what': eSocketWhat,
									'cmd': 'statEpr',

									'args': { idEpr: datas.args.subject.idepreuve }
								};
							socket.emit('read', data);
						}
					});
				}
			}

			if (datas.args.message && datas.args.message.key)
			{
				let messageAdded = this.workspaceData.parseHistoric(datas.args.message);
				this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
				this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
			}


			if (datas.args.data && datas.args.data.idsujet && datas.args.data.data && datas.args.action !== 'update')
			{
				let currentSujet = this.workspaceData.getSujet(datas.args.data.idsujet);
				if (currentSujet && currentSujet.name)
				{
					for (const key in datas.args.data.data)
					{
						currentSujet.datas[key] = datas.args.data.data[key];
					}

					// Si l'état d'un sujet à été modifié, on reporte la modification dans les passations
					if (datas.args.data.data['etat'])
					{
						_.map(this.vaultData.concours, (concours: any) =>
						{
							if (concours.epreuve && concours.epreuve.id === currentSujet.idMatiere)
							{
								for (const sujet of concours.epreuve.sujets)
								{
									if (sujet.id === currentSujet.id)
									{
										sujet.datas.etat = datas.args.data.data['etat'];
										this.vaultData.vaultEmitter.emit(['passation::update', concours]);
									}
								}
							}
						});
					}
				}
			}
		}


		if (datas.cmd === 'statEpr')
		{
			if (datas.args && datas.args.statsToUpdate && datas.args.statsToUpdate.idepreuve)
			{
				let currentStatEpr = _.find(this.workspaceData.epreuveHomeAdmin, (statEpr: any) =>
				{
					return statEpr.id === datas.args.statsToUpdate.idepreuve;
				});

				if (currentStatEpr)
				{
					for (let key in datas.args.statsToUpdate)
					{
						switch (key)
						{
							case 'ava':
								currentStatEpr.avancement.autre			= datas.args.statsToUpdate[key];
								break;
							case 'avp':
								currentStatEpr.avancement.principal		= datas.args.statsToUpdate[key];
								break;
							case 'avs':
								currentStatEpr.avancement.secondaire	= datas.args.statsToUpdate[key];
								break;
							case 'nbp':
								currentStatEpr.nombre.deprincipal		= datas.args.statsToUpdate[key];
								break;
							case 'nbr':
								currentStatEpr.nombre.derejete			= datas.args.statsToUpdate[key];
								break;
							case 'nbs':
								currentStatEpr.nombre.desecondaire		= datas.args.statsToUpdate[key];
								break;
							case 'nbsuj':
								currentStatEpr.nombre.desujet			= datas.args.statsToUpdate[key];
								break;
							default:
								break;
						}
					}
					this.workspaceData.setEpreuveHomeAdmin(currentStatEpr.id, currentStatEpr);
				}
			}
		}

		if (datas.cmd === 'task')
		{
			if (datas.args&& datas.args.objResult && datas.args.objResult.task)
			{
				this._updateTasksFromDatas(datas.args.objResult.task);
			}

			if (datas.args.message && datas.args.message.key && datas.args.message.bucket)
			{
				let messageAdded = this.workspaceData.parseHistoric(datas.args.message);
				this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
				this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
			}
		}

		if (datas.cmd === 'nodeDeleted')
		{

			if (datas.args && datas.args.deletedNode)
			{
				let nodeToRemove = datas.args.deletedNode;

				if (nodeToRemove.id && nodeToRemove.type && nodeToRemove.idparents)
				{
					const strucParents = this.workspaceData.removeNode(nodeToRemove.id, nodeToRemove.type, nodeToRemove.idparents);
					// Si l'élément supprimé est selectionné, on le déselectionne et on sélectionne son parent si il en possède un

					if (this.workspaceData.selectedElement.id === nodeToRemove.id)
					{
						let parentToSelect;
						switch(nodeToRemove.type)
						{
							default:
								break;
							case 'etablissement':
								this.workspaceData.selectedElement 				= undefined;
								this.workspaceData.selectedEtablissement 		= undefined;
								this.workspaceData.selectedHomeEtablissement	= undefined;
								break;
							case 'session':
								parentToSelect = this.workspaceData.getEtablissement(strucParents.etab);
								this.workspaceData.selectedSession = undefined;
								this.workspaceData.selectedHomeSession = undefined;
								break;
							case 'examen':
								parentToSelect = this.workspaceData.getSession(strucParents.session);
								this.workspaceData.selectedExamen = undefined;
								this.workspaceData.selectedHomeExamen = undefined;
								break;
							case 'epreuve':
								parentToSelect = this.workspaceData.getExamen(strucParents.examen);
								this.workspaceData.selectedMatiere = undefined;
								this.workspaceData.selectedHomeMatiere = undefined;
								break;
						}

						if (parentToSelect)
						{
							this.userData.globalEmitter.emit(['node::updateTree', parentToSelect]);

						}
					}
				}
			}
		}

		if (datas.cmd === 'userCodeMachine')
		{
			if (datas.args && datas.args.idUser && datas.args.codeMachine)
			{
				const currentUser = this.userData.getUser(datas.args.idUser);

				if (currentUser && currentUser.privates && currentUser.privates.fp)
				{
					let fp = currentUser.privates.fp;
					if (typeof fp === 'string')
					{
						fp = JSON.parse(fp);
					}

					fp.code = datas.args.codeMachine;
					currentUser.privates.fp = fp;
				}
				else
				{
					if (currentUser)
					{
						currentUser.privates =
						{
							fp: {
								code: datas.args.codeMachine
							}
						};
					}
				}
				this.userData.addUser(currentUser, currentUser.mail);
			}
		}

		if (datas.cmd === 'userRsaPublicKey')
		{
			if (datas.args && datas.args.compte && datas.args.idUser && datas.args.publicKey)
			{
				// On enregistre en local la clé rsa publique de l'utilisateur concerné et on affiche une notification
				// indiquant que l'utilisateur s'est connecté pour la première fois
				let currentUserId = this.userData.getUserId();

				if (currentUserId !== datas.args.idUser)
				{
					let currentUser = this.userData.getUser(datas.args.idUser);
					currentUser.publics.rsaPublicKey = datas.args.publicKey;
					this.userData.globalEmitter.emit(['user::update']);
					this.showNotification("Compte " + currentUser.mail + ": première connection.", '');
				}
			}
		}
	}


	/**
	 * Gestion des sujets transmis par socket et enregistrement de ces sujets dans la structure user-data
	 * @param jsonSujets
	 */
	public _addSubjectToUserDatas(jsonSujets: any[])
	{
		const subjectsAdded = this.workspaceData._addSubjectFromJSON(jsonSujets);

		for (const subj of subjectsAdded)
		{
			this.sujetService.listenSubjectChannel(subj);
		}
	}


	public dataReceivedUsers(data: any)
	{

	}


	public dataReceivedDebug(data: any)
	{
	}


	public dataReceivedError(data: any)
	{
	}


	public dataReceivedCreate(data: any)
	{
		if (data)
		{
			if (data.cmd === 'bareme')
			{
				if (data.args && data.args.data && data.args.data.baremeAdded)
				{
					// this.showNotification('Nouveau jeu de résultat', '');
					// this.vaultData.vaultEmitter.emit(['results::read', data.args.data.result]);
					this.baremeData.addBareme(data.args.data.baremeAdded);
				}
			}


			let userForInvite = this.userData.userForChatInvite;
			if (data.cmd === 'room.addp' && !data.args.err)
			{

			}


			if (data.cmd === 'room' && userForInvite !== null && !data.args.err && userForInvite.mail !== this.userData.getUserId() )
			{

				let roomId	= data.args.data.room.uuid;
				let room	= data.args.data.room;

				let oRoom = Piece.fromJsonObject(room);
				this.chatData.addRooms(oRoom, roomId);
				this.setRoomForCreate(data);
	//			this.chatData.setSelectedRoomBy(roomId);


				// Construction de la cdc pour avoir les retours de messages pour chaques rooms
				let roomState = '::priv';
				let roomReadOnly = '';

				if (room.isPublic)
				{
					roomState = '::pub';
				}
				if (room.isReadOnly)
				{
					roomReadOnly = '::' + room.owner;
				}

				let roomId2 = this.socketService.findRoomAGerer(name);
				let roomName = '*room::' + room.uuid + roomState + roomReadOnly;

				if (roomId2 === -1)
				{
					this.socketService.roomsAGerer.push({ name: roomName, state: 'nosub', watch: true, fct: this.newMessageReceived.bind(this, roomName), channel: undefined });
				}

				let eSocketWhat: SocketWhat.request;

				this.socketService.getSocketConnection().subscribe((socket) =>
				{

					if (socket)
					{
						this.socket = socket;

						let data: ISocketData =
							{
								'iam':	'c-c',
								'name': localStorage.getItem('userid'),
								'what': eSocketWhat,
								'cmd': 'room.addp',
								'args': { 'chan': '*room::' + roomId, 'list': '*user::' + userForInvite.mail }
							};

						socket.emit('create', data);
					}
				});

				this.userData.globalEmitter.emit(['notify2', roomId]);

				// ---------------------------------------
				// TODO => remplacer
				// ---------------------------------------
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						this.socket = socket;

						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': localStorage.getItem('userid'),
								'what': eSocketWhat,
								'cmd': 'rooms',
								'args': { mail: '' }
							};
						socket.emit('read', data);
					}
				});
			}

			if (data.cmd === 'subject')
			{
				if (data.args && data.args.data && data.args.data.result && data.args.data.result.subject)
				{
					let newSubject = data.args.data.result.subject;
					this._addSubjectToUserDatas([newSubject]);
					this.userData.globalEmitter.emit('subject::get');

					let subjectToAdd: Sujet = Sujet.fromJsonObject(newSubject);

					//On récupère l'épreuve correspondante
					let epreuve = this.workspaceData.getEpreuve(subjectToAdd.idMatiere);

					if (epreuve)
					{
						this.workspaceData.selectedSujet = subjectToAdd;
						this.workspaceData.globalEmitter2.emit(epreuve.id);
					}

					//Si la nouvelle épreuve contient une première tache crée automatiquement, on l'ajoute
					//aux taches existantes
					if (data.args.data.result.firstTask && data.args.data.result.firstTask.task)
					{
						this.workspaceData.setTasksBySubject([data.args.data.result.firstTask.task]);
						this.taskData.addTasks([data.args.data.result.firstTask.task]);

						if (data.args.data.result.firstTask.dates)
						{
							for (let date of data.args.data.result.firstTask.dates)
							{
								this.userData.addCalendarElement(date.id, date);
							}
							this.userData.globalEmitter.emit('calendar:newDate');
						}
					}
				}
			}

			if (data.cmd === 'newkey')
			{
				if (data.args && data.args.data)
				{
					this.workspaceData.sujetKey = data.args.data.keyType + '#' + data.args.data.val;
					this.userData.globalEmitter.emit('subject::getKey');
				}
			}

			if (data.cmd === 'task')
			{
				if (data.args && data.args.data && data.args.data.result && data.args.data.result.task)
				{
					if (data.args.data.result.dates)
					{
						for (let date of data.args.data.result.dates)
						{
							this.userData.addCalendarElement(date.id, date);
						}
						this.userData.globalEmitter.emit('calendar:newDate');
					}

					this.workspaceData.setTasksBySubject([data.args.data.result.task]);
					this.showNotification('Nouvelle tâche', '');

									let tatta = false;
									if(data.idc && data.idc === 'true')
									{
										tatta = true;
									}
					this.taskData.addTasks([data.args.data.result.task], tatta);

				}
			}

			if (data.cmd === 'createOTP')
			{
				if (data.args && data.args.data && data.args.data.result && data.args.data.result.otpval)
				{
					let valOtpCode = data.args.data.result.otpval;
					this.userData.globalEmitter.emit(['OTP:newCode', valOtpCode]);
				}
			}

			if (data.cmd === 'validateOtp')
			{
				if (data.args && data.args.data && data.args.data.result && data.args.data.result.mail && data.args.data.result.method)
				{
					let accountInfos = this.userData.getAccountInfo();

					if (accountInfos.infosUser.mail === data.args.data.result.mail)
					{
						this.userData.methodAuth = Number(data.args.data.result.method);
					}
					this.userData.globalEmitter.emit('OTP:codeValidated');
				}
			}


			if (data.cmd === 'password')
			{
				if (data.args && data.args.data && data.args.data.result)
				{
					this.userData.globalEmitter.emit(['user::newPassword', data.args.data.result]);
				}
			}

			if (data.cmd === 'checkUserFingerprint')
			{
				if (data.args && data.args.data && data.args.data.result && data.args.data.result.fingerPrint)
				{
					if (data.args.data.result.mustValidMachine === false || data.args.data.result.rsaGenerated === false)
					{
						this.userData.mustValidateBrowser = false;
						if (data.args.data.result.mustGenerateRsa === true)
						{
							// On génère en local les clé RSA privé et public, on envoie la clé publique au serveur
							// et on stocke la clé privé en local
							this.encryptService.generateRsaKeyPair().then(resultRsa =>
							{

								if (resultRsa && resultRsa.privateKey && resultRsa.publicKey)
								{
									this.userData.setRSAPrivate(resultRsa.privateKey);
									this.encryptService.sendRsaPublic(resultRsa.publicKey);

									let dialogRefDownloadRsa = this.dialog.open(DownloadRsaModalComponent,
									{
										height:			'250px',
										panelClass:		'responsive_mini_modal',
										width:			'600px',
										data:			{},
										disableClose:	true
									});

									dialogRefDownloadRsa.afterClosed().subscribe((resultWarning: any) =>
									{
										this._otpAuthentification();
									});
								}
							});
						}
						else
						{
							let currentRsaPrivate = this.userData.getRSAPrivate();

							if (currentRsaPrivate)
							{
								this.checkCurrentRsaKey();
							}
							else
							{
								let dialogRefWarningRsa = this.dialog.open(WarningRsaModalComponent,
									{
										height:			'210px',
										panelClass:		'responsive_mini_modal',
										width:			'600px',
										data:			{},
										disableClose:	true
									});

								dialogRefWarningRsa.afterClosed().subscribe((resultWarning: any) =>
								{
									this._otpAuthentification();
								});
							}
						}
					}
					else
					{
						this.userData.mustValidateBrowser = true;
						// On doit valider le code numérique envoyer par mail et valider la machine
						let dialogRefValidMachine = this.dialog.open(ConnectNewMachineModalComponent,
							{
								height:		'300px',
								panelClass: 'responsive_mini_modal',
								width:		'600px',
								data:
								{
									fingerprint: data.args.data.result.fingerPrint
								},
								disableClose: true
							});

						dialogRefValidMachine.afterClosed().subscribe((result: any) =>
						{
							if (result && result === true)
							{
								this.userData.mustValidateBrowser = false;

								let currentRsaPrivate = this.userData.getRSAPrivate();

								if (currentRsaPrivate)
								{
									this.checkCurrentRsaKey();
								}
								else
								{
									let dialogRefWarningRsa = this.dialog.open(WarningRsaModalComponent,
										{
											height:			'210px',
											panelClass:		'responsive_mini_modal',
											width:			'600px',
											data:			{},
											disableClose:	true
										});

									dialogRefWarningRsa.afterClosed().subscribe((resultWarning: any) =>
									{
										this._otpAuthentification();
									});
								}
							}
							else
							{
								this.userData.logout();
								this.socketService.logout();
								this.socketVaultService.logout();
								this.taskData.initTasks();
								this.router.navigate(['/login']);
								this.isConnected = false;
							}
						});

					}
				}
				else
				{
					this.userData.logout();
					this.socketService.logout();
					this.socketVaultService.logout();
					this.taskData.initTasks();
					this.router.navigate(['/login']);
					this.isConnected = false;
				}
			}

			if (data.cmd === 'generateNewCodeMachine')
			{
				this.userData.globalEmitter.emit('machineCode:new');
			}

			if (data.cmd === 'checkOtp')
			{
			}

			if (data.cmd === 'candidats')
			{
				// On ajoute au modele les candidats créés
				if (data.args && data.args.data && data.args.data.candidats)
				{
					for(let candidat of data.args.data.candidats)
					{
						if (candidat.idparents && candidat.idparents.length > 0)
						{
							for(let idparent of candidat.idparents)
							{
								// this.vaultData.addCandidatByElement(idparent, candidat);
							}
						}
					}

					this.vaultData.vaultEmitter.emit('candidats::create');
				}
			}

			if (data.cmd === 'passation')
			{
				if (data.args
					&& data.args.data
					&& data.args.data.passationAdded)
				{
					let passationAdded = data.args.data.passationAdded;
					passationAdded.candidats = [];
					this.vaultData.addConcours(passationAdded);

					this.showNotification('Nouvelle passation créée', '');

					this.vaultData.vaultEmitter.emit('passation::create');

				}
			}

			if (data.cmd === 'userFileKey')
			{
				if (data.args
					&& data.args.data
					&& data.args.data.fileId)
				{
					this.userData.globalEmitter.emit(['userFileKey', data.args.data.fileId]);
				}
			}
		}
	}

	/**
	 * Retourne true si une chaine str commence par la sous-chaine word
	 * @param str
	 * @param word
	 */
	public startsWith(str: any, word: any)
	{
		return str.lastIndexOf(word, 0) === 0;
	}


	public dataReceivedUpdate(data: any)
	{
		if (data.cmd === 'file' && data.args.data && data.args.data.result && data.args.data.result.file)
		{
			let fileUpdate = FileSubject.fromJsonObject(data.args.data.result.file);

			this.workspaceData.addFile(fileUpdate.idSubject, fileUpdate);
			this.workspaceData.globalEmitter2.emit(fileUpdate.idSubject);
		}


		if (data.cmd === 'task')
		{ 
			if (data && data.args && data.args.data && data.args.data.result && data.args.data.result.task)
			{
				if (data.args.data.result.dates)
				{
					for (let date of data.args.data.result.dates)
					{
						this.userData.addCalendarElement(date.id, date);
					}
					this.userData.globalEmitter.emit('calendar:newDate');
				}

				if (data.args.data.result.datesToRemove)
				{
					for (let date of data.args.data.result.datesToRemove)
					{
						this.userData.removeCalendarElement(date);
					}
					this.userData.globalEmitter.emit('calendar:newDate');
				}

				if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
				{
					if (data.args.data.result.subject)
					{
						// enregistrement des sujets à la bonne place dans workspaceData
						this.workspaceData._addSubjectFromJSON([data.args.data.result.subject]);
					}
				}

				this._updateTasksFromDatas(data.args.data.result.task);
			}
		}


		if (data.cmd === 'user')
		{
			if (data.args && data.args.data && data.args.data.datasUpdated && data.args.data.userId)
			{
				let accountInfos = this.userData.getAccountInfo();

				//S'il s'agit de l'utilisateur courant on met à jour les infos dans user data
				if (accountInfos.infosUser.mail === data.args.data.userId)
				{
					if (data.args.data.datasUpdated.infos)
					{
						accountInfos.infosUser.infos = data.args.data.datasUpdated.infos;

						if (accountInfos.infosUser.infos.nom)
						{
							this.userData.firstName = accountInfos.infosUser.infos.nom;
						}

						if (accountInfos.infosUser.infos.prenom)
						{
							this.userData.lastName = accountInfos.infosUser.infos.prenom;
						}

						if (accountInfos.infosUser.infos.color)
						{
							this.userData.color = accountInfos.infosUser.infos.color;
						}
					}

					if (data.args.data.datasUpdated.publics)
					{
						accountInfos.infosUser.publics = data.args.data.datasUpdated.publics;
					}
					this.userData.setAccountInfo(accountInfos);
				}
				else
				{
					//Sinon on retourve l'utilisateur concerné et on le met à jour
					data.args.data.datasUpdated.mail	= data.args.data.userId;
					data.args.data.datasUpdated.userId	= data.args.data.userId;

					if (data.args.data.datasUpdated.infos && data.args.data.datasUpdated.infos.nom && data.args.data.datasUpdated.infos.prenom)
					{
						data.args.data.datasUpdated.completeName = data.args.data.datasUpdated.infos.nom + ' ' + data.args.data.datasUpdated.infos.prenom;
					}

					this.userData.addUser(data.args.data.datasUpdated, data.args.data.userId);
					this.userData.globalEmitter.emit(['user::add', data.args.data.datasUpdated]);

				}
			}
		}

		if (data.cmd === 'updateUserPassword')
		{
			this.userData.globalEmitter.emit('user::passwordUpdated');
		}


		if (data.cmd === 'changeOtpMethod')
		{
			// Si le changement de méthode de connexion concerne l'utilisateur cournat on met à jour
			// ses informations
			if (data.args && data.args.data && data.args.data.result && data.args.data.result.mail && data.args.data.result.method)
			{
				let accountInfos = this.userData.getAccountInfo();
				if (accountInfos.infosUser.mail === data.args.data.result.mail)
				{
					this.userData.methodAuth = Number(data.args.data.result.method);
				}
			}
			this.userData.globalEmitter.emit('user::newAuthMethod');
		}


		if (data.cmd === 'checkCodeNewMachine')
		{
			if (data.args && data.args.data && data.args.data.result && data.args.data.result === true)
			{
				this.userData.globalEmitter.emit('FP::machineValidated');
			}
			else
			{
				this.userData.globalEmitter.emit('FP::machineUnvalidated');
			}
		}


		if (data.cmd === 'adminParameters')
		{
			if (data.args && data.args.data && data.args.data.result)
			{
				let result: any = data.args.data.result;

				if (result['@admin'])				this.adminData.generalParameters.mailAdmin			= result['@admin'];
				if (result['@sys'])					this.adminData.generalParameters.mailSystem			= result['@sys'];
				if (result['domaine'])				this.adminData.generalParameters.domain				= result['domaine'];
				if (result['hostmailserver'])		this.adminData.generalParameters.hostMailServer 	= result['hostmailserver'];
				if (result['loginaccount'])			this.adminData.generalParameters.loginAccount		= result['loginaccount'];
				if (result['passwdaccount'])		this.adminData.generalParameters.passwdAccount		= result['passwdaccount'];
				if (result['portmailserver'])		this.adminData.generalParameters.portMailServer 	= result['portmailserver'];
				if (result['secure'])				this.adminData.generalParameters.secure				= result['secure'];
				if (result['typemailserver'])		this.adminData.generalParameters.typeMailServer 	= result['typemailserver'];
				if (result['useaccount'])			this.adminData.generalParameters.useAccount			= result['useaccount'];
				if (result['numericEnabled'])		this.adminData.generalParameters.numericEnabled		= result['numericEnabled'];
				if (result['specialTimeEnabled'])	this.adminData.generalParameters.specialTimeEnabled	= result['specialTimeEnabled'];
			//	if (result['specialTimeArray'])		this.adminData.generalParameters.specialTimeArray		= result['specialTimeArray'];

				this.adminData.generalParameters.specialTimeArray = [];
				if (result['specialTimeEnabled'])
				{
					const jsonSpecialTimeArray = JSON.parse(result['specialTimeArray']);
					for (const jsonSpecialTime of jsonSpecialTimeArray)
					{
						this.adminData.generalParameters.specialTimeArray.push(SpecialTime.fromJsonObject(jsonSpecialTime));
					}
				}


				if (result['scenarios'])
				{
					let scenariosObj: any = result['scenarios'];

					if (typeof scenariosObj === 'string')
					{
						scenariosObj = JSON.parse(scenariosObj);
					}

					this.adminData.scenariosAvailable = scenariosObj;
				}

				this.adminData.adminEmitter.emit('admin::parameters::update');
			}
		}

		if (data.cmd === 'userAccess')
		{
			if (data.args && data.args.data && data.args.data.user && data.args.data.enable !== undefined)
			{
				let user		= this.userData.getUser(data.args.data.user);
				user.enabled	= data.args.data.enable;
			}
		}

		if (data.cmd === 'passation')
		{
			if (data.args && data.args.data && data.args.data.datasUpdated && data.args.data.passationId)
			{
				// Mise à jours du bareme
				this.vaultData.updateNode(data.args.data.passationId, data.args.data.datasUpdated);

				const currentPassation = this.vaultData.getNodeById(data.args.data.passationId);

				if (currentPassation && currentPassation.id)
				{
					let canditatsError: any = [];
					if (data.args.data.candidatsError)
					{
						canditatsError = data.args.data.candidatsError;
					}

					this.vaultData.vaultEmitter.emit(['passation::update', currentPassation, canditatsError]);
					this.showNotification('Nouvelle passation mise à jour', '');
				}
			}
		}
//TestUPEC-09-06-2021-01

		if (data.cmd === 'bareme')
		{
			if (data.args && data.args.data && data.args.data.datasUpdated && data.args.data.datasUpdated.datas && data.args.data.baremeId)
			{
				this.baremeData.updateBaremeDatasById(data.args.data.baremeId, data.args.data.datasUpdated);
//				this.showNotification('Nouvelle passation mise à jour 1', '');
			}
		}

		if (data.cmd === 'sendPassation')
		{
			if (data.args && data.args.data && data.args.data.idPassation && data.args.data.subjects)
			{
				let passationId = data.args.data.idPassation;

				if (passationId)
				{

					if (data.args.data.subjects)
					{
						let subjectsPass: any = {};

						for (let subject of data.args.data.subjects)
						{
							subjectsPass[subject.idSubject] = subject.passwordUser;
						}

						let datasToUpdate: any =
							{
								passwordSubjects:	subjectsPass,
								datePassationSend:	data.args.data.datePassationSend,
								passationSend:		data.args.data.isPassationSend,
								mustUpdate:			data.args.data.mustUpdate
						};
						this.vaultData.updateNode(passationId, datasToUpdate);

						this.showNotification('Passation synchronisée sur Vault', '');
					}
				}
			}
		}

		if (data.cmd === 'adminMailsTemplates')
		{
			if (data.args && data.args.data && data.args.data.result)
			{
				this.showNotification('Modèles de mails mis à jour', '');
			}
		}
	}


	public  dataReceivedDelete(data: any)
	{
		if (data.cmd === 'deleteRsaKeyUser')
		{
			if (data.args && data.args.data && data.args.data.idUser)
			{
				const currentUser = this.userData.getUser(data.args.data.idUser);

				if (currentUser)
				{
					let dialogRef = this.dialog.open(InfoResetRsaModalComponent,
					{
						height:			'40%',
						width:			'50%',
						panelClass:		'responsive_modal',
						disableClose:	true,
						data:
						{
							'user': currentUser,
						}
					});
				}
			}
		}

		if (data.cmd === 'deleteScenario')
		{
			if (data && data.args && data.args.data)
			{
				this.adminData.adminEmitter.emit(['admin::scenario::delete', data.args.data]);
			}
		}
	}


	public dataReceivedAccountUpdates(data: any)
	{
		if (data.cmd === 'user')
		{
			let newUser = data.args;
			this.userData.addUser(newUser, newUser.mail);
			this.userData.globalEmitter.emit(['user::update']);
		}

		if (data.cmd === 'deleteUser'
			&& data.args && data.args && data.args.userMail)
		{
			this.userData.removeUser(data.args.userMail);
			this.userData.globalEmitter.emit('user::remove');
			this.showNotification('L\'utilisateur ' + data.args.userMail + ' a été supprimé.', '');

			if (data.args.tasksUnassigned)
			{
				for (const task of data.args.tasksUnassigned)
				{
					let oTask	= Task.fromJsonObject(task);
					oTask		= this.taskData.buildTask(oTask);

					oTask.assigneduser 	= '';
					oTask.state 		= 'new';
					oTask.stateLib 		= 'Non assigné';

					this.taskData.setGlobalTask(oTask.id, oTask);
				}
			}
		}

		if (data.cmd === 'state')
		{
			if (data.args.state === 'offline' && data.args.userName === this.userData.getUserId())
			{
				let etatc = 'imhere';
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						let data: ISocketData =
							{
								// "authToken":	this.userToken,
								'iam': 'c-c',
								'name': this.userData.getUserId(),
								'what': SocketWhat.info,
								'cmd': 'state',
								'args': { 'state': etatc }
							};

						socket.emit('update', data);
					}
				});

			}
			else
			{

			}

			this.chatData.setUserState(data.args.state, data.args.userName);
		}
	}


	public dataReceivedUser(data: any)
	{
		// ---------------------------------------
		// Cas de la reception d'une invitation
		// ---------------------------------------
		if (data.cmd === 'room.addp')
		{
			let sUserId = this.userData.getUserId();

			let who = data.args.who;
			let chanRoom = data.args.chan;

			// Suis-je la personne invité ?
			this.userData.idNewRoom = chanRoom;

			if (who === '*user::' + sUserId)
			{
				let eSocketWhat: SocketWhat.request;

				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						this.socket = socket;

						let data: ISocketData =
							{
								'iam': 'c-c',
								'name': sUserId,
								'what': eSocketWhat,
								'cmd': 'room',
								'idc': chanRoom,
								'args': { chan: chanRoom }
							};
						socket.emit('read', data);
					}
				});
			}
		}

		if (data.cmd === 'file')
		{
			if (data.args
				&& data.args.file)
			{
				let fileObj = FileSubject.fromJsonObject(data.args.file);

				if (data.args.encodedRsaKey)
				{
					fileObj.encryptedAesKeys = [data.args.encodedRsaKey];
				}

				this.workspaceData.addFile(fileObj.idSubject, fileObj);
				this.workspaceData.globalEmitter2.emit(fileObj.idSubject);
			}
		}


		// ---------------------------------------
		// Suppression des taches
		// On vérifie si la tache reçu est dans ma liste de tache et si la nouvelle affectation est pas pour moi
		// ---------------------------------------------------------
		if (data.cmd === 'deletetask')
		{
			if (data.args && data.args.task)
			{
				let taskToDelete = Task.fromJsonObject(data.args.task);
				// On supprime la taches des taches globales

				let tasks: any[] 		= this.workspaceData.globalTask;
				let keysTasks: any[] 	= [];
				let indexToDelete		= -1;

				tasks = _.filter(tasks,(task: any) => { return taskToDelete.id !== task.id });
				this.workspaceData.globalTask = tasks;

				keysTasks 		= Object.keys(this.workspaceData.taskHomeAdminExpired);
				indexToDelete 	= keysTasks.findIndex(val => { return val === taskToDelete.id });
				this.workspaceData.removeTaskExpired(indexToDelete);

				keysTasks 		= Object.keys(this.workspaceData.taskHomeMine);
				indexToDelete 	= keysTasks.findIndex(val => { return val === taskToDelete.id });
				this.workspaceData.removeTaskToMine(indexToDelete);


				keysTasks 		= Object.keys(this.workspaceData.taskHomeAdminToValid);
				indexToDelete 	= keysTasks.findIndex(val => { return val === taskToDelete.id });
				this.workspaceData.removeTaskToValid(indexToDelete);

				if (this.workspaceData.selectedTask && this.workspaceData.selectedTask.id === taskToDelete.id)
				{
					this.workspaceData.selectedTask = undefined;
				}


				/**Supression des taches */


				this.taskData.globalTaskDatas = this.taskData.globalTaskDatas.filter(task =>
					{
							if (taskToDelete.id !== task.id)
							{
								return true
							}
						//return tasksToRemove.includes(task.id);
					});

					this.taskData.taskDatas = this.taskData.taskDatas.filter(task =>
					{
							if (taskToDelete.id !== task.id)
							{
								return true
							}
						//return tasksToRemove.includes(task.id);
					});

				keysTasks 		= Object.keys(this.workspaceData.taskHomeAdminToValid);
				indexToDelete 	= keysTasks.findIndex(val => { return val === taskToDelete.id });
				this.workspaceData.removeTaskToValid(indexToDelete);

				if (this.workspaceData.selectedTask && this.workspaceData.selectedTask.id === taskToDelete.id)
				{
					this.workspaceData.selectedTask = undefined;
				}

				if (taskToDelete.idSujet && taskToDelete.idEpreuve)
				{
					const epreuve = this.workspaceData.getEpreuve(taskToDelete.idEpreuve);

					if (epreuve && epreuve.sujets)
					{
						epreuve.sujets = _.filter(epreuve.sujets,(sujet: any) => { return sujet.id !== taskToDelete.idSujet; });
					}

					if (epreuve && epreuve.tasks)
					{
						epreuve.tasks = _.filter(epreuve.tasks,(task: any) => { return task.id !== taskToDelete.id; });
					}

					let statsToClean2 = this.workspaceData.statsNode2;
					statsToClean2 = _.filter(statsToClean2,(stat: any) => { return stat.idTask !== taskToDelete.id; });
					this.workspaceData.statsNode2 = statsToClean2;

					let statsToClean = this.workspaceData.statsNode;
					statsToClean = _.filter(statsToClean,(stat: any) => { return stat.idTask !== taskToDelete.id; });
					this.workspaceData.statsNode = statsToClean;

					this.workspaceData.globalEmitter.emit('majStatsEpr');
					this.workspaceData.globalEmitter.emit(['deleteTask', taskToDelete]);
				}
			}
		}

		if (data.cmd === 'task')
		{
			if (data.args)
			{
				if (data.args.task)
				{
					/**
					 * Si la tache récupérée implique des resssources que l'on ne possède pas on les récupère
					 */
					if (data.args.task.idepreuve)
					{
						let epreuve = this.workspaceData.getEpreuve(data.args.task.idepreuve);
						let sUserId	= this.userData.getUserId();

						if (epreuve === undefined)
						{
							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': SocketWhat.info,
											'cmd': 'ressources',
											'args': {}
										};
									socket.emit('read', data);
								}
							});
						}
						else
						{
							// Si on possède déja les nodes on vérifie si on possède le sujet
							if (data.args.task.idsujet)
							{
								let sujet = this.workspaceData.getSujet(data.args.task.idsujet);
								if (sujet === undefined)
								{
									this.sujetService.getSujets(data.args.task.idepreuve, data.args.task.idsujet);
								}

								/**
								 * On rafraichie les statistiques relatives à l'épreuve de la tache concernée
								 */
								this.socketService.getSocketConnection().subscribe((socket) =>
								{
									let onlyMe: boolean = this.userData.isAdmin() === true || this.userData.isSuperviseur() === true ? false : true;
									if (socket)
									{
										let dataSocket: ISocketData =
										{
											'iam': 'c-c',
											'name': sUserId,
											'what': SocketWhat.info,
											'cmd': 'statSuj',
											'args': { idSuj: data.args.task.idsujet, onlyMe: onlyMe }
										};
										socket.emit('read', dataSocket);
									}
								});
							}
						}
					}

					this._updateTasksFromDatas(data.args.task);
				}

				if (data.args.dates)
				{
					for (let date of data.args.dates)
					{
						this.userData.addCalendarElement(date.id, date);
					}
					this.userData.globalEmitter.emit('calendar:newDate');
				}

				if (data.args.datesToRemove)
				{
					for (let date of data.args.datesToRemove)
					{
						this.userData.removeCalendarElement(date);
					}
					this.userData.globalEmitter.emit('calendar:newDate');
				}
			}
		}

		if (data.cmd === 'sendPassation')
		{
			if (data.args)
			{
				this.workspaceData.globalEmitter2.emit(['passation:sending', data.args]);
			}
		}

		if (data.cmd === 'passations')
		{
			if (data.args && data.args.passations && data.args.passations.length > 0)
			{
				let passations = data.args.passations;

				for (let passation of passations)
				{
					this.vaultData.addConcours(passation);
				}

				this.vaultData.vaultEmitter.emit(['passations::get', data.args]);
			}
		}
	}


	public dataReceivedMessage(data: any)
	{
		if (data && data.content)
		{
			if (data.content === 'account_initializing')
			{
				this.dialog.open(InfoInitAccountModalComponent,
				{
					height:			'40%',
					width:			'40%',
					panelClass:		'responsive_modal',
					disableClose:	true,
					data:
					{}
				});
			}
		}
	}


	/**
	 *
	 */
	public openPage()
	{

	}


	/**
	 *
	 */
	public userLogged()
	{

		return this.userData.hasLoggedIn();
	}


	/**
	 *
	 */
	public disconnect()
	{
		this.userData.isAccountSelected = false;

		this.userData.logout();
		this.socketService.logout();
		this.socketVaultService.logout();
		this.workspaceData.logout();
		this.savedData.logout();
		this.vaultData.logout();
		this.taskData.initTasks();
		AuthService.stopGestionRooms();
		this.socketService.init();
		this.router.navigate(['/login']);
		this.isConnected = false;
	}

	/**
	 *
	 */
	public help()
	{
		this.router.navigate(['/help']);
	}



	/**
	 *
	 */
	public openAdminPanel()
	{
		this.router.navigate(['/admin']);
	}


	/**
	 *
	 */
	public openAccountPanel()
	{
		let dialogRef = this.dialog.open(UserModalComponent,
			{
				height:			'80%',
				width:			'65%',
				panelClass:		'responsive_modal',
				disableClose:	true,
				data:
				{
					'edition': true,
				}
			});
	}


	/**
	 * Active le footer après la connexion
	 */
	public activateMenu()
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------

		let isLogged = this.userData.hasLoggedIn();
		if (isLogged === true && this.inLoginPanel === false)
		{
			this.showMenu = true;
		}
		else
		{
			this.showMenu = false;
		}
	}


	/**
	 * Lecture des rôles recus et affectation du status correspondant
	 *
	 * @param roles
	 */
	public handleRolesReceived(roles: any)
	{
		let rolesReturned:	string[] = [];
		let partsSplit:		string[];

		for (let role of roles)
		{
			partsSplit = role.split('::');
			if (partsSplit.length > 1)
			{
				rolesReturned.push(partsSplit[0]);
			}
		}

		if (_.indexOf(rolesReturned, '*super') !== -1)
		{
			this.userData.status = UserData.STATUS_SUPERVISEUR;
		}
		else
		{
			if (_.indexOf(rolesReturned, '*resp') !== -1)
			{
				this.userData.status = UserData.STATUS_RESPONSABLE;
			}
			else
			{
				if (_.indexOf(rolesReturned, '*admin') !== -1)
				{
					this.userData.status = UserData.STATUS_ADMIN;
				}
				else
				{
					this.userData.status = UserData.STATUS_USER;
				}
			}
		}
		this.userData.globalEmitter.emit('role::get');
	}


	/**
	 * Vérification sur serveur de la clé RSA courante
	 * Si la clé RSA courante n'est pas valide, on efface la clé RSA courante du navigateur
	 * et on affiche un message d'avertissement pour l'utilisateur
	 */
	public checkCurrentRsaKey()
	{
		let date		= new Date();
		let bucketDate	= date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
		let encryptStr	= this.userData.getUserId() + bucketDate;

		this.isCheckingRSAKey = true;
		this.userService.encryptDatasForValidation(encryptStr);
	}


	/**
	 * Apelle la modal de connexion si on pert cette dernière
	 */
	public openModalConnectApplication(callback: (result: any) => any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------

		if (!this.userData.dialogRefModalConnection)
		{
			this.userData.dialogRefModalConnection = this.dialog.open(ConnectApplicationModalComponent,
				{
					height:			'50%',
					panelClass:		'responsive_modal',
					width:			'60%',
					data:			{},
					disableClose:	true
				});

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			this.userData.dialogRefModalConnection.afterClosed().subscribe((result: any) =>
			{
				if (result !== undefined)
				{
					callback(result);
				}
			});
		}
	}


	public reset()
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		// this.idle.watch();
		// this.idleState = 'Started.';
		// this.timedOut = false;
	}


	public initConfigs(): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			if (this.isConfigInitiated === false)
			{
				CreationHttpService.initConfigs(this.http).then(res =>
				{
					resolve();
				}).catch(err =>
				{
					resolve();
				});
			}
			else
			{
				resolve();
			}
		});
	}


	private _checkAddBlocker()
	{
		let addBlockerEnabled = document.getElementById('adsChecker') === null;

		if (addBlockerEnabled === true)
		{
			let dialogRefWarningRsa = this.dialog.open(WarningAddBlockerEnabledComponent,
				{
					height:		'220px',
					width:		'40%',
					panelClass: 'responsive_mini_modal',
					data:
					{
						typeWarning: 2
					},
					disableClose: true
				});

			dialogRefWarningRsa.afterClosed().subscribe((resultWarning: any) =>
			{
				window.location.reload(true);
			});
		}
	}

}
