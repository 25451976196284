import { Injectable }	from '@angular/core';
import { Http }			from '@angular/http';
import {Observable} from 'rxjs/Rx';

import { DataHttpService }	from './dataHttp.service';
import { UserData }			from '../../providers/user-data';
import { CreationHttpService }	from './creationHttp.service';
import { Etablissement } from '../../classes/etablissement.class';

@Injectable()


/**
 * 
 */
export class EtablissementHttpService extends DataHttpService
{

	/**
	 * 
	 */
	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('etablissement'));
	}


	/**
	 * 
	 */
	public addEtablissement(dataEtab: any): Observable<any>
	{
		dataEtab.path = [];
		return this.add(null, [], dataEtab);
	}


	/**
	 * 
	 */
	public editEtablissement(dataEtab: any): Observable<any>
	{
		return this.edit(dataEtab);
		
		
	}


	/**
	 * 
	 */
	public getAllEtablissements(): Observable<any>
	{
		this.jOptionToken = 
			{
				nodes:	this.userData.getRessources()
			};
		return this.get();
	}


}
