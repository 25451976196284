<mat-dialog id="warning_rsa_modal" class="dialog_grid_container">
	<div class="dialog_header">
		<div class="dialog_header_title">
			{{ 'warningUser.title' | translate }}
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">
		<mat-card-content>
			<label>{{ 'warningUser.texte1' | translate }}</label>
			<div class="clear"></div>
			<label>{{ 'warningUser.texte2' | translate }}</label>
			<div class="clear"></div>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">{{ 'common.continue' | translate }}</button>
	</div>
</mat-dialog>