<mat-dialog id="connection_application_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Réinitialisation du mot de passe
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">
        <label>{{ libelle }}</label>
		<div class="clear"></div>
        <mat-card-content>
			<password-editor (onValidPassword)="handleOnValidPassword($event)" [password]="newPassword"></password-editor>
		</mat-card-content>

		<div *ngIf="hasError" class="error-message">
			<label>Une erreur est survenue. Veuillez rééssayer unltérieurement.</label>
		</div>

    </div>
	<div class="dialog_footer">
		<button class="cancel_button" type="button" mat-raised-button color="" (click)="cancel()">Annuler</button>
		<button class="validate_button" mat-raised-button color="accent" (click)="updatePassword()" [disabled]="!canValidPassword()">Valider</button>
	</div>
</mat-dialog>
