import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy }	from '@angular/core';
import { MatDialogRef }					from '@angular/material';
import { MAT_DIALOG_DATA }              from '@angular/material';
import { UserService }					from '../../services/user.service';
import { UserData }					    from '../../providers/user-data';

@Component({
	selector:		'connect-new-machine-modal',
	templateUrl:	'connectNewMachineModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./connectNewMachineModal.component.scss']
})
export class ConnectNewMachineModalComponent implements OnInit, OnDestroy
{

    public code:				any;
    public displayErrorCode:	boolean = false;
    public fingerPrint:			any;

	public moUpdateSubscription:	any;
	public displayInvalidCodeError: boolean = false;
	public displayNewCodeGenerared: boolean = false;

    constructor(public dialogRef:	MatDialogRef<ConnectNewMachineModalComponent, any>,
                public userService: UserService,
                public userData:	UserData,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
        this.fingerPrint = data.fingerprint;
	}


	/**
	 *
	 */
    public ngOnInit()
	{
        this.moUpdateSubscription = this.userData.globalEmitter.subscribe((value: any) =>
		{
            if (value === 'FP::machineValidated')
			{
				this.displayInvalidCodeError = false;
                this.dialogRef.close(true);
			}

			if (value === 'FP::machineUnvalidated')
			{
				this.displayInvalidCodeError = true;
				this.displayNewCodeGenerared = false;
			}

			if (value === 'machineCode:new')
			{
				this.displayInvalidCodeError = false;
				this.displayNewCodeGenerared = true;
			}
        });
    }


	/**
	 *
	 */
    public ngOnDestroy()
	{
		this.moUpdateSubscription.unsubscribe();
	}


    /**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandler(event: KeyboardEvent): void
	{
		if (event.key === "Enter")
		{
			this.validateCode();
		}
	}


	/**
	 *
	 */
    public validateCode()
	{
		this.displayErrorCode = false;
		this.userService.checkMachineCode(this.code, this.fingerPrint);
	}


	/**
	 *
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 *
	 */
    public canValidCheckMachine()
	{
		let result = false;

		if (this.code && this.code.length === 4 && ('1' + this.code === Number('1' + this.code).toString()))
		{
			result = true;
		}

		return result;
	}


	/**
	 *
	 */
	public generateNewMachineCode()
	{
		this.userService.regenerateCodeMachine();
	}

}

