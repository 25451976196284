<mat-dialog id="connection_application_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Erreur: Présence d'un bloceur de publicité
		</div>
	</div>
	<div class="dialog_content">
		<label>Le fonctionnement de la plate-forme nécessite la désactivation de bloceurs de publicité (type AdBlock). Veuillez désactiver ces outils sur votre navigateur et cliquer sur OK afin d'actualiser votre page.</label>
	</div>
	<div class="dialog_footer">
		<button class="validate_button" mat-raised-button color="accent" (click)="validate()">OK</button>
	</div>
</mat-dialog>
