// Angular
import { Injectable }	 			from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { EpreuveHttpService } 		from './http/epreuveHttp.service';
import { Matiere } 					from '../classes/matiere.class';
import { Comment }					from '../classes/comment.class';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()


/**
 *
 */
export class EpreuveService
{

	/**
	 *
	 */
    constructor(private epreuveHttpService: EpreuveHttpService,
				public socketService: SocketService,
				private userData: UserData)
    {

    }


    /**
     * Add new epreuve in database from Matiere object given in parameter
     * Parents ids are given by the parentPath parameter
     */
    public addEpreuve(epreuve: Matiere, parentPath: any[] = [])
    {
		let datas 		= epreuve.toJson();
		datas.idparents = parentPath;

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args':
						{
							type: 'epreuve',
							node: datas
						}
					};
				socket.emit('create', data);
			}
		});
    }


	/**
	 * Update epreuve in database from Matiere object given in parameter
	 * @param dataEpreuve
	 */
	public editEpreuve(dataEpreuve: Matiere)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args':
						{
							type: 'epreuve',
							node: dataEpreuve.toJson()
						}
					};
				socket.emit('update', data);
			}
		});
	}


    /**
     * Get all epreuves in database relative to user account and parameters given:
     *
     *  -idEpreuve: if this parameter is defined, return the Matiere object corresponding to the value id
     *  -idExamen:  if this parameter is defined (and if the parameter idEpreuve is not), return the Matiere
     *              objects wich the examen parent matches the value id.
     *
     * Return a list of Matiere objects
     */
	public getEpreuves(idEpreuve: string, idExamen: string): Observable<Matiere[]>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let observable = Observable.create((observer: any) =>
		{
			this.epreuveHttpService.getEpreuves(idEpreuve, idExamen).subscribe(res =>
			{
				let resJson				= res.json();
				let examens: Matiere[]	= [];

				if (resJson.data && resJson.data.rows)
				{
					for (let row of resJson.data.rows)
					{
						if (row)
						{
							examens.push(Matiere.fromJsonObject(row));
						}
					}
				}
				observer.next(examens);
			});
		});

		return observable;
	}
}


