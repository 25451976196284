import { Component, ViewEncapsulation, Inject, EventEmitter } from '@angular/core';

import { MatDialogRef }		from '@angular/material';
import { MAT_DIALOG_DATA }	from '@angular/material';

import * as _ from 'underscore';


@Component({
    selector:		'edit-file-name-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'editFileNameModal.component.html',
    styleUrls:		['./editFileNameModal.component.scss']
})


export class EditFileNameModalComponent
{
	public nameFile: 				string 	= '';
	public isCurrentFileNameValid: 	boolean = true;


    constructor(public dialogRef:	MatDialogRef<EditFileNameModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any)
	{
		
		if (this.data && this.data.subject && this.data.file && this.data.format)
		{
			const splittedOriginalName	= this.data.file.name.split('.');
			let prefixName				= '';
			let suffixName				= '';

			if (splittedOriginalName.length === 1)
			{
				prefixName += splittedOriginalName[0];
			}
			else
			{
				for (let i = 0; i < splittedOriginalName.length - 1; i++)
				{
					prefixName += splittedOriginalName[i];
				}
			}

			this.nameFile = prefixName;

			switch(this.data.format)
			{
				default:
				break;
					
				case 'html':
					this.nameFile	+= '.html';
					suffixName		= '.html';
				break;
			}

			let nameExisting	= this._isFileNameExisting();
			let compteur		= 0;

			while (nameExisting === true)
			{
				compteur++;
				this.nameFile	= prefixName + '(' + compteur + ')' + suffixName;
				nameExisting	= this._isFileNameExisting();
			}
		}
	}


	/**
	 * 
	 * @param event 
	 */
	public onChangeName(event: any)
	{
		this.nameFile				= event;
		const nameExisting			= this._isFileNameExisting();
		this.isCurrentFileNameValid = nameExisting === false;
//		const elementsName			= this.nameFile.split('.');
//		let tempName				= this.nameFile;
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close();
	}


	/**
	 * 
	 */
	public validate()
	{
		const elementsName = this.nameFile.split('.');

		if (elementsName[elementsName.length - 1] !== this.data.format)
		{
			this.nameFile += '.' + this.data.format;
		}

		this.dialogRef.close(this.nameFile);
	}


	/**
	 * 
	 */
	private _isFileNameExisting()
	{
		let result			= false;
		const elementsName	= this.nameFile.split('.');
		let tempName		= this.nameFile;

		if (elementsName[elementsName.length - 1] !== this.data.format)
		{
			tempName += '.' + this.data.format;
		}

		if (this.data.subject && this.data.subject.pj)
		{
			const find = _.find(this.data.subject.pj, (pj: any) => { return pj.name === tempName; });

			if (find !== undefined)
			{
				result = true;
			}
			else
			{
				result = false;
			}
		}

		return result;
	}
}

