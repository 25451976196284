import { Injectable, EventEmitter } from '@angular/core';
import { GenericData }				from './generic-data';
import { UserData }					from './user-data';
import { Piece }					from '../classes/piece.class';
import { User}						from '../classes/user.class';
import { Message }					from '../classes/message.class';
import { SocketService }			from '../services/socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';

import * as _ from 'underscore';


@Injectable()


/**
 * Class UserData
 */
export class ChatData extends GenericData
{
	private _idNewRoom:			string	= '';
	private _rooms:				Piece[]	= [];
	private _selectedRoom:		Piece	= null;
	private _users:				User[]	= [];
	private _userByRoom:		any[][]	= [];
	private _messagesByRoom:	any[]	= [];
	private _haveChatData:		boolean	= false;
    public socket:				any;

	private _isRoomOk:		boolean	= false;
	private _isRoomInfoOk:	boolean	= false;


	/**
	 *
	 */
	constructor(		public	userData:		UserData , 		public socketService:			SocketService)
	{
		super();
	}


	// ---------------------------------------
	// Getters
	// ---------------------------------------
	get idNewRoom():				string	{ return this._idNewRoom; }
	get users():					User[]	{ return this._users; }
	get selectedRoom():				Piece	{ return this._selectedRoom; }
	get userByRoom():				any[]	{ return this._userByRoom; }
	get messagesByRoom():			any[]	{ return this._messagesByRoom; }
	get haveChatData():				boolean	{ return this._haveChatData; }
	get isRoomOk():					boolean	{ return this._isRoomOk; }
	get isRoomInfoOk():				boolean	{ return this._isRoomInfoOk; }


	/**
	 * Petit truc spécial, on enregistre avec l'id en clef pour manipuler plus facilement
	 * mais on push à la bourrin le retour pour faciliter l'utilisation dans les templates
	 */
	get rooms(): Piece[]
	{

		let buffRoom: Piece[]	= [];
		let numberOfRoom		= 0;


		for (let idRoom in this._rooms)
		{
			buffRoom.push(this._rooms[idRoom]);
			numberOfRoom++;
		}


		// Si y'a des rooms on l'indique;
		if (numberOfRoom > 0)
		{
			this.haveChatData = true;
		}

		return buffRoom;
	}


	public getMessagesByRoomId(idRoom: any): any[]
	{
		if (this._rooms[idRoom] && this._rooms[idRoom].messages)
		{
			return this._rooms[idRoom].messages;
		}
	}


	public getUser(idUser: any) { return this._users[idUser]; }



	public getUserIcon(idUser: any)
	{
		let user = this._users[idUser];
		let sName: string = '';

		if (user)
		{
			sName = user.nom.charAt(0);
			sName += user.prenom.charAt(0);
		}
		return sName;

	}


	public setUserState(value: any, key: any)
	{
		if (this._users[key])
		{
			if (value === 'online')
			{
				this._users[key].status = true;
			}
			else
			{
				this._users[key].status = false;
			}
			this._users[key].lastDateCo = Date.now().toString();
		}
	}


	public getUserState(userList: any)
	{
		let me		= this.userData.getUserId();
		let sReturn = false;
		let oReturn = null;

		if (userList && userList.length > 0)
		for (let item of userList)
			{
				if (item.mail !== me)
				{
					oReturn = this.getUser(item.mail);
					if (oReturn)
					{
						sReturn = oReturn.status;
					}
				}
			}

		return sReturn;
	}


	public getUserColor(userList: any)
	{

		let me		= this.userData.getUserId();
		let sReturn = '#ffffff';
		let oReturn = null;

		if (userList && userList.length > 0)
		{
			for (let item of userList)
			{
				if (item.mail !== me)
				{
					oReturn = this.getUser(item.mail);
					if (oReturn)
					{
						sReturn = oReturn.color;

					}
				}
			}
		}
		return sReturn;
	}


	public getLastname(userList: any)
	{
		let me		= this.userData.getUserId();
		let sReturn = '';
		let oReturn = null;

		if (userList && userList.length > 0)
		{
			for (let item of userList)
			{
				if (item.mail !== me)
				{
					oReturn = this.getUser(item.mail);
					if (oReturn)
					{
						sReturn = oReturn.prenom;
					}
				}
			}
		}


		return sReturn;
	}


	// ------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Setters
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------
	set idNewRoom(idNewRoom)		{ this._idNewRoom		= idNewRoom; }
	set rooms(rooms)				{ this._rooms			= rooms; }
	set selectedRoom(selectedRoom)	{ this._selectedRoom	= selectedRoom; }
	set users(users)				{ this._users			= users; }
	set userByRoom(userByRoom)		{ this._userByRoom		= userByRoom; }
	set messagesByRoom(userByRoom)	{ this._messagesByRoom	= userByRoom; }
	set haveChatData(haveChatData)	{ this._haveChatData	= haveChatData; }

	/**
	 * Quand on a les data, si les 2 sont ok on precess, a la fin du process haveDatachat = true;
	 */
	set isRoomOk(isRoomOk)
	{
		this._isRoomOk = isRoomOk;
		if (this._isRoomOk && this._isRoomInfoOk)
		{
			this.processChat();
		}
	}


	/**
	 * Quand on a les data, si les 2 sont ok on precess, a la fin du process haveDatachat = true;
	 */
	set isRoomInfoOk(isRoomInfoOk)
	{
		this._isRoomInfoOk = isRoomInfoOk;
		if (this._isRoomOk && this._isRoomInfoOk)
		{
			this.processChat();
		}
	}


	// ---------------------------------------
	// Spécial
	// ---------------------------------------
	/**
	 * Affecte une room via l'id
	 */
	public setRoom(idRoom: number, room: Piece) { this._rooms[idRoom] = room; }

	public deleteRoom(idRoom: number)
	{
		if (this._rooms[idRoom])
		{
			delete this._rooms[idRoom];

			if (this._selectedRoom && this._selectedRoom.id === idRoom.toString())
			{
				this._selectedRoom = undefined;
			}
		}
	}

	public setUser(idUser: any, user: any)
	{
		this._users[idUser] = User.fromJsonObject(user);
	}


	/**
	 * Pour une room donnée on va ajouter un utilisateur (apellée a la reception des messages lus par room)
	 */
	public setUserByRoom(idRoom: any, userId: any)
	{
		if (typeof this._userByRoom[idRoom] === undefined || typeof this._userByRoom[idRoom] === 'undefined')
		{
			this._userByRoom[idRoom] = [];
			this._userByRoom[idRoom].push(userId);
		}
		else
		{
			this._userByRoom[idRoom].push(userId);
		}
	}


	/**
	 * TODO meme qu'en haut .. voir laquelle est utilisée pr delet l'autre
	 */
	public setUserByRoom2(idRoom: any, userId: any)
	{

		if (userId && userId !== this.userData.getUserId())
		{
			let userInfo = this.userData.getUser(userId);
			this._rooms[idRoom].description = userId;
			if (userInfo && userInfo.infos && userInfo.infos.nom)
			{

				this._rooms[idRoom].displayName = userInfo.infos.nom + ' ' + userInfo.infos.prenom;
				this._rooms[idRoom].users.push(userInfo);
			}
			else if (userInfo)
			{
				this._rooms[idRoom].users.push(userInfo);
				this._rooms[idRoom].displayName = userId;
			}
			else
			{
				this._rooms[idRoom].displayName = userId;
			}

		}
	}



	/**
	 * Voir si elle est utile
	 */
	public setLastMessageIdByRoom(idRoom: any, idMessage: any)
	{
			this._rooms[idRoom].lastId = idMessage;

	}
	public setLastMessageReadedByMeByRoom(roomId: any, messageTicks: any)
	{
		this._rooms[roomId].lastIdReadedByMe = messageTicks;
	}
	public setLastMessageReadedByHimByRoom(roomId: any, messageTicks: any)
	{
		this._rooms[roomId].lastIdReadedByHim = messageTicks;
	}


	public setLastMessageReceivedByMeByRoom(roomId: any, messageTicks: any)
	{
		this._rooms[roomId].lastIdReceivedByMe = messageTicks;
	}
	public setLastMessageReceivedByHimByRoom(roomId: any, messageTicks: any)
	{
		this._rooms[roomId].lastIdReceivedByHim = messageTicks;
	}

	public setFirstMessageByRoom(roomId: any, messageTicks: any)
	{
			this._rooms[roomId].firstId = messageTicks;
	}


	getTotalCounter()
	{
		let totalReturn = 0;

		let arrayOfKey = [];

		for (let key in this._rooms)
		{
			arrayOfKey.push(key);
		}
		let key2: any;
		for (key2 of arrayOfKey)
		{
			totalReturn += this._rooms[key2].counter;
		}
		return totalReturn;
	}


	/**
	 * bon hein, les compteurs quoi !
	 */
	public incrementCounterByRoom(idRoom: any)
	{
		this._rooms[idRoom].counter += 1;

	}
	public setCounterByRoom(idRoom: any, counter: any)
	{
		// moins 1 car il y a le message systeme au début ........
		this._rooms[idRoom].counter = counter;
	}


	/**
	 * est apellé quand on ouvre une room, permet également d'envoyer la notif de la lecture du message
	 */
	public setMessagesByRoom(idRoom: any, messages: any)
	{
		let arrayMessage = _.toArray(messages);
		let indice = 0;


		let arrayId = [];

		for(let id in messages)
		{
			arrayId.push(id);
		}

		// ---------------------------------------
		// Reconstruction d'un tableau template friendly
		// ---------------------------------------
		for (let key in messages)
		{
			arrayMessage[indice].key	= new Date(messages[key].dateticks);
			arrayMessage[indice].id		= arrayId[indice];
			indice++;
		}

		let nIndice = 0;

		for (let keyDate of arrayMessage)
		{
			let globalDate = keyDate.key;

			// Pour éviter les message systemes
			if (keyDate.val && keyDate.sub !== 'SYS')
			{
				let values = JSON.parse(keyDate.val);

				let eScocketMessage;
				let oMessage = new Message();

				// eScocketMessage = JSON.parse(values.message);
				oMessage.ticks		= keyDate.id;
				oMessage.content	= values.args.message;
				oMessage.userId		= values.name;
				oMessage.date		= keyDate.dateticks;

				if (this._rooms && this._rooms[idRoom])
				{
					if (oMessage)
					{
						this._rooms[idRoom].setMessageById(oMessage, keyDate.id);
					}

					if (nIndice === 0 && this._rooms[idRoom].lastDate < keyDate.dateticks)
					{
						this._rooms[idRoom].lastId		= keyDate.id;
						this._rooms[idRoom].lastDate	= keyDate.dateticks;
					}
					if (this._rooms[idRoom].firstDate > keyDate.dateticks || !this._rooms[idRoom].firstId)
					{
						this._rooms[idRoom].firstId		= keyDate.id;
						this._rooms[idRoom].firstDate	= keyDate.dateticks;
					}
				}

				nIndice++;
			}
		}

		if (this._rooms[idRoom] && this._rooms[idRoom].lastId && this._rooms[idRoom].lastIdReadedByMe && this._rooms[idRoom].lastId !== this._rooms[idRoom].lastIdReadedByMe)
		{
			this._rooms[idRoom].lastIdReadedByMe = this._rooms[idRoom].lastId;

			// Ici on est dans le cas de message non lu, on envoie donc un readed avec l'id en question
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'msg.read',
							'args':
							{
								bucket:		'rooms',
								notifier:	['*room::' + idRoom + '::priv'],
								key:		idRoom,
								sub:		'M',
								when:		this._rooms[idRoom].lastIdReadedByMe
							}
						};

					socket.emit('update', data);
				}
			});
		}
		this.selectedRoom = null;
		this.selectedRoom = this._rooms[idRoom];

	}


	public addRooms(value: any, key: any)
	{
		this._rooms[key] = value;
	}


	public setSelectedRoomBy(id: any)
	{
		this._selectedRoom = this._rooms[id];
	}


	public getUserByRoom(idRoom: any) { return this._userByRoom[idRoom];}


	/**
	 * Comme d'un coté on récup les rooms et les messages lus (et les compteurs..) une fois qu'on a toute les infos
	 * cette fonction se charge de tt mettre en ordre pr la suite
	 */
	public processChat()
	{
		for (let key in this._rooms)
		{

			let usersRoom = this.getUserByRoom(key);

			for (let user of usersRoom)
			{
				if (user !== this.userData.getUserId() && user)
				{
					let userInfo 					= this.userData.getUser(user);
					this._rooms[key].description	= user;

					if (userInfo.infos.nom)
					{
						this._rooms[key].displayName = userInfo.infos.nom + ' ' + userInfo.infos.prenom;
					}
					else
					{
						this._rooms[key].displayName = user;
					}
				}
			}
		}


		// on et les booleans pr ne pas repasser ds la fonction
		this._isRoomOk		= false;
		this._isRoomInfoOk	= false;
		this._haveChatData	= true;
	}


	setSelectedRoomByMail(mail: any)
	{
		let found = false;


		let arrayId = [];


		for (let key in this._rooms)
		{
			arrayId.push(key);
		}

		let key2: any;
		for (key2 of arrayId)
		{
			for (let user of this._rooms[key2].users)
			{
				if (user.mail === mail)
				{
					found = true;
					this.setSelectedRoomBy(key2);
				}
			}

		}
		let userId = this.userData.getUserId();

		if (!found && mail !== userId)
		{

			// ---------------------------------------
			// Ici il va falloir créer une room ...
			// ---------------------------------------
			this.userData.userForChatInvite = this.userData.getUser(mail);
			let eSocketWhat: SocketWhat.request;

			// Lib auto permet d'avoir un nom automatique en fonction de la personne avec qui on communique.
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					this.socket = socket;
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': userId,
							'what': eSocketWhat,
							'cmd': 'room',
							'args':
							{
								'libAuto': 		true,
								'owner': 		userId,
								'isPublic': 	false,
								'isReadOnly': 	false
							}
						};
					socket.emit('create', data);
				}
			});
		}
	}


	public canGetChatData()
	{
		return this._haveChatData;
	}


}

