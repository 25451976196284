import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()

/**
 *
 */
export class BaremeService
{
    constructor(private socketService: SocketService,
                private userData: UserData)
    {

    }


    public createBareme(pjDatas: any)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.mail,
						'what': SocketWhat.info,
						'cmd': 'bareme',
						'args':
						{
							datas:			pjDatas
						}
					};
				socket.emit('create', data);
			}
		});
	}


	public getBaremes()
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'allBaremes',
					'args': {  }
				};
				socket.emit('read', data);
			}
		});
    }



	/**
	 * Update epreuve in database from Matiere object given in parameter
	 * @param dataEpreuve
	 */
	public editBareme(psId: string, dataEpreuve: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'bareme',
					'args': {
						datasToUpdate: {
							datas: dataEpreuve
						},
						baremeId: psId
					}
				};

			socket.emit('update', data);
			}
		});
	}


//	/**
//	 * Update epreuve in database from Matiere object given in parameter
//	 * @param dataEpreuve
//	 */
//	public associateBareme(psBaremeId: string, psEpreuveId: string, psActivePart: string, psActiveQuestion: string, psSubjectType: string)
//	{
//		this.socketService.getSocketConnection().subscribe((socket) =>
//		{
//			if (socket)
//			{
//				let data: ISocketData =
//					{
//						'iam': 'c-c',
//						'name': this.userData.getUserId(),
//						'what': SocketWhat.info,
//						'cmd': 'baremeToQuestion',
//						'args':
//						{
//							datasToUpdate:
//							{
//								epreuveId:		psEpreuveId,
//								activePart:		psActivePart,
//								activeQuestion: psActiveQuestion,
//								subjectType: psSubjectType,
//							},
//							baremeId: psBaremeId
//						}
//					};
//				socket.emit('create', data);
//			}
//		});
//	}

}

