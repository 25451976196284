import { Injectable, EventEmitter } from '@angular/core';

// import { Storage } from '@ionic/storage';

@Injectable()


/**
 * Class UserData
 */
export class GenericData
{


	public globalEmitter: EventEmitter<any> = new EventEmitter<any>();
	public globalEmitter2: EventEmitter<any> = new EventEmitter<any>();


	/**
	 *
	 */
	constructor()
	{
	}


	/**
	 * 
	 */
	public getStorage(key: any)
	{
		let value = localStorage.getItem(key);

		if (!value) { return; }

		// assume it is an object that has been stringified
		if (value[0] === "{" || value[0] === "[")
		{
			value = JSON.parse(value);
		}

		return value;
	}


	/**
	 * 
	 */
	public removeStorage(key: any)
	{
		localStorage.removeItem(key);
	}
	
	
	/**
	 * 
	 */
	public store(key: any, data: any)
	{
//		if (!key || !data) { return; }

		if(typeof(data) === "object")
		{
			data = JSON.stringify(data);
		}

		localStorage.setItem(key, data);
	}



}

