import { Injectable }	from '@angular/core';
import { Http }			from '@angular/http';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }	from './dataHttp.service';
import { Examen }			from '../../classes/examen.class';
import { UserData }			from '../../providers/user-data';


@Injectable()


export class ExamenHttpService extends DataHttpService
{
	protected sToken:			string = 'idexamen';
	protected sParentToken:		string = 'idsession';
	
	
    constructor(protected http: Http, protected userData: UserData)
    {
		super(http, userData, CreationHttpService.getPathByWebServiceName('examen'));
    }


    /**
     * Add new examen in database from Examen object given in parameter
     */
    public addExamen(examen: any, parentPath: any[] = [])
    {
        examen.path = parentPath;
        return this.add(null, [], examen);
    }


    /**
     * Get all examens in database relative to user account and parameters given:
     * 
     *  -cascade:   if false, return only examens objects, if true return Examens object with childs 
     *              (epreuves) in each objects
     *  -idExamen: if this parameter is defined, return the Examen object corresponding to the value id
     *  -idSession:    if this parameter is defined (and if the parameter idExamen is not), return the Examen
     *              objects wich the session parent matches the value id.
     *
     * Return a list of Examen objects
     */
	public getExamens(cascade: boolean, idExamen: string, idSession: string)
	{

		let cascadeStr		= cascade ? (cascade === true ? 'true' : 'false') : 'false';
		this.jOptionToken	= { "cascade": cascadeStr };

		return this.get(idExamen, idSession);
	}

}


