// Angular
import { Injectable }				from '@angular/core';
import { Http, Headers, Response }	from '@angular/http';
import {Observable}					from 'rxjs/Rx';
// Noeptec
import { Task }						from '../model/task';
import { ISocketData, SocketWhat }	from '../../../models/message.model';
import { SocketService }			from '../../../services/socket.service';
import { UserData }					from '../../../providers/user-data';

@Injectable()


/**
 *
 */
export class TaskService
{
	public tasks:		Task[] = [];
	public urlTask:		string = 'http://192.168.0.24:3004/tasks';
	public socket:		any;
	/**
	 *
	 */
	constructor(
		public socketService: SocketService,
		public userData: UserData,
		public http: Http
	)
	{

	}


	/**
	 * retourne la liste des taches
	 */
	public getTasks()
	{
		return new Promise(resolve =>
		{

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			this.http.get(this.urlTask).map(res => res.json()).subscribe(data =>
			{
				let task: Task;
				for (task of data)
				{
					this.tasks.push(new Task(task.id, task.name, task.description, task.status, task.priority, task.dateCreation, task.dateUpdate, task.points));
				}
				resolve(this.tasks);
			});
		});
	}


	/**
	 *
	 */
	public removeTask(taskId: number)
	{

		// ---------------------------------------
		// On remove la task du tableau
		// ---------------------------------------
		let array: Task[]	= this.tasks.filter(item => { return item.id !== taskId });
		this.tasks			= array;


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		// Création de l'url de suppression
		let url = this.urlTask + '/' + taskId;
		
		return this.http.delete(url);

	}


	/**
	 *
	 */
	public updateTask(task: Task)
	{
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		let credentials =
			{
				"id":				task.id,
				"name":				task.name,
				"description":		task.description,
				"status":			task.status,
				"dateCreation":		task.dateCreation,
				"dateUpdate":		task.dateUpdate,
				"priority":			task.priority,
				"points":			task.points
			};

		// ---------------------------------------
		// Si la tache est nouvelle on l'enregistre, sinon on met à jour
		// ---------------------------------------

		// ADD
		if (task.newTask)
		{
			task.newTask = false;
			this.tasks.push(task)
			let sUserId = this.userData.getUserId();
			let eSocketWhat: SocketWhat.request;

			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					this.socket = socket;

					let data: ISocketData =
						{
							'iam':	'c-c',
							'name':	sUserId,
							'what':	eSocketWhat,
							'cmd':	'tasks',
							'args': { tasks: credentials }
						};

					socket.emit('create', data);
				}
			});

//			return this.http.post(this.urlTask, JSON.stringify(credentials), { headers: headers }).map((res: Response) => res.json());
		}
		else
		{
		// MAJ
			// Mise à jours de la liste des taches
			for (let nIndice = 0; nIndice < this.tasks.length; nIndice++)
			{
				if (this.tasks[nIndice].id === task.id)
				{
					this.tasks[nIndice] = task;
				}
			}

			return this.http.put(this.urlTask + '/' + task.id, JSON.stringify(credentials), { headers: headers }).map((res: Response) => res.json());
		}
	}


	/**
	 *
	 */
	public dispatchPromise(callback: any)
	{
		let promise = new Promise(function (resolve)
		{
			resolve(callback());
		});

		return promise;
	}
}
