import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA }            from '@angular/material';

import { AdminData }			 from '../../../../providers/admin-data';
import { AdminService }          from '../../../../services/admin.service';


import * as _ from 'underscore';


@Component({
    selector:		'edition-processus-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'editionProcessusModal.component.html',
    styleUrls:		['./editionProcessusModal.component.scss']
})


/**
 * 
 */
export class EditionProcessusModalComponent implements OnInit, OnDestroy
{

    public itemSelected:		string	= 'infos';
    public titleModal:			string	= 'Edition scénario';
    public titleValidateButton: string	= 'Ajouter un scénario';
    public objProcess:			any		= {};


    /**
     * 
     * @param dialogRef 
     * @param adminService 
     * @param adminData 
     * @param data 
     */
    constructor(public dialogRef:		MatDialogRef<EditionProcessusModalComponent, any>,
                public adminService:	AdminService,
                public adminData:		AdminData,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
		this.objProcess =
		{
			id:				this.adminData.scenariosAvailable.length,
			title:			'',
			description:	'',
			steps:			[]
		};
	}
	

    /**
     * 
     */
    public ngOnInit()
	{
    }


    /**
     * 
     */
	public ngOnDestroy()
	{

    }


    /**
     * 
     * @param rubrique 
     */
    public switch_tab(rubrique: string)
    {
        this.itemSelected = rubrique;
    }


    /**
     * 
     */
    public canValidateNewProcess()
    {
        let result = true;

        if (this.objProcess.title === undefined || this.objProcess.title === '' || this.objProcess.steps.length === 0) 
		{
            result = false;
        }

        return result;
    }
	

    /**
     * 
     */
    public validate()
    {
        let scenariosToSend = this.adminData.scenariosAvailable.concat(this.objProcess);

		let datas: any =
			{
				scenarios: JSON.stringify(scenariosToSend)
			};
		this.adminService.updateAdminParameters(datas);
        this.dialogRef.close();
    }


    /**
     * 
     */
    public close()
    {
        this.dialogRef.close();
    }
}
