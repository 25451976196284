import { Sujet }	from './sujet.class';
import { Task }	    from './task.class';
import { Comment }  from './comment.class';
import * as _		from 'underscore';


/**
 *
 * @export
 * @class Matiere
 */
export class Matiere
{


    /**
     * Creates an instance of Matiere.
     *
     * @param {string} [_time='']
     * @param {boolean} [_show=false]
     * @param {boolean} [_selected=false]
     * @param {string} [_name='']
     * @param {string} [_date_creation='']
     * @param {string} [_date_passage='']
     * @param {string} [_author='']
     * @param {string} [_responsable='']
     * @param {string} [_redaction_start='']
     * @param {string} [_redaction_end='']
     * @param {string} [_meeting_date='']
     * @param {string} [_redaction_dead_line='']
     * @param {string} [_description='']
     * @param {Sujet[]} [_sujets=[]]
     *
     * @memberOf Matiere
     */
    constructor(
        private _id:                string		= '',
        private _time:              string		= '',
        private _show:              boolean		= false,
        private _selected:          boolean		= false,
        private _name:              string		= '',
        private _filiere:           string		= '',
        private _typeEpreuve:       string		= '',
        private _coeff:				string		= '',
        private _duree:             string		= '',
        private _notation:          string		= '',
        private _point:             string		= '',
        private _dateLivraison:		string		= '',
        private _dateCreation:      Date		= null,
        private _dateLastUpdate:    string		= '',
        private _datePassage:       string		= '',
        private _author:            string		= '',
        private _responsable:       string		= '',
        private _dateStart:			string		= '',
        private _dateEnd:			string		= '',
        private _redactionStart:    string		= '',
        private _redactionEnd:      string		= '',
        private _meetingDate:       string		= '',
        private _redactionDeadLine:	string		= '',
        private _description:       string		= '',
        private _flags:             string[]    = [],
        private _sujets:            Sujet[]		= [],
        private _examenId:          string		= '',
        private _comments:          Comment[]	= [],
        private _tasks:             Task[]      = [],
        private _visible:           boolean     = true,
        private _scenario:          any         = null,
        private _maxStep:			string      = '0',
        private _scenariocptsteps:  number      = 0
    )
    {
    }


    get id()                { return this._id; }
    get time()              { return this._time; }
    get show()              { return this._show; }
    get selected()          { return this._selected; }
    get name()              { return this._name; }
    get dateCreation()      { return this._dateCreation; }
    get dateLastUpdate()    { return this._dateLastUpdate; }
    get dateStart()			{ return this._dateStart; }
    get dateEnd()			{ return this._dateEnd; }
    get datePassage()       { return this._datePassage; }
    get author()            { return this._author; }
    get responsable()       { return this._responsable; }
    get redactionStart()    { return this._redactionStart; }
    get redactionEnd()      { return this._redactionEnd; }
    get meetingDate()       { return this._meetingDate; }
    get redactionDeadLine() { return this._redactionDeadLine; }
    get description()       { return this._description; }
    get flags()             { return this._flags; }
    get sujets()            { return this._sujets; }
    get examenId()			{ return this._examenId; }
    get comments()          { return this._comments; }
    get tasks()             { return this._tasks; }
    get filiere()			{ return this._filiere; }
    get typeEpreuve()		{ return this._typeEpreuve; }
    get coeff()				{ return this._coeff; }
    get duree()				{ return this._duree; }
    get notation()			{ return this._notation; }
    get point()				{ return this._point; }
    get dateLivraison()		{ return this._dateLivraison; }
    get visible()           { return this._visible; }
    get scenario()          { return this._scenario; }
    get scenariocptsteps()  { return this._scenariocptsteps; }
    get maxStep()			{ return this._maxStep; }


    set id(psValue)                 { this._id                  = psValue; }
    set show(psValue)               { this._show                = psValue; }
    set time(psValue)               { this._time                = psValue; }
    set selected(psValue)           { this._selected            = psValue; }
    set name(psValue)               { this._name                = psValue; }
    set dateCreation(psValue)       { this._dateCreation		= psValue; }
    set dateLastUpdate(psValue)     { this._dateLastUpdate		= psValue; }
    set datePassage(psValue)        { this._dateLastUpdate      = psValue; }
    set author(psValue)             { this._author              = psValue; }
    set responsable(psValue)        { this._responsable         = psValue; }
    set dateStart(psValue)			{ this._dateStart			= psValue; }
	set dateEnd(psValue)			{ this._dateEnd				= psValue; }
    set redactionStart(psValue)     { this._redactionStart		= psValue; }
    set redactionEnd(psValue)       { this._redactionEnd		= psValue; }
    set meetingDate(psValue)        { this._meetingDate			= psValue; }
    set redactionDeadLine(psValue)	{ this._redactionDeadLine	= psValue; }
    set description(psValue)        { this._description         = psValue; }
    set flags(psValue)              { this._flags               = psValue; }
    set sujets(psValue)             { this._sujets              = psValue; }
    set examenId(psValue)			{ this._examenId			= psValue; }
    set comments(psValue)			{ this._comments            = psValue; }
    set filiere(psValue)			{ this._filiere				= psValue; }
    set tasks(psValue)              { this._tasks               = psValue; }
	set typeEpreuve(psValue)		{ this._typeEpreuve			= psValue; }
	set coeff(psValue)          	{ this._coeff				= psValue; }
	set duree(psValue)          	{ this._duree				= psValue; }
	set notation(psValue)       	{ this._notation			= psValue; }
	set point(psValue)          	{ this._point				= psValue; }
	set dateLivraison(psValue)		{ this._dateLivraison		= psValue; }
    set visible(psValue)			{ this._visible		        = psValue; }
    set scenario(psValue)			{ this._scenario		    = psValue; }
    set scenariocptsteps(psValue)	{ this._scenariocptsteps	= psValue; }
    set maxStep(psValue)			{ this._maxStep				= psValue; }


    /**
     * Création d'un objet Matiere en fonction d'un objet json donné
     * @static
     * @param {any} jsonObj
     * @returns {Matiere}
     *
     * @memberOf Matiere
     */
    public static fromJsonObject(jsonObj: any): Matiere
    {

		let result = new Matiere();

        result.time	= jsonObj.time || 0;
        result.id   = jsonObj.uuid || jsonObj.id || '';

        result.show     = (typeof(jsonObj.show)		=== 'string' && jsonObj.show      === 'true') || jsonObj.show      === true ? true : false;
        result.selected	= (typeof(jsonObj.selected)	=== 'string' && jsonObj.selected  === 'true') || jsonObj.selected  === true ? true : false;

        result.name     = jsonObj.name		|| jsonObj.libelle || '';
        result.examenId = jsonObj.examen_id	|| '';
        result.flags    = jsonObj.flags     || [];

		if (jsonObj.datas)
		{
			result.filiere				= jsonObj.datas.filiere				|| '';
			result.description          = jsonObj.datas.description			|| '';
			result.dateCreation			= jsonObj.datas.dateCreation		|| '';
			result.datePassage          = jsonObj.datas.datePassage			|| '';
			result.author               = jsonObj.datas.author				|| '';
			result.responsable          = jsonObj.datas.responsable			|| '';
			result.redactionStart       = jsonObj.datas.redactionStart		|| '';
			result.redactionEnd         = jsonObj.datas.redactionEnd		|| '';
			result.meetingDate          = jsonObj.datas.meetingDate			|| '';
			result.redactionDeadLine	= jsonObj.datas.redactionDeadline	|| '';
            result.duree				= jsonObj.datas.duree				|| '';
            result.scenariocptsteps     = jsonObj.datas.scenariocptsteps    || 0;
            result.maxStep				= jsonObj.datas.maxStep				|| 0;
            result.typeEpreuve          = jsonObj.datas.typeEpreuve			|| '';

            if (jsonObj.datas.scenario)
            {
                if (typeof(jsonObj.datas.scenario) === 'string')
                {
                    result.scenario = JSON.parse(jsonObj.datas.scenario);
                }
                else
                {
                    result.scenario = jsonObj.datas.scenario;
                }
            }
            else
            {
                result.scenario = null;
			}
        }

        result.dateCreation = result.dateCreation || jsonObj.creationdate;
        result.dateCreation = new Date(result.dateCreation);
        result.author		= result.author || jsonObj.creator;

		// ---------------------------------------
		// Initialisation des sujets contenus dans la matiere
		// ---------------------------------------
        if (jsonObj.sujets && jsonObj.sujets.length)
        {
            for (let i = 0; i < jsonObj.sujets.length; i++)
            {
                result.sujets.push(Sujet.fromJsonObject(jsonObj.sujets[i]));
            }

			result.sujets.sort(function (a, b)
                {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase

                    if (nameA < nameB)
                    {
                        return -1;
                    }
                    if (nameA > nameB)
                    {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
        }


		let date = jsonObj.creationdate || jsonObj.creation_date;
		result.dateCreation = new Date(date);

        //initialisation des commentaires contenus dans la matiere
        if (jsonObj.comments && Object.keys(jsonObj.comments).length > 0)
        {
            for (let key in jsonObj.comments)
            {
                if (jsonObj.comments[key])
                {
                    let objComment = Comment.fromJsonObject(jsonObj.comments[key]);
                    result.comments.push(objComment);
                }
            }
		}

        return result;
    }


    /**
     *
     * @param {any} info
     * @returns
     *
     * @memberOf Matiere
     */
    public extractBranchInfos(info: any)
    {
        let infoCopy = Object.assign({}, info);
        let infos: any = [];

        infoCopy.idMatiere                  = this.id;
        infoCopy.timeMatiere                = this.time;
        infoCopy.nameMatiere                = this.name;
        infoCopy.dateCreationMatiere        = this.dateCreation;
        infoCopy.datePassageMatiere         = this.datePassage;
        infoCopy.authorMatiere              = this.author;
        infoCopy.responsableMatiere         = this.responsable;
        infoCopy.redactionStartMatiere      = this.redactionStart;
        infoCopy.redactionEndMatiere        = this.redactionEnd;
        infoCopy.meetingDateMatiere         = this.meetingDate;
        infoCopy.redactionDeadLineMatiere   = this.redactionDeadLine;
        infoCopy.descriptionMatiere         = this.description;
        infoCopy.filiere			        = this.filiere;
        infoCopy.duree						= this.duree;
        infoCopy.maxStep					= this.maxStep;

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        if (this.sujets.length > 0)
        {
            for (let key in this.sujets)
            {
                infos = infos.concat(this.sujets[key].extractBranchInfos(infoCopy));
            }
        }
        else
        {
            infos.push(infoCopy);
        }

        return infos;
    }


    /**
     *
     * @param {any} matiereObject
     * @param {any} [propertyFounded={}]
     *
     * @memberOf Matiere
     */
    public updateSujetsAvailable(matiereObject: any, propertyFounded = {})
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let sujetNames = _.uniq(_.map(matiereObject, function (matiere: any) { return matiere['nameSujet']; }));
        let currentSujets;

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        this.sujets = _.reject(this.sujets, function (sujet: any) { return !sujetNames.includes(sujet.name); });

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let key in this.sujets)
		{
			currentSujets = _.where(matiereObject, { nameSujet: this.sujets[key].name });
		}
    }


    /**
     * 
     * @param paramsSelected 
     */
    public getSelectedItems(paramsSelected: any)
	{

		let result = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (paramsSelected['epreuve'] && this.id === paramsSelected['epreuve'])
		{
			result.push(this);
		}

		return result;
	}


    /**
     * 
     * @param paramsShowed 
     */
	public getShowedItems(paramsShowed: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let result = [];

		if (paramsShowed[this.id] && paramsShowed[this.id] === 'epreuve')
		{
			result.push(this);
		}

		return result;
    }
    

    /**
	 * convertion d'un objet Matiere au format Json
	 */
	public toJson()
	{
		let result: any = {
			id:				    this.id,
            type:               'epreuve',
            libelle:			this.name,
            examenId:			this.examenId,
            flags:              this.flags,
			datas:
			{
				time:				this.time,
				show:				this.show		? 'true' : 'false',
				selected:			this.selected	? 'true' : 'false',
				dateCreation:		this.dateCreation,
				datePassage:		this.datePassage,
				author:				this.author,
				responsable:		this.responsable,
				dateStart:			this.dateStart,
				dateEnd:			this.dateEnd,
				redaction_start:	this.redactionStart,
				redaction_end:		this.redactionEnd,
				meeting_date:		this.meetingDate,
				redaction_deadline: this.redactionDeadLine,
				description:		this.description,
				filiere:			this.filiere,
				typeEpreuve:		this.typeEpreuve,
				coeff:				this.coeff,
				duree:				this.duree,
				notation:			this.notation,
				point:				this.point,
                dateLivraison:		this.dateLivraison,
                scenario:           this.scenario,
                max_step:           this.maxStep,
                scenariocptsteps:   this.scenariocptsteps
			}
		};

        // initialisation des commentaires dans la structure json retournée
        if (this.comments.length > 0)
		{
			result.comments = [];
			for (let comment of this.comments)
			{
				result.comments.push(comment.toJson());
			}
		}

		return result;
	}
}
