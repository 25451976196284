import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation }             from '@angular/core';

import { MatDialogRef, MatDialog }                          from '@angular/material';
import { MAT_DIALOG_DATA }									from '@angular/material';
import { FormControl, FormBuilder, FormGroup, Validators }	from '@angular/forms';

import { UserService }		from '../../services/user.service';
import { EncryptService }   from '../../services/encrypt.service';
import { UserData }			from '../../providers/user-data';
import { WorkspaceData } 	from '../../providers/workspace-data';
import { TranslateService }	from '@ngx-translate/core';


declare const require: any;

import * as htmlEncode		from 'js-htmlencode';
import * as _				from 'underscore';

const fileSaver = require('file-saver');


@Component({
    selector:			'user-modal',
    templateUrl:		'userModal.component.html',
	    encapsulation:	ViewEncapsulation.None,
    styleUrls:			['./userModal.component.scss']
})


/**
 *
 */
export class UserModalComponent implements OnInit, OnDestroy
{
    /**
     * Name of the etablissement to add
     */
	public mailUser:		string;
	public secondMailUser:	string;
	public account:			string;
    public nomUser:			string;
    public prenomUser:		string;
    public color:			string	= '#ffffff';
	public textColor:		string	= '#000000';

	public mbIsAdmin:			boolean = false;
	public mbIsPrinter:			boolean = false;
	public mbUseSecondMailUser:	boolean = false;

    public currentPassword: string;
    public passwordUser:	string;

    public moFormUser: FormGroup;

	public moFormCtrlMailUser:			FormControl;
	public moFormCtrlAccountUser:		FormControl;
	public moFormCtrlIsAdmin:			FormControl;
    public moFormCtrlCurrenPasswd:      FormControl;
    public moFormCtrlNumTelFixe:        FormControl;
    public moFormCtrlNumTelMobile:      FormControl;
	public moFormColor:					FormControl;
    public moFormCtrlNomUser:			FormControl;
	public moFormCtrlPrenomUser:		FormControl;
	public moFormCtrlSecondMailUser:	FormControl;
    public moFormCtrlDoubleAuthType:    FormControl;
	public moFormCtrlOtpValidationCode: FormControl;
	public moFormCtrlNewProfession: 	FormControl;

    public itemSelected:					string;
    public editAccount:						boolean = false;
    public isCurrentAccount:                boolean = false;
    public displayMessagePasswordUopdated:	boolean = false;
    public displayMessageOTPValidated:      boolean = false;
    public displayMessageErrorRsa:          boolean = false;
    public displayMessageRsaValidated:      boolean = false;

    public titleModal:			string;
    public titleValidateButton: string;

    public moUpdateSubscription:	any;
    public displayQRcode:			boolean = false;

    public tempPrivateRsaKey:		any;
    public initialAuthMethod:		any;
    public doubleAuthTypes:			any		= [];
    public doubleAuthTypeSelected			= 1;

    public otpGeneratedCode:	any;
    public otpGeneratedUrl:		any;
    public otpValidationCode:	any;

    public numTelFixe:  	string = '';
    public numTelMobile:	string = '';

    public professions:		string[] = [];
    public newProfession:	string;

    public matieres:	string[] = [];
    public newMatiere:	string 	 = '';


	public mbIsNew			= false;
	public mbIsEditing		= false;
	public mbIsAdminEditing = false;

	public errorMailAvailable: boolean = false;

	public currentUserType	= 'user';
	public userTypes: any[] =
	[
		{
			value:		'user',
			libelle:	'Utilisateur'
		},
		{
			value:		'printer',
			libelle:	'Imprimeur'
		},
		{
			value:		'admin',
			libelle:	'Administrateur'
		}
	];


	/**
	 *
	 */
    constructor(public dialogRef:	    MatDialogRef<UserModalComponent, any >,
                public userService:	    UserService,
                public encryptService:  EncryptService,
                public fb:    		    FormBuilder,
				public userData:	    UserData,
				public workspaceData: 	WorkspaceData,
                public translate:       TranslateService,
				public dialog:			MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
		this.account = this.userData.account;

		translate.get('common.none2').subscribe((none: string) =>
		{
			translate.get('userModal.oneTimePassword').subscribe((oneTimePass: string) =>
			{
				this.doubleAuthTypes =
					[
						{
							value:		1,
							libelle:	none
						},
						{
							value:		3,
							libelle:	oneTimePass
						}
					];
			});
		});

		translate.get('userModal.createNewUser').subscribe((txt: string) =>
		{
			this.titleModal	= txt;
		});

		translate.get('userModal.createrUserButon').subscribe((txt: string) =>
		{
			this.titleValidateButton = txt;
		});

		this.professions	= [];
		this.matieres		= [];


		if (this.userData.isSuperviseur() === true)
		{
		/*	this.userTypes.push(
				{
					value:		'admin',
					libelle:	'Super Administrateur'
				});*/
		}

        if (data && data.edition && data.edition === true)
        {
            /**
             * Si un utilisateur est transmis on édite cet utlisateur
             * sinon on édite l'utilisateur courant
             */
            let currentUser: any;
            if (data.userSelected)
            {
				this.mbIsNew			= false;
				this.mbIsEditing		= true;
				this.mbIsAdminEditing	= false;

                this.editAccount		= true;
                currentUser				= data.userSelected;

                if (currentUser.ssoDatas && currentUser.ssoDatas.method)
                {
                    //Si method = 3 alors on est en double authentification
                    //sinon on est en mode password simple
                    this.doubleAuthTypeSelected = Number(currentUser.ssoDatas.method);
                }
            }
            else
            {
				this.mbIsNew			= false;
				this.mbIsEditing		= false;
				this.mbIsAdminEditing	= true;

                this.isCurrentAccount		= true;
                this.doubleAuthTypeSelected	= this.userData.methodAuth;
                this.editAccount			= true;

				const accountInfos = this.userData.getAccountInfo();

                if (accountInfos && accountInfos.infosUser)
                {
                    currentUser = accountInfos.infosUser;
                }
            }

            this.initialAuthMethod = this.doubleAuthTypeSelected;

            if (currentUser)
            {
				translate.get('userModal.titlePrefix').subscribe((res: string) =>
				{
                    this.titleModal	= res + currentUser.mail;
                });

				translate.get('common.validate').subscribe((res: string) =>
				{
                    this.titleValidateButton = res;
                });

				this.mailUser = currentUser.mail;

                if (currentUser.infos)
                {
                    this.nomUser				= currentUser.infos.nom;
					this.prenomUser				= currentUser.infos.prenom;
					this.secondMailUser			= currentUser.infos.secondMail;
                    this.numTelFixe				= currentUser.publics.fixe1;
					this.numTelMobile			= currentUser.publics.mobile1;
					this.mbUseSecondMailUser	= currentUser.infos.useSecondMail	=== 'true' || currentUser.infos.useSecondMail === true;
					this.mbIsAdmin				= currentUser.publics.isAdmin		=== 'true' || currentUser.publics.isAdmin	=== true;
					this.mbIsPrinter			= currentUser.publics.isPrinter		=== 'true' || currentUser.publics.isPrinter === true;

					this.currentUserType = 'user';

					if (this.mbIsAdmin === true)
					{
						this.currentUserType = 'admin';
					}
					else
					{
						if (this.mbIsPrinter === true)
						{
							this.currentUserType = 'printer';
						}
					}

                    if (currentUser.infos.color)
                    {
                        this.color = currentUser.infos.color;
                    }

                    if (currentUser.publics.professions)
                    {
                        const professions = currentUser.publics.professions;

                        if (typeof professions === 'string')
                        {
                            this.professions = JSON.parse(professions);
                        }
                        else
                        {
                            this.professions = professions;
                        }
                    }

                    if (currentUser.publics.matieres)
                    {
                        let matieres = currentUser.publics.matieres;

                        if (typeof matieres === 'string')
                        {
                            this.matieres = JSON.parse(matieres);
                        }
                        else
                        {
                            this.matieres = matieres;
                        }
                    }
                }
			}
        }
        else
        {
            //Il s'agit d'une création de compte
			this.mbIsNew			= true;
			this.mbIsEditing		= false;
			this.mbIsAdminEditing	= false;
            //Ongénère automatiquement un mot de passe valide que le createur
            //pourra quand même modifier s'il le souhaite
            this.generateRandomPassword();
        }

        this.itemSelected = 'infos';

        this.moFormCtrlMailUser			    = this.fb.control({value: this.mailUser, disabled: this.editAccount}, [Validators.required, Validators.email, this.isMailFree.bind(this)]);
		this.moFormCtrlAccountUser			= this.fb.control({value: this.account, disabled: true});
		this.moFormCtrlCurrenPasswd			= this.fb.control(this.currentPassword);
        this.moFormCtrlNumTelFixe		    = this.fb.control(this.numTelFixe);
        this.moFormCtrlNumTelMobile		    = this.fb.control(this.numTelMobile);
        this.moFormCtrlNomUser			    = this.fb.control(this.nomUser, Validators.required);
		this.moFormCtrlPrenomUser		    = this.fb.control(this.prenomUser, Validators.required);
		this.moFormCtrlSecondMailUser		= this.fb.control({value: this.secondMailUser, disabled: false}, Validators.email);
		this.moFormCtrlIsAdmin		    	= this.fb.control(this.mbIsAdmin);
        this.moFormCtrlDoubleAuthType       = this.fb.control(this.doubleAuthTypeSelected);
        this.moFormCtrlOtpValidationCode    = this.fb.control(this.otpValidationCode);
		this.moFormColor					= this.fb.control(this.color);
		this.moFormCtrlNewProfession		= this.fb.control(this.newProfession);


        this.moFormUser = this.fb.group(
		{
			mail:		        this.moFormCtrlMailUser,
			account:			this.moFormCtrlAccountUser,
			admin:				this.moFormCtrlIsAdmin,
			currentPassword:    this.moFormCtrlCurrenPasswd,
			telFixe:            this.moFormCtrlNumTelFixe,
			telMobile:          this.moFormCtrlNumTelMobile,
			nom:		        this.moFormCtrlNomUser,
			prenom:		        this.moFormCtrlPrenomUser,
			secondMail:			this.moFormCtrlSecondMailUser,
			color:		        this.moFormColor,
			doubleAuthType:     this.moFormCtrlDoubleAuthType,
			otpValidationCode:  this.moFormCtrlOtpValidationCode,
			newProfession: 		this.moFormCtrlNewProfession
		});
    }


	/**
	 *
	 */
    public ngOnInit()
	{
        this.moUpdateSubscription = this.userData.globalEmitter.subscribe((value: any) =>
		{
            if (value.length && value.length > 1 && value[0] === 'user::newPassword')
			{
				this.passwordUser = value[1];
			}

            if (value === 'OTP:codeValidated')
			{
				this.displayMessageOTPValidated = true;
			}

            if (value.length && value.length > 1 && value[0] === 'OTP:newCode')
			{
				this.otpGeneratedCode	= value[1];
				this.otpGeneratedUrl	= '';

				this.otpGeneratedUrl += 'otpauth://totp/';
				this.otpGeneratedUrl += this.mailUser;
				this.otpGeneratedUrl += '?secret=';
				this.otpGeneratedUrl += this.otpGeneratedCode;
				this.otpGeneratedUrl += '&issuer=Exatech&algorithm=SHA1';
			}

            if (value.length && value.length === 3 && value[0] === 'RSA:validate')
			{

                const dataEncoded		= value[1];
				const dataToValidate	= value[2];

				this.encryptService.decryptData(dataEncoded, this.tempPrivateRsaKey).then((decoded: any) =>
				{

					if (dataToValidate === decoded)
					{
						//On renregistre la clé RSA privée dans le localstorage
						this.displayMessageErrorRsa		= false;
						this.displayMessageRsaValidated = true;

						this.userData.setRSAPrivate(this.tempPrivateRsaKey);

						const dialogRef2 = this.dialog.open(DialogOverviewExampleDialog,
							{
								width: '600px',
								data:	{ text: 'Succès: votre clé RSA est désormais enregistrée dans votre navigateur.', title: 'Clé RSA correcte' }

							});

						dialogRef2.afterClosed().subscribe((result: any) =>
						{
						});
					}
					else
					{
						this.userData.setRSAPrivate(this.tempPrivateRsaKey);
						const dialogRef3 = this.dialog.open(DialogOverviewExampleDialog,
							{
								width: '600px',
								data:	{ text: 'Erreur: la clé RSA importée est incorrecte.', title: 'Clé RSA incorrecte' }

							});

						dialogRef3.afterClosed().subscribe((result: any) =>
						{
						});
						//On affiche un message d'érreur
						this.displayMessageErrorRsa		= true;
						this.displayMessageRsaValidated = false;
						this.tempPrivateRsaKey			= undefined;
					}

				}).catch((err: any) =>
				{
					const dialogRef3 = this.dialog.open(DialogOverviewExampleDialog,
						{
							width: '600px',
							data:	{ text: 'Erreur: la clé RSA importée est incorrecte.', title: 'Clé RSA incorrecte' }

						});

					dialogRef3.afterClosed().subscribe((result: any) =>
					{
					});
					//On affiche un message d'érreur
					this.displayMessageErrorRsa		= true;
					this.displayMessageRsaValidated = false;
					this.tempPrivateRsaKey			= undefined;
				});
			}
		});
	}


	/**
	 *
	 */
    public ngOnDestroy()
	{
		if (this.moUpdateSubscription)
		{
			this.moUpdateSubscription.unsubscribe();
		}
	}


	/**
	 *
	 * @param control
	 */
	public isMailFree(control: FormControl): any
	{
		let xReturn: any = null;

		this.errorMailAvailable = false;

		// si on est en édition, il fait laisser passer le nom actuel
		if (control && control.value)
		{
			const objUsers		= this.userData.getUsers();
			const mailsUsers	= Object.keys(objUsers);

			if (mailsUsers.includes(control.value))
			{
				this.errorMailAvailable = true;
				xReturn					= { invalid: true };
			}
		}

		return xReturn;
	}


	/**
	 *
	 * @param rubriquel
	 */
    public switch_tab(rubrique: string)
    {
        this.displayMessagePasswordUopdated = false;
        this.displayMessageOTPValidated		= false;
        this.itemSelected					= rubrique;
    }


	/**
	 *
	 */
    public validateOTP()
    {
        this.userService.validateUserOTPCode(this.mailUser, this.otpValidationCode);
    }


	/**
	 *
	 * @param password
	 */
    public handleOnValidPassword(password: any)
    {
        this.passwordUser = password;
    }


	/**
	 *
	 * @param event
	 */
    public onKeyUpMail(event: any)
    {
		if (this.mailUser)
		{
			this.mailUser = this.mailUser.toLowerCase();
		}
		else
		{
			this.mailUser = '';
		}
	}


	/**
	 *
	 * @param event
	 */
	public onKeyUpMail2(event: any)
    {
		if (this.secondMailUser)
		{
			this.secondMailUser = this.secondMailUser.toLowerCase();
		}
		else
		{
			this.secondMailUser = '';
		}
    }


	/**
	 *
	 */
    public canValidateUserModal()
    {
        let result = true;

		if (!this.mailUser || this.mailUser === '' || !this.nomUser || this.nomUser === '' || !this.prenomUser || this.prenomUser === '' || (!this.passwordUser && !this.editAccount))
		{
			result = false;
		}

		return result;
	}


    /**
     * Executed when the modal is validated and a new etablissement must be created
     */
	public validate()
	{
		//on apelle htmlEncode afin d'éviter l'injection de code
		if (this.mailUser)
		{
			this.mailUser = htmlEncode.htmlEncode(this.mailUser);
		}

		if (this.nomUser)
		{
			this.nomUser = htmlEncode.htmlEncode(this.nomUser);
		}

		if (this.prenomUser)
		{
			this.prenomUser = htmlEncode.htmlEncode(this.prenomUser);
		}

		if (this.secondMailUser)
		{
			this.secondMailUser = htmlEncode.htmlEncode(this.secondMailUser);
		}

		if (this.numTelFixe)
		{
			this.numTelFixe = htmlEncode.htmlEncode(this.numTelFixe);
		}

		if (this.numTelMobile)
		{
			this.numTelMobile = htmlEncode.htmlEncode(this.numTelMobile);
		}

		if (this.mailUser && this.mailUser !== '' && this.nomUser && this.nomUser !== '' && this.prenomUser && this.prenomUser !== '')
        {
			const jsonUser: any =
			{
				infos:
				{
					nom:			this.nomUser,
					prenom:			this.prenomUser,
					color:			this.color,
					secondMail:		this.secondMailUser,
					useSecondMail:	this.mbUseSecondMailUser
				},
				publics:
				{
					fixe1:		this.numTelFixe,
					mobile1:	this.numTelMobile,
					isPrinter:	this.mbIsPrinter,
					isAdmin:	this.mbIsAdmin
				}
			};

            //Si on passe de la double authentification à l'authentification password
            //alors on doit faire une mise à jour sur le sso
            if (this.doubleAuthTypeSelected === 1 && this.doubleAuthTypeSelected !== this.initialAuthMethod)
            {
                jsonUser.authMethod = this.doubleAuthTypeSelected;
            }

			if (!this.mbIsPrinter)
			{
				this.professions.push(this.newProfession);
				const professionsFiltered = _.filter(this.professions, (profession: any) => { return profession && profession !== ''; });

				for (let i = 0; i < professionsFiltered.length; i++)
				{
					professionsFiltered[i] = htmlEncode.htmlEncode(professionsFiltered[i]);
				}

				jsonUser.publics.professions = professionsFiltered;

				this.matieres.push(this.newMatiere);
				const matieresFiltered = _.filter(this.matieres, (matiere: any) => { return matiere && matiere !== ''; });

				for (let i = 0; i < matieresFiltered.length; i++)
				{
					matieresFiltered[i] = htmlEncode.htmlEncode(matieresFiltered[i]);
				}

				jsonUser.publics.matieres = matieresFiltered;
			}

            if (this.editAccount === true)
            {
                if (jsonUser.authMethod)
                {
                    this.userService.changeOtpMethod(this.mailUser, jsonUser.authMethod);
                }

                //Si le mot de passe a été modifié on le met à jour coté serveur
                if (this.passwordUser && (this.isCurrentAccount === false || this.currentPassword))
                {
                    const datas: any =
					{
						idUser:			this.mailUser,
						nomUser:		this.nomUser,
						prenomUser:		this.prenomUser,
						newPassword:	this.passwordUser
					};

                    if (this.currentPassword)
                    {
                        datas.oldPassword = this.currentPassword;
                    }

                    if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
                    {
                        datas.needOldPassword = false;
					}

                    this.userService.updateUserPassword(datas);
				}
                this.userService.updateUser(this.mailUser, jsonUser);
            }
            else
            {
                if (this.passwordUser)
                {
                    jsonUser.password = this.passwordUser;
                }

				jsonUser.mail = this.mailUser;
                this.userService.createNewUser(jsonUser);
            }
        }
        this.dialogRef.close();
    }


	/**
	 *
	 * @param event
	 */
    public onChangeAuthType(event: any)
    {
        this.doubleAuthTypeSelected		= event;
        this.displayMessageOTPValidated = false;

        if (this.doubleAuthTypeSelected === 3)
        {
            //Si l'iutlisateur était en simple password on génère directement
            //le QR Code
            if (this.initialAuthMethod === 1)
            {
                this.createOtpQRCode();
                this.displayQRcode = true;
            }
            else
            {
                //Sinon on affiche un bouton permettant de générer un nouveau QR Code
                this.displayQRcode = false;
            }
        }
        else
        {
            this.displayQRcode = false;
        }
    }


	/**
	 *
	 */
    public generateNewOTPCode()
    {
        this.createOtpQRCode();
        this.displayQRcode = true;
    }


	/**
	 *
	 */
    public generateRandomPassword()
    {
        this.userService.generateNewPassword(10);
    }


	/**
	 *
	 */
    public createOtpQRCode()
    {
        this.otpGeneratedCode	= undefined;
        this.otpGeneratedUrl	= undefined;

        this.userService.createUserOTPCode(this.mailUser);

	}


	/**
	 *
	 */
	public addProfession()
	{
		this.professions.push(this.newProfession);
		this.newProfession = '';
	}


	/**
	 *
	 * @param index
	 */
	public removeProfession(index: any)
	{
		this.professions.splice(index, 1);
	}


	/**
	 *
	 */
	public addMatiere()
	{
		this.matieres.push(this.newMatiere);
		this.newMatiere = '';
	}


	/**
	 *
	 * @param index
	 */
	public removeMatiere(index: any)
	{
		this.matieres.splice(index, 1);
	}


	/**
	 *
	 * @param index
	 * @param item
	 */
	public trackByProfession(index: any, item: any)
	{
		return index;
	}


	/**
	 *
	 * @param index
	 * @param item
	 */
	public trackByMatiere(index: any, item: any)
	{
		return index;
	}


	/**
	 *
	 */
	public downloadRsaKey()
	{
		window.URL = this.workspaceData.urlCreator;
		let key = this.userData.getRSAPrivate();

		if (key !== undefined)
		{
			let blob: Blob = new Blob([key], { type: 'text/plain' });
			fileSaver.saveAs(blob, 'rsaKey.exakey');
		}
	}


	/**
	 *
	 * @param event
	 */
	public importRsaKey(event: any)
	{
		if (event)
		{
			let psFile: File = event.target.files[0];

			if (psFile)
			{
				let reader = new FileReader();

				reader.onload = (error) =>
				{
					this.tempPrivateRsaKey = reader.result;
					//On génère une chaine représentant le jour actuel
					let date		= new Date();
					let bucketDate	= date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
					let encryptStr	= this.userData.getUserId() + bucketDate;

					this.userService.encryptDatasForValidation(encryptStr);

				};
				reader.readAsText(psFile);
			}
		}
	}


	/**
	 *
	 */
    public hasRsaPrivate()
    {
        return this.userData.hasRSAprivate();
    }


	/**
	 *
	 */
	public close()
	{
		this.dialogRef.close();
	}


	/**
	 *
	 * @param color
	 */
	testColor(color: any)
	{
		color = color.replace('#', '');

		var arrBuff		= new ArrayBuffer(4);
		var vw			= new DataView(arrBuff);


		vw.setUint32(0, parseInt(color, 16), false);
		var arrByte = new Uint8Array(arrBuff);

		//		return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];

		let value		= (arrByte[1] * 299 + arrByte[2] * 587 + arrByte[3] * 114) / 1000;
		let colorReturn = "#000";

		if (value < 128)
		{
			colorReturn = "#fff";
		}

		this.textColor = colorReturn;
	}


	/**
	 *
	 */
	public onChangePrint()
	{
		this.mbIsPrinter = !this.mbIsPrinter;
	}


	/**
	 *
	 * @param event
	 */
	public onChangeUserTypeSelect(event: any)
	{
		this.currentUserType = event;

		switch (event)
		{
			default:
				break;
			case 'user':
				this.mbIsAdmin		= false;
				this.mbIsPrinter	= false;
				break;
			case 'admin':
				this.mbIsAdmin		= true;
				this.mbIsPrinter	= false;
				break;
			case 'printer':
				this.mbIsAdmin		= false;
				this.mbIsPrinter	= true;
				break;
		}
	}
}

@Component({
	selector:		'dialog-overview-example-dialog',
	styleUrls:		['./dialog-overview-example-dialog.scss'],
	templateUrl:	'dialog-overview-example-dialog.html',
})


/**
 *
 */
export class DialogOverviewExampleDialog
{


	/**
	 *
	 * @param dialogRef23
	 * @param data
	 */
	constructor(
		public dialogRef23: MatDialogRef<DialogOverviewExampleDialog, any>,
		@Inject(MAT_DIALOG_DATA) public data: any)
	{

	}


	/**
	 *
	 */
	onNoClick(): void
	{
		this.dialogRef23.close();
	}

}
