// Angular
import { Injectable }	 			from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { EpreuveHttpService } 		from './http/epreuveHttp.service';
import { Event }					from '../classes/event.class';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()


/**
 * 
 */
export class EventService
{
	
	/**
	 * 
	 */
    constructor(private epreuveHttpService: EpreuveHttpService,
				public socketService: SocketService,
				private userData: UserData)
    {

    }


    /**
     * Add new epreuve in database from Matiere object given in parameter
     * Parents ids are given by the parentPath parameter
     */
    public addEvent(event: Event)
    {
		let datas = event.toJson();

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'calendar',
						'args': 
							{
								node: datas
							}
					};
				socket.emit('create', data);
			}
		});
    }


	/** 
	 * Update epreuve in database from Matiere object given in parameter
	 * @param dataEpreuve 
	 */
	public editEvent(event: Event)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'calendar',
					'args': 
						{
							node:	event.toJson()
						}
				};
			socket.emit('update', data);
			}
		});
	}


    /**
     * Get all epreuves in database relative to user account and parameters given:
     * 
     *  -idEpreuve: if this parameter is defined, return the Matiere object corresponding to the value id
     *  -idExamen:  if this parameter is defined (and if the parameter idEpreuve is not), return the Matiere
     *              objects wich the examen parent matches the value id.
     *
     * Return a list of Matiere objects
     */
	public getEvents(idNode: string)
	{
				
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
			this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'calendar',
					'args': 
						{
							node: idNode
						}
				};
			socket.emit('read', data);
			}
		});
	}
}


