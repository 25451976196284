import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType, URLSearchParams }   from '@angular/http';
import {Observable} from 'rxjs/Rx';

import { CreationHttpService }	from './creationHttp.service';
import { Task } 				from '../../classes/task.class';
import { Comment } 				from '../../classes/comment.class';
import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';

@Injectable()
export class TaskHttpService extends DataHttpService
{

    /**
	 *
	 */
	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('task'));
	}

	public getTask(sessionId: string, examenId: string = '', epreuveId: string = '')
	{
		this.jOptionToken = 
		{
			sessionId:	sessionId,
			examenId:	examenId,
			epreuveId:	epreuveId
		};

		return this.get();
	}



	public addTask(task: Task)
	{
		let data = 
		{
			task: task.toJson()
		};

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return this.add(undefined, undefined, data);
	}


	public deleteTask(task: Task)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let params: URLSearchParams = new URLSearchParams();
		params.set('task', JSON.stringify(task.toJson()));

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let options = new RequestOptions({ search: params, headers: headers });

		return this.http.get(this.getUrl() + 'delete', options);
	}
	

	public updateTask(task: Task, newDatas: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let data = 
		{
			task:	task.toJson(),
			datas:	newDatas
		};

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let credentials = 
		{
			data:	data
		};

		return this.http.post(this.getUrl() + 'update', JSON.stringify(credentials), { headers: headers });
	}

	public addTaskComment(task: Task, newComment: Comment)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let data = 
		{
			task:		task.toJson(),
			comment: 	newComment.toJson()
		};
		// ---------------------------------------
		// ToComment
		// ---------------------------------------

		let credentials = 
		{
			data:	data
		};

		return this.http.post(this.getUrl() + 'addComment', JSON.stringify(credentials), { headers: headers });
	}
}
