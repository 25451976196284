import { CreationHttpService }	from './creationHttp.service';

import { Injectable }		from '@angular/core';
import { Http, Headers }	from '@angular/http';
import {Observable} from 'rxjs/Rx';

@Injectable()

/**
 * Todo: commenter
 */
export class AuthHttpService
{

	/**
	 * Todo: commenter
	 */
	constructor(private http: Http)
	{
	}

	/**
	 * Todo: commenter
	 */
	public doLogin(username: string, password: string, fingerprint: string): Observable<any>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');


		// ---------------------------------------
		// Création d'un post pour demander un login.
		// Todo: mise en place programmation defensive!
		// ---------------------------------------
		let credentials =
		{
			username:		username,
			password:		password,
			fingerprint:	fingerprint
		};


		let apiUrl			= CreationHttpService.getAPIUrl();
		let path			= CreationHttpService.getPathByWebServiceName('auth');
		let jSonCredential	= JSON.stringify(credentials);
		let absolutePath	= CreationHttpService.absolute(apiUrl,path);


		// Todo : passer en socker
		return this.http.post(absolutePath, jSonCredential, { headers: headers });
	}


	public refreshToken(token: any): Observable<any>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + token);

		let credentials = {};

        return this.http.post(CreationHttpService.absolute(CreationHttpService.getAPIUrl(),CreationHttpService.getPathByWebServiceName('refresh'))
			, JSON.stringify(credentials), { headers: headers });
	}


	public doLoginWithToken(token: any): Observable<any>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + token);

		let credentials =
			{
				token: token
			};


		// Sale
        return this.http.post(CreationHttpService.absolute(CreationHttpService.getAPIUrl(), CreationHttpService.getPathByWebServiceName('auth'))
			, JSON.stringify(credentials), { headers: headers });
	}
}
