import { Injectable }		from '@angular/core';
import { Http, Headers }	from '@angular/http';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';
import {Observable} from 'rxjs/Rx';


@Injectable()


/**
 *
 */
export class UserHttpService extends DataHttpService
{
	protected sToken:			string = 'idsubject';
	protected sParentToken:		string = 'idepreuve';
 

	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('user'));
	}


    /**
     * Get all users in the creation app server and their rights
     */
	public getAllUsers(): Observable<any>
	{
		return this.get();
	}


    /**
     * Update informations and rights for a set of users given
     */
	public updateUser(dataUser: any): Observable<any>
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());
		let credentials = 
		{
			data: dataUser
		};
		// Sale
		return this.http.post(this.getUrl() + 'update', JSON.stringify(credentials), { headers: headers });
	}


	/**
	 * Get current user datas from database
	 */
	public getCurrentUser(): Observable<any>
	{
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		return this.http.get(this.getUrl() + 'getCurrent', { headers: headers });
	}

    /**
     * Update informations and rights for a set of users given
     */
	public updateCurrentUser(dataUser: any): Observable<any>
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());
		let credentials = 
		{
			data: dataUser
		};
		// Sale
		return this.http.post(this.getUrl() + 'updatecurrent', JSON.stringify(credentials), { headers: headers });
	}


}
