import { CommonModule }							from '@angular/common';
import { NgModule, ApplicationRef }				from '@angular/core';
import { FormsModule, ReactiveFormsModule  }	from '@angular/forms';
import { SearchBar }							from './search-bar.component';
import { SearchBarService }						from './services/search-bar.service';
import { PopoverSearchFilter }					from './popover-search-filter/popover-search-filter.component';


@NgModule({
	imports:
	[
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations:		[SearchBar, PopoverSearchFilter],
	entryComponents:	[SearchBar, PopoverSearchFilter],
	providers:			[SearchBarService],
	exports:			[SearchBar]
})


/**
 *
 */
export class SearchBarModule
{

	constructor()
	{
	}
}
