// Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { ExamenHttpService }		from './http/examenHttp.service';
import { Examen }					from '../classes/examen.class';
import { Comment }					from '../classes/comment.class';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()
export class ExamenService
{


	/**
	 *
	 */
	constructor(private examenHttpService: 	ExamenHttpService,
				public socketService: 		SocketService,
				private userData: 			UserData)
	{

	}


    /**
     * Add new examen in database from Examen object given in parameter
     * Parents ids are given by the parentPath parameter
     */
	public addExamen(examen: Examen, parentPath: any[] = [])
	{
		let datas 		= examen.toJson();
		datas.idparents = parentPath;

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args': 
						{
							type: 'examen',
							node: datas
						}
					};
				socket.emit('create', data);
			}
		});
	}


	/**
     * Update examen in database from Examen object given in parameter
     */
	public editExamen(dataExamen: Examen)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'node',
					'args': 
					{
						type: 'examen',
						node:	dataExamen.toJson()
					}
				};
			socket.emit('update', data);
			}
		});
	}


    /**
     * Get all examens in database relative to user account and parameters given:
     *
     *  -cascade:   if false, return only examens objects, if true return Examens object with childs
     *              (epreuves) in each objects
     *  -idExamen: if this parameter is defined, return the Examen object corresponding to the value id
     *  -idSession:    if this parameter is defined (and if the parameter idExamen is not), return the Examen
     *              objects wich the session parent matches the value id.
     *
     * Return a list of Examen objects
     */
	public getExamens(cascade: boolean, idExamen: string, idSession: string, callback: (result: any) => void)
	{

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'node.info',
					'args': 
					{
						node: 		idSession,
						nodeOption: 'wc',
					}
				};
				socket.emit('read', data);
			}
			else
			{
				this.examenHttpService.getExamens(cascade, idExamen, idSession).subscribe(res =>
				{
					let resJson				= res.json();
					let examens: Examen[]	= [];

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					if (resJson.data && resJson.data.rows)
					{
						for (let row of resJson.data.rows)
						{
							if (row)
							{
								examens.push(Examen.fromJsonObject(row));
							}
						}
					}
					callback(examens);
				});
			}
		});
	}
}


