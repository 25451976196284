import { Session }				from './session.class';
import { WorkspaceDataService } from '../providers/workspaceData.service';
import { Component }			from './component';

import * as _ from 'underscore';


/**
 *
 * @export
 * @class Etablissement
 */
export class Etablissement extends Component
{

	public moWorkData:	WorkspaceDataService = null;
	public _visible:	boolean = true;


	/**
     * Création d'un objet Etablissement en fonction d'un objet json donné
     * @static
     * @param {any} jsonObj
     * @returns {Etablissement}
     *
     * @memberOf Etablissement
     */
	public static fromJsonObject(jsonObj: any): Etablissement
	{
		// Initialisation de l'identifiant et du nom
		let oEtablissement = new Etablissement();

		oEtablissement.id	= jsonObj.id 	|| jsonObj.uuid 	|| 0;
		oEtablissement.name = jsonObj.name 	|| jsonObj.libelle 	|| '';

		if (jsonObj.datas)
		{
			oEtablissement.description = jsonObj.datas.description;
		}

		oEtablissement.author	= jsonObj.createur		|| jsonObj.creator;
		let date				= jsonObj.creationdate	|| jsonObj.creation_date;

		oEtablissement.dateCreation = new Date(date);

		if (jsonObj.lastupdatedate)
		{
			oEtablissement.dateModification = new Date(jsonObj.lastupdatedate) || new Date(jsonObj.updateDate);
		}

		// ---------------------------------------
		// Initialisation de l'état dze l'etablissement
		// show: l'établissement est ouvert dans l'arborescence
		// selected: l'établissement est selectionné dans l'arborescence
		// ---------------------------------------
		oEtablissement.show		= (typeof (jsonObj.show)		=== 'string' && jsonObj.show.toLowerCase()		=== 'true') || jsonObj.show		=== true ? true : false;
		oEtablissement.selected = (typeof (jsonObj.selected)	=== 'string' && jsonObj.selected.toLowerCase()	=== 'true') || jsonObj.selected === true ? true : false;

		oEtablissement.flags = jsonObj.flags || [];

		// ---------------------------------------
		// Récupération des sessions contenus dans l'établissement
		// ---------------------------------------
		if (jsonObj.sessions && jsonObj.sessions.length)
		{
			for (let session of jsonObj.sessions)
			{
				oEtablissement.sessions.push(Session.fromJsonObject(session));
			}
			oEtablissement.sessions.sort(function (a, b)
			{
				var nameA = a.name.toUpperCase(); // ignore upper and lowercase
				var nameB = b.name.toUpperCase(); // ignore upper and lowercase

				if (nameA < nameB)
				{
					return -1;
				}
				if (nameA > nameB)
				{
					return 1;
				}

				// names must be equal
				return 0;
			});
		}
		return oEtablissement;
	}


    /**
     * Creates an instance of Etablissement.
     *
     * @param {string} [_name='']
     * @param {boolean} [_show=false]
     * @param {boolean} [_selected=false]
     * @param {Session[]} [_sessions=[]]
     *
     * @memberOf Etablissement
     */
	constructor(
		private _show:		boolean		= false,
		private _selected:	boolean		= false,
		private _author:	string		= '',
		private _flags:		string[]	= [],
		private _sessions:	Session[]	= [])
	{
		super();
		this._visible = true;
	}



	get show()		{ return this._show; }
	get selected()	{ return this._selected; }
	get author()	{ return this._author; }
	get flags()		{ return this._flags; }
	get sessions()	{ return this._sessions; }
	get visible()	{ return this._visible; }


	set show(show)			{ this._show		= show; }
	set selected(selected)	{ this._selected	= selected; }
	set author(author)		{ this._author		= author; }
	set flags(flags)		{ this._flags		= flags; }
	set sessions(sessions)	{ this._sessions	= sessions; }
	set visible(visible)	{ this._visible		= visible; }


    /**
     *
     * @returns infos from children
     *
     * @memberOf Etablissement
     */
	public extractBranchInfos()
	{
		let infos:	any	= [];
		let info:	any	= {};

		info.nameEtab	= this.name;
		info.idEtab 	= this.id;

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (this.sessions.length > 0)
		{
			for (let i in this.sessions)
			{
				if (this.sessions[i])
				{
					infos = infos.concat(this.sessions[i].extractBranchInfos(info));
				}
			}
		}
		else
		{
			infos.push(info);
		}

		return infos;
	}


    /**
     *
     * @param {any} etabsObject
     * @param {any} [propertyFounded={}]
     *
     * @memberOf Etablissement
     */
	public updateSessionsAvailable(etabsObject: any, propertyFounded: any = {}, hasFilter = false)
	{

		// ---------------------------------------
		// Filtrage pour détecter et supprimer les doublons
		// ---------------------------------------
		let sessionsNames	= _.uniq(_.map(etabsObject, (etab: any)		=> { return etab['nameSession']; }));
		this.sessions		= _.reject(this.sessions, (session: any)	=> { return !sessionsNames.includes(session.name); });


		// ---------------------------------------
		// Filtre les sessions par ordre alphabétique
		// ---------------------------------------
		if (hasFilter === true)
		{
			this.sessions = _.sortBy(this.sessions, (session: any) => { return _.indexOf(sessionsNames, session.name); });
		}


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let currentSessions;
		for (let i in this.sessions)
		{
			if (this.sessions[i])
			{
				currentSessions = _.where(etabsObject, { nameSession: this.sessions[i].name });

				this.sessions[i].show = propertyFounded['key'] === 'default' && propertyFounded['values'].includes(this.sessions[i].name.toLowerCase()) ? true : false;

				this.sessions[i].updateExamensAvailable(currentSessions, propertyFounded, hasFilter);
			}
		}
	}


	/**
	 *
	 */
	public getSelectedItems(paramsSelected: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let result: any[] = [];

		if (paramsSelected['etablissement'] && this.id === paramsSelected['etablissement'])
		{
			result.push(this);
		}


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let session of this.sessions)
		{
			result = result.concat(session.getSelectedItems(paramsSelected));
		}

		return result;
	}


	/**
	 *
	 */
	public getShowedItems(paramsShowed: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let result: any[] = [];

		if (paramsShowed[this.id] && paramsShowed[this.id] === 'etablissement')
		{
			result.push(this);
		}
		
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let session of this.sessions)
		{
			result = result.concat(session.getShowedItems(paramsShowed));
		}

		return result;
	}


	/**
	 * convertion d'un objet Etablissement au format Json
	 */
	public toJson()
	{
		const result: any =
		{
			id:			this.id,
			idparents:	'',
			libelle:	this.name,
			creator:	this.author,
			flags:		this.flags,
			type:		'etablissement',
			datas:
			{
				description:	this.description,
				creationdate:	this.dateCreation,
				lastupdatedate: this.dateModification,
				show:			this.show				? 'true' : 'false',
				selected:		this.selected			? 'true' : 'false'
			}
		};

		if (this.sessions.length > 0)
		{
			result.sessions = [];
			for (const session of this.sessions)
			{
				result.sessions.push(session.toJson());
			}
		}

		return result;
	}
}
