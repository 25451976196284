<div class="main_content" padding>
	<div class="home_calendar" >
		<app-calendar (clickDateEvent)="clickDateEvent($event)"></app-calendar>
	</div>
	<div class="home_content  commons_inner_card">
		<div class="home_content_fastaccess" *ngIf="haveTask || haveTaskAdminExp || haveTaskAdminValidate || (!userData.isAdmin() && !userData.isSuperviseur()) || haveEpreuve">


			<h2>Tableau de bord</h2>
			<div class="homeacces_header">


				<div class="homeacces_header_more">
					<div class="homeacces_header_more_block">
						<mat-form-field class="homeacces_header_more_block_selector" >
							<mat-select placeholder="Classeur" class="item_selector" [ngModel]="workspaceData.selectedHomeEtablissement" (ngModelChange)="changeSelectedEtablissement($event)" >
								<mat-option [value]="null" >Aucun</mat-option>
								<mat-option [value]="etablissement" *ngFor="let etablissement of workspaceData.etablissements">{{ etablissement.name }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="homeacces_header_more_block">
						<mat-form-field class="homeacces_header_more_block_selector" *ngIf="workspaceData.selectedHomeEtablissement">
							<mat-select placeholder="Session" class="item_selector" [ngModel]="workspaceData.selectedHomeSession" (ngModelChange)="changeSelectedSession($event)" >
								<mat-option [value]="null" >{{ 'task.none' | translate }}</mat-option>
								<mat-option [value]="session" *ngFor="let session of workspaceData.selectedHomeEtablissement.sessions">{{session.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="homeacces_header_more_block" >
						<mat-form-field class="homeacces_header_more_block_selector" *ngIf="workspaceData.selectedHomeSession">
							<mat-select placeholder="Examen" class="item_selector" [ngModel]="workspaceData.selectedHomeExamen" (ngModelChange)="changeSelectedExamen($event)" >
								<mat-option [value]="null" >{{ 'task.none' | translate }}</mat-option>
								<mat-option [value]="examen" *ngFor="let examen of workspaceData.selectedHomeSession.examens">{{examen.name}}</mat-option>
							</mat-select>

						</mat-form-field>
					</div>

					<div class="homeacces_header_more_block">
						<mat-form-field class="homeacces_header_more_block_selector" *ngIf="workspaceData.selectedHomeExamen">
							<mat-select placeholder="Epreuve" class="item_selector" [ngModel]="workspaceData.selectedHomeMatiere" (ngModelChange)="changeSelectedMatiere($event)" >
								<mat-option [value]="null" >{{ 'task.none' | translate }}</mat-option>
								<mat-option [value]="matiere" *ngFor="let matiere of workspaceData.selectedHomeExamen.matieres">{{matiere.name}}</mat-option>
							</mat-select>

						</mat-form-field>
					</div>

					<div class="homeacces_header_more_block" >
						<mat-form-field class="homeacces_header_more_block_selector" *ngIf="workspaceData.selectedHomeMatiere">
							<mat-select placeholder="Sujet" class="item_selector" [ngModel]="workspaceData.selectedHomeSujet" (ngModelChange)="changeSelectedSujet($event)" >
								<mat-option [value]="null" >{{ 'task.none' | translate }}</mat-option>
								<mat-option [value]="sujet" *ngFor="let sujet of workspaceData.selectedHomeMatiere.sujets">
									{{sujet.name}}
							</mat-option>
						</mat-select>

					</mat-form-field>
				</div>
				<div class="clear"></div>
			</div>
		</div>


			<!--
			<div class="home_content_fastaccess_content">
				<button mat-raised-button class="home_content_fastaccess_button workspace_link">
					<span class="footer_icon"></span>
				</button>

				<button mat-raised-button class="home_content_fastaccess_button community_link">
					<span class="footer_icon"></span>
				</button>

				<button mat-raised-button class="home_content_fastaccess_button task_link">
					<span class="footer_icon"></span>
				</button>
			</div>
			-->
		</div>

		<div class="home_last_activity">
			<div class="home_last_activity_epreuve2">
				<div class="home_last_activity_late" *ngIf="(userData.isAdmin() || userData.isSuperviseur()) && haveTaskAdminValidate && workspaceData.geTaskHomeAdminToValidLenght() > 0" >
					 <mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									 {{ 'home.tasksToValidate' | translate }} ( {{ workspaceData.geTaskHomeAdminToValidLenght() }} )
								</mat-panel-title>
								<mat-panel-description>

								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class='step_item_block'>
								<div class="step_item" *ngFor="let task of workspaceData.geTaskHomeAdminToValid()" >
									<app-state-stats [task]="task" (click)="goToSubject(task)" ></app-state-stats>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

				</div>

				<div class='home_last_activity_late' *ngIf="workspaceData.getTaskHomeExpiredLength() > 0" >
					<mat-accordion>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ 'home.tasksEndDate' | translate }} ( {{ workspaceData.getTaskHomeExpiredLength() }} )
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="step_item_block">
								<div class="step_item" *ngFor="let task of workspaceData.getTaskHomeAdminExpired()"  >
									<app-state-stats [task]="task" (click)="goToSubject(task)" ></app-state-stats>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>


			</div>
			<div class="home_last_activity_mine" *ngIf="this.workspaceData.getTaskHomeMineLength() > 0">
				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{ 'home.assignedTasks' | translate }} ( {{ this.workspaceData.getTaskHomeMineLength() }} )
							</mat-panel-title>
							<mat-panel-description>

							</mat-panel-description>
						</mat-expansion-panel-header>
						<div class='step_item_block'>
							<div class="step_item" *ngFor="let task of workspaceData.getTaskHomeMine()"   >
								<app-state-stats *ngIf="task.name" [task]="task"  (onClick)="goToSubject(task)"></app-state-stats>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>

			</div>

			<div *ngIf="!haveTask && !haveTaskAdminExp && !haveTaskAdminValidate && (userData.isAdmin() || userData.isSuperviseur()) && !haveEpreuve" class="home_empty">
				<div class="home_empty_container">
					<div class="home_empty_admin_icon"></div>
					<h2>{{ 'home.emptyActivityMessage' | translate }}</h2>

					<button mat-raised-button routerLink="/workspace">Espace de travail</button>
				</div>
			</div>

			<div *ngIf="!haveTaskData() && !haveTaskAdminExp && !haveTaskAdminValidate && (!userData.isAdmin() && !userData.isSuperviseur())"  class="home_empty">


				<div class="home_empty_container">
					<div class="home_empty_user_icon"></div>
					<h2>{{ 'home.emtyToDoListMessage' | translate }}</h2>
				</div>
			</div>

			<div class="home_last_activity_epreuve" *ngIf="(userData.isAdmin() || userData.isSuperviseur()) && haveEpreuve && workspaceData.epreuveHomeAdmin.length > 0">




				 <mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{ 'home.epreuvesTitle' | translate }} ( {{ workspaceData.epreuveHomeAdmin.length }} )
							</mat-panel-title>
							<mat-panel-description>

							</mat-panel-description>
						</mat-expansion-panel-header>
						<div class="epreuve_item_block" >
							<div class="epreuve_item step_item" *ngFor="let epreuve of workspaceData.epreuveHomeAdmin" >
								 <div class="epreuve_item_header">
									<div class="epreuve_item_header_block">
										<div class="epreuve_item_header_left">
											<div class="epreuve_item_header_left_title  title-h3" (click)="goToEpreuve(epreuve)">
												{{epreuve.name}}
											</div>
										</div>
										<div class="epreuve_item_header_right" *ngIf="epreuve.nombre.desujet === 1">
											{{ 'home.subject' | translate }}<span>{{epreuve.nombre.desujet}}</span>
										</div>
										<div class="epreuve_item_header_right" *ngIf="epreuve.nombre.desujet > 1">
											{{ 'home.subjects' | translate }}<span>{{epreuve.nombre.desujet}}</span>
										</div>
										<div class="clear"></div>
									</div>
									<div class="epreuve_item_header_left_ariane  title-h4">
										{{ epreuve.ariane }}
									</div>
								</div>
								<div class="epreuve_item_body_container">
									<div class="epreuve_item_body">
										<div class="epreuve_item_body_left">
											<div class="epreuve_item_body_left_principal" matTooltip="Sujet principal"  [class.epreuve_item_left_body_active]="epreuve.avancement.principal > 0"></div>
										</div>
										<div class="epreuve_item_body_right">
											<mat-progress-bar mode="determinate" class="progress_full" *ngIf="epreuve.avancement.principal === 100" value="{{epreuve.avancement.principal}}"></mat-progress-bar>
											<mat-progress-bar mode="determinate" *ngIf="epreuve.avancement.principal !== 100"  value="{{epreuve.avancement.principal}}"></mat-progress-bar>
										</div>
									</div>
									<div class="epreuve_item_body"  *ngIf="epreuve.nombre.deprincipal != 0 || epreuve.nombre.desecondaire != 0">
										 <div class="epreuve_item_body_left">
											<div class="epreuve_item_body_left_secondaire"  matTooltip="Sujet secondaire"  [class.epreuve_item_left_body_active]="epreuve.avancement.secondaire > 0"></div>
										</div>
										<div class="epreuve_item_body_right">
											<mat-progress-bar mode="determinate" class="progress_full"  *ngIf="epreuve.avancement.secondaire === 100" value="{{epreuve.avancement.secondaire}}"></mat-progress-bar>
											<mat-progress-bar mode="determinate" *ngIf="epreuve.avancement.secondaire !== 100" value="{{epreuve.avancement.secondaire}}"></mat-progress-bar>
										</div>
									</div>
									<div class="epreuve_item_body"  *ngIf="epreuve.nombre.deprincipal == 0 && epreuve.nombre.desecondaire == 0">
										<div class="epreuve_item_body_left">
											<div class="epreuve_item_body_left_other"  matTooltip="Autre"  [class.epreuve_item_left_body_active]="epreuve.avancement.autre > 0"></div>
										</div>
										<div class="epreuve_item_body_right">
											<mat-progress-bar mode="determinate" class="progress_full"  *ngIf="epreuve.avancement.autre === 100" value="{{epreuve.avancement.autre}}"></mat-progress-bar>
											<mat-progress-bar mode="determinate" *ngIf="epreuve.avancement.autre !== 100" value="{{epreuve.avancement.autre}}"></mat-progress-bar>
										</div>
									</div>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>
	<div  class="home_community" >
		<div class="" *ngIf="chatData.canGetChatData()" >
			<div class="home_community_title">{{ 'common.mail' | translate }}</div>
			<app-module-chat class="home_community_chat " ngsReveal [maHomeRooms]="chatData.rooms.reverse()" [contexte]="'globalHome'">
		</app-module-chat>
	</div>

	<div *ngIf="canAccessForum" class="home_community_forum " >
		<div class="home_community_title">{{ 'common.forum' | translate }}</div>
		<div class="home_community_content">

		</div>
	</div>


</div>
<div class="clear"></div>
</div>
