
import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }					        from '@angular/material';
import { MAT_DIALOG_DATA }                      from '@angular/material';

import { Etablissement }		from '../../classes/etablissement.class';
import { Session }				from '../../classes/session.class';
import { Examen }				from '../../classes/examen.class';
import { Matiere }				from '../../classes/matiere.class';


@Component({
	selector:		'warning-delete-element-modal',
	templateUrl:	'confirmDeleteElementModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./confirmDeleteElementModal.component.scss']
})


export class ConfirmDeleteElementModalComponent
{
    public	type:		any;
    public	element:	any;

    constructor(public dialogRef: MatDialogRef<ConfirmDeleteElementModalComponent, any>, @Inject(MAT_DIALOG_DATA) public data: any)
    {
        if (data && data.element)
        {
            this.element = data.element;

            if (data.element instanceof Etablissement)
			{
				this.type = 'Classeur';
			}

			if (data.element instanceof Session)
			{
				this.type = 'Session';
			}

            if (data.element instanceof Examen)
			{
                this.type = 'Examen';
			}

            if (data.element instanceof Matiere)
			{
                this.type = 'Matiere';
			}
        }
    }


    /**
     * 
     */
    public cancel()
    {
        this.dialogRef.close(false);
    }


    /**
     * 
     */
    public validate()
    {
        this.dialogRef.close(true);
    }
}
