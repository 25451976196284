import { Component, ViewEncapsulation }	from '@angular/core';
import { MatDialogRef }					from '@angular/material';

import { SocketService }	from '../../services/socket.service';
import { AuthService }		from '../../services/auth.service';
import { UserData }			from '../../providers/user-data';
import { SCClientSocket }	from 'socketcluster-client';


@Component({
	selector:		'connect-application-modal',
	templateUrl:	'connectApplication.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./connectApplication.component.scss']
})


/**
 *
 */
export class ConnectApplicationModalComponent
{
    public username: string;
    public password: string;

    public pending				= false;
	public isSocketUnavailable	= false;


	/**
	 *
	 */
    constructor(public dialogRef:		MatDialogRef<ConnectApplicationModalComponent, any>,
                public socketService:	SocketService,
                public authService:		AuthService,
                public userData:		UserData)
    {
		this.username = this.userData.getUserId();
		this.socketService.on('authenticate', this.authChange.bind(this));
    }


	/**
	 * 
	 */
    public canValidLogin()
    {
        return this.pending === false && this.username && this.password;
    }


	/**
	 * 
	 */
    public login()
    {
        this.pending	= true;
        let fingerprint = this.userData.getFingerPrint();

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			if (!socket || !socket.state || socket.state !== SCClientSocket.OPEN)
			{
				this.isSocketUnavailable	= true;
				this.pending				= false;
			}
			else
			{
				this.isSocketUnavailable = false;
				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				let credentials =
					{
						username:		this.username,
						password:		this.password,
						fingerprint:	fingerprint
					};

				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				socket.emit('login', credentials);
				this.pending = false;
			}
		});
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandler(event: KeyboardEvent): void
	{
		if (event.key === "Enter")
		{
			this.login();
		}
	}


	/**
	 * 
	 * @param datas 
	 */
	public authChange(datas: any)
	{
		if (datas)
		{
			this.dialogRef.close(true);
		}
	}
}
