import { Injectable, EventEmitter, OnDestroy } from '@angular/core';

import { WorkspaceData } from './workspace-data';

import * as _ from 'underscore';



@Injectable()
export class BaremeData implements OnDestroy
{


    public masTypeList:				any[]	= ['Strict', 'Par réponse', 'Concordance', 'Relatif'];

	private _majBaremeList: any		= [];
	private _mnLastNumber: number	= 0;



	constructor(public workspaceDate: WorkspaceData)
	{

	}

	ngOnDestroy()
	{
    }


	get majBaremeList()
	{

		let aReturn = [];

		for (let sIndex in this._majBaremeList)
		{
			aReturn.push(this._majBaremeList[sIndex]);
		}

		return aReturn;
	}


	getBaremeByPassation(psPassation:string)
	{
		const aReturn = [];

		for (const sIndex in this._majBaremeList)
		{
			if (this._majBaremeList[sIndex].datas.idPassation && this._majBaremeList[sIndex].datas.idPassation === psPassation)
			{
				aReturn.push(this._majBaremeList[sIndex]);
			}
		}

		return aReturn;
	}


	set majBaremeList(pajBaremeList)
	{
		this._majBaremeList = pajBaremeList;
	}

	setBaremeById(psId: any, pjData: any)
	{
		this._majBaremeList[psId] = pjData;
	}


	getBaremeById(psId: any)
	{
		return this._majBaremeList[psId];
	}
	getBaremeNameById(psId: any)
	{
		let sReturn = '';
		if (psId)
		{
			sReturn = this._majBaremeList[psId].datas.name + '-' + this._majBaremeList[psId].datas.number;
		}
		return sReturn;
	}


	updateBaremeDatasById(psId: any, paoDatasToUpdate: any)
	{
		if (this._majBaremeList[psId])
		{
			for (const sKey in paoDatasToUpdate)
			{
				if (paoDatasToUpdate[sKey])
				{
					this._majBaremeList[psId][sKey] = paoDatasToUpdate[sKey];
				}
			}
		}
	}


	addBareme(pjBareme: any)
	{

		let baremeData			= JSON.parse(pjBareme.datas.data);
		pjBareme.datas.data		= baremeData;

		if (pjBareme.datas.number && pjBareme.datas.number > this._mnLastNumber)
		{
			this._mnLastNumber =  +pjBareme.datas.number;
		}

		this._majBaremeList[pjBareme.id] = pjBareme;
	}


	addBaremes(pajBareme: any)
	{
		for (let jBareme of pajBareme)
		{
			this.addBareme(jBareme);
		}
	}


	public getLastId():number
	{
		this._mnLastNumber++;

		return this._mnLastNumber;
	}
}
