<mat-sidenav-container class="sidenav_container">
		<div class="login_wait" *ngIf="workspaceLoading">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
			</svg>
		</div>
	<div class="commons_header" ngsReveal [ngsReveal]="{origin:'top', distance:'80px'}" *ngIf="showMenu">

		 <div class="commons_header_top_line">
			<div class="commons_header_left"><button mat-button routerLink="home"><img src="../assets/img/exatique-bandeau.png" alt=""/></button>
				<!-- <div class="commons_header_left"><button mat-button (click)="backClicked()"><img src="../assets/img/logo_header.png" alt=""/></button>-->
				<!--<h2 id="headerTitle" #headerTitle>{{pageTitle}}</h2>-->
			</div>
			<div class="commons_header_right">
				<div class="commons_header_right_action" *ngIf="!inLoginPanel">
					<div *ngIf="chatData.canGetChatData()" mat-icon-button [matMenuTriggerFor]="chat" class="user_menu_button">
						<span class="commons_header_right_action_chat neoptec_icone_32" id="commons_header_right_action_chat"><span class="commons_header_right_action_chat_counter" *ngIf="this.chatData.getTotalCounter() > 0">{{this.chatData.getTotalCounter()}}</span></span>
					</div>
					<mat-menu #chat="matMenu">
						<div class="commons_header_right_content_header">
							<div class="commons_header_right_content_header_title">
								{{ 'common.mail' | translate }}
							</div>
							<div class="clear"></div>
						</div>
						<div class="commons_header_right_content_content">
							<app-module-chat class="home_community_chat " [maHomeRooms]="chatData.rooms" [contexte]="'globalHome'"></app-module-chat>
							<div class="clear"></div>
							<button mat-button routerLink="community">{{ 'app.mailaccess' | translate }}</button>
						</div>
					</mat-menu>
				</div>
				<div class="commons_header_right_menu">
					<div class="commons_header_right_user">
						<div  mat-icon-button [matMenuTriggerFor]="menu" class="user_menu_button">
							  <div class="user_block">
								<div class="profile-picture" [style.background]="userData.color" [style.color]="userData.getTextColor(userData.color)" attr.data-icone="{{userData.firstLetterIcone}}" ></div>
							</div>
							<div class="commons_header_right_user_name">{{userData.firstName}} {{userData.lastName}}<br />{{currentLogin}}</div>
							<mat-icon class="menu_header_icon">more_vert</mat-icon>
							<div class="clear"></div>
						</div>
						<mat-menu #menu="matMenu">
							<div  class="commons_user_menu">
								<div class="user_block">
									<div class="profile-picture" attr.data-icone="{{userData.firstLetterIcone}}"></div>
								</div>
								<div class="commons_header_right_user_name">{{userData.firstName}} {{userData.lastName}}<br />{{currentLogin}}</div>
							</div>
							<div class="clear"></div>
							<button mat-menu-item  (click)="openAccountPanel()" id="admin_button">
									<mat-icon>account_circle</mat-icon>
								<span>{{ 'app.myaccount' | translate }}</span>
							</button>
							<!--	-->
							<button mat-menu-item (click)="openAdminPanel()" *ngIf="userData.isAdmin() || userData.isSuperviseur()" id="admin_button">
									<mat-icon>settings</mat-icon>
								<span>{{ 'app.admin' | translate }}</span>
							</button>
							<button mat-menu-item (click)="disconnect()" id="logout_button">
									<mat-icon>cancel</mat-icon>
								<span>{{ 'common.disconnect' | translate }}</span>
							</button>
						</mat-menu>
					</div>

				</div>
			</div>
		</div>
	</div>

	<main [class.main-creation]="inCreationPart">
		<router-outlet></router-outlet>   <!-- the component's template will be inserted here-->
	</main>
	<div class="clear"></div>
	<!-- Partie Footer -->
	<app-notification-modal></app-notification-modal>

	<div>
		<footer-component *ngIf="showMenu && inCreationPart"></footer-component>
	</div>
</mat-sidenav-container>