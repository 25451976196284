import { Injectable } from '@angular/core';
import { UserData }	from './user-data';
import { GenericData } from './generic-data';

@Injectable()
/**
 *
 * @export
 * @class User
 */
export class SavedData extends GenericData
{
		private _id:						string	= '';
		private _lastSubjectId:				any		= null;
		private _lastDisciplineId:			any		= null;
		private _lastEtablissementId:		any		= null;
		private _lastSessionId:				any		= null;
		private _lastExamenId:				any		= null;
		private _lastTaskId:				any		= null;
		private _lastPath:					any		= null;
		private _lastGroup:					any		= null;
		private _openedNodes:				any		= null;
		private _paramsLog:					any		= null;
		private _isWorkspaceTaskChecked:	any		= '';


    /**
     * @memberOf User
     */
	constructor(public userData: UserData)
	{
		super();
		// Une fois n'est pas coutume on va construire tt le bordel dans le constructeur à partir des infos dans le localStorage
	}


	get id(): string { return this._id; }

	get lastSubjectId()
	{
		let oReturn = this._lastSubjectId;

		if (this._lastSubjectId === '')
		{
			oReturn 			= this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastSubjectId');
			this._lastSubjectId = oReturn;
		}
		return oReturn;
	}


	get lastDisciplineId()
	{
		let oReturn = this._lastDisciplineId;
		if (this._lastDisciplineId === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastDisciplineId');
			this._lastDisciplineId = oReturn;
		}
		return oReturn;
	}


	get lastEtablissementId()
	{
		let oReturn = this._lastEtablissementId;
		if (this._lastEtablissementId === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastEtablissementId');
			this._lastEtablissementId = oReturn;
		}
		return oReturn;
	}
	get lastSessionId()
	{
		let oReturn = this._lastSessionId;
		if (this._lastSessionId === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastSessionId');
			this._lastSessionId = oReturn;
		}
		return oReturn;
	}


	get openedNodes()
	{
		let oReturn = this._openedNodes;
		if (this._openedNodes === null)
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::openedNodes');

			if (oReturn !== null)
			{
				oReturn = JSON.parse(oReturn);
			}

			this._openedNodes = oReturn;
		}
		return oReturn;
	}


	get lastGroup()
	{

		let oReturn = this._lastGroup;
		if (this._lastGroup === null)
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastGroup');
			this._lastGroup = oReturn;
		}

		return oReturn;
	}

	get lastExamenId()
	{
		let oReturn = this._lastExamenId;
		if (this._lastExamenId === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastExamenId');
			this._lastExamenId = oReturn;
		}
		return oReturn;
	}

	get lastTaskId()
	{
		let oReturn = this._lastTaskId;
		if (this._lastTaskId === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastTaskId');
			this._lastTaskId = oReturn;
		}
		return oReturn;
	}

	get lastPath()
	{
		let oReturn = this._lastPath;
		if (this._lastPath === null)
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::lastPath');
		}

		if (oReturn !== null && oReturn !== undefined && oReturn !== 'undefined')
		{
			oReturn = JSON.parse(oReturn);
			this._lastPath = oReturn;
		}

		return oReturn;
	}

	get isWorkspaceTaskChecked()
	{
		let oReturn = this._isWorkspaceTaskChecked;
		if (this._isWorkspaceTaskChecked === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::isWorkspaceTaskChecked');
		}
		return oReturn;
	}

	/**
	 * Récupération des paramètres de l'appli (options selectionnées, etc...)
	 */
	get paramsLog()
	{
		let oReturn = this._paramsLog;

		if (this._paramsLog === '')
		{
			oReturn = this.getStorage(this.userData.mail + '::' + this.userData.account + '::paramsLog');
			
			if (oReturn !== null && oReturn !== undefined && oReturn !== 'undefined')
			{
				oReturn = JSON.parse(oReturn);
			}
		}

		return oReturn;
	}



	set id(id) { this._id = id; }


	set lastSubjectId(lastSubjectId)
	{
		if (lastSubjectId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastSubjectId', lastSubjectId);
		}
		this._lastSubjectId = lastSubjectId;
	}
	set lastDisciplineId(lastDisciplineId)
	{
		if (lastDisciplineId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastDisciplineId', lastDisciplineId);
		}
		this._lastSubjectId = lastDisciplineId;
	}
	set lastEtablissementId(lastEtablissementId)
	{
		if (lastEtablissementId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastEtablissementId', lastEtablissementId);
		}
		this._lastEtablissementId = lastEtablissementId;
	}
	set lastSessionId(lastSessionId)
	{
		if (lastSessionId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastSessionId', lastSessionId);
		}
		this._lastSessionId = lastSessionId;
	}
	set lastExamenId(lastExamenId)
	{
		if (lastExamenId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastExamenId', lastExamenId);
		}
		this._lastExamenId = lastExamenId;
	}
	set lastTaskId(lastTaskId)
	{
		if (lastTaskId)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastTaskId', lastTaskId);
		}
		this._lastTaskId = lastTaskId;
	}


	set lastPath(lastPath)
	{
		if (lastPath)
		{
			const data = JSON.stringify(lastPath);
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastPath', data);
		}
		this._lastPath = lastPath;
	}


	set lastGroup(lastGroup)
	{
		if (lastGroup)
		{
			const data = JSON.stringify(lastGroup);
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::lastGroup', data);
		}
		this._lastGroup = lastGroup;
	}


	set openedNodes(nodes)
	{
		if (nodes)
		{
			const data = JSON.stringify(nodes);
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::openedNodes', data);
		}
		this._openedNodes = nodes;
	}

	set isWorkspaceTaskChecked(isWorkspaceTaskChecked)
	{
		localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::isWorkspaceTaskChecked', isWorkspaceTaskChecked);
		this._isWorkspaceTaskChecked = isWorkspaceTaskChecked;

		// On enregistre également l'information dans l'ensemble des paramètres logiciels
		// (utilisé lors de l'enregistrement en base de données)
		let paramsLogs = this._paramsLog;

		if (paramsLogs === null)
		{
			paramsLogs = this.getStorage(this.userData.mail + '::' + this.userData.account + '::paramsLog');

			if (paramsLogs !== null && paramsLogs !== undefined && paramsLogs !== 'undefined')
			{
				paramsLogs = JSON.parse(paramsLogs);
			}
			else
			{
				paramsLogs = {};
			}
		}

		paramsLogs['worspaceTaskChecked'] = isWorkspaceTaskChecked;
		this._paramsLog 	= paramsLogs;
		let strParamsLogs 	= JSON.stringify(paramsLogs);

		localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::paramsLog', strParamsLogs);
	}

	
	set paramsLog(params)
	{
		if (params)
		{
			localStorage.setItem(this.userData.mail + '::' + this.userData.account + '::paramsLog', params);
		}
		this._paramsLog = params;
	}


	public getStorage(key: any)
	{
		let oReturn;
		oReturn = localStorage.getItem(key);
		return oReturn;
	}
	public removeStorage(key: any)
	{
		localStorage.removeItem(key);
	}
	public store(key: any, data: any)
	{
		localStorage.setItem(key, data);
	}

	public logout()
	{
		this._lastGroup 			= null;
		this._openedNodes			= null;
		this._paramsLog				= null;
		this._lastPath 				= null;
		this._lastSubjectId 		= null;
		this._lastDisciplineId 		= null;
		this._lastEtablissementId 	= null;
		this._lastSessionId 		= null;
		this._lastExamenId 			= null;
		this._lastTaskId 			= null;
		this._lastExamenId 			= null;
		this._lastExamenId 			= null;

	}

}

