// Angualr
import { Pipe, PipeTransform } from '@angular/core';
// Neoptec
import { Task }		from '../../../classes/task.class';


@Pipe({
	name: 'tasksFilter',
	// Pure make the pipe becomes stateful pipe
	// By default it's value is true which is stateless, data will be fetch only 1 time
	pure: false
})


/**
 *
 */
export class TaskFilterPipe implements PipeTransform
{


	/**
	 *
	 */
	public transform(value: Task[], key: string, val: string): Task[]
	{

		if (typeof key === 'undefined' || typeof val === 'undefined')
		{
			return value;
		}

		if (value)
		{
			return value.filter((task: any) => task[key] == val);
		}
	}
}

