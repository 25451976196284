<form (ngSubmit)="validate()" [formGroup]="moFormGroup"  class="dialog_form">
    <div class="dialog_grid_container">

        <div class="dialog_header">
			<div class="dialog_header_title">
				{{ 'editorPanel.title' | translate }}
			</div> 
			<div class="dialog_header_close">
				<div class="dialog_header_close_icon" (click)='close()'></div>
			</div>
		</div>

        <div class="dialog_menu">
			<div class="dialog_menu_title">{{ 'editorPanel.newTask' | translate }}</div>
			<div (click)="switch_tab('informations')" [class.dialog_menu_selected]="itemSelected === 'informations'" class="dialog_menu_block">{{ 'editorPanel.informations' | translate }}</div>
			<div (click)="switch_tab('droits')" [class.dialog_menu_selected]="itemSelected === 'droits'" class="dialog_menu_block">{{ 'editorPanel.droits' | translate }}</div>
		</div>

        <div class="dialog_content" *ngIf="currentTask && itemSelected">
			 <div class="workspace_header" *ngIf="treeDatas">
				<app-workspace-header [datasTree]="treeDatas"></app-workspace-header>
			</div>

			<div class="dialog_content_inner">
				<div class="form_section" [hidden]="itemSelected !== 'informations'">

					<mat-form-field *ngIf="scenarioList">
						<mat-select placeholder="{{ 'editorPanel.type' | translate }}" [(ngModel)]="currentType" formControlName="type" (ngModelChange)="majTitle()" >
							<mat-option *ngFor="let type of scenarioList.steps" [value]="type.id">{{ type.name }}</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="clear"></div>

					<mat-form-field>
						<mat-select placeholder="{{ 'editorPanel.sujet' | translate }}" [(ngModel)]="currentIdSujet" formControlName="subject" (ngModelChange)="majTitle()" >
							<mat-option *ngFor="let subject of subjectsAvailable" [value]="subject.value">{{ subject.libelle }}</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="clear"></div>

					<mat-form-field>
						<input matInput maxLength="80"  placeholder="{{ 'editorPanel.titre' | translate }}" id="workspace_modal_form_epreuve_name" [(ngModel)]="currentName" formControlName="name">
					</mat-form-field>
					<div *ngIf="moFormCtrlName.dirty && moFormCtrlName.hasError('required')">{{ 'editorPanel.nameRequired' | translate }}</div>

					<div class="clear"></div>

					<mat-form-field>
						<textarea  maxLength="250"  matInput placeholder="{{ 'editorPanel.description' | translate }}" [(ngModel)]="currentDescription" formControlName="description"></textarea>
					</mat-form-field>

					<div class="clear"></div>

					<mat-form-field>
						<input matInput [matDatepicker]="myDatepicker" [min]="startDate" [readonly]="true" [(ngModel)]="dateEcheance" placeholder="{{ 'editorPanel.echeance' | translate }}" id="workspace_modal_form_epreuve_date_start" formControlName="dateEcheance"><mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
					</mat-form-field>
					<mat-datepicker #myDatepicker></mat-datepicker>

					<div class="clear"></div>

					<mat-form-field>
						<mat-select placeholder="{{ 'editorPanel.assignedTo' | translate }}" [(ngModel)]="currentAssigneduser" [class.unlogged_user]="unloggedUserSelected" formControlName="assignedUser" (ngModelChange)="changeSelectedUser()">
							<mat-option *ngFor="let user of usersAvailable" [class.unlogged_user]="!user.loggedOnce" [value]="user.value">{{ user.libelle }}</mat-option>
						</mat-select>
					</mat-form-field>

				</div>
            </div>

            <div class="form_section" [hidden]="itemSelected !== 'droits'">
                <mat-checkbox [(ngModel)]="noAccessFile" formControlName="noAccessFile">{{ 'editorPanel.noAccessFile' | translate }}</mat-checkbox>
				<div class="clear"></div>

				<div class="file-access">
					<mat-checkbox [disabled]="noAccessFile" [(ngModel)]="readOnlyFile" [ngModelOptions]="{standalone: true}">{{ 'editorPanel.readOnlyFile' | translate }}</mat-checkbox>
					<div class="clear"></div>
					<mat-checkbox [disabled]="noAccessFile" [(ngModel)]="canDeleteFile" [ngModelOptions]="{standalone: true}">{{ 'editorPanel.canDeleteFile' | translate }}</mat-checkbox>
					<div class="clear"></div>
					<mat-radio-group [disabled]="noAccessFile" [(ngModel)]="typeFileAccess" [ngModelOptions]="{standalone: true}" >
						<mat-radio-button value="all" class="mat_radio_right">{{ 'editorPanel.accessAllFiles' | translate }}</mat-radio-button>
						<div class="clear"></div>
						<mat-radio-button value="list"  class="mat_radio_right" [disabled]="!selectedSubject || selectedSubject.pj.length === 0">{{ 'editorPanel.accessFileList' | translate }}</mat-radio-button>
					</mat-radio-group>
					<div class="clear"></div>

					<div *ngIf="typeFileAccess === 'list' && !noAccessFile">

						<mat-table #table [dataSource]="filesSubject">

							<!-- Checkbox Column -->
							<ng-container matColumnDef="select">
								<mat-header-cell *matHeaderCellDef>
									<mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
								</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="name">
								<mat-header-cell *matHeaderCellDef> {{ 'editorPanel.nameFile' | translate }} </mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
							</ng-container>

							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></mat-row>
						</mat-table>
					</div>
				</div>

				<mat-checkbox [(ngModel)]="canCreateFile" formControlName="canCreateFile">{{ 'editorPanel.userCanCreateFile' | translate }}</mat-checkbox>
				<div class="clear"></div>
			</div>
		</div>

		<div class="dialog_comments_tasks">
			<comments-component *ngIf="currentTask && selectedSubject" [type]="'historic-comment'" [idElement]="currentTask.id" [parent]="selectedSubject" ></comments-component>
		</div>

		<div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button type="button" mat-raised-button (click)='close()'>{{ 'common.cancel' | translate }}</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button type="submit" [disabled]="!canValidateTask()" mat-raised-button>{{ 'common.validate' | translate }}</button>
			</div>
		</div>

	</div>
</form>
