import { Component, ViewEncapsulation }	from '@angular/core';
import { MatDialogRef }					from '@angular/material';

@Component({
	selector:		'warning-unlogged-user-modal',
	templateUrl:	'warningUnloggedUserModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./warningUnloggedUserModal.component.scss']
})

export class WarningUnloggedUserModalComponent
{
	
    constructor(public dialogRef: MatDialogRef<WarningUnloggedUserModalComponent, any>)
    {
	}

    public validate()
    {
        this.dialogRef.close();
    }
    public cancel()
    {
        this.dialogRef.close();
    }
}