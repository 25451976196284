// Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { SessionHttpService }		from './http/sessionHttp.service';
import { Session }					from '../classes/session.class';
import { Comment }					from '../classes/comment.class';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()


/**
 *
 */
export class SessionService
{


	/**
	 *
	 */
	constructor(private sessionHttpService: SessionHttpService,
			    public socketService: SocketService,
				private userData: UserData)
	{

	}


    /**
     * Add new session in database from Session object given in parameter
	 * Parents ids are given by the parentPath parameter
     */
	public addSession(dataSession: Session, parentPath: any[] = [])
	{
		let datas 		= dataSession.toJson();
		datas.idparents = parentPath;

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'node',
						'args': 
						{
							type: 'session',
							node: datas
						}
					};
				socket.emit('create', data);
			}
		});
	}


	/**
	 * Update session in database from Session object given in parameter
	 * @param dataSession
	 */
	public editSession(dataSession: Session)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'node',
					'args':
					{
						type: 	'session',
						node:	dataSession.toJson()
					}
				};
			socket.emit('update', data);
			}
		});
	}


    /**
     * Get all sessions in database relative to user account and parameters given:
     *
     *  -cascade:   if false, return only Sessions objects, if true return Sessions object with childs
     *              (examens and epreuves) in each objects
     *  -idSession: if this parameter is defined, return the Session object corresponding to the value id
     *  -idEtab:    if this parameter is defined (and if the parameter idSession is not), return the Sessions
     *              objects wich the etablissement parent matches the value id.
     *
     * Return a list of Session objects
     */
	public getSessions(cascade: boolean, idSession: string, idEtab: string, callback: (result: any) => void)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'node.info',
					'args': 
					{
						node: 		idEtab,
						nodeOption: 'wc',
					}
				};
				socket.emit('read', data);
			}
			else
			{
				this.sessionHttpService.getSessions(cascade, idSession, idEtab).subscribe(res =>
				{
					let resJson				= res.json();
					let sessions: Session[] = [];

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					if (resJson.data && resJson.data.rows)
					{
						for (let row of resJson.data.rows)
						{
							if (row)
							{
								sessions.push(Session.fromJsonObject(row));
							}
						}
					}
					callback(sessions);
				});
			}
		});
	}
}


