import { Component }        from '@angular/core';
import { Filter }           from './classes/filter.class';
import { SearchBarService } from '../services/search-bar.service';


@Component(
  {
    templateUrl: 'popover-search-filter.html',
  })


/**
 * 
 */
export class PopoverSearchFilter
{

  public filterChosen:     any;
  public filters:          Filter[];
  public searchBarService: SearchBarService;


  /**
   * Creates an instance of PopoverSearchFilter.
   * 
   * @param {ViewController} viewCtrl
   * 
   * @memberOf PopoverSearchFilter
   */
  constructor() 
  {

  }


  /**
   * 
   * @param {any} value
   * 
   * @memberOf PopoverSearchFilter
   */
  public changeValueFilter(value: any) 
  {
    this.filterChosen = value;
    this.searchBarService.setFilterSelected(value);
    this.searchBarService.applyFilterAndEmit();
  }


  /**
   * @memberOf PopoverSearchFilter
   */
  public close() 
  {
//    this.viewCtrl.dismiss();
  }
}
