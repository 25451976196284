<form (ngSubmit)="vadidateUser()" [formGroup]="moFormUser" class="dialog_form">
    <div class="dialog_grid_container nomenu">
        <div class="dialog_header">
			<div class="dialog_header_title">
				{{ 'selectAssigned.message' | translate }}
			</div>

			<div class="dialog_header_close">
				<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
			</div>
		</div>

        <div class="dialog_content">

            <mat-form-field>
                <mat-select placeholder="Assigné à" [(ngModel)]="selectedUser" formControlName="user">
					<mat-option *ngFor="let user of usersAvailable" [value]="user.value">{{ user.libelle }}</mat-option>
                </mat-select>
            </mat-form-field>

			<div class="clear"></div>

			<div *ngIf="selectedUser">
				<mat-checkbox [(ngModel)]="sendEmail" formControlName="sendEmail">{{ 'selectAssigned.sendMail' | translate }}</mat-checkbox>
			</div>
        </div>

        <div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button mat-raised-button type="button" (click)='cancel()'>{{ 'common.cancel' | translate }}</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button mat-raised-button type="submit" [disabled]="!canValidate()">{{ 'common.validate' | translate }}</button>
			</div>
		</div>
    </div>
</form>
