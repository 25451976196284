<form class="dialog_form">
	<div class="dialog_grid_container nomenu">
		<div class="dialog_header">
			<div class="dialog_header_title">
				{{titleModal}}
			</div>
			<div class="dialog_header_close">
				<div class="dialog_header_close_icon" (click)='validate()'></div>
			</div>
		</div>
		<div class="dialog_content">
			<mat-card-content>
				<div *ngIf="scenario">
					<label>{{ messageModal }}</label>
					<div class="clear"></div>
					<label>- {{scenario.title}}</label>
					<div class="clear"></div>

				</div>
			</mat-card-content>
		</div>
		<div class="dialog_footer">
			<div class="dialog_footer_valid_button">
				<button mat-raised-button type="button" (click)='validate()'>{{ 'common.ok' | translate }}</button>
			</div>
		</div>
	</div>
</form>
