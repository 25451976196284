import { Component, ViewEncapsulation, Inject, OnInit,OnChanges, Input }	from '@angular/core';
import { MatDialogRef, MatDialog }											from '@angular/material';
import { MAT_DIALOG_DATA }													from '@angular/material';

import { FileUploader, FileItem }	from 'ng2-file-upload';
import { CreationHttpService }		from '../../../services/http/creationHttp.service';

import { BaremeCreatorComponent }	from '../../bareme-creator/bareme-creator.component';
import { BaremeService }			from '../../../services/bareme.service';
import { SujetService }				from '../../../services/sujet.service';
import { File as FileClass }		from '../../../classes/file.class';
import { BaremeData }				from '../../../providers/bareme-data';
import { VaultData }				from '../../../providers/vault-data';
import { UserData }					from '../../../providers/user-data';
import { WorkspaceData }			from '../../../providers/workspace-data';


@Component({
  selector:			'app-bareme-editor',
	encapsulation:	ViewEncapsulation.None,
  templateUrl:		'./bareme-editor.component.html',
  styleUrls:		['./bareme-editor.component.scss']
})


export class BaremeEditorComponent implements OnInit, OnChanges
{
	@Input() majData:		any;
	@Input() subjectType:	string;
	@Input() passationId:	string;


	public majMainQCM:	any	= {};
	public majSecQCM:	any	= {};

	public mainFileQCM:		FileClass;
	public secondFileQCM:	FileClass;

	public mjBaremeMain:	any;
	public mjBaremeSec:		any;

	public mjSelectedBaremeId:	string = '';
	public majSelectedBareme:	any;

	public msActiveQuestion:	any = {id:0};
	public msActivePart:		any = {id:0};
	
	public isPartSelected:		boolean = false;
	public isQuestionSelected:	boolean = false;
	public isShowed:			boolean = false;
	
	public msSelectedBareme:	any;
	public validation				= true;

	public msEpreuveId = '';

	public mjMainSubject:	any;
	public mjSecSubject:	any;
	public moResponseCancel: any =[];
	
	public msPartSelectedBaremeId:		string = '';

	public passation: any;
	
	public quillModules = 
	{
		toolbar: 
		false,
//		imageResize: true,
		formula: false
	};


	/**
	 * 
	 * @param moBaremeService 
	 * @param userData 
	 * @param vaultData 
	 * @param workspaceData 
	 * @param moSujetService 
	 * @param moBaremeData 
	 * @param dialog 
	 */
	constructor(
		public moBaremeService:		BaremeService,
		public userData:			UserData,
		public vaultData:			VaultData,
		public workspaceData:		WorkspaceData,
		public moSujetService:		SujetService,
		public moBaremeData:		BaremeData,
		public dialog:				MatDialog)
	{
		// ---------------------------------------
		// Récupération des infos depuis la page appelante
		// ---------------------------------------

	}


	ngOnChanges()
	{

	}


	ngOnInit()
	{
		this.passation = this.vaultData.getConcourByid(this.passationId);

		if (this.passation.datas && this.passation.datas.baremesMain)
		{
			this.mjBaremeMain = JSON.parse(this.passation.datas.baremesMain);
		}

		if (this.passation.datas && this.passation.datas.baremesSec)
		{
			this.mjBaremeSec = JSON.parse(this.passation.datas.baremesSec);
		}

		let firstQuestion;
		let firstPart;
		// ---------------------------------------
		// On remet l'id du bareme quand la question du qcm
		// ---------------------------------------
		if (this.majData && this.majData.parts)
		{
			let isFirstQuestion = true;
			for (let part of this.majData.parts)
			{
				
				if (part && part.questions)
				{

					for (let question of part.questions)
					{
						if (isFirstQuestion)
						{
							firstQuestion	= question;
							firstPart		= part;
							isFirstQuestion	= false;
						}


						// Ptet une refonte à faire en fonction
						if (this.subjectType === "main" && this.mjBaremeMain)
						{
							// selection automatique du premier bareme
							for (let bareme of this.mjBaremeMain)
							{
								part.coef = bareme.coef;
								if (bareme && bareme.questions)
								{
									for (let questionOfBareme of bareme.questions)
									{
										if (bareme.part === part.id && question.id === questionOfBareme.question)
										{
											question.baremeId	= questionOfBareme.baremeId;
											question.coef		= questionOfBareme.coef;

											if (!question.hasOwnProperty('isCanceled'))
											{
												question.isCanceled 			= false;
												questionOfBareme.isCanceled 	= false;
											}
											else
											{
												question.isCanceled = questionOfBareme.isCanceled;
											}

											// Mise en place de l'invalidation des réponses
											for (let reponse of question.responses)
											{
												if(questionOfBareme.responses)
												{
													for (let reponseBareme of questionOfBareme.responses)
													{
														if(reponse.id === reponseBareme.id)
														{
															if (!reponseBareme.hasOwnProperty('isCanceled'))
															{
																reponse.isCanceled 			= false;
																reponseBareme.isCanceled 	= false;
															}
															else
															{
																reponse.isCanceled = reponseBareme.isCanceled;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}

						if (this.subjectType === "sec" && this.mjBaremeSec)
						{
							for (let bareme of this.mjBaremeSec)
							{
								part.coef = bareme.coef;
								if (bareme && bareme.questions)
								{
									for (let questionOfBareme of bareme.questions)
									{
										if (bareme.part === part.id && question.id === questionOfBareme.question)
										{
											question.baremeId	= questionOfBareme.baremeId;
											question.coef		= questionOfBareme.coef;

											if (!questionOfBareme.hasOwnProperty('isCanceled'))
											{
												question.isCanceled 		= false;
												questionOfBareme.isCanceled	= false;
											}
											else
											{
												question.isCanceled = questionOfBareme.isCanceled;
											}
										}
										// Mise en place de l'invalidation des réponses
										for (let reponse of question.responses)
										{
											if(questionOfBareme.responses)
											{
												for (let reponseBareme of questionOfBareme.responses)
												{
													if(reponse.id === reponseBareme.id)
													{
														if (!reponseBareme.hasOwnProperty('isCanceled'))
														{
															reponse.isCanceled 			= false;
															reponseBareme.isCanceled 	= false;
														}
														else
														{
															reponse.isCanceled = reponseBareme.isCanceled;
														}
													}
												}
											}
										}
									}	
								}
							}
						}
					}
				}
			}
			this.selectQuestion(firstPart, firstQuestion);
		}
	}

	/**
	 * @param typeRep
	 * @param reponse
	 */
	public onChangeRep(reponse: any)
	{
		
	}


	public showBareme()
	{
		this.isShowed = !this.isShowed;
	}

	/**
	 * Ouvre la modale pour ajouter un nouveau barème
	 */
	public addBareme()
	{
		let dialogRef = this.dialog.open(BaremeCreatorComponent,
			{
				height:			'65%',
				width:			'65%',
				panelClass:		'responsive_modal',
				disableClose:	true,
				data:
				{

				}
			});


		dialogRef.afterClosed().subscribe((result: any) =>
		{
			// penser à ajouter l'id passation
			if(result)
			{
				result.idPassation		= this.passationId;
				let nNumber: number		= this.moBaremeData.getLastId();
				result.number			= nNumber;

				this.moBaremeService.createBareme(result);
			}
		});
	}


	/**
	 * Ouvre la modale pour éditer un barème
	 * Il s'agit de la mm modale mais on lui passe un id de barème
	 */
	public editBareme()
	{

		let oBareme =  this.moBaremeData.getBaremeById(this.msActiveQuestion.baremeId);

		const dialogRef = this.dialog.open(BaremeCreatorComponent,
			{
				height:			'65%',
				width:			'65%',
				panelClass:		'responsive_modal',
				disableClose:	true,
				data:
				{
					bareme: oBareme
				}
			});


		dialogRef.afterClosed().subscribe((result: any) =>
		{
			if (result)
			{
				this.moBaremeService.editBareme(oBareme.id, result);
			}
		});
	}


	/**
	 * Est appelé au clic sur le menu
	 */
	public selectQuestion(psPart: any, psQuestion: any)
	{
				
		this.isPartSelected		= false;
		this.isQuestionSelected = true;
		this.msActiveQuestion	= psQuestion;
		this.msActivePart		= psPart;
		let baremeData 			= this.moBaremeData.getBaremeById(psQuestion.baremeId);

		/*for (let reponse of baremeData)
		{

		}*/

		if(baremeData)
		{
			this.majSelectedBareme = baremeData;
		}
	}


	/**
	 * Est appelé au clic sur le menu
	 */
	public selectPart(psPart: any, psQuestion: any)
	{
		this.isPartSelected		= true;
		this.isQuestionSelected = false;
		this.msActivePart		= psPart;
		this.msActiveQuestion	= null;
	}


	/*
	 * Au changement du bareme on met à j (on fera au validate plus tard)
	 */
	public changeSelected(poEvent: any)
	{
		let baremeData 			= this.moBaremeData.getBaremeById(poEvent);
		this.majSelectedBareme 	= baremeData;
	}


	/*
	 * Au changement du bareme on met à j (on fera au validate plus tard)
	 */
	public changeSelectedGlobal(poEvent: any)
	{
		let baremeData = this.moBaremeData.getBaremeById(poEvent);

		this.majSelectedBareme = baremeData;
	}
	
	/*
	 * Au changement du bareme on met à j (on fera au validate plus tard)
	 */
	public isReponseCancel(reponse: any)
	{
		return false;
	}
	
	
	/**
	 * 
	 */
	public activeBaremeForPart()
	{
		for (let question of this.msActivePart.questions)
		{
			question.baremeId = this.majSelectedBareme.id;
		}
	}

	
	/**
	 * 
	 */
	public associateBareme()
	{
		
	}


	public changeCoefQuestion()
	{
		if (this.msActiveQuestion.coef <= 0)
		{
			this.msActiveQuestion.coef = 1;
		}
	}

	public changeCoefPart()
	{
		if (this.msActivePart.coef <= 0)
		{
			this.msActivePart.coef = 1;
		}
	}
}
