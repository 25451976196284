import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy } 	from '@angular/core';
import { MatDialogRef, MatDialog }									from '@angular/material';
import { MAT_DIALOG_DATA }											from '@angular/material';

import { FileUploader, FileItem }	from 'ng2-file-upload';
import { BaremeService }			from '../../services/bareme.service';
import { SujetService }				from '../../services/sujet.service';
import { PassationService }			from '../../services/passation.service';
import { File as FileClass }		from '../../classes/file.class';
import { BaremeData }				from '../../providers/bareme-data';
import { UserData }					from '../../providers/user-data';
import { WorkspaceData }			from '../../providers/workspace-data';
import { VaultData } 				from '../../providers/vault-data';


@Component({
	selector:		'app-bareme-to-qcm',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./bareme-to-qcm.component.html',
	styleUrls:		['./bareme-to-qcm.component.scss']
})


/**
 *
 */
export class BaremeToQcmComponent implements OnInit, OnDestroy
{
	public fileContent: File;
	public uploader:	FileUploader;

	public majMainQCM:	any	= {};
	public majSecQCM:	any	= {};

	public mainFileQCM:		FileClass;
	public secondFileQCM:	FileClass;

	public mjSelectedBaremeId:	string = '';
	public majSelectedBareme:	any;

	public msActiveQuestion:	any = {id:0};
	public msActivePart:		any = {id:0};
	public validation				= true;

	public msEpreuveId		= '';
	public msPassationId	= '';

	public mjMainSubject:	any;
	public mjSecSubject:	any;
	public majData:			any;

	public workspaceDataEmmiterSubscription:	any;
	public idsSubjectSelected:					any[] = [];


	/**
	 *
	 * @param dialogRef
	 * @param data
	 * @param moBaremeService
	 * @param moPassationService
	 * @param userData
	 * @param workspaceData
	 * @param vaultData
	 * @param moSujetService
	 * @param moBaremeData
	 * @param dialog
	 */
	constructor(public dialogRef:	MatDialogRef<BaremeToQcmComponent, any>,
		@Inject(MAT_DIALOG_DATA)	public data: any,
		public moBaremeService:		BaremeService,
		public moPassationService:	PassationService,
		public userData:			UserData,
		public workspaceData:		WorkspaceData,
		public vaultData:			VaultData,
		public moSujetService:		SujetService,
		public moBaremeData:		BaremeData,
		public dialog:				MatDialog)
	{

		// ---------------------------------------
		// Récupération des infos depuis la page appelante
		// ---------------------------------------
		this.majData = data;
		// QCM principal et secondaire
		if (data.qcmJson && data.qcmJson.mainQcm)
		{
			this.majMainQCM = data.qcmJson.mainQcm;
		}

		if (data.qcmJson && data.qcmJson.secondQcm)
		{
			this.majSecQCM = data.qcmJson.secondQcm;
		}

		// Sujet principal et secondaire
		if (data.mainSubject)
		{
			this.mjMainSubject = data.mainSubject;
		}
		if (data.secSubject)
		{
			this.mjSecSubject = data.secSubject;
		}

		// File pour renvoyer la maj (info sur le qcm)
		if (data.qcmJson && data.qcmJson.mainFile)
		{
			this.mainFileQCM = data.qcmJson.mainFile;
		}

		if (data.qcmJson && data.qcmJson.secondFile)
		{
			this.secondFileQCM = data.qcmJson.secondFile;
		}

		if (data.idEpreuve)
		{
			this.msEpreuveId = data.idEpreuve;
		}

		if (data.idPassation)
		{
			this.msPassationId = data.idPassation;
		}

		// Mise en forme du nom des questions et partie
		if (this.majMainQCM.parts)
		{
			for (const part of this.majMainQCM.parts)
			{
				for (const question of part.questions)
				{
					question.partId	= part.id;
					question.nameC	= part.id + '.' + question.id;
	
				}
			}
		}

		if (this.majSecQCM.parts)
		{
			for (const part of this.majSecQCM.parts)
			{
				for (const question of part.questions)
				{
					question.partId	= part.id;
					question.nameC	= part.id + '.' + question.id;
	
				}

			}
			
		}
		
	}


	ngOnInit()
	{
	}


	ngOnDestroy()
	{
		if (this.workspaceDataEmmiterSubscription)
		{
			this.workspaceDataEmmiterSubscription.unsubscribe();
		}
	}


	public close()
	{
		this.dialogRef.close(false);
	}


	public cancel()
	{
		this.dialogRef.close(false);
	}


	public validate()
	{
		const jMainBareme: any		= [];
		const jSecBareme: any		= [];

		// ---------------------------------------
		// Enregistrement du bareme principal
		// ---------------------------------------
		if (this.majMainQCM && this.majMainQCM.parts)
		{
			// Enregistrement des infos de la partie, id, coef et question
			for (const part of this.majMainQCM.parts)
			{
				const jPart: any = { part: part.id, coef: part.coef, questions: [] };

				// Pour chaque question, id du bareme, coef et id question
				for (const question of part.questions)
				{
					if (question.baremeId)
					{
						let responses: any = [];
						for (let reponse of question.responses)
						{
							responses.push(reponse);
						}
							const jQuestion =
							{
								question:	question.id,
								baremeId:	question.baremeId,
								coef:		question.coef,
								isCanceled: question.isCanceled,
								responses: 	responses
							};
						jPart.questions.push(jQuestion);
					}
				}
				jMainBareme.push(jPart);
			}
		}


		// ---------------------------------------
		// Enregistrement du bareme secondaire
		// ---------------------------------------
		if (this.majSecQCM && this.majSecQCM.parts)
		{
			// Enregistrement des infos de la partie, id, coef et question
			for (const part of this.majSecQCM.parts)
			{
				const jPart: any = { part: part.id, coef: part.coef, questions: [] };

				// Pour chaque question, id du bareme, coef et id question
				for (const question of part.questions)
				{
					if (question.baremeId)
					{
						const jQuestion =
							{
								question: 		question.id,
								baremeId: 		question.baremeId,
								coef: 			question.coef,
								isCanceled: 	question.isCanceled,
							};

						jPart.questions.push(jQuestion);

					}
/*					for (let reponse of question.responses)
					{
						if (!reponse.hasOwnProperty('isCanceled'))
						{
							reponse.isCanceled = false;
						}
					}*/
				}
				jSecBareme.push(jPart);
			}
		}
		const passation: any = this.vaultData.getConcourByid(this.msPassationId);

		// ---------------------------------------
		// MAj du bareme (passation) coté serveur
		// ---------------------------------------
		const aData: any = 
		{
			'+datas': 
			{
				notation: 			passation.datas.notation,
				baremesMain: 		jMainBareme,
				baremesSec: 		jSecBareme,
				needCalcResults: 	true
			}
		};
		this.moPassationService.updatePassation(this.msPassationId, aData);
		this.dialogRef.close(aData);
	}
}
