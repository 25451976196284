<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">{{ 'confirmDeleteUserModal.title' | translate }}</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>

	<div class="dialog_content">

		<mat-card-content>
			<label>{{ 'confirmDeleteUserModal.message' | translate }}</label>
			<div class="clear"></div>
			<label *ngIf="currentUser && currentUser.infos">- {{ currentUser.infos.prenom }} {{ currentUser.infos.nom }} ({{ currentUser.infos.mail }})</label>
			<div class="clear"></div>
		</mat-card-content>

    </div>
	<div class="dialog_footer">
        <button id="cancel-connect" class="cancel_button" mat-raised-button (click)="cancel()">{{ 'common.cancel' | translate }}</button>
		<button id="valid-connect" class="alert_button" mat-raised-button (click)="validate()">{{ 'common.delete' | translate }}</button>
	</div>
</mat-dialog>
