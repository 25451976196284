import { Component, Inject }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA }            from '@angular/material';


@Component({
    selector:		'confirm-delete-processus-modal',
    templateUrl:	'confirmDeleteProcessusModal.component.html',
    styleUrls:		['./confirmDeleteProcessusModal.component.scss']
})


/**
 * 
 */
export class ConfirmDeleteProcessusModalComponent
{
	public scenario: any;


	constructor(public dialogRef: MatDialogRef<ConfirmDeleteProcessusModalComponent, any>, @Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.scenario)
		{
			this.scenario = data.scenario;
		}
	}


	public validate()
	{
		this.dialogRef.close(true);
	}


	public close()
	{
		this.dialogRef.close(false);
	}
}
