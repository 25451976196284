import { Component, Input, OnInit, OnChanges }		from '@angular/core';
import { ViewEncapsulation }	from '@angular/core';
import { UserData }					from '../../providers/user-data';


@Component({
	selector: 'app-user',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})


/**
 *
 */
export class UserComponent implements OnInit, OnChanges
{
	@Input() public _id:               number;
    @Input() public _name:             string = 'Non assigné';
    @Input() public _lastName:         string;
    @Input() public _etablissement:	   string;
    @Input() public _session:          string;
    @Input() public _examen:           string;
    @Input() public _status:           string;
    @Input() public _icone:            string;
    @Input() public _state:            string;
    @Input() public _color:            string = '';
    @Input() public displayType:       string;

	public firstLetterIcone:	string;
    private linkToRoute:		string = '/community';

    constructor(public userData:UserData)
	{

    }


	public ngOnChanges()
	{
		if (this.name)
		{
			this.firstLetterIcone = this.name.charAt(0);
			if (this.lastName)
			{
				this.firstLetterIcone += this.lastName.charAt(0);
			}
			else
			{
				this.firstLetterIcone += this.name.charAt(1);
			}
		}
	}

	
	public ngOnInit()
	{
		if (this.name)
		{
			this.firstLetterIcone = this.name.charAt(0);
			if (this.lastName)
			{
				this.firstLetterIcone += this.lastName.charAt(0);
			}
			else
			{
				this.firstLetterIcone += this.name.charAt(1);

			}
		}
		else
		{
			this.firstLetterIcone = this.status.charAt(0);
			this.firstLetterIcone += this.status.charAt(1);
		}
		

		if (this.color === '' || this.color === undefined)
		{
			this.color = '#ffffff';
		}
    }


	public setIcon()
	{
		if (this.name)
		{
			this.firstLetterIcone = this.name.charAt(0);
			this.firstLetterIcone += this.name.charAt(1);
		}
	}


    get id():               number  { return this._id; }
    get name():             string  { return this._name; }
    get lastName():         string  { return this._lastName; }
    get etablissement():    string  { return this._etablissement; }
    get session():          string  { return this._session; }
    get examen():           string  { return this._examen; }
    get status():           string  { return this._status; }
    get icone():            string  { return this._icone; }
    get color():            string  { return this._color; }
    get state():            string  { return this._state; }

    set id(id)                          { this._id              = id; }
    set name(name)                      { this._name            = name; }
    set lastName(lastName)              { this._lastName        = lastName; }
    set etablissement(etablissement)    { this._etablissement   = etablissement; }
    set session(session)                { this._session         = session; }
    set examen(examen)                  { this._examen          = examen; }
    set icone(icone)                  	{ this._icone           = icone; }
    set status(status)                  { this._status          = status; }
    set color(color)					{ this._color			= color; }
    set state(state)                 	{ this._state           = state; }


	@Input()
	set link(link: string) 
	{

		if (link === undefined || link === 'none')
		{
			this.linkToRoute = undefined;
		}
		else
		{
			this.linkToRoute = link;
		}
	}
}
