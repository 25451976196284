
import { Pipe, PipeTransform }	from '@angular/core';

@Pipe({
	name: 'otherUsersFilter'
})
export class OtherUsersFilter implements PipeTransform
{

	public transform(users: any[], mailCurrentUser: string): any[]
	{
		if (users)
		{
            return users.filter((user) => user.mail !== mailCurrentUser);
		}
		else
		{
			return users;
		}
	}
}