import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { GenericData }				from './generic-data';
import { WorkspaceData }			from './workspace-data';
import { UserData }					from './user-data';
import { Matiere }					from '../classes/matiere.class';
import { Task }						from '../classes/task.class';
import { SocketService }			from '../services/socket.service';

// import { Storage } from '@ionic/storage';
import * as _ from 'underscore';

@Injectable()
/**
 * Class UserData
 */
export class TaskData extends GenericData implements OnDestroy
{

	/**
	 * Stockage des taches récupérées dans l'appli
	 */
	private _taskDatas:					Task[]		= [];
	private _globalTaskDatas:			Task[]		= [];
	private _epreuveByWorkflowSelected: Matiere[]	= [];

	public taskDataEmitter: EventEmitter<any> = new EventEmitter<any>();
	public taskDataEmitter2: EventEmitter<any> = new EventEmitter<any>();


	get epreuveByWorkflowSelected()	{ return this._epreuveByWorkflowSelected; }
	get taskDatas()					{ return this._taskDatas; }
	get globalTaskDatas()			{ return this._globalTaskDatas; }

	set taskDatas(taskDatas)				{ this._taskDatas					= taskDatas; }
	set globalTaskDatas(globalTaskDatas)	{ this._globalTaskDatas				= globalTaskDatas; }
	set epreuveByWorkflowSelected(psParam)	{ this._epreuveByWorkflowSelected	=  psParam; }


	/**
	 *
	 */
	constructor(public workspaceData: WorkspaceData,
				public userData: UserData,
				public socketService: SocketService)
	{
		super();
		this.workspaceData.setTaskData(this);
	}


	ngOnDestroy()
	{
		this.taskDataEmitter.unsubscribe();
	}


	public initTasks()
	{
		this._taskDatas 		= [];
		this._globalTaskDatas 	= [];
	}


	public majSubjectName(psIdSujet: any)
	{

		for (const task of this._globalTaskDatas)
		{
			if (task.idSujet === psIdSujet)
			{
				const oSujet = this.workspaceData.getSujet(psIdSujet);
				task.nameSujet = oSujet.name;
			}
		}

	}


	public buildTask(oTask: any)
	{
		if (oTask)
		{
			const epreuve			= this.workspaceData.getEpreuve(oTask.idEpreuve);
			const sujet				= this.workspaceData.getSujet(oTask.idSujet);
			const session			= this.workspaceData.getSession(oTask.idSession);
			const etablissement		= this.workspaceData.getEtablissement(oTask.idEtablissement);
			const examen			= this.workspaceData.getExamen(oTask.idExamen);

			oTask.nameSession		= session		? session.name			: ' Non autorisé ';
			oTask.nameEtablissement = etablissement	? etablissement.name	: ' Non autorisé ';
			oTask.nameEpreuve		= epreuve		? epreuve.name			: ' Non autorisé ';
			oTask.nameSujet			= sujet			? sujet.name			: ' Non autorisé ';
			oTask.nameExamen		= examen		? examen.name			: ' Non autorisé ';

			oTask.stateLib = 'En cours';

			// récupération du nom de l'état de la tache
			for (const item of oTask.statesAvailable)
			{
				if (item.value === oTask.state)
				{
					oTask.stateLib = item.libelle;
				}
			}

			// Nom du type de tache
			if (epreuve && epreuve.scenario && epreuve.scenario.steps)
			{
				for (const key in epreuve.scenario.steps)
				{
					if (key === oTask.type)
					{
						oTask.typeLib = epreuve.scenario.steps[key].name;
					}
				}
			}
		}

		return oTask;
	}


	/**
	 *  sauvegarde de la liste des taches
	 */
	public addTasks(data: any, pbMajSelected = false)
	{

		for (const task of data)
		{
			let oTask = Task.fromJsonObject(task);
			oTask = this.buildTask(oTask);

			const indexInTaskDatas = _.findIndex(this._taskDatas, (taskObj: any) => { return taskObj.id === oTask.id; });

			if (indexInTaskDatas === -1)
			{
				this._taskDatas.push(oTask);
			}
			else
			{
				this._taskDatas[indexInTaskDatas] = this.buildTask(this._taskDatas[indexInTaskDatas]);
			}

			//On vérifie que la tache à ajouter n'est pas déja présente dans l'ensemble des
			//taches déja récupérées
			const indexInGlobalTasks = _.findIndex(this._globalTaskDatas, (taskObj: any) => { return taskObj.id === oTask.id; });

			if (indexInGlobalTasks === -1)
			{
				this._globalTaskDatas.push(oTask);
			}
			else
			{
				// On met à jour les infos de la tache existante
				this._globalTaskDatas[indexInGlobalTasks] = this.buildTask(this._globalTaskDatas[indexInGlobalTasks]);
			}

			if (pbMajSelected)
			{
				this.workspaceData.selectedTask = oTask;
			}
		}
	}


	setGlobalTask(taskId: any, task: Task)
	{
		const indexInGlobalTasks = _.findIndex(this._globalTaskDatas, (taskObj: any) => { return taskObj.id === taskId; });

		if (indexInGlobalTasks !== -1)
		{
			this._globalTaskDatas[indexInGlobalTasks] = task;
		}
		else
		{
			this._globalTaskDatas.push(task);
		}
	}




	/**
	 * Récupération d'une tache par son identifiant
	 * @param idTask
	 */
	public getTaskById(idTask: any)
	{
		const result = _.find(this._globalTaskDatas, (task: any) => { return task.id === idTask; });;
		return result;
	}

	public listenTask(task: any)
	{
		// Construction du channel que l'on va écouter
		const channel = '*soft::' + this.userData.account + '::creation::task::' + task.id;
		/**
		 * On vérifie si on écoute pas déja sur le channe!
		 * Si ce n'est pas le cas on ajoute une room pour écouter sur ce channel
		 * en transmettant une fonction pour gérer les evènements
		 */
		let value = this.socketService.findRoomAGerer(channel);

		if (value < 0)
		{
			this.socketService.roomsAGerer.push(
				{
					name:		channel,
					state:		'nosub',
					watch:		true,
					fct:		this._handleTaskEvent.bind(this),
					channel:	undefined
				});
		}
	}


	/**
	 * Permet la récupération des épreuves en fonction d'un workflow donné
	 */
	public getEpreuveByWorkflow(workflowId: any)
	{
		let arrayReturn = [];
		let etablissements = this.workspaceData.etablissements;

		for (let etablissement of etablissements)
		{
			for (let session of etablissement.sessions)
			{
				for (let exam of session.examens)
				{
					for (let matiere of exam.matieres)
					{
						if (matiere.scenario)
						{
							// Récupérer l'étape de la derniere tache ! pour savoir ou en est l'épreuve
							if (matiere.scenario.title === workflowId)
							{
								// ---------------------------------------
								// Fuck à partir d'ici il va falloir récupérer les taches par sujets
								// ---------------------------------------
								let maxStep= "0";

								for (let task of this._globalTaskDatas)
								{

									if (task.idEpreuve === matiere.id)
									{
										let typeTask = task.type;

										let indiceOfTask;
										let indiceOfStep;

										// Si la tache correspond l'indice max des taches rattaché à ce sujet pour avoir l'avancement

										// Nom du type de tache
										for (let step in matiere.scenario.steps)
										{

											if (parseInt(step) === parseInt(typeTask))
											{
												indiceOfStep = step;
											}
										}

										if (parseInt(indiceOfStep) > parseInt(maxStep))
										{
											maxStep = indiceOfStep;
										}

									}
								}
								matiere.maxStep = maxStep;

								arrayReturn.push(matiere);
							}
						}
					}
				}
			}
		}

		this._epreuveByWorkflowSelected = arrayReturn;

	}


	/**
	 * Fonction apellé lorsque un tri par noeud est demandé
	 */
	public filterTasks(typeOfFilter: any, id: any = null)
	{

		let resultDatas: any[] = [];
		if (id)
		{
			// Raz des data, si on veut trier sur étab2 et que seulement les etab1 sont dispo ça va pas le faire
			resultDatas = this.globalTaskDatas;

			switch (typeOfFilter)
			{

				case 'etab':
					resultDatas = _.filter(resultDatas, (step: any) =>
					{

						if (step.idEtablissement)
						{
							return step.idEtablissement === id;
						}
						else
						{
							// du code supplémentaire est nécéssaire car certaine tache ont été enregistré sans idEtablissement, du coup on va le chercher dans les sessions
							let oEtablissements = this.workspaceData.etablissements;

							let idEtabFinded;
							for (let etab of oEtablissements)
							{
								for (let session of etab.sessions)
								{
									if (session.id === step.idSession)
									{
										idEtabFinded = etab.id;
									}
								}
							}
							return idEtabFinded === id;
						}
					});

					break;
				case 'sess':
					resultDatas = _.filter(resultDatas, (task: any) => { return task.idSession == id; });
					break;

				case 'exam':
					resultDatas = _.filter(resultDatas, (step: any) => { return step.idExamen === id; });
					break;

				case 'matiere':
					resultDatas = _.filter(resultDatas, (step: any) => { return step.idEpreuve === id; });
					break;

				case 'suj':
					resultDatas = _.filter(resultDatas, (step: any) => { return step.idSujet === id; });
					break;

				default:
					resultDatas = this.globalTaskDatas;
					break;
			}
		}
		else
		{
			// ---------------------------------------
			// Cas aucun => on filtre l'étape d'avant si elle est selectionnée
			// ---------------------------------------
			resultDatas = this.globalTaskDatas;
			let filterId: any;

			switch (typeOfFilter)
			{

				case 'etab':
					break;

				case 'sess':

					filterId	= this.workspaceData.selectedEtablissement.id;
					resultDatas = _.filter(resultDatas, (task: any) => { return task.idEtablissement == filterId; });

					break;
				case 'exam':
					filterId	= this.workspaceData.selectedSession.id;
					resultDatas = _.filter(resultDatas, (task: any) => { return task.idSession == filterId; });

					break;
				case 'matiere':
					filterId	= this.workspaceData.selectedExamen.id;
					resultDatas = _.filter(resultDatas, (task: any) => { return task.idExamen == filterId; });

					break;
				case 'suj':
					filterId	= this.workspaceData.selectedMatiere.id;
					resultDatas = _.filter(resultDatas, (task: any) => { return task.idEpreuve == filterId; });

					break;
			}
		}
		this.taskDatas = resultDatas;
	}


	public getTaskDatas()
	{
		let aoReturn = [];

		for (let key in this._globalTaskDatas)
		{
			aoReturn.push(this._globalTaskDatas[key]);
		}

		return aoReturn;
	}


	private _handleTaskEvent(datas: any)
	{
		if (datas.cmd === 'messages.set')
		{
			if (datas.args && datas.args.message)
			{
				let messageAdded = this.workspaceData.parseHistoric(datas.args.message);

				this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
				this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
			}
		}
	}


}

