<div class="content">
	<div class="grid_header"><div class="grid_header_logo" ngsReveal>		<img  src="../../../assets/img/exatique-lab_blanc.png" alt="" /></div>
		<div class="clear"></div>
		<h2 ngsReveal>{{ 'login.subtitle' | translate }}</h2>
	</div>

	<div class="grid_main" [class.loginError]="isError">
		<div class="credential_background">
			<div class="login_block"><input maxLength="80" class="login_input" (focus)="onFocus()" (keyup)="onKeyUp($event)" [(ngModel)]="username" id="login_username" placeholder="Identifiant / Mail" required (keypress)="eventHandler($event)" />		</div>
			<div class="login_block"><input class="login_input" (focus)="onFocus()" [(ngModel)]="password" id="login_password" placeholder="Mot de passe" type="password" required (keypress)="eventHandler($event)" /></div>
			<div color="warn" ngsReveal class="login_error" *ngIf="isError">{{errorMessage}}</div>
			<div class="submit-form-block" ngsReveal>
				<button (click)="login()" [disabled]="canDoLogin() === false" class="login_button" mat-raised-button id="login_button">{{ 'common.connect' | translate }}</button>
				<div class="clear"></div>

				<div class="create_account_block2">
					<button (click)="launchResetPassword()"  mat-button class="create-account">Mot de passe perdu</button>
				</div>
				<div class="clear"></div>
					
				<!--
			<div class="private-switch">
				<mat-slide-toggle class="private_toggle" color="primary">
					Cet ordinateur est privé
				</mat-slide-toggle>
			</div>a
				-->
			</div>
			<div class="login_wait" *ngIf="isLoading">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
				</svg>
			</div>
			
			<div class="exatech_logo">
				<img src="../../../assets/img/logo_exatech_blanc.png" alt=""/>
			</div>
		</div>
	</div>
</div>
