import { Component, Input, ViewEncapsulation, OnInit, EventEmitter, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { SocketService }	from '../../../services/socket.service';
import { ChatHttpService }	from '../../../services/http/chatHttp.service';
import { RoomService }		from '../../../services/room.service';
import { UserData }			from '../../../providers/user-data';
import { ChatData }			from '../../../providers/chat-data';

declare var socketCluster: any;
import { timer as Timer} from 'rxjs/observable/timer';

import * as _ from 'underscore';

//		ScrollGlue ,
//declare var emoji = require('node-emoji')


@Component({
	selector:		'app-chat-detail',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./chat-detail.component.html',
	outputs:		['_sendMessageEmitter: onSendMessage'],
	styleUrls:		['./chat-detail.component.scss']

})


/**
 * 
 */
export class ChatDetailComponent implements OnChanges, OnInit
{
	@Input() displayMessage:	boolean;
	@Input() isRoomSelected:	any;
	@ViewChild('message2') vc:			ElementRef<any>;
	@ViewChild('textArea') textArea:	ElementRef<any>;

	public _sendMessageEmitter: EventEmitter<string> = new EventEmitter<string>();

	public lastMessageTix:		string;
	public currentUser:			string;
	public messages:			any;
	public message:				any = '';
	public mustScrollBottom:	boolean = false;

	lastDate:	string;
	dateObj:	any;
	month:		any; //months from 1-12
	day:		any;
	year:		any;


	/**
	 * Creates an instance of ChatDetail.
	 *
	 * @param { ChatService } chatService
	 *
	 * @memberOf ChatDetail
	 */
	constructor(public socketService:	SocketService,
				public userData:		UserData,
				public roomService:		RoomService,
				public chatData:		ChatData,
				public chatHttpService:	ChatHttpService)
	{

		// récupération de l'utilisateur en cours
		this.currentUser	= userData.getUserId();
		this.dateObj		= new Date();
		this.month			= this.dateObj.getUTCMonth() + 1; //months from 1-12
		this.day			= this.dateObj.getUTCDate();
		this.year			= this.dateObj.getUTCFullYear();

		this.lastDate = this.year + '' + this.month + '' + this.day;
	}


	/**
	 *
	 */
	public ngOnInit()
	{
		// Récupération de l'utilisateur courant, pour l'emission des sockets
		this.getMessagesAvailable();
		this.mustScrollBottom = true;
		this.userData.globalEmitter.subscribe((value: any[]) => this.chatControl(value[0], value[1]),
			(error: Error) => console.log(error),
			() => console.log('done'));

		this.scrollToBottom();
	}


	/**
	 * 
	 * @param key 
	 * @param value 
	 */
	public chatControl(key: any, value: any)
	{
		if(key === 'notify' && value === 'scrollBottom')
		{
			this.scrollToBottom();
		}
	}


	/**
	 * 
	 */
	ngAfterViewChecked()
	{
		if (this.mustScrollBottom === true)
		{
			this.mustScrollBottom = false;
			this.scrollToBottom();
		}
	}


	/**
	 * 
	 */
	scrollToBottom(): void
	{
		let timer2 = Timer(100);

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		timer2.subscribe((t2: any) =>
		{
			this.vc.nativeElement.scrollTop = this.vc.nativeElement.scrollHeight;
		});

	}


	/**
	 *
	 */
	public ngOnChanges()
	{
		// Récupération de l'utilisateur courant, pour l'emission des sockets
	}


	/**
	 *
	 * @returns Une liste de message en fonction du compte
	 *
	 * @memberOf ChatDetail
	 */
	public getMessagesAvailable()
	{

	}


	/**
	 * 
	 */
	public clearTextArea()
	{
		this.message = '';
		this.textArea.nativeElement.value = '';
	}


	/**
	 * Au cluc sur envoyer, ou la touche "enter", permet d'envoyer le contenu du textarea sous forme de message
	 * Sauf si ce contenu est vide.
	 *
	 *
	 * TODO => prévoir de vider le textarea	après l'envoi
	 */
	public send()
	{
		if (this.message !== '' && this.message !== undefined && this.message !== '\n')
		{
			this._sendMessageEmitter.emit(this.message);
			this.clearTextArea();
		}
		if (this.message === undefined || this.message === '\n')
		{
			this.clearTextArea();
		}
	}


	/**
	 * Est appelé à chaque modification du textarea, permet de garder à jours le message à envoyer une fois le btn send cliqué.
	 */
	public refreshMessage(poValue: any)
	{
		this.message = poValue;
	}


	/**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandler(event: KeyboardEvent): void
	{
		if ((event.key === "Enter" && !event.shiftKey) && (this.message !== '' && this.message !== undefined && this.message !== '\n' && this.message !== null))
		{
			event.preventDefault();
			this.message = this.replaceBr(this.message);
			this.send();
		}

		else if (event.key === "Enter" && !event.shiftKey && (this.message === '\n' || this.message === undefined || this.message === null || this.message === ''))
		{
			event.preventDefault();
			if (this.message)
			{
				this.message = this.replace(this.message);
			}
			this.clearTextArea();
		}
	}


	/**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandlerUp(event: KeyboardEvent): void
	{
		event.preventDefault();

		if (event.key === "Enter" && !event.shiftKey && (this.message === '\n' || this.message === undefined || this.message === null || this.message === ''))
		{
			this.clearTextArea();
		}
	}


	/**
	 * 
	 * @param v 
	 */
	emojiAdded(v: any)
	{
		this.message = v;
	}


	/**
	 * 
	 * @param chaine 
	 */
	replace(chaine: any)
	{
		return (chaine.replace(/\n/g, ""));
	}


	/**
	 * 
	 * @param chaine 
	 */
	replaceBr(chaine: any)
	{
		return (chaine.replace(/\n/g, "<br>"));
	}


	/**
	 * 
	 */
	public handlerFunction()
	{
		if (this.chatData.selectedRoom)
		{
			this.roomService.getMessagesBucket(this.chatData.selectedRoom.id, this.chatData.selectedRoom.firstId, 100, 'true');
		}
	}
}

