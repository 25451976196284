import { Component, ViewEncapsulation, OnInit }		from '@angular/core';

import { AppComponent }	from '../../app.component';

import { MatDialog }	from '@angular/material';
import { UserData }		from '../../providers/user-data';


@Component({
	selector:		'app-community',
	encapsulation:	ViewEncapsulation.None,
	templateUrl:	'./community.component.html',
	styleUrls:		['./community.component.scss']
})


/**
 * 
 */
export class CommunityComponent implements OnInit
{

	public panelSelected					= 0;
    public isChatPanelSelected             = true;
    public isForumtPanelSelected           = false;
    public isNotificationstPanelSelected   = false;
    public mbLoadState                     = false;
	
	
	public canAccessChat:			boolean = true;
	public canAccessForum:			boolean = false;
	public canAccessNotification:	boolean = false;
	

    /**
     * Creates an instance of CommunityPage.
     * 
     * @param {PopoverController} popoverCtrl
     * @param {NavController} nav
     *
     * @memberOf CommunityPage
     */
	constructor(public appComponent:	AppComponent,
				public dialog:			MatDialog,
				public userData:		UserData,)
	{
		this.panelSelected = 0;
//		this.canAccessChat = this.userData.canAccessChat;
		
		// Vérification des droits pour valider l'accès aux différentes parties
		if (this.userData.canAccessForum === true)
		{
			this.canAccessForum = true;
		}
		
		// Modification du header
		appComponent.setPageTitle('Communauté');
	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		// TODO:  voir si c'est tjr d'actualité
		this.panelSelected = 0;
		this.refreshTab(0);
//		this.appComponent.setHeaderColor('#0277bd');
	}


	/**
	 * 
	 */
	public ngOnChange()
	{
	}


	/**
	 *
	 * @memberOf CommunityPage
	 */
	public updateSegment(): void
	{

	}


    /**
     *
     */
	public addCommunityItem(nameButton: any)
	{
		switch (nameButton)
		{
			case 'newTopic':
				break;
				
			case 'publicConversation':
			case 'privateGroup':
				break;
				
			default:
		}
	}

	
	/**
	 * Retourne true une fois la page chargée
	 *
	 * @returns
	 *
	 * @memberOf WorkspacePage
	 */
	public isLoaded(): boolean
	{
		return this.mbLoadState;
	}


    /**
     * Display message for chat, topic and notifications creation. Display equally error messages
     *
     * @returns
     *
     * @memberOf WorkspacePage
     */
	public presentToast(message: any)
	{
	}


	/**
	 * Call on every modification of the tab
	 */
	public refreshTab(e: any)
	{

		// En fonction de l'index on va choisir quelle section est à afficher
		switch (e)
		{
			case 0:
				this.appComponent.setPageTitle('Communauté - Messagerie');
				break;

			case 1:
				this.appComponent.setPageTitle('Communauté - Forum');
				break;

			case 2:
				this.appComponent.setPageTitle('Communauté - Notification');
				break;

			default:
				break;
		}
	}
}
