/**
 * 
 */
export interface IMessage
{
    _userId:   string;
    _content:   string;
    _date:      Date;
}


/**
 * 
 */
export enum SocketWhat
{
	info,
	response,
	request
}


/**
 * 
 */
export interface ISocketData
{
	iam:	string;
	name:	string;
	what:	SocketWhat;
	cmd:	string;
	idc?:	string;
	args:	any;
}