import { Injectable } from '@angular/core';

import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData }					from '../providers/user-data';
import { EncryptService }			from '../services/encrypt.service';


@Injectable()


export class PassationService
{
	constructor(
		private socketService:	SocketService,
		private userData:		UserData,
		private encryptService: EncryptService)
	{

	}


	public createPassation(datas: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam':	'c-c',
						'name': this.userData.mail,
						'what': SocketWhat.info,
						'cmd':	'passation',
						'args': datas
					};

				socket.emit('create', data);
			}
		});
	}


	public getPassations()
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.mail,
						'what': SocketWhat.info,
						'cmd': 'passations',
						'args': {}
					};
				socket.emit('read', data);
			}
		});
	}


	public getCandidatsPassations(idPassation: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.mail,
						'what': SocketWhat.info,
						'cmd': 'candidatsPassations',
						'args': {
							idPassation: idPassation
						}
					};
				socket.emit('read', data);
			}
		});
	}


	public updatePassation(idPassation: any, datasToUpdate: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam':	'c-c',
						'name': this.userData.mail,
						'what': SocketWhat.info,
						'cmd':	'passation',
						'args':
						{
							passationId:	idPassation,
							datasToUpdate:	datasToUpdate
						}
					};
				socket.emit('update', data);
			}
		});
	}


	public sendPassation(passation: any, decryptedFiles: any, subjectsToAdd: any, passwordSelected: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'sendPassation',
					'args': 
					{
						token:				this.userData.getTokenKey(),
						passation:			passation,
						decryptedFiles:		decryptedFiles,
						subjectsToAdd:		subjectsToAdd,
						passwordSelected:	passwordSelected
					} 
				};
				socket.emit('update', data);
			}
		});
	}


	public importResultsPassation(passation: any, moDecryptedFiles: any, needRecalculate: boolean = false)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'importResultsPassations2',
					'args':
					{
						token:				this.userData.getTokenKey(),
						passation:			passation,
						decryptedFiles: 	moDecryptedFiles,
						mustRecalculate: 	needRecalculate
					}
				};
			socket.emit('read', data);
		});
	}


	public importEmargementsPassation(passation: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (passation)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'candidatsEmargement',
					'args':
					{
						token: this.userData.getTokenKey(),
						passation: passation
					}
				};
				socket.emit('read', data);
			}
		});
	}


	public decodePassationFiles(passation: any, subjectsToEncode: any[]): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			const resultEncoding: 	any 			= {};
			const promises: 		Promise<any>[] 	= [];

			let idFileMain: any;
			let idFileSec: 	any;

			if (passation && passation.datas && passation.datas.idFileMain)
			{
				idFileMain = passation.datas.idFileMain;
			}

			if (passation && passation.datas && passation.datas.idFileSec)
			{
				idFileSec = passation.datas.idFileSec;
			}


			for (const subject of subjectsToEncode)
			{
				let qcmFileSubject: any;

				// En fonction du type de sujet (principal ou secondaire) on récupère le
				// fichier qui a été défini comme fichier QCM
				if (idFileMain)
				{
					qcmFileSubject = subject.pj.find((pj: any) => pj.id === idFileMain);
				}

				if (qcmFileSubject === undefined && idFileSec)
				{
					qcmFileSubject = subject.pj.find((pj: any) => pj.id === idFileSec);
				}

				// Si le fichier n'est pas trouvé en on prend par défaut le premier fichier avec une extension JSON
				if (qcmFileSubject === undefined)
				{
					qcmFileSubject = subject.pj.find((pj: any) => pj.extension === 'json');
				}

				if (qcmFileSubject)
				{
					let filesToInclude: any[] = [];
					filesToInclude.push(qcmFileSubject);

					if (qcmFileSubject.path && qcmFileSubject.path !== '')
					{
						const jsonPath = JSON.parse(qcmFileSubject.path);
						const attachedFileQcmIds  = jsonPath.attachedFiles;

						if (attachedFileQcmIds)
						{
							const attachedFileQcm  = subject.pj.filter((pj: any) => attachedFileQcmIds.includes(pj.id));
							filesToInclude = filesToInclude.concat(attachedFileQcm);
						}
					}

					resultEncoding[subject.id] =
					{
						idFile:			[],
						decryptedAes:	[]
					};

					for (const pj of filesToInclude)
					{
						// On ne décrypte que les fichiers que ne sont pas en corbeille
						if (pj.deleted === false)
						{
							promises.push(this._decryptAesKeysForEpreuve(pj));
						}
					}
				}
			}
			Promise.all(promises).then(result =>
			{
				for (const res of result)
				{
					if (res.idSubject && res.idFile)
					{
						resultEncoding[res.idSubject].idFile.push(res.idFile);
						resultEncoding[res.idSubject].decryptedAes.push(res.decryptedAes);
					}
				}
				resolve(resultEncoding);

			}).catch((error) =>
			{
				reject(error);
			});
		});
	}


	private _decryptAesKeysForEpreuve(pj: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			if (pj.encryptedAesKeys && pj.encryptedAesKeys.length > 0)
			{
				this.encryptService.decryptData(pj.encryptedAesKeys[0].val).then((aesDecoded: any) =>
				{
					let result: any =
						{
							idSubject:		pj.idSubject,
							idFile:			pj.id,
							decryptedAes:	aesDecoded
						};
					resolve(result);

				}).catch(error =>
				{
					reject();
				});
			}
			else
			{
				reject();
			}
		});
	}
}
