<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">Création du compte</div>
	</div>
	<div class="dialog_content">
		<mat-card-content>
			<label>Votre nouveau compte est actuellement en cours d'initialisation, veuillez vous reconnecter dans quelques instants.</label>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">Ok</button>
	</div>
</mat-dialog>
