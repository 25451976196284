<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">{{ 'confirmResetRsa.title' | translate }}</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

		<mat-card-content>
			<label>{{ 'confirmResetRsa.message' | translate }}</label>
			<div class="clear"></div>
			<label *ngIf="nameUser"> - {{ nameUser }}</label>
			<div class="clear"></div>
			<label>{{ 'confirmResetRsa.message2' | translate }}</label>
		</mat-card-content>

    </div>
	<div class="dialog_footer">
        <button id="cancel-connect" class="cancel_button" mat-raised-button (click)="cancel()">{{ 'common.cancel' | translate }}</button>
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">{{ 'common.continue' | translate }}</button>
	</div>
</mat-dialog>
