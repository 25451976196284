
/**
 * 
 * @export
 * @class User
 */
export class User
{


    /**
     * 
     * @static
     * @param {any} jsonObj
     * @returns {User}
     * 
     * @memberOf User
     */
    public static fromJsonObject(jsonObj: any): User
    {

        let result = new User();

        result.id               = jsonObj.id				? jsonObj.id				: '';
        result.username         = jsonObj.mail				? jsonObj.mail				: '';
        result.nom				= jsonObj.infos.nom			? jsonObj.infos.nom			: '';
        result.prenom			= jsonObj.infos.prenom		? jsonObj.infos.prenom		: '';
        result.etablissement    = jsonObj.etablissement		? jsonObj.etablissement		: '';
        result.session          = jsonObj.session			? jsonObj.session			: '';
        result.examen           = jsonObj.examen			? jsonObj.examen			: '';
        result.status           = jsonObj.state.isOnline	? jsonObj.state.isOnline	: false;
        result.lastDateCo		= jsonObj.state.active		? jsonObj.state.active		: '';
        result.color			= jsonObj.infos.color		? jsonObj.infos.color		: '#ffffff';

        return result;
    }


    /**
     * Creates an instance of User.
     * 
     * @param {number} [_id=0]
     * @param {string} [_username='']
     * @param {string} [_etablissement='']
     * @param {string} [_session='']
     * @param {string} [_examen='']
     * @param {string} [_status='']
     * 
     * @memberOf User
     */
    constructor(
        private _id:            string	= '',
        private _username:      string	= '',
        private _nom:			string	= '',
        private _prenom:		string	= '',
        private _etablissement: string	= '',
        private _session:       string	= '',
        private _examen:        string	= '',
        private _color:			string	= '',
        private _lastDateCo:    string	= '',
        private _status:        boolean = false
    ){ }


    get id():				string	{ return this._id; }
    get username():			string	{ return this._username; }
    get nom():				string	{ return this._nom; }
    get prenom():			string	{ return this._prenom; }
    get etablissement():	string	{ return this._etablissement; }
    get session():			string	{ return this._session; }
    get examen():			string	{ return this._examen; }
    get color():			string	{ return this._color; }
    get status():			boolean { return this._status; }
    get lastDateCo():		string	{ return this._lastDateCo; }


    set id(id)                          { this._id				= id; }
    set username(username)              { this._username		= username; }
    set nom(nom)						{ this._nom				= nom; }
    set prenom(prenom)					{ this._prenom			= prenom; }
    set etablissement(etablissement)    { this._etablissement	= etablissement; }
    set session(session)                { this._session			= session; }
    set examen(examen)                  { this._examen			= examen; }
    set color(color)					{ this._color			= color; }
    set status(status)                  { this._status			= status; }
    set lastDateCo(lastDateCo)          { this._lastDateCo		= lastDateCo; }


    /**
     * 
     */
    public toJson()
    {
        let result = 
        {
            id:				this.id,
            username:		this.username,
            nom:			this.nom,
            prenom:			this.prenom,
            etablissement:	this.etablissement,
            session:		this.session,
            lastDateCo:		this.lastDateCo,
            examen:			this.examen,
            status:			this.status,
            color:			this.color
        };

        return result;
    }

}


