import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { MatDialog }								from '@angular/material';
import { TranslateService } 						from '@ngx-translate/core';

import { Piece }				from '../../../classes/piece.class';
import { User }					from '../../../classes/user.class';
import { Message }				from '../../../classes/message.class';
import { UserData }				from '../../../providers/user-data';
import { ChatData }				from '../../../providers/chat-data';
import { SocketService }		from '../../../services/socket.service';
import { UserService }			from '../../../services/user.service';
import { RoomService }			from '../../../services/room.service';
import { Filter, OrderType }	from '../../../components/search-bar/popover-search-filter/classes/filter.class';
import { SocketWhat }			from '../../../models/message.model';
import { ISocketData }			from '../../../models/message.model';

import { ModalCreateChatComponent } from '../modals/modal-create-chat/modal-create-chat.component';

import * as _ from 'underscore';


@Component({
    selector:		'app-chat-list',
    encapsulation:	ViewEncapsulation.None,
    templateUrl:	'./chat-list.component.html',
    styleUrls:		['./chat-list.component.scss']
})


/**
 * Composant parent du chat. Gère la liste des personnes et demande
 * au composant détail d'afficher la bonne conversation
 */
export class ChatListComponent implements OnInit, OnDestroy
{
    public pieces:				Piece[];
    public users:				User[];

	//Todo clean : voir à quoi il peut bien servir lui
    public datasPieces:		string;
    public piecesAvailable:	any = {};
    public filters:			Filter[];
    public separatorSearch:	string;
    public dataProperties:	any[];
    public displayMessage:	boolean;

    public room:			string = '';
    public selectedRoomId:	string = '';
    public newRoom:			string = '';
    public currentUser:		string = '';

    //a clean
    public isLoading:		boolean = false;
    public showFab:			boolean = false;
    public firstLoad:		boolean = true;
    public messages:		any;
    public socket:			any;
    public channels:		any;
    public selectedOption:	string;
    public userToken:		any;

    public me:				any;
    public fingerPrint:		any;
	public isFormOpen:		boolean = false;
	public isRoomSelected:	boolean = false;
	public messagesReaded:	any;
	public listener:		any;


	/**
	 * Creates an instance of ChatList.
	 *
	 * @param {ChatService} chatService
	 * Todo finir de renseigner les params
	 * @memberOf ChatList
	 */
    constructor(
		public translate:		TranslateService,
        public userData:		UserData,
        public chatData:		ChatData,
        public roomService:		RoomService,
        public socketService:	SocketService,
        public dialog:			MatDialog)
		{
			this.translate.get('chat.filterName').subscribe((filterName: string) =>
			{
				this.translate.get('chat.filterMessage').subscribe((filterMessage: string) =>
				{
					// Liste des filtres disponible
					this.filters =
					[
						new Filter('name', 			filterName, 	true, 	[{ 'name': 'default', 'order': OrderType.ASC }]),
						new Filter('lastMessage', 	filterMessage, 	false, 	[{ 'name': 'dateMessage', 'order': OrderType.DESC }])
					];

				});
            });

			// Infos pour la recherche
			this.separatorSearch	= ':';
			this.datasPieces		= JSON.stringify(this.chatData.rooms);
			this.dataProperties		=
				[
					{
						tagName: 'default',
						paths: [['_name']]
					},
					{
						tagName: 'etablissement',
						paths: [['_users', '_etablissement']]
					},
					{
						tagName: 'session',
						paths: [['_users', '_session']]
					},
					{
						tagName: 'examen',
						paths: [['_users', '_examen']]
					},
					{
						tagName: 'status',
						paths: [['_users', '_status']]
					},
					{
						tagName: 'dateMessage',
						paths: [['_messages', '_date']]
					}
				];
	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		this.listener = this.userData.globalEmitter.subscribe((value: any[]) => this.chatControl(value[0], value[1]),
			(error: Error) => console.log(error),
			() => console.log('done'));


		// ---------------------------------------
		// Récupérations des infos de base
		// ---------------------------------------
		this.currentUser	= this.userData.getUserId();
		this.userToken		= this.userData.getTokenKey();
		this.isLoading		= true;
		let array: any		= [];
		this.users			= array;
		this.channels		= {};


		// ---------------------------------------
		// A partir de la liste des messages on retrouve les users par rooms
		// ---------------------------------------
		/**
		 * Récupération du socket global pour faire des appels depuis ce fichier
		 */
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			this.socket = socket;
		});


		this.isLoading = false;

		if (this.chatData.selectedRoom !== null && this.chatData.selectedRoom !== undefined)
		{
			this.openPiece(this.chatData.selectedRoom);
		}
	}


	/**
	 * Ouvre la modal pour créer une conversation
	 */
	public openDialog()
	{
		this.showFab = true;
	}


	/**
	 * Ferme la modal de création de conversation
	 */
	public closeDialog()
	{
		this.showFab = false;
	}

	/**
	 * Toggle de la modal de conversation
	 */
	public toggleDialog()
	{
		this.showFab = !this.showFab;
	}


	/**
	 * Activé au clic sur le +.
	 * Envoie une demande de récupération de la liste des users.
	 * A la reception de celle ci la fonction openForm est apzellé
	 */
	public addCommunityItem()
	{
		// ---------------------------------------
		// Récupération de la liste des users pour pourvoir créer la liste des personnes invitable
		// ---------------------------------------
		let userId: string = this.userData.getUserId();
		let eSocketWhat: SocketWhat.request;


		// Demande de la liste des utilisateurs, un écouteur est placé,
		// lorsque la réponse arrive la modal s'ouvre automatiquement
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;
				let data: ISocketData =
					{
						'iam':	'c-c',
						'name': userId,
						'what': eSocketWhat,
						'cmd':	'user',
						'args': { mail:''  }
					};
				socket.emit('read', data);
			}
		});
		this.openForm('chat::getUsers');

	}


	/**
	 * Controller qui à partir d'une commande reçu apelle la bonne fonction
	 * Pour le moment permet d'ouvrir la modale de creation et d'ouvrir une room
	 * après la demande de récup d'info
	 */
	public chatControl(value: any, data: any = null)
	{

		// this.piecesAvailable.pieces.push(newPiece);
		// ---------------------------------------
		// TODO: Faire un switch
		// ---------------------------------------
		if (value === 'chat::getUsers' && !this.isFormOpen)
		{
			this.openForm(value);
		}

		if (value === 'chat::openRoomAfterData')
		{
			this.openRoomAfterData();
		}

		if (value === 'chat::newMessage')
		{
			this.newMessageReceived(data);
		}

		if (value === 'notify2')
		{
//			this.chatData.setSelectedRoomBy(data);
			this.roomService.getMessagesBucket(data);
		}
	}


	public majRooms(newPiece: any)
	{
		// ---------------------------------------
		// Ici rajouter la room à afficher
		// ---------------------------------------
		this.piecesAvailable.pieces.push(newPiece);
	}



	/**
	 * Ouverture du formulaire de création de room,
	 * est automatiquement appelé à la reception de la liste des users.
	 * (un clic sur le btn plus envoie la demande de la liste des users disponible.)
	 */
	public openForm(value: string)
	{
		// Vérification que la commande qui active l'ouverture est la bonne.
		// On vérifie aussi que le chat ne soit pas déjà ouvert (cas du double clic sur le +)
		if (!this.isFormOpen)
		{
			// MAJ de la variable, pour bloquer les futures ouvertures temps que le form n'est pas fermé
			this.isFormOpen = true;

			// Récupération de la liste des users depuis le localstorage et on la passe au formulaire.
			let userList = this.userData.getUsers();

			// Les rooms déjà ouverte pour récup les users déjà en cours
			let rooms = this.chatData.rooms;
			let arrayOfUserRoom = [];
			let userList2 = [];

			// On met tt ça ds un tableau
			for (let room of rooms)
			{
				for (let user of room.users)
				{
					arrayOfUserRoom.push(user.mail);
				}
			}
			// On rajoute le mail de l'user en cours
			arrayOfUserRoom.push(this.userData.getUserId());

			for (let key in userList)
			{
				let finded = false;

				// On compare les mails dispo et les mails déjà en chat
				for (let user of arrayOfUserRoom)
				{
					if (key === user)
					{
						finded = true;
					}
				}

				if (!finded)
				{
					userList2.push(userList[key]);
				}
			}

			// Ouverture de la modal de selection d'user
			let dialogRef = this.dialog.open(ModalCreateChatComponent,
				{
					height:			'50%',
					panelClass:		'responsive_modal',
					width:			'60%',
					data:			{'userList': userList2},
					disableClose:	false
				});
			let userId = localStorage.getItem('userid');


			// ---------------------------------------
			// Après la fermeture, création de la room via socket
			// ---------------------------------------
			dialogRef.afterClosed().subscribe((result: any) =>
			{
				// vérification du result.. si c'est false = erreur, du coup on va pas ajouter
				if (result)
				{
					this.isFormOpen = false;
					let eSocketWhat: SocketWhat.request;


					// Lib auto permet d'avoir un nom automatique en fonction de la personne avec qui on communique.
					this.socketService.getSocketConnection().subscribe((socket) =>
					{
						if (socket)
						{
							this.socket = socket;
							let data: ISocketData =
								{
									'iam':	'c-c',
									'name':	userId,
									'what':	eSocketWhat,
									'cmd':	'room',
									'args':
									{
										'libAuto':		true,
										'owner':		userId,
										'isPublic':		false,
										'isReadOnly':	false
									}
								};
							socket.emit('create', data);
						}
					});
				}
				this.isFormOpen = false;
				dialogRef.close();
			});
		}
	}


	/**
	 * Fonction pour afficher un nouveau message, est bindé directement avec la récup de new message
	 */
	public newMessageReceived(data: any)
	{
		// ---------------------------------------
		// Message = isocket data
		// ---------------------------------------
		if (data.cmd === 'msg')
		{
			if (this.chatData.selectedRoom)
			{
				this.chatData.selectedRoom.counter = 0;
				this.chatData.setCounterByRoom(this.chatData.selectedRoom.id, 0);
				// création de l'objet message et ajout dans la room correspondante (var message en global)
				let arrayKey		= [];
				let arrayMessage	= _.toArray(data.args.messages);

				// Tableau de clef pour les selectionner plus simplement par l'indice
				for(let key in data.args.messages)
				{
					arrayKey.push(key);
				}

				for(let key in arrayKey)
				{
					let values = JSON.parse(arrayMessage[key].val);

					if (values.name === this.chatData.selectedRoom.users[0].mail || values.name === this.userData.getUserId())
					{
						let oMessage = new Message(values.name, values.args.message, arrayMessage[key].dateticks, arrayMessage[key].id);
						this.addMessage(oMessage);


						// Commentaire à rajouter
						if (values.name !== this.userData.getUserId())
						{
							this.socketService.getSocketConnection().subscribe((socket) =>
							{
								if (socket)
								{
									let data: ISocketData =
										{
											'iam': 'c-c',
											'name': this.userData.getUserId(),
											'what': SocketWhat.info,
											'cmd':	'msg.read',
											'args':
											{
												bucket:		'rooms',
												notifier:	['*room::' + this.chatData.selectedRoom.id + '::priv'],
												key:		this.chatData.selectedRoom.id,
												sub:		'M',
												when:		arrayMessage[key].id
											}
										};

									socket.emit('update', data);
								}
							});
						}
					}
				}
			}
		}
	}


	/**
	 *
	 * @param {*} piece
	 * @returns
	 *
	 * @memberOf ChatList
	 */
    public isPieceSelected(piece: any)
    {
    }


	/**
	 * est appelé au clic sur une room.
	 * Va envoyer une demande de récup des messages ... à faire que si on a pas de messages sauvegardé et 0 nouveau
	 */
	public openPiece(piece: any)
	{
		this.roomService.getMessagesBucket(piece.id);
	}


	/**
	 *  Une fois les data de la room reçu pour l'ouverture, on les traites et on ouvre la room
	 *  TODO : Rajouter un appel de messages supplémentaire si y'en a pas assez
	 */
	public openRoomAfterData(messages: any = null)
	{
		this.isRoomSelected = false;

	//ici=> revoir formatage
		let arrayMessage: any[];
		let isFormated	= false;

		if (messages === null && this.chatData.selectedRoom)
		{
			// cas par socket, a été stocké dès la reception des infos => puis emit pr déclancher cette fonction
			arrayMessage		= this.chatData.getMessagesByRoomId(this.chatData.selectedRoom.id);
			this.selectedRoomId = this.chatData.selectedRoom.id;

			if (arrayMessage)
			{
				arrayMessage.reverse();
			}
		}
		else
		{
			isFormated		= true;
			arrayMessage	= messages;
		}

		// ---------------------------------------
		// A partir de la piece selected, récup les infos des users en storage
		// ---------------------------------------
		let messageList = [];
		if(arrayMessage)
		{
			for (let message of arrayMessage)
			{
				// ---------------------------------------
				//  On recoit un Esocket data à transformer en Message
				// ---------------------------------------
				let oMessage		= new Message();
				let eScocketMessage;
				if (!isFormated)
				{
					// eScocketMessage		= JSON.parse(message._content);
					// eScocketMessage		= JSON.parse(message.message);
					oMessage.content	= message._content;
					oMessage.userId		= message._userId;
					oMessage.ticks		= message.ticks;

					let tempConvert = new Date(message._date).getTime();
					// let delta		= Math.round((+new Date - this.date) / 1000);

					tempConvert		= Math.round((tempConvert));
					let dateTest	= new Date(tempConvert);
					oMessage.date	= dateTest;
				}
				else
				{
					oMessage.content	= message._content;
					oMessage.userId		= message._userId;
					oMessage.date		= message._date;
					oMessage.ticks		= message.ticks;
				}

				// On balance les messages dans un tableau pr les insérer à la room par la suite
				if (oMessage.content)
				{
					messageList.push(oMessage);
				}

				if (this.chatData.selectedRoom && this.chatData.selectedRoom.lastDate < oMessage.date)
				{
					this.chatData.selectedRoom.lastId	= oMessage.ticks;
					this.chatData.selectedRoom.lastDate = oMessage.date;
				}

					if (this.chatData.selectedRoom.firstDate > oMessage.date)
					{
						this.chatData.selectedRoom.firstId		= oMessage.ticks;
						this.chatData.selectedRoom.firstDate	= oMessage.date;
					}
			}
		}

		if(this.chatData.selectedRoom)
		{
			this.chatData.selectedRoom.counter = 0;
			this.chatData.setCounterByRoom(this.chatData.selectedRoom.id, 0);
		}

		// modification du boolean pour l'affichage de la room selectionnée
		this.isRoomSelected = true;
		this.userData.globalEmitter.emit(['notify', 'scrollBottom']);
	}


	/**
	 * Construit le JSON pour envoyer le message
	 */
	public sendMessage(psMessage: string)
	{

		// récupération du nom et de l'id de la room en cours
		// TODO: Factoriser et stocker tt ça, userData me semble pas mal pour ça
		if (this.chatData.selectedRoom)
		{
			const name		= '*room::' + this.chatData.selectedRoom.id + '::priv';
			const roomId	= this.socketService.findRoomAGerer(name);

			let eSocketWhat: SocketWhat.request;

			// Cas d'erreur
			if (roomId === -1)
			{
				return;
			}

			// récupération de l'object room, et publication du message
			let oRoom = this.socketService.roomsAGerer[roomId];

			const data: ISocketData =
				{
					// "authToken":	this.userToken,
					'iam':	'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd':	'msg',
					'args': { 'message': psMessage }
				};

			oRoom.channel.publish(data);
		}
	}


	/**
	 * @param {Piece} piece
	 *
	 * @memberOf ChatList
	 */
	public listenPiece(piece: Piece)
	{

		this.displayMessage = true;

		//Gestion des erreursw
		this.socket.on('error', function (err: any)
		{
			throw 'Socket error - ' + err;
		});

		// logout
		this.socket.on('logout', function (err: any)
		{

		});


		// Une fois connecté
		this.socket.on('connect', function ()
		{
			localStorage.setItem(this.userData.getUserId() + '::' + this.userData.account + '::socketCluster.authToken', this.userToken);
		}.bind(this));

		// ---------------------------------------
		// Vérification de la souscription
		// ---------------------------------------
		const isSuscribed = this.socket.isSubscribed(piece.id);

		if (isSuscribed)
		{
			// Vérif watcher
			const arrayWatchers = this.socket.watchers("*room::" + piece.id + "::priv");

			if (arrayWatchers === undefined || arrayWatchers.length === 0)
			{
				this.watchPiece(piece.id);
			}
		}
		else
		{

		}
	}


	/**
	 * 
	 * @param roomId 
	 */
	private watchPiece(roomId: string)
	{
		if (this.channels && this.channels[roomId])
		{
			this.channels[roomId].watch((newMessage: any) =>
			{
				if (newMessage.cmd === 'message')
				{
					const message = new Message(newMessage.name, newMessage.args.value, new Date());
					this.addMessage(message);
					this.messages += message;
				}
			});
		}
	}


	/**
	 *
	 */
	public addMessage(pjMessage: any)
	{
		// Programmation défensive à mettre en place
		if (this.chatData.selectedRoom)
		{
			this.chatData.selectedRoom.addMessage(pjMessage, pjMessage.ticks);

			if (this.chatData.selectedRoom.lastDate < pjMessage.date)
			{
				this.chatData.selectedRoom.lastId	= pjMessage.ticks;
				this.chatData.selectedRoom.lastDate = pjMessage.date;
			}

			if (this.chatData.selectedRoom.firstDate > pjMessage.date)
			{
				this.chatData.selectedRoom.firstId		= pjMessage.ticks;
				this.chatData.selectedRoom.firstDate	= pjMessage.date;
			}

			this.userData.globalEmitter.emit(['notify', 'scrollBottom']);
		}
	}


	/**
	 *
	 */
	public initMessage(pjMessage: any)
	{
	}


    /**
     * @param event
     *
     * @memberOf ChatList
     *
     */
	public updatePiecesAvailable(event: any)
	{

		// ---------------------------------------
		// Update des pieces
		// ---------------------------------------
		if (event.value)
		{
			this.piecesAvailable.pieces = [];
			let value = JSON.parse(event.value);
			for (let i = 0; i < value.length; i++)
			{
				this.piecesAvailable.pieces.push(Piece.fromJsonObject(value[i]));
			}
		}
	}


	/**
	 *
	 */
    public join(): void
	{
        //		this.roomService.join(this.room);
    }


	/**
	 * Create room, when Create-button is pressed and empty newRoom text input
	 */
	public create(): void
	{

		/*
		 * Creation d'un new room() (class)
		 */
		let id:				string	= '';
		let users:			any[]	= [];
		let messages:		any[]	= [];
		let sType:			string	= '';
		let name:			string	= '';
		let displayName:	string	= '';
		let description:	string	= '';
		let state:			boolean = false;


		/*
		 * Si création ok => on ajoute la room a la liste des rooms
		 */
		let newRoom = new Piece(id, users, messages, sType, name,displayName, description, state);
	}


	/**
	 * Remove room, when Remove-button is pressed and unset selected room
	 */
	public remove(): void
	{
		// this.roomService.remove(this.room);
		// this.room = "";
	}


	/**
	 * Handle keypress event (for creating a new room)
	 */
	public eventHandler(event: KeyboardEvent): void
	{

		if (event.key === "Enter")
		{
			// this.create();
		}
	}


	public ngOnDestroy()
	{

		if (!this.firstLoad)
		{
			//Ici on a changé de room, permet d'avoir qu'un seul watch en cours !
		}
		this.listener.unsubscribe();

	}


	public changeFocus(event: any)
	{
	}

}

