import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { ISocketData, SocketWhat } from '../models/message.model';
import { UserData } from '../providers/user-data';


@Injectable()


/**
 * 
 */
export class RoomService
{


	/**
	 * 
	 */
	constructor(public socketService: SocketService,
		private userData: UserData)	
	{

	}


	public getMessages(roomId: any)
	{
		//return this.etablissementHttpService.addEtablissement(dataEtab.toJson()).share();
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'msg',
						'args':
						{
							// Todo faire un truc moins honteux que ça
							key: roomId,
							bucket: 'rooms',
							limit: 100
						}
					};
				socket.emit('read', data);
			}
		});
	}
	
	
	public getMessagesBucket(roomId: any, ticks = '', limit = 100, isMore = 'false')
	{
		
		
		let args = 
		{
			// Todo faire un truc moins honteux que ça
			toDate:		ticks,
			key:		roomId,
			bucket:		'rooms',
			limit:		limit,
		};
		

		
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam':		'c-c',
						'name':		this.userData.getUserId(),
						'what':		SocketWhat.info,
						'cmd':		'msg',
						'idc':		isMore,
						'args':		args
					};
				socket.emit('read', data);
			}
		});
	}


	/**
	 * 
	 */
	public getNomberOfUnreadedMessage(roomId: any)
	{
		//return this.etablissementHttpService.addEtablissement(dataEtab.toJson()).share();
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'msg.unread',
						'args':
						{
							// Todo faire un truc moins honteux que ça
							chan: '*room::' + roomId + '::priv'
						}
					};
				socket.emit('read', data);
			}
		});
	}
}
