// Angular
import { Component, OnInit, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
// Neoptec
import { Task }				from '../../model/task';
import { TicketComponent }	from '../ticket/ticket.component';


@Component({
	selector:		'lane',
	inputs:			['tasks', 'type', 'laneTitle', 'libelle'],
	outputs:		['_onTicketEdit: onTicketEdit', '_onAddSubTicket: onAddSubTicket', '_onTicketRemove: onTicketRemove', '_onTicketSelect: onTicketSelect', '_onTicketFocus: onTicketFocus'],
	encapsulation:	ViewEncapsulation.None,
	host:			{ 'class': 'lane'	},
	templateUrl:	'./lane.component.html',
	styleUrls:		['./lane.component.css']
})


/**
 *
 */
export class LaneComponent implements OnInit
{
	@Input('tasks')			tasks:		Task[] = [];
	@Input() typeTicket: string;
	@Input('laneTitle')		laneTitle:	any;


	public _onTicketEdit:	EventEmitter<Task> = new EventEmitter<Task>();
	public _onTicketRemove:	EventEmitter<Task> = new EventEmitter<Task>();
	public _onTicketSelect:	EventEmitter<Task> = new EventEmitter<Task>();
	public _onTicketFocus:	EventEmitter<Task> = new EventEmitter<Task>();
	public _onAddSubTicket:	EventEmitter<Task> = new EventEmitter<Task>();


	/**
	 *
	 */
	public ngOnInit()
	{
	}


	/**
	 * Transmet l'event d'edition
	 */
	public editTicket(task: Task)
	{
		this._onTicketEdit.emit(task);
	}

	/**
	 *
	 * @param task
	 */
	public addSubTicket(task: Task)
	{
		this._onAddSubTicket.emit(task);
	}


	/**
	 * Transmet l'event de suppression
	 */
	public removeTicket(task: Task)
	{
		this._onTicketRemove.emit(task);
	}


	/**
	 * Transmet l'event de selection pour affichage détaillé
	 */
	public selectTicket(task: Task)
	{
		this._onTicketSelect.emit(task);
	}


	/**
	 * Focus => pour savoir qui a été Drag and dropé
	 */
	public focusTicket(task: Task)
	{
		this._onTicketFocus.emit(task);
	}
}
