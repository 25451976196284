<div class="dialog_qcm_to_bareme_content" [class.showed]="isShowed">


	
	<div class="dialog_qcm_to_bareme_content_header"  [class.showed]="isShowed" >


		<div class="show_bareme_block">
			<button mat-raised-button class="show_bareme" (click)="showBareme()" [class.showed]="isShowed" ><mat-icon class="show_bareme_icon">expand_more</mat-icon></button>
		</div>

		<div class="form_type" *ngIf="majSelectedBareme">
			<div class="form_type_title">B-{{majSelectedBareme.datas.number}} - {{majSelectedBareme.datas.name}}</div>
			<div class="form_type_title form_type_title_last">Type de barème : {{ majSelectedBareme.datas.name }}</div>


			<div class="form_type_inner"  [class.showed]="isShowed" *ngFor="let jSelectedTypeInput of majSelectedBareme.datas.data">
				 <div class="form_type_inner_block" *ngIf="jSelectedTypeInput.optionnal" [class.optionnalOff]="!jSelectedTypeInput.isOptionChecked">
					<span class="form_type_inner_name"> {{ jSelectedTypeInput.name }} : </span>
					<span class="form_type_inner_value">{{ jSelectedTypeInput.value }} </span>
				</div>
				<div class="form_type_inner_block" *ngIf="!jSelectedTypeInput.optionnal">
					<span class="form_type_inner_name"> {{ jSelectedTypeInput.name}} : </span>
					<span class="form_type_inner_value"> {{ jSelectedTypeInput.value }} </span>
				</div>
			</div>
		</div>		
	</div>
	
	
	
	<div class="dialog_qcm_to_bareme_content_menu" *ngIf="majMainQCM">
	<div class="dialog_qcm_to_bareme_global">

		<mat-form-field class="dialog_qcm_to_bareme_global_form"> 
			<input matInput maxLength="80"  placeholder="Notation globale" type="number"  min="1" [(ngModel)]="passation.datas.notation" [ngModelOptions]="{standalone: true}">
		</mat-form-field>
	</div>
		
		<div class="dialog_qcm_to_bareme_content_menu_div" *ngFor="let part of majData.parts">
			<button mat-button class="dialog_qcm_to_bareme_content_menu_part" (click)="selectPart(part)" [class.selected]="isPartSelected && msActivePart.id === part.id" >Partie {{part.id + 1}} </button>
			<div class="clear"></div>
			<div *ngFor="let question of part.questions" [class.selected]="msActiveQuestion && msActiveQuestion.id === question.id && msActiveQuestion.partId === part.id">
				<button mat-button (click)="selectQuestion(part, question)" class="dialog_qcm_to_bareme_content_menu_div_inner"> <div>Question {{part.id + 1 }}.{{question.id + 1}} : <span class="bareme_upper">{{question.typeresponses}}</span></div> <div>{{moBaremeData.getBaremeNameById(question.baremeId)}}</div></button>
				<div class="clear"></div>
			</div>
		</div>
	</div>

	<div class="dialog_qcm_to_bareme_content_middle" *ngIf="isQuestionSelected">


		<div class="block_grid_question">

			<div class="block_qcm_question">
				<div class="type_block">
					<div  class="type_question_intro_num">Partie {{msActiveQuestion.partId + 1}}  Question {{msActiveQuestion.id + 1}}</div>
					<div *ngIf="msActiveQuestion.typeresponses === 'qcu'" class="type_question_intro">
						<div  class="type_question_intro_title">Question à choix unique</div>
						<div  class="type_question_intro_text">Choisissez la bonne réponse</div>
					</div>
					<div *ngIf="msActiveQuestion.typeresponses === 'qcm'"  class="type_question_intro">
						<div  class="type_question_intro_title">Question à choix multiple</div>
						<div  class="type_question_intro_text">Choisissez une ou plusieurs réponses</div>
					</div>
					<div *ngIf="msActiveQuestion.typeresponses === 'vf'"  class="type_question_intro">
						<div class="type_question_intro_title">Vrai ou faux</div>
						<div class="type_question_intro_text">Choisissez la bonne réponse</div>
					</div>
				</div>
				<!--<div class="" [innerHTML]="msActiveQuestion.intro" ></div>-->
				<quill-editor class="bloc-left-part-question_html " [modules]="quillModules"  [readOnly]="true" [(ngModel)]="msActiveQuestion.intro" [ngModelOptions]="{standalone: true}"	></quill-editor>
			</div>
			
			<div class="block_qcm_response">
				<div *ngFor="let response of msActiveQuestion.responses; let index = index; "  class="block_qcm_response_item" [class.true_rep]="response.isTrue || response.isCorrectState">
					<div class="block_qcm_response_inner">
						<div class="block_qcm_response_header">
							<div class="block_qcm_question_header_title" >
								<h3>réponse n°{{ index + 1 }}</h3>
							</div>
							<!--(change)="onChangeRep(mjQuestion.typeresponses, response);" msActiveQuestion.responses[index].isCanceled-->
							<span *ngIf="!response.isCorrectState" ><mat-checkbox class="true_rep_check" (change)="onChangeRep(response);" [(ngModel)]="response.isCanceled">Invalider la réponse?</mat-checkbox></span>
						</div>
						<div class="block_qcm_response_content">
							<span>{{ response.name }}</span>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
	
	
	<div class="dialog_qcm_to_bareme_content_middle" *ngIf="isPartSelected">
		<div class="type_block">
			<div class="type_question_intro_num">Partie {{msActivePart.id + 1}}  </div>

		</div>

	</div>
	
	
	<div class="dialog_qcm_to_bareme_content_option" *ngIf="isQuestionSelected">
		
		<mat-form-field>
			<input matInput maxLength="80"  placeholder="Coefficient de la question" type="number" (change)="changeCoefQuestion()" min="1" [(ngModel)]="msActiveQuestion.coef" [ngModelOptions]="{standalone: true}">
		</mat-form-field>

		<mat-form-field>
			<mat-select placeholder="Bareme" [(ngModel)]="msActiveQuestion.baremeId" (ngModelChange)="changeSelected($event)">
				<mat-option *ngFor="let bareme of moBaremeData.getBaremeByPassation(passationId)" [value]="bareme.id">B-{{bareme.datas.number}} - {{bareme.datas.name}}</mat-option>
			</mat-select>
		</mat-form-field>


		<!--<button mat-button (click)='associateBareme()' *ngIf="msActiveQuestion && msActiveQuestion.baremeId" class="dialog_bareme_button">Associer le barème</button>-->
		<button mat-raised-button (click)='editBareme()' *ngIf="msActiveQuestion && msActiveQuestion.baremeId" class="dialog_bareme_button">Editer le barème</button>
		<button mat-raised-button (click)='addBareme()' class="dialog_bareme_button">Ajouter un barème</button>

		<span ><mat-checkbox class="true_rep_check" *ngIf="msActiveQuestion && msActiveQuestion.baremeId" [(ngModel)]="msActiveQuestion.isCanceled">Invalider la question ?</mat-checkbox></span>

	</div>
	<div class="dialog_qcm_to_bareme_content_option" *ngIf="isPartSelected">
		<mat-form-field>
			<input matInput maxLength="80"  placeholder="Coefficient de la partie" type="number"  min="1"  (change)="changeCoefPart()" [(ngModel)]="msActivePart.coef" [ngModelOptions]="{standalone: true}">
		</mat-form-field>
		
		<mat-form-field>
			<mat-select placeholder="Bareme" [(ngModel)]="msPartSelectedBaremeId" (ngModelChange)="changeSelectedGlobal($event)">
				<mat-option *ngFor="let bareme of moBaremeData.getBaremeByPassation(passationId)" [value]="bareme.id">B-{{bareme.datas.number}} - {{bareme.datas.name}}</mat-option>
			</mat-select>
		</mat-form-field>

		<!--<button mat-button (click)='associateBareme()' *ngIf="msActiveQuestion && msActiveQuestion.baremeId" class="dialog_bareme_button">Associer le barème</button>-->
		<button mat-raised-button (click)='activeBaremeForPart()' 	*ngIf="majSelectedBareme" 	class="dialog_bareme_button">Appliquer le barème</button>
		<button mat-raised-button (click)='editBareme()' 			*ngIf="majSelectedBareme" 	class="dialog_bareme_button">Editer le barème</button>
		<button mat-raised-button (click)='addBareme()'											class="dialog_bareme_button">Ajouter un barème</button>
	</div>
</div>
