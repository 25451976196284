import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }							from '@angular/material';
import { MAT_DIALOG_DATA }						from '@angular/material';


@Component({
	selector:		'warning-rsa-modal',
	templateUrl:	'warningRsaModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./warningRsaModal.component.scss']
})


/**
 * 
 */
export class WarningRsaModalComponent
{
	public typeWarning: number = 1;


	/**
	 * 
	 * @param dialogRef 
	 * @param data 
	 */
	constructor(public dialogRef: MatDialogRef<WarningRsaModalComponent, any>,
		@Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.typeWarning)
		{
			this.typeWarning = data.typeWarning;
		}
	}


	/**
	 * 
	 */
	public validate()
	{
		this.dialogRef.close();
	}
}
