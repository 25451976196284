import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }							from '@angular/material';
import { MAT_DIALOG_DATA }						from '@angular/material';


@Component({
	selector:		'info-reset-rsa-modal',
	templateUrl:	'infoResetRsaModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./infoResetRsaModal.component.scss']
})


/**
 * 
 */
export class InfoResetRsaModalComponent
{
	public user: any;


	/**
	 * 
	 * @param dialogRef 
	 * @param data 
	 */
	constructor(public dialogRef: MatDialogRef<InfoResetRsaModalComponent, any>,
		@Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data)
		{
			if (data.user)
			{
				this.user = data.user;
			}
		}
	}


	/**
	 * 
	 */
	public validate()
	{
		this.dialogRef.close();
	}
}
