import { Component, ViewEncapsulation, Inject }				from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators }	from '@angular/forms';
import { MatDialogRef }										from '@angular/material';
import { MAT_DIALOG_DATA }									from '@angular/material';

import { UserData }			from '../../../providers/user-data';


@Component({
    selector:		'select-assigned',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'select-assigned.component.html',
    styleUrls:		['./select-assigned.component.scss']
})


/**
 * Modal permettant l'ajout d'un nouveau commentaire pour un élement donné
 */
export class SelectAssignedComponent
{
    //Identifiant de l'élément concerné par le nouveau commentaire
    public _idElement: string;
    //objet Comment correspondant au commentaire en édition
    public _currentComment: Comment;

    public moFormUser:			FormGroup;
    public moFormCtrlUser:		FormControl;
    public moFormCtrlSendEmail:	FormControl;
    public selectedUser:		any;
    public sendEmail:			boolean = false;
    public usersAvailable:		any[] = [];


    constructor(public dialogRef:   MatDialogRef<SelectAssignedComponent, any>,
                public fb:          FormBuilder,
                public userData:    UserData,
                @Inject(MAT_DIALOG_DATA) public data: any)
	{
        this.userData.getUsers();

        const allUsers: any = this.userData.getUsers();

        if (allUsers)
        {
            for (const key in allUsers)
            {
                if (allUsers[key])
                {
                    let libelle = key;
                    if (allUsers[key].infos && allUsers[key].infos.nom && allUsers[key].infos.prenom)
                    {
                        libelle = allUsers[key].infos.prenom + ' ' + allUsers[key].infos.nom;
                    }

					this.usersAvailable.push(
						{
							value:		key,
							libelle:	libelle
						});
				}
            }
        }


        this.moFormCtrlUser			= this.fb.control(this.selectedUser, Validators.required);
        this.moFormCtrlSendEmail	= this.fb.control(this.sendEmail);

        this.moFormUser = this.fb.group(
        {
            user:		this.moFormCtrlUser,
            sendEmail:	this.moFormCtrlSendEmail
        });


        if (data)
        {
            if (data.sendMailDefaultValue)
            {
                this.sendEmail = data.sendMailDefaultValue;
            }
        }
	}


	public cancel()
    {
        this.dialogRef.close(null);
    }


    public canValidate()
    {
        let result = false;

        if (this.selectedUser !== undefined && this.selectedUser !== '')
        {
            result = true;
        }

        return result;
    }


    public vadidateUser()
    {
        const result: any = {};
        result.user = this.selectedUser;

        if (this.sendEmail === true)
        {
            result.typeEmail = 'newTask';
        }

        this.dialogRef.close(result);
    }
}
