<div>
    <mat-form-field>
        <input type="password" matInput (keyup)="onKeyPassword($event)" placeholder="{{ 'common.password' | translate }}" [(ngModel)]="password1"/>
    </mat-form-field>
    <div class="clear"></div>
    <mat-form-field>
        <input type="password" matInput (keyup)="onKeyPasswordConfirm($event)" placeholder="{{ 'passwordEditor.passwordConfirm' | translate }}" [(ngModel)]="password2"/>
    </mat-form-field>
    <div class="clear"></div>
    {{message}}


	<div class="mdp_validator">

		<div ngClass="{{symbool.isit}}" class="mdp_validator_line">
			<mat-icon *ngIf="symbool.isit">done</mat-icon>
			<mat-icon *ngIf="!symbool.isit">clear</mat-icon>
			<span>{{ 'passwordEditor.specialChar' | translate }}</span>
		</div>
		<div ngClass="{{nucbool.isit}}" class="mdp_validator_line">
			<mat-icon *ngIf="nucbool.isit">done</mat-icon>
			<mat-icon *ngIf="!nucbool.isit">clear</mat-icon>
			<span>{{ 'passwordEditor.uppercaseChar' | translate }}</span>
		</div>
		<div ngClass="{{numbool.isit}}" class="mdp_validator_line">
			<mat-icon *ngIf="numbool.isit">done</mat-icon>
			<mat-icon *ngIf="!numbool.isit">clear</mat-icon>
			<span>{{ 'passwordEditor.number' | translate }}</span>
		</div>
		<div *ngIf="passwordCurrent && passwordCurrent === password1" class="mdp_validator_line error">
			<span>{{ 'passwordEditor.samePassword' | translate }}</span>
		</div>


	</div>

</div>
