import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }					        from '@angular/material';
import { MAT_DIALOG_DATA }                      from '@angular/material';


@Component({
	selector:		'confirm-reset-rsa-modal',
	templateUrl:	'confirmResetRsaModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./confirmResetRsaModal.component.scss']
})


export class ConfirmResetRsaModalComponent
{
	public nameUser: any;


	constructor(public dialogRef: MatDialogRef<ConfirmResetRsaModalComponent, any>, @Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.user && data.user.completeName)
        {
			this.nameUser = data.user.completeName;
		}
	}


	public cancel()
	{
		this.dialogRef.close(false);
	}


	public validate()
	{
		this.dialogRef.close(true);
	}
}
