import { Component, ViewEncapsulation, Inject, EventEmitter, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef }		from '@angular/material';
import { MAT_DIALOG_DATA }	from '@angular/material';
import { DomSanitizer }		from '@angular/platform-browser';
import { Observable }		from 'rxjs';

import { File as ExatiqueFile }	from '../../classes/file.class';
import { FileService }          from '../../services/file.service';
import { MessagesService }		from '../../services/messages.service';

import { UserData }			from '../../providers/user-data';
import { WorkspaceData }	from '../../providers/workspace-data';

import { CreationHttpService }			from '../../services/http/creationHttp.service';
import { EditFileNameModalComponent }	from '../edit-file-name-modal/editFileNameModal.component';
import { FileUploader, FileItem }		from 'ng2-file-upload';

declare const require: any;

const fileSaver   = require('file-saver');


@Component({
    selector:		'preview-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'previewModal.component.html',
    styleUrls:		['./previewModal.component.scss']
})


/**
 * Modal permettant l'affichage du contenu d'un fichier avec la possibilité de visualiser et d'ajouter des commentaires
 */
export class PreviewModalComponent implements OnInit
{

    public contentLoaded:		boolean = false;
	public hasPDF:				boolean = true;
	public hasImg:				boolean = false;
	public hasVideo:			boolean = false;
    public currentFile:			ExatiqueFile;
    public moFileSubscription:	any;
    public objSrc:				any;
	public previewProcessing:	boolean = false;

	public canDownloadGeneratedFile: boolean = false;

	public canConvertFileFile: boolean		= false;
	public convertFormatsAvailable: any[]	=
		[
			{ value: 'html', libelle: 'HTML' }
		];

	public convertNameSelected:		any;
	public convertFormatSelected:	any;

	public currentPdfBlob:		any;
	public messageLoading:		string = 'Chargement...';
	public percentLoading:		number = 0;

	public uploaderForConvert:	any;

	public currentPdfDocument: any;

	constructor(public dialogRef:		MatDialogRef<PreviewModalComponent, any>,
				public dialog: 			MatDialog,
                public userData:		UserData,
                public workspaceData:	WorkspaceData,
				public fileService:		FileService,
				public messagesService: MessagesService,
				public sanitizer:	 	DomSanitizer,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
        if (this.data && this.data.file)
        {
            this.contentLoaded	= false;
            this.currentFile	= this.data.file;
        }
    }


    public ngOnInit()
	{
		this._initPreviewFromFileDetails();

		const apiUrl				= CreationHttpService.getAPIUrl();
		const pathFileSendToConvert	= CreationHttpService.getPathByWebServiceName('filesendToConvert');
		const urlAbsolute			= CreationHttpService.absolute(apiUrl, pathFileSendToConvert);
		const userTokenKey			= this.userData.getTokenKey();
		this.uploaderForConvert 	= new FileUploader({ url: urlAbsolute, authToken: 'Bearer ' + userTokenKey });

		this.uploaderForConvert.onAfterAddingFile = (fileItem: FileItem) =>
		{
			if (fileItem)
			{
				this.uploaderForConvert.uploadItem(fileItem);
			}
		};

		this.uploaderForConvert.onBuildItemForm = (item: any, form: any) =>
		{
			form.append('epreuveId',		this.workspaceData.selectedMatiere.id);
			form.append('subjectId',		this.workspaceData.selectedSujet.id);
			form.append('fingerprint',		this.userData.getFingerPrint().toString());
			form.append('format',			this.convertFormatSelected);
			form.append('nameConverted',	this.convertNameSelected);
			form.append('fileTypes',		JSON.stringify(['sujet']));

			this.convertFormatSelected = null;

			return { item, form };
		};
    }


	/**
	 */
    public closeModal()
	{
		if (this.currentPdfDocument)
		{
			this.currentPdfDocument.destroy();
		}

		this.dialogRef.close();
	}


	/**
	 * 
	 * @param event 
	 */
	public onLoadedPdf(event: any)
	{
		this.currentPdfDocument = event;
		this.previewProcessing = false;
	}
	

	/**
	 * 
	 * @param event 
	 */
	public onProgressPdf(event: any)
	{
		if (this.currentPdfBlob.size && event.loaded)
		{
			this.percentLoading = Math.round((event.loaded / this.currentPdfBlob.size) * 100);
			this.messageLoading = 'Chargement: ' + String(this.percentLoading) + '%';
		}
	}


	/**
	 * 
	 * @param event 
	 */
	public onRenderedPagePdf(event: any)
	{
	}


	/**
	 * 
	 */
	public downloadPreviewFile()
	{

		window.URL 			= this.workspaceData.urlCreator;
		// On recréer le nom pour ajouter l'extension PDF
		const currentName 	= this.currentFile.name;
		
		let newName;
		const partsCurrentName = currentName.split('.');

		if (partsCurrentName.length > 1)
		{
			partsCurrentName.pop();
			partsCurrentName.join('.');
			newName = partsCurrentName + '.pdf';
		}
		else 
		{
			newName = partsCurrentName[0] + '.pdf';
		}

		// On télécharge le PDF prévisualisé
		fileSaver.saveAs(this.currentPdfBlob, newName);
	}


	/**
	 * 
	 */
	public downloadFile() 
	{
		window.URL			= this.workspaceData.urlCreator;
		const currentName	= this.currentFile.name;
		
		fileSaver.saveAs(this.currentPdfBlob, currentName);
	}


	/**
	 * 
	 * @param format 
	 */
	public clickConvert(format: any)
	{
		const dialogRef = this.dialog.open(EditFileNameModalComponent,
			{
				height:			'40%',
				width:			'40%',
				panelClass:		'responsive_modal',
				disableClose:	true,
				data:
				{
					'subject':		this.workspaceData.selectedSujet,
					'file':			this.currentFile,
					'format':		format,
				}
			});

		dialogRef.afterClosed().subscribe((resultNameConvert: any) =>
		{
			if (resultNameConvert)
			{
				let detailsFile: any = this.workspaceData.getTreeElement(this.currentFile.id);

				if (detailsFile)
				{
					let extension	= detailsFile.extension.toLowerCase();
					let mimetype	= this._getMimeType(extension);
					let obsContent	= this.fileService.downloadContentFile(detailsFile);

					obsContent.subscribe((res) =>
					{
						let body = (<any> res)._body;

						this.fileService.decryptFileWithAES(detailsFile, body, mimetype).then((blobResult) => {

							let newFileContent			= new File([blobResult], detailsFile.name);
							this.convertNameSelected	= resultNameConvert;
							this.convertFormatSelected	= format;

							this.uploaderForConvert.addToQueue([newFileContent]);
						});
					});
				}
			}

		});
	}


	/**
	 * 
	 */
	private _initPreviewFromFileDetails()
	{
		const detailsFile: any = this.workspaceData.getTreeElement(this.currentFile.id);

		if (detailsFile)
		{
			let obsPreview: Observable<any>;
			let mustDecrypt: boolean;

			const extension = detailsFile.extension.toLowerCase();

			const mimetype = this._getMimeType(extension);

			if (extension    === 'context'
				|| extension === 'doc'
				|| extension === 'docx'
				|| extension === 'odt'
				|| extension === 'ctx'
				|| extension === 'ltx'
				|| extension === 'tex'
				|| extension === 'txt'
				|| extension === 'tif'
				|| extension === 'html'
				|| extension === 'xls'
				|| extension === 'xlsx')
			{
				//Cas d'exception pour les fichier tif (ce ne sont pas des pdf mais des jpg)
				if (extension === 'tif')
				{
					this.hasPDF		= false;
					this.hasImg		= true;
					this.hasVideo	= false;
				}

				if (extension === 'docx' || extension === 'doc')
				{
					this.canConvertFileFile = true;
				}

				obsPreview = this.fileService.downloadContentFile(detailsFile, 'pdf');
				mustDecrypt = false;
			}
			else
			{
				if (extension	 === 'png'
					|| extension === 'bmp'
					|| extension === 'jpg'
					|| extension === 'jpeg')
				{
					this.hasPDF		= false;
					this.hasImg		= true;
					this.hasVideo	= false;
				}
				else
				{
					if (extension    === 'mp4'
						|| extension === 'avi'
						|| extension === 'mkv')
					{
						this.hasPDF		= false;
						this.hasImg		= false;
						this.hasVideo	= true;
					}
				}

				mustDecrypt = true;
				obsPreview 	= this.fileService.downloadContentFile(detailsFile);
			}

			obsPreview.subscribe((res) =>
			{
				const body = (<any> res)._body;

				if (mustDecrypt === true)
				{
					this.fileService.decryptFileWithAES(detailsFile, body, mimetype).then((blobResult) =>
					{
						this._displayPreviewFile(blobResult, detailsFile);
					});
				}
				else
				{
					this._displayPreviewFile(body, detailsFile);
				}
			});
		}
	}
	

	/**
	 * 
	 * @param blob 
	 * @param file 
	 */
	private _displayPreviewFile(blob: Blob, file: ExatiqueFile)
	{
		this.currentPdfBlob		= blob;
		this.previewProcessing	= true;
		this.percentLoading		= 0;

		if (blob)
		{
			this.contentLoaded	= true;
			const urlCreator	= this.workspaceData.urlCreator;

			const datasNotification: any =
				{
					action: 'read',
					name:	file.name,
					user:	this.userData.mail
				};

			this.messagesService.sendMessage('preview', this.currentFile.id, 'H', datasNotification);

			if (this.hasImg || this.hasVideo)
			{
				this.objSrc	= this.sanitizer.bypassSecurityTrustResourceUrl(urlCreator.createObjectURL(blob));
			}
			else
			{
				this.objSrc						= urlCreator.createObjectURL(blob);
				this.canDownloadGeneratedFile	= true;
			}
		}
	}
	

	/**
	 *
	 */
	private _getMimeType(extension: string)
	{
		let oReturn: any;
		switch (extension)
		{
			case 'jpg':
				oReturn = 'image/jpeg';
				break;

			case 'zip':
				oReturn = 'application/zip';
				break;

			default:
				oReturn = 'application/octet-stream';
				break;
		}
		return oReturn;
	}
}
