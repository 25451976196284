<form (ngSubmit)="vadidateMail()" [formGroup]="moFormMail" class="dialog_form">
    <div class="dialog_grid_container nomenu">
        <div class="dialog_header">
            <div class="dialog_header_title">
                {{ 'sendMail.title' | translate }}
            </div>
			<div class="dialog_header_close">
				<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
			</div>
        </div>

        <div class="dialog_content">
            <div>
				<label>{{ 'sendMail.infos' | translate }}</label>
				<div class="clear"></div>

                <mat-checkbox [(ngModel)]="sendEmail" formControlName="sendEmail">{{ 'sendMail.sendMail' | translate }}</mat-checkbox>
            </div>
        </div>

        <div class="dialog_footer">
            <div class="dialog_footer_cancel_button">
                <button mat-raised-button type="button" (click)='cancel()'>{{ 'common.cancel' | translate }}</button>
            </div>
            <div class="dialog_footer_valid_button">
                <button mat-raised-button type="submit">{{ 'common.validate' | translate }}</button>
            </div>
        </div>
    </div>
</form>
