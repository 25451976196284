import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'underscore';


@Injectable()
export class AdminData implements OnDestroy
{

	private _generalParameters: 	any = {};
	private _mailsTemplates: 		any[] = [];
	private _scenariosAvailable: 	any[] = [];
	private _scenarioSelected: 		any;

	public adminEmitter: EventEmitter<any> = new EventEmitter<any>();


    /**
	 *
	 */
	constructor()
	{

	}

	ngOnDestroy()
	{
		this.adminEmitter.unsubscribe();

	}

	get generalParameters():	any		{ return this._generalParameters; }
	get scenariosAvailable():	any[]	{ return this._scenariosAvailable; }
	get mailsTemplates():		any[]	{ return this._mailsTemplates; }

	get scenarioSelected(): any
	{

		let indice = 0;
		if (this._scenarioSelected)
		{
			for (let steps of this._scenarioSelected.steps)
			{

				steps.id = indice;
				indice++;
			}
			return this._scenarioSelected;
		}
	}

	set generalParameters(generalParameters: any)		{ this._generalParameters	= generalParameters; }
	set scenariosAvailable(scenariosAvailable: any[])	{ this._scenariosAvailable	= scenariosAvailable; }
	set mailsTemplates(mailsTemplates: any[])			{ this._mailsTemplates 		= mailsTemplates; }

	set scenarioSelected(scenarioSelected: any)
	{

		let indice = 0;

		for (const steps of scenarioSelected.steps)
		{

			steps.id = indice;
			indice++;
		}
		this._scenarioSelected = scenarioSelected;
	}


	public isNumericEnabled()
	{
		let result = false;

		if (this._generalParameters && this._generalParameters['numericEnabled'])
		{
			result = Number(this._generalParameters['numericEnabled']) === 1;
		}

		return result;
	}


	public isSpecialTimeEnabled()
	{
		let result = false;

		if (this._generalParameters && this._generalParameters['specialTimeEnabled'])
		{
			result = Number(this._generalParameters['specialTimeEnabled']) === 1;
		}

		return result;
	}


	public isSpecialTimeArray()
	{
		let result = false;

		if (this._generalParameters && this._generalParameters['specialTimeArray'])
		{
			result = this._generalParameters['specialTimeAarray'];
		}

		return result;
	}
}
