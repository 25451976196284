import { Component, OnDestroy, ViewEncapsulation, OnInit, AfterViewInit }	from '@angular/core';
import { ActivatedRoute, Router }											from '@angular/router';

import { UserData }			from '../../providers/user-data';
import { SavedData }		from '../../providers/saved-data';
import { WorkspaceData }	from '../../providers/workspace-data';
import { AuthService }		from '../../services/auth.service';
import { SocketService }	from '../../services/socket.service';
import { UserService }		from '../../services/user.service';

import { ForgetPasswordModalComponent }			from '../../modal/forgetPassword/forgetPasswordModal.component';
import { InfosForgetPasswordModalComponent }	from '../../modal/forgetPassword/infosForgetPasswordModal.component';
import { ResetPasswordModalComponent }			from '../../modal/resetPassword/resetPasswordModal.component';

import { AppComponent }		from '../../app.component';
import { SCClientSocket }	from 'socketcluster-client';
import { MatDialog }		from '@angular/material';

import * as htmlEncode from 'js-htmlencode';

declare var Fingerprint2: any;


@Component({
	selector:		'login-component',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./login.component.scss'],
	templateUrl:	'login.component.html'
})


/**
 *
 */
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit
{
	public username:		string = '';
	public password:		string = '';
	public color:			string	= 'primary';
	public checked:			boolean = false;
	public disabled:		boolean = false;
	public isError:			boolean = false;
	public isLoading:		boolean = false;
	public errorMessage:	string	= 'Vos identifiants sont incorrects';
	public dialogRef:		any		= null;


	/**
	 *
	 */
	constructor(
		public authService:		AuthService,
		public userData:		UserData,
		public savedData:		SavedData,
		public workspaceData:	WorkspaceData,
		public socketService:	SocketService,
		public userService:		UserService,
		public dialog:			MatDialog,
		public activatedRoute:	ActivatedRoute,
		public router:			Router,
		public appComponent:	AppComponent)
    {
		appComponent.setPageTitle('Connexion');

		this.socketService.on('error', this._handleError.bind(this));
    }


	/**
	 *
	 */
	public ngOnInit()
	{
	}


	/**
	 *
	 */
	public ngAfterViewInit()
	{
		// On analyse l'url pour le cas d'une réinitialisation de mot de passe
		if (window && window.location && window.location.search)
		{
			const elements = window.location.search.split('?');

			if (elements.length === 2)
			{
				const strElement = elements[1].split('=');
				if (strElement.length === 2)
				{
					const key	= strElement[0];
					const value = strElement[1];

					if (key === 'resetPassCode' && value)
					{
						// On vérifie la validité du code de réinitialisation
						setTimeout(() =>
						{
							this.userService.checkCodePasswordReset(value).then(res =>
							{

								if (res && res.result && res.result.codeValid === true)
								{
									// si le code de réinitialisation est valide
									// mais qu'il est périmé on affiche une pop up
									// d'information
									if (res.result.dateValid === false && res.result.mailUser === undefined && res.result.compte === undefined)
									{
										this.dialog.open(InfosForgetPasswordModalComponent,
											{
												height:			'30%',
												width:			'60%',
												panelClass:		'responsive_modal',
												disableClose:	true,
												data:
												{
													usermail:	'error',
													type:		'errorOutOfDateLink'
												}
											});
									}
									else
									{
										// Si le code n'est pas périmé alors on affiche la pop up de saisie
										// du nouveau mot de passe
										const dialogRefPasswordReset = this.dialog.open(ResetPasswordModalComponent,
											{
												height:			'50%',
												width:			'60%',
												panelClass:		'responsive_modal',
												data:
												{
													type:		'resetWithCode',
													resetCode:	res.codeReset,
													compteUser: res.result.compte,
													mailUser:	res.result.mailUser
												},
												disableClose: true
											});

										// A la fermeture de la box
										dialogRefPasswordReset.afterClosed().subscribe((resultReset: any) =>
										{
											if (resultReset && resultReset === true)
											{
												this.dialog.open(InfosForgetPasswordModalComponent,
													{
														height:			'30%',
														width:			'60%',
														panelClass:		'responsive_modal',
														disableClose:	true,
														data:
														{
															usermail:	'error',
															type:		'confirmPasswordUpdated'
														}
													});
											}
										});
									}
								}
								else
								{
									if (res && res.result && res.result.codeValid === false)
									{
										this.dialog.open(InfosForgetPasswordModalComponent,
											{
												height:			'30%',
												width:			'60%',
												panelClass:		'responsive_modal',
												disableClose:	true,
												data:
												{
													usermail:	'error',
													type:		'errorInvalidLink'
												}
											});
									}
								}
							}).catch(err =>
							{
							});

						}, 1000);
					}
				}
			}
		}
	}


	/**
	 *
	 */
	public ngOnDestroy()
	{

	}


	/**
	 *
	 */
	public onFocus()
	{
		this.isError = false;
	}


	/**
	 *
	 * @param event
	 */
	public onKeyUp(event: any)
	{
		this.username = this.username.toLowerCase();
	}


	/**
	 *
	 */
	public login()
	{
		this.userData.setStateApplication('application', 'pending');
		let fResult: any;

		let options =
			{
				userDefinedFonts:					[''],
				excludeUserAgent :					true,
				excludeLanguage:					false,
				excludeColorDepth:					false,
				excludeAvailableScreenResolution:	false,
				excludeTimezoneOffset:				false,
				excludeIndexedDB:					false,
				excludeDeviceMemory:				false,
				excludeOpenDatabase:				false,
				excludeCpuClass:					false,
				excludePlatform:					false,
				excludeHardwareConcurrency:			false,
				excludeCanvas:						false,
				excludeTouchSupport:				false,
				excludeHasLiedLanguages:			false,
				excludeHasLiedResolution:			false,
				excludeHasLiedOs:					false,
				excludeHasLiedBrowser:				false,
				excludeAdBlock:						true,
				excludeSessionStorage:				true,
				excludeWebGL:						true,
				excludeAddBehavior:					true,
				excludeJsFonts:						true,
				excludeFlashFonts:					true,
				excludePlugins:						true,
				excludeIEPlugins:					true,
				excludeDoNotTrack:					true,
				excludePixelRatio:					true,
				excludeScreenResolution:			true,
				excludeWebGLVendorAndRenderer:		true,
			};

		// test fingerprint pr le moment
		new Fingerprint2(options).get((result: any, components: any) =>
		{
			// this will use all available fingerprinting sources
			fResult = result;

			// components is an array of all fingerprinting components used
			this.isLoading = true;

			this.username = htmlEncode.htmlEncode(this.username);
			this.password = htmlEncode.htmlEncode(this.password);

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			this.socketService.getSocketConnection().subscribe((socket: any) =>
			{
				if (!socket || !socket.state || socket.state !== SCClientSocket.OPEN)
				{
					this.authService.doLogin(this.username, this.password, fResult)
					.subscribe(res =>
					{
						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						let body = JSON.parse(res.text());

						let mailUnique = body.data.user.email;
						if (body.err === false && body.data && body.data.token)
						{
							// this.nav.setRoot(TabsPage);
							this.userData.setFingerPrint(fResult);

							this.isLoading		= false;
							this.isError		= false;

							this.userData.login(this.username, body.data.token, mailUnique);
							//this.router.navigate(['/home']);
						}
					}, (err) =>
						{
							// ---------------------------------------
							// ToComment
							//  ---------------------------------------
						});
				}
				else
				{
					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					if (socket.authState === SCClientSocket.AUTHENTICATED && this.userData.hasLoggedIn() === true)
					{
						this.userData.setTokenKey(socket.signedAuthToken);

						this.userData.mustReset				= false;
						this.userData.otpAuth				= false;
						this.userData.mustValidateBrowser	= false;

						this.isLoading	= false;
						this.isError	= false;

						this.userData.setStateApplication('application', 'online');
						this.userService.checkFingerPrintUser();
					}
					else
					{
						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						let credentials =
							{
								username:		this.username,
								password:		this.password,
								fingerprint:	fResult
							};

						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						socket.emit('login', credentials, (err: any, datas: any) =>
						{
							this.userData.setAccountInfo(datas);
							this.isLoading = false;

							if (datas.logged && datas.logged === true)
							{
								this.isError = false;

								if (datas && datas.infosUser)
								{
									this.userData.setCurrentUser(datas.infosUser);

									/**
									 * Par défault on initialise les noeuds ouverts précédements
									 * avec ceux enregistrés dans le localstorage
									 */

									this.workspaceData.selectedItems = this.savedData.openedNodes;

									if (datas.infosUser.prefs)
									{
										let preferences = datas.infosUser.prefs;

										if (preferences.paramLogiciels)
										{
											for (let key in preferences.paramLogiciels)
											{
												if (preferences.paramLogiciels[key])
												{
													switch (key)
													{
														case 'worspaceTaskChecked':
															let workspaceChecked = preferences.paramLogiciels[key] === true || preferences.paramLogiciels[key] === 'true';
															this.savedData.isWorkspaceTaskChecked = workspaceChecked;
															break;
														default:
													}
												}
											}
										}

										if (preferences.lastGroup)
										{
											this.savedData.lastGroup = preferences.lastGroup;
										}

										if (preferences.selectedNodes && this.savedData.openedNodes === null)
										{
											let selectedNodes;
											if (typeof preferences.selectedNodes === 'string')
											{
												selectedNodes = JSON.parse(preferences.selectedNodes);
											}
											else
											{
												selectedNodes = typeof preferences.selectedNodes;
											}

											/**
											 * S'il n'y a pas de noeud enregistré dans le localstorage, on les initialise
											 * avec ceux enregistrés en base de données
											 */
											if (!this.workspaceData.selectedItems || this.workspaceData.selectedItems.length === 0)
											{
												if (selectedNodes && selectedNodes.length > 0)
												{
													this.workspaceData.selectedItems = [];

													for (let index = 0; index < selectedNodes.length; index++)
													{
														if (selectedNodes[index])
														{
															switch(index)
															{
																case 0:
																	this.savedData.lastEtablissementId = selectedNodes[index];
																	this.workspaceData.selectedItems.push(selectedNodes[index]);
																	break;

																case 1:
																	this.savedData.lastSessionId = selectedNodes[index];
																	this.workspaceData.selectedItems.push(selectedNodes[index]);
																	break;

																case 2:
																	this.savedData.lastExamenId = selectedNodes[index];
																	this.workspaceData.selectedItems.push(selectedNodes[index]);
																	break;

																case 3:
																	this.savedData.lastDisciplineId = selectedNodes[index];
																	this.workspaceData.selectedItems.push(selectedNodes[index]);
																	break;

																default:
																	break;

															}
														}
													}
												}
											}
										}
									}
								}
							}
							else
							{
								this.isError = true;

								if (err.includes('#LG001') === true) // s'il s'agit d'une erreur d'identification
								{
									// On affiche un message adapté
									err = 'Votre identifiant ou votre mot de passe est incorrect';
								}

								let messageError	= err || 'Erreur de connexion';
								this.errorMessage	= messageError;
							}
						});
					}
				}
			});

		});
	}


	/**
	 *
	 */
	public launchResetPassword()
	{
		const dialogRef = this.dialog.open(ForgetPasswordModalComponent,
			{
				height:			'40%',
				width:			'40%',
				panelClass:		'responsive_modal',
				disableClose:	true,
				data:
				{}
			});

		dialogRef.afterClosed().subscribe((result: any) =>
		{
			if (result !== undefined)
			{
				this.dialog.open(InfosForgetPasswordModalComponent,
					{
						height:			'30%',
						width:			'60%',
						panelClass:		'responsive_modal',
						disableClose:	true,
						data:
						{
							usermail:	 result,
							type:		'confirmForget'
						}
					});

			}
		});
	}


	// Handle keypress event, for sending chat message
	public eventHandler(event: KeyboardEvent): void
	{
		if (event.key === "Enter")
		{
			this.login();
		}
	}


	/**
	 * Vérification de la présence d'un login et d'un password pour activer le bouton
	 */
	public canDoLogin()
	{

		let result = false;
		if (this.password && this.username)
		{
			const filteredLogin		= this.username.replace(/ /gi, '');
			const filteredPassoword	= this.password.replace(/ /gi, '');

			if (filteredLogin !== '' && filteredPassoword !== '')
			{
				result = true;
			}
		}

		return result;
	}


	/**
	 *
	 * @param data
	 */
	private _handleError(data: any)
	{
		/**
		 * Si on attend une connection on désactive le spinner et on affiche un message d'érreur
		 */
		if (this.isLoading)
		{
			this.isLoading		= false;

			this.userData.setStateApplication('application', 'offline');

			this.isError		= true;
			this.errorMessage	= 'Serveur indisponible. Veuillez rééssayer ultérieurement.';
		}
	}
}
