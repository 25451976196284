import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';

import { SocketService }    from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()

export class StatisticsService
{
    private socket: any = undefined;

    /**
	 *
	 */
    constructor(private socketService:	SocketService,
				private userData:		UserData)
    {
        this.socket = socketService.getSocketConnection();
    }

    public getEpreuveStats(nodeParentIds: any[] = [], limit: number = 0)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'epreuve.stats',
						'args': {
							parentNodes: nodeParentIds,
							limit: limit
						}
					};

				socket.emit('read', data);
			}
		});

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				this.socket = socket;

				if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'tasks.stats',
							'args': {}
						};

					socket.emit('read', data);
				}
				else
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'tasks.assigned',
							'args': {}
						};
					socket.emit('read', data);
				}
			}
		});
	}
}

