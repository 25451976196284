<div  ngsReveal class="content-chat content_view">
	<div class="commons_communauty_header">
		<div class="name" *ngIf="chatData.selectedRoom">{{ 'chat.chatWith' | translate }} {{chatData.selectedRoom.displayName}}</div>
		<div class="" *ngIf="chatData.selectedRoom && chatData.selectedRoom.description">{{chatData.selectedRoom.description}}</div>
		<div class="" *ngIf="chatData.selectedRoom && chatData.selectedRoom.creationDate">{{ 'chat.created' | translate }}
			<app-date-user-friendly [noFuture]="chatData.selectedRoom.creationDate" [log]="true"></app-date-user-friendly>
		</div>
	</div>

	<div class="commons_block  messages-chat" vp-scrollable-content #message2 [ngsRevealSet]="{ reset:true }" [ngsInterval]="50" [ngsSelector]="'.scrv-item'" scroll-glue>
		 <div class="scrv-item"></div>
		<!--	
			<div class="testforvp" (appear)="handlerFunction()" (click)="handlerFunction()">Charger plus</div>		-->
		<div class="testforvp" viewport-enter="handlerFunction()" (click)="handlerFunction()"></div>


		<div *ngIf="chatData.selectedRoom">
			<div class="message scrv-item" *ngFor="let message of chatData.selectedRoom.getMessages()" [class.user]="message.userId == currentUser">
				 <div class="message_block">
					<div class="message_contact" *ngIf="message.userId !== currentUser">
						<div class="readByMe neoptec_icone_24"		*ngIf="chatData.selectedRoom.lastIdReadedByMe		=== message.ticks"></div>
						<div class="message_contact_user">
							<div class="profile-picture"  attr.data-icone="{{chatData.getUserIcon(message.userId)}}">
								<div *ngIf="icone">
									<img [src]="icone">
								</div>
								<div *ngIf="!icone">
								</div>
							</div>
						</div>
						<div class="message_contact_date"><app-date-user-friendly [noFuture]="true" [date]="message.date"></app-date-user-friendly></div>
					</div>

					<div class="message_content" [innerHTML]="message.content">
						<!--<div class="message_content" [innerHTML]="message.content | emojify">-->
					</div>

					<!--
				<div class="receivedMe neoptec_icone_24"	*ngIf="chatData.selectedRoom.lastIdReceivedByMe		=== message.ticks">receivedMe</div>
				<div class="receivedByHim neoptec_icone_24"	*ngIf="chatData.selectedRoom.lastIdReceivedByHim	=== message.ticks">receivedByHim</div>
					-->


					<div class="message_contact" *ngIf="message.userId === currentUser">
						 <div class="readedByHim neoptec_icone_24"	*ngIf="chatData.selectedRoom.lastIdReadedByHim		=== message.ticks "></div>

						<div class="message_contact_user">
							<div class="profile-picture" attr.data-icone="{{chatData.getUserIcon(message.userId)}}">
								<div *ngIf="icone">
									<img [src]="icone" >
								</div>
								<div *ngIf="!icone">
								</div>
							</div>
						</div>
						<div class="message_contact_date"><app-date-user-friendly [noFuture]="true" [date]="message.date"></app-date-user-friendly></div>
					</div>
					<div class="clear"></div>
				</div>
				<!--<div class="msg_readed neoptec_icone_24"	*ngIf="chatData.selectedRoom.lastIdReadedByHim		=== message.ticks && message.userId == currentUser">Test1</div>
			<div class="msg_received neoptec_icone_24"	*ngIf="chatData.selectedRoom.lastIdReceivedByHim	=== message.ticks && message.userId == currentUser && chatData.selectedRoom.lastIdReceivedByHim !== chatData.selectedRoom.lastIdReadedByHim">Test12</div>
>-->		
			</div>
		</div>
	</div>

	<div class="editor-chat">
		<textarea rows="0" matInput #textArea [(ngModel)]="message" (keydown)="eventHandler($event)" (keyup)="eventHandlerUp($event)" #item (change)="refreshMessage(item.value)" resizeToFitContent="true" matTextareaAutosize matAutosizeMaxRows="5" maxRows="5" placeholder="{{ 'chat.yourMessage' | translate }}" class=""></textarea>
		<!--	<emojify-holder (emojiAdded)="emojiAdded($event)">
				<textarea rows="0"  emojiInput [emojiInputValue]="message" matInput #textArea [(ngModel)]="message" (keydown)="eventHandler($event)" (keyup)="eventHandlerUp($event)" #item (change)="refreshMessage(item.value)" resizeToFitContent="true" matTextareaAutosize matAutosizeMaxRows="5" maxRows="5" placeholder="{{ 'chat.yourMessage' | translate }}" class=""></textarea>
			</emojify-holder>
		-->
		<button mat-raised-button color="accent" class="send_message" (click)="send()">{{ 'chat.send' | translate }}</button>
	</div>
</div>