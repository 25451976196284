import { Component, ViewEncapsulation, OnInit, OnDestroy }	from '@angular/core';
import { MatDialog }										from '@angular/material';

import { UserData }								from '../../../providers/user-data';
import { UserModalComponent }					from '../../../modal/user/userModal.component';
import { ConfirmResetRsaModalComponent }		from '../../../modal/confirmResetRsa/confirmResetRsaModal.component';
import { ConfirmDeleteUserModalComponent }		from '../../../modal/confirmDeleteUser/confirmDeleteUserModal.component';
import { ConfirmGiveFilesKeysModalComponent }	from '../../../modal/confirmGiveFilesKeys/confirmGiveFilesKeysModal.component';

import { UserService }          from '../../../services/user.service';

import * as _ from 'underscore';


@Component({
    selector:		'app-admin-users',
    encapsulation:	ViewEncapsulation.None,
    templateUrl:	'./admin-users.component.html',
    styleUrls:		['./admin-users.component.scss']
})


/**
 * 
 */
export class AdminUsersComponent implements OnInit, OnDestroy
{
    public users:				any[] = [];
    public selectedUser:		any;
    public moUserSubscription:	any;

    public userDataChanged:		any		= {};
    public hasUserDataChanger:	boolean = false;

    public arrayProfessions:	string[] = [];
    public arrayMatieres:		string[] = [];

    constructor(public userData:	UserData,
                public userService: UserService,
                public dialog:		MatDialog)
    {
        this._initUsers();
    }


	/**
	 * 
	 */
    public ngOnInit()
	{

        this.moUserSubscription = this.userData.globalEmitter.subscribe((value: string) =>
		{
			//Evènement récupéré lorsqu'un nouvel utilisateur à été ajouté
			//On ajoute donc le nouvel utilisateur dans la liste
			if (value[0] === 'user::add')
			{
				if (value[1])
				{
					this.displayDetailUser(value[1]);
				}
			}

			if (value[0] === 'user::update')
			{
				this._initUsers();
			}
		});
    }
	

	/**
	 * 
	 */
    public ngOnDestroy()
	{
		if (this.moUserSubscription)
		{
			this.moUserSubscription.unsubscribe();
		}
    }


	/**
	 * 
	 * @param user 
	 */
    public displayDetailUser(user: any)
    {
        if (user)
        {
            this.userDataChanged	= {};
            this.hasUserDataChanger = false;
			this.selectedUser		= user;
			this.arrayProfessions = this.selectedUser.publics.professions;
			
			if (typeof this.arrayProfessions === 'string')
			{
				this.arrayProfessions = JSON.parse(this.arrayProfessions);
			}
			else
			{
				this.arrayProfessions = this.arrayProfessions;
			}

			this.arrayMatieres = this.selectedUser.publics.matieres;

			if (typeof this.arrayMatieres === 'string')
			{
				this.arrayMatieres = JSON.parse(this.arrayMatieres);
			}
			else
			{
				this.arrayMatieres = this.arrayMatieres;
			}
		}
	}


	/**
	 * 
	 */
	public addNewUser()
	{
		let dialogRef = this.dialog.open(UserModalComponent,
		{
			height:			'80%',
			panelClass:		'responsive_modal',
			width:			'75%',
			disableClose:	true,
		});


		dialogRef.afterClosed().subscribe((result: any) =>
		{
		});
	}
	

	/**
	 * 
	 */
	public displayModalAccount()
	{
		let dialogRef = this.dialog.open(UserModalComponent,
		{
			height:			'80%',
			panelClass:		'responsive_modal',
			width:			'75%',
			disableClose:	true,
			data:
			{
				'edition':		true,
				userSelected:	this.selectedUser
			}
		});


		dialogRef.afterClosed().subscribe((result: any) =>
		{
		});
	}


	/**
	 * 
	 */
	public toggleEnableAccount()
	{
		if (this.selectedUser.enabled === true)
		{
			//On désactive le compte
			this.userService.toggleEnabledUser(this.selectedUser.mail, false);
		}
		else
		{
			//On le réactive
			this.userService.toggleEnabledUser(this.selectedUser.mail, true);
		}
	}


	/**
	 * Réinitialisation de la clé RSA de l'utilisateur selectionné
	 */
	public resetRsaKey(user: any)
	{
		const dialogRef = this.dialog.open(ConfirmResetRsaModalComponent,
		{
			height:			'200px',
			panelClass:		'responsive_mini_modal',
			width:			'50%',
			disableClose:	true,
			data:
			{
				user: user
			}
		});

		dialogRef.afterClosed().subscribe((result: any) =>
		{
			if (result === true && user.mail)
			{
				this.userService.resetRsaKeyUSer(user.mail);
			}
		});
	}


	/**
	 *
	 */
	public giveFilesKeys(user: any)
	{
		const dialogRef = this.dialog.open(ConfirmGiveFilesKeysModalComponent,
		{
			height:			'250px',
			panelClass:		'responsive_mini_modal',
			width:			'50%',
			disableClose:	true,
			data:
			{
				user: user
			}
		});

		dialogRef.afterClosed().subscribe((result: any) =>
		{
			if (result === true)
			{
			}
		});
	}


	/**
	 * 
	 */
	public deleteAccount()
	{
		const dialogRef = this.dialog.open(ConfirmDeleteUserModalComponent,
		{
			height:			'200px',
			width:			'50%',
			panelClass:		'responsive_mini_modal',
			disableClose:	true,
			data:
			{
				'user': this.selectedUser,
			}
		});

		dialogRef.afterClosed().subscribe((result: any) => 
		{
			if (result === true)
			{
				this.userService.deleteUser(this.selectedUser.mail);
				this.selectedUser = null;
			}
		});
	}


	/**
	 * 
	 */
	private _initUsers()
	{
		this.users			= [];
		const objUsers		= this.userData.getUsers();
		const currentUserId	= this.userData.getUserId();

		let newUser: any;
		for (const key in objUsers)
		{
			if (currentUserId !== key)
			{
				newUser					= objUsers[key];
				newUser.completeName	= newUser.infos.nom + ' ' + newUser.infos.prenom;
				this.users.push(newUser);
			}
		}
	}


	/**
	 * 
	 * @param pjUser 
	 */
	public isSelected(pjUser: any)
	{
		let bReturn = false;
		if (this.selectedUser)
		{
			if (pjUser.mail === this.selectedUser.mail)
			{
				bReturn = true;
			}
		}
		return bReturn;
	}


	/**
	 * A revoir car un super admin doit pouvoir delete un admins
	 */
	public isCurrentUserAdminOrSuperUser()
	{
		let bReturn = false;

		if (this.selectedUser)
		{
			if (this.selectedUser.publics && (this.selectedUser.publics.isAdmin === 'true' || this.selectedUser.publics.isSuperUser === 'true'))
			{
				bReturn = true;
			} 

			// Si l'user courant est super, il doit pouvoir delete un admin
			if (this.selectedUser.publics && (this.selectedUser.publics.isAdmin === 'true' && this.userData.status === 'superviseur'))
			{
				bReturn = false;
			}
		}
		
		return bReturn;
	}



	/**
	 * 
	 */
	public hasCurrentUserRsaKey()
	{
		let bReturn = false;

		if (this.selectedUser)
		{
			if (this.selectedUser.publics && this.selectedUser.publics.rsaPublicKey !== undefined && this.selectedUser.publics.rsaPublicKey !== '')
			{
				bReturn = true;
			}
		}
		return bReturn;
	}
	

	/**
	 * 
	 */
	public canMakeActionOnCurrentUser()
	{
		let bReturn = true;
		// Si l'utlisateur courant est un super user, il ne peut pas faire d'action sur les autres super user
		if (this.userData.isSuperviseur() === true)
		{
			if (this.selectedUser.publics && this.selectedUser.publics.isSuperUser === 'true')
			{
				bReturn = false;
			}
		}

		// Si l'utlisateur courant est admin, il ne peut pas faire d'action sur les autres super user et admin
		if (this.userData.isAdmin() === true)
		{
			if (this.selectedUser.publics && (this.selectedUser.publics.isSuperUser === 'true' || this.selectedUser.publics.isAdmin === 'true'))
			{
				bReturn = false;
			}
		}

		// Si l'utlisateur courant est un user simple, il ne peut rien faire (de toute facon il ne devrait même pas être la)
		if (this.userData.isSimpleUser() === true)
		{
			bReturn = false;
		}

		return bReturn;
	}


	/**
	 * 
	 * @param user 
	 */
	public getCodeMachine(user: any)
	{
		let result = '';

		if (user && user.privates && user.privates.fp)
		{
			let fp = user.privates.fp;
			if (typeof fp === 'string')
			{
				fp = JSON.parse(fp);
			}

			if (fp.code)
			{
				result = fp.code;
			}
		}

		return result;
	}
}
