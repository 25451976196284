import { Examen }	from './examen.class';
import { Comment }  from './comment.class';
import * as _		from 'underscore';


/**
 *
 * @export
 * @class Session
 */
export class Session
{
    /**
     * Creation d'un objet Session à partir d'une structure json donnée
     * @static
     * @param {any} jsonObj
     * @returns {Session}
     *
     * @memberOf Session
     */
    static fromJsonObject(jsonObj: any): Session
    {

        let result = new Session();

		// ---------------------------------------
		// initialisation de l'identifiant, du nom et de la description de la session
		// ---------------------------------------
        result.id           = jsonObj.uuid          || jsonObj.id					|| '';
        result.name         = jsonObj.name          || jsonObj.libelle				|| '';
        result.description  = jsonObj.description   || jsonObj.datas.description	|| '';
		result.author		= jsonObj.createur		|| jsonObj.creator;
		let date			= jsonObj.creationdate	|| jsonObj.creation_date;
		result.dateCreation	= new Date(date);



        if (result.dateCreation)
        {
            result.dateCreation = new Date(result.dateCreation);
        }

		if (jsonObj.datas)
		{
			result.show		= jsonObj.datas.show		=== 'true' || false;
			result.selected = jsonObj.datas.selected	=== 'true' || false;
		}

        result.flags = jsonObj.flags || [];

		// ---------------------------------------
		// initialisation des examens récupérés
		// ---------------------------------------
        if (jsonObj.examens && jsonObj.examens.length)
        {
			for (let i = 0; i < jsonObj.examens.length; i++)
			{
				result.examens.push(Examen.fromJsonObject(jsonObj.examens[i]));
			}
			result.examens.sort(function (a, b)
			{
				var nameA = a.name.toUpperCase(); // ignore upper and lowercase
				var nameB = b.name.toUpperCase(); // ignore upper and lowercase
				
				if (nameA < nameB)
				{
					return -1;
				}
				if (nameA > nameB)
				{
					return 1;
				}

				// names must be equal
				return 0;
			});
		}
		

        //Initialisation des commentaires récupérés
        if (jsonObj.comments && Object.keys(jsonObj.comments).length > 0)
        {
            for (let key in jsonObj.comments)
            {
                if (jsonObj.comments[key])
                {
                    let objComment = Comment.fromJsonObject(jsonObj.comments[key]);
                    result.comments.push(objComment);
                }
            }
        }

        return result;
    }


    /**
     * Creates an instance of Session.
     *
     * @param {string} [_name='']
     * @param {boolean} [_show=false]
     * @param {boolean} [_selected=false]
     * @param {Examen[]} [_examens=[]]
     *
     * @memberOf Session
     */
    constructor(
       private _id:             string      = '',
       private _name:           string      = '',
       private _dateCreation:	Date        = null,
       private _description:	string		= '',
       private _show:           boolean     = false,
       private _selected:       boolean	    = false,
       private _flags:          string[]    = [],
       private _examens:        Examen[]    = [],
       private _comments:		Comment[]   = [],
       private _author:			string      = '',
       private _visible:        boolean     = true
    ){ }


    get id()            { return this._id; }
    get name()          { return this._name; }
    get dateCreation()	{ return this._dateCreation; }
    get description()   { return this._description; }
    get show()          { return this._show; }
    get selected()      { return this._selected; }
    get flags()         { return this._flags; }
    get examens()       { return this._examens; }
    get partie_id()     { return this._examens; }
    get comments()      { return this._comments; }
    get author()        { return this._author; }
    get visible()       { return this._visible; }

    set id(id)			            { this._id				= id; }
    set name(name)			        { this._name			= name; }
    set dateCreation(dateCreation)	{ this._dateCreation	= dateCreation; }
    set description(description)	{ this._description		= description; }
    set show(show)					{ this._show			= show; }
    set selected(selected)			{ this._selected		= selected; }
    set flags(flags)			    { this._flags		    = flags; }
    set examens(examens)			{ this._examens			= examens; }
    set comments(comments)			{ this._comments		= comments; }
    set author(author: any)         { this._author			= author; }
    set visible(visible: any)       { this._visible			= visible; }


    /**
     *
     * @param {any} info
     * @returns
     *
     * @memberOf Session
     */
    public extractBranchInfos(info: any)
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let infoCopy = Object.assign({}, info);

        let infos: any = [];
        infoCopy.nameSession	= this.name;
        infoCopy.idSession      = this.id;


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        if (this.examens.length > 0)
        {
            for (let i = 0; i < this.examens.length; i++)
            {
                infos = infos.concat(this.examens[i].extractBranchInfos(infoCopy));
            }
        }
        else
        {
            infos.push(infoCopy);
        }
        return infos;
    }


    /**
     *
     * @param {any} sessionsObject
     * @param {any} [propertyFounded={}]
     *
     * @memberOf Session
     */
    public updateExamensAvailable(sessionsObject: any, propertyFounded: any = {}, hasFilter = false)
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let currentexamens: any;
        let examenNames = _.uniq(_.map(sessionsObject,	function(etab: any)		{ return etab['nameExamen']; }));
        this.examens	= _.reject(this.examens,		function(examen: any)	{ return !examenNames.includes(examen.name); });

        if (hasFilter === true)
        {
            this.examens = _.sortBy(this.examens, function(exam: any){ return _.indexOf(examenNames, exam.name); });
        }


        for (let i = 0; i < this.examens.length; i++)
        {
			// ---------------------------------------
			// ToComment
			// ---------------------------------------
            this.examens[i].show = propertyFounded['key'] === 'default' && propertyFounded['values'].includes(this.examens[i].name.toLowerCase()) ? true : false;

            currentexamens = _.where(sessionsObject, {nameExamen: this.examens[i].name});
            this.examens[i].updateMatieresAvailable(currentexamens, propertyFounded, hasFilter);

            this.show = this.examens[i].show ? true : this.show;
        }
    }


    /**
     * 
     * @param paramsSelected 
     */
    public getSelectedItems(paramsSelected: any)
	{
		let result = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (paramsSelected['session'] && this.id === paramsSelected['session'])
		{
			result.push(this);
		}


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let examen of this.examens)
		{
			result = result.concat(examen.getSelectedItems(paramsSelected));
		}

		return result;
	}


    /**
     * 
     * @param paramsShowed 
     */
	public getShowedItems(paramsShowed: any)
	{
		let result = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (paramsShowed[this.id] && paramsShowed[this.id] === 'session')
		{
			result.push(this);
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let examen of this.examens)
		{
			result = result.concat(examen.getShowedItems(paramsShowed));
		}

		return result;
	}


    /**
	 * convertion d'un objet Session au format Json
	 */
	public toJson()
	{
		let result: any =
		{
			id:				this.id,
			libelle:		this.name,
            creator:		this.author,
            creationdate:	this.dateCreation,
            type:			'session',
            flags:          this.flags,
			datas:
			{
				description:	this.description,
				show:			this.show			? 'true' : 'false',
				selected:		this.selected		? 'true' : 'false',
			}
		};

		if (this.examens.length > 0)
		{
			result.examens = [];
			for (let examen of this.examens)
			{
				result.examens.push(examen.toJson());
			}
		}

        //Initialisation des commentaires dans la structure json retournée
        if (this.comments.length > 0)
		{
			result.comments = [];
			for (let comment of this.comments)
			{
				result.comments.push(comment.toJson());
			}
		}
		return result;
	}
}
