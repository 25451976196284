import { Component, EventEmitter, ViewEncapsulation, Inject, OnInit, OnDestroy } from '@angular/core';

import { MatDialogRef, MatDialog }							from '@angular/material';
import { MAT_DIALOG_DATA }									from '@angular/material';
import { DataSource, SelectionModel }						from '@angular/cdk/collections';
import {Observable}											from 'rxjs/Rx';
import { TranslateService }									from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators }	from '@angular/forms';

import { Etablissement }		from '../../../../classes/etablissement.class';
import { Session }				from '../../../../classes/session.class';
import { Examen }				from '../../../../classes/examen.class';
import { Matiere }				from '../../../../classes/matiere.class';
import { Task }					from '../../../../classes/task.class';
import { Sujet }				from '../../../../classes/sujet.class';
import { File as FileObject }	from '../../../../classes/file.class';

import { TaskService }		from '../../../../services/task.service';
import { FileService }		from '../../../../services/file.service';
import { EncryptService }   from '../../../../services/encrypt.service';
import { UserData }			from '../../../../providers/user-data';
import { WorkspaceData }	from '../../../../providers/workspace-data';

import { WarningUnloggedUserModalComponent }    from '../../modal/warningUnloggedUserModal.component';

import * as _ from 'underscore';

/*
export interface FileItemList {
    name: string;
}
*/


export class FileItemDataSource extends DataSource<FileObject>
{
    public objSubject: any;

	constructor(objSubject: any)
	{
		super();
        this.objSubject = objSubject;
	}


	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<FileObject[]>
	{
		return Observable.of(this.objSubject.pj);
	}

	disconnect() { }
}


@Component({
	selector:		'editor-panel',
	templateUrl:	'./editor-panel.component.html',
	inputs:			['task'],
	encapsulation:	ViewEncapsulation.None,
	outputs:		['_onSubmit: onSubmit', '_onCancel: onCancel'],
	styleUrls:		['./editor-panel.component.scss']
})


export class EditorPanelComponent implements OnInit, OnDestroy
{
    public startDate = new Date();

    public initialTask:			Task;
    public currentTask:			Task;
    public structureModel:		any[];
    public isTaskEdition:		boolean = false;
    public itemSelected:		string;
    public subjectsAvailable:	any[];
    public usersAvailable:		any[];
    public moFormGroup:			FormGroup;
    public scenarioList:		any;

    public currentName:			string;
    public currentType:			number;
    public currentIdSujet:		string;
    public currentDescription:	string;
    public currentAssigneduser: string;

    public noAccessFile:			boolean = false;
    public readOnlyFile:			boolean = false;
    public canCreateFile:			boolean = false;
    public canDeleteFile:			boolean = false;
    public typeFileAccess:          string  = 'all';

    public selectedClasseur:	Etablissement;
    public selectedSession:		Session;
    public selectedExamen:		Examen;
    public selectedMatiere:		Matiere;
    public selectedSubject:     Sujet;
    public dateEcheance:		Date;

    public moFormCtrlName:					FormControl;
    public moFormCtrType:					FormControl;
    public moFormCtrSubject:				FormControl;
    public moFormCtrDescription:			FormControl;
    public moFormCtrEcheance:				FormControl;
    public moFormCtrNoAccessFile:			FormControl;
    public moFormCtrCanCreateFile:			FormControl;
    public moFormCtrAssignedUser:			FormControl;


    public filesSubject:		FileItemDataSource;
    public selection:			SelectionModel<FileObject> = new SelectionModel<FileObject>(true, []);
	
    public displayedColumns = ['select', 'name'];
    public moUserDataEmmiterSubscription: any;

    public unloggedUserSelected: boolean = false;

    public _onSubmit: EventEmitter<any>		= new EventEmitter<any>();
	public _onCancel: EventEmitter<boolean>	= new EventEmitter<boolean>();

    public treeDatas: any = undefined;


    constructor(public dialogRef:		MatDialogRef<EditorPanelComponent, any>,
                public taskService:		TaskService,
                public fileService:		FileService,
                public encryptService:	EncryptService,
                public fb:				FormBuilder,
                public userData:		UserData,
                public workspaceData:	WorkspaceData,
                public dialog:			MatDialog,
                public translate:		TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any)
	{
		this.itemSelected = 'informations';
		this.translate.get('editorPanel.new').subscribe((value: string) =>
		{
			this.subjectsAvailable = [
				{
					value:		'new',
					libelle:	value
				}
			];
		});


		this.translate.get('editorPanel.noUser').subscribe((value: string) =>
		{
			this.usersAvailable = [
				{
					value:		'none',
					loggedOnce: true,
					libelle:	value
				}
			];
		});

		let allUsers = this.userData.getUsers();


		if (allUsers)
		{
			for (let key in allUsers)
			{
				if (allUsers[key])
				{
					let userInfo;
					let outputContent = key;

					if (key)
					{
						userInfo = this.userData.getUser(key);
					}

					if (userInfo && userInfo.infos && (userInfo.infos.nom || userInfo.infos.prenom))
					{
						outputContent = '';

						if (userInfo.infos.prenom)	outputContent += userInfo.infos.prenom + ' ';
						if (userInfo.infos.nom)		outputContent += userInfo.infos.nom;
                    }

                    let loggedOnce: boolean = true;
                    if (userInfo && userInfo.state && userInfo.state.active)
                    {
                        let dateActive = new Date(userInfo.state.active);
                        if (dateActive && dateActive.getTime() === 0)
                        {
                            loggedOnce = false;
						}
					}

					this.usersAvailable.push(
						{
							value:			key,
							loggedOnce:		loggedOnce,
							libelle:		outputContent
						});
				}
			}
		}

		if (this.data)
        {
            /**
             * Récupération des éléments de l'arborescence selectionnés
             */
            if (this.data.selectedItems)
            {
                if (this.data.selectedItems[0])
				{
					this.selectedClasseur = this.workspaceData.selectedEtablissement;
				}

                if (this.data.selectedItems[1])
				{
					this.selectedSession = this.workspaceData.selectedSession;
				}

                if (this.data.selectedItems[2])
				{
					this.selectedExamen = this.workspaceData.selectedExamen;
				}

                if (this.data.selectedItems[3])
				{
					this.selectedMatiere = this.workspaceData.selectedMatiere;
				}
            }

            /**
             * lorsqu'une tache est transmise au composant d'édition
             * on remplie le formulaire avec les informations existantes
             * pour gérer l'édition
             * Setter
             */



            if (this.data.task)
            {
                this.currentTask = this.data.task;

                // ---------------------------------------
				// récupération du process courant
				// ---------------------------------------
				let idEpreuve	= this.currentTask.idEpreuve;
                let oEpreuve	= this.workspaceData.getEpreuve(idEpreuve);
//                this.scenarioList = oEpreuve.scenario;

                this.currentName			= this.currentTask.name;
                this.currentType			= Number(this.currentTask.type);
                this.currentIdSujet			= this.currentTask.idSujet;
                this.currentDescription		= this.currentTask.description;
                this.currentAssigneduser	= this.currentTask.assigneduser;
                this.selectedSubject		= this.workspaceData.getSujet(this.currentIdSujet);


                if (this.selectedSubject)
                {
                    this.fileService.getFiles(this.selectedSubject.id);
                }

                if (!this.currentAssigneduser)
                {
                    this.currentAssigneduser	= 'none';
                    this.unloggedUserSelected	= false;
                }
                else
                {
                    let selectedUser = _.find(this.usersAvailable, (user: any) => { return user.value === this.currentAssigneduser; });

                    if (selectedUser)
                    {
                        if (selectedUser.loggedOnce === false)
                        {
                            this.unloggedUserSelected = true;
                        }
						else
                        {
                            this.unloggedUserSelected = false;
                        }
                    }
                }

                this.dateEcheance = this.currentTask.endDate;
                this._initTaskParents();
                this.isTaskEdition = true;

                if (this.data.task.idSujet)
                {
                    let treeStructure = this.workspaceData.getBranchFromId(this.data.task.idSujet);

                    this.treeDatas							= {};
                    this.treeDatas.selectedEtablissement	= treeStructure.etablissement;
                    this.treeDatas.selectedSession			= treeStructure.session;
                    this.treeDatas.selectedExamen			= treeStructure.examen;
                    this.treeDatas.selectedMatiere			= treeStructure.matiere;

                    if (this.treeDatas.selectedMatiere)
                    {
                        for (let sujet of this.treeDatas.selectedMatiere.sujets)
						{
							this.subjectsAvailable.push(
								{
									value:		sujet.id,
									libelle:	sujet.name
								});
						}
					}
				}

                // ---------------------------------------
                // S'il ne s'agit pas d'une nouvelle tache que l'on créé
                // on initialise l'ensemble des champs
                // ---------------------------------------
				let jsonValue		= this.currentTask.toJson();
                this.initialTask	= Task.fromJsonObject(jsonValue);

                //initialisation des champs relatifs aux droits
                this.noAccessFile	= this.currentTask.noAccessFile;
                this.canCreateFile	= this.currentTask.canCreateFile;

                if (this.currentTask.noAccessFile === false)
                {
                    this.readOnlyFile		= this.currentTask.readOnlyFile;
                    this.canDeleteFile		= this.currentTask.canDeleteFile;
                    this.typeFileAccess		= this.currentTask.typeFileAccess;
                }
                else
                {
                    this.readOnlyFile		= false;
                    this.canDeleteFile		= false;
                    this.typeFileAccess		= 'all';
                    this.selection			= new SelectionModel<FileObject>(true, []);
                }

            }
            else
            {

                // ---------------------------------------
                // Si auncune tache n'est renseigné on considère qu'il s'agit d'un
                // ajout de tache
                // ---------------------------------------
                this.currentTask			= new Task();
                this.currentTask.flags		= ['inprogress'];

                this.currentIdSujet		= this.workspaceData.selectedSujet.id;
                this.selectedSubject	= this.workspaceData.getSujet(this.currentIdSujet);
				this.scenarioList		= this.workspaceData.selectedMatiere.scenario;


                if (this.workspaceData.selectedMatiere)
                {
                    for (let sujet of this.workspaceData.selectedMatiere.sujets)
					{
						this.subjectsAvailable.push(
							{
								value:		sujet.id,
								libelle:	sujet.name
							});
                    }
                }

				this.translate.get('editorPanel.formatedText1').subscribe((value1: string) =>
				{
					this.translate.get('editorPanel.formatedText2').subscribe((value2: string) =>
					{
						this.currentName = value1 + this.getTypeName() + value2 + this.workspaceData.selectedSujet.name;
					});
				});


                this.currentAssigneduser = 'none';
                this._initTaskParents();
                this.isTaskEdition = false;

                this.selection = new SelectionModel<FileObject>(true, []);
            }


            this.moFormCtrlName					= this.fb.control(this.currentName,			Validators.required);
            this.moFormCtrType					= this.fb.control(this.currentType,			Validators.required);
            this.moFormCtrSubject				= this.fb.control(this.currentIdSujet,		Validators.required);
            this.moFormCtrDescription			= this.fb.control(this.currentDescription,	Validators.required);
            this.moFormCtrEcheance				= this.fb.control(this.dateEcheance,		Validators.required);
            this.moFormCtrAssignedUser			= this.fb.control(this.currentAssigneduser,	Validators.required);
            this.moFormCtrNoAccessFile			= this.fb.control(this.noAccessFile,	    Validators.required);
            this.moFormCtrCanCreateFile			= this.fb.control(this.canCreateFile,       Validators.required);

            this.moFormGroup = this.fb.group(
				{
					name:			this.moFormCtrlName,
					type:			this.moFormCtrType,
					subject:		this.moFormCtrSubject,
					description:	this.moFormCtrDescription,
					dateEcheance:	this.moFormCtrEcheance,
					assignedUser:	this.moFormCtrAssignedUser,
					noAccessFile:	this.moFormCtrNoAccessFile,
                    canCreateFile:	this.moFormCtrCanCreateFile
                });
        }
    }
	

    public ngOnInit()
	{
        this.moUserDataEmmiterSubscription = this.userData.globalEmitter.subscribe((value: string) =>
		{
			if (value === 'files::get')
			{
                this.initExistingFiles();
            }

        });
        this.initExistingFiles();
    }


    public ngOnDestroy()
	{
        this.moUserDataEmmiterSubscription.unsubscribe();
	}


    public initExistingFiles()
    {

    }


    /**
     *
     */
    public changeSelectedUser()
    {
        let selectedUser = _.find(this.usersAvailable, (user: any) => { return user.value === this.currentAssigneduser; });

        if (selectedUser)
        {
            if (selectedUser.loggedOnce === false)
            {
                this.unloggedUserSelected = true;
                this.dialog.open(WarningUnloggedUserModalComponent,
				{
                    height:			'auto',
                    width:			'auto',
                    data:			{},
                    disableClose:	true
                });

            }
			else
            {
                this.unloggedUserSelected = false;
            }
        }
    }

    /**
     * Dans la partie droits, retourne vrai si tout les fichiers sont selectionnées dans
     * la table de selection des sujets
     */
	isAllSelected() 
	{
		const numSelected	= this.selection.selected.length;
		const numRows		= this.filesSubject.objSubject.pj.length;
		
		return numSelected === numRows;
	}
	

    /**
     * Dans l'interface des droits si on souhaite choisir les fichiers accessibles
     * Selectionne tous les fichiers dans le tableau s'ils ne sont pas déja tous selectionné.
     * Dans le cas contraire ils sont tous déselectionnés
     */
	masterToggle()
	{
		this.isAllSelected() ? this.selection.clear() : this.filesSubject.objSubject.pj.forEach((pj: any) => this.selection.select(pj));
	}


	/**
	 *
	 */
    public close()
    {
        this.dialogRef.close();
    }


	/**
	 *
	 */
	public validate()
	{

		this.currentTask.name			= this.currentName;
		this.currentTask.type			= this.currentType.toString();
		this.currentTask.libelleType	= this.getTypeName();

		this.currentTask.idSujet		= this.currentIdSujet;
		this.currentTask.description	= this.currentDescription;
		this.currentTask.assigneduser	= this.currentAssigneduser;

		this.currentTask.noAccessFile	= this.noAccessFile;
		this.currentTask.canCreateFile	= this.canCreateFile;

		if (this.currentTask.noAccessFile === false)
		{
			this.currentTask.readOnlyFile	= this.readOnlyFile;
			this.currentTask.canDeleteFile	= this.canDeleteFile;
			this.currentTask.typeFileAccess = this.typeFileAccess;
			this.currentTask.fileToAccess	= _.map(this.selection.selected, (pj: any) => { return pj.id; });

		}
		else
		{
			this.currentTask.readOnlyFile	= false;
			this.currentTask.canDeleteFile	= false;
			this.currentTask.typeFileAccess	= 'none';
			this.currentTask.fileToAccess	= [];
		}


		if (this.dateEcheance)
		{
			this.currentTask.endDate = new Date(this.dateEcheance);
		}

        if (this.currentTask.assigneduser === 'none')
        {
            this.currentTask.assigneduser	= '';
            this.currentTask.state			= 'new';
        }
        else
        {
            this.currentTask.state = 'todo';
        }

		//Si c'est une édition on met la tache à jour
		if (this.isTaskEdition === true)
		{
			let datasToUpdate = this._getDatasToUpdate(this.initialTask, this.currentTask);
			this.taskService.updateTask(this.initialTask, datasToUpdate);
		}
		else
		{
			//Sinon on l'ajoute en BDD
			this.taskService.addTask(this.currentTask);

			//On récupère les clé aes des fichiers dont on donne les droits et on les ré-encode avec
			//la clé publique de l'utilisateur selectionné
			let listFile: any[];
			if (this.currentTask.typeFileAccess === 'all')
			{
				listFile = _.map(this.filesSubject.objSubject.pj, (pj: any) => { return pj.id });
			}
			else
			{
				if (this.currentTask.typeFileAccess === 'list')
				{
					listFile = _.map(this.selection.selected, (pj: any) => { return pj.id; });
				}
			}

			if (listFile && this.currentTask.assigneduser && this.currentTask.assigneduser !== '')
			{
				let currentSubject = this.workspaceData.getSujet(this.currentTask.idSujet);

				this.encryptService.encryptFilesForUSer(listFile, this.currentTask.assigneduser, currentSubject);
			}
		}

		this.dialogRef.close();
	}


	/**
	 *
	 */
    public switch_tab(tabName: any)
	{
        this.itemSelected = tabName;
    }


	/**
	 *
	 */
    private _initTaskParents()
    {
        if (this.selectedClasseur)
		{
            this.currentTask.idEtablissement = this.selectedClasseur.id;
		}

        if (this.selectedSession)
		{
            this.currentTask.idSession = this.selectedSession.id;
		}

        if (this.selectedExamen)
		{
            this.currentTask.idExamen = this.selectedExamen.id;
		}

        if (this.selectedMatiere)
		{
            this.currentTask.idEpreuve = this.selectedMatiere.id;
		}
    }


	getTypeName()
	{
		let sReturn = '';
		if (this.scenarioList)
		{
			for (let typeName of this.scenarioList.steps)
			{
				if (typeName.id === this.currentType)
				{
					sReturn = typeName.name;
				}
			}
		}
		return sReturn;
	}


	majTitle()
	{
		let sujetId		= this.currentIdSujet;
		let sujetName	= '';

		if (sujetId === 'new')
		{
			sujetName = 'Nouveau';
			this.selectedSubject = undefined;
		}
		else
		{
			this.selectedSubject	= this.workspaceData.getSujet(sujetId);
			sujetName				= this.selectedSubject.name;
		}

		this.translate.get('editorPanel.formatedText1').subscribe((value1: string) =>
		{
			this.translate.get('editorPanel.formatedText2').subscribe((value2: string) =>
			{
				this.currentName = value1 + this.getTypeName() + value2 + sujetName;
			});
		});
	}


	canValidateTask()
	{
        let result = true;

        if (!this.currentName || this.currentName === undefined || this.currentName === '')
        {
            result = false;
        }

        return result;
    }


	/**
	 *
	 */
    private _getDatasToUpdate(oldTask: Task, newTask: Task)
    {
        let datas: any = {};

        // ---------------------------------------
        // ToComment
        // ---------------------------------------
        if (oldTask.description !== newTask.description)
        {
            datas['description'] = newTask.description;
        }

        if (oldTask.name !== newTask.name)
        {
            datas['name'] = newTask.name;
        }

        if (oldTask.type !== newTask.type)
        {
            datas['type'] = newTask.type;
        }

        // ---------------------------------------
        // ToComment
        // ---------------------------------------
        if (oldTask.state !== newTask.state)
        {
            datas['state'] = newTask.state;
        }

        // ---------------------------------------
        // ToComment
        // ---------------------------------------
        if (oldTask.assigneduser !== newTask.assigneduser)
        {
            datas['assigneduser'] = newTask.assigneduser;
            //si l'utilisateur assigné est supprimé, on met automatiquement
            // la tache en non assigné
            if (datas['assigneduser'] === '')
            {
                datas['state'] = 'new';
            }
            else
            {
                //Si le nouvel utilisateur assigné est non vide et que le l'état initial
                //de la tache est non assigné alors on la met automatiquement à l'état assigné
                if (oldTask.state === 'new')
                {
                    datas['state'] = 'todo';
                }
            }
        }

        if (oldTask.endDate.getTime() !== newTask.endDate.getTime())
        {
            datas['enddate'] = newTask.endDate;
        }

        if (oldTask.noAccessFile !== newTask.noAccessFile)
        {
            datas['noaccessfile'] = newTask.noAccessFile ? '1' : '0';
        }

        if (oldTask.canCreateFile !== newTask.canCreateFile)
        {
            datas['cancreatefile'] = newTask.canCreateFile ? '1' : '0';
        }

        if (oldTask.readOnlyFile !== newTask.readOnlyFile)
        {
            datas['readonlyfile'] = newTask.readOnlyFile ? '1' : '0';
        }

        if (oldTask.canDeleteFile !== newTask.canDeleteFile)
        {
            datas['candeletefile'] = newTask.canDeleteFile ? '1' : '0';
        }

        if (oldTask.typeFileAccess !== newTask.typeFileAccess)
        {
            datas['typefileaccess'] = newTask.typeFileAccess;
        }

        let intersectFiles = _.intersection(oldTask.fileToAccess, newTask.fileToAccess);
		
        if (oldTask.fileToAccess.length !== newTask.fileToAccess.length || intersectFiles.length !== oldTask.fileToAccess.length)
        {
            datas['filetoaccess'] = newTask.fileToAccess;
        }

        return datas;
    }
}
