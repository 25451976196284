<mat-dialog id="connection_application_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Mot de passe oublié
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">
        <mat-card-content>
			<label>Veuillez saisir le mail du compte dont vous souhaitez réinitialiser le mot de passe. Un mail sera envoyer contenant un lien de réinitialisation.</label>
			<div class="clear"></div>
			<mat-form-field>
				<input type="text" matInput maxLength="80" placeholder="E-mail" id="connection_application_modal_mail" [(ngModel)]="usermail">
			</mat-form-field>
			<div class="clear"></div>
			<div class="error" *ngIf="displayError">
				<label>Erreur: le compte saisi est inexistant</label>
			</div>

		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button class="cancel_button" type="button" mat-raised-button color="" (click)="cancel()">Annuler</button>
		<button class="validate_button" mat-raised-button color="accent" (click)="sendPasswordReset()" [disabled]="!canValidReset()">Valider</button>
	</div>
</mat-dialog>
