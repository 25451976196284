import { Component, ChangeDetectionStrategy, EventEmitter, ViewChild, Output, TemplateRef, ViewEncapsulation, OnInit, OnDestroy }	from '@angular/core';

import { startOfMonth, endOfMonth, isSameDay, isSameMonth }	from 'date-fns';
import { CalendarEventTimesChangedEvent }					from 'angular-calendar';

import { Subject }					from 'rxjs';
import { UserData }					from '../../providers/user-data';
import { WorkspaceData }			from '../../providers/workspace-data';
import { TaskData }					from '../../providers/task-data';
import { SocketService }			from '../../services/socket.service';
import { ISocketData, SocketWhat }	from '../../models/message.model';


const colors: any =
	{
		red:
		{
			primary:	'#ad2121',
			secondary:	'#FAE3E3'
		},
		blue:
		{
			primary:	'#1e90ff',
			secondary:	'#D1E8FF'
		},
		yellow:
		{
			primary:	'#e3bc08',
			secondary:	'#FDF1BA'
		}
	};


@Component({
	changeDetection:	ChangeDetectionStrategy.OnPush,
	selector:			'app-calendar',
	encapsulation:		ViewEncapsulation.None,
	templateUrl:		'./calendar.component.html',
	styleUrls:			['./calendar.component.scss']
})


/**
 * 
 */
export class CalendarComponent implements OnInit, OnDestroy
{

	@ViewChild('modalContent') modalContent: TemplateRef<any>;
	@Output() viewDateChange: EventEmitter<Date>	= new EventEmitter();
	@Output() clickDateEvent: EventEmitter<any>		= new EventEmitter();


	public refresh: Subject<any> = new Subject();

	public view:				string	= 'month';
	public viewDate:			Date	= new Date();
	public events:				any[]	= [];
	public socket:				any		= undefined;
	public activeDayIsOpen:		boolean = true;
	public moDateSubscription:	any;

	public modalData:
	{
		action: string;
		event:	any;
	};


	/**
	 * 
	 * @param userData 
	 * @param socketService 
	 * @param workspaceData 
	 * @param taskData 
	 */
	constructor(public userData:		UserData,
				public socketService:	SocketService,
				public workspaceData:	WorkspaceData,
				public taskData:		TaskData)
	{
	}
	
	
	/**
	 * 
	 */
	public ngOnInit()
	{
		this.moDateSubscription = this.userData.globalEmitter.subscribe((value: string) =>
		{
			//Evènement récupéré lorsqu'un nouveau classeur à été ajouté
			//On ajoute donc le dernier elément de la structure dans user data
			//dans les classeurs du composant
			if (value === 'calendar:newDate')
			{
				this._initCalendarFromUserDatas();
			}

		});

		this.viewDateChange.subscribe((viewDate: any) =>
		{

			let month = (viewDate.getFullYear() * 100 + (viewDate.getMonth() + 1));

			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					this.socket = socket;

					let data: ISocketData =
						{
							'iam':	'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.request,
							'cmd':	'calendar',
							'args': { month: month }
						};
					socket.emit('read', data);
				}
			});
		});

		//On apelle une fois l'initialisation pour traiter les éventuelles
		//dates existantes dans le localstorage
		this._initCalendarFromUserDatas();
	}


	/**
	 * 
	 */
	public ngOnDestroy()
	{
		if (this.moDateSubscription)
		{
			this.moDateSubscription.unsubscribe();
		}
	}


	/**
	 * Ouvre un évent à partir d'un clic sur un jour
	 */
	public dayClicked({ date, events }: { date: Date; events: any[] }): void
	{
		if (isSameMonth(date, this.viewDate))
		{
			if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0 )
			{
				this.activeDayIsOpen = false;
			}
			else
			{
				this.activeDayIsOpen	= true;
				this.viewDate			= date;
			}
		}
	}


	/**
	 * 
	 * @param param0 
	 */
	public eventTimesChanged({event, newStart, newEnd }: CalendarEventTimesChangedEvent): void
	{
		event.start = newStart;
		event.end	= newEnd;

		this.handleEvent('Dropped or resized', event);
		this.refresh.next();
	}


	/**
	 * 
	 * @param action 
	 * @param event 
	 */
	public handleEvent(action: string, event: any): void
	{
		if (event && event.id)
		{
			let idSplitted = event.id.split('::');
			if (idSplitted.length > 1 && idSplitted[0] === '*task')
			{
				let task = this.taskData.getTaskById(idSplitted[1]);
				this.clickDateEvent.emit(task);
			}
		}
		// this.modalData = { event, action };
		// this.modal.open(this.modalContent, { size: 'lg' });
	}


	/**
	 * 
	 */
	private _initCalendarFromUserDatas()
	{
		let calendarDatas	= this.userData.getCalendarDatas();
		this.events			= [];

		for (let dateKey of Object.keys(calendarDatas))
		{
			if (calendarDatas[dateKey])
			{
				let date = calendarDatas[dateKey];

				if (date.unique)
				{
					let objDate = new Date(date.unique.date);
					this.events.push(
						{
							start: objDate,
							title: date.unique.libelle,
							color: colors.blue,
							id:    date.unique.id
						});
				}

				if (date.inter)
				{
					let objDate = new Date(date.inter.date);
					this.events.push(
						{
							start:	startOfMonth(objDate),
							end:	endOfMonth(objDate),
							title:	date.inter.libelle,
							color:	colors.yellow,
							id: 	date.inter.id
						});
				}

				if (date.debut)
				{
					let objDate = new Date(date.debut.date);

					let newEvent: any =
						{
							start:	objDate,
							end:	endOfMonth(objDate),
							title:	date.debut.libelle,
							color:	colors.yellow,
							id: 	date.debut.id
						}

					if (date.fin)
					{
						newEvent.end = new Date(date.fin.date);
					}

					this.events.push(newEvent);
				}
			}
		}
		this.refresh.next();
	}
}