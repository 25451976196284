import { Component, OnInit, OnDestroy, AfterViewInit }	from '@angular/core';

import { ViewEncapsulation }	from '@angular/core';
import { Router }				from '@angular/router';
import { ChatData }				from '../../providers/chat-data';
import { SavedData }			from '../../providers/saved-data';
import { TaskData }				from '../../providers/task-data';
import { UserData }				from '../../providers/user-data';
import { WorkspaceData }        from '../../providers/workspace-data';
import { AppComponent }			from '../../app.component';
import { SocketService }		from '../../services/socket.service';
import { StatisticsService }	from '../../services/statistics.service';
import { Task }					from '../../classes/task.class';

import { ISocketData, SocketWhat }				from '../../models/message.model';

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';

import * as _ from 'underscore';
//import Quill from 'quill';
// add image resize module
// add mention module


const colors: any =
	{
		red:
		{
			primary:	'#ad2121',
			secondary:	'#FAE3E3'
		},
		blue:
		{
			primary:	'#1e90ff',
			secondary:	'#D1E8FF'
		},
		yellow:
		{
			primary:	'#e3bc08',
			secondary:	'#FDF1BA'
		}
	};


@Component({
	selector:		'page-home',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./home.component.scss'],
	templateUrl:	'home.component.html'
})


/**
 *
 */
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy
{

	public localState:				any;
	public title:					any;
	public someUrl:					string	= "assets/img/marcel.png";
	public name:					string	= 'user';
	public ckeditorContent:			string  = 'test';
	public canAccessChat:			boolean = true;
	public haveTask:				boolean = false;
	public haveTaskAdminExp:		boolean = false;
	public haveTaskAdminValidate:	boolean = false;
	public haveChatData:			boolean = false;
	public haveEpreuve:				boolean = false;
	public canAccessForum:			boolean = false;
	public canAccessNotification:	boolean = false;
	public viewDate							= subDays(startOfDay(new Date()), 1);
//	public locale							= 'fr';
	public tooltipAppendToBody				= false;
	public socket:					any;
	public data:					any;

	public color			= 'secondary';
	public isRoomSetting	= false;

	public events = [
		{
			start:		subDays(startOfDay(new Date()), 1),
			end:		addDays(new Date(), 1),
			title:		'A 3 day event',
			color:		colors.red,
			actions:	this.test()
		},
		{
			start:		startOfDay(new Date()),
			title:		'An event with no end date',
			color:		colors.yellow,
			actions:	this.test()
		},
		{
			start:		subDays(endOfMonth(new Date()), 3),
			end:		addDays(endOfMonth(new Date()), 3),
			title:		'A long event that spans 2 months',
			color:		colors.blue
		},
		{
			start:		addHours(startOfDay(new Date()), 2),
			end:		new Date(),
			title:		'A draggable and resizable event',
			color:		colors.yellow,
			actions:	this.test(),
			resizable:
			{
				beforeStart:	true,
				afterEnd:		true
			},
			draggable: true
		}
	];

	public moAdminTasks:	any		= [];
	public moTasks:			any		= [];
	public maRooms:			any		= [];
	public maHomeRooms:		any		= [];
	public showTask					= true;
	public showAdminTask			= true;
	public listner:			any;


    /**
     * Creates an instance of HomePage.
     *
     * @param {NavController} navCtrl
     * @param {TranslateService} translate
     *
     * @memberOf HomePage
     */
	constructor(
		public	chatData:			ChatData,
		public	appComponent:		AppComponent,
		public	userData:			UserData,
		public	savedData:			SavedData,
		public  workspaceData:		WorkspaceData,
		public	socketService:		SocketService,
		public 	statisticsService:	StatisticsService,
		public	router:				Router)
	{
		// Affectation du titre de la page
		appComponent.setPageTitle('Accueil');

		if (this.userData.canAccessNotification === true)
		{
			this.canAccessNotification = true;
		}

		this.data 		= {};
		this.listner 	= appComponent.homeInfosEmitter.subscribe((value: any) => this.switchEmitterValue(value),
											(error: any) => console.log(error),
											() => console.log('done'));

		// ---------------------------------------
		// Récupération de la liste des rooms dispo
		// ---------------------------------------
	}


	/**
	 *
	 */
	public test()
	{

	}


	/**
	 *
	 * @param value
	 */
	public switchEmitterValue(value: any)
	{
			switch (value)
		{
			case 'homeRoomsOk':
				break;

			case 'homeTasksOk':
				this.getHomeTask();
				break;

			case 'homeEpreuveOk':
				this.haveEpreuveAdmin();
				break;

			default:

				break;
		}
	}


	/**
	 *
	 */
	public getHomeRooms()
	{
	}


	/**
	 *
	 */
	public getHomeTask()
	{
		this.haveTaskData();
		this.haveTaskDataAdmin();
	}


	/**
	 * Clic permettant d'accéder directement à l'interface d'une épreuve
	 * @param epreuve
	 */
	goToEpreuve(epreuve: any)
	{
		//On selectionne les noeuds parents par leurs identifiants
		if (epreuve.parents)
		{
			if (epreuve.id)
			{
				this.workspaceData.selectedMatiere	= this.workspaceData.getEpreuve(epreuve.id);
			}
			this.workspaceData.selectedItems = [];

			for (let parent of epreuve.parents)
			{
				if (parent.startsWith('*'))
				{
					let arrayValue	= parent.split('::');
					let key			= arrayValue[0];
					let valueTag	= arrayValue[1];

					switch (key)
					{
						case '*etab':
							this.workspaceData.selectedEtablissement	= this.workspaceData.getEtablissement(valueTag);
							this.workspaceData.selectedItems[0]			= valueTag;

							break;

						case '*sess':
							this.workspaceData.selectedSession 	= this.workspaceData.getSession(valueTag);
							this.workspaceData.selectedItems[1] = valueTag;
							break;

						case '*exam':
							this.workspaceData.selectedExamen 	= this.workspaceData.getExamen(valueTag);
							this.workspaceData.selectedItems[2] = valueTag;
							break;

						default:
							break;
					}

					this.workspaceData.selectedSujet 	= null;

					this.workspaceData.selectedItems[3] = this.workspaceData.selectedMatiere.id;
					this.workspaceData.selectedSegment	= 'all';
					this.savedData.lastGroup 			= 'epreuve';

					this.router.navigate(['/workspace']);
				}
			}
		}
	}


	/**
	 *
	 * @param task
	 */
	public goToSubject(task: Task)
	{
		let canAccessTask = this.canAccessTask(task);

		if (canAccessTask === true)
		{
			// ===================================
			// Affectation des objets
			// ===================================
			this.workspaceData.selectedSujet			= this.workspaceData.getSujet(task.idSujet);
			this.workspaceData.selectedMatiere			= this.workspaceData.getEpreuve(task.idEpreuve);
			this.workspaceData.selectedExamen			= this.workspaceData.getExamen(task.idExamen);
			this.workspaceData.selectedSession			= this.workspaceData.getSession(task.idSession);
			this.workspaceData.selectedEtablissement	= this.workspaceData.getEtablissement(task.idEtablissement);;

			this.workspaceData.selectedItems = [];
			if (this.workspaceData.selectedEtablissement)
			{
				this.workspaceData.selectedItems[0] = this.workspaceData.selectedEtablissement.id;
			}
			if (this.workspaceData.selectedSession)
			{
				this.workspaceData.selectedItems[1] = this.workspaceData.selectedSession.id;
			}
			if (this.workspaceData.selectedExamen)
			{
				this.workspaceData.selectedItems[2] = this.workspaceData.selectedExamen.id;
			}
			if (this.workspaceData.selectedMatiere)
			{
				this.workspaceData.selectedItems[3] = this.workspaceData.selectedMatiere.id;
			}

			this.workspaceData.selectedTask		= task;
			this.workspaceData.selectedSegment	= 'all';
			this.workspaceData.goToSubject		= true;
			this.savedData.lastGroup			= 'task';


			if (this.userData.isAdmin() || this.userData.isSuperviseur() === true)
			{
				this.savedData.lastGroup = 'epreuve';
			}
			this.router.navigate(['/workspace']);
		}
		else
		{
			this.workspaceData.goToSubject		= false;
			this.router.navigate(['/workspace']);
		}
  	}


	  /**
	   *
	   * @param task
	   */
	public canAccessTask(task: Task)
	{
		let result = true;

		if ((this.userData.isAdmin() === false && this.userData.isSuperviseur() === false) && task.state === 'done')
		{
			result = false;
		}

		return result;
	}


	/**
	 *
	 */
	public haveTaskData()
	{
		let tasks 	= this.workspaceData.taskHomeMine;
		let nNumber = 0;
		let bReturn = false;

		for (let task in tasks)
		{
			nNumber++;
		}

		if (tasks && nNumber > 0)
		{
			this.haveTask 	= true;
			bReturn 		= true;
		}

		return bReturn;
	}


	/**
	 *
	 */
	public haveTaskDataAdmin()
	{
		let adminTasks			= this.workspaceData.taskHomeAdmin;
		let adminTasksExpired	= this.workspaceData.taskHomeAdminExpired;
		let nNumber				= 0;
		let nNumberExp			= 0;

		for (let task of adminTasks)
		{
			if (task.state === 'review')
			{
				nNumber++;
			}
		}

		if (adminTasks && nNumber > 0)
		{
			this.haveTaskAdminValidate = true;
		}

		for (let task of adminTasksExpired)
		{
			nNumberExp++;
		}

		if (adminTasksExpired && nNumberExp > 0)
		{
			this.haveTaskAdminExp = true;
		}
	}


	/**
	 *
	 */
	public haveEpreuveAdmin()
	{
		let epreuves	= this.workspaceData.epreuveHomeAdmin;
		let nNumber		= 0;

		for (let epreuve of epreuves)
		{
			nNumber++;
		}

		if (epreuves && nNumber > 0)
		{
			this.haveEpreuve = true;
		}
	}


	/**
	 *
	 * @param element
	 */
	public clickDateEvent(element: any)
	{
		if (element && element instanceof Task)
		{
			this.goToSubject(element);
		}
	}


	/**
	 *
	 * @param event
	 */
	public keyupHandlerFunction(event: any)
	{

	}


	/**
	 *
	 * @memberOf HomePage
	 */
	public ngOnInit()
	{
		this.getHomeRooms();
		this.getHomeTask();

		this.workspaceData.resetSelectedHomeEtablissement();
		this.workspaceData.resetSelectedHomeSession();
		this.workspaceData.resetSelectedHomeExamen();
		this.workspaceData.resetSelectedHomeMatiere();
		this.workspaceData.resetSelectedHomeSujet();
	}


	/**
	 *
	 */
	public ngAfterViewInit()
	{
		this.statisticsService.getEpreuveStats(null, 160);
	}


	/**
	 *
	 */
	public ngOnDestroy()
	{

	}


	/**
	 *
	 */
	public changeSelectedEtablissement(event: any)
	{

		this.workspaceData.selectedHomeEtablissement = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
			this.workspaceData.resetSelectedHomeSession();
			this.workspaceData.resetSelectedHomeExamen();
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}
		else
		{
			this.workspaceData.resetSelectedHomeEtablissement();
			this.workspaceData.resetSelectedHomeSession();
			this.workspaceData.resetSelectedHomeExamen();
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}

		this.workspaceData.filterHomeTasks('etab', id);
	}


	/**
	 *
	 */
	public changeSelectedSession(event: any)
	{
		this.workspaceData.selectedHomeSession = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;

			this.workspaceData.resetSelectedHomeExamen();
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}
		else
		{
			this.workspaceData.resetSelectedHomeSession();
			this.workspaceData.resetSelectedHomeExamen();
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}
		this.workspaceData.filterHomeTasks('sess', id);
	}


	/**
	 *
	 */
	public changeSelectedExamen(event: any)
	{
		this.workspaceData.selectedHomeExamen = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}
		else
		{

			this.workspaceData.resetSelectedHomeExamen();
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}

		this.workspaceData.filterHomeTasks('exam', id);
	}


	/**
	 *
	 */
	public changeSelectedMatiere(event: any)
	{
		this.workspaceData.selectedHomeMatiere = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
			this.workspaceData.resetSelectedHomeSujet();
		}
		else
		{
			this.workspaceData.resetSelectedHomeMatiere();
			this.workspaceData.resetSelectedHomeSujet();
		}

		this.workspaceData.filterHomeTasks('matiere', id);
	}


	/**
	 *
	 */
	public changeSelectedSujet(event: any)
	{
		this.workspaceData.selectedHomeSujet = event;

		let id = null;
		if (event && event.id)
		{
			id = event.id;
		}
		else
		{
			this.workspaceData.selectedHomeSujet = null;
		}

		this.workspaceData.filterHomeTasks('suj', id);
	}
}
