<div class="dialog_grid_container_preview dialog_grid_container nomenu">

    <div class="dialog_header">
        <div class="dialog_header_title">
            {{ 'previewModal.filePreview' | translate }}
        </div>
        <div class="dialog_header_close">
			<div (click)='closeModal()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
        </div>
    </div>
    <div class="dialog_content">
		<div class="dialog_content_inner">

			<div class="dialog_content_viewer" *ngIf="hasPDF && objSrc">
				<pdf-viewer [src]="objSrc"
					(after-load-complete)="onLoadedPdf($event)"
					(on-progress)="onProgressPdf($event)"
					(page-rendered)="onRenderedPagePdf($event)">
				</pdf-viewer>

				<div *ngIf="previewProcessing === true">
					<mat-progress-spinner
						[mode]="'determinate'"
						[value]="percentLoading">
					</mat-progress-spinner>
					<div class="message-generating">
						<label>{{ messageLoading }}</label>
					</div>
				</div>
			</div>

			<div class="dialog_content_img" *ngIf="hasImg && objSrc">
				 <app-canvas [src]="objSrc" ></app-canvas>
			</div>

			<div class="dialog_content_video" *ngIf="hasVideo">

				<div *ngIf="objSrc">
					<vg-player>
						<video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
							<source [src]="objSrc" type="video/mp4">
						</video>
					</vg-player>
				</div>
				<div *ngIf="!objSrc">
					<mat-spinner></mat-spinner>
				</div>

			</div>
			<div class="clear"></div>
        </div>
		<div class="dialog_commentary" *ngIf="currentFile" >
			<comments-component class="dialog-comments" [showButton]="true" [type]="'historic-comment'" [idElement]="currentFile.id"></comments-component>
		</div>
		<div class="clear"></div>
    </div>
    <div class="dialog_footer">
		<div class="dialog_footer_download_button">

			<button mat-raised-button *ngIf="canDownloadGeneratedFile === true && hasVideo === false && currentPdfBlob" (click)='downloadPreviewFile()'>{{ 'previewModal.filePreviewDownload' | translate }}</button>
			<button mat-raised-button *ngIf="hasVideo === true && currentPdfBlob" (click)='downloadFile()'>{{ 'previewModal.fileDownload' | translate }}</button>
		</div>
        <div class="dialog_footer_cancel_button">
            <button mat-raised-button (click)='closeModal()'>{{ 'common.close' | translate }}</button>
        </div>
    </div>
</div>
