<div class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Nouvelle conversation
		</div>
		<div class="dialog_header_close">
			<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">
		<div class="create_chat_label_input">Entrez les premières lettres du nom ou du mail de votre interlocuteur</div>
		<div class="clear"></div>
		<mat-form-field>
			<input matInput placeholder="Nom ou mail" maxLength="80" [(ngModel)]="userSelected" id="modal_create_chat_userlist" [matAutocomplete]="autoNameChat" [formControl]="userCtrl" >
		</mat-form-field>
		<mat-autocomplete #autoNameChat="matAutocomplete" [displayWith]="displayUsername">
			<mat-option *ngFor="let user of filteredUsers | async" [value]="user">
				<div *ngIf="user.infos.prenom && user.infos.nom">{{ user.infos.prenom }} {{ user.infos.nom }}</div>
				<div *ngIf="!user.infos.prenom || !user.infos.nom">{{user.mail}}</div>
			</mat-option>
		</mat-autocomplete>
		<div class="clear"></div>
	</div>
	<div class="dialog_footer">
		<div class="dialog_footer_cancel_button">
			<button type="button" mat-raised-button (click)='close()'>{{ 'common.cancel' | translate }}</button>
		</div>
		<div class="dialog_footer_valid_button">
			<button type="submit"(click)="createPiece()" [disabled]="canCreateRoom() === false" mat-raised-button>{{ 'common.validate' | translate }}</button>
		</div>
	</div>
</div>
