<mat-dialog class="dialog_grid_container nomenu">
    <div class="dialog_header">
        <div class="dialog_header_title">Création d'un nouveau barème</div>
		<div class="dialog_header_close">
			<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
    </div>
    <div class="dialog_content">
		<div class="select_type">
			<mat-radio-group class="example-radio-group" (ngModelChange)="changeSelected($event)" [(ngModel)]="mnSelectedTypeId" >
				<mat-radio-button class="example-radio-button" *ngFor="let sType of masTypeList" [value]="sType.id">{{sType.name}}</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form_type" *ngIf="mjSelectedType">
			<div class="form_type_inner" *ngFor="let jSelectedTypeInput of mjSelectedType.data">
				<div class="" *ngIf="jSelectedTypeInput.optionnal">
					<section class="form_type_inner_section" >
						<mat-checkbox class="form_type_inner_check form_type_inner_label" [(ngModel)]="jSelectedTypeInput.isOptionChecked">{{jSelectedTypeInput.name}}</mat-checkbox>
						<mat-form-field class="form_type_inner_label">
							<input class="form_type_inner_input" type="number" ngMin="jSelectedTypeInput.minValue" ngMax="jSelectedTypeInput.maxValue" (change)="changeValue(jSelectedTypeInput)"  [disabled]="!jSelectedTypeInput.isOptionChecked" matInput placeholder="" [(ngModel)]="jSelectedTypeInput.displayValue" value="{{jSelectedTypeInput.displayValue}}">
							<mat-error>Merci d'entrer une valeur entre 0 et 100</mat-error>
						</mat-form-field>
					</section>
				</div>

				<div class="" *ngIf="!jSelectedTypeInput.optionnal">
					<section class="form_type_inner_section">
						<label class="form_type_inner_label" >{{jSelectedTypeInput.name}}</label>
						<mat-form-field class="form_type_inner_input">
							<input  type="number" matInput ngMin="jSelectedTypeInput.minValue" ngMax="jSelectedTypeInput.maxValue" (change)="changeValue(jSelectedTypeInput)" [(ngModel)]="jSelectedTypeInput.displayValue" value="{{jSelectedTypeInput.displayValue}}">
							<mat-error>Merci d'entrer une valeur entre 0 et 100</mat-error>
						</mat-form-field>
					</section>
				</div>	
			</div>
		</div>
	</div>

	<div class="dialog_footer">
		<div class="dialog_footer_cancel_button">
			<button mat-raised-button (click)="cancel()">Annuler</button>
		</div>
		<div class="dialog_footer_valid_button">
			<button mat-raised-button (click)="validate()">Valider</button>
		</div>
	</div>
</mat-dialog>