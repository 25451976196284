import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
	selector:		'infos-forget-password-modal',
	templateUrl:	'infosForgetPasswordModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./infosForgetPasswordModal.component.scss']
})


/**
 * 
 */
export class InfosForgetPasswordModalComponent
{
	public usermail: any;
	public type: any;


	/**
	 * 
	 * @param dialogRef 
	 * @param data 
	 */
	constructor(public dialogRef: MatDialogRef<InfosForgetPasswordModalComponent, any>, @Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (this.data && this.data.usermail && this.data.type)
		{
			this.usermail = this.data.usermail;
			this.type = this.data.type;
		}
	}
	

	/**
	 * 
	 */
	public close()
	{
		this.dialogRef.close();
	}
}
