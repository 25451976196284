<mat-dialog id="connection_application_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			{{ 'connectApplication.title' | translate }}
		</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

		<mat-card-content>
			<mat-form-field class="first">
				<input type="text" readonly matInput maxLength="80" placeholder="{{ 'connectApplication.identifiant' | translate }}" (keypress)="eventHandler($event)" id="connection_application_modal_mail" [(ngModel)]="username">
			</mat-form-field>
			<div class="clear"></div>
			<mat-form-field>
				<input type="password" matInput placeholder="{{ 'connectApplication.password' | translate }}" (keypress)="eventHandler($event)" id="connection_application_modal_password" [(ngModel)]="password">
			</mat-form-field>
			<div class="clear"></div>
			<label *ngIf="isSocketUnavailable">{{ 'connectApplication.serverUnavailable' | translate }}</label>
		</mat-card-content>

    </div>
	<div class="dialog_footer">
		<button id="cancel-connect" class="cancel_button" mat-raised-button (click)="cancel()">{{ 'common.cancel' | translate }}</button>
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="login()" [disabled]="!canValidLogin()">{{ 'chat.connect' | translate }}</button>
	</div>
</mat-dialog>
