import { Component, Inject }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA }            from '@angular/material';


@Component({
    selector:		'infos-delete-processus-modal',
    templateUrl:	'infosDeleteProcessusModal.component.html',
    styleUrls:		['./infosDeleteProcessusModal.component.scss']
})


/**
 * 
 */
export class InfosDeleteProcessusModalComponent
{
	public deleted:			any;
	public scenarioUsed:	any;
	public scenario:		any;
	public titleModal:		any;
	public messageModal:	any;
	

    constructor(public dialogRef:		MatDialogRef<InfosDeleteProcessusModalComponent, any>, @Inject(MAT_DIALOG_DATA) public data: any)
    {
		if (data && data.deleted !== undefined && data.scenarioUsed !== undefined && data.scenario !== undefined)
		{
			this.deleted		= data.deleted;
			this.scenarioUsed	= data.scenarioUsed;
			this.scenario		= data.scenario;

			if (this.deleted === true)
			{
				this.titleModal		= 'Confirmation suppression de scénario';
				this.messageModal	= 'Le scénario suivant a bien été supprimé :';
			}
			else
			{
				this.titleModal = 'Erreur suppression de scénario';

				if (this.scenarioUsed === true)
				{
					this.messageModal = 'Le scénario suivant n\'as pas pu être supprimé car il est utilisé par une épreuve existante :';
				}
				else
				{
					this.messageModal = 'Le scénario suivant n\'as pas pu être supprimé, veuillez rééssayer ultérieurement :';
				}
			}
		}
	}


	/**
	 * 
	 */
    public validate()
    {
        this.dialogRef.close();
    }
}
