<mat-dialog class="dialog_grid_container nomenu">
    <div class="dialog_header">
        <div class="dialog_header_title">Affecter un barème à la passation</div>
		<div class="dialog_header_close">
			<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
    </div>
	<div class="dialog_content">
		<mat-tab-group class="bareme_mat_group">
			<mat-tab label="Principal">
				<app-bareme-editor [majData]="majMainQCM" [subjectType]="'main'" [passationId]="msPassationId"></app-bareme-editor>
			</mat-tab>
			<mat-tab label="Secondaire">
				<app-bareme-editor [majData]="majSecQCM"  [subjectType]="'sec'" [passationId]="msPassationId">></app-bareme-editor>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="dialog_footer">
		<div class="dialog_footer_cancel_button">
			<button mat-raised-button (click)="cancel()">Annuler</button>
		</div>
		<div class="dialog_footer_valid_button">
			<button mat-raised-button [disabled]="idsSubjectSelected.length > 0" (click)="validate()">Valider</button>
		</div>
	</div>
</mat-dialog>
