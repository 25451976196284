//import { HtmlMakerComponent } from '../components/html-maker/html-maker.component';
import { ComponentFactoryResolver } from '@angular/core';


/**
 * Classe représentant les membres d'une ... classe
 * Permet d'affecter un type d'input par membre de classe et d'avoir leur rendu par un getHTML.
 * @export
 * @class User
 */
export class Member
{
//	htmlMaker: HtmlMakerComponent;
	componentFactoryResolver: ComponentFactoryResolver;

    /**
     * Creates an instance of User.
     * 
     * @param {number} [_id=0]
     * @param {string} [_username='']
     * @param {string} [_etablissement='']
     * @param {string} [_session='']
     * @param {string} [_examen='']
     * @param {string} [_status='']
     * 
     * @memberOf User
     */
    constructor(
        private _msId:				string	= '',
        private _msName:			string	= '',
		private _msClass:			string	= '',
        private _msValue:			any		= '',
        private _msType:			string	= '',
        private _msNgModel:			string	= '',
        private _msPlaceholder:		string	= '',
        private _mbRequired:		boolean = false,
		private _mbAutoComplete:	boolean = false,
		private _mbHidden:			boolean = false,
		private _msSpecial:			string	= ''
	)
	{
//		this.htmlMaker = new HtmlMakerComponent(this.componentFactoryResolver);
	}


    get msId():				string	{ return this._msId; }
    get msName():			string	{ return this._msName; }
    get msClass():			string	{ return this._msClass; }
    get msValue():			any		{ return this._msValue; }
    get msType():			string	{ return this._msType; }
    get msNgModel():		string	{ return this._msNgModel; }
    get msPlaceholder():	string	{ return this._msPlaceholder; }
    get mbRequired():		boolean { return this._mbRequired; }
    get mbAutoComplete():	boolean { return this._mbAutoComplete; }
    get mbHidden():			boolean { return this._mbHidden; }
    get msSpecial():		string	{ return this._msSpecial; }


	set msId(psId:						string)		{ this._msId			= psId; }
    set msName(psName:					string)		{ this._msName			= psName; }
    set msClass(psClass:				string)		{ this._msClass			= psClass; }
	set msValue(psvalue:				any)		{ this._msValue			= psvalue; }
    set msType(psType:					string)		{ this._msType			= psType; }
    set msNgModel(psNgModel:			string)		{ this._msNgModel		= psNgModel; }
    set msPlaceholder(psPlaceholder:	string)		{ this._msPlaceholder	= psPlaceholder; }
    set mbRequired(pbRequired:			boolean)	{ this._mbRequired		= pbRequired; }
    set mbAutoComplete(pbAutoComplete:	boolean)	{ this._mbAutoComplete	= pbAutoComplete; }
    set mbHidden(pbHidden:				boolean)	{ this._mbHidden		= pbHidden; }
    set msSpecial(psSpecial:			string)		{ this._msSpecial		= psSpecial; }


	/**
	 * Construit le code HTML pour un input donné
	 */
	public getFormInput() 
	{
		let sReturn = '';
		
		switch (this.msType)
		{
//			case 'input':
//				sReturn = this.htmlMaker.getInputText(this.msNgModel, this.msClass, this.msName, this.msId);
//				break;
//				
//			case 'textarea':
//				sReturn = this.htmlMaker.getTextArea(this.msNgModel, this.msClass, this.msName, this.msId);
//				break;
//				
//			case 'date':
////				sReturn = this.getDatePicker();
//				break;
//				
//			case 'checkbox':
//				sReturn = this.htmlMaker.getCheckbox(this.msNgModel, this.msClass, this.msName, this.msId);
//				break;
//				
//			case 'radio':
//				sReturn = this.htmlMaker.getCheckbox(this.msNgModel, this.msClass, this.msName, this.msId);
//				break;
//				
////			case 'input':
////				sReturn = this.getInput();
////				break;
//
//			case 'special':
//				sReturn = this.getSpecial();
//				break;
//
			default:

				break;
		}
		
		return sReturn;
	}


	private getSpecial()
	{
		let sReturn = '';
		return sReturn;
	}

}


