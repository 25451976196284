<div class="tree-view" >
	<mat-list *ngIf="chatData.canGetChatData()">
		<mat-list-item  class="group sc2rv-item " *ngFor="let piece of chatData.rooms" >
				<button  mat-button class="" [class.selected]="selectedRoomId === piece.id" (click)="openPiece(piece)" [class.active]="isPieceSelected(piece)">
				<span class="global_community_item_counter" *ngIf="piece.counter > 0">{{piece.counter}}</span>
				<app-user [_name]="piece.displayName" [_lastName]="chatData.getLastname(piece.users)"  [_color]="chatData.getUserColor(piece.users)" [_status]="piece.description" [_state]="chatData.getUserState(piece.users)" [_icone]="" [displayType]="'list'"></app-user>
			</button>
		</mat-list-item>
	</mat-list>
</div>

<app-chat-detail *ngIf="isRoomSelected" [isRoomSelected]="isRoomSelected" (onChange)="changeFocus($event)" (onSendMessage)="sendMessage($event)" [displayMessage]="displayMessage"></app-chat-detail>
<div class="content_view" *ngIf="!isRoomSelected" >
	<div class="commons_content_empty">
		<img class="commons_content_empty_img" src="assets/img/dossier-vide.png">
			<h2>{{ 'chat.chooseConversation' | translate }}</h2>
			<button mat-raised-button class="commons_content_empty_button" (click)="addCommunityItem()">Ajouter une conversation</button>
	</div>
</div>
<div class="commons_add_block">
	<button mat-fab (click)="addCommunityItem()"><i class="material-icons">add</i></button>
</div>
<div class="login_wait" *ngIf="isLoading">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
	</svg>
</div>
