import { Component, OnInit, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { WorkspaceData }		from '../../../../providers/workspace-data';
import { Task  as TaskClass}	from '../../../../classes/task.class';
import { SavedData }			from '../../../../providers/saved-data';
import { TaskData }				from '../../../../providers/task-data';
import { UserData }				from '../../../../providers/user-data';
import { Task }					from '../../model/task';
import * as _					from 'underscore';
import { Router }				from '@angular/router';


@Component({
	selector:		'ticket',
	templateUrl:	'./ticket.component.html',
	inputs:			['task'],
	encapsulation:	ViewEncapsulation.None,
	outputs:		['_editTicket: onEdit', '_addTicket: onAdd', '_removeTicket: onRemove', '_displayDetails: onSelect', '_focusTicket: onFocus'],
	styleUrls:		['./ticket.component.css']
})


/**
 *
 */
export class TicketComponent implements OnInit
{
	task: Task;
	@Input()		typeTicket: string;

	public _editTicket:			EventEmitter<Task> = new EventEmitter<Task>();
	public _removeTicket:		EventEmitter<Task> = new EventEmitter<Task>();
	public _displayDetails:		EventEmitter<Task> = new EventEmitter<Task>();
	public _focusTicket:		EventEmitter<Task> = new EventEmitter<Task>();
	public _addTicket:			EventEmitter<Task> = new EventEmitter<Task>();


	/**
	 *
	 */
	constructor(
		public workspaceData:	WorkspaceData,
		public userData:		UserData,
		public savedData:		SavedData,
		public router:			Router)
	{
	}


	/**
	 *
	 *
	 *
	 *
	 */
	public ngOnInit()
	{

	}


	/**
	 *
	 */
	public editTicket()
	{
		this._editTicket.emit(this.task);
	}


	/**
	 *
	 */
	public addTicket()
	{

		this._addTicket.emit(this.task);
	}


	/**
	 *
	 */
	public focusTicket()
	{
		this._focusTicket.emit(this.task);
	}


	/**
	 *
	 */
	public removeTicket()
	{
		if (confirm("Confirmez vous la suppression de " + this.task.name + ' ?' ))
		{
			this._removeTicket.emit(this.task);
		}
	}


	/**
	 *
	 */
	public selectTicket(evt: any)
	{
		if (evt)
		{
			evt.preventDefault();
		}
		this._displayDetails.emit(this.task);
	}

	/**
	 *
	 * @param task
	 */
	public addSubTask(task: any)
	{
	}


	public gotoSubject(idSujet: any)
	{
		this.workspaceData.constructAutoTreeBySubjectId(idSujet);
	}



	public goToSubject(task: TaskClass)
	{
		// ===================================
		// Affectation des objets
		// ===================================

		this.workspaceData.selectedSujet			= this.workspaceData.getSujet(task.idSujet);
		this.workspaceData.selectedMatiere			= this.workspaceData.getEpreuve(task.idEpreuve);
		this.workspaceData.selectedExamen			= this.workspaceData.getExamen(task.idExamen);
		this.workspaceData.selectedSession			= this.workspaceData.getSession(task.idSession);
		this.workspaceData.selectedEtablissement	= this.workspaceData.getEtablissement(task.idEtablissement);;

		this.workspaceData.selectedItems	= [];
		this.workspaceData.selectedItems[0] = this.workspaceData.selectedEtablissement.id;
		this.workspaceData.selectedItems[1] = this.workspaceData.selectedSession.id;
		this.workspaceData.selectedItems[2] = this.workspaceData.selectedExamen.id;
		this.workspaceData.selectedItems[3] = this.workspaceData.selectedMatiere.id;

		this.workspaceData.selectedTask		= task;
		this.workspaceData.selectedSegment	= 'subject';
		this.workspaceData.goToSubject		= true;
		this.savedData.lastGroup			= 'task';


		if (this.userData.isAdmin() || this.userData.isSuperviseur() === true)
		{
			this.savedData.lastGroup = 'epreuve';
		}

		this.router.navigate(['/workspace']);
  	}

}
