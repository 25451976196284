<mat-dialog id="download_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">Nouvelle clé RSA</div>
	</div>
	<div class="dialog_content">
		<mat-card-content>
			<label>Votre clé RSA vient d'être enregistrée dans votre navigateur.</label>
			<div class="clear"></div>
			<label>Afin d'assurer un accès sécurisé à votre compte, il vous est vivement recommandé d'enregistrer cette clé sur votre ordinateur.</label>
			<div class="clear"></div>
			<button id="valid-connect" class="download_button" mat-raised-button color="accent" (click)="downloadRsa()">Télécharger la clé RSA</button>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="close()">Ok</button>
	</div>
</mat-dialog>
