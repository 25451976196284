﻿import { Injectable } from '@angular/core';
import { Http }	from '@angular/http';


@Injectable()


export class CreationHttpService
{

	private static useApiUrl:		boolean = undefined; // l'api ce trouve, sur le serveur de connexion, a part si vrai si dessous
	private static useChatUrl:		boolean = undefined; // idem on peut deporter le chat
	private static chatSecure:		boolean = undefined; // http ou https (si pas automatique)
	private static apiBehindProxy:	boolean = undefined; // proxy = rajouter un chemin
	private static chatBehindProxy: boolean = undefined; // proxy = rajouter un chemin
	private static apiUrl:			string	= undefined; // http ou https, url de l'api et port si besoin'
	private static apiPath:			string	= undefined;
	private static chatUrl:			string	= undefined;
	private static chatPath:		string	= undefined;
	private static chatPort:		string	= undefined;

	public static initConfigs(http: Http): Promise<any>
	{ 
		return new Promise((resolve, reject) =>
		{
			 const jsonFile 	= 'assets/configprod.json';
			// const jsonFile 	= 'assets/configpreprod.json';
			// const jsonFile 		= 'assets/configlocal.json';
			
			http.get(jsonFile).toPromise().then((res: any) =>
			{
				if (res && res._body)
				{
					const configsJson: any = JSON.parse(res._body);

					CreationHttpService.useApiUrl 		= configsJson.useApiUrl 		=== 'true' ? true : false;
					CreationHttpService.useChatUrl 		= configsJson.useChatUrl 		=== 'true' ? true : false;
					CreationHttpService.chatSecure 		= configsJson.chatSecure 		=== 'true' ? true : false;
					CreationHttpService.apiBehindProxy 	= configsJson.apiBehindProxy 	=== 'true' ? true : false;
					CreationHttpService.chatBehindProxy = configsJson.chatBehindProxy 	=== 'true' ? true : false; 
					CreationHttpService.apiUrl 			= configsJson.apiUrl;
					CreationHttpService.apiPath 		= configsJson.apiPath;
					CreationHttpService.chatUrl 		= configsJson.chatUrl;
					CreationHttpService.chatPath 		= configsJson.chatPath;
					CreationHttpService.chatPort 		= configsJson.chatPort;

					resolve();
				} 
				else 
				{
					reject();
				} 

			}).catch(err =>
			{
				reject();
			});
		});
	}


	/**
	 *  Retourne l'url de l'api (normalement sur le meme serveur que le serveur http)
	 */
	public static getAPIUrl(): string
	{
		if (CreationHttpService.useApiUrl)
		{
			if (CreationHttpService.apiBehindProxy)
			{
				return CreationHttpService.absolute(CreationHttpService.apiUrl, CreationHttpService.apiPath);
			}
			else
			{
				return CreationHttpService.apiUrl;
			}
		}
		else
		{
			if (CreationHttpService.apiBehindProxy)
			{
				return CreationHttpService.absolute(window.location.origin, CreationHttpService.apiPath);
			}
			else
			{
				return window.location.origin;
			}
		}
	}


    /**
	 *  Retourne l'url du chat (normalement sur le meme serveur que le serveur http)
	 */
	public static getChatUrl(): string
	{
		if (CreationHttpService.useChatUrl)
		{
			return CreationHttpService.chatUrl;
		}
		else
		{
			return window.location.hostname;
		}
	}


	public static getChatPort(): string
	{
		if (CreationHttpService.useChatUrl)
		{
			return CreationHttpService.chatPort;
		}
		else
		{
			return window.location.port;
		}
	}


	public static getChatSecure(): boolean
	{
		let bReturn;
		if (CreationHttpService.useChatUrl)
		{
			bReturn = CreationHttpService.chatSecure;
		}
		else
		{
			bReturn = window.location.protocol === 'https:';
		}
		return bReturn;
	}


	public static getChatPath(): string
	{
		if (CreationHttpService.chatBehindProxy)
		{
			return CreationHttpService.chatPath;
		}
		else
		{
			return undefined;
		}
	}


    /**
     * Ajoute une url a l'adresse en gérant le /
     * @param base
     * @param relative
     */
	public static absolute(...args: string[]): string
	{
		let join = [].slice.call(args, 0).join('/');
		return CreationHttpService.normalize(join);
	}


	/**
	 * Retourne l'url du service dans l'api
	 */
	public static getPathByWebServiceName(name: string)
	{
		let sReturn = '';

		// Si c'est en prod on va avoir besoin du suffixe
		// sReturn = '/creation';

		// Et encore on pourrait ajouter le "name" à la suite sans passer par le switch ... mais au cas ou je laisse
		switch (name)
		{
			case 'auth':
				sReturn += '/api/v1/auth/';
				break;

			case 'refresh':
				sReturn += '/api/v1/auth/refresh';
				break;

			case 'chat':
				sReturn += '/api/v1/chat/';
				break;

			case 'epreuve':
				sReturn += '/api/v1/epreuve/';
				break;

			case 'etablissement':
				sReturn += '/api/v1/etablissement/';
				break;

			case 'examen':
				sReturn += '/api/v1/examen/';
				break;

			case 'file':
				sReturn += '/api/v1/file/';
				break;

			case 'filesend':
				sReturn += '/api/v1/file/send/';
				break;

			case 'filesendforpreview':
				sReturn += '/api/v1/file/sendForPreview/';
				break;

			case 'filesendToConvert':
				sReturn += '/api/v1/file/sendToConvert/';
				break;

			case 'session':
				sReturn += '/api/v1/session/';
				break;

			case 'sujet':
				sReturn += '/api/v1/sujet/';
				break;

			case 'task':
				sReturn += '/api/v1/task/';
				break;

			case 'user':
				sReturn += '/api/v1/user/';
				break;

			default:
				break;
		}

		return sReturn;
	}


    /**
     * Fonction interne pour normaliser une url
     * @param str
     * @pzaram searchString
     */
	private static startsWith(str: string, searchString: string): boolean
	{
		return str.substr(0, searchString.length) === searchString;
	}


    /**
     * Normalise une url
     * @param str
     */
	private static normalize(str: string): string
	{

		if (CreationHttpService.startsWith(str, 'file://'))
		{
			// 3 slash pour file protocol
			str = str.replace(/(\/{0,3})\/*/g, '$1');
		}
		else
		{
			// au minimum 2 apres le protocole
			str = str.replace(/:\//g, '://');

			// enlever les slash en trop
			str = str.replace(/([^:\s\%\3\A])\/+/g, '$1/');
		}

		// enlever les slash de fin avant param
		str = str.replace(/\/(\?|&|#[^!])/g, '$1');

		// remplace ? par &
		str = str.replace(/(\?.+)\?/g, '$1&');

		return str;
	}
}
