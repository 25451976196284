import { Injectable }       from '@angular/core';

import { OrderType } from '../popover-search-filter/classes/filter.class';

import * as _ from 'underscore';

@Injectable()


export class SearchBarService
{

	public allItemsJson:			string;
	public itemsSelectedJson:		string;
	public tagsItems:				any[];
	public tabProperties:			any[]	= [];
	public tabPropertiesSelected:	any[]	= [];
	public filters:					any[];
	public filterSelected:			any;
	public separatorSearch:			string;
	public currentLevelData:		number;
	public eventEmitter:			any;


	/**
	 *
	 */
	constructor()
    {
    }


	/**
	 *
	 */
    public setDatasToManage(allItemsJson: any, tagsItems: any, filters: any, separatorSearch = ':')
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.allItemsJson		= allItemsJson;
		this.tagsItems			= tagsItems;
		this.filters			= filters;
		this.separatorSearch	= separatorSearch;

		this.filterSelected = _.where(this.filters, { default: true });
		this.filterSelected = this.filterSelected.length > 0 ? this.filterSelected[ 0 ] : undefined;

		this.tagsItems = _.reject(this.tagsItems, function (item: any) { return !item.tagName || !item.paths || item.paths.length === 0; });


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let i = 0; i < this.tagsItems.length; i++)
		{
			this.tagsItems[i].currentPathIndex = [];
			for (let j = 0; j < this.tagsItems[i].paths.length; j++)
			{
				this.tagsItems[i].currentPathIndex.push(0);
			}
		}


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.parseJsonDatas();
		this.applyFilterAndEmit();
	}


	/**
	 *
	 */
    public setEventEmiter(eventEmiter: any)
    {
        this.eventEmitter = eventEmiter;
    }


	/**
	 *
	 */
	private parseJsonDatas()
	{
		this.tabProperties			= [];
		this.tabPropertiesSelected	= [];

		// ---------------------------------------
		// Si l'ensemble des données à traiter est renseigné, alors on les convertis en structure JSON
		// ---------------------------------------
		if (this.allItemsJson)
		{
			let jsonObjDatas = JSON.parse(this.allItemsJson);

			// ---------------------------------------
			// Les données en entrée doivent être rangées dans un tableau
			// ---------------------------------------
			if (jsonObjDatas instanceof Array)
			{
				// ---------------------------------------
				// Pour chaque ensemble de données on créé une structure contenant le JSON d'origine
				// et les données mappées.
				// ---------------------------------------
				for (let data of jsonObjDatas)
				{
					let propertiesObj =
					{
						rowJson:	data,
						properties: this.parseDataRow(data)
					};

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					this.tabProperties.push(propertiesObj);
					this.tabPropertiesSelected.push(propertiesObj);
				}
			}
		}
    }


	/**
	 *
	 */
	private parseDataRow(data: any)
	{
		// ---------------------------------------
		// On affecte dans un tableau l'ensemble des tags avec leur chemins respectif
		// ---------------------------------------
		let rowProperties = [];
		for (let property of this.tagsItems)
		{
			rowProperties.push(Object.assign({}, property));
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.currentLevelData	= 0;
		rowProperties			= this.handleDataRowElement(data, rowProperties);

		return rowProperties;
	}


	/**
	 *
	 */
	private handleDataRowElement(data: any, rowProperties: any)
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let rowPropertiesResult = rowProperties;


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (data instanceof Array)
		{
			for (let subData of data)
			{
				rowPropertiesResult = this.handleDataRowElement(subData, rowPropertiesResult);
			}
		}
		else
		{
			if (data !== undefined && data !== null)
			{
				let keys = Object.keys(data);

				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				for (let key of keys)
				{
					if (typeof data[key] !== 'object')
					{
						let indexProperty = _.findIndex(rowPropertiesResult, function (property: any)
						{
							return property['property'] === key;
						});


						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						if (indexProperty !== -1)
						{
							if (rowPropertiesResult[indexProperty].values)
							{
								let valToInclude = data[key].toString().toLowerCase();
								
								if (rowPropertiesResult[indexProperty].values.includes(valToInclude) === false)
								{
									rowPropertiesResult[indexProperty].values.push(valToInclude);
								}
							}
							else
							{
								rowPropertiesResult[indexProperty].values = [data[key].toString().toLowerCase()];
							}
						}
						else
						{
							rowPropertiesResult.push({property: key, values: [data[key].toString().toLowerCase()]});
						}


						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						for (let i = 0; i < rowPropertiesResult.length; i++)
						{
							if (rowPropertiesResult[i].paths)
							{
								for (let j = 0; j < rowPropertiesResult[i].paths.length; j++)
								{
									if (this.currentLevelData === rowPropertiesResult[i].currentPathIndex[j]
										&& rowPropertiesResult[i].paths[j].length - 1 === rowPropertiesResult[i].currentPathIndex[j]
										&& rowPropertiesResult[i].paths[j][rowPropertiesResult[i].currentPathIndex[j]] === key)
									{

										// ---------------------------------------
										// ToComment
										// ---------------------------------------
										if (rowPropertiesResult[i].values)
										{
											let valToInclude = data[key].toString().toLowerCase();
											if (rowPropertiesResult[i].values.includes(valToInclude) === false)
											{
												rowPropertiesResult[i].values.push(valToInclude);
											}
										}
										else
										{
											rowPropertiesResult[i].values = [data[key].toString().toLowerCase()];
										}
										break;
									}
								}
							}
						}
					}
					else
					{

						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						for (let i = 0; i < rowPropertiesResult.length; i++)
						{
							if (rowPropertiesResult[i].paths)
							{
								// ---------------------------------------
								// ToComment
								// ---------------------------------------
								for (let j = 0; j < rowPropertiesResult[i].paths.length; j++)
								{
									if (this.currentLevelData === rowPropertiesResult[i].currentPathIndex[j]
										&& rowPropertiesResult[i].paths[j].length - 1 > rowPropertiesResult[i].currentPathIndex[j]
										&& rowPropertiesResult[i].paths[j][rowPropertiesResult[i].currentPathIndex[j]] === key)
									{
										rowPropertiesResult[i].currentPathIndex[j] = rowPropertiesResult[i].currentPathIndex[j] + 1;
									}
								}
							}
						}

						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						this.currentLevelData++;
						rowPropertiesResult = this.handleDataRowElement(data[key], rowPropertiesResult);
						this.currentLevelData--;
					}
				}
			}
		}

		return rowPropertiesResult;
	}


	/**
	 *
	 */
	public updateAvailableItems(currentSearch: any)
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.itemsSelectedJson	= '';
		let resultSearch		= [];

		this.itemsSelectedJson		= this.allItemsJson;
		this.tabPropertiesSelected	= this.tabProperties;

		let propertySelected =
		{
			key: '',
			values: ['']
		};


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (currentSearch.length > 0)
		{
			if (currentSearch.charAt(0) === '#')
			{
				let indexEndKeyWord = currentSearch.indexOf(this.separatorSearch);
				if (indexEndKeyWord !== -1)
				{
					let keyWord = currentSearch.substring(1, indexEndKeyWord);
					let value 	= currentSearch.substring(indexEndKeyWord + 1);


					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					if (value)
					{
						resultSearch = _.filter(this.tabProperties, function (propertyObj: any)
						{
							let resultFilter = _.filter(propertyObj.properties, function (property: any)
							{
								let result = false;

								// ---------------------------------------
								// ToComment
								// ---------------------------------------
								if (property['tagName'] === keyWord && property['values'])
								{
									propertySelected.key = property['tagName'];

									// ---------------------------------------
									// ToComment
									// ---------------------------------------
									for (let i = 0; i < property['values'].length; i++)
									{
										if (property['values'][i].indexOf(value) !== -1)
										{
											if (!_.contains(propertySelected.values, property['values'][i]))
											{
												propertySelected.values.push(property['values'][i]);
											}
											result = true;
											break;
										}
									}
								}

								// ---------------------------------------
								// ToComment
								// ---------------------------------------
								return result;
							});


							// ---------------------------------------
							// ToComment
							// ---------------------------------------
							return resultFilter.length > 0;
						});
					}
				}
			}
			else
			{

				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				resultSearch = _.filter(this.tabProperties, function (propertyObj: any)
				{
					let resultFilter = _.filter(propertyObj.properties, function (property: any)
					{
						let result = false;

						if (property['tagName'] === 'default' && property['values'])
						{
							propertySelected.key = property['tagName'];

							// ---------------------------------------
							// ToComment
							// ---------------------------------------
							for (let i = 0; i < property['values'].length; i++)
							{
								if (property['values'][i].indexOf(currentSearch) !== -1)
								{
									if (!_.contains(propertySelected.values, property['values'][i]))
									{
										propertySelected.values.push(property['values'][i]);
									}
									result = true;
									break;
								}
							}
						}

						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						return result;
					});

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					return resultFilter.length > 0;
				});
			}

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			this.tabPropertiesSelected = [];
			for (let i = 0; i < resultSearch.length; i++)
			{
				this.tabPropertiesSelected.push(resultSearch[i]);
			}
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.applyFilterAndEmit(this.filterSelected, propertySelected, currentSearch);
	}


	/**
	 *
	 */
	public getitemSelectedJson()
	{
		return this.itemsSelectedJson;
	}


	/**
	 *
	 */
	public setFilterSelected(filter: any)
	{
		this.filterSelected = filter;
	}


	/**
	 *
	 */
	public getFilterSelected()
	{
		return this.filterSelected;
	}


	/**
	 *
	 */
	public getFilters()
	{
		return this.filters;
	}


	/**
	 *
	 */
	public applyFilter(filter = this.filterSelected)
	{

		if (filter)
		{
			// ok ...
		}
	}


	/**
	 *
	 */
	public applyFilterAndEmit(filter = this.filterSelected, propertySelected: any = undefined, searchText: string = '')
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (filter)
		{
			let itemsFilterArray: any = [];
			let groupsToSort = [_.extend([], this.tabPropertiesSelected)];

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			for (let i = 0; i < filter.groupsFilter.length; i++)
			{
				let newGroupsToSort: any = [];


				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				for (let j = 0; j < groupsToSort.length; j++)
				{
					let resultFilter;
					let resultGroupBy;

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					resultFilter = _.sortBy(groupsToSort[j], (properties: any) =>
					{
						let result			= null;
						let propertyToSort	= _.find(properties['properties'], (property: any) =>
						{
							return property['tagName'] === filter.groupsFilter[i].name || property['property'] === filter.groupsFilter[i].name;
						});
						if (propertyToSort)
						{
							let valuesSorted	= filter.groupsFilter[i].order === OrderType.ASC ? propertyToSort['values'].sort() : propertyToSort['values'].sort().reverse();
							result				= valuesSorted[0];
						}

						return result;
					});

					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					resultFilter	= filter.groupsFilter[i].order === OrderType.ASC ? resultFilter : resultFilter.reverse();
					resultGroupBy	= _.groupBy(resultFilter, (properties: any) =>
					{
						let result			= null;
						let propertyToSort	= _.find(properties['properties'], (property: any) =>
						{
							return property['tagName'] === filter.groupsFilter[i].name || property['property'] === filter.groupsFilter[i].name;
						});

						if (propertyToSort)
						{
							result = propertyToSort['values'][0];
						}
						return result;
					});


					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					_.map(resultGroupBy, function (group: any) { newGroupsToSort.push(group); });
				}
				groupsToSort = newGroupsToSort;
			}

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			for (let i = 0; i < groupsToSort.length; i++)
			{
				itemsFilterArray = itemsFilterArray.concat(groupsToSort[i].map(function (property: any)
				{
					return property.rowJson;
				}));
			}

			// ---------------------------------------
			// ToComment
			// ---------------------------------------
			this.eventEmitter.emit(
				{
					value:		JSON.stringify(itemsFilterArray),
					property:	JSON.stringify(propertySelected),
					textSearch: searchText
				});
		}
	}
}
