 import { Injectable }	                    from '@angular/core';
import { Http, Headers, URLSearchParams }   from '@angular/http';

import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';
import { CreationHttpService }	from './creationHttp.service';
import { Piece }				from '../../classes/piece.class';


@Injectable()
export class ChatHttpService extends DataHttpService
{
    constructor(public http: Http, public userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('chat'));
	}


    /**
     * Get rooms available for given user
     */
	public getUserRooms(userId: any)
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type',	'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let params: URLSearchParams = new URLSearchParams();
		params.set('userid', userId);


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return this.http.get(this.getUrl() + 'getUserRooms', { headers: headers, search: params });
	}


	public setRoom(newRoom: Piece)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let credentials = 
			{
				data:	newRoom.toJson()
			};

		return this.http.post(this.getUrl() + 'addRoom', JSON.stringify(credentials), { headers: headers });
	}

    /**
     * Get room by ids
     */
    public getRooms(roomsIds: any[])
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type',	'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

        let params: URLSearchParams = new URLSearchParams();
        params.set('ids', roomsIds.join());

        return this.http.get(this.getUrl() + 'getRooms', { headers: headers, search: params });
    }


    /**
     * Get messages by room id
     */
    public getMessages(roomId: any)
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let headers = new Headers();
		headers.append('Content-Type',	'application/json');
        headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

        let params: URLSearchParams = new URLSearchParams();
        params.set('roomid', roomId);

        return this.http.get(this.getUrl() + 'getMessages', { headers: headers, search: params });
    }
}
