
import { Injectable } from '@angular/core';

@Injectable()

export class VaultHttpService
{


/* Local*/


/*
private static useApiUrl = true; // l'api ce trouve, sur le serveur de connexion, a part si vrai si dessous
private static useChatUrl = true; // idem on peut deporter le chat
private static chatSecure = false; // http ou https (si pas automatique)
private static apiBehindProxy = false;  // proxy = rajouter un chemin
private static chatBehindProxy = false;	 // proxy = rajouter un chemin
private static apiUrl = 'http://192.168.0.21:8002'; // http ou https, url de l'api et port si besoin'
private static apiPath = '';
private static chatUrl = '192.168.0.21';
private static chatPath = '/creation/socketcluster/';
private static chatPort = '8002';
*/
//	private static useApiUrl:		boolean = true; // l'api ce trouve, sur le serveur de connexion, a part si vrai si dessous
//	private static useChatUrl:		boolean = true; // idem on peut deporter le chat
//	private static chatSecure:		boolean = true; // http ou https (si pas automatique)
//	private static apiBehindProxy:	boolean = true;  // proxy = rajouter un chemin
//	private static chatBehindProxy: boolean = true;	 // proxy = rajouter un chemin
//	private static apiUrl:			string	= "https://vault.viatique.com"; // http ou https, url de l'api et port si besoin'
//	private static apiPath:			string	= "";
//	private static chatUrl:			string	= 'vault.viatique.com';
//	private static chatPath:			string	= "/socketcluster/";
//  private static chatPort:			string	= "443";


	/*  Prod*/
	private static useApiUrl:		boolean = true; // l'api ce trouve, sur le serveur de connexion, a part si vrai si dessous
	private static useChatUrl:		boolean = false; // idem on peut deporter le chat
	private static chatSecure:		boolean = false; // http ou https (si pas automatique)
	private static apiBehindProxy:	boolean = true;  // proxy = rajouter un chemin
	private static chatBehindProxy: boolean = true;	 // proxy = rajouter un chemin
	private static apiUrl:			string	= "https://vault.viatique.com"; // http ou https, url de l'api et port si besoin'
	private static apiPath:			string	= "";
	private static chatUrl:			string	= 'vault.viatique.com';
	private static chatPath:		string	= "/socketcluster/";
	private static chatPort:		string	= "443";

	/*  Confirugation sur machine isolée*/
	/*
	private static useApiUrl:		boolean = true; // l'api ce trouve, sur le serveur de connexion, a part si vrai si dessous
	private static useChatUrl:		boolean = false; // idem on peut deporter le chat
	private static chatSecure:		boolean = false; // http ou https (si pas automatique)
	private static apiBehindProxy:	boolean = true;  // proxy = rajouter un chemin
	private static chatBehindProxy: boolean = true;	 // proxy = rajouter un chemin
	private static apiUrl:			string	= "http://vault.exatique.lan"; // http ou https, url de l'api et port si besoin'
	private static apiPath:			string	= "";
	private static chatUrl:			string	= 'vault.viatique.com';
	private static chatPath:		string	= "/socketcluster/";
	private static chatPort:		string	= "443";
	*/

    /**
	 *  Retourne l'url de l'api (normalement sur le meme serveur que le serveur http)
	 */
	public static getAPIUrl(): string
	{
		if (VaultHttpService.useApiUrl)
		{
			if (VaultHttpService.apiBehindProxy)
			{				
				return VaultHttpService.absolute(VaultHttpService.apiUrl, VaultHttpService.apiPath);
			}			
			else
			{
				return VaultHttpService.apiUrl;
			}
		}
		else
		{
			if (VaultHttpService.apiBehindProxy)
			{
				return VaultHttpService.absolute(window.location.origin, VaultHttpService.apiPath);
			}
			else
			{
				return window.location.origin;
			}
		}
    }


    /**
	 *  Retourne l'url du chat (normalement sur le meme serveur que le serveur http)
	 */
	public static getChatUrl(): string
	{
		if (VaultHttpService.useChatUrl)
		{
			return VaultHttpService.chatUrl;
		}
		else
		{
			return window.location.hostname;
		}
	}


	public static getChatPort(): string
	{
		if (VaultHttpService.useChatUrl)
		{
			return VaultHttpService.chatPort;
		}
		else
		{
			return window.location.port;
		}
	}


	public static getChatSecure(): boolean
	{
		let bReturn;
		if (VaultHttpService.useChatUrl)
		{
			bReturn = VaultHttpService.chatSecure;
		}
		else
		{
			bReturn = window.location.protocol === 'https:';
		}
		return bReturn;
    }


    public static getChatPath(): string
	{
		if (VaultHttpService.chatBehindProxy)
		{
			return VaultHttpService.chatPath;
		}
		else
		{
			return '/socketcluster/';
		}
	}


    /**
     * Ajoute une url a l'adresse en gérant le /
     * @param base
     * @param relative
     */
	public static absolute(...args: string[]): string
	{
		let join = [].slice.call(args, 0).join('/');
		return VaultHttpService.normalize(join);
	}


	/**
	 * Retourne l'url du service dans l'api
	 */
	public static getPathByWebServiceName(name: string)
	{
		let sReturn = '';

		// Si c'est en prod on va avoir besoin du suffixe
		//sReturn = '/creation';

		// Et encore on pourrait ajouter le "name" à la suite sans passer par le switch ... mais au cas ou je laisse
		switch (name)
		{
			case 'auth':
				sReturn += '/api/v1/auth/';
				break;

			case 'refresh':
				sReturn += '/api/v1/auth/refresh';
				break;

			case 'chat':
				sReturn += '/api/v1/chat/';
				break;

			case 'epreuve':
				sReturn += '/api/v1/epreuve/';
				break;

			case 'etablissement':
				sReturn += '/api/v1/etablissement/';
				break;

			case 'examen':
				sReturn += '/api/v1/examen/';
				break;

			case 'file':
				sReturn += '/api/v1/file/';
				break;

			case 'filesend':
				sReturn += '/api/v1/file/send/';
				break;

			case 'filesendforpreview':
				sReturn += '/api/v1/file/sendForPreview/';
				break;

			case 'sendToConvert':
				sReturn += '/api/v1/file/sendToConvert/';
				break;

			case 'session':
				sReturn += '/api/v1/session/';
				break;

			case 'sujet':
				sReturn += '/api/v1/sujet/';
				break;

			case 'task':
				sReturn += '/api/v1/task/';
				break;

			case 'user':
				sReturn += '/api/v1/user/';
				break;

			default:
				break;
		}

		return sReturn;
    }

        /**
     * Fonction interne pour normaliser une url
     * @param str
     * @pzaram searchString
     */
	private static startsWith(str: string, searchString: string): boolean
	{
		return str.substr(0, searchString.length) === searchString;
	}


    /**
     * Normalise une url
     * @param str
     */
	private static normalize(str: string): string
	{

		if (VaultHttpService.startsWith(str, 'file://'))
		{
			// 3 slash pour file protocol
			str = str.replace(/(\/{0,3})\/*/g, '$1');
		}
		else
		{
			// au minimum 2 apres le protocole
			str = str.replace(/:\//g, '://');

			// enlever les slash en trop
			str = str.replace(/([^:\s\%\3\A])\/+/g, '$1/');
		}

		// enlever les slash de fin avant param
		str = str.replace(/\/(\?|&|#[^!])/g, '$1');

		// remplace ? par &
		str = str.replace(/(\?.+)\?/g, '$1&');

		return str;
	}

}
