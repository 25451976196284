import { Component, ViewEncapsulation, Inject }				from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators }	from '@angular/forms';
import { MatDialogRef }										from '@angular/material';
import { MAT_DIALOG_DATA }									from '@angular/material';


@Component({
    selector:		'send-mail-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'sendMailModal.component.html',
    styleUrls:		['./sendMailModal.component.scss']
})

export class SendMailModalComponent
{
    public moFormMail:			FormGroup;
    public moFormCtrlSendEmail: FormControl;

    public sendEmail: boolean = false;

    constructor(public dialogRef:	MatDialogRef<SendMailModalComponent, any>,
                public fb:			FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
		
        this.moFormCtrlSendEmail	= this.fb.control(this.sendEmail);
        this.moFormMail				= this.fb.group(
        {
            sendEmail: this.moFormCtrlSendEmail
        });
    }
	

    public cancel()
    {
        this.dialogRef.close(null);
    }
	

    public vadidateMail()
    {
        const result: any = 
		{
            sendMail: this.sendEmail
        };
		
        this.dialogRef.close(result);
    }
}
