import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }							from '@angular/material';


@Component({
	selector:		'info-init-account-modal',
	templateUrl:	'infosInitAccountModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./infosInitAccountModal.component.scss']
})

export class InfoInitAccountModalComponent
{
	public user: any;


	/**
	 * 
	 * @param dialogRef 
	 */
	constructor(public dialogRef: MatDialogRef<InfoInitAccountModalComponent, any>)
	{
	}


	/**
	 * 
	 */
	public validate()
	{
		this.dialogRef.close();
	}
}
