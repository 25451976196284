import { Injectable }		from '@angular/core';
import { SocketService } from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } from '../providers/user-data';

import {Observable} from 'rxjs/Rx';


@Injectable()
export class AdminService
{

    constructor(private socketService: SocketService,
                private userData: UserData)
    {

    }

    /**
     * Récupération de l'ensemble des paramètres d'administration
     */
    public getAllAdminParameters()
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'adminParameters',
					'args': {}
				};
				socket.emit('read', data);
			}
		});
    }

    /**
     * Mise à jour des paramètres principaux dans l'interface d'administration
     * @param datasParameters
     */
    public updateAdminParameters(datasParameters: any)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'adminParameters',
					'args': {
						'datas': datasParameters
					}
				};
				socket.emit('update', data);
			}
		});
	}


	/**
	 * Mise à jour des midèles de mails dans l'administration
	 */
	public updateAdminMailsTemplates(mailsTemplates: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'adminMailsTemplates',
					'args': {
						'datas': mailsTemplates
					}
				};
				socket.emit('update', data);
			}
		});
	}


	public deleteScenario(idScenario: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'deleteScenario',
					'args': {
						'idScenario': idScenario
					}
				};
				socket.emit('delete', data);
			}
		});
	}
}
