import { Task }	from './task.class';
import { Matiere }	from './matiere.class';
import { File }		from './file.class';
import { Comment }  from './comment.class';
import * as _		from 'underscore';


/**
 *
 * @export
 * @class Sujet
 */
export class SpecialTime
{

    /**
     *
     *
     * @static
     * @param {any} jsonObj
     * @returns {Sujet}
     *
     * @memberOf Sujet
     */
    static fromJsonObject(jsonObj: any): SpecialTime
    {

        let oSpecialTime = new SpecialTime();

        oSpecialTime.id					= jsonObj.id;
		oSpecialTime.name				= jsonObj.name				|| '';
        oSpecialTime.duration			= jsonObj.duration			|| null;
        oSpecialTime.datas				= jsonObj.datas			    || {};
        oSpecialTime.type				= jsonObj.type				|| '';


        return oSpecialTime;
    }


    /**
     * Creates an instance of Sujet.
     *
     * @param {string} [_name='']
     * @param {string} [_time_start='']
     * @param {string} [_time_end='']
     * @param {string} [_location='']
     * @param {string[]} [_tracks=[]]
     * @param {Exercice[]} [_exercices=[]]
     *
     * @memberOf Sujet
     */
    constructor(
        private _id:				number      = null,
        private _name:				string      = '',
        private _type:				string      = '',
        private _duration:				any      = null,
        private _datas:             any         = {}
    ){ }


    get id()                    { return this._id; }
    get name()                  { return this._name; }
    get type()                  { return this._type; }
    get datas()                 { return this._datas; }
    get duration()              { return this._duration; }


    set id(id)							{ this._id				= id; }
    set name(name)						{ this._name			= name; }
    set type(type)						{ this._type			= type; }
    set duration(duration)				{ this._duration		= duration; }
    set datas(datas)				    { this._datas		    = datas; }



	/**
	 *
	 */
    public toJson()
    {
        const result: any =
        {
            id:			this.id,
            name:		this.name,
            type:		this.type,
            duration:	this.duration,
            datas:      this.datas,
        };

        return result;
    }

}
