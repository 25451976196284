import { Component, ViewEncapsulation, Inject }				from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators }	from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA }					from '@angular/material';

import { SocketService } 		from '../../../services/socket.service';
import { SessionService }   	from '../../../services/session.service';
import { ExamenService }    	from '../../../services/examen.service';
import { EpreuveService }   	from '../../../services/epreuve.service';
import { SujetService }     	from '../../../services/sujet.service';
import { FileService }      	from '../../../services/file.service';
import { UserData }				from '../../../providers/user-data';
import { WorkspaceData }		from '../../../providers/workspace-data';
import { File as ExatiqueFile }	from '../../../classes/file.class';
import { Comment }              from '../../../classes/comment.class';


@Component({
    selector:		'comment-modal',
	encapsulation:	ViewEncapsulation.None,
    templateUrl:	'commentModal.component.html',
    styleUrls:		['./commentModal.component.scss']
})


/**
 * Modal permettant l'ajout d'un nouveau commentaire pour un élement donné
 */
export class CommentModalComponent
{
    // Identifiant de l'élément concerné par le nouveau commentaire
    public _idElement:			string;
    // objet Comment correspondant au commentaire en édition
    public _currentComment:		Comment;
    public moFormComment:		FormGroup;
    public moFormCtrlMessage:	FormControl;


    constructor(public dialogRef:		MatDialogRef<CommentModalComponent, any>,
                public socketService:	SocketService,
                public sessionService:	SessionService,
                public examenService:	ExamenService,
                public epreuveService:	EpreuveService,
                public sujetService:	SujetService,
                public fileService:		FileService,
				public fb:				FormBuilder,
				public userData:		UserData,
				public workspaceData:	WorkspaceData,
				@Inject(MAT_DIALOG_DATA) public data: any)
	{

		if (this.data && this.data.idElement && this.data.comment)
		{
			this._idElement			= this.data.idElement;
			this._currentComment	= this.data.comment;
			this.moFormCtrlMessage	= this.fb.control(this._currentComment.message, Validators.required);


			this.moFormComment = this.fb.group(
				{
					message: this.moFormCtrlMessage,
				});
		}
	}


	/**
	 *
	 */
	public cancelComment()
    {
        this.dialogRef.close();
    }


	/**
	 *
	 */
    public vadidateComment()
    {
        this.dialogRef.close();
    }
}
