import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MatDialogRef }									from '@angular/material';
import { MAT_DIALOG_DATA }								from '@angular/material';
import { BaremeData }									from '../../providers/bareme-data';


@Component({
	selector: 'app-bareme-creator',
	templateUrl: './bareme-creator.component.html',
	styleUrls: ['./bareme-creator.component.scss']
})


export class BaremeCreatorComponent implements OnInit
{
	public mnSelectedTypeId			= 0;
	public msSelectedTypeName		= 'Strict';
	public mjSelectedType:	any		= null;
	public mbIsEditing:		boolean	= false;


	public masTypeList: any[] =
	[{
		id:		0,
		name:	'Strict',
		desc:	'Barème Strict',
		data: [
			{
				id:					0,
				name:				"Nombre de point par question",
				typeInput:			'number',
				value:				1,
				displayValue:				1,
				defaultValue:		0,
				optionnal:			false,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					1,
				name:				"Nombre de point de pénalité",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					2,
				name:				"Nombre de point si tout coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					3,
				name:				"Nombre de point si rien coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			}
		]
	},
	{
		id:		1,
		name:	'Relatif',
		desc:	'Barème Relatif',
		data: [
			{
				id:					0,
				name:				"Sommes des points des réponses justes",
				typeInput:			'number',
				value:				1,
				displayValue:				1,
				defaultValue:		1,
				optionnal:			false,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					1,
				name:				"Somme des points des réponses fausses",
				typeInput:			'number',
				value:				1,
				displayValue:				1,
				defaultValue:		1,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					2,
				name:				"Perte maximum par question",
				typeInput:			'number',
				value:				1,
				displayValue:		1,
				defaultValue:		1,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					3,
				name:				"Nombre de point si tout coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					4,
				name:				"Nombre de point si rien coché",
				typeInput:			'number',
				value:				0,
				displayValue:		0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			}
		]
	},
	{
		id:		2,
		name:	'Concordance',
		desc:	'Barème Concordance',
		data: [
			{
				id:					0,
				name:				"Si toutes les réponses sont conformes",
				typeInput:			'number',
				value:				1,
				displayValue:		1,
				defaultValue:		1,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	true
			},
			{
				id:					1,
				name:				"Si 1 réponse est non conforme",
				typeInput:			'number',
				value:				0,
				displayValue:		0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					2,
				name:				"Si 2 réponses sont non conformes",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					3,
				name:				"Si 3 réponses sont non conformes",
				typeInput:			'number',
				value:				0,
				displayValue:		0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					4,
				name:				"Si n ou plus réponses sont non conformes",
				typeInput:			'number',
				value:				0,
				displayValue:		0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					5,
				name:				"Nombre de points si tout coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false, 
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					6,
				name:				"Nombre de points si rien coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			}
		]
	},
	{
		id: 3,
		name: 'Par réponse',
		desc: 'Barème Par réponse',
		data: [
			{
				id:					0,
				name:				"Proposition Vrai: Point si coché",
				typeInput:			'number',
				value:				1,
				displayValue:				1,
				defaultValue:		1,
				optionnal:			false,
				maxValue:			false,
				minValue:			false, 
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					1,
				name:				"Proposition Vrai: Point si non coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					2,
				name:				"Proposition fausse: Point si non coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					3,
				name:				"Proposition fausse: Point si coché",
				typeInput:			'number',
				value:				-1,
				displayValue:				-1,
				defaultValue:		-1,
				optionnal:			false,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					4,
				name:				"Perte maximum par question",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			0,
				isAbsolue:			true,
				isOptionChecked:	false
			},
			{
				id:					5,
				name:				"Nombre de points si tout coché",
				typeInput:			'number',
				value:				0,
				displayValue:		0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			},
			{
				id:					6,
				name:				"Nombre de points si rien coché",
				typeInput:			'number',
				value:				0,
				displayValue:				0,
				defaultValue:		0,
				optionnal:			true,
				maxValue:			false,
				minValue:			false,
				isAbsolue:			false,
				isOptionChecked:	false
			}
		]
	}];


	constructor(
		public dialogRef:	MatDialogRef<BaremeCreatorComponent, any>,
		public baremeData:	BaremeData,
		@Inject(MAT_DIALOG_DATA) public data: any
	)
	{
		// récupération des datas du composant appelant
		if (data && data.bareme)
		{
			this.mbIsEditing		= true;
			this.mjSelectedType		= data.bareme.datas;
			this.mnSelectedTypeId	= +data.bareme.datas.id;
			this.msSelectedTypeName	= data.bareme.datas.name;
		}
		else
		{
			this.mjSelectedType = this.masTypeList[this.mnSelectedTypeId];
		}
	}


	ngOnInit()
	{

	}


	/**
	 * 
	 */
	public close()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 */
	public cancel()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 * @param event 
	 */
	public changeSelected(event: any)
	{
		this.mjSelectedType = this.masTypeList[event.value];

		for (let data of this.masTypeList)
		{
			if (data.id === event)
			{
				this.mjSelectedType = data;
			}
		}
	}


	/**
	 * 
	 */
	public validate()
	{
		this.dialogRef.close(this.mjSelectedType);
	}


	public changeValue(value: any)
	{
		if (value.isAbsolue)
		{
			value.value = Math.abs(value.displayValue);
		}
		else
		{
			value.value = value.displayValue;
		}
	}
	
}
