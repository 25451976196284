import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
// import { Storage } from '@ionic/storage';
import { User } from '../classes/user.class';

@Injectable()


/**
 * Class UserData
 */
export class UserData implements OnDestroy
{
	public _favorites: any 	= [];
	public HAS_LOGGED_IN 	= 'hasLoggedIn';
	public TOKEN_KEY 		= 'tokenKey';

	public userDatas: any = null;
	public bddAccountConnected: string = null;

	public states: any =
		{
			application:	'offline',
			chat:			'offline',
			userchat:		'offline'
		};


	public static STATUS_ADMIN			= 'admin';
	public static STATUS_RESPONSABLE	= 'responsable';
	public static STATUS_SUPERVISEUR	= 'superviseur';
	public static STATUS_USER			= 'user';


	public static CONST_CHAT_ACTION_NEWMESSAGE		= 'chat::newMessage';
	public static CONST_CHAT_ACTION_NEWROOM			= 'chat::newRoom';
	public static CONST_CHAT_ACTION_NEWUSER			= 'chat::newUser';
	public static CONST_CHAT_ACTION_USERCONNECTED	= 'chat::userConnected';
	public static CONST_CHAT_ACTION_GETUSERLIST		= 'chat::getUserList';


	protected _setAccountInfo:			any;

	protected _canAccessChat:			boolean = false;
	protected _canAccessForum:			boolean = false;
	protected _canAccessNotification:	boolean = false;

	protected _canRead:		boolean = false;
	protected _canCreate:	boolean = false;
	protected _canUpdate:	boolean = false;
	protected _canDelete:	boolean = false;

	private _id:			string	= '';
	private _fingerPrint:	string	= '';
	private _dateCreation:	string	= '';

	// Partie du chat
	private _idNewRoom:			string	= '';
	private _rooms:				any[]	= [];
	private _users:				User[]	= [];
	private _userByRoomId:		any[]	= [];
	private _messagesByRoomId:	any[]	= [];
	private _firstLetterIcone			= '';
	private _color			= '';

	// Status de l'user conencté, par défaut il possède le status de simple utilisateur
	private _status:			string	= UserData.STATUS_USER;
	private _isServer:			boolean = false;
	private _mail:				string	= '';
	private _username:			string	= '';
	private _accounts:			any[]	= [];
	private _account:			string	= '';
	private _isAccountSelected:	boolean = false;
	private _right:				string	= '';
	private _role:				string	= '';
	private _firstName:			string	= '';
	private _lastName:			string	= '';
	private _userId:			string	= '';
	private _deleted:			boolean = false;
	private _userForChatInvite:	any		= null;

	private _connected:			boolean = false;
	private _otpAuth:			boolean	= false;
	private _methodAuth:		any		= 1;
	private _mustReset:			boolean = false;
	private _mustValidateBrowser:boolean = false;


	public mbIsPrinter: boolean = false;

	private _dialogRefModalConnection: any = null;

	/**
	 * Stockage des évènements du calendrier
	 */
	private _calendarDatas:	any = {};

	/**
	 * Stockage des taches récupérées dans l'appli
	 */
	private _taskDatas:		any = {};

	public globalEmitter: 	EventEmitter<any> = new EventEmitter<any>();
	public notifEmitter: 	EventEmitter<any> = new EventEmitter<any>();

	/**
	 *
	 */
	constructor()
	{

	}


	ngOnDestroy()
	{
		this.globalEmitter.unsubscribe();
		this.notifEmitter.unsubscribe();
	}


	public getStorage(key: any)
	{
		let value = localStorage.getItem(key);

		if (!value) { return; }

		// assume it is an object that has been stringified
		if (value[0] === "{" || value[0] === "[")
		{
			value = JSON.parse(value);
		}

		return value;
	}



	public removeStorage(key: any)
	{
		localStorage.removeItem(key);
	}

	public store(key: any, data: any)
	{
//		if (!key || !data) { return; }

		if(typeof(data) === "object")
		{
			data = JSON.stringify(data);
		}
		localStorage.setItem(key, data);
	}




	get id():						string	{ return this._id; }
	get fingerPrint():				string	{ return this._fingerPrint; }
	get dateCreation():				string	{ return this._dateCreation; }
	get isServer():					boolean { return this._isServer; }
	get mail():						string	{ return this._mail; }
	get username():					string	{ return this._username; }
	get accounts():					any[]	{ return this._accounts; }
	get account():					string	{ return this._account; }
	get isAccountSelected():		boolean	{ return this._isAccountSelected; }
	get right():					string	{ return this._right; }
	get role():						string	{ return this._role; }
	get firstName():				string	{ return this._firstName; }
	get firstLetterIcone():			string	{ return this._firstLetterIcone; }
	get color():			string	{ return this._color; }
	get lastName():					string	{ return this._lastName; }
	get deleted():					boolean { return this._deleted; }
	get status():					string	{ return this._status; }
	get idNewRoom():				string	{ return this._idNewRoom; }
	get canAccessChat():			boolean { return this._canAccessChat; }
	get canAccessForum():			boolean { return this._canAccessForum; }
	get canAccessNotification():	boolean { return this._canAccessNotification; }
	get canRead():					boolean { return this._canRead; }
	get canCreate():				boolean { return this._canCreate; }
	get canUpdate():				boolean { return this._canUpdate; }
	get canDelete():				boolean { return this._canDelete; }
	get userForChatInvite():		any		{ return this._userForChatInvite; }
	get rooms():					any[]	{ return this._rooms; }
	get users(): any[]
	{
		let objUsers = [];

		for (let key in this._users)
		{
			objUsers.push(this._users[key]);

		}
		return objUsers;
	}
	get userByRoomId():				any[]	{ return this._userByRoomId; }
	get messagesByRoomId():			any[]	{ return this._messagesByRoomId; }
	get connected():				boolean { return this._connected; }
	get otpAuth():					boolean { return this._otpAuth; }
	get methodAuth():				any		{ return this._methodAuth; }
	get mustReset():				boolean	{ return this._mustReset; }
	get mustValidateBrowser():   	boolean	{ return this._mustValidateBrowser; }
	get dialogRefModalConnection():	any     { return this._dialogRefModalConnection; }


	set id(id)											{ this._id						= id; }
	set fingerPrint(fingerPrint)						{ this._fingerPrint				= fingerPrint; }
	set dateCreation(dateCreation)						{ this._dateCreation			= dateCreation; }
	set isServer(isServer)								{ this._isServer				= isServer; }
	set mail(mail)										{ this._mail					= mail; }
	set username(username)								{ this._username				= username; }
	set accounts(accounts)								{ this._accounts				= accounts; }
	set account(account)								{ this._account					= account; }
	set isAccountSelected(isAccountSelected)			{ this._isAccountSelected		= isAccountSelected; }
	set right(right)									{ this._right					= right; }
	set role(role)										{ this._role					= role; }
	set firstName(firstName)							{ this._firstName				= firstName; }
	set color(color)									{ this._color				= color; }
	set firstLetterIcone(firstLetterIcone)				{ this._firstLetterIcone				= firstLetterIcone; }
	set lastName(lastName)								{ this._lastName				= lastName; }
	set deleted(deleted)								{ this._deleted					= deleted; }
	set status(status)									{ this._status					= status; }
	set idNewRoom(idNewRoom)							{ this._idNewRoom				= idNewRoom; }
	set canAccessChat(canAccessChat)					{ this._canAccessChat			= canAccessChat; }
	set canAccessForum(canAccessForum)					{ this._canAccessForum			= canAccessForum; }
	set canAccessNotification(canAccessNotification)	{ this._canAccessNotification	= canAccessNotification; }
	set canRead(canRead)								{ this._canRead					= canRead; }
	set canCreate(canCreate)							{ this._canCreate				= canCreate; }
	set canUpdate(canUpdate)							{ this._canUpdate				= canUpdate; }
	set canDelete(canDelete)							{ this._canDelete				= canDelete; }
	set userForChatInvite(userForChatInvite)			{ this._userForChatInvite		= userForChatInvite; }
	set rooms(rooms)									{ this._rooms					= rooms; }
	set users(users)									{ this._users					= users; }
	set userByRoomId(userByRoomId)						{ this._userByRoomId			= userByRoomId; }
	set messagesByRoomId(userByRoomId)					{ this._messagesByRoomId		= userByRoomId; }
	set connected(connected)							{ this._connected				= connected; }
	set otpAuth(otpAuth)								{ this._otpAuth					= otpAuth; }
	set methodAuth(methodAuth)							{ this._methodAuth				= methodAuth; }
	set mustReset(mustReset)							{ this._mustReset				= mustReset; }
	set mustValidateBrowser(mustValidateBrowser)		{ this._mustValidateBrowser		= mustValidateBrowser; }
	set dialogRefModalConnection(dialogRef)				{ this._dialogRefModalConnection= dialogRef; }

	/**
	 * Retourne true si l'user courant est un administrateur
	 */
	public isAdmin()
	{
		return this.status === UserData.STATUS_ADMIN;
	}

	/**
	 * Retourne true si l'user courant est un responsable
	 */
	public isResponsable()
	{
		return this.status === UserData.STATUS_RESPONSABLE;
	}

	/**
	 * Retourne true si l'user courant est un superviseur
	 */
	public isSuperviseur()
	{
		return this.status === UserData.STATUS_SUPERVISEUR;
	}

	/**
	 * Retourne true si l'user courant est un simple utilisateur
	 */
	public isSimpleUser()
	{
		return this.status === UserData.STATUS_USER;
	}

	public parseBoolean(psString: any)
	{
		let bReturn = null;

		if(psString === "true")
		{
			bReturn = true;
		}
		else if (psString === "false")
		{
			bReturn = false;
		}

		return bReturn;

	}


	/**
	 * Fonction qui permet de définir les droits globaux
	 */
	public setCurrentUser(poUserData: any)
	{
		// ---------------------------------------
		// Récupération des infos de base
		// ---------------------------------------
		if (poUserData)
		{
			if (poUserData.infos)
			{
				this.firstName	= poUserData.infos.prenom;
				this.lastName	= poUserData.infos.nom;
				this.mail		= poUserData.infos.mail;
			}

			if (poUserData.privates)
			{
				this.account	= poUserData.privates.account;
			}


			this.mbIsPrinter = this.parseBoolean(poUserData.publics.bIsPrinter);

			// ---------------------------------------
			// Gestion des droits sur le chat
			// ---------------------------------------
			if (poUserData.droitsLogiciel)
			{
				let aRight = poUserData.droitsLogiciel.creation;
				if (aRight)
				{
					if (aRight.indexOf('chat') !== -1)
					{
						this.canAccessChat = true;
					}

					if (aRight.indexOf('forum') !== -1)
					{
						this.canAccessForum = true;
					}

					if (aRight.indexOf('notification') !== -1)
					{
						this.canAccessNotification = true;
					}

					// ---------------------------------------
					// Droits CRUD
					// ---------------------------------------
					if (aRight.indexOf('create') !== -1)
					{
						this.canCreate = true;
					}

					if (aRight.indexOf('read') !== -1)
					{
						this.canRead = true;
					}

					if (aRight.indexOf('update') !== -1)
					{
						this.canUpdate = true;
					}

					if (aRight.indexOf('delete') !== -1)
					{
						this.canDelete = true;
					}
				}
			}
		}
	}


	/**
	 *
	 */
	public hasFavorite(sessionName: any)
	{
		let oReturn = this._favorites.indexOf(sessionName) > -1;
		return oReturn;
	}


	/**
	 *
	 */
	public addFavorite(sessionName: any)
	{
		this._favorites.push(sessionName);
	}


	/**
	 *
	 */
	public removeFavorite(sessionName: any)
	{
		let index = this._favorites.indexOf(sessionName);
		if (index > -1)
		{
			this._favorites.splice(index, 1);
		}
	}


	/**
	 *
	 */
	public login(username: string, token: string, idUnique: string)
	{
		if (!username || !idUnique ||
			username.length === 0 || idUnique.length === 0)
		{
			return false;
		}

		this.store(this.HAS_LOGGED_IN, 'true');
		this.setUsername(username);
		this.setUserId(idUnique);
		if (token)
		{
			this.setTokenKey(token);
		}

		// this.events.publish('user:login');
		// TODO => voir si il à pas un status ne pas déranger en cours lors de la dernière connexion
		this.states.application = 'online';
		this.states.chat		= 'online';
	}


	/**
	 *
	 */
	public signup(username: any)
	{
		//		this.storage.set(this.HAS_LOGGED_IN, true);
		this.setUsername(username);
		//		this.events.publish('user:signup');
	}


	/**
	 *
	 */
	public logout()
	{
		this.removeStorage("username");
		this.removeStorage("userid");
		this.removeStorage("calendarDatas");

		this.store(this.HAS_LOGGED_IN, 'false');
		this.removeStorage(this.getUserId() + '::' + this.account + '::socketCluster.authToken');
		this.states.application = 'offline';

		for (let keyUser in this._users)
		{
			this.removeStorage('usersDatas::' + this.account + '::' + keyUser);
		}
		this._users = [];
		this._otpAuth = false;
		this.connected = false;

		this.setTasksHomeForMe({});
		this.setTasksHomeImAdmin({});
		this.setCalendarDatas({});
	}


	/**
	 *
	 */
	public setUsername(username: any)
	{
		this.store('username', username);
		// this.router.navigate(['/home']);
		// this.storage.set('username', username);
	}
	/**
	 *
	 */
	public setUserId(userid: any)
	{
		// this.router.navigate(['/home']);
		// this.storage.set('username', username);
		this._userId = userid;
	}


	public setStateApplication(key: string, value: any)
	{
		this.states[key] = value;
		// this.store(this.HAS_LOGGED_IN, 'true');
	}

	public setFingerPrint(value: any)
	{
		this.store(this.getUserId() + '::' + this.account + '::fingerprint', value);
	}


	public setAccountInfo(value: any)
	{
		this.store('accountInfo', value);
	}


	// ------------------------------------------------------------------------------------------------------------------------------------------------------------
	//																	Partie Chat
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------
	public addUser(value: any, key: any)
	{
		this._users[key] = value;
		this.store('usersDatas::' + this.account + '::' + key, value);
	}

	public removeUser(key: any)
	{
		delete this._users[key];
		this.removeStorage('usersDatas::' + this.account + '::' + key);
	}

	public setUserState(value: any, key: any)
	{
		if(value === 'online')
		{
			this._users[key].status = true;

		}
		else
		{

			this._users[key].status = false;
		}
	}


	public getUser(key: any)
	{
		let oReturn: any;

		if (!this._users[key])
		{
			oReturn = this.getStorage('usersDatas::' + this.account + '::' + key);
		}
		else
		{
			oReturn = this._users[key];
		}

		return oReturn;
	}

	public getUsers()
	{
		return this._users;
	}

	public getUsers2()
	{
		let test: any = this._users;
		let returnArray = [];

		for (let user in this._users)
		{
			returnArray.push(this.getUser(user));
		}
		return returnArray;
	}

	public getUsersList()
	{
		let oReturn: any;
		oReturn = this.getStorage(this.account + '::usersList');
		return oReturn;
	}


	// ------------------------------------------------------------------------------------------------------------------------------------------------------------
	//																	Partie autre
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------

	public setTasksHomeForMe(value: any)
	{
		this.store(this.account + '::tasksHomeForMe', value);
	}

	public addTaskHomeForMe(value: any)
	{
		const tasks: any = this.getStorage(this.account + '::tasksHomeForMe');

		const index = _.findIndex(tasks, (val: any) => {
			return val.uuid === value.uuid;
		});

		if (index !== -1)
		{
			tasks[index] = value;
		}
		else
		{
			tasks.push(value);
		}
		this.store(this.account + '::tasksHomeForMe', tasks);
	}

	public setUsersList(value: any)
	{
		this.store(this.account + '::usersList', value);
	}


	public setTasksHomeImAdmin(value: any)
	{
		this.store(this.account + '::tasksHomeImAdmin', value);
	}

	public addTasksHomeImAdmin(value: any)
	{
		const tasks: any = this.getStorage(this.account + '::tasksHomeImAdmin');

		const index = _.findIndex(tasks, (val: any) => {
			return val.uuid === value.uuid;
		});

		if (index !== -1)
		{
			tasks[index] = value;
		}
		else
		{
			tasks.push(value);
		}
		this.store(this.account + '::tasksHomeImAdmin', tasks);

	}


	public setUserDatas(datas: any)
	{
		this.userDatas = datas;
//		('setCurrentUSer', datas);

		// parser des éléments pour les membres de classe
		this.setCurrentUser(datas);

		this.store('userDatas', datas);
	}

	public setRessources(datas: any)
	{
		this.store('ressources', datas);
	}

	public setUsersDatas(datas: any, key: any)
	{
		this.userDatas = datas;
		//		s('setCurrentUSer', datas);

		// parser des éléments pour les membres de classe

		this.store('usersDatas::' + this.account + '::' + key, datas);
	}

	public getUsersDatas(key: any)
	{
		let oReturn: any;
		oReturn = this.getStorage('usersDatas::' + this.account + '::' + key);
		return oReturn;
	}




	/**
	 * Récupère un évènement en fonction de son identifiant dans le localstorage
	 * @param idElement
	 */
	public getCalendarElement(idElement: string)
	{
		let result;

		if (!this._calendarDatas)
		{
			this._calendarDatas = this.getStorage(this.getUserId() + '::' + this.account + '::calendarDatas');
		}

		result = this._calendarDatas[idElement];

		return result;
	}


	/**
	 * Ajout d'un evènement dans le localstorage en fonction de son identifiant
	 * @param idData
	 * @param data
	 */
	public addCalendarElement(idData: string, data: any)
	{

		if (!this._calendarDatas)
		{
			this._calendarDatas = this.getStorage(this.getUserId() + '::' + this.account + '::calendarDatas');
		}

		let type = data.type;

		if (!this._calendarDatas[idData])
		{
			this._calendarDatas[idData] = {};
		}
		this._calendarDatas[idData][type] = data;

		this.store(this.getUserId() + '::' + this.account + '::calendarDatas', this._calendarDatas);
	}


	/**
	 * 
	 * @param dateId 
	 */
	public removeCalendarElement(dateId: any)
	{
		if (!this._calendarDatas)
		{
			this._calendarDatas = this.getStorage(this.getUserId() + '::' + this.account + '::calendarDatas');
		}

		if (this._calendarDatas[dateId])
		{
			delete this._calendarDatas[dateId];
			this.store(this.getUserId() + '::' + this.account + '::calendarDatas', this._calendarDatas);
		}
	}

	/**
	 * Affectation d'un ensemble de dates dans le localstorage
	 * @param datas
	 */
	public setCalendarDatas(datas: any)
	{
		this._calendarDatas = datas;
		this.store(this.getUserId() + '::' + this.account + '::calendarDatas', datas);
	}


	/**
	 * Récupération des évènements dans le localstorage
	 */
	public getCalendarDatas()
	{
		let oReturn: any = {};

		if (this._calendarDatas)
		{
			oReturn = this._calendarDatas;
		}
		else
		{
			this._calendarDatas = this.getStorage(this.getUserId() + '::' + this.account + '::calendarDatas');
			oReturn 			= this._calendarDatas;
		}

		return oReturn;
	}


	/**
	 * Récupération d'une tache dans le localstorage en fonction de son identifiant
	 * @param idData
	 * @param data
	 */
	public addTaskData(idData: string, data: any)
	{
		if (!this._taskDatas)
		{
			this._taskDatas = this.getStorage('taskDatas');
		}

		if (!this._taskDatas)
		{
			this._taskDatas = {};
		}

		this._taskDatas[idData] = data;
		this.store('taskDatas', this._taskDatas);
	}


	/**
	 * Ajout de la structure de nodes dans le locastorage
	 * @param datas
	 */
	public setTaskDatas(datas: any)
	{
		this._taskDatas = datas;
		this.store('taskDatas', datas);
	}

	public getAccountInfo()
	{
		let oReturn: any;
		oReturn = this.getStorage('accountInfo');
		return oReturn;
	}


	/**
	 *
	 */
	public getUsername()
	{
		let oReturn: any;
		oReturn = this.getStorage('username');
		return oReturn;
	}


	public getUserId()
	{
		return this._userId;
	}


	public getUserObject()
	{
		let oReturn: any;
		oReturn = this.getStorage('userid');
		return oReturn;
	}


	public getFingerPrint()
	{
		let oReturn: any;
		oReturn = this.getStorage(this.getUserId() + '::' + this.account + '::fingerprint');
		return oReturn;
	}



	public getTasksHomeForMe()
	{
		let oReturn: any;
		oReturn = this.getStorage(this.account + '::tasksHomeForMe');
		return oReturn;
	}

	public getTasksHomeForMeById(idTask: string)
	{
		let oReturn: any;
		const tasks = this.getStorage(this.account + '::tasksHomeForMe');

		const index = _.findIndex(tasks, (val: any) => 
		{
			return val.uuid === idTask;
		});

		if (index !== -1)
		{
			oReturn = tasks[index];
		}

		return oReturn;
	}


	public getTasksHomeImAdmin()
	{
		let oReturn: any;
		oReturn = this.getStorage(this.account + '::tasksHomeImAdmin');
		return oReturn;
	}


	public isUserAdmin(userId: any)
	{
		const user 	= this.getUser(userId);
		let isAdmin = false;

		if (user.publics && (user.publics.isAdmin === true || user.publics.isAdmin === 'true'))
		{
			isAdmin = true;
		}

		return isAdmin;
	}


	public isUserSuperviseur(userId: any)
	{
		const user 		= this.getUser(userId);
		let isSuperUser = false;

		if (user.publics && (user.publics.isSuperUser === true || user.publics.isSuperUser === 'true'))
		{
			isSuperUser = true;
		}

		return isSuperUser;
	}


	public getReadedMessages()
	{
		let oReturn: any;
		oReturn = this.getStorage('readedMessages');
		return oReturn;
	}


	public getRessources()
	{
		let oReturn: any;
		oReturn = this.getStorage('ressources');
		return oReturn;
	}


	/**
	 * Récupération de l'ensemble des tâches disponibles dans le localstorage
	 */
	public getTaskDatas()
	{
		let oReturn: any = {};

		if (this._taskDatas)
		{
			oReturn = this._taskDatas;
		}
		else
		{
			this._taskDatas = this.getStorage('taskDatas');
			oReturn 		= this._taskDatas;
		}

		return oReturn;
	}


	public getUserDatas()
	{
		let oReturn: any;
		if (this.userDatas !== null)
		{
			oReturn = this.userDatas;
		}
		else
		{
			oReturn = this.getStorage('userDatas');
		}

		return oReturn;
	}


	public getStateApplication()
	{
		return this.states;
	}

	/**
	 * return a promise
	 */
	public hasLoggedIn()
	{
		let oReturn: any;
		oReturn = this.getStorage(this.HAS_LOGGED_IN) === 'true';
		return oReturn;
	}


	/**
	 *
	 */
	public setTokenKey(token: string)
	{
		this.store(this.getUserId() + '::' + this.account + '::socketCluster.authToken', token);
	}

	public setRSAPrivate(rsaKey: any)
	{
		this.store(this.getUserId() + '::' + this.account + '::rsa-private', rsaKey);
	}


	public getRSAPrivate()
	{
		let rsa = this.getStorage(this.getUserId() + '::' + this.account + '::rsa-private');

		return rsa;
	}

	public hasRSAprivate()
	{
		let result = false;

		if (this.getStorage(this.getUserId() + '::' + this.account + '::rsa-private') !== undefined)
		{
			result = true;
		}

		return result;
	}

	public deleteRSAPrivate()
	{
		this.removeStorage(this.getUserId() + '::' + this.account + '::rsa-private');
	}

	/**
	 *
	 */
	public delTokenKey()
	{
		this.removeStorage(this.getUserId() + '::' + this.account + '::socketCluster.authToken');
	}


	/**
	 *
	 */
	public getTokenKey()
	{
		let token = this.getStorage(this.getUserId() + '::' + this.account + '::socketCluster.authToken');

		if (token)
		{
			token = token.replace(/"/g, '');
		}
		return token;
	}


	public storeRooms(paRooms: any)
	{
		this.store('avaibleRooms', paRooms);
	}


	getTextColor(color: any)
	{
		if (color === '#fff')
		{
			color = "#ffffff";
		}
		let value;

		let colorReturn = "#000000";

		if (color)
		{
			color = color.replace('#', '');

			var arrBuff = new ArrayBuffer(4);
			var vw		= new DataView(arrBuff);
			vw.setUint32(0, parseInt(color, 16), false);
			var arrByte = new Uint8Array(arrBuff);

			//		return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];

			value = (arrByte[1] * 299 + arrByte[2] * 587 + arrByte[3] * 114) / 1000;

			if (value < 128)
			{
				colorReturn = "#ffffff";
			}
		}

		return colorReturn;
	}
}

