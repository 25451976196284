<mat-dialog id="user_add_form_modal">
	<div class="dialog_header">
		<div class="dialog_header_title">
			Ajout d'utilisateur
		</div>
	</div>
	<div class="dialog_content">
		<div class="dialog_content_block_search">
			<div class="dialog_content_block_search_input"><input  maxLength="80"  type="text" placeholder="Rechercher"/></div>
			<div class="dialog_content_block_search_result">
				<div class="dialog_content_block_search_inner">Résultat</div>
			</div>
		</div>
		<div class="dialog_content_block_add">
			<div class="dialog_content_block_add_node">
				<div class="dialog_content_block_add_node_title">
					<input type="radio" id="dialog_content_block_add_node_radio"/> <label for="dialog_content_block_add_node_radio" >De niveau: établissement 1, ....</label>
				</div>
			</div>
			<div class="dialog_content_block_add_group">
				<label>Dans le groupe</label>
				<select id="dialog_content_block_add_group_select">
					<option value="groupe1">groupe1</option>
					<option value="groupe2">groupe2</option>
					<option value="groupe3">groupe3</option>
					<option value="groupe4">groupe4</option>
				</select>
				<button id="dialog_content_block_add_group_new">+</button>
			</div>
			<div class="dialog_content_block_add_result">
				personnes présentes
			</div>
		</div>
		<div class="clear"></div>
    </div>
	<div class="dialog_footer">
		<button id="valid_add_user" class="login_button" mat-raised-button color="accent" >Ajouter</button>
	</div>
</mat-dialog>
