import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef }							from '@angular/material';

@Component({
	selector:		'app-user-add-form',
	templateUrl:	'./user-add-form.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./user-add-form.component.scss']
})

export class UserAddFormComponent implements OnInit
{

	constructor(
		public dialogRef: MatDialogRef<UserAddFormComponent, any>
	) 
	{ }

	public ngOnInit()
	{
	}

}
