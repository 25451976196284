import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject }	from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../services/user.service';
import { UserData } from '../../providers/user-data';
import { MAT_DIALOG_DATA } from '@angular/material';


@Component({
	selector:		'reset-password-modal',
	templateUrl:	'resetPasswordModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./resetPasswordModal.component.scss']
})


/**
 *
 */
export class ResetPasswordModalComponent implements OnInit, OnDestroy
{
	public newPassword: any;
	public type:		any;
	public libelle:		any;

	public resetCode:	any;
	public mailUser:	any;
	public compteUser:	any;

	public hasError: 				boolean = false;
    public moUpdateSubscription:	any;


    constructor(public dialogRef:		MatDialogRef<ResetPasswordModalComponent, any>,
                public userService:		UserService,
				public userData:		UserData,
				@Inject(MAT_DIALOG_DATA) public data: any)
    {
		if (data && data.type)
		{
			this.type = data.type;

			switch (this.type)
			{
				default:
					break;
					
				case 'resetForTemporary':
					this.libelle = 'Votre mot de passe actuel est un mot de passe temporaire. Veillez en saisir un nouveau:';
					break;
					
				case 'resetWithCode':
					this.libelle = 'Vous avez demandé la réinitialisation de votre mot de passe. Veuillez saisir et confirmer votre nouveau mot de passe:';
					break;
			}

			if (data.resetCode && data.mailUser && data.compteUser)
			{
				this.resetCode	= data.resetCode;
				this.mailUser	= data.mailUser;
				this.compteUser = data.compteUser;
			}
		}
    }
	
	
	/**
	 * 
	 */
    public ngOnInit()
	{

        this.moUpdateSubscription = this.userData.globalEmitter.subscribe((value: any) =>
		{
            if (value === 'user::passwordUpdated')
			{
				this.dialogRef.close(true);
			}
        });
    }


	/**
	 * 
	 */
    public ngOnDestroy()
	{
		this.moUpdateSubscription.unsubscribe();
	}


    /**
	 * Handle keypress event, for sending chat message
	 */
	public eventHandler(event: KeyboardEvent): void
	{
        let canValidate = this.canValidPassword();

		if (event.key === "Enter" && canValidate === true)
		{
			this.updatePassword();
		}
	}


	/**
	 * 
	 */
	updatePassword()
	{
		let datas: any = {};

		switch (this.type)
		{
			default:
				break;

			case 'resetForTemporary':
				datas =
					{
						newPassword:		this.newPassword,
						needOldPassword:	false
					};
				this.userService.updateUserPassword(datas);
				break;

			case 'resetWithCode':
				this.hasError = false;
				
				if (this.compteUser && this.mailUser && this.resetCode && this.newPassword)
				{
					this.userService.updatePasswordUserWithCode(this.compteUser, this.mailUser, this.resetCode, this.newPassword).then(res =>
					{
						this.dialogRef.close(true);
					}).catch(error =>
					{
						this.hasError = true;
					});
				}
				break;
		}
	}


	/**
	 * 
	 */
    cancel()
    {
        this.dialogRef.close(false);
    }


	/**
	 * 
	 * @param password 
	 */
    public handleOnValidPassword(password: any)
    {
        if (password !== false)
        {
            this.newPassword = password;
        }
        else
        {
            this.newPassword = undefined;
        }
    }


	/**
	 * 
	 */
    canValidPassword()
    {
        let result = false;

        if (this.newPassword)
        {
            result = true;
        }

        return result;
    }
}

