/***********************************************
 * Angular
 ***********************************************/
import { NgModule, ApplicationRef, Sanitizer }					from '@angular/core';
import { BrowserModule }										from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } 					from '@ngx-translate/core';
import { TranslateHttpLoader } 									from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule }						from '@angular/forms';
import { HttpModule }											from '@angular/http';
import { HttpClientModule, HttpClient} 							from '@angular/common/http';
import { Routes, RouterModule, PreloadAllModules }				from '@angular/router';
import { removeNgStyles, createNewHosts, createInputTransfer }	from '@angularclass/hmr';
import { Http }													from '@angular/http';
import { CUSTOM_ELEMENTS_SCHEMA }								from '@angular/core';
import { MAT_DATE_LOCALE }										from '@angular/material';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { DomSanitizer }											from '@angular/platform-browser';
import { AngularDateTimePickerModule } 							from 'angular2-datetimepicker';
import { NotifierModule, NotifierOptions }						from 'angular-notifier';
import { ValidateTaskModalComponent }							from './pages/workspace/subject/subject-list/modal/validateTaskModal/validateTaskModal.component';

import {VgCoreModule} 											from 'videogular2/core';
import {VgControlsModule} 										from 'videogular2/controls';
import {VgOverlayPlayModule} 									from 'videogular2/overlay-play';
import {VgBufferingModule} 										from 'videogular2/buffering';

import { ColorPickerModule }			from 'ngx-color-picker';
import { CalendarModule, DateAdapter }	from 'angular-calendar';
import { adapterFactory }				from 'angular-calendar/date-adapters/date-fns';
import { DragDropModule }				from '@angular/cdk/drag-drop';

import { registerLocaleData }	from '@angular/common';
import localeFr					from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import {DateAdapter as test } from '@angular/material/core';


import 
{
	MatToolbarModule,
	MatTableModule,
	MatExpansionModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatSortModule,
	MatDialogModule,
	MatIconModule,
	MatSnackBarModule,
	MatGridListModule,
	MatSliderModule,
	MatTabsModule,
	MatFormFieldModule,
	MatListModule,
	MatMenuModule,
	MatSlideToggleModule,
	MatTooltipModule,
	MatSidenavModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatStepperModule,
	MatDatepickerModule,
	MatChipsModule,
	MatAutocompleteModule,
	MatPaginatorModule,
	MatInputModule,
} from '@angular/material';

//import { ClickOutsideModule } from 'ng-click-outside';
import { PdfViewerModule }			from 'ng2-pdf-viewer';
import { MatNativeDateModule }		from '@angular/material';
import { NgxQRCodeModule }			from 'ngx-qrcode2';
import { QuillModule }				from 'ngx-quill';



/************************************************
 * Platform and Environment providers/directives/pipes/services
 ***********************************************/
import { ENV_PROVIDERS }			from './environment';
import { DeviceDetectorModule  }	from 'ngx-device-detector';

import { ROUTES }						from './app.routes';
import { APP_RESOLVER_PROVIDERS }		from './app.resolver';
import { AppState, InternalStateType }	from './app.service';

import { CreationHttpService }	from './services/http/creationHttp.service';
import { SocketService }		from './services/socket.service';

import { VaultHttpService }		from './services/http/vaultHttp.service';
import { SocketVaultService }	from './services/socketVault.service';

import { AuthHttpService }	from './services/http/authHttp.service';
import { AuthService }		from './services/auth.service';

import { EtablissementHttpService } from './services/http/etablissementHttp.service';
import { EtablissementService }		from './services/etablissement.service';

import { SessionHttpService }	from './services/http/sessionHttp.service';
import { SessionService }		from './services/session.service';

import { ExamenHttpService }	from './services/http/examenHttp.service';
import { ExamenService }		from './services/examen.service';

import { EpreuveHttpService }	from './services/http/epreuveHttp.service';
import { EpreuveService }		from './services/epreuve.service';

import { SujetHttpService } from './services/http/sujetHttp.service';
import { SujetService }		from './services/sujet.service';

import { UserHttpService }	from './services/http/userHttp.service';
import { UserService }		from './services/user.service';

import { ChatHttpService }	from './services/http/chatHttp.service';
import { RoomService }		from './services/room.service';

import { FileService }		from './services/file.service';
import { FileHttpService }	from './services/http/fileHttp.service';

import { MessagesService }		from './services/messages.service';
import { StatisticsService }	from './services/statistics.service';
import { EncryptService }		from './services/encrypt.service';

import { TaskService as TaskService2 }	from './services/task.service';
import { AdminService }					from './services/admin.service';
import { TaskHttpService }				from './services/http/taskHttp.service';

import { PassationService }		from './services/passation.service';
import { CandidatService }		from './services/candidat.service';
import { BaremeService }		from './services/bareme.service';

import { EventService } from './services/event.service';

import { UserData }				from './providers/user-data';
import { AdminData }			from './providers/admin-data';
import { BaremeData }			from './providers/bareme-data';
import { TaskData }				from './providers/task-data';
import { WorkspaceData }		from './providers/workspace-data';
import { FileData }				from './providers/file-data';
import { ChatData }				from './providers/chat-data';
import { SavedData }			from './providers/saved-data';
import { VaultData }			from './providers/vault-data';
import { WorkspaceDataService } from './providers/workspaceData.service';
import { Tools }				from './classes/tools';
import { Page }					from './classes/page.class';

import { MyDateAdapter }		from './classes/dateAdapter';
// import { Fingerprint2 }					from 'fingerprintjs2';
import { SafePipe }			from './services/safepipe';
import { TaskService }		from './pages/task/services/task.service';
import { AutoFocus }		from './pages/task/directives/autoFocus';
import { AppearDirective }	from './pages/community/directives/AppearDirective';
import { SafeHtmlPipe }		from './pipes/safeHtml.pipe';

// App is our top level component
import { AppComponent } from './app.component';

import { TaskFilterPipe }	from './pages/task/pipes/taskFilter.pipe';
import { HomeFilterPipe }	from './pages/home/pipes/homeFilter.pipe';
import { OtherUsersFilter } from './pipes/otherUsers.pipe';


/************************************************
 * Composants npm
 ***********************************************/
import { NgsRevealModule }	from 'ngx-scrollreveal';
import { DragulaModule }	from 'ng2-dragula';
import 'hammerjs';


import { CanvasModule }						from './pages/canvas/canvas.module';


//import { MasonryModule }			from 'angular2-masonry';
//import { MomentModule }			from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
//import { NgMasonryGridModule }		from 'ng-masonry-grid';


/************************************************
 * Composants Neoptec
 ***********************************************/
// Structure
import { FooterComponent }	from './pages/footer/footer.component';


// Générique
import { HomeComponent }				from './pages/home/home.component';
import { LoginComponent }				from './pages/login/login.component';
import { UserComponent }				from './pages/user/user.component';
import { CommentsComponent }			from './components/comments/comments.component';
import { CommentModalComponent }		from './components/comments/modal/commentModal.component';
import { PreviewModalComponent }		from './components/preview-modal/previewModal.component';
import { EditFileNameModalComponent }	from './components/edit-file-name-modal/editFileNameModal.component';
import { PasswordEditorComponent }		from './components/password-editor/passwordEditor.component';
import { ProcessEditorComponent }		from './components/process-editor/processEditor.component';


// administration
import { AdminComponent }						from './pages/admin/admin.component';
import { AdminUsersComponent }					from './pages/admin/admin-users/admin-users.component';
import { AdminSystemComponent } 				from './pages/admin/admin-system/admin-system.component';
import { EditionProcessusModalComponent } 		from './pages/admin/admin-system/modals/editionProcessusModal.component';
import { ConfirmDeleteProcessusModalComponent } from './pages/admin/admin-system/modals/confirmDeleteProcessusModal.component';
import { InfosDeleteProcessusModalComponent } 	from './pages/admin/admin-system/modals/infosDeleteProcessusModal.component';

import { AdminEtablissementComponent }						from './pages/admin/admin-etablissement/admin-etablissement.component';
import { ModalCreateChatComponent }							from './pages/community/modals/modal-create-chat/modal-create-chat.component';
import { UserModalComponent,DialogOverviewExampleDialog  }	from './modal/user/userModal.component';
import { ConnectApplicationModalComponent } 				from './modal/connect/connectApplication.component';
import { ConnectOTPModalComponent } 						from './modal/connectOTP/connectOTPModal.component';
import { ConfirmDeleteElementModalComponent } 				from './modal/confirmDeleteElement/confirmDeleteElementModal.component';
import { ConfirmDeleteUserModalComponent } 					from './modal/confirmDeleteUser/confirmDeleteUserModal.component';
import { ConnectNewMachineModalComponent } 					from './modal/connectNewMachine/connectNewMachineModal.component';
import { ResetPasswordModalComponent } 						from './modal/resetPassword/resetPasswordModal.component';
import { ForgetPasswordModalComponent } 					from './modal/forgetPassword/forgetPasswordModal.component';
import { InfosForgetPasswordModalComponent } 				from './modal/forgetPassword/infosForgetPasswordModal.component';
import { WarningRsaModalComponent }							from './modal/warningRsa/warningRsaModal.component';
import { SelectAccountModalComponent }						from './modal/selectAccount/selectAccountModal.component';
import { WarningAddBlockerEnabledComponent }				from './modal/warningAddBlockerEnabled/warningAddBlockerEnabledModal.component';

// Taches
import { TaskComponent }						from './pages/task/task.component';
import { DetailPanelComponent }					from './pages/task/components/detail-panel/detail-panel.component';
import { EditorPanelComponent }					from './pages/task/components/editor-panel/editor-panel.component';
import { EditorPanelTaskComponent } 			from './pages/task/components/editor-panel-task/editor-panel-task.component';
import { LaneComponent }						from './pages/task/components/lane/lane.component';
import { TicketComponent }						from './pages/task/components/ticket/ticket.component';
import { SelectAssignedComponent }				from './pages/task/modal/select-assigned.component';
import { SendMailModalComponent }				from './pages/task/modal/sendMailModal.component';
import { WarningUnloggedUserModalComponent }	from './pages/task/modal/warningUnloggedUserModal.component';


// Resultats
import { ResultComponent } from './pages/result/result.component';

// Communauté
import { CommunityComponent }			from './pages/community/community.component';
import { ChatListComponent }			from './pages/community/chat-list/chat-list.component';
import { ChatDetailComponent }			from './pages/community/chat-detail/chat-detail.component';
import { NotificationListComponent }	from './pages/community/notification-list/notification-list.component';
import { NotificationDetailComponent }	from './pages/community/notification-detail/notification-detail.component';


import { UserAddFormComponent }		from './pages/user-add-form/user-add-form.component';
import { BrowserAnimationsModule }	from '@angular/platform-browser/animations';
import { SearchBarModule }			from './components/search-bar/search-bar.module';
import { CalendarComponent }		from './pages/calendar/calendar.component';


// ---------------------------------------
// Edition
// ---------------------------------------
import { ModuleChatComponent }			from './pages/community/module-chat/module-chat.component';
import { ModuleNotificationComponent }	from './pages/community/module-notification/module-notification.component';


// ---------------------------------------
// HELP
// ---------------------------------------
import { NotificationModalComponent } from './pages/community/notification-modal/notification-modal.component';


import { UserNameComponent }			from './pages/user-name/user-name.component';
import { DateUserFriendlyComponent }	from './pages/date-user-friendly/date-user-friendly.component';
import { StateStatsComponent }			from './pages/state-stats/state-stats.component';


import { BaremeCreatorComponent }	from './components/bareme-creator/bareme-creator.component';
import { BaremeToQcmComponent }		from './components/bareme-to-qcm/bareme-to-qcm.component';
import { BaremeEditorComponent }	from './components/bareme-to-qcm/bareme-editor/bareme-editor.component';


import { ConfirmResetRsaModalComponent }		from './modal/confirmResetRsa/confirmResetRsaModal.component';
import { InfoResetRsaModalComponent }			from './modal/infoResetRsa/infoResetRsaModal.component';
import { ConfirmGiveFilesKeysModalComponent }	from './modal/confirmGiveFilesKeys/confirmGiveFilesKeysModal.component';
import { InfoInitAccountModalComponent }		from './modal/infosInitAccount/infosInitAccountModal.component';
import { DownloadRsaModalComponent }			from './modal/downloadRsa/downloadRsaModal.component';



// Application wide providers
const APP_PROVIDERS = [
	...APP_RESOLVER_PROVIDERS,
	AppState
];

type StoreType =
	{
		state:				InternalStateType,
		restoreInputValues: () => void,
		disposeOldHosts:	() => void
	};

/**
 *
 */
export function createTranslateLoader(http: Http)
{
	// return new TranslateStaticLoader(http, 'assets/i18n', '.json');
}

export function HttpLoaderFactory(http: HttpClient)
{
	return new TranslateHttpLoader(http);
}

export const BROWSER_SANITIZATION_PROVIDERS: Array<any> = [
	{ provide: Sanitizer, useExisting: DomSanitizer },
];

export const routes: Routes = [
	{ path: '',						component: LoginComponent },
	{ path: 'workspace',			loadChildren: './pages/workspace/workspace.module#WorkspaceModule' },
//	{ path: 'workspace/passation',	component: PassationComponent },
//	{ path: 'workspace/result',		component: ResultComponent },
	{ path: 'community',			component: CommunityComponent },
	{ path: 'login',				component: LoginComponent },
	{ path: 'task',					component: TaskComponent },
	{ path: 'admin',				component: AdminComponent },
	{ path: 'home',					component: HomeComponent },
	{ path: 'task/NewTicket',		component: TaskComponent }
];


/************************************************
* `AppModule` is the main entry point into Angular2's bootstraping process
***********************************************/
@NgModule({
	bootstrap: [AppComponent],
	declarations:
		[
			AutoFocus,
//			routes,
			ConfirmResetRsaModalComponent,
			ConfirmGiveFilesKeysModalComponent,
			InfoResetRsaModalComponent,
			DownloadRsaModalComponent,
			InfoInitAccountModalComponent,
			AppearDirective,
			AppComponent,
			HomeComponent,
			LoginComponent,
			DateUserFriendlyComponent,
			StateStatsComponent,
			CommentsComponent,
			PasswordEditorComponent,
			ProcessEditorComponent,
			CommentModalComponent,
			PreviewModalComponent,
			EditFileNameModalComponent,
			EditionProcessusModalComponent,
			ConfirmDeleteProcessusModalComponent,
			InfosDeleteProcessusModalComponent,
			SelectAssignedComponent,
			SendMailModalComponent,
			AdminComponent,
			AdminUsersComponent,
			AdminSystemComponent,
			ChatListComponent,
			AdminEtablissementComponent,
			UserModalComponent,
			DialogOverviewExampleDialog,
			FooterComponent,
//			WorkspaceComponent,
			ConnectApplicationModalComponent,
			ConnectOTPModalComponent,
			ConfirmDeleteElementModalComponent,
			ConfirmDeleteUserModalComponent,
			ConnectNewMachineModalComponent,
			ResetPasswordModalComponent,
			SelectAccountModalComponent,
			ForgetPasswordModalComponent,
			InfosForgetPasswordModalComponent,
			WarningRsaModalComponent,
			WarningAddBlockerEnabledComponent,
			ModalCreateChatComponent,
			CommunityComponent,
			ChatDetailComponent,
			NotificationListComponent,
			NotificationDetailComponent,
			UserComponent,
			TaskComponent,
			ResultComponent,
			WarningUnloggedUserModalComponent,
			DetailPanelComponent,
			EditorPanelComponent,
			EditorPanelTaskComponent,
			LaneComponent,
			UserNameComponent,
			SafePipe,
			TicketComponent,
			TaskFilterPipe,
			HomeFilterPipe,
			SafeHtmlPipe,
			OtherUsersFilter,
			UserAddFormComponent,
			CalendarComponent,
			ModuleChatComponent,
			ModuleNotificationComponent,
//			ClickOutsideModule,
			BaremeCreatorComponent,
			BaremeToQcmComponent,
			BaremeEditorComponent,
			NotificationModalComponent,
			ValidateTaskModalComponent
	],
	entryComponents:
	[
		ValidateTaskModalComponent,
		UserModalComponent,
		DialogOverviewExampleDialog,
		ConfirmResetRsaModalComponent,
		ConfirmGiveFilesKeysModalComponent,
		InfoResetRsaModalComponent,
		DownloadRsaModalComponent,
		InfoInitAccountModalComponent,
		ConnectApplicationModalComponent,
		ConnectOTPModalComponent,
		ConfirmDeleteElementModalComponent,
		ConfirmDeleteUserModalComponent,
		ResetPasswordModalComponent,
		SelectAccountModalComponent,
		ConnectNewMachineModalComponent,
		ForgetPasswordModalComponent,
		InfosForgetPasswordModalComponent,
		WarningRsaModalComponent,
		WarningAddBlockerEnabledComponent,
		ModalCreateChatComponent,
		UserAddFormComponent,
		CommentModalComponent,
		PreviewModalComponent,
		EditFileNameModalComponent,
		EditionProcessusModalComponent,
		ConfirmDeleteProcessusModalComponent,
		InfosDeleteProcessusModalComponent,
		SelectAssignedComponent,
		SendMailModalComponent,
		WarningUnloggedUserModalComponent,
		EditorPanelComponent,
		EditorPanelTaskComponent,
		BaremeCreatorComponent,
		BaremeToQcmComponent,
		BaremeEditorComponent
	],
	imports: [ // import Angular's modules
		FormsModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		CommonModule,
		PdfViewerModule,
		MatSnackBarModule ,
		AngularDateTimePickerModule,
		HttpClientModule,
//		NgMasonryGridModule,
		ColorPickerModule,
		MatNativeDateModule,
		DeviceDetectorModule.forRoot(),
		ReactiveFormsModule,
		HttpModule,
		BrowserModule,
		QuillModule.forRoot(),
		BrowserAnimationsModule,
//		CalendarModule.forRoot(),
		SearchBarModule,
		DragulaModule,
		NgxQRCodeModule.forRoot(),
		NgsRevealModule,
		TranslateModule.forRoot({
			loader:
			{
				provide:	TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps:		[HttpClient]
			}
		}),
		MatToolbarModule,
		MatTableModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatSortModule,
		MatDialogModule,
		MatIconModule,
		MatGridListModule,
		MatSliderModule,
		MatTabsModule,
		MatFormFieldModule,
		MatListModule,
		MatExpansionModule,
		MatMenuModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatSidenavModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatStepperModule,
		MatDatepickerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatPaginatorModule,
		MatInputModule,
		CanvasModule,
//		RouterModule.forRoot(routes ,{ useHash: true, preloadingStrategy: PreloadAllModules }),
		RouterModule.forRoot(routes),
		DragDropModule
	],
	providers: [
		// expose our Services and Providers into Angular's dependency injection
		BROWSER_SANITIZATION_PROVIDERS,
		ENV_PROVIDERS,
		APP_PROVIDERS,
		UserData,
		AdminData,
		BaremeData,
		MyDateAdapter,
		TaskData,
		WorkspaceData,
		FileData,
		ChatData,
		VaultData,
		BaremeService,
		AuthService,
		CreationHttpService,
		SocketService,
		VaultHttpService,
		SocketVaultService,
		AuthHttpService,
		EtablissementService,
		EtablissementHttpService,
		SessionService,
		FooterComponent,
		SessionHttpService,
		ExamenService,
		ExamenHttpService,
		EpreuveService,
		EpreuveHttpService,
		SujetService,
		SujetHttpService,
		AppComponent,
		UserService,
		UserHttpService,
		FileHttpService,
		FileService,
		PassationService,
		CandidatService,
		EncryptService,
		MessagesService,
		StatisticsService,
		WorkspaceDataService,
		ChatHttpService,
		RoomService,
		EventService,
		TaskService,
		AdminService,
		TaskHttpService,
		TaskService2,
		Tools,
		Page,
		SavedData,
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: test, useClass: MyDateAdapter},

	],
	exports: [
		ProcessEditorComponent,

	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class AppModule
{
	constructor(public appRef: ApplicationRef, public appState: AppState) { }

	/**
	 *
	 */
	public hmrOnInit(store: StoreType)
	{
		if (!store || !store.state) return;

		// set state
		this.appState._state = store.state;
		// set input values
		if ('restoreInputValues' in store)
		{
			let restoreInputValues = store.restoreInputValues;
			setTimeout(restoreInputValues);
		}

		this.appRef.tick();
		delete store.state;
		delete store.restoreInputValues;
	}

	/**
	 *
	 */
	public hmrOnDestroy(store: StoreType)
	{
		const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
		// save state
		const state = this.appState._state;
		store.state = state;
		// recreate root elements
		store.disposeOldHosts = createNewHosts(cmpLocation);
		// save input values
		store.restoreInputValues = createInputTransfer();
		// remove styles
		removeNgStyles();
	}

	/**
	 *
	 */
	public hmrAfterDestroy(store: StoreType)
	{
		// display new elements
		store.disposeOldHosts();
		delete store.disposeOldHosts;
	}
}
