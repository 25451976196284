import { Injectable }		from '@angular/core';
import { GenericData }		from './generic-data';
import { WorkspaceData }	from './workspace-data';
import { TaskData }			from './task-data';
import { UserData }			from './user-data';

import { File as EFile }	from '../classes/file.class';
import * as _				from 'underscore';

// import { Storage } from '@ionic/storage';

@Injectable()


/**
 * Class UserData
 */
export class FileData extends GenericData
{

	// Selection user

	// Les datas

	public currentSortName	= '';
	public currentAsc		= '';
	/**
	 * Stockage des taches récupérées dans l'appli
	 */
	private _filterList:		any	= [];
	private _taskList:			any = [];
	private _haveData:			boolean = true;
	private _arrayFiles:		EFile[];
	private _sorterList:		any[];
	private _sorterSelected:	any;
	private _taskListSelected:	any;
	private _filterSelected:	any;

	public listTypesDocument = [
		{
			label: 'Sujet',
			value: 'sujet'
		},
		{
			label: 'Document Réponse',
			value: 'docRep'
		},
		{
			label: 'Document Annexe',
			value: 'docAnn'
		},
		{
			label: 'Barème',
			value: 'bareme'
		},
		{
			label: 'Correction',
			value: 'correction'
		},
		{
			label: 'Document de test',
			value: 'docTest'
		},
		{
			label: 'Source',
			value: 'source'
		},
		{
			label: 'Mémo de correction',
			value: 'memo'
		},
		{
			label: 'Bon A Tirer (BAT)',
			value: 'bat'
		}];


	/**
	 *
	 */
	constructor(public workspaceData:	WorkspaceData,
				public taskData:		TaskData,
				public userData:		UserData)
	{
		super();
	}


	get arrayFiles()		{ return this._arrayFiles; }
	
	get filterList()
	{
		let oReturn = [];
		for (let key of this._filterList)
		{
			oReturn.push(key);
		}
		return oReturn;
	}
	get taskList()
	{
		let oReturn = [];
		for (let item of this._taskList)
		{
			oReturn.push(item);
		}
		return oReturn;
	 }


	get sorterList()		{ return this._sorterList; }
	get sorterSelected()	{ return this._sorterSelected; }
	get filterSelected()	{ return this._filterSelected; }
	get taskListSelected()	{ return this._taskListSelected; }
	get haveData()			{ return this._haveData; }


	set haveData(haveData)					{ this._haveData			= haveData; }
	set arrayFiles(arrayFiles)				{ this._arrayFiles			= arrayFiles; }
	set taskList(taskList)					{ this._taskList			= taskList; }
	set filterList(filterList)				{ this._filterList			= filterList; }
	set sorterList(sorterList)				{ this._sorterList			= sorterList; }
	set sorterSelected(sorterSelected)		{ this._sorterSelected		= sorterSelected; }
	set filterSelected(filterSelected)		{ this._filterSelected		= filterSelected; }
	set taskListSelected(taskListSelected)	{ this._taskListSelected	= taskListSelected; }



	/**
	 * TODO tableau de conversion à prévoir pour voir les bons nom.
	 * Renommer filter en sort et filter2 en filter
	 */
	public synchronize()
	{
		
		let taskArray = this.taskData.globalTaskDatas;

		this.arrayFiles = this.workspaceData.selectedSujet.pj;

		if (this._arrayFiles.length > 0)
		{
			this.haveData = true;
		}
		else
		{
			this.haveData = false;
		}

//		this._filterList['tous']  = 1;
//		this._filterList['aucun'] = 1;

		let filterTemp 		= [];
		let filterId1: any 	= {};
		filterId1.num		= 1;
		filterId1.id		= 'tous';
		filterId1.name		= 'Tous';
		filterTemp.push(filterId1);

		let filterId2: any		= {};
		filterId2.num			= 1;
		filterId2.id			= 'aucun';
		filterId2.name			= 'Sans type';
		filterTemp.push(filterId2);

		let filterId3: any		= {};
		filterId3.num			= 1;
		filterId3.id			= 'corbeille';
		filterId3.name			= 'Corbeille';
		filterTemp.push(filterId3);

		let taskTemp:	any	= [];
		let taskId:		any = {};
		
		taskId.num	= 1;
		taskId.id	= 'toutes';
		taskId.name = 'Toutes';

		taskTemp.push(taskId);


		let taskId2: any	= {};
		taskId2.num			= 1;
		taskId2.id			= 'aucune';
		taskId2.name		= 'Aucune';
		taskTemp.push(taskId2);


		// Récupération des types de documents
		for (let document of this.arrayFiles)
		{
			if (document.typeDocument)
			{
				let filterId4: any 	= {};
				filterId4.num		= 1;
				filterId4.id		= document.typeDocument;

				// Récupération du vrai nom
				for (let typeDoc of this.listTypesDocument)
				{
					if (typeDoc.value === document.typeDocument)
					{
						filterId4.name = typeDoc.label;
					}
				}
				filterTemp.push(filterId4);
			}

			if (document.taskId)
			{
				let currentTask = this.taskData.getTaskById(document.taskId);
				if (currentTask && currentTask.typeLib)
				{
					document.currentStep = currentTask.typeLib;
					let taskId3: any = {};
					taskId3.num		= 1;
					taskId3.id		= currentTask.type;
					taskId3.name	= currentTask.typeLib;
		
					taskTemp.push(taskId3);
				}
			}
		}
		
		this._taskList		= _.uniq(taskTemp,		false, 'id');
		this._filterList	= _.uniq(filterTemp,	false, 'id');
		
		
		// récupération des taches rattaché au document
	}


	/**
	 *  Remet à l'origine les fichier (efface le tri)
	 */
	public raz()
	{
		this.arrayFiles = this.workspaceData.selectedSujet.pj;
		if (this._arrayFiles.length > 0)
		{
			this.haveData = true;
		}
		else
		{
			this.haveData = false;
		}
		this.sorterSelected = '';
		this.filterSelected = '';
	}



	/**
	 * Tri la liste des fichier
	 */
	public sortList(nameFilter: any, orderAsc: any)
	{
		this.currentSortName	= nameFilter;
		this.currentAsc			= orderAsc;

		this._arrayFiles.sort(function (a, b)
		{
			let nameA: any;
			let nameB: any;

			// en fonction du choix user on applique le tri en quesiton
			switch (nameFilter)
			{
				case 'name':
					 nameA = a.name.toUpperCase(); // ignore upper and lowercase
					 nameB = b.name.toUpperCase(); // ignore upper and lowercase
					break;

				case 'type':
					 nameA = a.typeDocument;
					 nameB = b.typeDocument;
					break;

				case 'date':
					 nameA = a.dateCreation
					 nameB = b.dateCreation;
					break;
				default:
					break;
			}

			if (orderAsc)
			{
				if (nameA < nameB)
				{
					return -1;
				}
				if (nameA > nameB)
				{
					return 1;
				}
			}
			else
			{
				if (nameA > nameB)
				{
					return -1;
				}
				if (nameA < nameB)
				{
					return 1;
				}
			}

			// names must be equal
			return 0;
		});

	}


	public filter(typeDoc: any, typeState: any, searchValue: any = undefined)
	{
		
		this.synchronize();
		let aResult			= [];
		let aResultState	= [];
		let aResultSearch	= [];

		let taskArray = this.taskData.globalTaskDatas;

		// ---------------------------------------
		// Partie type super
		// ---------------------------------------
		if (typeDoc === 'tous')
		{
			for (let doc of this.arrayFiles)
			{
				if (doc.deleted === false)
				{
					aResult.push(doc);
				}
			}
			this._arrayFiles = aResult;
		}
		else if (typeDoc === 'aucun')
		{
			for (let doc of this.arrayFiles)
			{
				if (doc.typeDocument === '' && doc.deleted === false)
				{
					aResult.push(doc);
				}
			}
			this._arrayFiles = aResult;
		}
		else
		{
			if (typeDoc === 'corbeille')
			{
				for (let doc of this.arrayFiles)
				{
					if (doc.deleted === true)
					{
						aResult.push(doc);
					}
				}
				this._arrayFiles = aResult;
			}
			else
			{
				for (let doc of this.arrayFiles)
				{
					if (doc.typeDocument === typeDoc && doc.deleted === false)
					{
						aResult.push(doc);
					}
				}
				this._arrayFiles = aResult;
			}
		}


		// ---------------------------------------
		// Partie type de tache
		// ---------------------------------------
		if (typeState === 'toutes')
		{
		}
		else if (typeState === 'aucune')
		{
			for (let doc of this.arrayFiles)
			{
				if (doc.taskId === '')
				{
					aResultState.push(doc);
				}
			}
			this._arrayFiles = aResultState;
		}
		else
		{
			
			for (let document of this.arrayFiles)
			{
				if (document.taskId)
				{
					for (let task of taskArray)
					{
						if (task.id === document.taskId && task.type === typeState)
						{
							aResultState.push(document);
						}
					}
				}

			}

			this._arrayFiles = aResultState;
		}

		// ---------------------------------------
		// Partie recherche
		// ---------------------------------------
		if (searchValue)
		{
			let searchValueWithoutSpaces = searchValue.replace(/\s/g, '');

			if (searchValueWithoutSpaces !== '')
			{
				let containsString;
				let searchLowerCase		= searchValue.toLowerCase();
				let searchWithoutAccent = searchLowerCase.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

				for (let document of this.arrayFiles)
				{
					let nameLowerCase		= document.name.toLowerCase();
					let nameWithoutAccent	= nameLowerCase.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

					if (document.name)
					{
						containsString = nameWithoutAccent.indexOf(searchWithoutAccent) !== -1;
						if (containsString === true)
						{
							aResultSearch.push(document);
						}
					}

				}
				this._arrayFiles = aResultSearch;
			}
		}

		this.sortList(this.currentSortName, this.currentAsc);
	}




}
