import { Injectable }		from '@angular/core';
import { AuthHttpService }	from './http/authHttp.service';
import { SocketService }	from './socket.service';
import { UserData }			from '../providers/user-data';

import { SCClientSocket, SCChannel } from 'socketcluster-client';

import {Observable} from 'rxjs/Rx';
import { ChatService } from './chat.service';

//declare var SCSocket: any;
@Injectable()

//
/**
 *
 */
export class AuthService
{

	private static autoRefreshInterval: any = undefined;
	private static nextPing					= 0;
	private static pingInterval				= 0;
	private static currentSocket: 		any;
	private static roomsAvailable: 		any;

	private static mainRooms = 
	[
		'*user::mailuser',
		'*soft::all::creation'
	];

	public userToken: string;
	public currentUser: any;

	/**
	 * Arret du service de gestion des rooms
	 * Cette fonction est appelé lorsqu'on se déconnecte de l'application
	 */
	public static stopGestionRooms()
	{
		if (AuthService.autoRefreshInterval)
		{
			clearInterval(AuthService.autoRefreshInterval);
			AuthService.autoRefreshInterval = undefined;
		}
	}


	/**
	 *
	 */
	constructor(private authHttpService: 	AuthHttpService,
				private userData: 			UserData,
				private socketService: 		SocketService
	)
	{

	}

	/**
	 *  Get un socket et verifie si le token est valide (par http si besoin)
	 */
	public checkTokenWsHttp(): Observable<any>
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (!AuthService.autoRefreshInterval)
		{
			AuthService.autoRefreshInterval = setInterval(() => { this.gestionSockets(); }, 200);
		}

		let observable = Observable.create((observer: any) =>
		{
			// get du socket
			this.socketService.getSocketConnection().subscribe((socket: any) =>
			{
				AuthService.currentSocket = socket;


				// ---------------------------------------
				// ToComment
				// ---------------------------------------
				if (!this.isCurrentSocketValid())
				{
					let token = this.userData.getTokenKey();
					//si token
					if (token)
					{
						let base64js = this.getTokenJSON(token);

						if (base64js && base64js.exp)
						{
							//token expiré
							if (base64js.exp < Date.now())
							{
								observer.next(false);
							}
						}

						// on le checke en htttp
						this.refreshToken(token).subscribe(res =>
						{
							let body = JSON.parse(res.text());
							if (body.err === false && body.data && body.data.token)
							{
								// ---------------------------------------
								// ToComment
								// ---------------------------------------
								this.userData.setTokenKey(body.data.token);
								// je peux ajouter mes canaux utilisateurs si pas déjà fait
								this.addRoomForToken(this.getTokenJSON(token));
								observer.next(true);
							}
							else
							{
								// ---------------------------------------
								// ToComment
								// ---------------------------------------
								observer.next(false);
							}
						});
					}
					else
					{
						// ---------------------------------------
						// ToComment
						// ---------------------------------------
						observer.next(false);
					}
				}
				else
				{
					// ---------------------------------------
					// ToComment
					// ---------------------------------------
					if (socket.authState === SCClientSocket.AUTHENTICATED)
					{
						// je peux ajouter mes canaux utilisateurs si pas déjà fait depuis authToken du socket
						if (this.socketService.roomsAGerer)
						{
							this.addRoomForToken(socket.authToken);
						}
						observer.next(true);
					}
					else
					{
						observer.next(false);
					}
				}
			});
		});

		return observable;
	}


	private addRoomForToken(tokenJS: any)
	{
		if (tokenJS)
		{
			if (this.socketService.roomsAGerer)
			{
				// Canal spécifique à l'user (multimachine)
				if (tokenJS.idUser)
				{
					let room = '*user::' + tokenJS.idUser;
					let value = this.socketService.findRoomAGerer(room);
					if (value < 0)
					{
						this.socketService.roomsAGerer.push({ name: room, state: 'nosub', watch: true, fct: this.socketService.watchUser.bind(this.socketService), channel: undefined })
					}

				}
				// Canal pour la machine
				if (tokenJS.fp)
				{
					let room = '*machine::' + tokenJS.fp;
					let value = this.socketService.findRoomAGerer(room);
					if (value < 0)
					{
						this.socketService.roomsAGerer.push({ name: room, state: 'nosub', watch: true, fct: this.socketService.watchMachine.bind(this.socketService), channel: undefined })
					}

				}
				// Canal pour le compte + soft/compte
				if (tokenJS.acc)
				{
					let room = '*cpt::' + tokenJS.acc;
					let value = this.socketService.findRoomAGerer(room);
					if (value < 0)
					{
						this.socketService.roomsAGerer.push({ name: room, state: 'nosub', watch: true, fct: this.socketService.watchAccount.bind(this.socketService), channel: undefined })
					}
					if (tokenJS.idUser)
					{
						let room2 = '*soft::' + tokenJS.acc + '::creation';
						value = this.socketService.findRoomAGerer(room2);
						if (value < 0)
						{
							this.socketService.roomsAGerer.push({ name: room2, state: 'nosub', watch: true, fct: this.socketService.watchAccountUpdates.bind(this.socketService), channel: undefined })
						}
					}

				}
			}
		}
	}


	private getTokenJSON(token: any): any
	{
		if (token)
		{
			//decodage token
			let splitToken: string[] = token.split('.');
			if (splitToken.length > 2)
			{
				return JSON.parse(window.atob(splitToken[1]));
			}
		}
		return undefined;
	}

	/**
	 *

	 */
	public doLogin(username: any, password: any, fingerprint: any = '')
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return this.authHttpService.doLogin(username, password, fingerprint).share();
	}

	/**
	 *
	 * @param token
	 */
	public refreshToken(token: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return this.authHttpService.refreshToken(token).share();
	}

	/**
	 *
	 */
	public doLoginWithToken(token: any)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return this.authHttpService.doLoginWithToken(token).share();
	}

	/**
	 * Check if current socket is in a valid state
	 * SI c'est IS => ça retourne true ou false, là c'est un get
	 */
	private isCurrentSocketValid()
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return AuthService.currentSocket && AuthService.currentSocket.state && AuthService.currentSocket.state === SCClientSocket.OPEN;
	}


	private isCurrentSocketAuth()
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		return AuthService.currentSocket && AuthService.currentSocket.authState && AuthService.currentSocket.authState == SCClientSocket.AUTHENTICATED;
	}

	private ping(token: any)
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		this.refreshToken(token).subscribe(res =>
		{
			let body = JSON.parse(res.text());
			if (body.err === false && body.data && body.data.token)
			{
				this.userData.setTokenKey(body.data.token);
				AuthService.nextPing = Date.now() + AuthService.pingInterval;;
			}
			else
			{
				AuthService.nextPing = 0;
				this.userData.delTokenKey();
			}
		});
	}

	/**
	 * Intervalle pour vérifier les connexions et les relancer si elles sont tombées
	 */
	private gestionSockets()
	{
		//si connection socket valide
		if (!this.isCurrentSocketValid())
		{
			//sinon on verifie si on a un token et la variable nextping
			let token = this.userData.getTokenKey();

			if (token)
			{
				//si nextping =0, on lui affecte now+ 20 *1000
				if (AuthService.nextPing === 0)
				{
					//decodage token
					let splitToken: string[] = token.split('.');
					if (splitToken.length > 2)
					{
						let base64js = JSON.parse(window.atob(splitToken[1]));

						if (base64js.iat && base64js.exp)
						{
							//si token deja expiré on le supprime
							if (base64js.exp < (Date.now() / 1000))
							{
								this.userData.delTokenKey();
							}
							else
							{
								let msec = (base64js.exp - base64js.iat) / 0.003;
								//calcul du prochain ping
								if (msec < 5000)
								{
									AuthService.pingInterval = 5 * 1000;
								}
								else
								{
									AuthService.pingInterval = msec;
								}
								AuthService.nextPing = Date.now() + AuthService.pingInterval;
								let mSec = base64js.exp * 1000;
								// si deja (presque) expiré
								if (AuthService.nextPing > mSec)
								{
									AuthService.nextPing = mSec;
									this.ping(token);
								}
							}
						}
						else
						{
							AuthService.nextPing = Date.now() + (20 * 1000);
						}
					}
					else
					{
						AuthService.nextPing = Date.now() + (20 * 1000);
					}
				}
				else
				{
					//si now > nextping, on reaffecte nextping + appel refresh (ping)
					if (AuthService.nextPing < Date.now())
					{
						AuthService.nextPing = Date.now() + (5 * 1000);
						this.ping(token);
					}
				}

			}
			else
				return;
		}
		else
		{
			//Soket valide
			if(this.isCurrentSocketAuth())
			{
				this.socketService.GererRoom();
			}
		}
	}
}
