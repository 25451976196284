import { Matiere }	from './matiere.class';
import { Comment }  from './comment.class';

import * as _ from 'underscore';


/**
 *
 * @export
 * @class Examen
 */
export class Examen
{
    /**
     * Creates an instance of Examen.
     *
     * @param {string} [_date='']
     * @param {string} [_name='']
     * @param {boolean} [_show=false]
     * @param {boolean} [_selected=false]
     * @param {string} [_date_start='']
     * @param {string} [_date_end='']
     * @param {Matiere[]} [_matieres=[]]
     *
     * @memberOf Examen
     */


    /**
     * Création d'un objet Examen à partir d'un objet json donné
     * @static
     * @param {any} jsonObj
     * @returns {Examen}
     *
     * @memberOf Examen
     */
    public static fromJsonObject(jsonObj: any): Examen
    {
        let oExamen: Examen = new Examen();

        oExamen.id		= jsonObj.uuid          || jsonObj.id              || '';
        oExamen.name	= jsonObj.name          || jsonObj.libelle         || '';
		oExamen.author	= jsonObj.creator 		|| jsonObj.createur;
		oExamen.flags   = jsonObj.flags			|| [];

        if (jsonObj.datas)
		{
			oExamen.show        = (typeof(jsonObj.datas.show)		=== 'string' && jsonObj.datas.show.toLowerCase()		=== 'true') || jsonObj.datas.show      === true ? true : false;
			oExamen.selected	= (typeof(jsonObj.datas.selected)	=== 'string' && jsonObj.datas.selected.toLowerCase()	=== 'true') || jsonObj.datas.selected  === true ? true : false;

			oExamen.date		= jsonObj.datas.date || jsonObj.datas.creation_date || '';
			oExamen.description	= jsonObj.datas.description;
		}

        oExamen.date = oExamen.date || jsonObj.creationdate;
        oExamen.date = new Date(oExamen.date);

        if (jsonObj.epreuves && jsonObj.epreuves.length)
        {
            for (let key in jsonObj.epreuves)
            {
                oExamen.matieres.push(Matiere.fromJsonObject(jsonObj.epreuves[key]));
			}

			oExamen.matieres.sort(function (a, b)
			{
				var nameA = a.name.toUpperCase(); // ignore upper and lowercase
				var nameB = b.name.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB)
				{
					return -1;
				}
				if (nameA > nameB)
				{
					return 1;
				}

				// names must be equal
				return 0;
			});
        }


        // Initialisation des commentaires de l'examen
        if (jsonObj.comments && Object.keys(jsonObj.comments).length > 0)
        {
            for (let key in jsonObj.comments)
            {
                if (jsonObj.comments[key])
                {
                    let objComment = Comment.fromJsonObject(jsonObj.comments[key]);
                    oExamen.comments.push(objComment);
                }
            }
        }

		let date				= jsonObj.creationdate || jsonObj.creation_date;
		oExamen.dateCreation	= new Date(date);

        return oExamen;
    }


	/**
	 *
	 * @param _id
	 * @param _date
	 * @param _name
	 * @param _description
	 * @param _show
	 * @param _selected
	 * @param _flags
	 * @param _matieres
	 * @param _comments
	 * @param _classeurId
	 * @param _sessionId
	 * @param _author
	 * @param _visible
	 */
    constructor(
        private _id:            string      = '',
        private _date:          Date        = null,
        private _name:          string      = '',
        private _description:   string      = '',
        private _show:          boolean     = false,
		private _selected:      boolean     = false,
		private _flags:			string[]	= [],
        private _matieres:      Matiere[]	= [],
        private _comments:      Comment[]   = [],
        private _classeurId:	string      = '',
        private _sessionId:		string      = '',
        private _author:        string      = '',
		private _visible:		boolean		= true
    ){ }


    get id()            { return this._id; }
    get date()          { return this._date; }
    get dateCreation()	{ return this._date; }
    get name()          { return this._name; }
    get description()   { return this._description; }
	get show()          { return this._show; }
	get selected()      { return this._selected; }
	get flags()      	{ return this._flags; }
    get matieres()      { return this._matieres; }
    get comments()      { return this._comments; }
    get classeurId()    { return this._classeurId; }
    get sessionId()     { return this._sessionId; }
    get author()        { return this._author; }
	get visible()       { return this._visible; }

    set id(id)						{ this._id			= id; }
    set date(date)				    { this._date        = date; }
    set dateCreation(dateCreation)	{ this._date        = dateCreation; }

    set name(name)					{ this._name        = name; }
    set description(description)	{ this._description = description; }
    set show(show)					{ this._show        = show; }
	set selected(selected)			{ this._selected    = selected; }
	set flags(flags)				{ this._flags    	= flags; }
    set matieres(matieres)			{ this._matieres    = matieres; }
    set comments(comments)			{ this._comments    = comments; }

    set classeurId(classeurId:	any)	{ this._classeurId  = classeurId; }
    set sessionId(sessionId:	any)	{ this._sessionId	= sessionId; }
    set author(author:			any)    { this._author      = author; }
	set visible(visible:		any)    { this._visible     = visible; }


    /**
     *
     * @param {any} info
     * @returns
     *
     * @memberOf Examen
     */
    public extractBranchInfos(info: any)
    {
        let infoCopy        = Object.assign({}, info);
        let infos: any		= [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        infoCopy.nameExamen         = this.name;
		infoCopy.idExamen         	= this.id;

		if (this.matieres.length > 0)
		{
			for (let key in this.matieres)
			{
				infos = infos.concat(this.matieres[key].extractBranchInfos(infoCopy));
			}
		}
		else
        {
            infos.push(infoCopy);
        }

        return infos;
    }


    /**
     *
     * @param {any} examenObject
     * @param {any} [propertyFounded={}]
     *
     * @memberOf Examen
     */
	public updateMatieresAvailable(examenObject: any, propertyFounded: any = {}, hasFilter = false)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let matiereNames = _.uniq(_.map(examenObject, function (examen: any) { return examen['nameMatiere']; }));
		let currentMatieres;

		this.matieres = _.reject(this.matieres, function (matiere: any) { return !matiereNames.includes(matiere.name); });

		if (hasFilter === true)
		{
			this.matieres = _.sortBy(this.matieres, function (matiere: any) { return _.indexOf(matiereNames, matiere.name); });
		}


		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let key in this.matieres)
		{
			this.matieres[key].show = false;
			// a refacto => un truc lisible, thx ;)
			this.matieres[key].show = (propertyFounded['key']	=== 'default'		|| propertyFounded['key'] === 'discipline') && propertyFounded['values'].includes(this.matieres[key].name.toLowerCase())	? true : this.matieres[key].show;
			this.matieres[key].show = propertyFounded['key']	=== 'createur'		&& propertyFounded['values'].includes(this.matieres[key].author.toLowerCase())												? true : this.matieres[key].show;
			this.matieres[key].show = propertyFounded['key']	=== 'responsable'	&& propertyFounded['values'].includes(this.matieres[key].responsable.toLowerCase())											? true : this.matieres[key].show;

			currentMatieres = _.where(examenObject, {nameMatiere: this.matieres[key].name});
			// this.matieres[i].updateSujetsAvailable(this.matieres[i], propertyFounded);

			this.show = this.matieres[key].show ? true : this.show;
		}
	}


	/**
	 *
	 */
    public getSelectedItems(paramsSelected: any)
	{
		let result: any[] = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (paramsSelected['examen'] && this.id === paramsSelected['examen'])
		{
			result.push(this);
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let matiere of this.matieres)
		{
			result = result.concat(matiere.getSelectedItems(paramsSelected));
		}

		return result;
	}


	/**
	 *
	 */
	public getShowedItems(paramsShowed: any)
	{

		let result: any[] = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (paramsShowed[this.id] && paramsShowed[this.id] === 'examen')
		{
			result.push(this);
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		for (let matiere of this.matieres)
		{
			result = result.concat(matiere.getShowedItems(paramsShowed));
		}

		return result;
	}


    /**
	 * convertion d'un objet Examen au format Json
	 */
	public toJson()
	{
		let result: any =
			{
				id:				this.id,
				libelle:		this.name,
				type:			'examen',
				classeurId:		this.classeurId,
				sessionId:		this.sessionId,
				date:           this.date,
				creator:        this.author,
				flags:			this.flags,
				datas:
				{
					date:			this.date,
					description:	this.description,
					show:			this.show		? 'true' : 'false',
					selected:		this.selected	? 'true' : 'false',
				}
			};

        // Convertion des examens dans la structure json de l'examen
		if (this.matieres.length > 0)
		{
			result.epreuves = [];
			for (let epreuve of this.matieres)
			{
				result.epreuves.push(epreuve.toJson());
			}
		}

        // Convertion des commentaires dans la structure json de l'examen
        if (this.comments.length > 0)
		{
			result.comments = [];
			for (let comment of this.comments)
			{
				result.comments.push(comment.toJson());
			}
		}

		return result;
	}
}
