import { Component, ViewEncapsulation, Inject }	from '@angular/core';
import { MatDialogRef }					        from '@angular/material';
import { MAT_DIALOG_DATA }                      from '@angular/material';

@Component({
	selector:		'confirm-delete-user-modal',
	templateUrl:	'confirmDeleteUserModal.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./confirmDeleteUserModal.component.scss']
})


export class ConfirmDeleteUserModalComponent
{
	public currentUser:	any;


	constructor(public dialogRef: MatDialogRef<ConfirmDeleteUserModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any)
	{
		if (data && data.user)
		{
			this.currentUser = data.user;
		}
	}


	/**
	 * 
	 */
	public cancel()
    {
        this.dialogRef.close(false);
    }


	/**
	 * 
	 */
    public validate()
    {
        this.dialogRef.close(true);
    }
}
