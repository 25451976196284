import { Injectable }		from '@angular/core';
import { UserHttpService }	from './http/userHttp.service';

import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData }					from '../providers/user-data';

import {Observable} from 'rxjs/Rx';

declare var Fingerprint2: any;

@Injectable()


export class UserService
{
    constructor(private userHttpService:	UserHttpService,
				public	socketService:		SocketService,
				private userData:			UserData)
    {

    }


    /**
     * Get all users informations and their rights.
     */
	public getAllUsers(): Observable<any>
	{

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let observable = Observable.create((observer: any) =>
		{
			this.userHttpService.getAllUsers().subscribe(res =>
			{
				observer.next(res);
			});

		});

		return observable;
	}


	public createNewUser(userJson: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'user',
						'args':
						{
							'user': { userJson },
							'token': this.userData.getTokenKey()
						}
					};
				socket.emit('create', data);
			}
		});
	}


	public deleteUser(userMail: string)
	{
		this.socketService.getSocketConnection().subscribe((socket:any) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'user',
						'args':
						{
							'userMail': userMail,
							'token': this.userData.getTokenKey()
						}
					};
				socket.emit('delete', data);
			}
		});
	}

	/**
	 * Génération du code OTP permettant la génération d'un QR code pour la double authentification
	 * @param mailUser
	 */
	public createUserOTPCode(mailUser: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'createOTP',
						'args':
						{
							'mail': mailUser,
							'token': this.userData.getTokenKey()
						}
					};

				socket.emit('create', data);
			}
		});
	}

	/**
	 * Validation d'un code généré périodiquement à partir de l'appli smartphone
	 * Permet de finaliser le passage d'un compte en double authentification
	 * @param mailUser
	 * @param otpValidationCode
	 */
	validateUserOTPCode(mailUser: any, otpValidationCode: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'validateOtp',
						'args':
						{
							'mail':		mailUser,
							'otpCode': 	otpValidationCode,
							'token': 	this.userData.getTokenKey()
						}
					};

				socket.emit('create', data);
			}
		});
	}

	/**
	 * Mise a jour de la methode d'authentification
	 */
	changeOtpMethod(mailUser: any, otpMethod: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'changeOtpMethod',
						'args':
						{
							'mail': 		mailUser,
							'otpMethod': 	otpMethod,
							'token': 		this.userData.getTokenKey()
						}
					};

				socket.emit('update', data);
			}
		});
	}


	/**
	 * Vérification à la connection d'un code OTP généré périodiquement au saisie par l'utilisateur
	 * à la connexion
	 * @param mailUser
	 * @param otpValidationCode
	 */
	checkUserOTPCode(otpValidationCode: any, callback: (err: any, data: any) => any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let credentials = 
				{
					otpCode: 	otpValidationCode,
					token: 		this.userData.getTokenKey()
				};

				socket.emit('login2nd', credentials, (err: any, datas: any) =>
				{
					callback(err, datas);
				});
			}
		});
	}


	/**
	 *
	 * @param code
	 * @param fingerPrint
	 * @param callback
	 */
	checkMachineCode(code: any, fingerPrint: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'checkCodeNewMachine',
						'args':
						{
							'code': 		code,
							'fingerprint': 	fingerPrint,
							'token': 		this.userData.getTokenKey()

						}
					};
				socket.emit('update', data);
			}
		});
	}

    /**
     * update informations and right for a set of users
     */
	public updateUser(userId: any, dataUser: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'user',
						'args':
						{
							'idUser': 			userId,
							'datasToUpdate': 	dataUser,
							'token': 			this.userData.getTokenKey()

						}
					};
				socket.emit('update', data);
			}
		});
	}


	/**
	 * Get infos from current user logged
	 */
	public getCurrentUser(): Observable<any>
	{
		const observable = Observable.create((observer: any) =>
		{
			this.userHttpService.getCurrentUser().subscribe(res =>
			{
				observer.next(res);
			});
		});

		return observable;
	}


	/**
	 * Génère coté serveur un password aléatoire dont la longueur est donnée en paramètre
	 * @param passwordLength
	 */
	public generateNewPassword(passwordLength: number)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'password',
						'args':
						{
							'lengthPassword': passwordLength
						}
					};
				socket.emit('create', data);
			}
		});
	}


	public updateUserPassword(jsonUser: any)
	{
		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'updateUserPassword',
						'args':
						{
							'datasuser': 	jsonUser,
							'token': 		this.userData.getTokenKey()
						}
					};
				socket.emit('update', data);
			}
		});
	}


	/** */
	public checkFingerPrintUser()
	{
		this.socketService.getSocketConnection().subscribe((socket:any) =>
		{
			if (socket)
			{

				let options:any = 
				{
					userDefinedFonts:					[],
					excludeUserAgent:					false,
					excludeLanguage:					false,
					excludeColorDepth:					false,
					excludeScreenResolution:			true,
					excludeAvailableScreenResolution:	false,
					excludeTimezoneOffset:				false,
					excludeSessionStorage:				false,
					excludeIndexedDB:					false,
					excludeAddBehavior:					true,
					excludeOpenDatabase:				false,
					excludeCpuClass:					false,
					excludePlatform:					false,
					excludeDoNotTrack:					true,
					excludeCanvas:						false,
					excludeWebGL:						true,
					excludeAdBlock:						true,
					excludeHasLiedLanguages:			false,
					excludeHasLiedResolution:			false,
					excludeHasLiedOs:					false,
					excludeHasLiedBrowser:				false,
					excludeJsFonts:						true,
					excludeFlashFonts:					true,
					excludePlugins:						true,
					excludeIEPlugins:					true,
					excludeTouchSupport:				false,
					excludePixelRatio:					true,
					excludeHardwareConcurrency:			false,
					excludeWebGLVendorAndRenderer:		false,
					excludeDeviceMemory:				false
				};
				new Fingerprint2(options).get((result: any, components: any) =>
				{
					let fResult = result;
					let data: ISocketData =
					{
						'iam':	'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd':	'checkUserFingerprint',
						'args':
						{
							'fingerprint': 	fResult,
							'token': 		this.userData.getTokenKey()
						}
					};
					socket.emit('create', data);
				});
			}
		});
	}


	/**
	 * Demande au serveur la regeneration d'ue code machine à 4 chiffres et l'envoie par mail
	 */
	public regenerateCodeMachine()
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			const data: ISocketData =
			{
				'iam': 'c-c',
				'name': this.userData.getUserId(),
				'what': SocketWhat.info,
				'cmd': 'generateNewCodeMachine',
				'args': {}
			};
			socket.emit('create', data);
		});
	}


	/**
	 * Décode coté serveur avec la clé RSA publique la signature donnée qui est encodée avec
	 * la clé RSA privée, renvoie la confirmation de la signature
	 * @param encryptedSignature
	 */
	public encryptDatasForValidation(datas: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'encryptDatasForValidation',
						'args':
						{
							'data': datas
						}
					};
				socket.emit('read', data);
			}
		});
	}


	public toggleEnabledUser(mailUser: string, enable: boolean)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'userAccess',
						'args':
						{
							'idUser': mailUser,
							'enable': enable
						}
					};
				socket.emit('update', data);
			}
		});
	}

	public resetRsaKeyUSer(mailUser: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'deleteRsaKeyUser',
					'args':
					{
						'idUser': mailUser
					}
				};
				socket.emit('delete', data);
			}
		});
	}


	public getAesKeysFromUser(mailUser: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'getAesKeysFromUser',
					'args':
					{
						'idUser': mailUser
					}
				};
				socket.emit('read', data);
			}
		});
	}


	public sendPasswordReset(emailUser: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					const credentials =
					{
						emailUser: emailUser
					};

					socket.emit('sendPasswordReset', credentials, (err: any, datas: any) =>
					{
						if (err)
						{
							reject();
						}
						else
						{
							resolve();
						}
					});
				};
			});
		});
	}


	public checkCodePasswordReset(codeReset: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					const credentials = 
					{
						codeReset: codeReset
					};

					socket.emit('checkCodePasswordReset', credentials, (err: any, datas: any) =>
					{
						if (err)
						{
							reject(err);
						}
						else
						{
							resolve(datas);
						}
					});
				};
			});
		});
	}


	public updatePasswordUserWithCode(compteUser: any, mailUser: any, codeReset: any, newPassword: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					const credentials = 
					{
						compteUser: 	compteUser,
						mailUser: 		mailUser,
						codeReset: 		codeReset,
						newPassword: 	newPassword
					};

					socket.emit('updatePasswordUserWithCode', credentials, (err: any, datas: any) =>
					{
						if (err)
						{
							reject(err);
						}
						else
						{
							resolve(datas);
						}
					});
				};
			});
		});
	}


	public initKeyspaceAndUser(compteUser: any, mailUser: any, token: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					const credentials = 
					{
						compteUser: compteUser,
						mailUser: 	mailUser,
						token: 		token
					};

					socket.emit('initKeyspaceAndUser', credentials, (err: any, datas: any) =>
					{
						if (datas.user)
						{
							const accountInfos 		= this.userData.getAccountInfo();
							accountInfos.infosUser 	= datas.user;
							this.userData.setAccountInfo(accountInfos);
						}
						if (err)
						{
							reject(err);
						}
						else
						{
							resolve(datas);
						}
					});
				}
				else
				{
					reject();
				}
			});
		});
	}
}
