// Angular
import { Injectable } 				from '@angular/core';
import {Observable} 				from 'rxjs/Rx';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat } 	from '../models/message.model';

import { File as FileItem } from '../classes/file.class';
import { Sujet } 			from '../classes/sujet.class';

import { WorkspaceData } 	from '../providers/workspace-data';
import { UserData } 		from '../providers/user-data';
declare var require: any;

let fileSaver = require('file-saver');

import { Buffer } 	from 'buffer';
import * as _ 		from 'underscore';

@Injectable()
export class EncryptService
{
	constructor(private userData: 	UserData,
		private workspaceData:		WorkspaceData,
		private socketService: 		SocketService)
	{
	}


	/**
	 * Encryptage des clés AES associées à un ou plusieurs fichiers donnés avec la clé publique d'un utilisateur
	 * donné en paramètre. Envoie de la clé AES encodée au serveur pour enregistrement en base de données
	 * @param files
	 * @param userId
	 */
	public encryptFilesForUSer(files: any[], userId: string, currentSubject: Sujet)
	{
		const user = this.userData.getUser(userId);

		//On vérifie que l'on possède bien la clé RSA publique de l'utilisateur concerné
		if (user && user.publics && user.publics.rsaPublicKey && user.publics.rsaPublicKey !== '')
		{
			const userRsaPublicKey = user.publics.rsaPublicKey;

			if (currentSubject)
			{
				const filesObj = _.filter(currentSubject.pj, (pj: any) =>
				{
					return _.contains(files, pj.id);
				});
				//Pour chaque fichier dont on souhaite transmettre les droits, on vérifie que l'on possède
				//la clé AES et on l'envoie au serveur afin que cette clé soit associée au client

				for (const fileToSend of filesObj)
				{
					if (fileToSend.encryptedAesKeys && fileToSend.encryptedAesKeys.length > 0)
					{
						const aesKeyEncoded = fileToSend.encryptedAesKeys[0];

						if (aesKeyEncoded.val && aesKeyEncoded.val !== '' && aesKeyEncoded.version_min)
						{
							//On décrypte la clé AES avec la clé privée de l'utilisateur courant
							this.decryptData(aesKeyEncoded.val).then((aesDecoded: any) =>
							{
								//On encrypte la clé AES décryptée avec la clé publique de l'utilisateur affecté
								this.encryptData(aesDecoded, userRsaPublicKey).then((encryptedAes: any) =>
								{
									this.sendAesKey(userId, fileToSend.id, encryptedAes, aesKeyEncoded.version_min);

								});

							}).catch(error =>
							{
							});
						}
					}
				}
			}
		}
	} 


	/**
	 * Génération d'une clé RSA publique et d'une clé RSA privée associée
	 */
	public generateRsaKeyPair(): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			let subtle = this._getSubtleCryptoInstance();

			if (subtle)
			{
				subtle.generateKey({
					name: 				"RSA-OAEP",
					modulusLength: 		4096,
					publicExponent: 	new Uint8Array([0x01, 0x00, 0x01]),
					hash: 
					{
						name: "SHA-1"
					},
				},
					true,
					["encrypt", "decrypt"]
				).then((key: any) =>
				{

					let promises: PromiseLike<any>[] = [];
					promises.push(subtle.exportKey('spki', key.publicKey));
					promises.push(subtle.exportKey('pkcs8', key.privateKey));

					Promise.all(promises).then(rsaResult =>
					{
						if (rsaResult && rsaResult.length === 2)
						{
							let publicKeyBuffer		= rsaResult[0];
							let privateKeyBuffer	= rsaResult[1];

							let result: any =
								{
									publicKey:	this.convertBinaryToPem(publicKeyBuffer, 	'PUBLIC KEY'),
									privateKey: this.convertBinaryToPem(privateKeyBuffer, 	'RSA PRIVATE KEY')
								};

							resolve(result);
						}
						else
						{
							reject(null);
						}
					});
				});
			}
			else
			{
				reject(null);
			}
		});
	}


	/**
	 * Formatage d'une clé RSA
	 * @param binaryData
	 * @param label
	 */
	public convertBinaryToPem(binaryData: any, label: any)
	{

		let base64Cert 	= this.arrayBufferToBase64String(binaryData);
		let pemCert 	= "-----BEGIN " + label + "-----\r\n";
		let nextIndex 	= 0;

		let lineLength;

		while (nextIndex < base64Cert.length)
		{
			if (nextIndex + 64 <= base64Cert.length)
			{
				pemCert += base64Cert.substr(nextIndex, 64) + "\r\n";
			}
			else
			{
				pemCert += base64Cert.substr(nextIndex) + "\r\n";
			}
			nextIndex += 64;
		}
		pemCert += "-----END " + label + "-----\r\n";
		return pemCert;
	};


	/**
	 * Conversion d'un ArrayBuffer vers une chaine encodée en base64
	 * @param arrayBuffer
	 */
	public arrayBufferToBase64String(arrayBuffer: any)
	{

		let byteArray	= new Uint8Array(arrayBuffer)
		let byteString	= '';

		for (var i = 0; i < byteArray.byteLength; i++)
		{
			byteString += String.fromCharCode(byteArray[i]);
		}
		return btoa(byteString);
	}


	/**
     * Envoie une ou plusieurs clé aes pour un utilisateur donné
     * @param publicKey
     */
	sendAesKey(userId: any, fileId: any, aesKey: string, aesKeyVersionMin: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'userFileKey',
						args:
						{
							userId:				userId,
							fileId:				fileId,
							aesKey:				aesKey,
							aesKeyVersionMin:	aesKeyVersionMin
						}
					};
				socket.emit('create', data);
			}
		});
	}


	/**
	 * Suppression de la clé fichier pour un utilisateur et un fichier donné
	 * @param userId
	 * @param fileId
	 */
	deleteAesKey(userId: any, fileId: any)
	{
		// On vérifie si l'utilisateur dont on souhaite supprimer des clés de fichiers
		// n'est pas un super utilisateur ou un administrateur
		const isAdmin = this.userData.isUserAdmin(userId);
		const isSuper = this.userData.isUserSuperviseur(userId);

		if (isAdmin === false && isSuper === false)
		{
			this.socketService.getSocketConnection().subscribe((socket) =>
			{
				if (socket)
				{
					let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'userFileKey',
							'args':
							{
								userId: userId,
								fileId: fileId
							}
						};

					socket.emit('delete', data);
				}
			});
		}
	}


    /**
     * Envoie la clé publique sur le serveur pour que cette dernière soit enregistrée en base de donnée
     * @param publicKey
     */
	sendRsaPublic(publicKey: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'userPublicKey',
						'args':
						{
							publicKey: publicKey
						}
					};
				socket.emit('create', data);
			}
		});
	}


	encryptData(datas: any, publicRsaKey: any)
	{
		return new Promise((resolve, reject) =>
		{
			let subtle = this._getSubtleCryptoInstance();

			if (subtle)
			{
				let algorithmImport =
					{
						name: "RSA-OAEP",
						hash:
						{
							name: "SHA-1"
						}
					};
				subtle.importKey("spki", this.convertPemToBinary(publicRsaKey), algorithmImport, true, ['encrypt']).then((publicCryptoKey: any) =>
				{
					let testBuffer			= Buffer.from(datas, 'ascii');
					let algorithmEncrypt	=
						{
							name: 'RSA-OAEP'
						};

					subtle.encrypt(algorithmEncrypt, publicCryptoKey, testBuffer).then((encrypted: any) =>
					{
						let hexDataEncrypted = this.bufferToHex(encrypted);
						resolve(hexDataEncrypted);
					});

				});
			}
			else
			{
				reject(null);
			}
		});
	}


	/**
	 * Decrypt data encrypted using private RSA key
	 * @param datas
	 */
	decryptData(datas: any, rsaPRivate: any = undefined): Promise<any>
	{

		return new Promise((resolve, reject) =>
		{
			let subtle = this._getSubtleCryptoInstance();
			if (subtle)
			{
				let rsaPrivate = rsaPRivate || this.userData.getRSAPrivate();
				if (rsaPrivate)
				{
					let dataToDecrypt = new Uint8Array(datas.match(/[\da-f]{2}/gi).map((h: any) =>
					{
						return parseInt(h, 16);
					}));

					let algorithmImport =
						{
							name: "RSA-OAEP",
							hash:
							{
								name: "SHA-1"
							}
						};

					subtle.importKey("pkcs8", this.convertPemToBinary(rsaPrivate), algorithmImport, true, ['decrypt'])
						.then((privateCryptoKey: any) =>
						{
							let algorithmDecrypt =
								{
									name: 'RSA-OAEP',
									hash:
									{
										name: "SHA-1"
									}
								};

							subtle.decrypt(algorithmDecrypt, privateCryptoKey, dataToDecrypt).then((decrypted: any) =>
							{
								let strDecoded = String.fromCharCode.apply(null, new Uint8Array(decrypted));
								resolve(strDecoded);


							}).catch((err: any) =>
							{
								reject(err);
							});

						}).catch((err: any) =>
						{
							reject(err);
						});

				} 
				else
				{
					reject(null);
				}
			}
			else
			{
				reject(null);
			}

		});
	}


	/**
	 * Déchiffrement d'un fichier en utilisant une clé et vecteur AES 256
	 */
	decryptAesFile(blob: Blob, mimeType: string, sharedkey: string, vectorkey: string): Promise<any>
	{

		return new Promise((resolve, reject) =>
		{
			const subtle = this._getSubtleCryptoInstance();

			if (subtle)
			{
				const reader = new FileReader();

				// Attente du chargement du blob
				reader.onload = () =>
				{
					// Chargment de la clé dans SUBTLE
					subtle.importKey(

						// hex transformé en Array8
						'raw', this.hexStringToByte(sharedkey),
						{   // this is the algorithm options

							name: 'AES-CBC',
						},
						false,
						['encrypt', 'decrypt']
					).then((key: any) =>
					{
						// Déchiffrement
						subtle.decrypt(
							{
								name: 'AES-CBC',
								iv: this.hexStringToByte(vectorkey)
							},
							key,
							reader.result // ArrayBuffer
						).then((decrypted: ArrayBuffer) =>
						{
							// le blob veut un talbeau et pas un array buffer
							resolve(new Blob([decrypted],
								{

									type: mimeType
								}));

						}).catch((error: Error) =>
						{
							reject(null);
						});
					}).catch((error: Error) =>
					{
						reject(null);
					});
				};
				reader.readAsArrayBuffer(blob);
			}
			else
			{
				reject(null);
			}
		});
	}


	/**
	 * convertion d'une chaine encodée en base64 vers on tableau d'octets
	 * @param base64
	 */
	public b64ToArray(base64: any)
	{

		let binary_string 	= window.atob(base64);
		let len 			= binary_string.length;
		let bytes 			= new Uint8Array(len);

		for (let i = 0; i < len; i++)
		{
			bytes[i] = binary_string.charCodeAt(i);
		}

		return bytes.buffer;
	}


	bufferToHex(buffer: any)
	{
//		let sVarTest:any;
		return Array.prototype.map.call(new Uint8Array(buffer), (sVarTest:any) => ('00' + sVarTest.toString(16)).slice(-2)).join('');
	}


	/**
	 * Retourne une string hex depuis un tableau de byte
	 */
	byteToHexString(uint8arr: Uint8Array): string
	{
		if (!uint8arr)
		{
			return '';
		}

		var hexStr = '';
		for (var i = 0; i < uint8arr.length; i++)
		{
			var hex = (uint8arr[i] & 0xff).toString(16);
			hex 	= (hex.length === 1) ? '0' + hex : hex;
			hexStr += hex;
		}

		return hexStr.toUpperCase();
	}


	/**
	 * Retourne un tableau de byte depuis une chaine hexa
	 */
	public hexStringToByte(str: string): Uint8Array
	{
		if (!str)
		{
			return new Uint8Array(0);
		}

		var a = [];
		for (var i = 0, len = str.length; i < len; i += 2)
		{
			a.push(parseInt(str.substr(i, 2), 16));
		}

		return new Uint8Array(a);
	}


	public arrayBufferToBase64(arrayBuffer: any)
	{

		let binary	= '';
		let bytes	= new Uint8Array(arrayBuffer);
		let len 	= bytes.byteLength;

		for (let i = 0; i < len; i++)
		{
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}


	public stringToArrayBuffer(strDatas: string)
	{
		let buf 	= new ArrayBuffer(strDatas.length * 2);
		let bufView = new Uint8Array(buf);

		for (let i = 0, strLen = strDatas.length; i < strLen; i++)
		{
			bufView[i] = strDatas.charCodeAt(i);
		}
		return buf;
	}

	
	/**
	 * Convertion d'une clé RSA formatée pem en clé RSA binaire
	 * @param pem
	 */
	public convertPemToBinary(pem: any)
	{
		let lines = pem.split('\n');
		let encoded = '';
		for (let i = 0; i < lines.length; i++)
		{
			if (lines[i].trim().length > 0 &&
				lines[i].indexOf('-----BEGIN RSA PRIVATE KEY-----') < 0 &&
				lines[i].indexOf('-----BEGIN RSA PUBLIC KEY-----') < 0 &&
				lines[i].indexOf('-----BEGIN PUBLIC KEY-----') < 0 &&
				lines[i].indexOf('-----END PUBLIC KEY-----') < 0 &&
				lines[i].indexOf('-----BEGIN PRIVATE KEY-----') < 0 &&
				lines[i].indexOf('-----END PRIVATE KEY-----') < 0 &&
				lines[i].indexOf('-----END RSA PRIVATE KEY-----') < 0 &&
				lines[i].indexOf('-----END RSA PUBLIC KEY-----') < 0)
			{

				encoded += lines[i].trim();
			}
		}
		return this.b64ToArray(encoded);
	}


	private _getSubtleCryptoInstance()
	{
		let instance: any;
		if (window.crypto.subtle !== undefined)
		{
			instance = window.crypto.subtle;
		}
		else
		{
			if (crypto.subtle !== undefined)
			{
				instance = crypto.subtle;
			}
			else
			{
				//Compatibilité Safari
//				if (crypto['webkitSubtle'] !== undefined)
//				{
//					instance = crypto['webkitSubtle'];
//				}
			}
		}


		return instance;
	}
}
