<form (ngSubmit)="vadidateComment()" [formGroup]="moFormComment" class="dialog_form">
    <div class="dialog_grid_container">
        <div class="dialog_header">
			<div class="dialog_header_title">
				Edition commentaire
			</div>
			<div class="dialog_header_close">
				<div (click)='cancelComment()' class="dialog_header_close_button"></div>
			</div>
		</div>

        <div class="dialog_content">
            <mat-form-field>
				<textarea matInput placeholder="Message" [(ngModel)]="_currentComment.message" maxLength="250" formControlName="message"></textarea>
			</mat-form-field>
			<div class="clear"></div>
        </div>

        <div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button mat-raised-button type="button" (click)='cancelComment()'>Annuler</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button mat-raised-button type="submit"[disabled]="moFormComment.invalid" >Valider</button>
			</div>
		</div>
    </div>
</form>
