 // Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/share'


import { Task }				from '../classes/task.class';
import { Sujet }			from '../classes/sujet.class';
import { Comment }			from '../classes/comment.class';
import { Etablissement }	from '../classes/etablissement.class';
import { Session }			from '../classes/session.class';
import { Examen }			from '../classes/examen.class';
import { Matiere }			from '../classes/matiere.class';
import { TaskHttpService }	from './http/taskHttp.service';

import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData }					from '../providers/user-data';
import { WorkspaceData }			from '../providers/workspace-data';

import { EncryptService }   from '../services/encrypt.service';
import { FileService }   	from '../services/file.service';


import * as _ from 'underscore';

@Injectable()
/**
 *
 */
export class TaskService
{

    /**
	 *
	 */
	constructor(private taskHttpService:	TaskHttpService,
				public	socketService:		SocketService,
				private userData:			UserData,
				private workspaceData:		WorkspaceData,
				private encryptService:		EncryptService,
				private fileService:		FileService)
	{

	}

	/**
	 * Get tasks available considering the ressources for current user
	 */
	public getTasksAvailable()
	{
		this.socketService.on('read', this._onRessourcesReturned.bind(this));

		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'ressources',
					'args': {}
				};
				socket.emit('read', data);
			}
		});
	}


	public addTask(task: Task, sendMail: boolean = false, datasMail: any = null, pbMajSelected = 'false')
	{
		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'task',
					idc:	String(pbMajSelected),
					'args':
					{
						task:		task.toJson(),
						datasMail: datasMail
					}
				};
				socket.emit('create', data);
			}
		});
	}


	public deleteTask(task: Task)
	{
		return this.taskHttpService.deleteTask(task).share();
	}


	public updateTask(task: Task, newDatas: any)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'task',
					'args':
					{
						task: task.toJson(),
						datasTask: newDatas
					}
				};
				socket.emit('update', data);
			}
		});

		if (newDatas.assigneduser)
		{
			task.assigneduser = newDatas.assigneduser;
		}

		if (newDatas.state && newDatas.oldState && task.assigneduser)
		{
			const currentSubject = this.workspaceData.getSujet(task.idSujet);
			this.fileService.getFiles(currentSubject.id);

			const userDataEmmiterSubscription = this.userData.globalEmitter.subscribe((value: string) =>
			{
				if (value === 'files::get')
				{
					this._updateFilesAccessFromNewState(newDatas.state, newDatas.oldState, task, currentSubject);
					userDataEmmiterSubscription.unsubscribe();
				}

			});
		}
	}


	private _updateFilesAccessFromNewState(newState: string, oldState: string, task: Task, currentSubject: Sujet)
	{
		if (newState && task)
		{
			if (currentSubject)
			{
				//L'état est mis à jour
				//si le nouvel état est review on supprime les accès aux fichiers
				//concernés
				if (newState === 'done' || newState === 'new')
				{
					for (const file of currentSubject.pj)
					{
						this.encryptService.deleteAesKey(task.assigneduser, file.id);
					}
				}
				else
				{
					//sinon si l'ancien état est review on réatribue les droits
					if ((oldState === 'done' || oldState === 'new') && (newState === 'todo' || newState === 'inprogress'))
					{
						if (task.noAccessFile === false)
						{
							if (task.typeFileAccess === 'all')
							{
								let listFile = _.map(currentSubject.pj, (pj: any) => { return pj.id });
								this.encryptService.encryptFilesForUSer(listFile, task.assigneduser, currentSubject);
							}
							else
							{
								if (task.fileToAccess && task.fileToAccess.length > 0)
								{
									this.encryptService.encryptFilesForUSer(task.fileToAccess, task.assigneduser, currentSubject);
								}
							}
						}
					}
				}
			}
		}
	}


	public addTaskComment(task: Task, newComment: Comment)
	{
		return this.taskHttpService.addTaskComment(task, newComment).share();
	}


	/**
	 * Fonction appelée automatiquement à la récupération de ressources
	 */
	private _onRessourcesReturned(datas: any)
	{
		if (datas.cmd && datas.cmd === 'ressources')
		{
			if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
			{
				if (datas.args && datas.args.data && datas.args.data.ressources)
				{
					let parentNodes = _.keys(datas.args.data.ressources);
					this.userData.setRessources(parentNodes);

					for (let parentNode of parentNodes)
					{
						this.socketService.getSocketConnection().subscribe((socket) =>
						{
							if (socket)
							{
								let data: ISocketData =
								{
									'iam': 'c-c',
									'name': this.userData.getUserId(),
									'what': SocketWhat.info,
									'cmd': 'tasks.bynode',
									'args': {
										parentNode: parentNode
									}
								};
								socket.emit('read', data);
							}
						});
					}
				}
			}
			else
			{
				this.socketService.getSocketConnection().subscribe((socket) =>
				{
					if (socket)
					{
						let data: ISocketData =
						{
							'iam': 'c-c',
							'name': this.userData.getUserId(),
							'what': SocketWhat.info,
							'cmd': 'tasks.assigned',
							'args': {}
						};
						socket.emit('read', data);
					}
				});
			}
		}
	}
}
