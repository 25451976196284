
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';

import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData }					from '../providers/user-data';

@Injectable()
/**
 *
 */
export class MessagesService
{
    private socket: any = undefined;


    /**
	 *
	 */
    constructor(private socketService:	SocketService,
				private userData:		UserData)
    {
        this.socket = socketService.getSocketConnection();
    }


    /**
     *
     * @param idElement
     * @param bucket
     */
    public getMessages(idElement: any, bucket: string)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
        {
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'messages',
						'args':
						{
							key:	idElement,
							bucket: bucket
						}
					};
				socket.emit('read', data);
			}
		});
	}


    /**
     * Requete permettant dz récupéré les commentaires lus par l'utilisateur
     * pour un élément donné (tache, fichier, sujet ou noeud)
     * @param idElement
     */
	public getReadedMessages(idElement: string, bucket: string)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						cmd: 'messages.readed',
						args:
						{
							key:	idElement,
							bucket: idElement
						}
					};
				socket.emit('read', data);
			}
		});
	}


    /**
     * Récupère les messages appartenant à un élément non lu
     * A utiliser plutôt qu'un count
     * @param idElement
     * @param bucket
     * @param dateMessageFrom
     * @param limite
     */
    public getLastUnread(idElement: string, bucket: string, dateMessageFrom: any, limite: number)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
        {
			if (socket)
			{
				let data: ISocketData =
					{
						iam: 'c-c',
						name: this.userData.getUserId(),
						what: SocketWhat.info,
						cmd: 'messages',
						args:
						{
							key:		idElement,
							bucket:		idElement,
							fromDate:	dateMessageFrom,
							limit:		limite
						}
					};
				socket.emit('read', data);
			}
		});
	}


    /**
     * Flague le commentaire comme lu
     * @param idElement
     * @param bucket
     * @param idMessage
     * @param typeMessage
     * @param notification
     */
    public setMessageRead(idElement: string, bucket: string, idMessage: string, typeMessage: string, notification: string[])
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
        {
			if (socket)
			{
				let data: ISocketData =
					{
						iam: 'c-c',
						name: this.userData.getUserId(),
						what: SocketWhat.info,
						cmd: 'messages.read',
						args:
						{
							key:		idElement,
							bucket:		idElement,
							sub:		typeMessage,
							when:		idMessage,
							notifier:	notification
						}
					};
				socket.emit('update', data);
			}
		});
	}


    /**
     * Flague le commentaire comme recu
     * @param idElement
     * @param bucket
     * @param idMessage
     * @param typeMessage
     * @param notification
     */
    public setMessageAck(idElement: string, bucket: string, idMessage: string, typeMessage: string, notification: string[])
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
        {
			if (socket)
			{
				let data: ISocketData =
					{
						iam: 'c-c',
						name: this.userData.getUserId(),
						what: SocketWhat.info,
						cmd: 'messages.ack',
						args:
						{
							key:		idElement,
							bucket:		idElement,
							sub:		typeMessage,
							when:		idMessage,
							notifier:	notification
						}
					};
				socket.emit('update', data);
            }
        });
	}
	

    /**
     * Récupère le nombre de messages appartenant à un élémént
     * @param idElement
     * @param bucket
     * @param dateMessageFrom
     */
    public getMessagesCount(idElement: string, bucket: string, dateMessageFrom: any)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
        {
            if (socket)
			{
				let data: ISocketData =
					{
						iam: 'c-c',
						name: this.userData.getUserId(),
						what: SocketWhat.info,
						cmd: 'messages.count',
						args:
						{
							key:		idElement,
							bucket:		idElement,
							fromDate:	dateMessageFrom,
							sub:		'C'
						}
					};
				socket.emit('read', data);
            }
        });
    }

	
    /**
     *
     */
    public sendMessage(nameBucket: string, key: string, type: string, datas: any, notifiers: string[] = [])
    {
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						iam: 'c-c',
						name: this.userData.getUserId(),
						what: SocketWhat.info,
						cmd: 'messages.set',
						args:
						{
							bucket: nameBucket,
							key,
							sub: type,
							datas
						}
					};

				if (notifiers.length > 0)
                {
                    data.args.notifier = notifiers;
                }

                socket.emit('create', data);
            }
        });
    }
}
