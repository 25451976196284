<mat-dialog id="warning_rsa_modal" class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">{{ 'confirmGiveFiles.title' | translate }}</div>
		<div class="dialog_header_close">
			<div (click)='cancel()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">

		<mat-card-content>

			<div class="content-give-files" *ngIf="processing === false && processFinished === false">
				<label>{{ 'confirmGiveFiles.message' | translate }}</label>
				<div class="clear"></div>
				<label *ngIf="nameUser"> - {{ nameUser }}</label>
			</div>

			<div class="content-give-files" *ngIf="processing === true">
				<div *ngIf="processFinished === false">
					<label>{{ 'confirmGiveFiles.message2' | translate }}</label>
				</div>
				<div *ngIf="processFinished === true">
					<label>{{ 'confirmGiveFiles.message3' | translate }}</label>
				</div>

				<div class="clear"></div>

				<mat-progress-bar mode="determinate" [value]="processPercent"></mat-progress-bar>
				<label>{{ processPercent }} %</label>
			</div>

		</mat-card-content>

    </div>
	<div class="dialog_footer">
        <button id="cancel-connect" class="cancel_button" mat-raised-button (click)="cancel()">{{ 'common.cancel' | translate }}</button>
		<button *ngIf="processing === false" id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="validate()">{{ 'common.continue' | translate }}</button>
		<button *ngIf="processing === true && processFinished === true" id="valid-connect" class="login_button" mat-raised-button color="accent" (click)="close()">{{ 'common.close' | translate }}</button>
	</div>
</mat-dialog>
