<mat-dialog id="connection_application_modal " class="dialog_grid_container nomenu">
	<div class="dialog_header">
		<div class="dialog_header_title">
			<div *ngIf="type === 'confirmForget' || type === 'confirmPasswordUpdated'">
				Confirmation
			</div>
			<div *ngIf="type === 'errorOutOfDateLink' || type === 'errorInvalidLink'">
				Erreur
			</div>
		</div>
		<div class="dialog_header_close">
			<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
		</div>
	</div>
	<div class="dialog_content">
        <mat-card-content *ngIf="type === 'confirmForget'">
			<label>La demande de réinitialisation de mot de passe pour le compte {{usermail}} a été prise en compte. Vous recevrez dans les cinq prochaines minutes un mail contenant un lien vous permettant de réinitialiser votre mot de passe.</label>
			<div class="clear"></div>
			<label>Attention: ce lien a une durée de validité d'une heure.</label>
			<div class="clear"></div>
		</mat-card-content>

		<mat-card-content *ngIf="type === 'errorOutOfDateLink'">
			<label>Erreur: le délais de validité de votre lien de réinitialisation est dépassé.</label>
			<div class="clear"></div>
			<label>Rappel: le lien de validité est d'une heure.</label>
			<div class="clear"></div>
		</mat-card-content>


		<mat-card-content *ngIf="type === 'errorInvalidLink'">
			<label>Erreur: le lien de réinitialisation de votre mot de passe est invalide.</label>
			<div class="clear"></div>
			<label>Veuillez réitérer votre demande.</label>
			<div class="clear"></div>
		</mat-card-content>

		<mat-card-content *ngIf="type === 'confirmPasswordUpdated'">
			<label>Votre mot de passe a bien été mis à jour. Vous pouvez dès maintenant vous connecter avec ce nouveau mot de passe.</label>
			<div class="clear"></div>
			<label>(Un mail de confirmation vous sera envoyé dans les 5 prochaines minutes)</label>
			<div class="clear"></div>
		</mat-card-content>
    </div>
	<div class="dialog_footer">
		<button class="validate_button" mat-raised-button color="accent" (click)="close()">OK</button>
	</div>
</mat-dialog>
