import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation }	from '@angular/core';
import { SearchBarService }		from './services/search-bar.service';


@Component(
	{
		selector:		'cumstom-search-bar',
		encapsulation:	ViewEncapsulation.None,
		templateUrl:	'./search-bar.component.html',
		styleUrls:		['./search-bar.component.scss']
	})


/**
 *
 */
export class SearchBar implements OnInit
{

    @Input('datas')             jsonDatas:          any;
    @Input('properties')        tabProperties:      any;
    @Input('filters')           filters:            any;
    @Input('separator-search')  separatorSearch:    any;
    @Input('select-filter')     selectFilter:       any;
    @Output('datasReturned')    eventDatasReturn	= new EventEmitter();

    searchText: string = '';


    /**
     * Creates an instance of SearchBar.
     *
     * @param {PopoverController} popoverCtrl
     * @param {SearchBarService} searchBarService
     *
     * @memberOf SearchBar
     */
//    constructor(public popoverCtrl: PopoverController, private searchBarService: SearchBarService)
    constructor(private searchBarService: SearchBarService)
    {
	}


    /**
     *
     * @memberOf SearchBar
     */
    public ngOnInit()
    {
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        this.searchBarService.setEventEmiter(this.eventDatasReturn);
        this.searchBarService.setDatasToManage(this.jsonDatas, this.tabProperties, this.filters, this.separatorSearch);
    }


    /**
     *
     * @param {any} myEvent
     *
     * @memberOf SearchBar
     */
	public presentFilter(myEvent: any)
	{

	}


    /**
     *
     *
     * @memberOf SearchBar
    * */
    public updateItems()
    {

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
        let currentSearchText = this.searchText.toLowerCase();
        this.searchBarService.updateAvailableItems(currentSearchText);
    }

}
