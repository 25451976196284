export class Tools
{


	/**
	 * Verifie que pxValue ne soit pas vide.
	 * return true si vide, false dans le cas contraire
	 */
	public static isEmpty(value:any): boolean
	{
		let returnState: boolean = false;
		// If its stupid but works, It ain't stupid
		switch (value)
		{
			case '':
			case 0:
			case '':
			case 'undefined':
			case null:
			case false:
			case typeof this === 'undefined':
				returnState = true;
			break;

			default:
				returnState = false;
		}
		
		return returnState;
	}



	/**
	 * Lance une erreur, temporaire pour le moment  avoir avec david par la suite comment on gere les erreurs (log et tt ca)
	 */
	public static throwError(message:string): void
	{
		if (this.isEmpty(message))
		{
			// au passage c'est du foutage de gueule si on est là  !!!
			this.throwError('Message for throw error is empty')
		}
		throw new Error(message);
	}

	
	/**
	 * 
	 */
	constructor()
	{
		throw new Error('Cannot new this class');
	}

}
