// Angular
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
// Other
import { SujetHttpService }			from './http/sujetHttp.service';
import { Sujet }					from '../classes/sujet.class';
import { File as FileSubject }		from '../classes/file.class';
import { Comment }					from '../classes/comment.class';
import { SocketService }			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';

import { UserData } 		from '../providers/user-data';
import { WorkspaceData } 	from '../providers/workspace-data';
import { TaskData }			from '../providers/task-data';

import { FileService } from '../services/file.service';


import * as _ from 'underscore';

@Injectable()


/**
 *
 */
export class SujetService
{
	/**
	 *
	 */
	constructor(private sujetHttpService:	SujetHttpService,
				private socketService:		SocketService,
				private workspaceData:		WorkspaceData,
				private taskData:			TaskData,
				private fileService:		FileService,
				private userData:			UserData)
	{

	}


    /**
     * Add new sujet in database from Sujet object given in parameter
     */
	public addSujet(sujet: Sujet, parentPath: any, datasMail: any = '', idc: any = '')
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'subject',
					'args':
					{
						subject:	sujet.toJson(),
						parentPath: parentPath,
						datasMail:	datasMail
					}
				};

				socket.emit('create', data);
			}
		});
	}


    /**
     *
     */
	public editFlag(psId: string, psEtat: string, idMatiere: any)
	{

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'subject',
					'args': {
						subject:
						{
							idsujet:	psId,
							idepreuve:	idMatiere,
							data:		{ etat: psEtat }
						}
					}
				};

				socket.emit('update', data);
			}
		});
	}


    /**
     * Get all subjects in database relative to user account and parameters given:
     *
     *  -idSujet:    if this parameter is defined, return the Sujet object corresponding to the value id
     *  -idEpreuve:  if this parameter is defined (and if the parameter idSujet is not), return the Sujet
     *               objects wich the epreuve parent matches the value id.
     *
     */
	public getSujets(idEpreuve: string = undefined, idSujet: string = '')
	{
		this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
				let data: ISocketData =
					{
						'iam': 'c-c',
						'name': this.userData.getUserId(),
						'what': SocketWhat.info,
						'cmd': 'subjects',
						'args':
						{
							idMatiere: idEpreuve,
							idSubject: idSujet
						}
					};

				socket.emit('read', data);
			}
		});
	}


	public getSubjectHistoric(idSubject: any)
    {
        this.socketService.getSocketConnection().subscribe((socket: any) =>
		{
			if (socket)
			{
                let dataHistoric: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'history',
					'args': {
						bucket: 'subjects',
						key: idSubject,
					}
				};
				socket.emit('read', dataHistoric);
			}
        });
    }


	/**
	 * Mise à jour coté serveur du sujet donné en paramètre
	 * @param subject
	 */
	public updateSubject(subject: Sujet)
	{
		const jsonSubject: any = subject.toJson();

		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'subject',
					'args': 
					{
						subject: jsonSubject
					}
				};

				socket.emit('update', data);
			}
		});
	}

	/**
	 * Vérification à partir des taches du sujet donné si l'utilisateur donné à le droit d'ajouter des fichiers
	 * -Il peut ajouter des fichiers s'il est admin
	 * -Il peut ajouter des fichiers si une des taches du sujet dans lesquelles il est affecté
	 * contient le droit d'ajout de fichier
	 */
	public getAuthCreateFileInSubject(idUser: any, subject: Sujet)
	{
		let result = false;

		if (this.userData.isAdmin() === true || this.userData.isSuperviseur() === true)
		{
			result = true;
		}
		else
		{
			if (subject && subject.tasks)
			{
				// On recherche dans les taches du sujet, la premiere ayant la propriete canCreateFile a vrai
				// s'il n'y en a aucune alors l'utilisateur courant ne peut pas ajouter de fichier dans le sujet
				const indexTaskWithUploadRight = _.find(subject.tasks, (task: any) =>
				{
					return task.canCreateFile === true && task.state !== 'done' && task.state !== 'review' && task.assigneduser === idUser; 
				});

				if (indexTaskWithUploadRight !== undefined)
				{
					result = true;
				}
			}


		}

		return result;
	}

    /**
	 * Add note to subject in database
	 *
	 * @param sujet: Sujet object in wich the note will be added
	 * @param note: note wich will be added in database to given subject
	 */
	public addNoteToSujet(sujet: Sujet, note: string)
	{
		return this.sujetHttpService.addNoteToSujet(sujet, note);
	}


	public deleteSubject(sujet: Sujet)
	{
		this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				const data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.getUserId(),
					'what': SocketWhat.info,
					'cmd': 'deleteSubject',
					'args': 
					{
						idSubject: sujet.id,
						idEpreuve: sujet.idMatiere
					}
				};

				socket.emit('delete', data);
			}
		});
	}


	/**
	 * Ecoute le channel socket d'un sujet donnée si on ne l'écoute pas déja.
	 * @param task
	 */
	public listenSubjectChannel(subject: Sujet)
	{
		// Construction du channel que l'on va écouter
		const channel = '*soft::' + this.userData.account + '::creation::suj::' + subject.id;
		/**
		 * On vérifie si on écoute pas déja sur le channe!
		 * Si ce n'est pas le cas on ajoute une room pour écouter sur ce channel
		 * en transmettant une fonction pour gérer les evènements
		 */
		const value = this.socketService.findRoomAGerer(channel);

		if (value < 0)
		{
			this.socketService.roomsAGerer.push(
				{
					name:		channel,
					state:		'nosub',
					watch:		true,
					fct:		this._handleSubjectEvent.bind(this),
					channel:	undefined
				});
		}
	}


	private _handleSubjectEvent(datas: any)
	{
		if (datas.cmd === 'update')
		{
			if (datas.args.data)
			{

				this.workspaceData._updateSubjectFromJSON([datas.args.data]);
//				this.workspaceData._addSubjectFromJSON([datas.args.data]);
				this.taskData.majSubjectName(datas.args.data.idsujet);

				if (datas.args.data.idsujet && datas.args.data.data)
				{
					const sujet = this.taskData.workspaceData.getSujet(datas.args.data.idsujet);

					for (const key in datas.args.data.data)
					{
						if (sujet.datas[key])
						{
							sujet.datas[key] = datas.args.data.data[key];
						}
					}
					const sujet2 = this.taskData.workspaceData.getSujet(datas.args.data.idsujet);
				}
			}
		}


		if (datas.cmd === 'updatefile')
		{
			if (datas.args.file)
			{
				const fileToReplace: FileSubject = FileSubject.fromJsonObject(datas.args.file);

				if (datas.args.definitive === true)

				{
					if (this.workspaceData.selectedSujet)
					{
						this.workspaceData.selectedSujet.pj = _.filter(this.workspaceData.selectedSujet.pj, (pj: any) =>
						{
							return pj.id !== fileToReplace.id;
						});
					}

					this.workspaceData.globalEmitter2.emit(fileToReplace.idSubject);
				}
				else
				{
					if (this.workspaceData.selectedSujet)
					{
						const index = _.findIndex(this.workspaceData.selectedSujet.pj, (pj: any) =>
						{
							return pj.id === fileToReplace.id;
						});

						// On vérifie si les donnnées de fichier récupéré contiennent des thumbnails
						if (index !== -1)
						{
							fileToReplace.encryptedAesKeys = this.workspaceData.selectedSujet.pj[index].encryptedAesKeys;
							fileToReplace.thumbs = this.workspaceData.selectedSujet.pj[index].thumbs;

							if (fileToReplace.tags)
							{
								if (_.indexOf(fileToReplace.tags, 'corbeille') === -1)
								{
									fileToReplace.deleted = false;
								}
								else
								{
									fileToReplace.deleted = true;
								}
							}

							this.workspaceData.selectedSujet.pj[index] = fileToReplace;
							this.workspaceData.globalEmitter2.emit(fileToReplace.idSubject);
						}
					}
				}
			}
		}

		if (datas.cmd === 'messages.set')
		{
			if (datas.args && datas.args.message)
			{
				const messageAdded = this.workspaceData.parseHistoric(datas.args.message);
				this.workspaceData.addHistoricToElement(datas.args.message.key, messageAdded);
				this.workspaceData.globalEmitter.emit(['messages::get', datas.args.message.key]);
			}
		}

		/**
		 * Onrécupère un évènement concernant un fichier
		 */
		if (datas.cmd === 'file')
		{
			if (datas.args
				&& datas.args.action
				&& datas.args.subjectId
				&& (datas.args.action === 'update'
				|| datas.args.action === 'create'
				|| datas.args.action === 'delete'))
			{
				let newHistoricItem: any = {};
				newHistoricItem.idUser			= datas.args.idUser;
				newHistoricItem.creationDate	= new Date(datas.args.creationDate);

				newHistoricItem = this.workspaceData.parseHistoricItem(datas.args, newHistoricItem);
				if (newHistoricItem)
				{
					//On ajoute l'historique au sujet courant
					this.workspaceData.addHistoricToElement(datas.args.subjectId, [newHistoricItem]);
					this.workspaceData.globalEmitter.emit(['messages::get', datas.args.subjectId]);

					if (datas.args.idFile)
					{
						//On ajoute l'historique au fichier courant
						this.workspaceData.addHistoricToElement(datas.args.idFile, [newHistoricItem]);
						this.workspaceData.globalEmitter.emit(['messages::get', datas.args.idFile]);
					}
				}
			}
		}

		if (datas.cmd === 'thumbnails')
		{
			if (datas && datas.args && datas.args.file)
			{
				// On recherche le fichier créé à mettre à jour suite à la génération d'un thumbnail
				const fileAdded: FileSubject		= FileSubject.fromJsonObject(datas.args.file);
				let oldUuid: any;

				if (datas.args.file.oldUuid)
				{
					oldUuid = datas.args.file.oldUuid;
				}

				if (this.workspaceData.selectedSujet && this.workspaceData.selectedSujet.pj)
				{
					const fileToUpdate: FileSubject = _.find(this.workspaceData.selectedSujet.pj, (pj: any) =>
					{
						return pj.id === fileAdded.id || (oldUuid && oldUuid === pj.id);
					});

					// On vérifie si les donnnées de fichier récupéré contiennent des thumbnails
					if (fileToUpdate)
					{
						if (datas.args.error === false)
						{
							fileToUpdate.id 		= fileAdded.id;
							fileToUpdate.idSubject 	= fileAdded.idSubject;
							fileToUpdate.pathThumbs = fileAdded.pathThumbs;
							fileToUpdate.thumbs 	= [];

							// On télécharge le thumbnail à partir de son identifiant unique et on le stocke
							this.fileService.downloadThumbnail(fileToUpdate).subscribe((res) =>
							{
								if (res && res.url)
								{
									fileToUpdate.errorThumb = false;
									fileToUpdate.thumbs.push(res);
								}
								this.workspaceData.globalEmitter2.emit(this.workspaceData.selectedSujet.id);
							});
						}
						else
						{
							fileToUpdate.errorThumb = true;
						}
					}
				}
			}
		}

		if (datas.cmd === 'pdf')
		{
			if (datas && datas.args && datas.args.file)
			{
				const fileAdded: FileSubject		= FileSubject.fromJsonObject(datas.args.file);

				if (this.workspaceData.selectedSujet && this.workspaceData.selectedSujet.pj)
				{
					const fileToUpdate: FileSubject = _.find(this.workspaceData.selectedSujet.pj, (pj: any) =>
					{
						return pj.id === fileAdded.id;
					});

					// On met à jour la référence du pdf
					if (fileToUpdate)
					{
						if (datas.args.error === undefined || datas.args.error === false)
						{
							fileToUpdate.errorPdf 	= false;
							fileToUpdate.pathPdf 	= fileAdded.pathPdf;
						}
						else
						{
							fileToUpdate.errorPdf = true;
						}
					}
				}
			}
		}


		if (datas.cmd === 'deleteSubject')
		{
			if (datas && datas.args && datas.args.idEpreuve && datas.args.idSubject  && datas.args.tasksToRemove)
			{
				this.workspaceData.selectedMatiere.sujets = _.filter(this.workspaceData.selectedMatiere.sujets, (sujet: any) => { return sujet.id !== datas.args.idSubject });

				// On supprime les taches concernées par le sujet
				const tasksToRemove: any[] = datas.args.tasksToRemove;

				if (tasksToRemove.length > 0)
				{
					this.taskData.globalTaskDatas = this.taskData.globalTaskDatas.filter(task => 
					{
						for (let taskToRemove of tasksToRemove)
						{
							if (taskToRemove !== task.id)
							{
								return true
							}
						}
						//return tasksToRemove.includes(task.id);
					});

					this.taskData.taskDatas = this.taskData.taskDatas.filter(task => 
					{
						for (let taskToRemove of tasksToRemove)
						{
							if (taskToRemove !== task.id)
							{
								return true
							}
						}
						//return tasksToRemove.includes(task.id);
					});





					let nIndiceMine = 0;
					
				for (let taskMineIndice in this.workspaceData.taskHomeMine)
				{
					for (let taskToRemove of tasksToRemove)
					{
						if (taskToRemove === this.workspaceData.taskHomeMine[taskMineIndice].id)
						{
							this.workspaceData.removeTaskToMine(nIndiceMine);

						}
					}
					nIndiceMine++;
				}

					/*
					this.workspaceData.taskHomeMine = this.workspaceData.taskHomeMine.filter(task => 
						{
							for (let taskToRemove of tasksToRemove)
							{
								if (taskToRemove !== task.id)
								{
									return true
								}
							}
						});
					*/
						let nIndiceExpired = 0;
						
					for (let taskExpiredIndice in this.workspaceData.taskHomeAdminExpired)
					{
						for (let taskToRemove of tasksToRemove)
						{
							if (taskToRemove === this.workspaceData.taskHomeAdminExpired[taskExpiredIndice].id)
							{
								this.workspaceData.removeTaskExpired(nIndiceExpired);

							}
						}
						nIndiceExpired++;

					}
/*

					this.workspaceData.taskHomeMine = taskExpired.filter(task => 
						{
							for (let taskToRemove of tasksToRemove)
							{
								if (taskToRemove !== task.id)
								{
									return true
								}
							}
						});*/
				}
				this.userData.globalEmitter.emit(['subject::delete', datas.args.idSubject]);
			}
		}
	}

}










