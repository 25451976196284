export class Task
{

	public static fromJsonObject(jsonObj: any): Task
    {
		return new Task(0);
	}
	
	
	constructor(
		public id:				number,
		public name:			string = '',
		public description:		string = '',
		public status:			string = 'todo',
		public priority:		number = 2,
		public dateCreation:	string = '',
		public dateUpdate:		string = '',
		public points:			number = 0
	) { }


	public newTask: boolean;


	public toJson()
    {
	}
}
