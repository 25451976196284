import { Component }			from './component';
import { Comment }				from './comment.class';
import { File as FileObject }	from './file.class';

export class Task extends Component
{
	/**
	 * Creation  d'un objet Task à partir de données contenues dans
	 * un objet json.
	 * @param jsonObj
	 */
	static fromJsonObject(jsonObj: any): Task
    {

		const result = new Task();

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		result.id					= jsonObj.uuid;
		result.name					= jsonObj.name;
		result.dateCreation			= new Date(jsonObj.creationdate);
		result.dateModification		= new Date(jsonObj.lastupdatedate);
		result.realTask				= true;

		result.endDate = jsonObj.end_date ? new Date(jsonObj.end_date) : null;


		if (jsonObj.datas)
		{
			if (jsonObj.datas.description)
			{
				result.description = jsonObj.datas.description;
			}

			if (jsonObj.datas.beginDate)
			{
				result.beginDate = new Date(jsonObj.datas.beginDate);
			}

			if (jsonObj.datas.endDate)
			{
				result.endDate = new Date(jsonObj.datas.endDate);
			}

			if (jsonObj.datas.realBeginDate)
			{
				result.realBeginDate = new Date(jsonObj.datas.realBeginDate);
			}

			if (jsonObj.datas.realEndDate)
			{
				result.realEndDate = new Date(jsonObj.datas.realEndDate);
			}

			if (jsonObj.datas.libelleEtape)
			{
				result.libelleType = jsonObj.datas.libelleEtape;
			}

			if (jsonObj.datas.etapeCptPassage)
			{
				result.etapeCptPassage = jsonObj.datas.etapeCptPassage;
			}

			result.noAccessFile				= jsonObj.datas.noAccessFile			&& jsonObj.datas.noAccessFile			=== '1' ? true	: false;
			result.readOnlyFile				= jsonObj.datas.readOnlyFile			&& jsonObj.datas.readOnlyFile			=== '1' ? true	: false;
			result.canDeleteFile			= jsonObj.datas.canDeleteFile			&& jsonObj.datas.canDeleteFile			=== '1' ? true	: false;
			result.typeFileAccess			= jsonObj.datas.typeFileAccess			? jsonObj.datas.typeFileAccess					: 'all';
			result.fileToAccess				= jsonObj.datas.fileToAccess			? jsonObj.datas.fileToAccess					: [];
			result.canCreateFile			= jsonObj.datas.canCreateFile			&& jsonObj.datas.canCreateFile			=== '1' ? true	: false;
			result.notifyByMail				= jsonObj.datas.notifyByMail			&& jsonObj.datas.notifyByMail			=== '1' ? true	: false;
			result.hasReadRightForPrinter	= jsonObj.datas.hasReadRightForPrinter	&& jsonObj.datas.hasReadRightForPrinter === '1' ? true	: false;
		}

		result.assigneduser			= jsonObj.assigned_user;

		// ---------------------------------------
		// On récupère les commentaires dans la tache à formater
		// et on les transforme en objets Comment
		// ---------------------------------------
		if (jsonObj.comments && jsonObj.comments.length > 0)
		{
			for (const comment of jsonObj.comments)
			{
				result.comments.push(Comment.fromJsonObject(comment));
			}
		}

		result.creator	= jsonObj.creator;
		result.parents	= jsonObj.parents;
		result.owner	= jsonObj.owner;

		result.idEtablissement	= jsonObj.idetablissement 	? jsonObj.idetablissement	: '';
		result.idSession		= jsonObj.idsession 		? jsonObj.idsession			: '';
		result.idExamen			= jsonObj.idexamen			? jsonObj.idexamen			: '';
		result.idEpreuve		= jsonObj.idepreuve 		? jsonObj.idepreuve			: '';
		result.idSujet			= jsonObj.idsujet			? jsonObj.idsujet			: '';

		result.type		= jsonObj.type;
		result.updater	= jsonObj.updater;
		result.state	= jsonObj.state || jsonObj.status || 'todo';
		result.flags	= jsonObj.flags || [];

		return result;
	}


	constructor(
	    public id:						string		= '',
		name:							string		= '',
        dateCreation:					string		= '',
		dateUpdate:						string		= '',
		public nameEtablissement:		string		= '',
		public idEtablissement:			string		= '',
		public idSession:				string		= '',
		public nameSession:				string		= '',
		public idExamen:				string		= '',
		public nameExamen:				string		= '',
		public idEpreuve:				string		= '',
		public nameEpreuve:				string		= '',
		public idSujet:					string		= '',
		public nameSujet:				string		= '',
		public description:				string		= '',
		public creator:					string		= '',
		public owner:					string		= '',
		public updater:					string		= '',
		public assigneduser:			string		= '',
		public beginDate:				Date		= null,
		public endDate:					Date		= null,
		public realBeginDate:			Date		= null,
		public realEndDate:				Date		= null,
		public parents:					string[]	= [],
		public parent:					Task		= null,
		public type:					string		= '0',
		public libelleType:				string		= '',
		public etapeCptPassage:			string		= '0',
		public state:					string		= 'todo',
		public priority:				number		= 2,
		public points:					number		= 0,
		public comments:				Comment[]	= [],
		public subtasks:				any[]		= [],
		public notification:			any			= {},
		public locked:					boolean		= false,
		public lockRules:				Task[]		= [],
		public taskLocked:				Task[]		= [],
		public newTask:					boolean		= true,
		public statesAvailable:			any[]		= [],
		public selected:				boolean		= false,
		public noAccessFile:			boolean		= false,
		public readOnlyFile:			boolean		= false,
		public canDeleteFile:			boolean		= false,
		public typeFileAccess:			string		= 'all',
		public fileToAccess:			FileObject[]=[],
		public canCreateFile:			boolean		= false,
		public containsNewDatas:		boolean		= false,
		public flags:					string[]	= [],
		public typeLib:					string		= '',
		public stateLib:				string		= '',
		public realTask:				boolean		= true,
		public notifyByMail:			boolean		= false,
		public hasReadRightForPrinter:	boolean		= false
	) 
	{
        super();

        this.id					= id;
        this.name				= name;
        this.dateCreation		= dateCreation	? new Date(Date.parse(dateCreation))	: new Date();
		this.dateModification	= dateUpdate	? new Date(Date.parse(dateUpdate))		: new Date();
		this.realTask           = true;

		this.statesAvailable = [
			{
				value: 'new',
				libelle: 'Non assigné'
			},
			{
				value: 'todo',
				libelle: 'Assigné'
			},
			{
				value: 'ongoing',
				libelle: 'En cours'
			},
			{
				value: 'review',
				libelle: 'A valider'
			},
			{
				value: 'done',
				libelle: 'Terminé'
			}
		];
     }


	get author()        		{ return this.creator; }
	set author(creator: any)   { this.creator = creator; }


	/**
	 * Transformation d'un object Task en objet Json.
	 * Les noms des données json correspondent à la nomenclature
	 * appliquée cotée back-end.
	 */
	public toJson(): any
    {
		let comments = [];

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		if (this.comments)
		{
			for (let comment of this.comments)
			{
				comments.push(comment.toJson());
			}
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let lockrules = [];

		if (this.lockRules)
		{
			for (let rule of this.lockRules)
			{
				lockrules.push(rule.toJson());
			}
		}

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let tasklocked = [];

		if (this.taskLocked)
		{
			for (let task of this.taskLocked)
			{
				tasklocked.push(task.toJson());
			}
		}

		let datas: any =
		{
			description:			this.description,
			begin_date:				this.beginDate				? this.beginDate.toJSON()		: null,
			end_date:				this.endDate				? this.endDate.toJSON()			: null,
			realBeginDate:			this.realBeginDate			? this.realBeginDate.toJSON()	: null,
			real_end_date:			this.realEndDate			? this.realEndDate.toJSON()		: null,
			noAccessFile:			this.noAccessFile			? '1' : '0',
			readOnlyFile:			this.readOnlyFile			? '1' : '0',
			canDeleteFile:			this.canDeleteFile			? '1' : '0',
		    typeFileAccess: 		this.typeFileAccess,
		    fileToAccess:			this.fileToAccess,
			canCreateFile:			this.canCreateFile			? '1' : '0',
			libelleEtape:			this.libelleType,
			notifyByMail:			this.notifyByMail			? '1' : '0',
			hasReadRightForPrinter: this.hasReadRightForPrinter	? '1' : '0'
		};

		
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let result = {
			uuid:				this.id,
			name:				this.name,
			creationdate:		this.dateCreation		? this.dateCreation.toJSON() : null,
			lastupdatedate:		this.dateModification	? this.dateModification.toJSON() : null,
			nameetablissement:	this.nameEtablissement,
			idetablissement:	this.idEtablissement,
			idsession:			this.idSession,
			namesession:		this.nameSession,
			idexamen:			this.idExamen,
			nameexamen:			this.nameExamen,
			idepreuve:			this.idEpreuve,
			nameepreuve:		this.nameEpreuve,
			idsujet:			this.idSujet,
			namesujet:			this.nameSujet,
			type:				this.type,
			mail_createur:		this.creator,
			owner:				this.owner,
			updater:			this.updater,
			assigned_user:		this.assigneduser,
			parents:			this.parents,
			state:				this.state,
			comments:			comments,
			subtasks:			this.subtasks,
			notification:		this.notification,
			locked:				this.locked				? '1' : '0',
			lockrules:			lockrules,
			tasklocked:			tasklocked,
			flags:				this.flags,
			datas:				datas
		};

		return result;
	}
}
