import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';

import { FormControl } 	from '@angular/forms';
import { MatDialogRef }	from '@angular/material';

import { WorkspaceDataService } from '../../../../providers/workspaceData.service';
import { UserData }				from '../../../../providers/user-data';

import * as _ from 'underscore';
import {MAT_DIALOG_DATA} from '@angular/material';


/**
 *
 */
@Component({
	selector:		'app-modal-create-chat',
	templateUrl:	'./modal-create-chat.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./modal-create-chat.component.scss']
})


/**
 *
 */
export class ModalCreateChatComponent implements OnInit
{
    public userSelected: any	= null;
    public user					= '';
	public usersList: any		= [];
	public selectedType: any;

	// ---------------------------------------
	// Controle du formulaier
	// ---------------------------------------
	public userCtrl: FormControl;

	// Les élements filtré pour l'autocompletion
	public filteredUsers: any;


	public typesAvailable =
	[
		{ value: 'public',	libelle: 'Conversation publique' },
		{ value: 'group',	libelle: 'Conversation de groupe' }
	];


    /**
    * Creates an instance of PopoverCreateChat.
    *
    * @param {ViewController} viewCtrl
    *
    * @memberOf PopoverCreateChat
    */
	constructor(public dialogRef: MatDialogRef<ModalCreateChatComponent, any>,
				public workData: WorkspaceDataService,
				public userData:UserData,
				@Inject(MAT_DIALOG_DATA) public data: any)
	{
		this.usersList = this.data.userList;
	}


	/**
	 * 
	 */
	public ngOnInit()
	{
		// ---------------------------------------
		// Init des formsControl
		// ---------------------------------------
		this.userCtrl		= new FormControl();

		// Raffraichi le filtrage à chaque modification
    	this.filteredUsers = this.userCtrl.valueChanges.startWith(null).map((name: any) => this.filterMail(name));
	}


    /**
     * Create a new piece with parameters of the current popup
     *
     * @memberOf PopoverCreateChat
     */
	public createPiece()
	{
		this.userData.userForChatInvite = this.userSelected;
		this.dialogRef.close(true);
	}


    /**
     * Close the current popup
     *
     * @memberOf PopoverCreateChat
    */
	public close()
	{
		this.dialogRef.close(false);
	}


	/**
	 * 
	 * @param user 
	 */
  	public displayUsername(user: any): string
	{
      	return user ? user.mail : null;
   	}


	/**
	 Salut à tous,
	 Le 11 mai je vous donne rendez-vous à Serignan pour mon premier combat en boxe anglaise 🥊
	 Je tiens à remercier Jacques Patrac mon matchmaker, ainsi mon coach Daniel Barry pour la prépa au top ainsi que tout les membres du Boxing Club Montpelliérain pour leur accueil.
	 Un gros bisous à mes supporter, ils ne sont pas nombreux mais ils se reconnaitront !
	 Boxe serignan <= tagguer ici l'event
	 *
	 */
	public filterMail(val: any)
	{

		let result	= this.usersList;
		let result2 = this.usersList;
		let result3 = this.usersList;
		let result4 = this.usersList;
		let result5 = this.usersList;

		if (val && typeof val === 'string')
		{
			result	= this.usersList.filter((user: any) => user.mail.toLowerCase().indexOf(val.toLowerCase())			=== 0);
			result2 = this.usersList.filter((user: any) => user.infos.nom.toLowerCase().indexOf(val.toLowerCase())		=== 0);
			result3 = this.usersList.filter((user: any) => user.infos.prenom.toLowerCase().indexOf(val.toLowerCase())	=== 0);

			result4 = this.usersList.filter((user: any) =>
			{
				const str = user.infos.prenom.toLowerCase() + ' ' + user.infos.nom.toLowerCase();
				
				return str.indexOf(val.toLowerCase()) === 0;
			});

			result5 = this.usersList.filter((user: any) =>
			{
				const str = user.infos.nom.toLowerCase() + ' ' + user.infos.prenom.toLowerCase();
				
				return str.indexOf(val.toLowerCase())	=== 0;
			});
		}

		const aReturn = _.union(result, result2, result3, result4, result5);

		return aReturn;
	}


	/**
	 * 
	 */
	public onNoClick(): void
	{
		this.dialogRef.close();
	}


	/**
	 * 
	 */
	public canCreateRoom()
	{
		let result = false;

		if (this.userSelected && this.userSelected.mail)
		{
			result = true;
		}

		return result;
	}
}
