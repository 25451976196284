import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener, Inject }	from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA }                        				from '@angular/material';
import { EditionProcessusModalComponent }   				from './modals/editionProcessusModal.component';
import { ConfirmDeleteProcessusModalComponent } 			from './modals/confirmDeleteProcessusModal.component';
import { InfosDeleteProcessusModalComponent } 				from './modals/infosDeleteProcessusModal.component';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AdminData }	from '../../../providers/admin-data';
import { AdminService } from '../../../services/admin.service';
import { SpecialTime }	from '../../../classes/specialTime.class';

import { Observable } from 'rxjs/Rx';

import * as htmlEncode	from 'js-htmlencode';
import * as _			from 'underscore';
import { NavigationStart, Router } from '@angular/router';




@Component({
    selector:		'app-admin-system',
    encapsulation:	ViewEncapsulation.None,
    templateUrl:	'./admin-system.component.html',
    styleUrls:		['./admin-system.component.scss']
})


/**
 *
 */
export class AdminSystemComponent implements OnInit, OnDestroy
{
	private routeSub:any;  // subscription to route observer



    public moFromGroupMain: FormGroup;

    public moFormCtrlUrlMailServer:			FormControl;
    public moFormCtrlMailContact:			FormControl;
    public moFormCtrlMailNoReply:			FormControl;
    public moFormCtrlTypeMailServer:		FormControl;
    public moFormCtrlHostMailServer:		FormControl;
    public moFormCtrlPortMailServer:		FormControl;
    public moFormCtrlSecureMailServer:		FormControl;
    public moFormCtrlUseAccount:			FormControl;
    public moFormCtrlLoginAccount:			FormControl;
	public moFormCtrlPasswordAccount:		FormControl;
	public moFormCtrlNumericEnabled:		FormControl;
	public moFormCtrlSpecialTimeEnabled:	FormControl;
	//public moFormCtrlSpecialTimeArray:	FormControl;

	public moFormCtrlSpecialTimeName:		FormControl;
	public moFormCtrlSpecialTimeType:		FormControl;

    public moAdminSubscription:	any;
    public mbShowItemGeneral		= true;
	public mbShowItemProcessus      = false;
	public mbShowItemMail			= false;

    public urlMailServer:	any;
    public mailContact:		any;
    public mailNoReply:		any;

    public hostMailServer:		any;
    public portMailServer:		any;
    public typeMailServer:		string	= 'postfix';
    public secureMailServer:	boolean = false;


    public useAccount:		boolean = false;
    public loginAccount:	string	= '';
    public passwordAccount: string	= '';

    public mainParametersUpdated: boolean = false;

    public typesMailsServer: any[] = [
        {
            value: 'postfix',
            text: 'Postfix'
		},
		{
            value: 'smtp',
            text: 'SMTP'
        }
	];

	public numericEnabled: 		boolean 		= false;
	public specialTimeArray: 	SpecialTime[] 	= [];
	public specialTimeEnabled: 	boolean 		= false;

	public scenariosAvailable:		any[] = [];
	public mailsTemplatesAvailable: any[] = [];

    public titleScenarioSelected:		any;
	public scenarioSelected:			any;
	public titleMailTemplateSelected:	any;
	public mailTemplateSelected:		any;
	public quill:						any;

	public specialTimeName:		string 	= '';
	public specialTimeType:		string 	= '';
	public canValidParams: 		boolean = false;
	public RouterSubscription: 	any;

	quillModules =
	{
		toolbar:
		{
			container: [
				[{ 'font': [''] }],
				[{ 'size': ['small', false, 'large', 'huge'] }],
				['bold', 'italic', 'underline', 'strike'],
				[{ 'header': 1 }, { 'header': 2 }],
				[{ 'color': [''] }, { 'background': [''] }],
				[{ 'list': 'ordered' }, { 'list': 'bullet' }],
				[{ 'align': [''] }]
			]
		},
//		imageResize: true,
		formula: true
	};

	loading = true;

	/**
	 *
	 * @param dialog
	 * @param adminService
	 * @param adminData
	 * @param fb
	 */
    constructor(public dialog:			MatDialog,
                public adminService:	AdminService,
                public adminData:		AdminData,
				public router:			Router,
                public fb:				FormBuilder)
    {
        this.mainParametersUpdated = false;
		this.adminService.getAllAdminParameters();
		
		let isFirst = true;
		this.RouterSubscription = router.events.subscribe((val) => 
		{
			if (val instanceof NavigationStart && isFirst && this.moFromGroupMain.dirty) 
			{
				let result = confirm("Voulez vous vraiment quitter cette page ? Des paramètres n'ont pas été enregistrés et vont être perdus");
				if(!result)
				{
					router.navigateByUrl('/admin');
				}
				else
				{
					isFirst = false;
					this.router.navigate([val.url]); 
				}
			}


		});
	}
	
/*
	
	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event: any)
	{

	}
	@HostListener('window:unload', ['$event']) 
	unloadHandler(event: any) { 
	} 

	@HostListener('window:beforeunload', ['$event'])
	onBeforeUnload(event: any)
	{
		
		let result = confirm('ok');
		if(result)
		{
			event.preventDefault();
		}
	}
*/
	 
	/**
	 *
	 */
    public ngOnInit()
	{
        this.moAdminSubscription = this.adminData.adminEmitter.subscribe((value: any) =>
		{
            if (value === 'admin::parameters::read')
			{
                this._initParameters();
            }

            if (value === 'admin::parameters::update')
			{
                this._initParameters();

                //On a mis à jour des informations dans la fenètre générale
                if (this.mbShowItemGeneral === true)
                {
                    this.mainParametersUpdated = true;
                }
			}

				if (typeof value === 'object' && value.length > 1)
				{
					if (value[0] === 'admin::scenario::delete')
					{
						const result = value[1];
						const scenarioToDelete = this.scenariosAvailable.find((scenario: any) =>
						{
							return scenario.id === result.idScenario;
						});

						if (result.deleted === true)
						{
							this.scenariosAvailable = _.filter(this.scenariosAvailable, (scenario: any) =>
							{
								return scenario.id !== result.idScenario;
							});

							this.scenarioSelected				= this.scenariosAvailable[0];
							this.titleScenarioSelected			= this.scenarioSelected.title;
							this.adminData.scenariosAvailable	= this.scenariosAvailable;
						}

						this.dialog.open(InfosDeleteProcessusModalComponent,
							{
								height:			'30%',
								panelClass:		'responsive_modal',
								width:			'40%',
								disableClose:	true,
								data:
								{
									deleted:		result.deleted,
									scenarioUsed:	result.scenarioUsed,
									scenario:		scenarioToDelete
								}
							});
					}
				}
		});


        this.moFormCtrlUrlMailServer		= this.fb.control(this.urlMailServer);
        this.moFormCtrlMailContact			= this.fb.control(this.mailContact);
        this.moFormCtrlMailNoReply			= this.fb.control(this.mailNoReply);
        this.moFormCtrlTypeMailServer		= this.fb.control(this.typeMailServer);
        this.moFormCtrlHostMailServer		= this.fb.control(this.hostMailServer);
        this.moFormCtrlPortMailServer		= this.fb.control(this.portMailServer);
        this.moFormCtrlSecureMailServer 	= this.fb.control(this.secureMailServer);
        this.moFormCtrlUseAccount			= this.fb.control(this.useAccount);
        this.moFormCtrlLoginAccount			= this.fb.control(this.loginAccount);
		this.moFormCtrlPasswordAccount		= this.fb.control(this.passwordAccount);
		this.moFormCtrlNumericEnabled		= this.fb.control(this.numericEnabled);
		this.moFormCtrlSpecialTimeEnabled	= this.fb.control(this.specialTimeEnabled);
		// this.moFormCtrlSpecialTimeArray		= this.fb.control(this.specialTimeArray);
		this.moFormCtrlSpecialTimeName		= this.fb.control(this.specialTimeName);
		this.moFormCtrlSpecialTimeType		= this.fb.control(this.specialTimeType);


        this.moFromGroupMain = this.fb.group(
		{
			urlMailServer:			this.moFormCtrlUrlMailServer,
			mailContact:			this.moFormCtrlMailContact,
			mailNoReply:			this.moFormCtrlMailNoReply,
			typeMailServer:			this.moFormCtrlTypeMailServer,
			hostMailServer:			this.moFormCtrlHostMailServer,
			portMailServer:			this.moFormCtrlPortMailServer,
			secureMailServer:		this.moFormCtrlSecureMailServer,
			useAccount:		    	this.moFormCtrlUseAccount,
			loginAccount:			this.moFormCtrlLoginAccount,
			passwordAccount:		this.moFormCtrlPasswordAccount,
			numericEnabled:			this.moFormCtrlNumericEnabled,
			specialTimeEnabled:		this.moFormCtrlSpecialTimeEnabled,
			specialTimeName:		this.moFormCtrlSpecialTimeName,
			specialTimeType:		this.moFormCtrlSpecialTimeType
		});
	}


	/**
	 *
	 */
    public ngOnDestroy()
	{
        if (this.moAdminSubscription)
		{
			this.moAdminSubscription.unsubscribe();
			this.RouterSubscription.unsubscribe();
		}
    }


	/**
	 *
	 */
	public addSpecialTime()
	{

		if ( this.specialTimeName !== '' && this.specialTimeType !== '')
		{
			let nNewId = 0;

			for (let specialTime of this.specialTimeArray)
			{
				if(nNewId <= specialTime.id)
				{
					nNewId = specialTime.id + 1; 
				}
			}

			let bIsUnique = true;
			
			for (let specialTime of this.specialTimeArray)
			{
				if(this.specialTimeType === specialTime.type)
				{
					bIsUnique = false;
				}
			}

			if (bIsUnique)
			{
				const oSpecialTime: SpecialTime = new SpecialTime(nNewId, this.specialTimeName, this.specialTimeType);

				this.specialTimeArray.push(oSpecialTime);
				this.specialTimeName 	= '';
				this.specialTimeType 	= ''; 
				this.canValidParams 	= true;
			}
			else
			{
				this.specialTimeName 	= '';
				this.specialTimeType 	= '';
			}

		}
		else
		{
		}
	} 


	showSpecialTimeAlert()
	{
		let bReturn = false;

		for (let specialTime of this.specialTimeArray)
		{
			if(this.specialTimeType === specialTime.type)
			{
				bReturn = true;
			}
		}
		return bReturn;

	}

	public canAddSpecialTime()
	{
		let bReturn = true;

		if(!this.specialTimeName || !this.specialTimeType)
		{
			bReturn = false;
		}

		
		for (let specialTime of this.specialTimeArray)
		{
			if(this.specialTimeType === specialTime.type)
			{
				bReturn = false;
			}
		}

		return bReturn;
	}


	/**
	 *
	 */
	public delSpecialTime(psId: number)
	{
		let newSpecialTimeArray: SpecialTime[] = [];
		for (let specialTime of this.specialTimeArray)
		{
			if (specialTime.id !== psId)
			{
				newSpecialTimeArray.push(specialTime);
			} 
		}
		this.specialTimeArray = newSpecialTimeArray;
		this.canValidParams = true;
	}


	/**
	 *
	 * @param tabName
	 */
    public switch_tab(tabName: any)
    {
        this.mbShowItemGeneral		= false;
		this.mbShowItemProcessus	= false;
		this.mbShowItemMail			= false;
		this.mainParametersUpdated	= false;

        switch (tabName)
		{
			case 'general':
				this.mbShowItemGeneral = true;
				break;
            case 'processus':
				this.mbShowItemProcessus = true;
				break;
			case 'mail':
				this.mbShowItemMail = true;
				//Initialisation des templates de mail
				this.initMailsTemplates();
				break;

			default:
				break;
		}
    }


	/**
	 *
	 */
    public updateMainParameters()
    {
        let datas: any = {};

        if (this.moFormCtrlUrlMailServer.dirty)			datas['domaine']			= htmlEncode.htmlEncode(this.urlMailServer);
        if (this.moFormCtrlMailContact.dirty)			datas['@admin']				= htmlEncode.htmlEncode(this.mailContact);
        if (this.moFormCtrlMailNoReply.dirty)			datas['@sys']				= htmlEncode.htmlEncode(this.mailNoReply);
        if (this.moFormCtrlTypeMailServer.dirty)		datas['typemailserver']		= htmlEncode.htmlEncode(this.typeMailServer);
        if (this.moFormCtrlHostMailServer.dirty)		datas['hostmailserver']		= htmlEncode.htmlEncode(this.hostMailServer);
        if (this.moFormCtrlPortMailServer.dirty)		datas['portmailserver']		= htmlEncode.htmlEncode(this.portMailServer);
        if (this.moFormCtrlSecureMailServer.dirty)		datas['secure']				= this.secureMailServer ? 1 : 0;
        if (this.moFormCtrlUseAccount.dirty)			datas['useaccount']			= this.useAccount		? 1 : 0;
        if (this.moFormCtrlLoginAccount.dirty)			datas['loginaccount']		= htmlEncode.htmlEncode(this.loginAccount);
		if (this.moFormCtrlPasswordAccount.dirty)		datas['passwdaccount']		= htmlEncode.htmlEncode(this.passwordAccount);
		if (this.moFormCtrlNumericEnabled.dirty)		datas['numericEnabled']		= this.numericEnabled ? 1 : 0;
		if (this.moFormCtrlSpecialTimeEnabled.dirty)	datas['specialTimeEnabled']	= this.specialTimeEnabled ? 1 : 0;

		const specialTimeArrayJson: any[] = [];


		for (const specialTime of this.specialTimeArray)
		{
			specialTimeArrayJson.push(specialTime.toJson());
		}

		if(specialTimeArrayJson.length > 0)
		{
			datas['specialTimeArray']	= JSON.stringify(specialTimeArrayJson);
		}
		else
		{
			datas['specialTimeArray'] = {};
		}

		// if (this.moFormCtrlSpecialtimeEnabled.dirty)	datas['specialtimeEnabled']	= this.specialtimeEnabled ? 1 : 0;

		this.adminService.updateAdminParameters(datas);
		this.canValidParams = false;
    }



	/**
	 *
	 */
    public createNewProcess()
	{
		this.dialog.open(EditionProcessusModalComponent,
		{
			height: 		'80%',
			panelClass: 	'responsive_modal',
			width: 			'75%',
			disableClose: 	true,
		});
	}


	/**
	 *
	 */
	public canDeleteCurrentProcess()
	{
		let result = true;

		if (!this.scenarioSelected || this.scenarioSelected.id === 0)
		{
			result = false;
		}

		return result;
	}


	/**
	 *
	 */
	public deleteCurrentProcess()
	{
		const dialogRef = this.dialog.open(ConfirmDeleteProcessusModalComponent,
		{
				height:			'30%',
				panelClass:		'responsive_modal',
				width:			'40%',
				disableClose:	true,
				data:
				{
					scenario: this.scenarioSelected
				}
			});

		dialogRef.afterClosed().subscribe((result: any) =>
		{
			if (result === true)
			{
				this.adminService.deleteScenario(this.scenarioSelected.id);
			}
		});
	}


	/**
	 *
	 */
    public changeScenarioSelected()
    {
        this.scenarioSelected = _.find(this.scenariosAvailable, (scenario: any) => { return scenario.title === this.titleScenarioSelected; });
	}


	/**
	 *
	 * @param event
	 */
	public changeTemplateMailSelected(event: any)
    {
		this.titleMailTemplateSelected	= event;
        this.mailTemplateSelected		= _.find(this.mailsTemplatesAvailable, (template: any) => { return template.title === this.titleMailTemplateSelected; });
	}


	/**
	 *
	 * @param event
	 */
	public onEditorCreated(event: any)
	{
		this.quill = event;
	}


	/**
	 *
	 * @param jeton
	 */
	public onClickJeton(jeton: any)
	{
		const range = this.quill.getSelection();
		if (range)
		{
			if (range.length === 0)
			{
				this.quill.insertText(range.index, '{{' + jeton + '}}');
			}
			else
			{
				this.quill.deleteText(range.index,  range.length);
				this.quill.insertText(range.index, '{{' + jeton + '}}');
			}

			const elementContentQuill = document.getElementsByClassName('ql-editor');

			if (elementContentQuill.length > 0)
			{
				const element							= elementContentQuill[0];
				this.mailTemplateSelected.datas.content = element.innerHTML;
			}
		}
		else
		{
			const elementTitleTemplate: any = document.getElementById('titleTemplate');
			const startPosition:		any = elementTitleTemplate['selectionStart'];
			const endPosition:			any = elementTitleTemplate['selectionEnd'];

			if (this.mailTemplateSelected.datas.title)
			{
				const substr1 = this.mailTemplateSelected.datas.title.substring(0, startPosition);
				const substr2 = this.mailTemplateSelected.datas.title.substring(endPosition);

				this.mailTemplateSelected.datas.title = substr1 + '{{' + jeton + '}}' + substr2;
			}
		}
	}


	/**
	 * Initialisation des modèles de mail telles qu'ils sont en base de données
	 */
	public initMailsTemplates()
	{
		if (this.adminData.mailsTemplates && this.adminData.mailsTemplates.length > 0)
		{
			this.mailsTemplatesAvailable = [];

			for (const template of this.adminData.mailsTemplates)
			{
				const tempTemplate = Object.assign({}, template);

				if (template.datas)
				{
					tempTemplate.datas = Object.assign({}, template.datas);
				}

				this.mailsTemplatesAvailable.push(tempTemplate);
			}

			this.mailTemplateSelected		= this.mailsTemplatesAvailable[0];
			this.titleMailTemplateSelected	= this.mailTemplateSelected.title;
		}
	}


	/**
	 * Sauvegarde des modèles de mail
	 */
	public saveMailsTemplates()
	{
		this.adminData.mailsTemplates = [];

		for (const template of this.mailsTemplatesAvailable)
		{
			this.adminData.mailsTemplates.push(Object.assign({}, template));
		}

		this.adminService.updateAdminMailsTemplates(this.adminData.mailsTemplates);
	}


	/**
	 *
	 */
    private _initParameters()
    {
        //Initialisation des paramètres généraux
        if (this.adminData.generalParameters)
        {
            this.urlMailServer			= this.adminData.generalParameters['domain'];
            this.mailContact			= this.adminData.generalParameters['mailAdmin'];
            this.mailNoReply			= this.adminData.generalParameters['mailSystem'];
            this.typeMailServer			= this.adminData.generalParameters['typeMailServer'];
            this.hostMailServer			= this.adminData.generalParameters['hostMailServer'];
            this.portMailServer			= this.adminData.generalParameters['portMailServer'];
            this.secureMailServer		= Number(this.adminData.generalParameters['secure'])				=== 1;
            this.useAccount				= Number(this.adminData.generalParameters['useAccount'])			=== 1;
            this.loginAccount			= this.adminData.generalParameters['loginAccount'];
			this.passwordAccount		= this.adminData.generalParameters['passwdAccount'];
			this.numericEnabled			= Number(this.adminData.generalParameters['numericEnabled']) 		=== 1;
			this.specialTimeEnabled		= Number(this.adminData.generalParameters['specialTimeEnabled']) 	=== 1;
			this.specialTimeArray		= this.adminData.generalParameters['specialTimeArray'];

			// todo
			//this.specialTimeEnabledArray		= Number(this.adminData.generalParameters['specialTimeEnabled']) 	=== 1;
        }

        //Initialisation des scénarios
        if (this.adminData.scenariosAvailable && this.adminData.scenariosAvailable.length > 0)
        {
            this.scenarioSelected		= this.adminData.scenariosAvailable[0];
            this.titleScenarioSelected	= this.scenarioSelected.title;
            this.scenariosAvailable		= this.adminData.scenariosAvailable;
		}
	}
}
