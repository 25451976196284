<div class="dialog_form">
	<div class="dialog_grid_container">
		<div class="dialog_header">
			<div class="dialog_header_title">
				{{ 'convertExistingDocument.title' | translate }}
			</div>
			<div class="dialog_header_close">
				<div class="dialog_header_close_icon" (click)='cancel()'></div>
			</div>
		</div>

		<div class="dialog_content">
			<label>
				{{ 'convertExistingDocument.message' | translate }}
			</label>
			<div class="clear"></div>
			<label>
				{{ 'convertExistingDocument.nameField' | translate }}
			</label>
			<mat-input-container class="input">
				<input type="text" matInput (ngModelChange)="onChangeName($event)" [ngModel]="nameFile">
			</mat-input-container>
			<div *ngIf="isCurrentFileNameValid === false">
				<label *ngIf="nameFile && nameFile !== ''" class="form_dirty_input">{{ 'subjectDocumentModal.nameExisting' | translate }}</label>
			</div>
		</div>

		<div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button mat-raised-button type="button" (click)='cancel()'>{{ 'common.cancel' | translate }}</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button mat-raised-button type="button" [disabled]="isCurrentFileNameValid === false" (click)='validate()'>{{ 'common.validate' | translate }}</button>
			</div>
		</div>
	</div>
</div>
