import { Component, Input, ViewChild, ElementRef, HostListener, AfterViewInit, AfterViewChecked, ViewEncapsulation } from '@angular/core';

declare var Caman: any;

@Component({
	selector:		'app-canvas',
	templateUrl:	'./canvas.component.html',
	encapsulation:	ViewEncapsulation.None,
	styleUrls:		['./canvas.component.scss']
})


/**
 * 
 */
export class CanvasComponent implements AfterViewInit, AfterViewChecked
{

	@ViewChild('img') img:		ElementRef<any>;
	@Input() src:				string;
	@Input() containerWidth:	number;
	@Input() containerHeight:	number;

	@Input()
    set canvasId(canvasId: any)
    {
        this.idCanvas = canvasId;
    }

	public ctx:					CanvasRenderingContext2D;
	public element:				HTMLImageElement;
	public elementOriginal:		HTMLImageElement;

	public canvas:		any;
	public idCanvas:	string;
	public width:		number;
	public height:		number;


	/**
	 * 
	 * @param canvas 
	 * @param img 
	 */
	constructor(canvas: ElementRef<any>, img: ElementRef<any>)
	{
		this.width		= window.innerWidth;
		this.height		= window.innerHeight;
		this.idCanvas	= 'target5';

	}


	/**
	 * 
	 */
	public ngAfterViewInit()
	{
		// ---------------------------------------
		// Récupération des éléments de la page pour transformation
		// ---------------------------------------
		this.canvas	= document.getElementById(this.idCanvas);
		if (this.canvas)
		{
			this.ctx				= this.canvas.getContext('2d');
			this.element			= this.img.nativeElement;
			this.elementOriginal	= this.img.nativeElement;
		}
	}


	/**
	 * Sale => julien's tips
	 */
	public getSrc()
	{

		// ---------------------------------------
		// Récupération de la source de l'image
		// ---------------------------------------
		this.width	= this.img.nativeElement.clientWidth;
		this.height = this.img.nativeElement.clientHeight;
		this.canvas = document.getElementById(this.idCanvas);

		return this.src;
	}


	/**
	 * 
	 */
	public getWidth()
	{
		return this.width;
	}


	/**
	 * 
	 */
	public getHeight()
	{
		return this.height;
	}


	/**
	 * 
	 */
	public ngAfterViewChecked()
	{
		// ---------------------------------------
		// Trigger au changement de l'url de l'image (modif par l'user)
		// ---------------------------------------
		this.img.nativeElement.onload = () =>
		{
			this.canvas		= document.getElementById(this.idCanvas);
			this.element	= this.img.nativeElement;

			this.render();
		};

		this.render();
	}


	@HostListener('window:resize')
	public resize()
	{
		this.render();
	}


	/**
	 * 
	 */
	public clear()
	{
		this.ctx.clearRect(0, 0, this.width, this.height);
	}
	

	/**
	 *
	 */
	public render()
	{
		// ---------------------------------------
		// Une fois l'image découpé on la place dans le canvas pour la découpe
		// ---------------------------------------
		try
		{
			this.canvas		= document.getElementById(this.idCanvas);

			if (this.canvas)
			{
				this.ctx		= this.canvas.getContext('2d');
				this.clear();

				if (this.containerWidth && this.containerHeight)
				{
					let coeffResize;
					let newX = 0;
					let newY = 0;

					let realContainerWidth;
					let realContainerHeight;

					realContainerWidth	= this.containerWidth	> this.canvas.width		? this.canvas.width		: this.containerWidth;
					realContainerHeight = this.containerHeight	> this.canvas.height	? this.canvas.height	: this.containerHeight;

					if (this.width > this.height)
					{
						coeffResize = this.width / realContainerWidth;
					}
					else
					{
						coeffResize = this.height / realContainerHeight;
					}

					this.width	= Math.round(this.width / coeffResize);
					this.height = Math.round(this.height / coeffResize);

					newX = Math.round((realContainerWidth	- this.width)	/ 2);
					newY = Math.round((realContainerHeight	- this.height)	/ 2);

					newX = newX > 0 ? newX : 0;
					newY = newY > 0 ? newY : 0;

					this.ctx.drawImage(this.element, newX, newY, this.width, this.height);
				}
				else
				{
					this.ctx.drawImage(this.element, 0, 0, this.width, this.height);
				}
			}
		}
		catch(error) 
		{
			console.error(error);
		}
	}
}