<div class="name_component_block">
	<span class="name_content" (click)="onClick($event);">{{outputContent}}</span>


	<div class="name_component_infobulle"  *ngIf="oUser && showBubble">
		<div class="name_component_infobulle_item" *ngIf="oUser.infos && !showMail">
			{{oUser.infos.nom}} {{oUser.infos.prenom}}
		</div>
		<div class="name_component_infobulle_item" *ngIf="oUser.infos && showMail">
			{{userMail}}
		</div>
		<div class="clear"></div>
		<div class="name_component_infobulle_item">
			{{oUser.mail}}
		</div>
	</div>
	<div class="clear"></div>

</div>