import { Sujet }		from '../../classes/sujet.class';
import { Comment }      from '../../classes/comment.class';
import { Injectable }	from '@angular/core';
import { Http, Headers }from '@angular/http';

import { CreationHttpService }	from './creationHttp.service';
import { DataHttpService }		from './dataHttp.service';
import { UserData }				from '../../providers/user-data';


@Injectable()


/**
 *
 */
export class SujetHttpService extends DataHttpService
{
	protected sToken:			string = 'idsubject';
	protected sParentToken:		string = 'idepreuve';

	constructor(protected http: Http, protected userData: UserData)
	{
		super(http, userData, CreationHttpService.getPathByWebServiceName('sujet'));

	}


    /**
     * Add new sujet in database from Sujet object given in parameter
     */
	public addSujet(sujet: Sujet)
	{
		let data =
			{
				name:		sujet.name,
				idepreuve:	sujet.idMatiere,
				type:		sujet.type,
				note:		sujet.note,
				path:		['']
			};

		return this.add(sujet, undefined, data);
	}


    /**
     * Get all subjects in database relative to user account and parameters given:
     *
     *  -idSujet:    if this parameter is defined, return the Sujet object corresponding to the value id
     *  -idEpreuve:  if this parameter is defined (and if the parameter idSujet is not), return the Sujet
     *               objects wich the epreuve parent matches the value id.
     *
     * Return a list of Sujet objects
     */
	public getSujets(idSujet: string, idEpreuve: string)
	{
		return this.get(idSujet, idEpreuve);
	}


	/**
	 * Add new comment to subject in database.
	 *
	 * @param sujet: Sujet object in wich the comment will be added
	 * @param comment: Comment object corresponding to the comment to add in database
	 */
	public addCommentToSujet(sujet: Sujet, comment: Comment)
	{
		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		let datas = 
			{
				subject: sujet.toJson(),
				comment: comment.toJson()
			};

		let credentials = 
			{
				data:	datas
			};

		return this.http.post(this.getUrl() + 'addComment', JSON.stringify(credentials), { headers: headers });
	}

	/**
	 * Add note to subject in database
	 *
	 * @param sujet: Sujet object in wich the note will be added
	 * @param note: note wich will be added in database to given subject
	 */
	public addNoteToSujet(sujet: Sujet, note: string)
	{
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + this.userData.getTokenKey());

		// ---------------------------------------
		// ToComment
		// ---------------------------------------
		let datas = 
			{
				subject:	sujet.toJson(),
				note:		note
			};
		let credentials = 
			{
				data:	datas
			};

		return this.http.post(this.getUrl() + 'addNote', JSON.stringify(credentials), { headers: headers });
	}
}
