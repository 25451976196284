<div class="tree-view" >
    <div class="dialog_menu">
        <div (click)="switch_tab('general')" [class.dialog_menu_selected]="mbShowItemGeneral" class="dialog_menu_block" id="dialog_menu_button_general">
            {{ 'adminSystem.general' | translate }}
        </div>
        <div (click)="switch_tab('processus')" [class.dialog_menu_selected]="mbShowItemProcessus" class="dialog_menu_block" id="dialog_menu_button_general">
            {{ 'adminSystem.process' | translate }}
		</div>
		<div (click)="switch_tab('mail')" [class.dialog_menu_selected]="mbShowItemMail" class="dialog_menu_block" id="dialog_menu_button_general">
            {{ 'adminSystem.mail' | translate }}
        </div>
    </div>
</div>

<div class="content_view">
    <div class="inser-info-container-system">
        <form class="form_section" (ngSubmit)="updateMainParameters()" [formGroup]="moFromGroupMain" [hidden]="!mbShowItemGeneral">
			<div class="confirm_block confirm_block_first">
				<button mat-raised-button class="confirm_block_button confirm_block_first"  type="submit" [disabled]="!moFromGroupMain.dirty && !canValidParams">{{ 'adminSystem.updateParameters' | translate }}</button>
			</div>

			<!-------------------------------- Activation du mode numérique -------------------------------->
			<h3>{{ 'adminSystem.isNumeric' | translate }}</h3>
			<div class="form_section_block">

				<div class="value-info">
					<mat-slide-toggle formControlName="numericEnabled" [(ngModel)]="numericEnabled">
						<span *ngIf="numericEnabled === true">{{ 'common.enabled' | translate }}</span>
						<span *ngIf="numericEnabled === false">{{ 'common.disabled' | translate }}</span>
					</mat-slide-toggle>
				</div>
			</div>


			<!-------------------------------- Gestion des temps aménagés -------------------------------->
			<h3>Temps aménagés</h3>
			<div class="form_section_block">
				<div class="value-info">
					<mat-slide-toggle formControlName="specialTimeEnabled" [(ngModel)]="specialTimeEnabled">
						<span *ngIf="specialTimeEnabled === true">{{ 'common.enabled' | translate }}</span>
						<span *ngIf="specialTimeEnabled === false">{{ 'common.disabled' | translate }}</span>
					</mat-slide-toggle>
				</div>

				<div *ngIf="specialTimeEnabled" class="form_section_block">
					<div *ngFor="let specialTimeItem of specialTimeArray">
						<div  class="form_section_block" *ngIf="specialTimeItem && specialTimeItem.name && specialTimeItem.type">
							Nom: {{specialTimeItem.name}} - Type: {{specialTimeItem.type}} <button mat-raised-button (click)="delSpecialTime(specialTimeItem.id)">Supprimer</button>
						</div>
					</div>

					<mat-form-field>
						<input matInput [(ngModel)]="specialTimeName" formControlName="specialTimeName" placeholder="Nom">
					</mat-form-field>
					<mat-form-field class="specialTimeType">
						<input matInput [(ngModel)]="specialTimeType" formControlName="specialTimeType"  placeholder="Type">
						<div class="specialTimeAlert" *ngIf="showSpecialTimeAlert()">Le type du temps aménagés doit être unique</div>
					</mat-form-field>
					<button mat-raised-button [disabled]="!canAddSpecialTime() || showSpecialTimeAlert()" (click)="addSpecialTime()">Ajouter</button>
					<div class="clear"></div>
				</div>
			</div>


			<!-------------------------------- Infos serveur -------------------------------->
			<h3>{{ 'adminSystem.server' | translate }}</h3>
			<div class="form_section_block">
				<div class="clear"></div>
				<div class="label-info">
					<label>{{ 'adminSystem.externalAdress' | translate }}</label>
				</div>
				<div class="value-info">
					<mat-form-field>
						<input  maxLength="80" matInput formControlName="urlMailServer" [(ngModel)]="urlMailServer">
					</mat-form-field>
				</div>
				<div class="clear"></div>
				<div class="label-info">
					<label>{{ 'adminSystem.contactMail' | translate }}</label>
				</div>
				<div class="value-info">
					<mat-form-field>
						<input maxLength="80"  matInput formControlName="mailContact" [(ngModel)]="mailContact">
					</mat-form-field>
				</div>
				<div class="clear"></div>
				<div class="label-info">
					<label>{{ 'adminSystem.noReplyMail' | translate }}</label>
				</div>
				<div class="value-info">
					<mat-form-field>
						<input  maxLength="80" matInput formControlName="mailNoReply" [(ngModel)]="mailNoReply">
					</mat-form-field>
				</div>
				<div class="clear"></div>
			</div>
			<h3>{{ 'adminSystem.mailServer' | translate }}</h3>

			<div class="form_section_block">
				<div class="clear"></div>
				<div class="label-info">
					<label>{{ 'adminSystem.type' | translate }}</label>
				</div>
				<div class="value-info">
					<mat-form-field>
						<mat-select formControlName="typeMailServer" [(ngModel)]="typeMailServer">
							<mat-option *ngFor="let type of typesMailsServer" [value]="type.value">
								{{ type.text }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

			<div class="clear"></div>

			<div class="label-info">
				<label>{{ 'adminSystem.host' | translate }}</label>
			</div>
			<div class="value-info">
				<mat-form-field>
					<input maxLength="80" matInput formControlName="hostMailServer" [(ngModel)]="hostMailServer">
				</mat-form-field>
			</div>
			<div class="clear"></div>
			<div class="label-info">
				<label>{{ 'adminSystem.port' | translate }}</label>
			</div>
			<div class="value-info">
				<mat-form-field>
					<input maxLength="80" matInput formControlName="portMailServer" [(ngModel)]="portMailServer">
				</mat-form-field>
			</div>
			<div class="clear"></div>
			<div class="value-info">
				<mat-checkbox formControlName="secureMailServer" [(ngModel)]="secureMailServer">le serveur est-il {{ 'adminSystem.securised' | translate }} ?</mat-checkbox>
			</div>
		</div>
		<div class="clear"></div>


					<!-- ------------------------------ Se connecter au compte ------------------------------ -->

<!--
		<div class="value-info">
			<mat-checkbox formControlName="useAccount" [(ngModel)]="useAccount">{{ 'adminSystem.useAccount' | translate }}</mat-checkbox>
		</div>

		<div class="clear"></div>

		<div *ngIf="useAccount === true" class="form_section_block">

			<div class="label-info">
				<label>{{ 'adminSystem.login' | translate }}</label>
			</div>

			<div class="value-info">
				<mat-form-field>
					<input  maxLength="80" matInput autocomplete="new-password" formControlName="loginAccount" [(ngModel)]="loginAccount">
				</mat-form-field>
			</div>

			<div class="clear"></div>

			<div class="label-info">
				<label>{{ 'adminSystem.password' | translate }}</label>
			</div>

			<div class="value-info">
				<mat-form-field>
					<input  maxLength="80" type="password" matInput autocomplete="new-password" formControlName="passwordAccount" [(ngModel)]="passwordAccount">
				</mat-form-field>
			</div>

			<div class="clear"></div>
		</div>
		-->
					<!-------------------------------- Valider -------------------------------->
		<div class="confirm_block">
			<button mat-raised-button class="confirm_block_button"  type="submit" [disabled]="!moFromGroupMain.dirty && !canValidParams">{{ 'adminSystem.updateParameters' | translate }}</button>
		</div>
		<div class="clear"></div>
		<div>
			<label *ngIf="mainParametersUpdated === true">{{ 'adminSystem.updateDone' | translate }}</label>
		</div>
		<div class="clear"></div>
	</form>

	<div class="form_section" [hidden]="!mbShowItemProcessus">
					<!-------------------------------- Template de mail -------------------------------->

		<h3>{{ 'adminSystem.scenarios' | translate }}</h3>
		<div class="form_section_block">
			<div class="value-info">
				<mat-form-field>
					<mat-select [(ngModel)]="titleScenarioSelected" (ngModelChange)="changeScenarioSelected()">
						<mat-option *ngFor="let scenario of scenariosAvailable" [value]="scenario.title">
							{{ scenario.title }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="button-add-process">
			<button mat-raised-button type="button" (click)='createNewProcess()'>{{ 'adminSystem.createNewScenario' | translate }}</button>
			<button mat-raised-button type="button" *ngIf="canDeleteCurrentProcess()" (click)='deleteCurrentProcess()'>{{ 'adminSystem.deleteCurrentScenario' | translate }}</button>
		</div>
		<div class="clear"></div>
	</div>

	<div *ngIf="scenarioSelected">
		<div class="form_section_block">
			<process-editor [readOnly]="'true'" [scenario]="scenarioSelected"></process-editor>
		</div>
	</div>
</div>

<div class="form_section" [hidden]="!mbShowItemMail">
	<h3>{{ 'adminSystem.mailsTemplates' | translate }}</h3>
	<div class="form_section_block">
		<mat-form-field>
			<mat-select [ngModel]="titleMailTemplateSelected" (ngModelChange)="changeTemplateMailSelected($event)">
				<mat-option *ngFor="let mailTemplate of mailsTemplatesAvailable" [value]="mailTemplate.title">
					{{ mailTemplate.title }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<div class="clear"></div>

<div *ngIf="mailTemplateSelected">
	<h3>{{ 'adminSystem.jetonsAvailables' | translate }}</h3>
	<div class="form_section_block">
		<span *ngFor="let jetons of mailTemplateSelected.datas.jetons">
			<button mat-raised-button type="button" (click)="onClickJeton(jetons)" class="mail_token_button">{{ jetons }}</button>
		</span>
	</div>
</div>

<div class="clear"></div>
<div class="" *ngIf="mailTemplateSelected && mailTemplateSelected.datas && mailTemplateSelected.datas.title">
	<h3>{{ 'adminSystem.titleMailTemplate' | translate }}</h3>
	<div class="form_section_block">
		<mat-form-field class="title-mail-template">
			<input matInput id="titleTemplate" [(ngModel)]="mailTemplateSelected.datas.title">
		</mat-form-field>
	</div>
</div>

<div class="clear"></div>
<div class="form_section_quill">

	<div _ngcontent-c55="" class="quill_container" *ngIf="mailTemplateSelected && mailTemplateSelected.datas">
		 <div _ngcontent-c55="" class="quill_container_inner">
			<quill-editor [modules]="quillModules" [required]="true" (onEditorCreated)="onEditorCreated($event);" placeholder="Contenu du modèle" [(ngModel)]="mailTemplateSelected.datas.content" [ngModelOptions]="{standalone: true}"></quill-editor>
		</div>
	</div>
</div>

<div class="clear"></div>
<div class="confirm_block">
	<!--<button mat-raised-button type="submit" (click)="initMailsTemplates()">{{ 'adminSystem.cancelUpdate' | translate }}</button>-->
	<button class="confirm_block_button" mat-raised-button type="submit" (click)="saveMailsTemplates()">Valider les modifications</button>
</div>
<div class="clear"></div>

</div>

</div>
