import { Member }			from './member.class';

/**
 * Root component for the class application.
 * Manage id, name, and event with socket (to know when its locked, and modified).
 * @export
 * @class Component
 */
export class Component
{
	public _id:					Member	= new Member();
	public _name:				Member	= new Member();
    public _description:		Member	= new Member();
    public _dateCreation:		Date	= null;
    public _dateModification:	Date	= null;
	
	
//	public _sanitizer: DomSanitizer;
    
	/**
	 * From a json object, its construct the three of object
	 * @static
	 * @param {any} jsonObj
	 * @returns {Any}
	 */
	public static fromJsonObject(jsonObj: any)
	{

	}
	
    /**
     * Creates an instance of Component.
     * 
     * @param {number} [_id=0]
     * @param {string} [_name='']
     * 
     * @memberOf Component
     */
	constructor()
	{
		// ---------------------------------------
		// ID
		// ---------------------------------------
		this._id.msName			= 'uuid';
		this._id.msType			= 'input';
		this._id.msNgModel		= 'nomEtab';
		this._id.mbRequired		= true;
		this._id.mbAutoComplete = false;
		this._id.mbHidden		= true;
		
		
		// ---------------------------------------
		// Nom
		// ---------------------------------------
		this._name.msName			= 'Nom';
		this._name.msType			= 'input';
		this._name.msType			= 'input';
		this._name.msId				= 'etab_form_input_name';
		this._name.msClass			= 'etab_form_input_name';
		this._name.msNgModel		= 'descriptionEtab';
		this._name.mbRequired		= true;
		this._name.msPlaceholder	= 'Nom établissement';
		this._name.mbAutoComplete	= false;
		this._name.mbHidden			= false;

						
		// ---------------------------------------
		// Description
		// ---------------------------------------
		this._description.msName			= 'Description';
		this._description.msType			= 'textarea';
		this._description.mbRequired		= true;
		this._description.mbAutoComplete	= false;
		this._description.mbHidden			= false;
	}
	

    /**
     * Basic getter for the id
     * @type {number}
     * @memberOf Component
     */
    get id(): string { return this._id.msValue; }


    /**
     * Basic getter for the name
     * @type {string}
     * @memberOf Component
     */
    get name(): string { return this._name.msValue; }

    /**
     * Basic getter for the description
     * @type {string}
     * @memberOf Component
     */
    get description(): string { return this._description.msValue; }


    /**
     * Basic getter for the date creation
     * @type {Date}
     * @memberOf Component
     */
    get dateCreation(): Date { return this._dateCreation; }

    
    /**
     * Basic getter for last modification
     * @type {Date}
     * @memberOf Component
     */
    get dateModification(): Date { return this._dateModification; }

    /**
     * Basic setter for the id
     * @memberOf Component
     */
	set id(id) 
	{
		this._id.msValue = id;
	}


    /**
     * Basic setter for the name
     * @memberOf Component
     */
    set name(name) { this._name.msValue = name; }

    /**
     * Basic setter for the name
     * @memberOf Component
     */
    set description(description) { this._description.msValue = description; }


    /**
     * Basic setter for the date of creation
     * @memberOf Component
     */
    set dateCreation(dateCreation) { this._dateCreation = dateCreation; }


    /**
     * Basic setter for the last modification
     * @memberOf Component
     */
    set dateModification(dateModification) { this._dateModification = dateModification; }


    /**
     * Get the item on data server who correspond by id
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public getItemById(id: any)
	{
	}


    /**
     * get a list of item by the foreign key
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public getItemsByForeignId(id: any, foreignKey: string)
	{
	}


    /**
     * notify to the server, this object is viewed
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public notifyView(id: any)
	{
	}


    /**
     * 
     * Listen for the view of this object
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public listenView(id: any)
	{
	}


    /**
     * notify to the server a modification has been done
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public notifyModification(id: any)
	{
		
	}


    /**
     * get the information of a modification to reload the data
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public listenModification(id: any)
	{

	}


    /**
     * Notify the server an delet has been done
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public notifyDelete(id: any)
	{

	}


    /**
     * Listen for a delet
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public listenDelete(id: any)
	{
		
	}


    /**
     * Notify the server for a new object
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public notifyCreate(id: any)
	{
		
	}


    /**
     * listen for new object
     * @public
     * @param {any} id
     * 
     * @memberOf Component
     */
	public listenCreate(id: any)
	{
	}


	/**
	 * 
	 */
	public canAccess(userId: any, id: any, action: any)
	{
	}
	
	
	public getForm()
	{
		let sReturn:string = '';
		
		sReturn += this._name.getFormInput();
		sReturn += this._description.getFormInput();
		
		return sReturn;
	}
}


