import { Task }	from './task.class';
import { Matiere }	from './matiere.class';
import { File }		from './file.class';
import { Comment }  from './comment.class';
import * as _		from 'underscore';


/**
 *
 * @export
 * @class Sujet
 */
export class Sujet
{

    /**
     *
     *
     * @static
     * @param {any} jsonObj
     * @returns {Sujet}
     *
     * @memberOf Sujet
     */
    static fromJsonObject(jsonObj: any): Sujet
    {

        let oSujet = new Sujet();

        oSujet.id				= jsonObj.idsujet			|| '';
        oSujet.idMatiere		= jsonObj.idepreuve			|| '';
        oSujet.name				= jsonObj.libelle			|| '';
        oSujet.creationDate		= jsonObj.dateCreation		|| '';
        oSujet.lastUpdateDate	= jsonObj.dateLastUpdate	|| '';
        oSujet.location			= jsonObj.location			|| '';
        oSujet.datas			= jsonObj.datas			    || {};
        oSujet.tracks			= jsonObj.tracks			|| [];
        oSujet.type				= jsonObj.type				|| '';
        oSujet.note				= jsonObj.note				|| '';
        oSujet.show				= jsonObj.show				|| '';
        oSujet.updater			= jsonObj.updater			|| '';
        oSujet.flags			= jsonObj.flags			    || [];
        oSujet.fake				= jsonObj.fake			    || [];
        oSujet.tasks			= jsonObj.tasks			    || [];


//		if (jsonObj.pj)
//		{
//			for (let idPj of Object.keys(jsonObj.pj))
//			{
//				if (jsonObj.pj[idPj])
//				{
//					let file		= File.fromJsonObject(jsonObj.pj[idPj]);
//					file.id			= idPj;
//					file.idSubject	= oSujet.id;
//					file.idEpreuve	= oSujet.idMatiere;
//
//					oSujet.pj.push(file);
//				}
//			}
//		}

        //Initialisation des commentaires récupérés
        if (jsonObj.comments && Object.keys(jsonObj.comments).length > 0)
        {
            for (let key in jsonObj.comments)
            {
                if (jsonObj.comments[key])
                {
                    let objComment = Comment.fromJsonObject(jsonObj.comments[key]);
                    oSujet.comments.push(objComment);
                }
            }
        }

        return oSujet;
    }


    /**
     * Creates an instance of Sujet.
     *
     * @param {string} [_name='']
     * @param {string} [_time_start='']
     * @param {string} [_time_end='']
     * @param {string} [_location='']
     * @param {string[]} [_tracks=[]]
     * @param {Exercice[]} [_exercices=[]]
     *
     * @memberOf Sujet
     */
    constructor(
        private _id:				string      = '',
        private _name:				string      = '',
        private _creationDate:		string      = '',
        private _lastUpdateDate:	string      = '',
        private _location:			string      = '',
        private _tracks:			string[]    = [],
        private _idMatiere:			string      = '',
        private _type:				string      = '',
        private _note:				string      = '',
        private _etat:				string      = '',
        private _pj:				File[]		= [],
        private _selected:			boolean		= false,
        private _show:				boolean     = false,
        private _flags:             string[]    = [],
        private _comments:			Comment[]   = [],
        private _fake:				any[]		= [],
        private _updater:			string      = '',
        private _datas:             any         = {},
        private _tasks:             Task[]      = []
    ){ }


    get id()                    { return this._id; }
    get name()                  { return this._name; }
    get creationDate()          { return this._creationDate; }
    get lastUpdateDate()        { return this._lastUpdateDate; }
    get location()              { return this._location; }
    get tracks()                { return this._tracks; }
    get idMatiere()             { return this._idMatiere; }
    get type()                  { return this._type; }
    get note()                  { return this._note; }
    get pj()					{ return this._pj; }
    get selected()				{ return this._selected; }
    get etat()					{ return this._etat; }
    get show()                  { return this._show; }
    get fake()                  { return this._fake; }
    get flags()                 { return this._flags; }
    get comments()              { return this._comments; }
    get updater()               { return this._updater; }
    get datas()                 { return this._datas; }
    get tasks()                 { return this._tasks; }


    set id(id)							{ this._id				= id; }
    set name(name)						{ this._name			= name; }
    set creationDate(creationDate)		{ this._creationDate	= creationDate; }
    set lastUpdateDate(lastUpdateDate)	{ this._lastUpdateDate	= lastUpdateDate; }
    set location(location)				{ this._location		= location; }
    set fake(fake)						{ this._fake			= fake; }
    set tracks(tracks)					{ this._tracks			= tracks; }
    set idMatiere(idMatiere)			{ this._idMatiere		= idMatiere; }
    set type(type)						{ this._type			= type; }
    set note(note)						{ this._note			= note; }
    set etat(etat)						{ this._etat			= etat; }
    set pj(pj)							{ this._pj				= pj; }
    set selected(selected)				{ this._selected		= selected; }
    set show(show)						{ this._show			= show; }
    set flags(flags)				    { this._flags		    = flags; }
    set comments(comments)				{ this._comments		= comments; }
    set updater(updater)				{ this._updater			= updater; }
    set datas(datas)				    { this._datas			= datas; }
    set tasks(tasks)				    { this._tasks			= tasks; }


    /**
     *
     * @param {any} info
     * @returns
     *
     * @memberOf Sujet
     */
    public extractBranchInfos(info: any)
    {

        let infoCopy = Object.assign({}, info);

        let infos = [];
        infoCopy.idSujet        = this.id;
        infoCopy.nameSujet      = this.name;
        infoCopy.locationSujet  = this.location;
        infoCopy.tracksSujet    = this.tracks;
        infoCopy.typeSujet      = this.type;
        infoCopy.noteSujet      = this.note;
        infoCopy.showSujet      = this.show;
        infoCopy.tasks			= this.tasks;
//      infoCopy.pjSujet		= this.pj;

		// ---------------------------------------
		// ToComment
		// ---------------------------------------

        infos.push(infoCopy);

        return infos;
    }


	/**
	 *
	 */
    public toJson()
    {
        const result: any = 
        {
            idsujet:	this.id,
            idepreuve:  this.idMatiere,
            libelle:	this.name,
            location:	this.location,
            tracks:		this.tracks,
            type:		this.type,
            note:		this.note,
            show:		this.show,
            datas:      this.datas,
            flags:      this.flags,
            tasks:      this.tasks
        };

        //Initialisation des commentaires dans la structure json retournée
        if (this.comments.length > 0)
		{
			result.comments = [];
			for (const comment of this.comments)
			{
				result.comments.push(comment.toJson());
			}
		}

        return result;
    }
	/**
	 *
	 */
    public toState()
    {
        let result: any =
			{
				idsujet:	this.id,
				datas:		{etat: this.etat}
			};


        return result;
    }
}
