import { Injectable, EventEmitter, OnDestroy } from '@angular/core';

import { WorkspaceData } from './workspace-data';
import { CandidatService }		from '../services/candidat.service';

import * as _ from 'underscore';



@Injectable()
export class VaultData implements OnDestroy
{

    public vaultEmitter: EventEmitter<any> = new EventEmitter<any>();

    public static rootParentId = 'b1bcd800-1dc9-11b2-8080-808080808080';

    private _concours:				any[]	= [];
    private _nodesById:				any		= {};
    private _candidatsByElement:	any		= {};
    private _candidats:				any		= {
        all: [],
        groups: null
	}

	constructor(public workspaceDate: WorkspaceData, public candidatService: CandidatService)
	{

	}

	ngOnDestroy()
	{
		this.vaultEmitter.unsubscribe();
    }

	get concours(): any[]
	{
		return this._concours;
	}


	getConcourByid(psId: string): any[]
	{

		let aReturn;
		for (const concour of this._concours)
		{
			if (concour.id === psId)
			{
				aReturn = concour;
			}
		}
		return aReturn;
	}


    get candidats():			any[] { return this._candidats; }
    get candidatsByElement():	any[] { return this._candidatsByElement; }



	public addConcours(concours: any)
	{
		if (concours && concours.id && this._nodesById[concours.id] === undefined)
		{
			concours.epreuve		= undefined;
			const epreuveAffected	= this.workspaceDate.getEpreuve(concours.idEpreuve);

			if (epreuveAffected)
			{
				concours.epreuve = epreuveAffected;
			}
			if (!concours.datas)
			{
				concours.datas = {};
			}
			if (concours.datas.candidats)
			{
				try{
					concours.datas.candidats = JSON.parse(concours.datas.candidats);
				}
				catch(e)
				{}
			}
			if (concours.datas.specialTime)
			{
				try{
					concours.datas.specialTime = JSON.parse(concours.datas.specialTime);
				}
				catch(e)
				{}
			}
			else if (!concours.datas || !concours.datas.candidats)
			{
				concours.datas.candidats = [];
			}

			this._nodesById[concours.id] = concours;

			if (concours.datas && concours.datas.datePassationSend)
			{
				concours.passationSend = true;

				if (concours.datas.mustUpdate && (concours.datas.mustUpdate === true || concours.datas.mustUpdate === 'true'))
				{
					concours.mustUpdate = true;
				}
				else
				{
					concours.mustUpdate = false;
				}
			}

			this._concours.push(concours);
			this._concours = _.sortBy(this._concours, 'creationDate');
			this._concours = this.concours.reverse();
		}

	}


	public getNodeById(id: string)
	{
		let result = [];

		if (this._nodesById[id])
		{
			result = this._nodesById[id];
		}

		return result;
	}

	
	public updateNode(nodeId: string, datasUpdated: any)
	{
		if (this._nodesById && this._nodesById[nodeId])
		{

			for (const key in datasUpdated)
			{
				switch (key)
				{
					case 'libelle':
						this._nodesById[nodeId].libelle = datasUpdated[key];
						break;
					case 'passationSend':
						this._nodesById[nodeId].passationSend = datasUpdated[key];
						break;
					case 'mustUpdate':
						this._nodesById[nodeId].mustUpdate = datasUpdated[key];
						break;
					case 'passwordSubjects':
						this._nodesById[nodeId].passwordSubjects = datasUpdated[key];
						break;
					case 'datePassationSend':
						this._nodesById[nodeId].datas.datePassationSend = datasUpdated[key];
						break;
					case 'update_date':
						this._nodesById[nodeId].updateDate = datasUpdated[key];
						break;
					case 'idEpreuve':
						this._nodesById[nodeId].idEpreuve = datasUpdated[key];
						break;
					case 'datas':
						this._nodesById[nodeId].datas = datasUpdated[key];
					case '+datas':
						for (let keyData in datasUpdated[key])
						{
							if (datasUpdated[key][keyData])
							{
								if (keyData === 'baremesMain' || keyData === 'baremesSec')
								{
									if (typeof datasUpdated[key][keyData] === 'string')
									{
										this._nodesById[nodeId].datas[keyData] = datasUpdated[key][keyData];
									}
									else
									{
										this._nodesById[nodeId].datas[keyData] = JSON.stringify(datasUpdated[key][keyData]);
									}
								}
								else
								{
									if (keyData === 'mustUpdate')
									{
										this._nodesById[nodeId].mustUpdate = datasUpdated[key][keyData];
									}
									else
									{
										this._nodesById[nodeId].datas[keyData] = datasUpdated[key][keyData];
									}
								}
							}
						}
						break;

					default:
						break;
				}
			}
		}
	}


	public logout()
	{
		this._concours 				= [];
		this._nodesById 			= {};
		this._candidatsByElement 	= {};
		this._candidats 			=
		{
			all: 	[],
			groups: null
		};
	}
}
