<form class="dialog_form">
    <div class="dialog_grid_container nomenu">
        <div class="dialog_header">
			<div class="dialog_header_title">
				{{titleModal}}
			</div>
			<div class="dialog_header_close">
				<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>
			</div>
		</div>
		<div class="dialog_content">
			<div class="form_section" [hidden]="itemSelected !== 'infos'">
				<process-editor [readOnly]="'false'" [scenario]="objProcess"></process-editor>
			</div>
		</div>
		<div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button mat-raised-button type="button" (click)='close()'>Annuler</button>
			</div>
			<div class="dialog_footer_alert_button">
				<button mat-raised-button type="button" [disabled]="!canValidateNewProcess()" (click)='validate()'>{{titleValidateButton}}</button>
			</div>
		</div>
    </div>
</form>
