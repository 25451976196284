<mat-card class="home_last_activity_step_item "  class="{{task.state}}">
	<div class="step_item_header" (click)="onClick($event)">
		<div class="step_item_header_title_block">
			<div class="step_item_header_title  title-h3">
				{{task.nameEpreuve}} - {{task.nameSujet}}
			</div>
			<div class="clear"></div>
			<div class="step_item_header_undertitle  title-h4">{{task.nameEtablissement}} - {{task.nameSession}}</div>
		</div>
		<div class="step_item_header_icon_block" matTooltip="{{task.name}}">
			<!--<mat-icon>warning</mat-icon>-->
			<div class="neoptec_icone_24 neoptec_icone_24_treedot"></div>
		</div>
	</div>
	<div class="step_item_content">
		<div class="step_item_content_info">
			<div class="step_item_content_info_user" *ngIf="(userData.isAdmin() || userData.isSuperviseur()) && task.assigneduser">{{ 'state-stats.assignedTo' | translate }}<app-user-name [userMail]="task.assigneduser"></app-user-name></div>
			<div class="step_item_content_info_user" *ngIf="!userData.isAdmin() && !userData.isSuperviseur() && task.creator"> {{ 'state-stats.createdBy' | translate }}<app-user-name [userMail]="task.creator"></app-user-name></div>

			<div class="clear"></div>
			<div class="step_item_content_info_echeance">
				<span class="step_item_content_info_echeance_title">{{ 'disciplineDetail.echeance' | translate }} : </span><span class="step_item_content_info_echeance_date">
					<app-date-user-friendly [date]="endDate"></app-date-user-friendly></span>
			</div>
		</div>
		<div class="step_item_content_type">
			<div class="step_item_content_type_name">{{task.typeLib}}</div>
			<div class="step_item_content_type_state">{{task.stateLib}}</div>
		</div>
	</div>
</mat-card>
