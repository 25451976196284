<div *ngIf='userData.isAdmin() || userData.isSuperviseur()'>
	<!-- Partie Tabs-->
	<div class="ion-navbar tabs-page community-tab">
		<mat-tab-group #matTabs [(selectedIndex)]="panelSelected" (selectedIndexChange)="refreshTab($event)" >
			<mat-tab label="{{ 'admin.usersManager' | translate }}" >
				<div class="">
					<app-admin-users></app-admin-users>
				</div>
			</mat-tab>
			<mat-tab label="{{ 'admin.parameters' | translate }}" >
				<div class="">
					<app-admin-system></app-admin-system>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
