
import { Injectable } 				from '@angular/core';
import { SocketService } 			from './socket.service';
import { ISocketData, SocketWhat }	from '../models/message.model';
import { UserData } 				from '../providers/user-data';

@Injectable()
/**
 *
 */
export class CandidatService
{
    constructor(private socketService: 	SocketService,
                private userData: 		UserData)
    {

    }


    public createCandidats(datas: any)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'candidats',
					'args': datas
				};
				socket.emit('create', data);
			}
		});
	}
	

    public getCandidatsByParent(idParent: any)
    {
        this.socketService.getSocketConnection().subscribe((socket) =>
		{
			if (socket)
			{
				let data: ISocketData =
				{
					'iam': 'c-c',
					'name': this.userData.mail,
					'what': SocketWhat.info,
					'cmd': 'candidatByParent',
					'args': { idParent: idParent }
				};
				socket.emit('read', data);
			}
		});
    }
}

