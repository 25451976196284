<form (ngSubmit)="validate()" [formGroup]="moFormGroup"  class="dialog_form">
    <div class="dialog_grid_container_task dialog_grid_container">

        <div class="dialog_header">
			<div class="dialog_header_title">
				{{titleModal}}
			</div>

			<div class="dialog_header_close">
				<div (click)='close()' class="dialog_header_close_icon"><mat-icon>close</mat-icon></div>

			</div>
		</div>

        <div class="dialog_menu">
			<div (click)="switch_tab('informations')" [class.dialog_menu_selected]="itemSelected === 'informations'" class="dialog_menu_block">
				{{ 'editorPanel.informations' | translate }}
			</div>

			<div (click)="switch_tab('droits')" [class.dialog_menu_selected]="itemSelected === 'droits'" class="dialog_menu_block">
				{{ 'editorPanel.droits' | translate }}
			</div>
		</div>

        <div class="dialog_content" *ngIf="currentTask && itemSelected">
			 <div class="workspace_header" *ngIf="treeDatas">
				<app-workspace-header [datasTree]="treeDatas"></app-workspace-header>
			</div>

			<div class="dialog_content_inner">
				<div class="form_section form_infomation" [hidden]="itemSelected !== 'informations'">

					<mat-form-field>
						<mat-select placeholder="{{ 'editorPanel.sujet' | translate }}" [(ngModel)]="currentIdSujet" formControlName="subject" (ngModelChange)="majTitle()" >
							<mat-option  *ngFor="let subject of subjectsAvailable" [value]="subject.value">{{ subject.libelle }}</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="clear"></div>

					<mat-form-field *ngIf="scenarioList">
						<mat-select placeholder="{{ 'editorPanel.type' | translate }}" disabled="true" [(ngModel)]="currentType" formControlName="type" (ngModelChange)="majTitle()" >
							<mat-option *ngFor="let type of scenarioList.steps" [value]="type.id">{{ type.name }}</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="clear"></div>

					<mat-form-field>
						<textarea matInput rows="3" placeholder="{{ 'editorPanel.description' | translate }}" [(ngModel)]="currentName" formControlName="description"></textarea>
					</mat-form-field>
					<div *ngIf="moFormCtrDescription.dirty && moFormCtrDescription.hasError('required')">{{ 'editorPanel.descriptionRequired' | translate }}</div>

					<div class="clear"></div>

					<mat-form-field>
						<input matInput [matDatepicker]="myDatepicker" [min]="startDate" [readonly]="true" [(ngModel)]="dateEcheance" placeholder="{{ 'editorPanel.echeance' | translate }}" id="workspace_modal_form_epreuve_date_start" formControlName="dateEcheance">
							   <mat-datepicker-toggle [disabled]="userData.isAdmin() === false  && userData.isSuperviseur() === false" matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
					</mat-form-field>
					<mat-datepicker #myDatepicker >
					</mat-datepicker>
					<div *ngIf="moFormCtrDescription.dirty && moFormCtrEcheance.hasError('required')">{{ 'editorPanel.descriptionRequired' | translate }}</div>

					<div class="clear"></div>

					<mat-form-field class="example-full-width"  matTooltip="{{buildInfosTooltipUser(currentAssigneduser.value)}}" [matTooltipPosition]="'right'" [matTooltipClass]="'my_tooltip'">

						<input type="text" placeholder="{{ 'editorPanel.assignedTo' | translate }}" aria-label="Number" (ngModelChange)="onChangeAssignedUser($event)" matInput formControlName="assignedUser" [matAutocomplete]="auto">

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayUser">
										  <mat-option *ngFor="let option of filteredOptions | async" [value]="option" matTooltip="{{buildInfosTooltipUser(option.value)}}" [matTooltipPosition]="'left'" [matTooltipClass]="'my_tooltip'" > {{ option.libelle }} </mat-option>
						</mat-autocomplete>

					</mat-form-field>


					<div class="clear"></div>

					<div *ngIf="currentAssigneduser.value && currentAssigneduser.value !== 'none'">
						<mat-checkbox class="task_button_send_mail" [(ngModel)]="sendEmail" formControlName="sendEmail">{{ 'editorPanel.sendMail' | translate }}</mat-checkbox>
					</div>

				</div>

				<div class="form_section form_right" [hidden]="itemSelected !== 'droits'">
					<mat-checkbox [(ngModel)]="noAccessFile" formControlName="noAccessFile">{{ 'editorPanel.noAccessFile' | translate }}</mat-checkbox>
					<div class="clear"></div>

					<div class="file-access">

						<mat-checkbox class="set_margin_btm" [disabled]="(userData.isAdmin() === false && userData.isSuperviseur() === false) || noAccessFile" [(ngModel)]="readOnlyFile" [ngModelOptions]="{standalone: true}">{{ 'editorPanel.readOnlyFile' | translate }}</mat-checkbox>
						<div class="clear"></div>
						<mat-checkbox class="set_margin_btm" [disabled]="(userData.isAdmin() === false && userData.isSuperviseur() === false) || noAccessFile" [(ngModel)]="canDeleteFile" [ngModelOptions]="{standalone: true}">{{ 'editorPanel.canDeleteFile' | translate }}</mat-checkbox>
						<div class="clear"></div>

						<mat-radio-group class="set_margin_btm" [disabled]="(userData.isAdmin() === false && userData.isSuperviseur() === false) || noAccessFile" [(ngModel)]="typeFileAccess" [ngModelOptions]="{standalone: true}">
										 <mat-radio-button  class="mat_radio_right" value="all">{{ 'editorPanel.accessAllFiles' | translate }}</mat-radio-button>
							<div class="clear"></div>

							<mat-radio-button value="list"  class="mat_radio_right" [disabled]="!selectedSubject || selectedSubject.pj.length === 0">{{ 'editorPanel.accessFileList' | translate }}</mat-radio-button>
						</mat-radio-group>
						<div class="clear"></div>

						<div *ngIf="typeFileAccess === 'list' && !noAccessFile">

							<mat-table #table [dataSource]="filesSubject">

								<!-- Checkbox Column -->
								<ng-container matColumnDef="select">
									<mat-header-cell *matHeaderCellDef>
										<mat-checkbox [disabled]="(userData.isAdmin() === false && userData.isSuperviseur() === false)"  (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"> </mat-checkbox>
									</mat-header-cell>
									<mat-cell *matCellDef="let row">
										<mat-checkbox [disabled]="(userData.isAdmin() === false && userData.isSuperviseur() === false)" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="name">
									<mat-header-cell *matHeaderCellDef> {{ 'editorPanel.nameFile' | translate }} </mat-header-cell>
									<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
								</ng-container>

								<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></mat-row>
							</mat-table>
						</div>
					</div>

					<mat-checkbox [(ngModel)]="canCreateFile" formControlName="canCreateFile">{{ 'editorPanel.userCanCreateFile' | translate }}</mat-checkbox>
					<div class="clear"></div>
				</div>

			</div>
		</div>

		<div class="dialog_comments_tasks">
			<comments-component *ngIf="currentTask && selectedSubject && isTaskEdition" [type]="'historic-comment'" [idElement]="currentTask.id" [parent]="selectedSubject" ></comments-component>
		</div>

		<div class="dialog_footer">
			<div class="dialog_footer_cancel_button">
				<button type="button" mat-raised-button (click)='close()'>{{ 'common.cancel' | translate }}</button>
			</div>
			<div class="dialog_footer_valid_button">
				<button type="submit" [disabled]="!canValidateTask() || moFormGroup.invalid" mat-raised-button>{{txtValidateButon}}</button>
			</div>
		</div>

	</div>
</form>
